import { Location } from 'history';
import { reduce } from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

import { featureDecisions } from 'featureDecisions';

import { DEFAULT_WARNING_MODAL_INFO } from './defaultWarningModalInfo';

interface IProps {
  customModalDescription?: string;
  nextPaths: string[];
}

export const InputNavigationBlocker = ({ customModalDescription, nextPaths }: IProps) => {
  const _isMounted = useRef(false);
  const [inputValues, setInputValues] = useState('');

  const reduceSelectorsValuesAsText = (selectors: NodeListOf<HTMLInputElement>) =>
    reduce(selectors, (res, v) => (res += v.value), '');

  useEffect(() => {
    const checkIfNavigationIsBlocked = () => {
      // get current copy of all input values
      // use to compare with initial mounted copy for any changes
      const currInputsValues = reduceSelectorsValuesAsText(document.querySelectorAll('input'));

      // if no values changed, we will not show the reload prompt
      // if values changed, we will show the reload prompt
      return currInputsValues === inputValues ? null : true;
    };

    // check if env disables blocker
    if (featureDecisions.disableBlocker) {
      return () => {};
    }

    // mount and store a copy of all the input values
    // use to compare with working copy for any changes
    if (!_isMounted.current) {
      setInputValues(reduceSelectorsValuesAsText(document.querySelectorAll('input')));
      _isMounted.current = true;
    }

    // displays leave site/reload confirmation prompt on closing tab/refresh
    window.onbeforeunload = checkIfNavigationIsBlocked;
    return () => {
      Object.defineProperty(window, 'onbeforeunload', {
        value: null,
        configurable: true,
        writable: true,
      });
      _isMounted.current = false;
      setInputValues('');
    };
  }, [_isMounted, inputValues]);

  const handleNavigation = (nextLocation: Location) => {
    const { pathname } = nextLocation;

    const currInputsValues = reduceSelectorsValuesAsText(document.querySelectorAll('input'));

    if (
      currInputsValues === inputValues || // allow nav if no dirty inputs
      nextPaths.includes(pathname)
    ) {
      return true;
    }

    return customModalDescription || DEFAULT_WARNING_MODAL_INFO.description;
  };

  return <Prompt message={handleNavigation} />;
};

import Chart, {
  ChartTooltipItem,
  ChartData,
  ChartOptions,
  PluginServiceRegistrationOptions,
} from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { CustomTooltip, customTooltipStyle } from 'components/Composite/Charts/CustomToolTip';
import { colorScale, typography, theme } from 'themes';
import { currencyAbbreviationFormatter } from 'utilities/currencyUtilities';

const ChartArea = styled.div`
  min-height: 218px;
`;

const ChartAverageLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  ${typography('label-bold')}
`;

const ChartAverageDottedLine = styled.div`
  width: 48px;
  height: 2px;
  border-top: 2px dashed ${colorScale('grey', 90)};
  margin-right: 8px;
`;

export interface IFinancialBarChartProps {
  showAverageLine?: boolean;
  chartData: {
    label: string | string[];
    amount: number;
    color: { main: string };
    hoverText: string;
  }[];
}

export const FinancialBarChart: React.FC<IFinancialBarChartProps> = ({
  showAverageLine,
  chartData,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);

  const initialiseChartService = () => {
    Chart.pluginService.register({
      beforeDraw: function (chart: Chart) {
        const width = chart.chartArea.right;
        const height = chart.chartArea.bottom;
        const ctx = chart.ctx as CanvasRenderingContext2D;

        ctx.restore();
        const fontSize = (height / 144).toFixed(2);
        ctx.font = `${fontSize}em ${theme.fonts.mainFontFamily}`;
        ctx.textBaseline = 'middle';
        ctx.fillStyle = colorScale('grey', 80)({ theme });

        const chartConfigOptions = chart.config.options as Chart.ChartOptions;
        const text = (chartConfigOptions.title as Chart.ChartTitleOptions).text as string;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    });
  };

  const buildChart = () => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx === null) {
        return;
      }

      const xLabels = chartData.map((x) => x.label);
      const xAmounts = chartData.map((x) => x.amount);
      const xColors = chartData.map((x) => x.color.main);

      new Chart(ctx, {
        type: 'bar',
        plugins: [ChartAnnotation as PluginServiceRegistrationOptions],
        options: {
          title: { display: false },
          legend: { display: false },
          maintainAspectRatio: false,
          scales: {
            gridLines: {
              offsetGridLines: false,
            },
            yAxes: [
              {
                ticks: {
                  maxTicksLimit: 6,
                  fontStyle: 'bold',
                  beginAtZero: true,
                  callback: (value, index) =>
                    index % 2 ? '' : currencyAbbreviationFormatter(value),
                },
              },
            ],
            xAxes: [
              {
                gridLines: { display: false },
                ticks: {
                  fontStyle: 'bold',
                  maxRotation: 0,
                  minRotation: 0,
                  callback: function (value) {
                    return value.split(' ');
                  },
                },
              },
            ],
          },
          tooltips: {
            enabled: false,
            custom: CustomTooltip,
            callbacks: {
              title: (tooltipItem: ChartTooltipItem[], data: ChartData) => {
                const index = tooltipItem[0].index as number;
                const dataLabels = data.labels as string[];
                return dataLabels[index];
              },
              label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
                const index = tooltipItem.index as number;
                const dataLabels = data.labels as string[];
                const category = dataLabels[index];

                return chartData.find(({ label }) => label === category)?.hoverText || '';
              },
            },
          },
          annotation: showAverageLine && {
            annotations: [
              {
                id: 'average-line',
                mode: 'horizontal',
                type: 'line',
                scaleID: 'y-axis-0',
                value: Math.round(
                  chartData.reduce((acc, x) => acc + x.amount, 0) / chartData.length
                ),
                borderColor: colorScale('grey', 90)({ theme }),
                borderWidth: 2,
                borderDash: [6, 6],
              },
            ],
          },
        } as ChartOptions,
        data: {
          labels: xLabels,
          datasets: [
            {
              data: xAmounts,
              backgroundColor: xColors,
              hoverBackgroundColor: xColors,
              borderWidth: 0,
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    initialiseChartService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    buildChart();
  });

  const chart = <canvas ref={chartRef} aria-label="bar chart" role="img" />;

  return (
    <>
      <ChartArea>
        <style>{customTooltipStyle}</style>
        {chart}
      </ChartArea>
      {showAverageLine && (
        <ChartAverageLabelWrapper data-testid="financial-bar-chart-average-label">
          <ChartAverageDottedLine />
          Average
        </ChartAverageLabelWrapper>
      )}
    </>
  );
};

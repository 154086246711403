import React from 'react';
import AriaModal from 'react-aria-modal';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { createGlobalStyle } from 'styled-components';
import { ifProp } from 'styled-tools';

import { theme } from 'themes';

import { uniqueIdGenerator } from '../uniqueIdGenerator/uniqueIdGenerator';

import { ModalContent, ModalChild } from './ModalContent';

const isMobile = window.matchMedia(`(max-width: ${theme.breakpoints.mobileMax})`);

type IFlexStylePosition = 'center' | 'bottom' | 'top' | 'fullScreen';
const flexStyle = (position: IFlexStylePosition, otherStyles?: {}) => {
  let flexPositionProperties = {};
  switch (position) {
    case 'center':
      flexPositionProperties = {
        justifyContent: 'center',
      };
      break;

    case 'bottom':
      flexPositionProperties = {
        justifyContent: 'flex-end',
      };
      break;

    case 'top':
      flexPositionProperties = { justifyContent: 'flex-start' };
      break;
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'unset',
    ...flexPositionProperties,
    ...otherStyles,
  };
};

interface IGlobalStyleProps {
  transitionState: TransitionStatus;
}
const GlobalStyle = createGlobalStyle<IGlobalStyleProps>`
  #react-aria-modal-dialog {
    position: ${ifProp({ transitionState: 'entered' }, 'unset', 'fixed')};
    bottom: 0;
    display: flex !important;
    flex-direction: inherit;
    align-items: inherit;
    justify-content: inherit;
  }
`;

type IOwnPropsPosition = 'center' | 'bottom' | 'top' | 'fullScreen';
interface IOwnProps {
  className?: string;

  /** Determines the position of the Panel with respect to the entire screen */
  position: IOwnPropsPosition;

  /** Determines if the close button will be rendered at all. By default it is true */
  showCloseButton: boolean;

  /** Colour variant for closeButton */
  closeButtonColourVariant: 'light' | 'dark';

  /** This allows for the transition status and wrapper ref to be passed into any child components that require this knowledge.
   * This is necessary when a child needs to perform an action after the modal has completed a transition or
   * when the modal needs to be programmatically scrolled.
   */
  renderChildren?: (
    state?: TransitionStatus,
    scrollRef?: React.RefObject<HTMLDivElement>
  ) => ModalChild;

  /** To ensure that there are elements inside the Modal component */
  children?: ModalChild;

  /** Background colour for the modal icon */
  iconBackgroundColor: string;

  /** Icon to be used for the top of the modal */
  icon?: React.ReactNode;

  /** Determines if modal enter transition should be shown */
  enableEnterAnimation?: boolean;

  /** Determines if modal exit transition should be shown */
  enableExitAnimation?: boolean;

  /** Callback after modal closing animation is complete */
  onExitAnimationEnd?: () => void;
}

interface IReactAriaModalProps {
  /** Determines if the modal is active or not */
  mounted: boolean;

  /** A string to use as the modal's accessible title. This value is passed to the modal's aria-label attribute */
  titleText: string;

  /** This function handles the state change of exiting (or deactivating) the modal.
   * It will be invoked when the user clicks outside the modal (if underlayClickExits={true})
   * or hits Escape (if escapeExits={true}), and it receives the event that triggered it as its only argument
   */
  onExit: (event?: React.SyntheticEvent) => void;

  /** This function is called in the modal's componentDidMount() lifecycle method.
   * You can use it to do whatever diverse and sundry things you feel like doing after the modal activates
   */
  onEnter?: () => void;

  /** By default, a click on the underlay will exit the modal. Pass false, and clicking on the underlay will do nothing */
  underlayClickExits?: boolean;

  /** By default, the Escape key exits the modal. Pass false, and it won't */
  escapeExits?: boolean;

  /** By default, the modal dialog will prevent any scrolling behind the modal window. Pass false, and it allows scrolling. */
  scrollDisabled?: boolean;

  /** Customize properties of the style prop that is passed to the underlay. */
  underlayStyle?: object;

  /** If true, the modal will receive a role of alertdialog, instead of its default dialog.
   * The alertdialog role should only be used when an alert, error, or warning occurs
   */
  alert?: boolean;
}

export type IModalProps = IReactAriaModalProps & IOwnProps;

export class Modal extends React.Component<IModalProps> {
  public static defaultProps = {
    position: isMobile.matches ? 'bottom' : 'center',
    showCloseButton: true,
    underlayClickExits: true,
    escapeExits: true,
    scrollDisabled: true,
    alert: false,
    iconBackgroundColor: 'white',
    closeButtonColourVariant: 'dark',
    enableEnterAnimation: true,
    enableExitAnimation: true,
  };

  private static readonly idGenerator = uniqueIdGenerator('Modal');

  private readonly id: string;

  constructor(props: IModalProps) {
    super(props);

    this.id = Modal.idGenerator.next().value as string;
  }

  componentDidUpdate(prevProps: IModalProps) {
    if (!prevProps.mounted && this.props.mounted) {
      const doc = document.documentElement;
      doc.style.position = 'absolute';
      doc.style.top = '0px';
    }
  }

  public render() {
    const {
      position,
      className,
      showCloseButton,
      mounted,
      underlayStyle,
      children,
      renderChildren,
      icon,
      enableEnterAnimation,
      enableExitAnimation,
      onExit,
      onExitAnimationEnd,
      iconBackgroundColor,
      closeButtonColourVariant,
      ...otherProps
    } = this.props;

    return (
      <Transition
        timeout={{ enter: 400, exit: 400 }}
        in={mounted}
        unmountOnExit={true}
        mountOnEnter={true}
        enter={enableEnterAnimation}
        exit={enableExitAnimation}
        onExited={onExitAnimationEnd}
      >
        {(state) => {
          return (
            <React.Fragment>
              <GlobalStyle transitionState={state} />
              <AriaModal
                onExit={onExit}
                initialFocus={`#${this.id}`}
                underlayStyle={flexStyle(position, underlayStyle)}
                {...otherProps}
              >
                <ModalContent
                  className={className}
                  onExit={onExit}
                  state={state}
                  id={this.id}
                  showCloseButton={showCloseButton}
                  closeButtonColourVariant={closeButtonColourVariant}
                  position={position}
                  icon={icon}
                  iconBackgroundColor={iconBackgroundColor}
                  renderChildren={renderChildren}
                >
                  {children}
                </ModalContent>
              </AriaModal>
            </React.Fragment>
          );
        }}
      </Transition>
    );
  }
}

import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getAvailableFunds } from 'redux/finances/selectors';
import { addDefaultErrorMessage } from 'redux/messages/actions';
import { markModuleAsCompletedRequest } from 'redux/modules/actions';
import { updateGoalRequest, updateEmergencyFundRequest } from 'redux/targets/actions';
import {
  getEmergencyFund,
  getGoals,
  getOngoingGoals,
  getReallocatedTargets,
  isEF,
} from 'redux/targets/selectors';

import { Modal, WarningModalContent } from 'components/Common';
import { AvailableFundsModal } from 'components/Composite';
import { ModuleContentCTAPanel, ModuleContentSideBar, ModuleContent } from 'components/Composite';
import { ComparisonBarWithValidation } from 'components/Composite/ComparisonBarSection/ComparisonBarWithValidation';
import { ICTAPanelProps } from 'components/Composite/ModuleContent/interfaces';
import { MAIN_MODULE } from 'constants/enums';
import { IStore } from 'constants/storeTypes';
import { minWidth } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';

import { AllocationPanel } from './AllocationPanel';
import { AvailableFundsCard } from './AvailableFundsCard';

const StyledModal = styled(Modal)`
  ${minWidth(
    'tablet',
    css`
      max-width: 384px;
    `
  )}
`;

const StyledAvailableFundsCard = styled(AvailableFundsCard)`
  margin-bottom: 16px;
`;

interface ILocalProps {
  nextRoute: string;
  title: string;
  subtitle?: string;
  hasSideBar?: boolean;
  onSuccessfulUpdate?: () => void;
}

type IProps = ILocalProps & Pick<ICTAPanelProps, 'backRoute' | 'continueBtnText'>;

export const AllocateSavingsContent: React.FC<IProps> = ({
  backRoute,
  nextRoute,
  continueBtnText,
  onSuccessfulUpdate,
  title,
  subtitle,
  hasSideBar = true,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const availableFunds = useSelector(getAvailableFunds);

  const emergencyFund = useSelector(getEmergencyFund);
  const sortedGoals = useSelector(getOngoingGoals);

  const getInitialCurrentEmergencyFund = useCallback(() => {
    return emergencyFund.currentAmount;
  }, [emergencyFund]);

  const getInitialCurrentGoalAmounts = useCallback(() => {
    return sortedGoals.reduce<{ [key: string]: number }>((acc, goal) => {
      acc[goal.id] = goal.currentAmount;
      return acc;
    }, {});
  }, [sortedGoals]);

  const [modalOpened, setModalOpened] = useState<'info' | 'unallocated-savings-warning' | null>(
    null
  );
  const [currEFAmount, setCurrEFAmount] = useState(getInitialCurrentEmergencyFund);
  const [currGoalAmounts, setCurrGoalAmounts] = useState(getInitialCurrentGoalAmounts);
  const [prevEFAmount, setPrevEFAmount] = useState(getInitialCurrentEmergencyFund);
  const [prevGoalAmounts, setPrevGoalAmounts] = useState(getInitialCurrentGoalAmounts);
  const [optimiseUndoState, setOptimiseUndoState] = useState<'optimise' | 'undo'>('optimise');

  const goals = useSelector(getGoals);
  const reallocatedTargets = useSelector((state: IStore) =>
    getReallocatedTargets(state, availableFunds)
  );

  const allocatedSavings =
    Object.values(currGoalAmounts).reduce((acc, x) => acc + x, 0) + currEFAmount;
  const unallocatedSavings = availableFunds - allocatedSavings;
  const areTargetsFullyAllocatedTo =
    Object.entries(currGoalAmounts).every(([id, amount]) => amount === goals[id].totalAmount) &&
    currEFAmount === emergencyFund.totalAmount;

  const getAutoAllocatedCurrGoalAmounts = useCallback(() => {
    return reallocatedTargets.reduce<{ [key: string]: number }>((acc, target) => {
      if (isEF(target)) {
        return acc;
      }
      acc[target.id] = target.reallocatedAmount;
      return acc;
    }, {});
  }, [reallocatedTargets]);

  const getAutoAllocatedCurrEF = useCallback(() => {
    const EF = reallocatedTargets.find((ele) => isEF(ele));
    return EF ? EF.reallocatedAmount : emergencyFund.currentAmount;
  }, [reallocatedTargets, emergencyFund.currentAmount]);

  const handleEFAmountChange = useCallback(
    (amount: number, _id: string) => {
      setCurrEFAmount(Math.min(emergencyFund.totalAmount, amount));
      setOptimiseUndoState('optimise');
    },
    [emergencyFund.totalAmount]
  );

  const handleGoalAmountChange = useCallback(
    (amount: number, goalID: string) => {
      setCurrGoalAmounts({
        ...currGoalAmounts,
        [goalID]: Math.min(goals[goalID].totalAmount, amount),
      });
      setOptimiseUndoState('optimise');
    },
    [currGoalAmounts, goals]
  );

  const handleAutoAllocateTargets = useCallback(() => {
    setPrevEFAmount(currEFAmount);
    setPrevGoalAmounts(currGoalAmounts);
    setCurrEFAmount(getAutoAllocatedCurrEF);
    setCurrGoalAmounts(getAutoAllocatedCurrGoalAmounts);
    setOptimiseUndoState('undo');
  }, [getAutoAllocatedCurrEF, getAutoAllocatedCurrGoalAmounts, currEFAmount, currGoalAmounts]);

  const handleUndo = useCallback(() => {
    setCurrEFAmount(prevEFAmount);
    setCurrGoalAmounts(prevGoalAmounts);
    setOptimiseUndoState('optimise');
  }, [prevEFAmount, prevGoalAmounts]);

  const updateStore = useCallback(async () => {
    try {
      const promises = Object.entries(currGoalAmounts).map(([goalID, currentAmount]) =>
        dispatch(updateGoalRequest(goalID, { currentAmount }))
      );

      emergencyFund.totalAmount > 0 &&
        promises.push(dispatch(updateEmergencyFundRequest({ currentAmount: currEFAmount })));

      await Promise.all(promises);
      await dispatch(markModuleAsCompletedRequest(MAIN_MODULE.SET_SAVING_TARGETS));

      if (onSuccessfulUpdate) {
        onSuccessfulUpdate();
      }
      history.push(nextRoute);
    } catch (e) {
      dispatch(addDefaultErrorMessage(e));
    }
  }, [
    dispatch,
    history,
    currEFAmount,
    currGoalAmounts,
    emergencyFund,
    nextRoute,
    onSuccessfulUpdate,
  ]);

  const handleClickCTAButton = useCallback(() => {
    if (unallocatedSavings > 0 && !areTargetsFullyAllocatedTo) {
      setModalOpened('unallocated-savings-warning');
    } else if (unallocatedSavings >= 0) {
      updateStore();
    }
  }, [updateStore, unallocatedSavings, areTargetsFullyAllocatedTo]);

  return (
    <>
      <AvailableFundsModal mounted={modalOpened === 'info'} onExit={() => setModalOpened(null)} />
      <StyledModal
        titleText="unallocated-savings"
        mounted={modalOpened === 'unallocated-savings-warning'}
        onExit={() => setModalOpened(null)}
        showCloseButton={false}
        escapeExits={false}
        underlayClickExits={false}
      >
        <WarningModalContent
          header="Continue without allocating all your savings?"
          description={`${formatCurrency(
            unallocatedSavings
          )} that is unallocated will not go towards your targets.`}
          confirmationButtonText="Continue"
          rejectionButtonText="Cancel"
          handleConfirmation={updateStore}
          handleRejection={() => {
            setModalOpened(null);
          }}
        />
      </StyledModal>
      <ModuleContent
        title={title}
        subtitle={subtitle}
        sideBar={hasSideBar ? <ModuleContentSideBar /> : null}
        ctaPanel={
          <ModuleContentCTAPanel
            continueBtnText={continueBtnText}
            backRoute={backRoute}
            onSubmit={handleClickCTAButton}
            hasSideBar={hasSideBar}
            disabled={allocatedSavings > availableFunds}
          >
            <ComparisonBarWithValidation
              label="Staying within your available fund"
              errorLabel="Exceeded your available fund"
              leftValue={allocatedSavings}
              rightValue={availableFunds}
              showErrorWhen={allocatedSavings > availableFunds}
            />
          </ModuleContentCTAPanel>
        }
      >
        <StyledAvailableFundsCard setModalOpened={setModalOpened} />
        <AllocationPanel
          currEFAmount={currEFAmount}
          currGoalAmounts={currGoalAmounts}
          emergencyFund={emergencyFund}
          sortedGoals={sortedGoals}
          availableFunds={availableFunds}
          unallocatedSavings={unallocatedSavings}
          optimiseUndoState={optimiseUndoState}
          handleEFAmountChange={handleEFAmountChange}
          handleGoalAmountChange={handleGoalAmountChange}
          handleAutoAllocateTargets={handleAutoAllocateTargets}
          handleUndo={handleUndo}
        />
      </ModuleContent>
    </>
  );
};

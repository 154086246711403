import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHasToken } from 'redux/dataStorage/selectors';
import { createNotificationRequest } from 'redux/notifications/actions';
import { getNumEmergencyFundMonths } from 'redux/targets/selectors';

export const AchievedEmergencyFundMonthsListener: React.FC = () => {
  const dispatch = useDispatch();
  const hasToken = useSelector(getHasToken);
  const numMonthsOfEmergencyFund = Math.round(useSelector(getNumEmergencyFundMonths) * 10) / 10;

  const [prevNumMonthsOfEmergencyFund, setPrevNumMonthsOfEmergencyFund] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (hasToken && prevNumMonthsOfEmergencyFund === null) {
      setPrevNumMonthsOfEmergencyFund(numMonthsOfEmergencyFund);
    }
  }, [hasToken, numMonthsOfEmergencyFund, prevNumMonthsOfEmergencyFund]);

  useEffect(() => {
    if (hasToken) {
      if (
        prevNumMonthsOfEmergencyFund !== null &&
        numMonthsOfEmergencyFund !== Infinity &&
        numMonthsOfEmergencyFund > prevNumMonthsOfEmergencyFund
      ) {
        dispatch(
          createNotificationRequest(
            `Achieved ${numMonthsOfEmergencyFund} month${
              numMonthsOfEmergencyFund === 1 ? '' : 's'
            } of emergency fund`,
            '',
            new Date()
          )
        );
        setPrevNumMonthsOfEmergencyFund(numMonthsOfEmergencyFund);
      }
    }
  }, [hasToken, dispatch, numMonthsOfEmergencyFund, prevNumMonthsOfEmergencyFund]);

  return null;
};

export const mockMaintenanceSchedules = {
  data: {
    maintenanceSchedules: [
      {
        agencies: ['Myinfo', 'Singpass'],
        from: '2021-05-12T00:00:00+08:00',
        to: '2021-05-12T08:00:00+08:00',
        showOnLandingPageBanner: true,
        landingPageBannerMessage:
          'Myinfo & Singpass will be under maintenance from <b>12:00am to 8:00am on 5th May 2021</b>, and will not be available.',
        maintenanceNoticePageRemarks:
          '<b>Myinfo</b> data will be unavailable\n<b>Singpass</b> login might be unavailable',
      },
      {
        agencies: ['DBS'],
        from: '2021-05-13T00:00:00+08:00',
        to: '2021-05-13T00:01:30+08:00',
        showOnLandingPageBanner: false,
        landingPageBannerMessage: '',
        maintenanceNoticePageRemarks: '<b>DBS</b> data will be unavailable',
      },
      {
        agencies: ['OCBC'],
        from: '2021-05-14T00:00:00+08:00',
        to: '2021-05-14T02:00:00+08:00',
        showOnLandingPageBanner: true,
        landingPageBannerMessage:
          'OCBC will be under maintenance from <b>12:00am to 2:00am on 6th May 2021</b>, and OCBC data will not be retrievable.',
        maintenanceNoticePageRemarks: '<b>OCBC</b> bank data will not be retrievable.',
      },
      {
        agencies: ['Myinfo', 'Singpass'],
        from: '2021-06-01T00:00:00+08:00',
        to: '2021-06-01T08:00:00+08:00',
        showOnLandingPageBanner: true,
        landingPageBannerMessage:
          'Myinfo will be under maintenance from <b>12:00am to 8:00am on 1st Jun 2021</b>, and MyInfo data will not be retrievable.',
        maintenanceNoticePageRemarks:
          '<b>Myinfo</b> data will be unavailable\n<b>Singpass</b> login might be unavailable',
      },
    ],
    lastUpdated: '2021-05-11T10:53:00+08:00',
  },
};

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { investmentTypeLabels, investmentSourceLabels } from 'constants/apimMyinfoLabels';
import { ASSET_TYPE } from 'constants/enums';
import { typography, colorScale } from 'themes';

const Wrapper = styled.div`
  display: flex;
`;

const Text = styled.span`
  ${typography('body-s')};
  color: ${colorScale('grey', 80)};
`;

const InvestmentSourceText = styled.span<{ highlightText: boolean }>`
  ${typography('body-s-semibold')};

  ${ifProp(
    'highlightText',
    css`
      color: ${colorScale('primary', 50)};
      cursor: pointer;
    `
  )}
`;

export interface ISubtextProps {
  investmentType: ASSET_TYPE;
  investmentSource: string;
  onClickInvestmentSource?: () => void;
}

export const Subtext: React.FC<ISubtextProps> = ({
  investmentType,
  investmentSource,
  onClickInvestmentSource,
}) => (
  <Wrapper>
    <Text>
      {investmentTypeLabels[investmentType]}&nbsp;&nbsp;&#xB7;&nbsp;&nbsp;
      <InvestmentSourceText
        highlightText={Boolean(onClickInvestmentSource)}
        onClick={onClickInvestmentSource}
      >
        {investmentSourceLabels[investmentSource]}
      </InvestmentSourceText>
    </Text>
  </Wrapper>
);

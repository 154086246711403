import React from 'react';
import styled from 'styled-components';

import { Pill, PillAdd } from 'components/Common';
import { IExpense } from 'constants/storeTypes';

const ExpensePills = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NewCategoryPillWrapper = styled.div`
  width: 100%;
`;

interface IProps {
  clickHandler: (source: string) => void;
  expenses: IExpense[];
  predefinedExpensesOptions: { value: string }[];
}

export const ExpensesPills: React.FC<IProps> = ({
  clickHandler,
  expenses,
  predefinedExpensesOptions,
}) => {
  const remainingExpenseCategories = predefinedExpensesOptions.filter(
    (expense) => !expenses.find((e) => e.category.toLowerCase() === expense.value.toLowerCase())
  );

  return (
    <ExpensePills>
      <NewCategoryPillWrapper>
        <Pill
          value={undefined}
          onClick={() => clickHandler('')}
          isSelected={false}
          key="new-category"
        >
          <PillAdd /> New category
        </Pill>
      </NewCategoryPillWrapper>
      {remainingExpenseCategories.map((category) => (
        <Pill
          value={category.value}
          onClick={() => clickHandler(category.value)}
          isSelected={false}
          key={category.value}
        >
          <PillAdd /> {category.value}
        </Pill>
      ))}
    </ExpensePills>
  );
};

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { ITotalDebtServicingRatioInputs } from '../interfaces';

export const TDSR3: IRule<ITotalDebtServicingRatioInputs> = {
  condition: ({ totalDebtServicingRatio }: ITotalDebtServicingRatioInputs) =>
    totalDebtServicingRatio !== null && totalDebtServicingRatio === 0,
  result: {
    key: RULE.TDSR3,
    indicatorStatus: indicatorStatuses.GOOD,
    recommendedActions: [],
    resources: [],
  },
};

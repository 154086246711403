import React from 'react';
import styled from 'styled-components';

import { colorScale } from 'themes';

const StyledInput = styled.input`
  position: relative;
  height: 30px;
  width: 60px;
  border-radius: 16px;
  appearance: none;
  background: ${colorScale('grey', 50)};
  transition: 0.5s;

  &:checked {
    background: ${colorScale('primary', 40)};
  }

  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 16px;
    top: 6px;
    left: 6px;
    background: white;
    transition: 0.5s;
  }

  &:checked:before {
    left: 36px;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  height: 44px;
`;

export interface IToggleProps {
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

export const Toggle = ({ checked, onChange, disabled, ...otherProps }: IToggleProps) => {
  return (
    <StyledLabel>
      <StyledInput
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
        {...otherProps}
      />
    </StyledLabel>
  );
};

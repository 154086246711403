import mixpanel from 'mixpanel-browser';

/**
 * The following data tags are interlinked with the document "Mixpanel Tags MasterList"
 * under FPDS > 03 Development > 08 Investments > 04 Development > Data Analytics
 * https://docs.google.com/spreadsheets/d/1P5JAJDdIMu6uHjICNFnuU5i3d2jP-RPwn7u7_m8pglI/edit#gid=0
 *
 * Whenever a data tag function is added, get removed or is no longer being called in code,
 * the document should be updated accordingly too to sync up with business side.
 */

// S/N 031
export const captureExpenseManagerAverageMonthlyIncome = (averageMonthlyIncome: number) => {
  mixpanel.track('Expense Manager: Average Monthly Income', {
    averageMonthlyIncome,
  });
};

// S/N 032
export const captureExpenseManagerTotalFunds = (totalFunds: number) => {
  mixpanel.track('Expense Manager: Total Funds', { totalFunds });
};

// S/N 033
export const captureExpenseManagerCurrentMonthlyExpenses = (currentMonthlyExpenses: number) => {
  mixpanel.track('Expense Manager: Current Monthly Expenses', { currentMonthlyExpenses });
};

// S/N 034
export const captureExpenseManagerRecommendedMonthlyExpenses = (
  recommendedMonthlyExpenses: number
) => {
  mixpanel.track('Expense Manager: Recommended Monthly Expenses', { recommendedMonthlyExpenses });
};

// S/N 035
export const captureExpenseManagerCommittedMonthlyExpenses = (committedMonthlyExpenses: number) => {
  mixpanel.track('Expense Manager: Committed Monthly Expenses', { committedMonthlyExpenses });
};

// S/N 036
export const captureExpenseManagerReductionInMonthlyExpenses = (
  reductionInMonthlyExpenses: number
) => {
  mixpanel.track('Expense Manager: Reduction In Monthly Expenses', { reductionInMonthlyExpenses });
};

// S/N 037
type IRealityCheckModalType =
  | 'direct-to-assistance'
  | 'non-negative-cashflow'
  | 'savings-lifespan'
  | 'reduce-expenses';
export const captureExpenseManagerRealityCheckCategory = (
  realityCheckCategory: IRealityCheckModalType
) => {
  mixpanel.track('Expense Manager: Reality Check: Category', { realityCheckCategory });
};

// S/N 038
export const captureExpenseManagerRealityCheckMonthsSavingsCanLast = (
  realityCheckMonthsSavingsCanLast: number
) => {
  mixpanel.track('Expense Manager: Reality Check: Months Savings Can Last', {
    realityCheckMonthsSavingsCanLast,
  });
};

// S/N 039
export const captureExpenseManagerClickedEnterIncomeButton = () => {
  mixpanel.track('Expense Manager: Clicked Enter Income Button');
};

// S/N 040
export const captureExpenseManagerClickedDownloadExpenseTrackerPDF = () => {
  mixpanel.track('Expense Manager: Clicked Download expense tracker (PDF)');
};

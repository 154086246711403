import { INTERCEPT, ONBOARDING, PROFILE, TARGETS } from 'constants/urls';

export interface IGetRedirectRouteFnOptions {
  hasValidToken?: boolean;
  hasConsentedToAppTNC?: boolean;
  deferEmailVerification?: boolean;
  needsNOCIntercept?: boolean;
  hasCompletedOnboardingQuestions?: boolean;
  hasCompletedOnboarding?: boolean;
  hasCompletedTargetSetup?: boolean;
  hasExpiredAndNonPaidOutTargets?: boolean;
  hasLatestBankData?: boolean;
  isAccountPendingDeletion?: boolean;
  showSGFinDexVanillaTables: boolean;
  isDuplicateSession?: boolean;
}

export type getRedirectRouteFn = (options: IGetRedirectRouteFnOptions) => string | undefined;

const sieving = (flag: boolean, redirect: string): string | undefined =>
  flag ? redirect : undefined;

const combineSieves = (...sieves: getRedirectRouteFn[]): getRedirectRouteFn => (options) =>
  sieves.reduce<string | undefined>((a, c) => a || c(options), undefined);

const baseLoggedInSieve: getRedirectRouteFn = ({ hasValidToken, isDuplicateSession }) =>
  sieving(!hasValidToken, isDuplicateSession ? '/logout' : '/');

const cdpSieve: getRedirectRouteFn = ({ showSGFinDexVanillaTables }) =>
  sieving(showSGFinDexVanillaTables, '/sgfindex');

const nocSieve: getRedirectRouteFn = ({ needsNOCIntercept }) =>
  sieving(Boolean(needsNOCIntercept), INTERCEPT.LOGIN.nocInterceptOnLogin);

const emailSieve: getRedirectRouteFn = ({ deferEmailVerification }) =>
  sieving(Boolean(!deferEmailVerification), INTERCEPT.LOGIN.emailInterceptOnLogin);

const accountPendingDeletionSieve: getRedirectRouteFn = ({ isAccountPendingDeletion }) =>
  sieving(!isAccountPendingDeletion, '/dashboard');

const accountNotPendingDeletionSieve: getRedirectRouteFn = ({ isAccountPendingDeletion }) =>
  sieving(Boolean(isAccountPendingDeletion), PROFILE.deactivated);

const introPrivacySieve: getRedirectRouteFn = ({ hasConsentedToAppTNC }) =>
  sieving(!hasConsentedToAppTNC, '/intro-privacy');

const completedOnboardingQuestionsSieve: getRedirectRouteFn = ({
  hasCompletedOnboardingQuestions,
}) => sieving(!hasCompletedOnboardingQuestions, ONBOARDING.incomplete);

const completedOnboardingSieve: getRedirectRouteFn = ({ hasCompletedOnboarding }) =>
  sieving(!hasCompletedOnboarding, ONBOARDING.incomplete);

const completedTargetSetupSieve: getRedirectRouteFn = ({ hasCompletedTargetSetup }) =>
  sieving(!hasCompletedTargetSetup, '/targets/setup');

const updateSavingsSieve: getRedirectRouteFn = ({ hasExpiredAndNonPaidOutTargets }) =>
  sieving(Boolean(hasExpiredAndNonPaidOutTargets), '/update-savings-preview');

const updateAvailableFundsSieve: getRedirectRouteFn = ({ hasExpiredAndNonPaidOutTargets }) =>
  sieving(!hasExpiredAndNonPaidOutTargets, '/update-available-funds');

const updateBankDataSieve: getRedirectRouteFn = ({ hasLatestBankData }) =>
  sieving(Boolean(hasLatestBankData), '/dashboard');

const completedOnboardingQuestionsTargetRedirectionSieve: getRedirectRouteFn = ({
  hasCompletedOnboarding,
}) => sieving(!hasCompletedOnboarding, TARGETS.lockoutPage);

const loggedInSieve: getRedirectRouteFn = combineSieves(cdpSieve, baseLoggedInSieve);

export const loggedInRedirection = loggedInSieve;

export const accountPendingDeletionRedirection = combineSieves(
  loggedInSieve,
  accountPendingDeletionSieve
);

export const introPrivacyRedirection = combineSieves(
  loggedInSieve,
  accountNotPendingDeletionSieve,
  introPrivacySieve
);

export const dashBoardRedirection = combineSieves(
  introPrivacyRedirection,
  nocSieve,
  emailSieve,
  completedOnboardingQuestionsSieve
);

export const redirectToOnboardingQuestions = combineSieves(
  introPrivacyRedirection,
  completedOnboardingQuestionsSieve
);

export const defaultRedirection = combineSieves(introPrivacyRedirection, completedOnboardingSieve);

export const redirectToTargetsSetupOrOnboardingQuestions = combineSieves(
  introPrivacyRedirection,
  completedOnboardingQuestionsTargetRedirectionSieve,
  completedTargetSetupSieve,
  updateSavingsSieve
);

export const updateAvailableFundsRedirection = defaultRedirection;

export const updateExpiredTargetsRedirection = combineSieves(
  updateAvailableFundsRedirection,
  updateAvailableFundsSieve
);

export const updateBankDataRedirection = combineSieves(defaultRedirection, updateBankDataSieve);

import { Reducer, combineReducers } from 'redux';

import { IAllActions } from 'constants/actionTypes';
import { IStore } from 'constants/storeTypes';

import { apimReducer } from './apim/reducer';
import { crossSliceReducer } from './crossSliceReducer';
import { dataStorageReducer } from './dataStorage/reducer';
import { financesReducer } from './finances/reducer';
import { financesLiteReducer } from './financesLite/reducer';
import { financialStatusReducer } from './financialStatus/reducer';
import { fxRatesReducer } from './fxRates/reducer';
import { housingReducer } from './housing/slice';
import { investingForBeginnersReducer } from './investingForBeginners/slice';
import { messagesReducer } from './messages/reducer';
import { modulesReducer } from './modules/reducer';
import { modulesLiteReducer } from './modulesLite/reducer';
import { myinfoReducer } from './myinfo/reducer';
import { notificationsReducer } from './notifications/reducer';
import { profileReducer } from './profile/slice';
import { targetsReducer } from './targets/reducer';
import { userHistoryReducer } from './userHistory/reducer';

export const combinedReducer = combineReducers({
  profile: profileReducer,
  modules: modulesReducer,
  modulesLite: modulesLiteReducer,
  targets: targetsReducer,
  finances: financesReducer,
  financesLite: financesLiteReducer,
  dataStorage: dataStorageReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  myinfo: myinfoReducer,
  apim: apimReducer,
  fxRates: fxRatesReducer,
  financialStatus: financialStatusReducer,
  userHistory: userHistoryReducer,
  housing: housingReducer,
  investingForBeginners: investingForBeginnersReducer,
});

export const rootReducer: Reducer<IStore, IAllActions> = (state, action) => {
  const intermediateState = crossSliceReducer(state, action);
  return combinedReducer(intermediateState, action);
};

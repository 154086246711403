import React from 'react';

import LadyWithClipboard from './lady-with-clipboard.svg';
import ManWithSafe from './man-with-safe.svg';
import PeopleWithGlowingLightbulb from './people-with-glowing-lightbulb.svg';
import SimpleAndConvenient from './simple-and-convenient.svg';
import Swirl from './swirl.svg';
import WaveDesktop from './wave-desktop.svg';
import WaveMobile from './wave-mobile.svg';
import WeighingScale from './weighing-scale.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const LadyWithClipboardImage = (props: ImageProps) => (
  <img
    {...props}
    src={LadyWithClipboard}
    alt="Woman with a clipboard, with a bar graph in the background"
  />
);

export const WeighingScaleImage = (props: ImageProps) => (
  <img
    {...props}
    src={WeighingScale}
    alt="Weighing scale, showing a man with a laptop weighing heavier than a bag of money"
  />
);

export const PeopleWithGlowingLightbulbImage = (props: ImageProps) => (
  <img
    {...props}
    src={PeopleWithGlowingLightbulb}
    alt="Man and woman with glowing lightbulb containing dollar sign"
  />
);

export const ManWithSafeImage = (props: ImageProps) => (
  <img {...props} src={ManWithSafe} alt="Man with safe" />
);

export const SimpleAndConvenientImage = (props: ImageProps) => (
  <img {...props} src={SimpleAndConvenient} alt="Lady transferring graph and chart to the cloud" />
);

export const SwirlImage = (props: ImageProps) => <img {...props} src={Swirl} alt="Swirl" />;

export const WaveDesktopImage = (props: ImageProps) => (
  <img {...props} src={WaveDesktop} alt="Wave" />
);

export const WaveMobileImage = (props: ImageProps) => (
  <img {...props} src={WaveMobile} alt="Wave" />
);

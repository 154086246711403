import * as React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Panel, Button, RoutedButton } from 'components/Common';
import { ValidationButton, IButtonRenderParams } from 'components/Validations';
import { maxWidth, minWidth, PAGE_SIDE_SPACING } from 'themes';

import { ICTAPanelProps } from './interfaces';

const MainContent = styled.div`
  grid-area: main;
`;

interface ICTAWrapper {
  hasBackRoute?: boolean;
  hasSideBar: boolean;
}
const CTAWrapper = styled(Panel)<ICTAWrapper>`
  position: relative;
  bottom: 0;
  width: 100vw;
  border-radius: 0;
  grid-column-gap: 16px;

  ${maxWidth(
    'mobile',
    css`
      margin: 0 -${PAGE_SIDE_SPACING.DEFAULT_PX};
      padding: 24px ${PAGE_SIDE_SPACING.DEFAULT_PX};
    `
  )}

  ${minWidth(
    'tablet',
    css`
      margin: 0 -${PAGE_SIDE_SPACING.DEFAULT_PX};
      padding: 24px calc((100vw - 512px + 48px) / 2);
    `
  )}

  ${minWidth(
    'desktop',
    css`
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 24px calc((100vw - 768px) / 2);
      display: grid;
      grid-template-columns: ${ifProp('hasSideBar', '1fr 2fr', '1fr')};
      grid-template-areas: ${ifProp('hasSideBar', `'sidebar main'`, `'main'`)};
    `
  )}

  ${minWidth(
    'largeScreen',
    css`
      padding: 24px calc((100vw - 1024px) / 2);
    `
  )};
`;

interface ICTAButtonWrapper {
  hasBackRoute?: boolean;
}
const CTAButtonWrapper = styled.div<ICTAButtonWrapper>`
  ${maxWidth(
    'mobile',
    css`
      display: flex;
      justify-content: center;

      ${ifProp(
        'hasBackRoute',
        css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 16px;
        `
      )}
    `
  )}

  ${minWidth(
    'tablet',
    css`
      display: flex;
      justify-content: flex-end;

      ${ifProp(
        'hasBackRoute',
        css`
          justify-content: space-between;
        `
      )}
    `
  )}
`;

interface ICTAContinueButton {
  hasBackRoute?: boolean;
}
const CTAContinueButton = styled(Button)<ICTAContinueButton>`
  max-width: 328px;

  ${minWidth(
    'tablet',
    css`
      max-width: 160px;
    `
  )}
`;

const CTARoutedButton = styled(RoutedButton)`
  ${minWidth(
    'tablet',
    css`
      max-width: 160px;
    `
  )}
`;

export const ModuleContentCTAPanel: React.FC<ICTAPanelProps> = ({
  backRoute,
  onSubmit,
  onBack,
  children,
  disabled,
  backBtnText = 'Back',
  continueBtnText = 'Continue',
  hasSideBar = true,
}) => {
  const hasBackRoute = Boolean(backRoute);
  return (
    <CTAWrapper
      data-testid="module-content-cta-panel"
      hasBackRoute={hasBackRoute}
      hasSideBar={hasSideBar}
    >
      <MainContent>
        {children}
        <CTAButtonWrapper hasBackRoute={hasBackRoute}>
          {backRoute && (
            <CTARoutedButton
              variant="secondary"
              widthStyle="fluid"
              type="button"
              onClick={onBack}
              to={backRoute}
            >
              {backBtnText}
            </CTARoutedButton>
          )}
          <ValidationButton
            render={(renderParams: IButtonRenderParams) => (
              <CTAContinueButton
                widthStyle="fluid"
                type={onSubmit ? 'button' : 'submit'}
                onClick={onSubmit}
                disabled={disabled}
                hasBackRoute={hasBackRoute}
                {...renderParams}
              >
                {continueBtnText}
              </CTAContinueButton>
            )}
          />
        </CTAButtonWrapper>
      </MainContent>
    </CTAWrapper>
  );
};

import React from 'react';
import styled, { css } from 'styled-components';

import { WeighingScaleImage } from 'assets/LandingPage';
import { SimpleAndConvenientImage } from 'assets/LandingPage';
import { ManWithSafeImage } from 'assets/LandingPage';
import { Heading } from 'components/Common';
import { typography, colorScale, maxWidth, minWidth } from 'themes';

import {
  CollaborationSection,
  PartneringBanksSection,
  PartneringInsurersSection,
  PartneringSgxCdpSection,
} from '../partners';
import { SectionWrapper, StyledContainer } from '../sharedStyles';

import { MainHeading } from './sharedStyles';

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  ${maxWidth(
    'mobile',
    css`
      margin: 40px 0;
      flex-direction: column;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      justify-content: space-between;
      flex-direction: row;
      margin: 32px 0;
    `
  )}
`;

const TextWrapper = styled.div`
  flex-shrink: 0;

  ${maxWidth(
    'mobile',
    css`
      width: 100%;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      width: 50%;
      margin-left: 32px;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      margin-left: 48px;
    `
  )}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 24px;

  ${typography('heading-m')};
`;

const StyledP = styled.p`
  ${typography('body-l')};
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const imageStyle = css`
  ${maxWidth(
    'mobile',
    css`
      margin-bottom: 24px;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      max-width: 256px;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      max-width: 384px;
    `
  )}
`;

const StyledWeighingScaleImage = styled(WeighingScaleImage)`
  ${imageStyle}
`;

const StyledSimpleAndConvenientImage = styled(SimpleAndConvenientImage)`
  ${imageStyle}
`;

const StyledManWithSafeImage = styled(ManWithSafeImage)`
  ${imageStyle}
`;

const DataContainer = styled.div`
  background: ${colorScale('grey', 15)};
  padding: 16px;
  margin-top: 24px;
  border-radius: 6px;
  border: 1px solid ${colorScale('grey', 20)};
`;

const DataContainerTitle = styled.p`
  color: ${colorScale('grey', 90)};
  ${typography('body-l-bold')}
  margin: 0 0 16px 0;
`;

const DataContainerList = styled.ul`
  padding-left: 16px;
  margin-bottom: 16px;
  list-style-type: disc;
  ${typography('body-m')};
`;

export const InfoSection = () => (
  <SectionWrapper>
    <StyledContainer>
      <MainHeading>Why MyMoneySense?</MainHeading>
      <FlexWrapper>
        <ImageWrapper>
          <StyledWeighingScaleImage />
        </ImageWrapper>
        <TextWrapper>
          <StyledHeading>Free and neutral</StyledHeading>
          <StyledP>
            We’re a government website that provides financial guidance that is not tied to any
            product offerings. Make your own financial decisions with the information and tools you
            need.
          </StyledP>
        </TextWrapper>
      </FlexWrapper>

      <FlexWrapper>
        <ImageWrapper>
          <StyledManWithSafeImage />
        </ImageWrapper>
        <TextWrapper>
          <StyledHeading>Secure and private</StyledHeading>
          <StyledP>
            All the information you enter on this website stays on this website. We have taken the
            appropriate steps to protect your data and privacy.
          </StyledP>
        </TextWrapper>
      </FlexWrapper>

      <FlexWrapper>
        <ImageWrapper>
          <StyledSimpleAndConvenientImage />
        </ImageWrapper>
        <TextWrapper>
          <StyledHeading>Simple and convenient</StyledHeading>
          <StyledP>
            We've partnered 7 banks, SGX CDP and 7 insurers to allow you to consolidate government,
            bank, SGX CDP and insurance data with Myinfo and SGFinDex.
          </StyledP>
          <StyledP>
            After you have given your consent, you will be able to retrieve the following data:
          </StyledP>
          <DataContainer>
            <DataContainerTitle>Government data</DataContainerTitle>
            <DataContainerList>
              <li>Latest CPF account balances</li>
              <li>Past year’s tax bill (Notice of Assessment)</li>
              <li>Outstanding HDB loan balances and monthly instalments</li>
              {/* <li>Dependants’ Protection Scheme (DPS)</li>
              <li>Home Protection Scheme (HPS)</li>
              <li>MediShield Life</li> */}
            </DataContainerList>
            <DataContainerTitle>Bank data</DataContainerTitle>
            <DataContainerList>
              <li>Past months’ current and savings account balances</li>
              <li>Past months’ credit card statements</li>
              <li>Loans</li>
              <li>Fixed deposits</li>
              <li>Unit trusts</li>
              <li>Supplementary Retirement Scheme (SRS) account</li>
              <li>CPF Investment Scheme (CPFIS) account</li>
            </DataContainerList>
            <DataContainerTitle>Central Depository (CDP) data</DataContainerTitle>
            Investments in SGX CDP accounts, including:
            <DataContainerList>
              <DataContainerList>
                <li>Equities</li>
                <li>Bonds</li>
                <li>Funds</li>
                <li>Structured products</li>
              </DataContainerList>
            </DataContainerList>
            <DataContainerTitle>Insurance data</DataContainerTitle>
            Individual life and health insurance information, pertaining to:
            <DataContainerList>
              <DataContainerList>
                <li>Policy details</li>
                <li>Coverage details</li>
                <li>Funds details (where applicable)</li>
                <li>Projected future policy value (where applicable)</li>
              </DataContainerList>
            </DataContainerList>
          </DataContainer>
        </TextWrapper>
      </FlexWrapper>

      <PartneringBanksSection />
      <PartneringSgxCdpSection />
      <PartneringInsurersSection />
      <CollaborationSection />
    </StyledContainer>
  </SectionWrapper>
);

export default InfoSection;

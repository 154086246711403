import React from 'react';

import { INVESTMENT_SOURCE, ASSET_TYPE } from 'constants/enums';
import { IInsuranceAsset } from 'constants/storeTypes';

import { InvestmentWithTotalValueInputPanel } from '../InvestmentWithTotalValueInputPanel/InvestmentWithTotalValueInputPanel';

interface IOwnProps {
  asset: IInsuranceAsset;
  updateManualInvestmentAsset: (id: string, update: Partial<IInsuranceAsset>) => void;
  deleteManualInvestmentAsset: (id: string) => void;
  onClickInvestmentSource: (
    id: string,
    name: string,
    investmentSource: INVESTMENT_SOURCE,
    assetType: ASSET_TYPE
  ) => void;
  autoFocusOnStart?: boolean;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

export const InsuranceInputPanel: React.FC<IProps> = ({
  asset,
  updateManualInvestmentAsset,
  deleteManualInvestmentAsset,
  onClickInvestmentSource,
  autoFocusOnStart,
}) => {
  const { id, name, investmentSource, totalValueAmount, currency } = asset;
  return (
    <InvestmentWithTotalValueInputPanel
      investmentType={ASSET_TYPE.INSURANCE}
      totalValueFieldLabel="Surrender value"
      id={id}
      onDelete={() => deleteManualInvestmentAsset(id)}
      name={name}
      handleNameChange={(name) => updateManualInvestmentAsset(id, { name })}
      investmentSource={investmentSource}
      totalValue={totalValueAmount}
      handleTotalValueChange={(totalValueAmount) =>
        updateManualInvestmentAsset(id, { totalValueAmount })
      }
      onClickInvestmentSource={() =>
        onClickInvestmentSource(id, name, investmentSource, ASSET_TYPE.INSURANCE)
      }
      totalValueCurrency={currency}
      handleTotalValueCurrencyChange={(currency) => updateManualInvestmentAsset(id, { currency })}
      autoFocusOnStart={autoFocusOnStart}
    />
  );
};

import React from 'react';
import styled, { css } from 'styled-components';
import { switchProp, prop } from 'styled-tools';

import { minWidth, maxWidth } from 'themes';

import { CURTAIN_STOP_POINT_ID } from '../Backgrounds';
import { TemplateWrapper, TallManFatBoyContainer } from '../sharedStyles';

const CurtainStopPoint = styled.span`
  grid-area: curtain-stop-point;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'curtain-stop-point'
    'content'
    'cta';

  ${minWidth(
    'desktop',
    css`
      grid-template-columns: 6fr 6fr;
      grid-template-areas:
        'header content'
        'header cta';
    `
  )}
`;

const Header = styled.div`
  grid-area: header;

  ${minWidth(
    'desktop',
    css`
      margin-right: 24px;
    `
  )}
`;

const verticalAlignmentStyles = css`
  align-self: ${switchProp('verticalAlignment', {
    top: 'flex-start',
    bottom: 'flex-end',
  })};
`;

const Content = styled.div<{ verticalAlignment: string; marginTop: number }>`
  grid-area: content;
  ${verticalAlignmentStyles}

  ${maxWidth(
    'tablet',
    css`
      margin-top: ${prop('marginTop')}px;
    `
  )}
`;

const CTA = styled.div<{ verticalAlignment: string }>`
  grid-area: cta;
  ${verticalAlignmentStyles}

  margin-top: 64px;
`;

type IVerticalAlignment = 'top' | 'bottom';

export interface ITallManFatBoyTemplateProps {
  header: React.ReactChild;
  spaceBetweenHeaderAndContent?: number;
  content: React.ReactChild;
  contentVerticalAlignment?: IVerticalAlignment;
  cta?: React.ReactChild;
  ctaVerticalAlignment?: IVerticalAlignment;
  background?: React.ReactElement;
}

/**
 * On desktop, renders the header on the left half of the screen and
 * content/cta on the right half of the screen.
 *
 * On mobile, renders the header, content and cta in a single column.
 *
 * @param header A component from Templates/TallManFatBoyTemplate/Headers.
 * @param [spaceBetweenHeaderAndContent] Applicable to mobile/tablet only.
 *                                       Determines the spacing between the
 *                                       header and the content. Default 0.
 * @param content
 * @param [contentVerticalAlignment] Determines if the content should align
 *                                   itself to the top or the bottom of the div
 *                                   it's in.
 * @param [cta]
 * @param [ctaVerticalAlignment] Determines if the cta content should align
 *                               itself to the top or the bottom of the div
 *                               it's in.
 * @param [background] A component from Templates/Backgrounds.
 */
export const TallManFatBoyTemplate = ({
  header,
  spaceBetweenHeaderAndContent = 0,
  content,
  contentVerticalAlignment = 'bottom',
  cta,
  ctaVerticalAlignment = 'top',
  background,
}: ITallManFatBoyTemplateProps) => {
  return (
    <TemplateWrapper>
      {background}

      <TallManFatBoyContainer>
        <Wrapper>
          <Header>{header}</Header>

          <CurtainStopPoint id={CURTAIN_STOP_POINT_ID} />

          <Content
            marginTop={spaceBetweenHeaderAndContent}
            verticalAlignment={contentVerticalAlignment}
          >
            {content}
          </Content>
          {cta && <CTA verticalAlignment={ctaVerticalAlignment}>{cta}</CTA>}
        </Wrapper>
      </TallManFatBoyContainer>
    </TemplateWrapper>
  );
};

import React from 'react';
import styled from 'styled-components';

import { IEmergencyFundForRender } from 'constants/storeTypes';
import { formatDate } from 'utilities/dateUtilities';

import { StyledPanel, BaseWrapper, DateWrapper, Year } from './sharedStyles';
import { TargetContent } from './TargetContent';

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -24px -24px -24px -20px;
  background-color: #ebf6ff;
  z-index: -1;
  overflow: hidden;
`;

interface IOwnProps {
  emergencyFund: IEmergencyFundForRender;
  projectedEmergencyFund?: IEmergencyFundForRender;
  openSetEmergencyFundModal: () => void;
  showProgress?: boolean;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

export const EmergencyFundDetailsCard: React.FC<IProps> = ({
  emergencyFund,
  projectedEmergencyFund,
  openSetEmergencyFundModal,
  showProgress = true,
  className,
}) => {
  const [month, year] = formatDate(emergencyFund.date).split(' ');

  return (
    <StyledPanel className={className}>
      <DateWrapper data-testid="date-wrapper">
        <React.Fragment>
          <div>{month.substring(0, 3)}</div>
          <Year>{year}</Year>
        </React.Fragment>
      </DateWrapper>
      <BaseWrapper data-testid="emergency-fund-card">
        <TargetContent
          editCallback={() => openSetEmergencyFundModal()}
          target={emergencyFund}
          projectedTarget={projectedEmergencyFund}
          showProgress={showProgress}
        />
        <Background />
      </BaseWrapper>
    </StyledPanel>
  );
};

import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { NavigationBlockerModal } from './NavigationBlockerModal';

export const RouterWithBlocker: React.FC = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const [message, setMessage] = useState('');
  const [callback, setCallback] = useState<() => (ok: boolean) => void>(() => () => null);

  return (
    <React.Fragment>
      <NavigationBlockerModal
        mounted={mounted}
        setMounted={setMounted}
        message={message}
        callback={callback}
      />
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          setMounted(true);
          setMessage(message);
          setCallback(() => callback);
        }}
      >
        {children}
      </BrowserRouter>
    </React.Fragment>
  );
};

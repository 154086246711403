import { Reducer } from 'redux';

import { IMainModulesActions } from 'constants/actionTypes';
import { MAIN_MODULE, MODULES_ACTIONS } from 'constants/enums';
import { IMainModulesStore } from 'constants/storeTypes';

import { mockInitialStates } from './../mockInitialStates';

export const initialState: IMainModulesStore = {
  [MAIN_MODULE.CASHFLOW]: {
    module: MAIN_MODULE.CASHFLOW,
    currentStepIndex: null,
  },
  [MAIN_MODULE.WEALTH]: {
    module: MAIN_MODULE.WEALTH,
    currentStepIndex: null,
  },
  [MAIN_MODULE.SET_SAVING_TARGETS]: {
    module: MAIN_MODULE.SET_SAVING_TARGETS,
    currentStepIndex: null,
  },
  [MAIN_MODULE.BUDGET_CALCULATOR]: {
    module: MAIN_MODULE.BUDGET_CALCULATOR,
    currentStepIndex: null,
  },
};

export const modulesReducer: Reducer<IMainModulesStore, IMainModulesActions> = (
  state: IMainModulesStore = process.env.REACT_APP_DEPLOYMENT_ENV &&
  ['development', 'staging'].includes(process.env.REACT_APP_DEPLOYMENT_ENV) &&
  mockInitialStates.modules !== null
    ? mockInitialStates.modules
    : initialState,
  action: IMainModulesActions
) => {
  switch (action.type) {
    case MODULES_ACTIONS.SET_CURRENT_STEP_INDEX:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          currentStepIndex: action.currentStepIndex,
        },
      };

    case MODULES_ACTIONS.SET_LAST_UPDATED_DATE:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          lastUpdatedDate: action.lastUpdatedDate,
        },
      };

    case MODULES_ACTIONS.SET_LAST_COMPLETED_DATE:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          lastCompletedDate: action.lastCompletedDate,
        },
      };

    default:
      return state;
  }
};

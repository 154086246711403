import * as React from 'react';
import styled from 'styled-components';

import { IModuleDetails } from 'constants/storeTypes';

import { ModuleStepCard } from './ModuleStepCard';

const STEPS_ROW_GAP = '16px';

const ModuleStepsWrapper = styled.div`
  margin-top: 24px;
  position: relative;
  display: grid;
  grid-auto-rows: 1fr;
  row-gap: ${STEPS_ROW_GAP};
`;

interface IModuleStepsProps {
  chosenModule: IModuleDetails;
  wasModuleCompleted: boolean;
  currentStepIndex: number | null;
}

type IProps = IModuleStepsProps;

export const ModuleSteps = (props: IProps) => {
  const { chosenModule, wasModuleCompleted, currentStepIndex } = props;
  const stepPageRoutes = chosenModule && chosenModule.subRoutes.filter((s) => s.isStepPage);
  const totalSteps = stepPageRoutes.filter((s) => !s.isUpcoming).length;

  return (
    <ModuleStepsWrapper>
      {stepPageRoutes &&
        stepPageRoutes.map(({ name, subPath, isAllowed, isUpcoming, onClick }, i) => {
          return (
            <React.Fragment key={name}>
              <ModuleStepCard
                stepName={name || ''}
                stepNumber={i + 1}
                stepsGap={STEPS_ROW_GAP}
                totalSteps={totalSteps}
                route={`${chosenModule.route}/${subPath}`}
                disabled={!isAllowed}
                isUpcoming={isUpcoming}
                onClick={onClick}
                completed={
                  wasModuleCompleted || (currentStepIndex !== null && currentStepIndex > i)
                }
              />
            </React.Fragment>
          );
        })}
    </ModuleStepsWrapper>
  );
};

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { addDefaultErrorMessage } from 'redux/messages/actions';

import { Button, DateInputField, Heading, Section } from 'components/Common';
import { colorScale, typography } from 'themes';

const StyledSubHeading = styled(Heading)`
  ${typography('heading-m')};
`;

const Content = styled.p`
  ${typography('body-l')}
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
`;

const Label = styled.div`
  color: ${colorScale('grey', 80)};
  margin-bottom: 8px;
  ${typography('body-s-semibold')}
`;

interface IProps {
  onSubmit: (date: Date) => void;
}

export const SetInvestmentRemindersModalContent = ({ onSubmit }: IProps) => {
  const dispatch = useDispatch();

  const [dateOfReminder, setDateOfReminder] = useState<Date>(new Date());

  const handleSubmit = async () => {
    try {
      onSubmit(dateOfReminder);
    } catch (error) {
      dispatch(addDefaultErrorMessage(error));
    }
  };

  return (
    <Section>
      <StyledSubHeading>Set a calendar reminder that repeats every 6 months</StyledSubHeading>
      <Content>Choose the date for your first reminder.</Content>
      <Label>Date</Label>
      <DateInputField label="Date" onInputChange={setDateOfReminder} date={dateOfReminder} />
      <StyledButton variant="primary" widthStyle="fluid" onClick={handleSubmit}>
        Done
      </StyledButton>
    </Section>
  );
};

import React from 'react';
import styled from 'styled-components';

import { formatCurrency } from 'utilities/currencyUtilities';

const Wrapper = styled.p`
  margin: 0;
  text-align: left;
  display: flex;
  line-height: 1;
`;

const NegativeSpan = styled.span`
  margin-right: 0.1em;
`;

const AmountSpan = styled.span`
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DollarSign = styled.span`
  font-size: 0.5em;
  display: inline-block;
  margin-right: 0.2em;
  line-height: 1.3;

  ::before {
    content: 'SGD';
  }
`;

const DecimalSpan = styled.span`
  font-size: 0.5em;
  line-height: 1.3;
  align-self: flex-end;
`;

interface IProps {
  fullAmount: number;
  showDecimal: boolean;
}

export class AmountWithFlair extends React.Component<IProps> {
  public static defaultProps = {
    showDecimal: true,
  };

  public render() {
    const { fullAmount, showDecimal, ...otherProps } = this.props;
    const isNegative = Math.sign(fullAmount) === -1;
    const [dollarsPortion, centsPortion] = formatCurrency(
      Math.abs(fullAmount),
      2,
      'SGD',
      'decimal'
    ).split('.');

    return (
      <Wrapper {...otherProps}>
        {isNegative && <NegativeSpan>-</NegativeSpan>}
        <DollarSign />
        <AmountSpan>
          {dollarsPortion}
          {showDecimal && <DecimalSpan>.{centsPortion}</DecimalSpan>}
        </AmountSpan>
      </Wrapper>
    );
  }
}

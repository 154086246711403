import React from 'react';
import styled from 'styled-components';

import { Open } from 'assets/icons';
import { InfoModal, IInfoModalProps } from 'components/Common';
import { ASSET_TYPE } from 'constants/enums';
import { typeScale } from 'themes';

const StyledLink = styled.a`
  margin-top: 8px;
  font-size: ${typeScale(0)};
`;

const StyledOpen = styled(Open)`
  margin-left: 4px;
  vertical-align: middle;
`;

interface IOwnProps {
  assetType: ASSET_TYPE;
}

export type IApimMarketPriceInfoModalProps = IOwnProps &
  Omit<IInfoModalProps, 'children' | 'title' | 'titleText'>;

export const ApimMarketPriceInfoModal: React.FC<IApimMarketPriceInfoModalProps> = ({
  mounted,
  onExit,
  assetType,
}) => {
  const contents = {
    copy: 'Get the latest market price for your investments from the ',
    linkText: 'Singapore Exchange',
    link: 'https://www.sgx.com/securities/securities-prices?code=all',
  };

  switch (assetType) {
    case ASSET_TYPE.BOND:
      contents.link = 'https://www.sgx.com/fixed-income/retail-fixed-income-securities';
      break;
    case ASSET_TYPE.UNIT_TRUST:
      contents.link = 'http://www.fundsingapore.com/screener/basic_search';
      contents.linkText = 'Investment Management Association of Singapore';
      break;
    default:
      break;
  }

  return (
    <InfoModal
      titleText="Market price per unit info modal"
      title="Market price per unit"
      mounted={mounted}
      onExit={onExit}
      data-testid="investment-market-price-info-modal"
    >
      {contents.copy}
      <StyledLink href={contents.link} target="_blank" rel="noopener noreferrer">
        {contents.linkText}
        <StyledOpen />
      </StyledLink>
    </InfoModal>
  );
};

import {
  PLAN_TYPE,
  PLAN_COLOR,
  FLAT_TYPE,
  FLAT_SIZE,
  LOAN_TYPE,
  HOUSEHOLD_TYPE,
  PAYMENT_METHOD,
  ARTICLE_TYPES,
} from 'constants/enums';
import { INITIAL_DRAFT_HOUSING_PLAN_ID } from 'constants/housingConstants';
import { IHousingPlan } from 'constants/storeTypes';

export const mockDefaultPlan: IHousingPlan = {
  id: 'h13n92ei-c2f1-4cef-bb2d-4f723ca6f90d',
  isPlanCompleted: true,
  planType: PLAN_TYPE.DEFAULT,
  planStep: 1,
  planName: 'Recommended plan',
  planColor: PLAN_COLOR.PURPLE,
  firstTimeBuyer: true,
  householdType: HOUSEHOLD_TYPE.FAMILY,
  noOfBuyers: 2,
  monthlyHouseholdIncome: 800000,
  cpfOABalance: 5000000,
  cashSavings: 1000000,
  estimatedFlatBudget: 2222222,
  flatValueAtTimeOfPurchase: 555555,
  flatType: FLAT_TYPE.NEW,
  flatSize: FLAT_SIZE.FIVE_ROOM,
  loanType: LOAN_TYPE.HDB,
  upfrontPaymentInCash: 123123,
  cpfHousingGrant: 80000,
  enhancedCpfHousingGrant: 55555,
  proximityGrant: 30000,
  interestRate: 2.6,
  loanPeriod: 25,
  cpfMonthlyInstalment: 1000,
  buyerStampDuty: {
    amount: 1127549,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  optionFee: {
    amount: 200000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  conveyancingFee: {
    amount: 6484,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  propertyAgentCommission: {
    amount: 0,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  registrationFee: {
    amount: 0,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  surveyFee: {
    amount: 29425,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  leaseInEscrowFee: {
    amount: 3830,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  mortgageInEscrowFee: {
    amount: 3830,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  stampDutyDeedFee: {
    amount: 50000,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  renovationCost: {
    amount: 4400000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  serviceAndConservancyCharges: {
    amount: 6300,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  annualPropertyTax: {
    amount: 6400,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  mortgageInsurancePremiums: {
    amount: 4032000,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  lastUpdatedAt: new Date(2021, 6, 14).toISOString(),
};

export const mockCustomPlan: IHousingPlan = {
  id: '7deb5192-c2f1-4cef-bb2d-4f723ca6f90d',
  isPlanCompleted: true,
  planType: PLAN_TYPE.CUSTOM,
  planStep: 1,
  planName: 'My first housing plan',
  planColor: PLAN_COLOR.RED,
  firstTimeBuyer: true,
  householdType: HOUSEHOLD_TYPE.SINGLE,
  noOfBuyers: 1,
  monthlyHouseholdIncome: 10000,
  cpfOABalance: 123123,
  cashSavings: 4544444,
  estimatedFlatBudget: 2222222,
  flatValueAtTimeOfPurchase: 555555,
  flatType: FLAT_TYPE.NEW,
  flatSize: FLAT_SIZE.FIVE_ROOM,
  loanType: LOAN_TYPE.HDB,
  upfrontPaymentInCash: 123123,
  cpfHousingGrant: 80000,
  enhancedCpfHousingGrant: 55555,
  proximityGrant: 30000,
  interestRate: 20,
  loanPeriod: 10,
  cpfMonthlyInstalment: 1000,
  buyerStampDuty: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  optionFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  conveyancingFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  propertyAgentCommission: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  registrationFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  surveyFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  leaseInEscrowFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  mortgageInEscrowFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  stampDutyDeedFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  renovationCost: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  serviceAndConservancyCharges: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  annualPropertyTax: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  mortgageInsurancePremiums: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  lastUpdatedAt: new Date(2021, 6, 14).toISOString(),
};

export const mockHousingPlans: Record<string, IHousingPlan> = {
  [mockDefaultPlan.id]: mockDefaultPlan,
  [mockCustomPlan.id]: mockCustomPlan,
};

export const mockDraftHousingPlan: IHousingPlan = {
  id: INITIAL_DRAFT_HOUSING_PLAN_ID,
  isPlanCompleted: true,
  planType: PLAN_TYPE.CUSTOM,
  planStep: 1,
  planName: 'My first housing plan',
  planColor: PLAN_COLOR.RED,
  firstTimeBuyer: true,
  householdType: HOUSEHOLD_TYPE.SINGLE,
  noOfBuyers: 1,
  monthlyHouseholdIncome: 10000,
  cpfOABalance: 123123,
  cashSavings: 4544444,
  estimatedFlatBudget: 2222222,
  flatValueAtTimeOfPurchase: 555555,
  flatType: FLAT_TYPE.NEW,
  flatSize: FLAT_SIZE.FIVE_ROOM,
  loanType: LOAN_TYPE.HDB,
  upfrontPaymentInCash: 123123,
  cpfHousingGrant: 80000,
  enhancedCpfHousingGrant: 55555,
  proximityGrant: 30000,
  interestRate: 20,
  loanPeriod: 10,
  cpfMonthlyInstalment: 1000,
  buyerStampDuty: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  optionFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  conveyancingFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  propertyAgentCommission: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  registrationFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  surveyFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  leaseInEscrowFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  mortgageInEscrowFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  stampDutyDeedFee: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  renovationCost: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  serviceAndConservancyCharges: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  annualPropertyTax: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  mortgageInsurancePremiums: {
    amount: 10000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  lastUpdatedAt: new Date(2021, 6, 14).toISOString(),
};

export const mockArticlesCompleted = [ARTICLE_TYPES.CASH_OR_CPF, ARTICLE_TYPES.HDB_OR_BANK_LOAN];

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { ValidationForm, ValidationButton, IButtonRenderParams } from 'components/Validations';
import { IGoalForRender } from 'constants/storeTypes';
import { formatCurrency } from 'utilities/currencyUtilities';
import { formatDate } from 'utilities/dateUtilities';

import { AmountField } from '../AmountField/AmountField';
import { Button } from '../Button/Button';
import { Section } from '../SectionHeading/Section';

import {
  FieldLabel,
  FieldWrapper,
  SecondaryTargetButton,
  StyledHeading,
  StyledAmountField,
} from './sharedStyles';

const AmountInputWrapper = styled.div`
  margin-bottom: 24px;
  width: calc(70% - 8px);
`;

const SaveButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`;

const TargetButtonGroup = styled.div`
  display: flex;
`;

const StyledSecondaryTargetButton = styled(SecondaryTargetButton)`
  margin: 24px 16px 0 0;
  width: 100%;
`;

interface IOwnProps {
  goal: IGoalForRender;
  name: string;
  paidOutAmount: number;
  paidOutDate: Date;
  transitionStatus?: TransitionStatus;
  saveToStore: () => void;
  updatePaidOutAmount: (amount: number) => void;
  closePaidOutTargetModal: () => void;
}

type IProps = IOwnProps & RouteComponentProps;

class EditGoalContentPaidOut extends React.Component<IProps> {
  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.transitionStatus === 'entering' && this.props.transitionStatus === 'entered') {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  }

  public render() {
    const {
      goal,
      name,
      paidOutAmount,
      paidOutDate,
      saveToStore,
      updatePaidOutAmount,
      closePaidOutTargetModal,
      history,
    } = this.props;

    return (
      <Section>
        <StyledHeading>Congratulations on completing your target!</StyledHeading>
        <ValidationForm
          onSubmit={async () => {
            await Promise.resolve(saveToStore());
            await Promise.resolve(history.push('/allocate-funds-and-allocation'));
          }}
          disableAfterSubmit={true}
        >
          <FieldWrapper>
            <FieldLabel>Name</FieldLabel>
            {name}
          </FieldWrapper>

          <FieldWrapper>
            <FieldLabel>Date completed</FieldLabel>
            {formatDate(paidOutDate)}
          </FieldWrapper>

          <FieldWrapper>
            <FieldLabel>Savings allocated to the target</FieldLabel>
            {formatCurrency(goal.currentAmount, 0)}
          </FieldWrapper>

          <AmountInputWrapper>
            <StyledAmountField
              label="Amount you've spent on target"
              widthStyle="fluid"
              amount={paidOutAmount}
              required={true}
              minAmount={1}
              onInputChange={updatePaidOutAmount}
              addonBefore={<AmountField.DollarSign />}
            />
          </AmountInputWrapper>
          <TargetButtonGroup>
            <StyledSecondaryTargetButton
              variant="tertiary"
              onClick={closePaidOutTargetModal}
              type="button"
              widthStyle="fluid"
            >
              Back
            </StyledSecondaryTargetButton>
            <ValidationButton
              render={(renderParams: IButtonRenderParams) => (
                <SaveButton {...renderParams}>Save</SaveButton>
              )}
            />
          </TargetButtonGroup>
        </ValidationForm>
      </Section>
    );
  }
}

export const EditGoalContentPaidOutContainer = withRouter(EditGoalContentPaidOut);

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { ITotalDebtServicingRatioInputs } from '../interfaces';

export const TDSR0: IRule<ITotalDebtServicingRatioInputs> = {
  condition: ({ totalDebtServicingRatio }: ITotalDebtServicingRatioInputs) =>
    totalDebtServicingRatio === null,
  result: {
    key: RULE.TDSR0,
    indicatorStatus: indicatorStatuses.UNAVAILABLE,
    recommendedActions: [],
    resources: [],
  },
};

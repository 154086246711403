import React from 'react';
import styled from 'styled-components';

import { IGoalForRender, IEmergencyFundForRender } from 'constants/storeTypes';
import { colorScale, typography } from 'themes';
import { numberWithSeparator } from 'utilities/currencyUtilities';

import { SGDLabel } from './sharedStyles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0 0;
`;

const Text = styled.p`
  ${typography('label')}
  color: ${colorScale('primary', 50)};
  margin: 0 8px 0 0;
`;

const ProgressBarOuterWrapper = styled.div`
  display: flex;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin: auto;
`;

const FullProgressBar = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: ${colorScale('grey', 20)};
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  height: 6px;
  border-radius: 3px;
  background: ${colorScale('primary', 40)};
`;

const TotalAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0 0 auto;
  z-index: 2;
`;

const StyledSGDLabel = styled(SGDLabel)`
  margin: 0 4px;
`;

interface IProps {
  target: IGoalForRender | IEmergencyFundForRender;
}

export const ProgressBarSection: React.FC<IProps> = ({ target }) => {
  const progressFraction = target.currentAmount / target.totalAmount;

  return (
    <Wrapper>
      <ProgressBarOuterWrapper>
        <Text data-testid="progress-percentage">{Math.floor(progressFraction * 100)}%</Text>
        <ProgressBarWrapper>
          <FullProgressBar />
          <ProgressBar style={{ width: `calc(${Math.min(1, progressFraction)} * 100%)` }} />
        </ProgressBarWrapper>
      </ProgressBarOuterWrapper>
      <TotalAmountWrapper data-testid="progress-total">
        out of<StyledSGDLabel>SGD</StyledSGDLabel>
        {numberWithSeparator(target.totalAmount * 0.01, 2)}
      </TotalAmountWrapper>
    </Wrapper>
  );
};

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { ISavingsRatioInputs } from '../interfaces';

export const SR1A: IRule<ISavingsRatioInputs> = {
  condition: ({ emergencyFundMonths, savingsRatio }: ISavingsRatioInputs) =>
    savingsRatio !== null && savingsRatio >= 0.2 && emergencyFundMonths >= 6,
  result: {
    key: RULE.SR1A,
    indicatorStatus: indicatorStatuses.GOOD,
    recommendedActions: [recommendedActions.TRA_SR_B, recommendedActions.TRA_SR_A],
    resources: [
      // Some links
    ],
  },
};

import { RECOMMENDED_ACTION, RECOMMENDED_ACTION_COMPLETER } from 'constants/enums';
import { IRecommendedActionResponse } from 'constants/responseTypes';
import { IRecommendedAction } from 'constants/storeTypes';

export const convertResponseToRecommendedAction = (
  response: IRecommendedActionResponse
): IRecommendedAction => ({
  ...response,
  key: response.key.id as RECOMMENDED_ACTION,
  completedAt: response.completedAt || null,
  completedBy: (response.completedBy?.id as RECOMMENDED_ACTION_COMPLETER) || null,
});

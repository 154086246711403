import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { LogoImage, LogoWhiteImage } from 'assets/Miscellaneous';

const Wrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLogoSVGImage = styled(LogoImage)`
  margin-right: 12px;
  width: 90%;
  align-self: flex-start;
`;

const StyledLogoWhiteImage = styled(LogoWhiteImage)`
  margin-right: 12px;
  width: 90%;
  align-self: flex-start;
`;

interface IOwnProps {
  variant?: 'white' | 'default';
}

type IProps = IOwnProps & HTMLAttributes<HTMLSpanElement>;

export const Logo = ({ variant = 'default', ...otherProps }: IProps) => (
  <Wrapper {...otherProps}>
    {variant === 'white' ? <StyledLogoWhiteImage /> : <StyledLogoSVGImage />}
  </Wrapper>
);

import styled from 'styled-components';

import { DyingFlowerImage } from 'assets/Apim';
import { Open } from 'assets/icons';
import { Section, Heading } from 'components/Common';
import { typography, colorScale } from 'themes';

export const Wrapper = styled(Section)`
  display: grid;
  grid-gap: 16px;
  justify-items: center;
  margin: 24px;
  text-align: center;
  max-width: 512px;
`;

export const Title = styled(Heading)`
  ${typography('heading-m')};
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Description = styled.p`
  ${typography('body-l')};
`;

export const ErrorCodeText = styled.span`
  ${typography('body-m-bold')};
  color: ${colorScale('grey', 80)};
  white-space: pre;
`;

export const StyledLink = styled.a`
  display: inline-block;
`;

export const StyledOpen = styled(Open)`
  margin-left: 2px;
  vertical-align: middle;
`;

export const StyledDyingFlowerImage = styled(DyingFlowerImage)`
  width: 150px;
  height: 150px;
`;

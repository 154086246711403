import axios from 'axios';
import axiosRetry from 'axios-retry';

import { configureMockServerResponses } from './mockServerResponses';
import { handleSessionLogout, LOGOUT_STATUS_TYPES } from './sessionUtilities';

export const configureRequests = () => {
  configureMockServerResponses();

  axiosRetry(axios, {
    retries: 3,
    retryDelay: () => 500,
  });

  axios.interceptors.request.use((request) => {
    if (isBackendURL(request.url)) {
      request.withCredentials = true;
    }
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        const statusCode = error.response.status;

        if (isBackendURL(error.response.config.url)) {
          if (statusCode === 401) {
            // we'll need the session storage values for callback pages
            // to compare state / nonce and etc.
            handleSessionLogout(
              LOGOUT_STATUS_TYPES.ACCESS_TOKEN_EXPIRED,
              !window.location.pathname.includes('callback'),
              false
            );
          } else if (statusCode === 440) {
            handleSessionLogout(LOGOUT_STATUS_TYPES.DUPLICATE_SESSION);
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

const isBackendURL = (url?: string) => {
  return url && url.startsWith(process.env.REACT_APP_API_URL as string);
};

import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { isBrowser } from 'react-device-detect';
import styled, { css } from 'styled-components';

import { PanelWithDeleteIcon, Input, AmountField } from 'components/Common';
import { ValidationContainer, required } from 'components/Validations';
import { TIME_INTERVAL } from 'constants/enums';
import { IExpense } from 'constants/storeTypes';
import { colorScale, maxWidth, minWidth, typeScale } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';

const PanelWrapper = styled.div`
  margin-bottom: 16px;
`;

const StyledPanelWithDeleteIcon = styled(PanelWithDeleteIcon)`
  > button {
    font-size: ${typeScale(3)};
    top: 8px;
    right: 8px;
    color: ${colorScale('grey', 80)};
  }

  ${minWidth(
    'largeScreen',
    css`
      > button {
        top: 0;
        transform: translateY(50%);
        color: ${colorScale('grey', 50)};
      }
    `
  )}
`;

const InputWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    'category monthly-amount'
    '. yearly-amount';


  ${maxWidth(
    'mobile',
    css`
      grid-template-areas:
        'category'
        'monthly-amount'
        'yearly-amount';
    `
  )}
  ${minWidth(
    'tablet',
    css`
      grid-template-columns: repeat(2, 1fr);
    `
  )}

  ${minWidth(
    'desktop',
    css`
      grid-template-columns: repeat(2, minmax(auto, 220px));
    `
  )}
`;

export const PurpleSubtext = styled.div`
  color: ${colorScale('primary', 40)};
  font-weight: 600;
  font-size: ${typeScale(-2)};
  cursor: pointer;
`;

const StyledValidationContainer: typeof ValidationContainer = styled(ValidationContainer)`
  grid-area: category;
`;

const MonthlyAmountField = styled(AmountField)`
  grid-area: monthly-amount;
`;

const YearlyAmountField = styled(AmountField)`
  grid-area: yearly-amount;
`;

export const Subtext = (props: { subtext: string; onClick: () => void; disabled: boolean }) => {
  const { subtext, onClick, disabled } = props;
  return disabled ? null : <PurpleSubtext onClick={onClick}>{subtext}</PurpleSubtext>;
};

interface IExpensePanelProps {
  expense: IExpense;
  onDelete: (id: string) => void;
  onUpdate: (id: string, update: Partial<IExpense>) => void;
  autoFocusOnStart?: boolean;
}

export const ExpensePanel: React.FC<IExpensePanelProps> = ({
  expense,
  onDelete,
  onUpdate,
  autoFocusOnStart,
}) => {
  const { id, amount, category, timeInterval } = expense;
  const isEmptyCategory = isEmpty(category);
  const isEmptyAmount = amount < 1;

  const [showYearlyAmountField, setShowYearlyAmountField] = useState<boolean>(false);
  const [isYearlyAmount, setIsYearlyAmount] = useState<boolean>(
    timeInterval === TIME_INTERVAL.YEARLY
  );
  const [isMonthlyInputFocused, setIsMonthlyInputFocused] = useState<boolean>(false);

  const subtext = isMonthlyInputFocused
    ? 'Switch to yearly'
    : `${formatCurrency(isYearlyAmount ? amount : amount * 12)} per year`;

  const subtextBlur = (v = amount) => !v && setIsYearlyAmount(false);
  const subtextOnClick = () => setShowYearlyAmountField(true);

  return (
    <PanelWrapper id={id} data-testid="expense-panel">
      <StyledPanelWithDeleteIcon onDelete={() => onDelete(id)}>
        <InputWrapper>
          <StyledValidationContainer
            value={category || ''}
            validations={[required]}
            render={(validationsParams) => {
              return (
                <Input
                  widthStyle="fluid"
                  errorMessage={validationsParams.errorMessage}
                  onBlur={validationsParams.onBlur}
                  onFocus={validationsParams.onFocus}
                  value={category || ''}
                  type="text"
                  label="Category"
                  autoFocus={isBrowser && isEmptyCategory && isEmptyAmount}
                  handleChange={(category) => onUpdate(id, { category })}
                />
              );
            }}
          />
          <MonthlyAmountField
            label="Monthly amount"
            widthStyle="fluid"
            amount={amount === 0 ? null : Math.round(amount / timeInterval)}
            required={true}
            onFocus={() => setIsMonthlyInputFocused(true)}
            autoFocus={
              isBrowser &&
              ((autoFocusOnStart && !isEmptyCategory) || // for existing panel autofocus
                (!isEmptyCategory && isEmptyAmount)) // new panel autofocus
            }
            onInputChange={(value) => {
              setIsYearlyAmount(false);
              onUpdate(id, {
                amount: value,
                timeInterval: TIME_INTERVAL.MONTHLY,
              });
            }}
            addonBefore={<AmountField.DollarSign />}
            subtext={
              <Subtext
                onClick={subtextOnClick}
                subtext={subtext}
                disabled={showYearlyAmountField}
              />
            }
            minAmount={0}
            minAmountInclusive={false}
            maxAllowableAmount={99999999}
            onBlur={(e) => {
              setIsMonthlyInputFocused(false);
              subtextBlur(Number(e.target.value));
            }}
            disabled={showYearlyAmountField}
          />
          {showYearlyAmountField && (
            <YearlyAmountField
              label="Yearly amount"
              widthStyle="fluid"
              amount={amount === 0 || !isYearlyAmount ? null : amount}
              required={true}
              autoFocus={showYearlyAmountField}
              onInputChange={(value) => {
                setIsYearlyAmount(true);
                onUpdate(id, { amount: value, timeInterval: TIME_INTERVAL.YEARLY });
              }}
              addonBefore={<AmountField.DollarSign />}
              minAmount={0}
              minAmountInclusive={false}
              maxAllowableAmount={99999999}
              onBlur={(e) => {
                setShowYearlyAmountField(false);
                subtextBlur(Number(e.target.value));
              }}
            />
          )}
        </InputWrapper>
      </StyledPanelWithDeleteIcon>
    </PanelWrapper>
  );
};

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getFxRates } from 'redux/fxRates/selectors';

import { formatCurrency, convertToSGD } from 'utilities/currencyUtilities';

import { AmountField, IAmountFieldProps } from '../AmountField/AmountField';

import { CurrencyDropdown } from './CurrencyDropdown';

interface IOwnProps {
  onChangeCurrency: (currency: string) => void;
  selectedCurrency: string;
}

type IProps = IAmountFieldProps & IOwnProps;

export const MAX_AMOUNT_IN_SGD_CENTS = 300000000000;

export const CurrencyInputField = ({
  onChangeCurrency,
  selectedCurrency,
  amount,
  maxAllowableAmount = MAX_AMOUNT_IN_SGD_CENTS,
  ...inputProps
}: IProps) => {
  const fxRate = useSelector(getFxRates)[selectedCurrency][0].exchangeRate || 1;
  const maxAllowableAmountForCurrency = maxAllowableAmount / fxRate;

  const equivalentText = useMemo(
    () =>
      selectedCurrency === 'SGD' || amount === null
        ? ''
        : `${formatCurrency(
            convertToSGD(Math.min(maxAllowableAmountForCurrency, amount), selectedCurrency)
          )}`,
    [amount, maxAllowableAmountForCurrency, selectedCurrency]
  );

  return (
    <AmountField
      {...inputProps}
      amount={amount}
      collapseLabel={false}
      maxAllowableAmount={maxAllowableAmountForCurrency}
      externalAddonBefore={
        <CurrencyDropdown selectedCurrency={selectedCurrency} onChange={onChangeCurrency} />
      }
      subtext={equivalentText}
    />
  );
};

import React from 'react';

import { WarningBanner } from 'components/Common';
import { formatCurrency } from 'utilities/currencyUtilities';

interface IProps {
  currentAllocatedAmount: number;
  excessAmount: number;
}

export const OverAllocatedBanner: React.FC<IProps> = ({
  currentAllocatedAmount,
  excessAmount,
}: IProps) => (
  <WarningBanner
    text={
      <React.Fragment>
        {formatCurrency(currentAllocatedAmount)} is currently allocated to this target. The excess{' '}
        {formatCurrency(excessAmount)} will go into your unallocated savings.
      </React.Fragment>
    }
  />
);

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme as fromTheme } from 'styled-tools';

import { ArrowDown } from 'assets/icons';
import { colorScale, typeScale, shadowScale } from 'themes';
import { minWidth } from 'themes';

const Wrapper = styled.div`
  position: relative;
  padding: 16px 0;
  pointer-events: visible;
`;

const StyledDiv = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: ${fromTheme('heights.navbar')};
  font-size: ${typeScale(0)};
  font-weight: 500;
  position: relative;

  ${ifProp(
    'isActive',
    css`
      &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 5px;
        background: ${colorScale('primary', 30)};
      }
    `
  )}

  ${minWidth(
    'largeScreen',
    css`
      padding: 0 16px;
    `
  )}

  &:hover {
    color: ${colorScale('primary', 50)};
  }
`;

const Text = styled.div<{ text: string; isActive: boolean }>`
  &::before {
    display: block;
    font-weight: bold;
    content: attr(text);
    overflow: hidden;
    visibility: hidden;
  }

  ${ifProp(
    'isActive',
    css`
      color: ${colorScale('primary', 50)};
      font-weight: bold;
    `
  )}
`;

const StyledArrowDown = styled(ArrowDown)`
  color: ${colorScale('grey', 80)};
  margin-left: 8px;
`;

const DropWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  position: absolute;
  left: 16px;
  width: max-content;
  transition: ${fromTheme('transitions.durationLonger')} ease-in-out;
  clip-path: inset(0 -100% -100% -100%);
  z-index: 1;

  ${ifProp(
    'isOpen',
    css`
      opacity: 1;
      visibility: visible;
    `,
    css`
      visibility: hidden;
      opacity: 0;
    `
  )};
`;

const ListWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  width: max-content;
  transition: ${fromTheme('transitions.durationLonger')} ease-in-out;
  width: 100%;
  background-color: white;
  box-shadow: ${shadowScale(2)};

  ${ifProp(
    'isOpen',
    css`
      visibility: visible;
      transform: translateY(0);
    `,
    css`
      visibility: hidden;
      transform: translateY(-100%);
    `
  )};
`;

interface IBaseProps {
  isChildActive: boolean;
  children?: React.ReactNode;
}

type IDropdownProps = IBaseProps &
  (
    | {
        label: string;
        icon?: never;
      }
    | {
        label?: never;
        icon: React.ReactNode;
      }
  );

export const Dropdown = ({ label, icon, isChildActive, children }: IDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const setIsOpenTrue = () => setIsOpen(true);
  const setIsOpenFalse = () => setIsOpen(false);

  return (
    <Wrapper onMouseOver={setIsOpenTrue} onMouseLeave={setIsOpenFalse}>
      <StyledDiv isActive={isChildActive && !isOpen}>
        {label ? (
          <Text text={label} isActive={isChildActive}>
            {label}
          </Text>
        ) : (
          icon
        )}
        <StyledArrowDown />
      </StyledDiv>
      <DropWrapper isOpen={isOpen}>
        <ListWrapper isOpen={isOpen}>{children}</ListWrapper>
      </DropWrapper>
    </Wrapper>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const Wrapper = styled.div`
  overflow: hidden;
  padding: 8px;
`;

const SlideWrapper = styled.div<{
  numOfItems: number;
  isSliding: boolean;
  transitionDuration: number;
}>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(${prop('numOfItems')}, 100%);
  grid-gap: 16px;
  width: 100%;

  ${ifProp(
    'isSliding',
    css`
      transition: ${prop('transitionDuration')}ms ease-in-out;
      transform: translateX(calc(-100% - 16px));
    `,
    css`
      transition: none;
      transform: translateX(0);
    `
  )}
`;

const ElementWrapper = styled.div`
  min-width: 100%;
  text-align: center;
`;

interface IProps {
  /* Transition duration in milliseconds */
  transitionDuration?: number;

  /* Transition interval in milliseconds */
  transitionInterval?: number;

  children: React.ReactNode;
}

export const AnimatedCarousel = ({
  transitionDuration = 500,
  transitionInterval = 4500,
  children,
}: IProps) => {
  const childrenArray = React.Children.toArray(children);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [isSliding, setIsSliding] = useState(false);

  let slideLeftTimeout: NodeJS.Timeout;
  const slideLeft = useCallback(() => {
    setIsSliding(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    slideLeftTimeout = setTimeout(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % childrenArray.length);
      setIsSliding(false);
    }, transitionDuration);
  }, [childrenArray.length, transitionDuration]);

  useEffect(() => {
    const intervalFunction = setInterval(slideLeft, transitionInterval);
    return () => {
      clearTimeout(intervalFunction);
      clearTimeout(slideLeftTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideLeft, transitionInterval]);

  return (
    <Wrapper>
      <SlideWrapper
        numOfItems={childrenArray.length}
        isSliding={isSliding && childrenArray.length > 1}
        transitionDuration={transitionDuration}
      >
        {childrenArray.map((_child, i) => (
          <ElementWrapper key={i}>
            {childrenArray[(currentIndex + i) % childrenArray.length]}
          </ElementWrapper>
        ))}
      </SlideWrapper>
    </Wrapper>
  );
};

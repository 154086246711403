import React from 'react';
import styled, { css } from 'styled-components';

import { FlowersLeftImage } from 'assets/Templates';
import { FlowersRightImage } from 'assets/Templates';
import { ButterflyImage } from 'assets/Templates';
import { WaveBackgroundImage } from 'assets/Templates';
import { maxWidth, layers } from 'themes';

import { TEMPLATE_BACKGROUND_ID } from './constants';

const backgroundStyles = css`
  position: absolute;
  bottom: 0;
  z-index: ${layers.templateBackgroundFront};
`;

const flowerStyles = css`
  ${maxWidth(
    'tablet',
    css`
      max-height: 130px;
    `
  )}
`;

const StyledButterflyImage = styled(ButterflyImage)`
  ${backgroundStyles}
  right: 48px;

  ${maxWidth(
    'tablet',
    css`
      max-width: 180px;
    `
  )}
`;

const StyledFlowersLeftImage = styled(FlowersLeftImage)`
  ${backgroundStyles}
  ${flowerStyles}
  left: 0;
`;

const StyledFlowersRightImage = styled(FlowersRightImage)`
  ${backgroundStyles}
  ${flowerStyles}
  right: 0;
`;

const StyledWaveBackgroundImage = styled(WaveBackgroundImage)`
  ${backgroundStyles}
  width: 100vw;
  max-height: 160px;
`;

export const FlowerFieldMonochromatic = () => {
  return (
    <span id={TEMPLATE_BACKGROUND_ID}>
      <StyledWaveBackgroundImage />
      <StyledFlowersLeftImage />
      <StyledFlowersRightImage />
      <StyledButterflyImage />
    </span>
  );
};

import styled from 'styled-components';

import { colorScale, typography } from 'themes';

import { Panel } from '../Panel/Panel';

export const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const BaseWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  flex-grow: 1;
`;

export const DateWrapper = styled.div`
  width: 72px;
  margin-right: 20px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  ${typography('heading-s')};
  color: ${colorScale('grey', 90)};
  position: relative;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: -24px;
    bottom: -24px;
    width: 1px;
    background-color: ${colorScale('grey', 20)};
  }
`;

export const Year = styled.div`
  ${typography('label')};
`;

export const SGDLabel = styled.span`
  ${typography('label')}
  margin-right: 4px;
`;

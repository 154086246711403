import React from 'react';
import styled from 'styled-components';

import {
  RetirementSumSchemeMobileImage,
  RetirementSumSchemeTabletImage,
  FullRetirementSumMobileImage,
  FullRetirementSumTabletImage,
  EnhancedRetirementSumMobileImage,
  EnhancedRetirementSumTabletImage,
  FinancialPlanningMobileImage,
  FinancialPlanningTabletImage,
} from 'assets/Retirement/CloseSavingsGapArticle';
import { CurrencyPrefix } from 'components/Common';
import {
  ArticleHyperlink,
  ArticleParagraph,
  ArticleUnorderedList,
  ArticleListItem,
  ArticleImage,
  renderResponsiveGraphImages,
} from 'components/Templates';

const StyledArticleImage = styled(ArticleImage)`
  max-width: 420px;
`;

export const BuildCPFSavings = () => {
  return (
    <>
      <ArticleParagraph>
        The more you have in your CPF accounts, the more income you can have when you retire; and
        the earlier you top up, the more interest you will receive. You can build up your CPF
        through:
      </ArticleParagraph>

      <ArticleUnorderedList>
        <ArticleListItem>
          Cash top-ups to your Special Account (SA) or Retirement Account (RA) under the{' '}
          <ArticleHyperlink url="https://www.cpf.gov.sg/member/faq/growing-your-savings/retirement-sum-topping-up-scheme">
            Retirement Sum Topping Up Scheme
          </ArticleHyperlink>
          . You can also enjoy{' '}
          <ArticleHyperlink url="https://www.cpf.gov.sg/member/faq/growing-your-savings/retirement-sum-topping-up-scheme/what-are-the-conditions-for-me-to-enjoy-tax-relief-">
            tax relief
          </ArticleHyperlink>{' '}
          of up to <CurrencyPrefix>8,000</CurrencyPrefix> per calendar year on cash top-ups made for
          yourself. If you are eligible for the{' '}
          <ArticleHyperlink url="https://www.cpf.gov.sg/member/growing-your-savings/saving-more-with-cpf/matching-grant-for-seniors-who-top-up">
            Matched Retirement Savings Scheme
          </ArticleHyperlink>
          , you can receive a dollar-for-dollar matching grant of up to{' '}
          <CurrencyPrefix>600</CurrencyPrefix> per year from the government for cash top-ups
          received in your RA.
          {renderResponsiveGraphImages(
            <StyledArticleImage image={<RetirementSumSchemeMobileImage />} />,
            <StyledArticleImage image={<RetirementSumSchemeTabletImage />} />,
            <StyledArticleImage image={<RetirementSumSchemeTabletImage />} />
          )}
        </ArticleListItem>

        <ArticleListItem>
          Alternatively, you can transfer the savings from your Ordinary Account (OA) to your SA (if
          you are below age 55) up to the Full Retirement Sum (FRS) or transfer the savings from
          your SA and OA to your RA (if you are aged 55 and above), up to the Enhanced Retirement
          Sum (ERS).
          {renderResponsiveGraphImages(
            <StyledArticleImage image={<FullRetirementSumMobileImage />} />,
            <StyledArticleImage image={<FullRetirementSumTabletImage />} />,
            <StyledArticleImage image={<FullRetirementSumTabletImage />} />
          )}
          {renderResponsiveGraphImages(
            <StyledArticleImage image={<EnhancedRetirementSumMobileImage />} />,
            <StyledArticleImage image={<EnhancedRetirementSumTabletImage />} />,
            <StyledArticleImage image={<EnhancedRetirementSumTabletImage />} />
          )}
        </ArticleListItem>

        <ArticleListItem>
          Before you perform any cash top-ups and/or transfers to your CPF accounts, it is important
          to plan your finances first because you would not be able to reverse the top-ups and
          transfers.
          {renderResponsiveGraphImages(
            <StyledArticleImage image={<FinancialPlanningMobileImage />} />,
            <StyledArticleImage image={<FinancialPlanningTabletImage />} />,
            <StyledArticleImage image={<FinancialPlanningTabletImage />} />
          )}
        </ArticleListItem>
      </ArticleUnorderedList>
    </>
  );
};

import DOMPurify from 'dompurify';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Caution } from 'assets/icons';
import { colorScale } from 'themes';

import { useMaintenanceSchedules } from '../MaintenanceNoticesPage/useMaintenanceSchedules';
import { ContentWrapper, MessageContainer } from '../sharedStyles';

import { StyledArrowRight, iconStyles } from './sharedStyles';

const Wrapper = styled.div`
  background: ${colorScale('secondary', 10)};
  cursor: pointer;
`;

const StyledContentWrapper = styled(ContentWrapper)`
  color: ${colorScale('secondary', 50)};
  gap: 16px;
`;

const StyledCaution = styled(Caution)`
  ${iconStyles}
`;

const StyledMessageContainer = styled(MessageContainer)`
  p {
    margin: 0;
  }
`;

export const MaintenanceBanner = () => {
  const history = useHistory();
  const maintenanceSchedules = useMaintenanceSchedules();
  const maintenanceSchedulesToShow = maintenanceSchedules.getMaintenanceSchedulesToShowOnBanner();
  const maintenanceSchedulesToShowLength = maintenanceSchedulesToShow.length;
  const hasPullError = maintenanceSchedules.hasPullError;

  if (!maintenanceSchedulesToShowLength && !hasPullError) {
    return null;
  }

  return (
    <Wrapper onClick={() => history.push('/maintenance-notices')}>
      <StyledContentWrapper>
        <StyledCaution />
        <StyledMessageContainer>
          {hasPullError &&
            'Failed to retrieve upcoming maintenance details. Please report this issue to the team.'}
          {maintenanceSchedulesToShowLength === 1 && (
            <p
              key={maintenanceSchedulesToShow[0].landingPageBannerMessage}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(maintenanceSchedulesToShow[0].landingPageBannerMessage),
              }}
            />
          )}
          {maintenanceSchedulesToShowLength === 2 && (
            <>
              Maintenance schedule:
              {maintenanceSchedulesToShow.map(({ agencies, from, to }) => {
                const startDateTime = moment(from);
                const startTimeStr = startDateTime.format('hh:mma');

                const endDateTime = moment(to);
                const endTimeStr = endDateTime.format('hh:mma');

                const startDateStr = startDateTime.format('DD MMM YYYY');
                const endDateStr = endDateTime.format('DD MMM YYYY');

                const agencyList = agencies.join(', ').replace(/,(?=[^,]*$)/, ' and ');

                return (
                  <p key={`${agencyList}${from}${to}`}>
                    <b>{agencyList}</b> -{' '}
                    {startDateStr === endDateStr ? (
                      <>
                        {startDateStr} {startTimeStr} to {endTimeStr}
                      </>
                    ) : (
                      <>
                        {startDateStr} {startTimeStr} to {endDateStr} {endTimeStr}
                      </>
                    )}
                  </p>
                );
              })}
            </>
          )}
          {maintenanceSchedulesToShowLength > 2 && (
            <span>
              <b>
                {maintenanceSchedulesToShow
                  .reduce<string[]>((acc, curr) => acc.concat(curr.agencies), [])
                  .join(', ')
                  .replace(/,(?=[^,]*$)/, ' and ')}
              </b>
              &nbsp; will be under maintenance and will not be available.
            </span>
          )}
        </StyledMessageContainer>
        <StyledArrowRight />
      </StyledContentWrapper>
    </Wrapper>
  );
};

import React, { useState } from 'react';

import {
  ValidationContainerRenderParams,
  maximumAmount,
  minimumAmount,
  required as requiredValidationRule,
  ValidationContainer,
  IValidationType,
} from 'components/Validations';

import { IInputProps, Input } from '../Input/Input';

interface IOwnProps {
  keyname?: string;

  numberValue: number | null;

  allowedDecimalPlaces?: number;

  /** This value will be used for validations and will not affect the user typing in any way */
  minAmount?: number;
  minAmountInclusive?: boolean;

  /** This value will be used for validations and will not affect the user typing in any way */
  maxAmount?: number;
  maxAmountInclusive?: boolean;

  /** This value will not be used for validations and will stop the user from typing values greater than this */
  maxAllowableAmount?: number;

  onInputChange: (value: number) => void;
  required?: boolean;
  renderBefore?: (renderParams: ValidationContainerRenderParams) => React.ReactElement;
  renderAfter?: (renderParams: ValidationContainerRenderParams) => React.ReactElement;

  customValidations?: IValidationType[];
}

export type INumberFieldProps = IInputProps & IOwnProps;

export const NumberField: React.FC<INumberFieldProps> = ({
  keyname,
  numberValue,
  allowedDecimalPlaces = 0,
  onInputChange,
  required,
  renderBefore,
  renderAfter,
  customValidations,
  minAmount = -Infinity,
  maxAmount = Infinity,
  minAmountInclusive = true,
  maxAmountInclusive = true,
  maxAllowableAmount = 999999999,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  const [trailingCharacters, setTrailingCharacters] = useState('');

  function handleAmountChange(amount: string) {
    onInputChange(Number(amount));
  }

  function filterInput(value: string) {
    const newValue = Number(value);

    const allowedTrailingCharacters = Array(allowedDecimalPlaces + 1)
      .fill(0)
      .map((_, i) => `.${'0'.repeat(i)}`);
    const trailingCharacters = allowedTrailingCharacters.reduce(
      (prevChars, chars) => (value.endsWith(chars) ? chars : prevChars),
      ''
    );
    const [, fractional] = value.split('.');
    const isValidNumber =
      !isNaN(newValue) &&
      (!fractional || fractional.length <= allowedDecimalPlaces) &&
      newValue >= 0;

    if (isValidNumber) {
      setTrailingCharacters(trailingCharacters);
    }

    return isValidNumber
      ? newValue > maxAllowableAmount
        ? `${maxAllowableAmount}`
        : `${newValue}`
      : null;
  }

  function handleAmountBlur(e: React.FocusEvent<HTMLInputElement>) {
    onBlur && onBlur(e);
    setTrailingCharacters('');
  }

  const validations = [
    minimumAmount(minAmount, minAmountInclusive),
    maximumAmount(maxAmount, maxAmountInclusive),
  ];
  if (required) {
    validations.push(requiredValidationRule);
  }

  if (customValidations) {
    validations.push(...customValidations);
  }

  const value = numberValue === null ? '' : `${numberValue}${trailingCharacters}`;

  return (
    <ValidationContainer
      value={value}
      onBlur={handleAmountBlur}
      validations={validations}
      render={(validationsParams) => {
        return (
          <>
            {renderBefore && renderBefore(validationsParams)}
            <Input
              errorMessage={validationsParams.errorMessage}
              onBlur={validationsParams.onBlur}
              onFocus={validationsParams.onFocus}
              value={value}
              wrapperRef={validationsParams.placerRef}
              type="text"
              inputMode="numeric"
              autoComplete="off"
              inputFilter={filterInput}
              handleChange={handleAmountChange}
              {...otherProps}
            />
            {renderAfter && renderAfter(validationsParams)}
          </>
        );
      }}
    />
  );
};

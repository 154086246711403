import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import uuid from 'uuid/v4';

import { ValidationContext } from '../ValidationContext';

export interface ITogglerRenderParams {
  errorMessage?: string;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface IOwnProps {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  render: (renderParams: ITogglerRenderParams) => JSX.Element;
  errorMessage?: string;
  showErrorOnFocus?: boolean;
}

export type IValidationErrorTogglerProps = IOwnProps & React.HTMLAttributes<HTMLInputElement>;

export const ValidationErrorToggler = (prop: IValidationErrorTogglerProps) => {
  const { onBlur, onFocus, render, errorMessage } = prop;

  const messenger = useContext(ValidationContext);
  const keyname = useRef<string>(uuid()).current;
  const [pristine, setPristine] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const displayError = useCallback(() => {
    setPristine(false);
  }, []);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    displayError();
    onBlur && onBlur(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  const showErrorOnBlur = !pristine && errorMessage !== undefined && !isFocused;
  const showErrorOnFocus = prop.showErrorOnFocus && isFocused;

  useEffect(() => {
    messenger && messenger.registerErrorToggler(keyname, { displayError });

    return () => {
      messenger && messenger.unregisterErrorToggler(keyname);
    };
  }, [messenger, keyname, displayError]);

  return render({
    errorMessage: showErrorOnFocus || showErrorOnBlur ? errorMessage : undefined,
    onBlur: handleBlur,
    onFocus: handleFocus,
  });
};

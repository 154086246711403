import React from 'react';
import styled from 'styled-components';

import { AllocateSavingsContent } from './AllocateSavingsContent';

const Wrapper = styled.div`
  padding-top: 48px;
`;

export const AllocateSavingsStandalonePage: React.FC = () => {
  return (
    <Wrapper>
      <AllocateSavingsContent
        backRoute="/targets"
        nextRoute="/targets"
        continueBtnText="Update"
        title={'Update your available funds and allocation'}
        hasSideBar={false}
      />
    </Wrapper>
  );
};

export default AllocateSavingsStandalonePage;

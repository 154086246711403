import React from 'react';
import styled from 'styled-components';

import { AmountWithFlair } from 'components/Common';
import { typeScale } from 'themes';

const Wrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: ${typeScale(-3)};
  font-weight: bold;
`;

const StyledAmountWithFlair = styled(AmountWithFlair)`
  margin-top: 4px;
  font-size: ${typeScale(1)};
`;

const ComparisonBarLegendCircle = styled.div<{ bgColor: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${(props) => props.bgColor};
`;

export interface ILegendProps {
  label?: string;
  amount: number;
  bgColor: string;
}

export const ComparisonBarLegend = ({ label, amount, bgColor }: ILegendProps) => (
  <Wrapper>
    <ComparisonBarLegendCircle bgColor={bgColor} />
    <div>
      {label && <Label>{label}</Label>}
      <StyledAmountWithFlair fullAmount={amount} data-testid="summary-amount" />
    </div>
  </Wrapper>
);

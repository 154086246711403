import React, { useState } from 'react';
import styled from 'styled-components';

import { SwirlImage } from 'assets/LandingPage';
import { Heading, Carousel, TabHeaderGroup } from 'components/Common';
import { typography, colorScale } from 'themes';

import { SectionWrapper, StyledContainer } from '../../sharedStyles';

import { infoText, tabHeaderList } from './constants';
import { DemoCarousel } from './DemoCarousel';

const Wrapper = styled(SectionWrapper)`
  overflow: hidden;
  background: linear-gradient(
    ${colorScale('grey', 10)} 35%,
    ${colorScale('primary', 20)} 85%,
    ${colorScale('grey', 10)} 80%
  );
`;

const InnerWrapper = styled(StyledContainer)`
  display: flex;
  flex-direction: row;
  column-gap: 32px;
`;

const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 50%;
`;

const SwirlWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSwirlImage = styled(SwirlImage)`
  width: fit-content;
`;

const HeadingWrapper = styled.div`
  margin-top: 24px;
`;

const TabHeaderGroupWrapper = styled.div`
  margin-top: 24px;
`;

const StyledHeading = styled(Heading)`
  ${typography('heading-l')};
  color: ${colorScale('primary', 50)};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 90px 24px 64px;
`;

const Text = styled.span`
  ${typography('heading-m')};
  text-align: center;
`;

export const DesktopDemoSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Wrapper>
      <InnerWrapper>
        <SideWrapper>
          <DemoCarousel selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        </SideWrapper>
        <SideWrapper>
          <SwirlWrapper>
            <StyledSwirlImage />
          </SwirlWrapper>
          <HeadingWrapper>
            <StyledHeading>What can you do on MyMoneySense?</StyledHeading>
          </HeadingWrapper>
          <TabHeaderGroupWrapper>
            <TabHeaderGroup
              variant="non-scrollable"
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              tabs={tabHeaderList}
            />
          </TabHeaderGroupWrapper>
          <Carousel
            targetNumberOfItemsToShow={1}
            showDots={false}
            showArrows={false}
            peekAmount={0}
            targetOverflowAmount={0}
            gridGap={0}
            targetSelectedItemIndex={selectedIndex}
            setSelectedItemIndex={setSelectedIndex}
            isControllable={false}
          >
            {infoText.map((text, i) => (
              <TextWrapper key={i}>
                <Text>{text}</Text>
              </TextWrapper>
            ))}
          </Carousel>
        </SideWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

import { IEmergencyFundRatioInputs } from './EmergencyFundRatio/interfaces';
import { IPortfolioDiversificationInputs } from './PortfolioDiversification/interfaces';
import { IRecommendedActionForRender } from './recommendedActions';
import { ISavingsRatioInputs } from './SavingsRatio/interfaces';
import { ITotalDebtServicingRatioInputs } from './TotalDebtServicingRatio/interfaces';

export enum RULE {
  SR0 = 'SR0',
  SR1A = 'SR1A',
  SR1B = 'SR1B',
  SR1C = 'SR1C',
  SR2 = 'SR2',
  SR3 = 'SR3',

  TDSR0 = 'TDSR0',
  TDSR1 = 'TDSR1',
  TDSR2 = 'TDSR2',
  TDSR3 = 'TDSR3',

  IV1 = 'IV1',
  IV2 = 'IV2',
  IV3A = 'IV3A',
  IV3B = 'IV3B',

  EF1A = 'EF1A',
  EF1B = 'EF1B',
  EF2 = 'EF2',
  EF3 = 'EF3',
  EF4 = 'EF4',
  EF5 = 'EF5',
  EF6 = 'EF6',
}

export interface IIndicatorStatus {
  level: number;
  description: string;
}

interface IRecommendedActionStatuses {
  completedRecommendedActionKeys: string[];
}

export type IEngineInputs = IRecommendedActionStatuses &
  ISavingsRatioInputs &
  ITotalDebtServicingRatioInputs &
  IPortfolioDiversificationInputs &
  IEmergencyFundRatioInputs;

export interface IEngineOutputs {
  key: RULE;
  recommendedActions: IRecommendedActionForRender[];
  resources: string[];
  indicatorStatus: IIndicatorStatus;
}

export interface IRule<T> {
  condition: (input: T) => boolean;
  result: IEngineOutputs;
}

import { capitalize } from 'lodash';
import React from 'react';
import MediaQuery from 'react-responsive';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { ArrowLeft } from 'assets/icons';
import { Container, Button } from 'components/Common';
import { colorScale, typeScale, minWidth, ITheme } from 'themes';
import { breakpoints } from 'themes';

const StyledButton = styled(Button)`
  font-weight: normal;
  background: none;
  padding: 0;
  color: ${colorScale('grey', 80)};

  &:hover,
  &:active {
    box-shadow: none;
  }
`;

const StyledSpan = styled.span`
  color: ${colorScale('primary', 50)};
  margin-left: 12px;
`;

const Root = styled(Button)`
  font-weight: normal;
  font-size: ${typeScale(-1)};
  padding: 0;
  color: ${colorScale('grey', 80)};

  &:hover,
  &:active {
    background: initial;
  }

  &:hover {
    color: ${colorScale('primary', 40)};
  }
`;

const Leaf = styled.span`
  font-size: ${typeScale(-1)};
  color: ${colorScale('primary', 50)};

  cursor: default;
`;

const Divider = styled.span`
  margin: 0 12px;
  font-size: ${typeScale(-2)};
  color: ${colorScale('grey', 80)};
`;

const StyledDiv = styled.div<{
  bgDefault?: (props: ITheme) => string;
  bgDesktop?: (props: ITheme) => string;
}>`
${(props) => css`
  ${ifProp(
    'bgDefault',
    css`
      background: ${props.bgDefault};
    `
  )}
  ${ifProp(
    'bgDesktop',
    minWidth(
      'desktop',
      css`
        background: ${props.bgDesktop};
      `
    )
  )}
`}
  border-top: 1px solid ${colorScale('grey', 20)};
  width: 100%;
`;

const StyledContainer = styled(Container)<{ truncate: boolean }>`
  display: block;
  margin: 0 auto;
  ${ifProp(
    'truncate',
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `
  )}
`;

interface IProps {
  backgroundColor?: {
    default?: (props: ITheme) => string;
    desktop?: (props: ITheme) => string;
  };
  crumbs?: {
    urlPath: string;
    label: string;
  }[];
  truncate?: boolean;
}

export const Breadcrumbs = ({ backgroundColor, crumbs = [], truncate = false }: IProps = {}) => {
  const history = useHistory();
  let {
    location: { pathname },
  } = history;

  if (pathname.charAt(pathname.length - 1) === '/') {
    pathname = pathname.slice(0, -1);
  }

  const pathnames = pathname.slice(1).split('/');

  let fullPath = '/';

  // sanity check to return null if no custom breadcrumbs are present and if the pathname is too short to merit breadcrumbs.
  if (pathnames.length <= 1 && crumbs.length === 0) return null;

  if (crumbs.length === 0) {
    pathnames.forEach((partialPath) => {
      fullPath = fullPath + partialPath;
      crumbs.push({
        urlPath: fullPath,
        label: capitalize(partialPath).replace(/-/g, ' '),
      });
      fullPath = fullPath + '/';
    });
  }

  return (
    <StyledDiv
      className={'breadcrumbs'}
      bgDefault={backgroundColor?.default}
      bgDesktop={backgroundColor?.desktop}
    >
      <StyledContainer truncate={truncate}>
        <MediaQuery minWidth={breakpoints.tabletMin}>
          {crumbs.map(({ urlPath, label }, index) =>
            index === crumbs.length - 1 ? (
              <Leaf key={index}>{label}</Leaf>
            ) : (
              <span key={index}>
                <Root
                  onClick={() => {
                    history.push(urlPath);
                  }}
                  variant="tertiary"
                >
                  {label}
                </Root>
                <Divider>/</Divider>
              </span>
            )
          )}
        </MediaQuery>
        <MediaQuery maxWidth={breakpoints.mobileMax}>
          <StyledButton
            onClick={() => {
              history.push(crumbs[crumbs.length - 2].urlPath);
            }}
          >
            <ArrowLeft />
            <StyledSpan>{crumbs[crumbs.length - 2].label}</StyledSpan>
          </StyledButton>
        </MediaQuery>
      </StyledContainer>
    </StyledDiv>
  );
};

import { View, Text } from '@react-pdf/renderer';
import { flatten, partition } from 'lodash';
import React from 'react';

import { BasePage } from '../BasePage/BasePage';
import { ITip } from '../interfaces';
import { margin } from '../styles';

import { TipsPagesStyles as styles } from './TipsPagesStyles';

interface ITipProps {
  tipPoint: string;
}

export interface ITipsPageProps {
  tips: ITip[];
}

// Render single tip consisting of title & points
const TipPoint = ({ tipPoint }: ITipProps) => (
  <Text key={tipPoint} style={styles.tipBody}>
    &nbsp;{tipPoint}
  </Text>
);

const renderTip = (tip: ITip): React.ReactElement => {
  /*
    E.g.
    Title goes here
    ∙ I'm a first level point
    ∙ I'm a first level point
        ∙ I'm a second level point
        ∙ I'm a second level point
    ∙ I'm a first level point
    ∙ I'm a first level point
  */
  const tipPoints = flatten(
    tip.points.map((tipPoint) =>
      tipPoint.split('\n').map(
        (point, idx) =>
          `${
            idx === 0
              ? // if point has no "\t" at the start, first level point
                // if point has "\t" at the start, it's a 2nd level point
                // and render additional spaces first
                tipPoint.startsWith('\t')
                ? '     • '
                : '• '
              : tipPoint.startsWith('\t')
              ? '        '
              : '  '
          }${point.replace('\t', '')}`
      )
    )
  );

  return (
    <View key={tip.title} style={styles.tipContainer}>
      <Text style={styles.tipTitle}>{tip.title}</Text>
      {tipPoints.map((tipPoint) => (
        <TipPoint key={tipPoint} tipPoint={tipPoint} />
      ))}
    </View>
  );
};

// Renders tips by column left or right
// JH: Unfortunately, the wrapping for React-PDF styles
//     isn't able to overflow contents to wrap by column
//     Therefore, without splitting the data up,
//     the contents just continues to a new page.
//     i.e. using flex-flow: column wrap
// Therefore, my resolution is to just manage at the data
// constants since the data items do not change that often anyways
const TipsPage = ({ tips }: ITipsPageProps) => (
  <BasePage title="TIPS TO SAVE MONEY&#10;FOR EACH CATEGORY">
    <View style={styles.tipsContainer}>
      {partition(tips, { column: 'left' }).map((tipsByCol, idx) => {
        if (tipsByCol.length) {
          return (
            <View
              key={tipsByCol[0].column}
              style={[styles.tipsCol, { marginRight: idx === 0 ? margin.large : 0 }]}
            >
              {tipsByCol.map((tip) => renderTip(tip))}
            </View>
          );
        }
        return null;
      })}
    </View>
  </BasePage>
);

// Renders tips by page 1, 2
// JH: Related to "TipsPage"'s comments
export const TipsPages = ({ tips }: ITipsPageProps) => (
  <React.Fragment>
    {partition(tips, { page: 1 }).map((tips) => (
      <TipsPage key={tips[0].page} tips={tips} />
    ))}
  </React.Fragment>
);

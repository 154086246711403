import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router';

import { ONBOARDING } from 'constants/urls';

/**
 * Overrides RouteProps's path because <Redirect /> currently only
 * accepts a single string for its from prop
 */
interface IProtectedRouteProps extends RouteProps {
  isAllowed: boolean;
  path: string;
  redirectTo?: string;
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
  isAllowed,
  path,
  redirectTo = ONBOARDING.baseRoute,
  ...props
}: IProtectedRouteProps) =>
  isAllowed ? (
    <Route key="route" path={path} {...props} />
  ) : (
    <Redirect from={path} to={{ pathname: redirectTo, state: { from: path } }} />
  );

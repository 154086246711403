export enum NOTIFICATION_CATEGORY {
  TODO = 'TODO',
  UPDATES = 'UPDATES',
}

export enum NOTIFICATION_TYPE {
  TODO = 'TODO',
  PERSONAL_UPDATES = 'PERSONAL_UPDATES',
  INBUILT_UPDATES = 'INBUILT_UPDATES',
  GLOBAL_UPDATES = 'GLOBAL_UPDATES',
}

export enum NOTIFICATION {
  UPDATE_BANK_DATA = 'UPDATE_BANK_DATA',
  UPDATE_FUNDS_AND_TARGETS = 'UPDATE_FUNDS_AND_TARGETS',
  GIVE_FEEDBACK = 'GIVE_FEEDBACK',
  READ_ANTI_SCAM_ARTICLE = 'READ_ANTI_SCAM_ARTICLE',
  INCOMPLETE_PROFILE = 'INCOMPLETE_PROFILE',
  INSURANCE_MODULE = 'INSURANCE_MODULE',
  DBS_SYSTEM_UPDATE = 'DBS_SYSTEM_UPDATE',
  TNC_UPDATE = 'TNC_UPDATE',
}

export enum NOTIFICATION_PAGE_MODAL {}

import React from 'react';

import BudgetCalculator from './budget-calculator.svg';
import Bullseye from './bullseye.svg';
import CoinIntoPiggyBank from './coin-into-piggy-bank.svg';
import CoinIntoUpwardGrowthBox from './coin-into-upward-growth-box.svg';
import ManWithTargetsAsDestinationsLongRoad from './man-with-targets-as-destinations-long-road.svg';
import ManWithTargetsAsDestinations from './man-with-targets-as-destinations.svg';
import PiggyBankWalletAndCoins from './piggy-bank-wallet-and-coins.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const BudgetCalculatorImage = (props: ImageProps) => (
  <img {...props} src={BudgetCalculator} alt="Calculator and graph, with paper in the background" />
);

export const CoinIntoUpwardGrowthBoxImage = (props: ImageProps) => (
  <img
    {...props}
    src={CoinIntoUpwardGrowthBox}
    alt="Coin being deposited into a box which shows a graph of upward growth"
  />
);

export const CoinIntoPiggyBankImage = (props: ImageProps) => (
  <img {...props} src={CoinIntoPiggyBank} alt="Coin dropping into a piggy bank" />
);

export const BullseyeImage = (props: ImageProps) => (
  <img {...props} src={Bullseye} alt="Arrow hitting the bullseye" />
);

export const PiggyBankWalletAndCoinsImage = (props: ImageProps) => (
  <img {...props} src={PiggyBankWalletAndCoins} alt="" />
);

export const ManWithTargetsAsDestinationsImage = (props: ImageProps) => (
  <img {...props} src={ManWithTargetsAsDestinations} alt="" />
);

export const ManWithTargetsAsDestinationsLongRoadImage = (props: ImageProps) => (
  <img {...props} src={ManWithTargetsAsDestinationsLongRoad} alt="" />
);

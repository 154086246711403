import React from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';
import { prop, switchProp, withProp, ifProp } from 'styled-tools';

import { maxWidth, minWidth, PAGE_SIDE_SPACING } from 'themes';

import { Panel } from '../Panel/Panel';

import { CloseButton } from './CloseButton';

type IPosition = 'center' | 'bottom' | 'top' | 'fullScreen';
interface ITransitionDivProps {
  state?: TransitionStatus;
  position: IPosition;
}
const TransitionDiv = styled.div<ITransitionDivProps>`
  position: relative;
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  transform: translateY(
    ${withProp('state', (state) => (state === 'entered' || state === 'entering' ? '0' : '1rem'))}
  );
  opacity: ${withProp('state', (state) =>
    state === 'entered' || state === 'entering' ? '1' : '0'
  )};

  ${maxWidth(
    'mobile',
    css`
      transition-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
      opacity: 1;
      ${switchProp('position', {
        top: css`
          transform: translateY(
            ${withProp('state', (state) =>
              state === 'entered' || state === 'entering' ? '0' : '100vh'
            )}
          );
        `,
        bottom: css`
          transform: translateY(
            ${withProp('state', (state) =>
              state === 'entered' || state === 'entering' ? '0' : '100%'
            )}
          );
        `,
        fullScreen: css`
          transform: translateY(
            ${withProp('state', (state) =>
              state === 'entered' || state === 'entering' ? '0' : '100%'
            )}
          );
        `,
        center: css`
          transform: translateY(
            ${withProp('state', (state) =>
              state === 'entered' || state === 'entering' ? '0' : 'calc(50% + 50vh)'
            )}
          );
        `,
      })};
    `
  )}
`;

interface IStyledWrapperProps {
  state?: TransitionStatus;
  position: IPosition;
  useExtraPadding: boolean;
}
const StyledWrapper = styled(Panel)<IStyledWrapperProps>`
  text-align: center;
  max-height: 85vh;
  max-width: 100vw;
  overflow: auto;
  border: none;
  padding: 24px 24px 0;

  ${switchProp('position', {
    fullScreen: css`
      width: 100vw;
      height: calc(100vh);
      max-height: 100vh;
      border-radius: 0;
      padding: 0;
    `,
    top: css`
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    `,
    bottom: css`
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    `,
  })};

  ${maxWidth(
    'mobile',
    css`
      width: 100vw;
      padding: 24px ${PAGE_SIDE_SPACING.DEFAULT_PX} 0;

      ${ifProp(
        'useExtraPadding',
        css`
          padding-top: 48px;
        `
      )};
    `
  )}

  ${minWidth(
    'tablet',
    css`
      ${ifProp(
        'useExtraPadding',
        css`
          padding-top: 56px;
        `
      )};
    `
  )}
`;

// Bottom padding is tied to content instead of container because it gets ignored in FireFox when the content in the modal is vertically scrollable
// This is due to a bug in FireFox; reference link: https://bugzilla.mozilla.org/show_bug.cgi?id=748518
const BottomPaddingDiv = styled.div`
  padding-bottom: 24px;
`;

const IconCircle = styled.div<{ color: string }>`
  width: 64px;
  height: 64px;
  z-index: 2;
  border-radius: 50%;
  background: ${prop('color')};
  position: absolute;
  display: flex;
  top: -32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid white;
  justify-content: center;
  align-items: center;
`;

export interface IModalChildProps {
  modalKey?: string;
}
export type ModalChild = React.ReactElement<IModalChildProps>;

interface IOwnProps {
  className?: string;
  position: IPosition;
  showCloseButton: boolean;
  closeButtonColourVariant: 'light' | 'dark';
  id: string;
  onExit: (event?: React.SyntheticEvent) => void;
  state?: TransitionStatus;
  iconBackgroundColor: string;
  icon?: React.ReactNode;
  renderChildren?: (
    state?: TransitionStatus,
    scrollRef?: React.RefObject<HTMLDivElement>
  ) => ModalChild;
  children?: ModalChild;
}

export class ModalContent extends React.Component<IOwnProps> {
  private readonly scrollRef: React.RefObject<HTMLDivElement>;

  constructor(props: IOwnProps) {
    super(props);
    this.scrollRef = React.createRef();
  }

  componentDidUpdate(prevProps: IOwnProps) {
    let prevKey: string | undefined;
    let currentKey: string | undefined;
    let prevChildren: ModalChild | undefined;
    let currentChildren: ModalChild | undefined;

    if (prevProps.renderChildren) {
      prevChildren = prevProps.renderChildren(prevProps.state);
    } else {
      prevChildren = prevProps.children;
    }

    if (this.props.renderChildren) {
      currentChildren = this.props.renderChildren(this.props.state);
    } else {
      currentChildren = this.props.children;
    }

    React.Children.forEach(prevChildren, (child) => {
      prevKey = child && child.props.modalKey;
    });

    React.Children.forEach(currentChildren, (child) => {
      currentKey = child && child.props.modalKey;
    });

    if (
      currentKey !== undefined &&
      prevKey !== undefined &&
      currentKey !== prevKey &&
      this.scrollRef.current
    ) {
      this.scrollRef.current.scrollTo(0, 0);
    }
  }

  public render() {
    const {
      onExit,
      position,
      className,
      showCloseButton,
      state,
      children,
      renderChildren,
      icon,
      iconBackgroundColor,
      closeButtonColourVariant,
    } = this.props;

    return (
      <TransitionDiv state={state} position={position}>
        <StyledWrapper
          ref={this.scrollRef}
          className={className}
          id={this.props.id}
          position={position}
          useExtraPadding={Boolean(showCloseButton || icon)}
        >
          <BottomPaddingDiv>
            {(renderChildren && renderChildren(state, this.scrollRef)) || children}
          </BottomPaddingDiv>
        </StyledWrapper>
        <CloseButton
          hidden={!showCloseButton}
          onExit={onExit}
          colourVariant={closeButtonColourVariant}
        />
        {icon && <IconCircle color={iconBackgroundColor}>{icon}</IconCircle>}
      </TransitionDiv>
    );
  }
}

import { StyleSheet } from '@react-pdf/renderer';

import { colors, fontSizes, padding } from '../../styles';

const baseCellStyle = {
  fontSize: fontSizes.body,
  paddingTop: padding.small,
  paddingBottom: padding.small,
  paddingLeft: padding.small,
  paddingRight: padding.small,
};

const baseTableCellStyle = {
  ...baseCellStyle,
  borderBottomWidth: 1,
  borderColor: colors.grey30,
};

export const BreakdownTableStyles = StyleSheet.create({
  headerCell: {
    ...baseCellStyle,
    backgroundColor: colors.grey30,
    fontWeight: 'bold',
    paddingBottom: padding.small + padding.xsmall,
    borderColor: colors.grey30,
  },
  tableCell: {
    ...baseTableCellStyle,
    borderRightColor: 'white',
    borderLeftColor: 'white',
  },
  tableCellAlt: {
    ...baseTableCellStyle,
    backgroundColor: colors.grey10,
    borderRightColor: colors.grey10,
  },
  tableCellAltFirst: {
    ...baseTableCellStyle,
    backgroundColor: colors.grey10,
    borderRightColor: colors.grey10,
    borderLeftColor: colors.grey30,
    borderLeftWidth: 1,
  },
  tableCellAltLast: {
    ...baseTableCellStyle,
    backgroundColor: colors.grey10,
    borderLeftColor: colors.grey10,
    borderRightColor: colors.grey30,
    borderRightWidth: 1,
  },
});

import axios from 'axios';

import { ONBOARDING } from 'constants/urls';
import { generateSecureRandomString } from 'utilities/mathUtilities';

const SINGPASS_NONCE_KEY = 'singpass-nonce';
const SINGPASS_STATE_KEY = 'singpass-state';
const APIM_PREVIOUS_TOKEN_CODE = 'apim-prev-token-code';
const APIM_MYINFO_RETRIEVAL_ENTRY_PAGE_KEY = 'apim-myinfo-retrieval-entry-page';
const APIM_MYINFO_REDIRECTION_PURPOSE_KEY = 'apim-myinfo-redirection-purpose';
const LAST_VISITED_APIM_MYINFO_SUMMARY_PAGE_ROUTE_KEY =
  'last-visited-apim-myinfo-summary-page-route';
const PDF_DOWNLOADS_COUNT = 'pdf-downloads-count';
const LOGOUT_STATUS = 'logout-status';

export const getUserDataRequest = () => axios.get(`${process.env.REACT_APP_API_URL}/v1/user`);

export const getHasValidToken = async () => (await getTokenExpiry()) !== null;

export const getTokenExpiry = async () =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/auth/token-expiry`)
    .then(({ data: { tokenExpiry } }) => tokenExpiry * 1000)
    .catch(() => null);

const removeToken = () => axios.delete(`${process.env.REACT_APP_API_URL}/v1/auth/token`);

export const setSingpassNonce = () => {
  const nonce = generateSecureRandomString(16);
  window.sessionStorage.setItem(SINGPASS_NONCE_KEY, nonce);
  return nonce;
};

export const getSingpassNonce = () => window.sessionStorage.getItem(SINGPASS_NONCE_KEY);

export const setSingpassState = () => {
  const state = generateSecureRandomString(16);
  window.sessionStorage.setItem(SINGPASS_STATE_KEY, state);
  return state;
};

export const getSingpassState = () => window.sessionStorage.getItem(SINGPASS_STATE_KEY);

export enum APIM_MYINFO_RETRIEVAL_ENTRY_PAGE {
  SETTINGS = 'settings',
  DASHBOARD_SUMMARY = 'dashboard-summary',
  DASHBOARD_RETRIEVAL = 'dashboard-retrieval',
  NOTIFICATIONS = 'notifications',
  ESTIMATED_GROWTH_TOOL_LOCKOUT_PAGE = 'estimated-growth-tool-lockout-page',
  BUDGET_CALCULATOR_LOCKOUT_PAGE = 'budget-calculator-lockout-page',
  INVESTING_FOR_BEGINNERS_LOCKOUT_PAGE = 'investing-for-beginners-lockout-page',
  TARGETS_LOCKOUT_PAGE = 'targets-lockout-page',
}

export const setApimPreviousTokenCode = (code: string) =>
  window.sessionStorage.setItem(APIM_PREVIOUS_TOKEN_CODE, code);

export const getApimPreviousTokenCode = () =>
  window.sessionStorage.getItem(APIM_PREVIOUS_TOKEN_CODE);

export const setApimMyinfoRetrievalEntryPage = (entryPage: APIM_MYINFO_RETRIEVAL_ENTRY_PAGE) => {
  window.sessionStorage.setItem(APIM_MYINFO_RETRIEVAL_ENTRY_PAGE_KEY, entryPage);
};

export const getApimMyinfoRetrievalEntryPage = () =>
  window.sessionStorage.getItem(APIM_MYINFO_RETRIEVAL_ENTRY_PAGE_KEY);

export const setApimMyinfoLastVisitedSummaryPageRoute = (lastVisitedRoute: string) => {
  window.sessionStorage.setItem(LAST_VISITED_APIM_MYINFO_SUMMARY_PAGE_ROUTE_KEY, lastVisitedRoute);
};

export const getApimMyinfoLastVisitedSummaryPageRoute = () =>
  window.sessionStorage.getItem(LAST_VISITED_APIM_MYINFO_SUMMARY_PAGE_ROUTE_KEY);

export const setPDFDownloadsCount = (count: number) =>
  window.sessionStorage.setItem(PDF_DOWNLOADS_COUNT, count.toString());

export const getPDFDownloadsCount = () =>
  parseInt(window.sessionStorage.getItem(PDF_DOWNLOADS_COUNT) || '0');

export const increasePDFDownloadsCount = (increment = 1) => {
  const downloadsCount = getPDFDownloadsCount();
  window.sessionStorage.setItem(PDF_DOWNLOADS_COUNT, (downloadsCount + increment).toString());
};

export const clearApimMyinfoLastVisitedSummaryPageRoute = () =>
  window.sessionStorage.removeItem(LAST_VISITED_APIM_MYINFO_SUMMARY_PAGE_ROUTE_KEY);

export enum APIM_MYINFO_REDIRECTION_PURPOSE {
  MANAGEMENT = 'management',
  CONSENT = 'consent',
}

export const getApimMyinfoRedirectionPurpose = () =>
  window.sessionStorage.getItem(APIM_MYINFO_REDIRECTION_PURPOSE_KEY);

export const setApimMyinfoRedirectionPurpose = (
  redirectionPurpose: APIM_MYINFO_REDIRECTION_PURPOSE
) => {
  window.sessionStorage.setItem(APIM_MYINFO_REDIRECTION_PURPOSE_KEY, redirectionPurpose);
};

export const clearApimMyinfoRedirectionPurpose = () =>
  window.sessionStorage.removeItem(APIM_MYINFO_REDIRECTION_PURPOSE_KEY);

export const getReturnToOnboardingRoute = () => {
  const lastVisitedSummaryPageRoute = getApimMyinfoLastVisitedSummaryPageRoute();
  const route =
    lastVisitedSummaryPageRoute === null
      ? ONBOARDING.PROFILE.employmentStatus
      : lastVisitedSummaryPageRoute;
  return route;
};

export enum LOGOUT_STATUS_TYPES {
  ACCESS_TOKEN_EXPIRED = 'access-token-expired',
  INACTIVITY_EXPIRED = 'inactivity-expired',
  USER_INITIATED = 'user-initiated',
  DUPLICATE_SESSION = 'duplicate-session',
}

export const setLogoutPageStatusType = (logoutStatus: LOGOUT_STATUS_TYPES) =>
  window.sessionStorage.setItem(LOGOUT_STATUS, logoutStatus);

export const clearLogoutPageStatusType = () => window.sessionStorage.removeItem(LOGOUT_STATUS);

export const getLogoutPageStatusType = () => {
  const pageStatus = window.sessionStorage.getItem(LOGOUT_STATUS);
  return pageStatus;
};

export const handleSessionLogout = async (
  logoutStatus: LOGOUT_STATUS_TYPES,
  clearSessionStorage = true,
  redirectToLogoutPage = true
) => {
  const isDuplicateSession = logoutStatus === LOGOUT_STATUS_TYPES.DUPLICATE_SESSION;

  if (clearSessionStorage) {
    window.sessionStorage.clear();
  }

  if (isDuplicateSession) {
    setLogoutPageStatusType(logoutStatus);
    await removeToken();
  } else if (!isDuplicateSession && redirectToLogoutPage) {
    setLogoutPageStatusType(logoutStatus);
    await removeToken();
    window.location.href = process.env.REACT_APP_LOGOUT_URL as string;
  }
};

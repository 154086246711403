import styled from 'styled-components';

import { colorScale } from 'themes';

export const LoadingSpinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  height: 2rem;
  width: 2rem;

  &::before {
    animation: 2s linear infinite spinner;
    border: solid 3px ${colorScale('secondary', 20)};
    border-bottom-color: ${colorScale('secondary', 30)};
    border-radius: 50%;
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    will-change: transform;
  }
`;

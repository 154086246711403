import { indicatorStatuses } from './indicatorStatuses';
import { IRule, IIndicatorStatus } from './interfaces';

export const getStatus = <T>(inputs: T, rules: IRule<T>[]) =>
  rules.find(({ condition }) => condition(inputs))?.result || rules[0].result;

export const getPillarIndicator = (...indicators: IIndicatorStatus[]) => {
  const totalLevel = indicators.reduce((sum, { level }) => sum + level, 0);
  const numberOfIncludedIndicators = indicators.filter(({ level }) => level !== 0).length;

  return getAverageIndicator(totalLevel / numberOfIncludedIndicators);
};

export const getAverageIndicator = (averageLevel: number) =>
  Object.entries(indicatorStatuses).reduce(
    (finalStatus, [status, { level }]) =>
      averageLevel >= level ? indicatorStatuses[status] : finalStatus,
    indicatorStatuses.UNAVAILABLE
  );

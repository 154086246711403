import React from 'react';
import styled from 'styled-components';

import { typography, colorScale } from 'themes';

import { IRadioProps } from './Radio';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledDiv = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
`;

const ErrorMessage = styled.p`
  ${typography('body-s')};
  color: ${colorScale('supporting-red', 50)};
  margin: 8px 0 0;
`;

interface IOwnProps {
  /** Name of radio button children to group them together */
  name: string;

  /** Defines the look of the radio label for all children */
  variant?: 'default' | 'styled' | 'custom';

  /** Error message for failed validations, will be displayed at the bottom of the radio group*/
  errorMessage?: string;

  /** To link up the validation container with input so upon submitting user will be brought to the erroneous input */
  wrapperRef?: React.RefObject<HTMLDivElement>;

  children: (React.ReactElement<IRadioProps> | null)[];
}

type IRadioGroupProps = React.InputHTMLAttributes<HTMLInputElement> & IOwnProps;

export class RadioGroup extends React.Component<IRadioGroupProps> {
  public renderChildren() {
    const { children, name, variant, value, onChange, errorMessage, onBlur, onFocus } = this.props;

    if (!children) {
      return null;
    }

    return React.Children.map(children, (child: React.ReactElement<IRadioProps> | null) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          name,
          variant,
          onChange,
          selectedValue: value,
          error: Boolean(errorMessage),
          onBlur,
          onFocus,
        });
      } else {
        return null;
      }
    });
  }

  public render() {
    const { wrapperRef, errorMessage } = this.props;

    return (
      <Wrapper>
        <StyledDiv className={this.props.className} ref={wrapperRef}>
          {this.renderChildren()}
        </StyledDiv>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Wrapper>
    );
  }
}

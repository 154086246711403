import React from 'react';

import LadyWithClipboard from './lady-with-clipboard.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const LadyWithClipboardImage = (props: ImageProps) => (
  <img
    {...props}
    src={LadyWithClipboard}
    alt="Woman with a clipboard, with a bar graph in the background"
  />
);

import { StyleSheet } from '@react-pdf/renderer';

export const colors = {
  grey10: '#F8F9FC',
  grey30: '#D6DBE2',
  grey80: '#6E747E',
  grey90: '#363B43',
  primary10: '#F5F5FF',
  primary20: '#E7E5FF',
  primary40: '#644DCF',
};

export const fontSizes = {
  h0: 24,
  h1: 20,
  h2: 16,
  h3: 14,
  h4: 12,
  body: 10,
};

const BASE_PADDING = 5;
export const padding = {
  xsmall: BASE_PADDING,
  small: BASE_PADDING * 2,
  medium: BASE_PADDING * 4,
  large: BASE_PADDING * 6,
  xlarge: BASE_PADDING * 8,
};

const BASE_MARGIN = 4;
export const margin = {
  xsmall: BASE_MARGIN * 1.5,
  small: BASE_MARGIN * 3,
  medium: BASE_MARGIN * 6,
  large: BASE_MARGIN * 8,
};

// Common styles
export const commonStyles = StyleSheet.create({
  title: {
    fontWeight: 'bold',
    fontSize: fontSizes.h1,
    marginBottom: margin.xsmall,
  },
  subtitle: {
    fontSize: fontSizes.h3,
  },
});

import React from 'react';
import styled, { css } from 'styled-components';

import { minWidth } from 'themes';

import { Bar } from './Bar';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  align-items: flex-end;
`;

interface IProps {
  leftAmount: number;
  rightAmount: number;
  barWidths: [number, number];
  className?: string;
}

export const TwinBars: React.FC<IProps> = ({ leftAmount, rightAmount, barWidths, className }) => {
  const maxAmount = Math.max(leftAmount, rightAmount);
  const [leftHeightPercentage, rightHeightPercentage] = [leftAmount, rightAmount].map(
    (amt) => (amt / maxAmount) * 100
  );

  return (
    <Wrapper className={className}>
      {maxAmount === 0 || leftHeightPercentage === 0 ? (
        <Bar type="empty" heightPercentage={100} widths={barWidths} />
      ) : (
        <Bar type="main" heightPercentage={leftHeightPercentage} widths={barWidths} />
      )}

      {maxAmount === 0 || rightHeightPercentage === 0 ? (
        <Bar type="empty" heightPercentage={100} widths={barWidths} />
      ) : (
        <Bar type="sub" heightPercentage={rightHeightPercentage} widths={barWidths} />
      )}
    </Wrapper>
  );
};

export const StyledTwinBars = styled(TwinBars)`
  grid-gap: 25px;
  height: 160px;
  ${minWidth(
    'desktop',
    css`
      grid-gap: 50px;
      height: 240px;
    `
  )}
`;

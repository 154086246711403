import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { clearReduxData, setHasToken } from 'redux/dataStorage/actions';
import { setGlobalModal } from 'redux/messages/actions';

import { captureLogout, captureFrequencyOfPDFDownloadsAfterLogout } from 'analytics';
import { GLOBAL_MODAL } from 'constants/enums';
import {
  getPDFDownloadsCount,
  handleSessionLogout,
  LOGOUT_STATUS_TYPES,
} from 'utilities/sessionUtilities';

import { SessionManager } from './SessionManager';

export const SessionContext = React.createContext<SessionManager>(new SessionManager());

interface ILocalProps {
  children?: React.ReactNode;
}
type IProps = ILocalProps & RouteComponentProps;

let sessionManager: SessionManager;

const handledEventNames: string[] = [
  'DOMMouseScroll',
  'mousedown',
  'mousewheel',
  'MSPointerDown',
  'keydown',
  'touchmove',
  'touchstart',
  'wheel',
];

const SessionWrapper = (props: IProps) => {
  const dispatch = useDispatch();

  const handleAutoLogout = (logoutType: LOGOUT_STATUS_TYPES) => {
    captureFrequencyOfPDFDownloadsAfterLogout(getPDFDownloadsCount());
    captureLogout();
    handleSessionLogout(logoutType);
    dispatch(clearReduxData());
    dispatch(setHasToken(false));
  };

  const showGlobalModal = (type: GLOBAL_MODAL) => dispatch(setGlobalModal(type));

  sessionManager =
    sessionManager ||
    new SessionManager({
      handleAutoLogout,
      showGlobalModal,
    });

  useEffect(() => {
    handledEventNames.forEach((eventName) => {
      document.body.addEventListener(eventName, sessionManager.handleEvent, { passive: true });
    });

    return () => {
      handledEventNames.forEach((eventName) => {
        document.body.removeEventListener(eventName, sessionManager.handleEvent);
      });
    };
  }, []);

  return <SessionContext.Provider value={sessionManager}>{props.children}</SessionContext.Provider>;
};

export const SessionWrapperWithRouter = withRouter(SessionWrapper);

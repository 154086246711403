import { css } from 'styled-components';
import { ifProp, theme as fromTheme } from 'styled-tools';

import { colorScale, shadowScale } from 'themes';

export const panelProperties = css`
  align-items: initial;
  flex-direction: row;
  text-align: left;
  padding: 24px;
  border-radius: ${fromTheme('borders.radiusLarge')};
  color: ${colorScale('grey', 90)};
  font-weight: 400;

  > #icon {
    color: ${colorScale('primary', 40)};
  }

  ${ifProp(
    'disabled',
    css`
      border-color: ${colorScale('grey', 50)};
      box-shadow: none;
      color: ${colorScale('grey', 50)};

      > #icon {
        color: ${colorScale('grey', 50)};
      }
    `,
    css`
      background: white;

      &:hover,
      &:focus {
        transform: translateY(-1px);
        box-shadow: ${shadowScale(2, colorScale('grey', 80))};
      }

      &,
      &:active {
        transform: translateY(0);
        box-shadow: ${shadowScale(1)};
      }
    `
  )}
`;

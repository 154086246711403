import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme as fromTheme } from 'styled-tools';

import { typeScale, colorScale } from 'themes';

import { baseProperties } from '../Button/sharedStyles';

const SMALL_PADDING_PX = '6px';
const MEDIUM_PADDING_PX = '10px';

type IIconButtonVariant = 'purple' | 'grey' | 'white';
type IIconButtonSize = 'small' | 'medium';
type IIconButtonEdge =
  | 'false'
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'top-left'
  | 'top-right'
  | 'bottom-right'
  | 'bottom-left';

interface IOwnProps {
  /** Defines the color of the icon element */
  variant?: IIconButtonVariant;

  /** Defines the size of the button, its padding changes for each size */
  size?: IIconButtonSize;

  /** Defines if padding should be offset with negative margin to align to an edge without affecting its size */
  edge?: IIconButtonEdge;

  ariaLabel: string;

  /** The icon element */
  children: React.ReactElement;
}

export type IIconButtonProps = IOwnProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

const variantProperties = css`
  ${switchProp('variant', {
    purple: css`
      color: ${colorScale('primary', 50)};
    `,
    grey: css`
      color: ${colorScale('grey', 80)};
    `,
    white: css`
      color: ${colorScale('grey', 0)};
    `,
  })};
`;

const edgeProperties = (padding: string) =>
  css`
    ${switchProp('edge', {
      top: css`
        margin-top: -${padding};
      `,
      right: css`
        margin-right: -${padding};
      `,
      bottom: css`
        margin-bottom: -${padding};
      `,
      left: css`
        margin-left: -${padding};
      `,
      'top-left': css`
        margin-top: -${padding};
        margin-left: -${padding};
      `,
      'top-right': css`
        margin-top: -${padding};
        margin-right: -${padding};
      `,
      'bottom-right': css`
        margin-bottom: -${padding};
        margin-right: -${padding};
      `,
      'bottom-left': css`
        margin-bottom: -${padding};
        margin-left: -${padding};
      `,
    })}
  `;

const sizeProperties = css`
  ${switchProp('size', {
    small: css`
      font-size: ${typeScale(1)};
      padding: ${SMALL_PADDING_PX};
      ${ifProp('edge', edgeProperties(SMALL_PADDING_PX))}
    `,
    medium: css`
      font-size: ${typeScale(3)};
      padding: ${MEDIUM_PADDING_PX};
      ${ifProp('edge', edgeProperties(MEDIUM_PADDING_PX))}
    `,
  })}
`;

const StyledButton = styled.button<Omit<IIconButtonProps, 'ariaLabel'>>`
  ${baseProperties}
  ${variantProperties}
  ${sizeProperties}

  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: ${fromTheme('borders.radiusRound')};

  ${ifProp(
    'disabled',
    css`
      color: ${colorScale('grey', 30)};
    `,
    css`
      &:hover,
      &:active {
        background: ${colorScale('grey', 10)};
      }
    `
  )}
`;

export const IconButton = React.forwardRef<HTMLButtonElement, IIconButtonProps>(
  (
    {
      variant = 'purple',
      size = 'medium',
      edge = 'false',
      ariaLabel,
      children,
      ...otherProps
    }: IIconButtonProps,
    ref
  ) => {
    return (
      <StyledButton
        type="button"
        variant={variant}
        size={size}
        edge={edge}
        ref={ref}
        aria-label={ariaLabel}
        {...otherProps}
      >
        {children}
      </StyledButton>
    );
  }
);

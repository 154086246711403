import { css } from 'styled-components';
import { ifProp, theme as fromTheme } from 'styled-tools';

import { colorScale, gradient, shadowScale } from 'themes';

export const primaryProperties = css`
  align-items: center;
  justify-content: center;
  background: ${gradient('main', '50deg')};
  color: white;
  border-radius: ${fromTheme('borders.radiusRound')};
  font-weight: 600;

  ${ifProp(
    'disabled',
    css`
      background: ${colorScale('grey', 20)};
    `,
    css`
      &:hover,
      &:focus {
        transform: translateY(-1px);
        box-shadow: ${shadowScale(2, colorScale('primary', 50))};
      }

      &:active {
        transform: translateY(0);
        box-shadow: ${shadowScale(1, colorScale('primary', 50))};
      }
    `
  )}
`;

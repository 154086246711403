/**
 * Make sures all objects within an array has the same set of keys
 * @param {object[]} arr The array of objects to be padded
 * @returns {object[]} The padded array of objects
 * @example
 * padKeys([{ a: 1 }, { b: 2 } ]); // returns [{ a: 1, b: undefined }, { a: undefined, b: 2 }]
 */
export const padKeys = (arr: object[]) => {
  const unionOfKeys = new Set<string>();

  arr.forEach((obj) => Object.keys(obj).forEach((key) => unionOfKeys.add(key)));

  const defaultObject = {};

  unionOfKeys.forEach((key) => {
    defaultObject[key] = undefined;
  });

  return arr.map((obj) => ({
    ...defaultObject,
    ...obj,
  }));
};

import React from 'react';
import styled, { css } from 'styled-components';

import { Panel, Toggle } from 'components/Common';
import { IToggleProps } from 'components/Common/Toggle/Toggle';
import { maxWidth, typography } from 'themes';

const QuickFillPanel = styled(Panel)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  ${typography('body-l-bold')}

  ${maxWidth(
    'tablet',
    css`
      border-radius: 0;
      margin: 0 -16px 32px -16px;
    `
  )}
`;

export const MyinfoQuickFillPanel: React.FC<IToggleProps> = ({ checked, onChange }) => (
  <QuickFillPanel>
    Use data from Myinfo
    <Toggle checked={checked} onChange={onChange} />
  </QuickFillPanel>
);

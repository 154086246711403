import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { Section, Panel, Heading } from 'components/Common';
import { colorScale } from 'themes';
import { typography } from 'themesTypography';

import { CTAButtons } from '../CTAButtons/CTAButtons';

const CTAPanel = styled(Panel)`
  background: ${colorScale('primary', 20)};
`;

const CTAPanelTitle = styled(Heading)`
  ${typography('heading-m')}
  margin: 32px 0 16px;
`;

const CTAPanelParagraph = styled.p`
  ${typography('body-l')}
  margin-bottom: 32px;
`;

const StyledImage = styled.div`
  > img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: ${fromTheme('borders.radiusLarge')};
  }
`;

export interface IPurpleCtaPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  desc: string;
  image: React.ReactElement;
  route: string;
  ctaText: string;
}

export const PurpleCtaPanel = ({
  title,
  desc,
  image,
  route,
  ctaText,
  ...otherProps
}: IPurpleCtaPanelProps) => {
  const history = useHistory();

  return (
    <CTAPanel {...otherProps}>
      <Section>
        <StyledImage>{image}</StyledImage>
        <CTAPanelTitle>{title}</CTAPanelTitle>
        <CTAPanelParagraph>{desc}</CTAPanelParagraph>
        <CTAButtons
          primaryText={ctaText}
          primaryOnClick={() => {
            history.push(route);
          }}
        />
      </Section>
    </CTAPanel>
  );
};

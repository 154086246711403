import React from 'react';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { Logo, Container, NavigationBarComponents } from 'components/Common';

interface IProps {
  className?: string;
}

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: white;
  z-index: ${fromTheme('layers.navbar')};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${fromTheme('heights.navbar')};
`;

export const LogoBar = ({ className }: IProps) => (
  <Wrapper className={className}>
    <StyledContainer>
      <NavigationBarComponents.LogoWrapper to="/">
        <Logo />
      </NavigationBarComponents.LogoWrapper>
    </StyledContainer>
  </Wrapper>
);

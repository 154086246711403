import { Document, Font } from '@react-pdf/renderer';
import { chunk } from 'lodash';
import moment from 'moment';
import React from 'react';

import LibreFranklinBold from 'assets/PDF/LibreFranklin-Bold.ttf';
import LibreFranklinItalicBold from 'assets/PDF/LibreFranklin-BoldItalic.ttf';
import LibreFranklin from 'assets/PDF/LibreFranklin-Regular.ttf';
import { IExpense } from 'constants/storeTypes';

import { BreakdownPage } from './BreakdownPage/BreakdownPage';
import { SampleBreakdownPage } from './BreakdownPage/SampleBreakdownPage';
import { TipsPages, ITipsPageProps } from './TipsPages/TipsPages';

Font.register({
  family: 'Libre Franklin',
  fonts: [
    { src: LibreFranklin },
    { src: LibreFranklinBold, fontWeight: 'bold' },
    { src: LibreFranklinItalicBold, fontWeight: 'bold', fontStyle: 'italic' },
  ],
});

// Note: (MAGIC NUMBER) this is the max number of rows that
// can appear in a Breakdown page
const MAX_EXPENSES_PER_PAGE = 16;

interface IProps {
  expenses: IExpense[];
  totalCurrentAmount: number;
  totalBudgetAmount: number;
  title: string;
}

export type IExpenseBudgetPDFDocumentProps = IProps & ITipsPageProps;

export const ExpenseBudgetPDFDocument = ({
  expenses,
  totalCurrentAmount,
  totalBudgetAmount,
  title,
  tips,
}: IExpenseBudgetPDFDocumentProps) => {
  return (
    <Document title={`MyMoneySense ${title}`} author="MyMoneySense">
      {chunk(expenses, MAX_EXPENSES_PER_PAGE).map((subExpenses, idx) => (
        <BreakdownPage
          key={`fd-chunk${idx}`}
          pageTitle={title}
          pageSubtitle={moment().format('DD MMM YYYY')}
          expenses={subExpenses}
          startMonth={moment().add(1, 'month').format('MMM YYYY')}
          endMonth={moment().add(2, 'month').format('MMM YYYY')}
          showTotalRow={idx === Math.ceil(subExpenses.length / MAX_EXPENSES_PER_PAGE) - 1}
          totalCurrentAmount={totalCurrentAmount}
          totalBudgetAmount={totalBudgetAmount}
        />
      ))}
      <SampleBreakdownPage />
      <TipsPages tips={tips} />
    </Document>
  );
};

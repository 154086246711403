import {
  BUDGET_CALCULATOR,
  EXTERNAL_URL,
  RESOURCES,
  INVESTING_FOR_BEGINNERS,
  HOUSING,
} from 'constants/urls';

export const siteMapData = [
  {
    subHeadingTitle: 'General',
    links: [
      {
        text: 'About us',
        url: '/about-us',
        isExternal: false,
      },
      {
        text: 'Contact us',
        url: process.env.REACT_APP_CONTACT_US_FORM_URL || '#',
        isExternal: true,
      },
      {
        text: 'Data you can retrieve with SGFinDex',
        url: '/SGFinDex-Data-List',
        isExternal: false,
      },
      {
        text: 'Give Feedback',
        url: process.env.REACT_APP_FEEDBACK_FORM_URL || '#',
        isExternal: true,
      },
      {
        text: 'Maintenance notices',
        url: '/maintenance-notices',
        isExternal: false,
      },
      {
        text: 'Report vulnerability',
        url: 'https://www.tech.gov.sg/report_vulnerability',
        isExternal: true,
      },
      {
        text: 'Useful links',
        url: '/useful-links',
        isExternal: false,
      },
    ],
  },
  {
    subHeadingTitle: 'Legal',
    links: [
      {
        text: 'Privacy Policy',
        url: '/privacy-policy',
        isExternal: false,
      },
      {
        text: 'SGFinDex Terms and Conditions',
        url: '/SGFinDex-Terms-and-Conditions',
        isExternal: false,
      },
      {
        text: 'Terms of Use',
        url: '/terms-of-use',
        isExternal: false,
      },
    ],
  },
  {
    subHeadingTitle: 'Tools',
    links: [
      {
        text: 'Try our expense manager',
        url: '/expense-manager',
        isExternal: false,
      },
    ],
  },
  {
    subHeadingTitle: 'Resources',
    links: [
      {
        text: 'Savings',
        url: '/savings-resources',
        isExternal: false,
        children: [
          {
            text: 'Budget calculator',
            url: BUDGET_CALCULATOR.baseRoute,
            isExternal: false,
          },
          {
            text: 'Grow your CPF retirement savings',
            url: EXTERNAL_URL.cpfTopUpSavings,
            isExternal: true,
          },
          {
            text: 'Save before your spend',
            url: RESOURCES.SAVINGS.saveBeforeYouSpend,
            isExternal: false,
          },
          {
            text: 'Setting SMART targets',
            url: RESOURCES.SAVINGS.setSmartTargets,
            isExternal: false,
          },
        ],
      },
      {
        text: 'Loans and credit',
        url: RESOURCES.LOANS_AND_CREDIT.baseRoute,
        isExternal: false,
        children: [
          {
            text: 'Repay your loans early',
            url: RESOURCES.LOANS_AND_CREDIT.repayYourLoansEarlyPage,
            isExternal: false,
          },
          {
            text: 'Tide over this Covid-19 period with temporary relief',
            url:
              'https://www.mas.gov.sg/regulation/covid-19/supporting-individuals-through-covid-19/helping-individuals-transit-to-full-loan-repayments',
            isExternal: true,
          },
        ],
      },
      {
        text: 'Investment',
        url: RESOURCES.INVESTMENT.baseRoute,
        isExternal: false,
        children: [
          {
            text: 'Investing for beginners',
            url: INVESTING_FOR_BEGINNERS.baseRoute,
            isExternal: false,
            children: [
              {
                text: 'Are you ready to start investing?',
                url: INVESTING_FOR_BEGINNERS.READINESS_ASSESSMENT.baseRoute,
                isExternal: false,
              },
              {
                text: 'Get started with CDP and/or brokerage account',
                url: INVESTING_FOR_BEGINNERS.ARTICLES.setUpCdpBrokerageAccount,
                isExternal: false,
              },
              {
                text: 'How can you get financial advice?',
                url: INVESTING_FOR_BEGINNERS.ARTICLES.getFinancialAdvice,
                isExternal: false,
              },
            ],
          },
          {
            text: 'Beware of investment scams',
            url: RESOURCES.INVESTMENT.antiScamArticle,
            isExternal: false,
          },
          {
            text: 'Calculate your portfolio’s estimated growth rate',
            url: RESOURCES.INVESTMENT.ESTIMATEDGROWTHCAL.baseRoute,
            isExternal: false,
          },
          {
            text: 'Review your investments early',
            url: RESOURCES.INVESTMENT.setReminders,
            isExternal: false,
          },
          {
            text: 'Diversify your investments',
            url: 'https://www.moneysense.gov.sg/investments/types-of-investments/',
            isExternal: true,
          },
          {
            text: 'Learn about investing',
            url: 'https://www.moneysense.gov.sg/investments/investment-portfolio/',
            isExternal: true,
          },
          {
            text: 'Start investing',
            url: 'https://www.sgx.com/securities/retail-investor/apply-cdp-securities-account',
            isExternal: true,
          },
          {
            text: 'Invest some savings',
            url: 'https://www.mas.gov.sg/bonds-and-bills/Singapore-Savings-Bonds',
            isExternal: true,
          },
        ],
      },
      {
        text: 'Insurance',
        url: RESOURCES.INSURANCE.baseRoute,
        isExternal: false,
        children: [
          {
            text: 'Understanding the types of insurance',
            url: RESOURCES.INSURANCE.understandingTheTypesOfInsurance,
            isExternal: false,
          },
          {
            text: 'What is Dependants’ Protection Scheme?',
            url: RESOURCES.INSURANCE.ARTICLE.dpsArticle,
            isExternal: false,
          },
          {
            text: 'What is Home Protection Scheme?',
            url: RESOURCES.INSURANCE.ARTICLE.hpsArticle,
            isExternal: false,
          },
          {
            text: 'What is MediShield Life?',
            url: RESOURCES.INSURANCE.ARTICLE.mshlArticle,
            isExternal: false,
          },
          {
            text: 'What is Integrated Shield Plan?',
            url: RESOURCES.INSURANCE.ARTICLE.ispArticle,
            isExternal: false,
          },
          {
            text: 'What is CareShield Plan?',
            url: RESOURCES.INSURANCE.ARTICLE.careShieldArticle,
            isExternal: false,
          },
          {
            text: 'What is ElderShield?',
            url: RESOURCES.INSURANCE.ARTICLE.elderShieldArticle,
            isExternal: false,
          },
          {
            text: 'What are Supplements for CareShield Life/ElderShield?',
            url: RESOURCES.INSURANCE.ARTICLE.supplementArticle,
            isExternal: false,
          },
        ],
      },
      {
        text: 'Buying a house',
        url: HOUSING.housingResources,
        isExternal: false,
        children: [
          {
            text: 'Purchase guide',
            url: HOUSING.ARTICLES.baseRoute,
            isExternal: false,
            children: [
              {
                text: 'How much should your first flat cost?',
                url: HOUSING.ARTICLES.myFirstHouse,
                isExternal: false,
              },
              {
                text: 'Should you buy a new or resale flat?',
                url: HOUSING.ARTICLES.newOrResaleFlat,
                isExternal: false,
              },
              {
                text: 'What are the other things you’ll need to pay for when you buy a home?',
                url: HOUSING.ARTICLES.otherThingsToPayFor,
                isExternal: false,
              },
              {
                text: 'Should you take a housing loan from HDB or a bank?',
                url: HOUSING.ARTICLES.hdbOrBankLoan,
                isExternal: false,
              },
              {
                text: 'What housing loan amount and loan period should you go for?',
                url: HOUSING.ARTICLES.loanAmountAndPeriod,
                isExternal: false,
              },
              {
                text:
                  'Should you keep some money in your CPF Ordinary Account when you take up your housing loan?',
                url: HOUSING.ARTICLES.leaveMoneyInCPF,
                isExternal: false,
              },
              {
                text: 'Should you pay for your home with cash for CPF savings?',
                url: HOUSING.ARTICLES.cashOrCPF,
                isExternal: false,
              },
            ],
          },
          {
            text: 'Create your financing plans',
            url: HOUSING.financingPlan,
            isExternal: false,
          },
        ],
      },
      {
        text: 'Retirement planning',
        url: RESOURCES.RETIREMENT.baseRoute,
        isExternal: false,
        children: [
          {
            text: 'Step 1: Define retirement goals',
            url: RESOURCES.RETIREMENT.defineRetirementGoals,
            isExternal: false,
          },
          {
            text: 'Step 2: Assess current situation',
            url: RESOURCES.RETIREMENT.assessCurrentSituation,
            isExternal: false,
          },
          {
            text: 'Step 3: Close savings gap',
            url: RESOURCES.RETIREMENT.closeSavingsGap,
            isExternal: false,
          },
        ],
      },
    ],
  },
];

import React from 'react';
import styled, { css } from 'styled-components';
import { switchProp, theme as fromTheme } from 'styled-tools';

import { colorScale, typeScale } from 'themes';

import {
  baseProperties,
  sizeProperties,
  edgeProperties,
  widthStyleProperties,
} from './sharedStyles';
import { panelProperties } from './variantStyles/panelProperties';
import { popupProperties } from './variantStyles/popupProperties';
import { primaryProperties } from './variantStyles/primaryProperties';
import { secondaryProperties } from './variantStyles/secondaryProperties';
import { tertiaryProperties } from './variantStyles/tertiaryProperties';

const ICON_GAP_SMALL_PX = '6px';
const ICON_GAP_LARGE_PX = '8px';

export type IButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'panel' | 'popup';
type IButtonWidthStyle = 'elastic' | 'fluid';
type IButtonSize = 'small' | 'medium' | 'large';
type IButtonIconPosition = 'start' | 'end';
type IButtonEdge = 'false' | 'left' | 'right';

interface IOwnProps {
  /** Defines the look of the button */
  variant?: IButtonVariant;

  /** Defines how the button should fit its container */
  widthStyle?: IButtonWidthStyle;

  /** Defines the size of the button, its padding changes for each size */
  size?: IButtonSize;

  /** The icon element */
  icon?: React.ReactElement;

  /** Defines the position of the icon element relative to the button */
  iconPosition?: IButtonIconPosition;

  /** Defines if padding should be offset with negative margin to align to an edge without affecting its size */
  edge?: IButtonEdge;
}

export type IButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & IOwnProps;

type IButtonGroupVariant = 'primary' | 'secondary' | 'tertiary';
type IButtonGroupProps = React.HTMLAttributes<HTMLDivElement> & {
  /** Defines the look of the button */
  variant?: IButtonGroupVariant;
};

const StyledButton = styled.button<IButtonProps>`
  ${baseProperties}
  ${sizeProperties}
  ${edgeProperties}
  ${widthStyleProperties}
  ${switchProp('variant', {
    primary: primaryProperties,
    secondary: secondaryProperties,
    tertiary: tertiaryProperties,
    panel: panelProperties,
    popup: popupProperties,
  })};
`;

const Icon = styled.div`
  display: flex;
  align-self: center;
  font-size: ${typeScale(0)};
`;

const IconStart = styled(Icon)<{ size?: IButtonSize }>`
  ${switchProp('size', {
    small: css`
      margin-right: ${ICON_GAP_SMALL_PX};
    `,
    medium: css`
      margin-right: ${ICON_GAP_LARGE_PX};
    `,
    large: css`
      margin-right: ${ICON_GAP_LARGE_PX};
    `,
  })};
`;

const IconEnd = styled(Icon)<{ size?: IButtonSize; variant: IButtonVariant }>`
  ${switchProp('size', {
    small: css`
      margin-left: ${ICON_GAP_SMALL_PX};
    `,
    medium: css`
      margin-left: ${ICON_GAP_LARGE_PX};
    `,
    large: css`
      margin-left: ${ICON_GAP_LARGE_PX};
    `,
  })};

  ${switchProp('variant', {
    panel: css`
      padding-left: 8px;
      margin-left: auto;
    `,
  })};
`;

export const ButtonGroup = styled.div<IButtonGroupProps>`
  ${StyledButton} {
    border-radius: ${fromTheme('borders.radius')};
    ${switchProp('variant', {
      primary: css`
        border-top: none;
        border-bottom: none;
        border-color: ${colorScale('grey', 20)};
      `,
      tertiary: css`
        border-top: none;
        border-bottom: none;
        border-color: ${colorScale('grey', 20)};
      `,
    })};
  }

  ${StyledButton}:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    ${switchProp('variant', {
      primary: css`
        border-left-color: transparent;
      `,
      tertiary: css`
        border-left-color: transparent;
      `,
    })};
  }

  ${StyledButton}:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    ${switchProp('variant', {
      primary: css`
        border-right-color: transparent;
      `,
      tertiary: css`
        border-right-color: transparent;
      `,
    })};
  }

  ${StyledButton}:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  ${StyledButton}:not(:first-child) {
    margin-left: -1px;
  }
`;

// eslint-disable-next-line react/display-name
export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      variant = 'primary',
      widthStyle = 'elastic',
      size = 'large',
      icon,
      iconPosition = 'start',
      edge = 'false',
      children,
      ...otherProps
    }: IButtonProps,
    ref
  ) => {
    return (
      <StyledButton
        variant={variant}
        widthStyle={widthStyle}
        size={size}
        edge={edge}
        ref={ref}
        {...otherProps}
      >
        {icon && iconPosition === 'start' && (
          <IconStart id="icon" size={size}>
            {icon}
          </IconStart>
        )}
        {children}
        {icon && iconPosition === 'end' && (
          <IconEnd id="icon" size={size} variant={variant}>
            {icon}
          </IconEnd>
        )}
      </StyledButton>
    );
  }
);

import { Reducer } from 'redux';

import { IMessageActions } from 'constants/actionTypes';
import { MESSAGES_ACTIONS } from 'constants/enums';
import { IMessagesStore } from 'constants/storeTypes';

export const initialState: IMessagesStore = {
  latestMessage: null,
  globalModal: null,
};

export const messagesReducer: Reducer<IMessagesStore, IMessageActions> = (
  state: IMessagesStore = initialState,
  action: IMessageActions
) => {
  switch (action.type) {
    case MESSAGES_ACTIONS.ADD_MESSAGE:
      return {
        ...state,
        latestMessage: {
          content: action.content,
          options: action.options,
        },
      };

    case MESSAGES_ACTIONS.SET_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: action.globalModal,
      };

    default:
      return state;
  }
};

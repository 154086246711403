import { omit } from 'lodash';

import { IStore, IFxRate } from 'constants/storeTypes';

const NON_CURRENCY_CODES = ['XAU'];

export const getFxRates = (store: IStore): { [id: string]: IFxRate[] } => store.fxRates.fxRates;

export const getCurrencyFxRates = (store: IStore): { [id: string]: IFxRate[] } =>
  omit(getFxRates(store), NON_CURRENCY_CODES);

export const getFxRateByCodeFn = (store: IStore) => (code: string) => getFxRates(store)[code];

import axios from 'axios';
import { isEmpty } from 'lodash';
import uuidv4 from 'uuid/v4';

import {
  RequestAction,
  ISetRecommendedActions,
  ICreateRecommendedAction,
  IUpdateRecommendedAction,
  IDeleteRecommendedAction,
} from 'constants/actionTypes';
import {
  FINANCIAL_STATUS_ACTIONS,
  RECOMMENDED_ACTION,
  RECOMMENDED_ACTION_COMPLETER,
} from 'constants/enums';
import { IRecommendedAction } from 'constants/storeTypes';
import { featureDecisions } from 'featureDecisions';

import { convertResponseToRecommendedAction } from './helpers';

export const setRecommendedActions = (recommendedActions: {
  [id: string]: IRecommendedAction;
}): ISetRecommendedActions => ({
  type: FINANCIAL_STATUS_ACTIONS.SET_RECOMMENDED_ACTIONS,
  recommendedActions,
});

export const createRecommendedAction = (
  id: string,
  recommendedAction: IRecommendedAction
): ICreateRecommendedAction => ({
  type: FINANCIAL_STATUS_ACTIONS.CREATE_RECOMMENDED_ACTION,
  id,
  recommendedAction,
});

export const createRecommendedActionRequest = (
  key: RECOMMENDED_ACTION,
  completedBy: RECOMMENDED_ACTION_COMPLETER
): RequestAction => async (dispatch) => {
  if (featureDecisions.mockServerResponses) {
    const id = uuidv4();
    const recommendedAction: IRecommendedAction = {
      id,
      key,
      completedAt: new Date().toISOString(),
      completedBy,
    };
    dispatch(createRecommendedAction(id, recommendedAction));
    return Promise.resolve();
  }
  const promise = axios.post(`${process.env.REACT_APP_API_URL}/v1/recommended-action`, {
    key,
    completedBy,
  });

  promise
    .then(({ data: { recommendedAction } }) =>
      dispatch(
        createRecommendedAction(
          recommendedAction.id,
          convertResponseToRecommendedAction(recommendedAction)
        )
      )
    )
    .catch(() => {});

  return promise;
};

export const updateRecommendedAction = (
  id: string,
  update: Partial<IRecommendedAction>
): IUpdateRecommendedAction => ({
  type: FINANCIAL_STATUS_ACTIONS.UPDATE_RECOMMENDED_ACTION,
  id,
  update,
});

export const updateRecommendedActionRequest = (
  id: string,
  update: Pick<IRecommendedAction, 'completedBy'>
): RequestAction => async (dispatch) => {
  if (isEmpty(update)) {
    return;
  }

  const { completedBy } = update;
  const promise = axios.patch(`${process.env.REACT_APP_API_URL}/v1/recommended-action/${id}`, {
    ...update,
    completedBy: completedBy && RECOMMENDED_ACTION_COMPLETER[completedBy],
  });

  promise
    .then(({ data: { recommendedAction } }) =>
      dispatch(
        updateRecommendedAction(
          recommendedAction.id,
          convertResponseToRecommendedAction(recommendedAction)
        )
      )
    )
    .catch(() => {});

  return promise;
};

export const deleteRecommendedAction = (id: string): IDeleteRecommendedAction => ({
  type: FINANCIAL_STATUS_ACTIONS.DELETE_RECOMMENDED_ACTION,
  id,
});

export const deleteRecommendedActionRequest = (id: string): RequestAction => async (dispatch) => {
  const promise = axios.delete(`${process.env.REACT_APP_API_URL}/v1/recommended-action/${id}`);

  promise.then(() => dispatch(deleteRecommendedAction(id))).catch(() => {});

  return promise;
};

import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { switchProp, ifProp, withProp } from 'styled-tools';

import { colorScale, theme } from 'themes';

const loading = keyframes`
  from {
    transform: translateX(-100%);
  }
  
  to {
    transform: translateX(100%);
  }
`;

const Wrapper = styled.span<{ widthStyle: widthStyle; width?: string; isLoading: boolean }>`
  border-radius: ${theme.borders.radiusLarge};
  overflow: hidden;
  margin: 0 auto;
  display: inline-flex;

   ${ifProp(
     'isLoading',
     css`
       background: ${withProp('isLoading', (isLoading) =>
         isLoading ? colorScale('grey', 20) : 'inherit'
       )};
     `
   )}

  ${switchProp('widthStyle', {
    fluid: css`
      width: 100%;
    `,
    fit: css`
      width: fit-content;
    `,
  })}

  ${ifProp(
    'width',
    css`
      width: ${withProp('width', (width) => `${width}`)};
    `
  )}
`;

const ProgressIndicator = styled.span`
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    90deg,
    ${colorScale('grey', 20)},
    ${colorScale('grey', 10)},
    ${colorScale('grey', 20)}
  );
  animation: ${loading} 1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
`;

const HiddenText = styled.span`
  visibility: hidden;
`;

type widthStyle = 'fit' | 'fluid';

export type ISkeletonLoadingProps = {
  guesstimateContent: string;
  isLoading: boolean;
  children: React.ReactNode;
} & (
  | {
      widthStyle?: widthStyle;
      width?: never;
    }
  | {
      widthStyle?: never;
      width?: string;
    }
);

export const SkeletonLoading = ({
  widthStyle = 'fit',
  width,
  guesstimateContent,
  isLoading,
  children,
  ...otherProps
}: ISkeletonLoadingProps) => {
  return isLoading ? (
    <Wrapper widthStyle={widthStyle} width={width} isLoading={isLoading} {...otherProps}>
      <ProgressIndicator data-testid="skeleton-loading-indicator">
        <HiddenText>{guesstimateContent}</HiddenText>
      </ProgressIndicator>
    </Wrapper>
  ) : (
    <>{children}</>
  );
};

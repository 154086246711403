import React from 'react';
import styled from 'styled-components';

import LeftAlignedBigWave from './left-aligned-big-wave.svg';
import People from './people-with-hands-raised.svg';
import Profile from './profile.svg';
import Secure from './secure.svg';
import WaveMobile from './wave-mobile.svg';
import WaveTablet from './wave-tablet.svg';
import EmailNotification from './woman-with-email-notification.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

const Icon = styled.img`
  grid-area: icon;
`;

export const EmailNotificationImage = (props: ImageProps) => (
  <img {...props} src={EmailNotification} alt="" />
);

export const PeopleWithHandsRaisedImage = (props: ImageProps) => (
  <img {...props} src={People} alt="3 people with their hands raised" />
);

export const ProfileIcon = (props: ImageProps) => (
  <Icon {...props} src={Profile} alt="Man icon with a tick" />
);

export const SecureIcon = (props: ImageProps) => (
  <Icon {...props} src={Secure} alt="Shield with a tick" />
);

export const WaveMobileImage = (props: ImageProps) => (
  <img {...props} src={WaveMobile} alt="Purple wave" />
);

export const WaveTabletImage = (props: ImageProps) => (
  <img {...props} src={WaveTablet} alt="Purple wave" />
);

export const LeftAlignedBigWaveImage = (props: ImageProps) => (
  <img {...props} src={LeftAlignedBigWave} alt="Left aligned big purple wave" />
);

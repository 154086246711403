import styled, { css } from 'styled-components';

import { ResourcesCard } from 'components/Composite';
import { colorScale, maxWidth, typography } from 'themes';

export const StyledResourcesCard = styled(ResourcesCard)`
  margin-bottom: 16px;
`;

export const ResourcesPageContentTitle = styled.p`
  color: ${colorScale('grey', 90)};
  ${typography('heading-l')}
  text-align: left;
  margin: 0 0 32px 0;

  ${maxWidth(
    'tablet',
    css`
      display: none;
    `
  )}
`;

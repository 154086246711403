import React from 'react';

import Apple from './apple.svg';
import Google from './google.svg';
import ICS from './ics.svg';

type ImageProps = React.HTMLAttributes<HTMLImageElement>;

export const AppleIcon = (props: ImageProps) => <img {...props} src={Apple} alt="Apple" />;

export const GoogleIcon = (props: ImageProps) => <img {...props} src={Google} alt="Google" />;

export const ICSIcon = (props: ImageProps) => <img {...props} src={ICS} alt="ICS" />;

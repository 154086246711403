import React from 'react';

import { WarningBanner } from 'components/Common';

export const DatePassedBanner: React.FC = () => (
  <WarningBanner
    data-testid="date-passed-banner"
    text={<React.Fragment>Expired targets will be considered completed.</React.Fragment>}
  />
);

import {
  IDataStorageStore,
  ITargetsStore,
  IProfileStore,
  IMainModulesStore,
  IFinancesStore,
  IModulesLiteStore,
  IFinancesLiteStore,
  IHousingStore,
} from 'constants/storeTypes';

interface IMockInitialStates {
  dataStorage: IDataStorageStore | null;
  finances: IFinancesStore | null;
  financesLite: IFinancesLiteStore | null;
  modules: IMainModulesStore | null;
  modulesLite: IModulesLiteStore | null;
  profile: IProfileStore | null;
  targets: ITargetsStore | null;
  housing: IHousingStore | null;
}

export const mockInitialStates: IMockInitialStates = {
  dataStorage: null,
  finances: null,
  financesLite: null,
  modules: null,
  modulesLite: null,
  profile: null,
  targets: null,
  housing: null,
};

import { Location } from 'history';
import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

import { featureDecisions } from 'featureDecisions';

import { DEFAULT_WARNING_MODAL_INFO } from './defaultWarningModalInfo';

interface IProps {
  shouldBlockNavigation: boolean;
  customModalDescription?: string;
  nextPaths: string[];
}

export const NavigationBlocker = ({
  shouldBlockNavigation,
  customModalDescription,
  nextPaths,
}: IProps) => {
  useEffect(() => {
    const checkIfNavigationIsBlocked = () => {
      return shouldBlockNavigation;
    };

    // check if env disables blocker
    if (featureDecisions.disableBlocker) {
      return () => {};
    }

    // displays leave site/reload confirmation prompt on closing tab/refresh
    window.onbeforeunload = checkIfNavigationIsBlocked;
    return () => {
      Object.defineProperty(window, 'onbeforeunload', {
        value: null,
        configurable: true,
        writable: true,
      });
    };
  }, [shouldBlockNavigation]);

  const handleNavigation = (nextLocation: Location) => {
    const { pathname } = nextLocation;

    if (shouldBlockNavigation === false || nextPaths.includes(pathname)) {
      return true;
    }

    return customModalDescription || DEFAULT_WARNING_MODAL_INFO.description;
  };

  return <Prompt message={handleNavigation} />;
};

import React from 'react';

import BankIcon from './bank-icon.svg';
import BlueHdbBlock from './blue-hdb-block.svg';
import BuildingWithCoinsInfront from './building-with-coins-infront.svg';
import BunchOfCoins from './bunch-of-coins.svg';
import BusyDesk from './busy-desk.svg';
import CompoundingGraph from './compounding-graph.svg';
import CpfPiggieBank from './cpf-piggie-bank.svg';
import DeskWithNotesCalculatorAndCoffee from './desk-with-notes-calculator-and-coffee.svg';
import GraphWithMagnifyingGlassIcon from './graph-with-magnifying-glass-icon.svg';
import HdbBlob0 from './hdb-blob-0.svg';
import HdbBlob1 from './hdb-blob-1.svg';
import HdbBlob2 from './hdb-blob-2.svg';
import HdbBlob3 from './hdb-blob-3.svg';
import HdbBlob4 from './hdb-blob-4.svg';
import HdbConstruction0 from './hdb-construction-0.svg';
import HdbConstruction1 from './hdb-construction-1.svg';
import HdbConstruction2 from './hdb-construction-2.svg';
import HdbConstructionCompleted from './hdb-construction-completed.svg';
import HdbConstructionUnstable from './hdb-construction-unstable.svg';
import HdbFlat1 from './hdb-flat-1.svg';
import HdbFlat2 from './hdb-flat-2.svg';
import HdbFlat3 from './hdb-flat-3.svg';
import HdbFlat4 from './hdb-flat-4.svg';
import HdbFlatCompleted from './hdb-flat-completed.svg';
import HdbFlatIcon from './hdb-flat-icon.svg';
import HdbFlatUnstable from './hdb-flat-unstable.svg';
import HdbFlatsCircle from './hdb-flats-circle.svg';
import HdbFlatsSquare from './hdb-flats-square.svg';
import HdbFlats from './hdb-flats.svg';
import HdbThumbnailCompleted from './hdb-thumbnail-completed.svg';
import HdbThumbnailUnstable from './hdb-thumbnail-unstable.svg';
import HusbandHoldingWife from './husband-holding-wife.svg';
import LadyWithMoneyAndHouse from './lady-with-money-and-house.svg';
import LevyForm from './levy-form.svg';
import ManCarryingSofa from './man-carrying-sofa.svg';
import ManGettingLoan from './man-getting-loan.svg';
import ManListingHisHouseForSale from './man-listing-his-house-for-sale.svg';
import ManTypingOnLaptop from './man-typing-on-laptop.svg';
import MapPinpointingLocation from './map-pinpointing-location.svg';
import MoneyBagAndCoins from './money-bags-and-coins.svg';
import MsgBoxIcon from './msg-box-icon.svg';
import OneTimeCalendar from './one-time-calendar.svg';
import PiggyBankAndCoins from './piggy-bank-and-coins.svg';
import PiggyBankWithCoins from './piggy-bank-with-coins.svg';
import PurpleFancyHouse from './purple-fancy-house.svg';
import RecurringCalendar from './recurring-calendar.svg';
import RedHdbFlats from './red-flats.svg';
import RedHdbBlock from './red-hdb-block.svg';
import ResaleFlat from './resale-flat.svg';
import ScaleWithBankAndHdb from './scale-with-bank-and-hdb.svg';
import ShinyNewFlat from './shiny-new-flat.svg';
import SinglePringle from './single-pringle.svg';
import SingpassWave from './singpass-wave.svg';
import SmileyEmoji from './smiley-emoji.svg';
import Sparkle from './sparkle.svg';
import StackOfCoins from './stack-of-coins.svg';
import TargetsIcon from './targets-icon.svg';
import ThinkingEmoji from './thinking-emoji.svg';
import WomanDecidingBetweenCPFAndLoan from './woman-deciding-between-cpf-and-loan.svg';
import WomanWithPencilAndChecklist from './woman-with-pencil-and-checklist.svg';

type ImageProps = React.HTMLAttributes<HTMLImageElement>;

export const ManListingHisHouseForSaleImage = (props: ImageProps) => (
  <img {...props} src={ManListingHisHouseForSale} alt="Man listing his house for sale" />
);

export const ScaleWithBankAndHdbImage = (props: ImageProps) => (
  <img
    {...props}
    src={ScaleWithBankAndHdb}
    alt="A scale with bank building on left side, and a HDB building on the right side"
  />
);

export const LadyWithMoneyAndHouseImage = (props: ImageProps) => (
  <img {...props} src={LadyWithMoneyAndHouse} alt="A lady with a big house and lots of coins" />
);

export const CompoundingGraphImage = (props: ImageProps) => (
  <img {...props} src={CompoundingGraph} alt="Graph showings the effects of compounding" />
);

export const ManCarryingSofaImage = (props: ImageProps) => (
  <img {...props} src={ManCarryingSofa} alt="Man carrying a yellow sofa" />
);

export const BuildingWithCoinsInfrontImage = (props: ImageProps) => (
  <img {...props} src={BuildingWithCoinsInfront} alt="Building with stacks of gold coin in front" />
);

export const ManTypingOnLaptopImage = (props: ImageProps) => (
  <img {...props} src={ManTypingOnLaptop} alt="Man typing on laptop" />
);

export const HusbandHoldingWifeImage = (props: ImageProps) => (
  <img {...props} src={HusbandHoldingWife} alt="A husband holding his wife" />
);

export const MapPinpointingLocationImage = (props: ImageProps) => (
  <img {...props} src={MapPinpointingLocation} alt="A map pinpointing a location" />
);

export const CpfPiggieBankImage = (props: ImageProps) => (
  <img {...props} src={CpfPiggieBank} alt="A piggie bank with CPF stamped on it" />
);

export const BunchOfCoinsImage = (props: ImageProps) => (
  <img {...props} src={BunchOfCoins} alt="A bunch of coins" />
);

export const WomanDecidingBetweenCPFAndLoanImage = (props: ImageProps) => (
  <img
    {...props}
    src={WomanDecidingBetweenCPFAndLoan}
    alt="Woman deciding between keeping money in CPF OA and using it on loan downpayment"
  />
);

export const ManGettingLoanImage = (props: ImageProps) => (
  <img {...props} src={ManGettingLoan} alt="A man getting loan" />
);

export const WomanWithPencilAndChecklistImage = (props: ImageProps) => (
  <img {...props} src={WomanWithPencilAndChecklist} alt="A woman with a pencil and a checklist" />
);

export const BusyDeskImage = (props: ImageProps) => (
  <img {...props} src={BusyDesk} alt="A desk with a laptop, coffee, and many documents" />
);

export const HdbFlat1Image = (props: ImageProps) => (
  <img {...props} src={HdbFlat1} alt="" className="flat-img" />
);

export const HdbFlat2Image = (props: ImageProps) => (
  <img {...props} src={HdbFlat2} alt="" className="flat-img" />
);

export const HdbFlat3Image = (props: ImageProps) => (
  <img {...props} src={HdbFlat3} alt="" className="flat-img" />
);

export const HdbFlat4Image = (props: ImageProps) => (
  <img {...props} src={HdbFlat4} alt="" className="flat-img" />
);

export const HdbFlatCompletedImage = (props: ImageProps) => (
  <img {...props} src={HdbFlatCompleted} alt="" className="flat-img" />
);

export const HdbFlatUnstableImage = (props: ImageProps) => (
  <img {...props} src={HdbFlatUnstable} alt="" className="flat-img" />
);

export const HdbConstruction0Image = (props: ImageProps) => (
  <img {...props} src={HdbConstruction0} alt="" />
);

export const HdbConstruction1Image = (props: ImageProps) => (
  <img {...props} src={HdbConstruction1} alt="" />
);

export const HdbConstruction2Image = (props: ImageProps) => (
  <img {...props} src={HdbConstruction2} alt="" />
);

export const HdbConstructionCompletedImage = (props: ImageProps) => (
  <img {...props} src={HdbConstructionCompleted} alt="" />
);

export const HdbConstructionUnstableImage = (props: ImageProps) => (
  <img {...props} src={HdbConstructionUnstable} alt="" />
);

export const HdbBlob0Image = (props: ImageProps) => <img {...props} src={HdbBlob0} alt="" />;

export const HdbBlob1Image = (props: ImageProps) => <img {...props} src={HdbBlob1} alt="" />;

export const HdbBlob2Image = (props: ImageProps) => <img {...props} src={HdbBlob2} alt="" />;

export const HdbBlob3Image = (props: ImageProps) => <img {...props} src={HdbBlob3} alt="" />;

export const HdbBlob4Image = (props: ImageProps) => <img {...props} src={HdbBlob4} alt="" />;

export const HdbThumbnailCompletedImage = (props: ImageProps) => (
  <img {...props} src={HdbThumbnailCompleted} alt="" className="flat-img" />
);

export const HdbThumbnailUnstableImage = (props: ImageProps) => (
  <img {...props} src={HdbThumbnailUnstable} alt="" className="flat-img" />
);

export const SparkleImage = (props: ImageProps) => <img {...props} src={Sparkle} alt="" />;

export const SmileyEmojiImage = (props: ImageProps) => <img {...props} src={SmileyEmoji} alt="" />;

export const ThinkingEmojiImage = (props: ImageProps) => (
  <img {...props} src={ThinkingEmoji} alt="" />
);
export const DeskWithNotesCalculatorAndCoffeeImage = (props: ImageProps) => (
  <img
    {...props}
    src={DeskWithNotesCalculatorAndCoffee}
    alt="A desk with pen, notes, calculator and coffee"
  />
);

export const OneTimeCalendarImage = (props: ImageProps) => (
  <img {...props} src={OneTimeCalendar} alt="" />
);

export const RecurringCalendarImage = (props: ImageProps) => (
  <img {...props} src={RecurringCalendar} alt="" />
);

export const StackOfCoinsImage = (props: ImageProps) => (
  <img {...props} src={StackOfCoins} alt="" />
);

export const PiggyBankAndCoinsImage = (props: ImageProps) => (
  <img {...props} src={PiggyBankAndCoins} alt="" />
);

export const LevyFormImage = (props: ImageProps) => (
  <img {...props} src={LevyForm} alt="Resale levy form" />
);

export const ShinyNewFlatImage = (props: ImageProps) => (
  <img {...props} src={ShinyNewFlat} alt="" />
);

export const ResaleFlatImage = (props: ImageProps) => <img {...props} src={ResaleFlat} alt="" />;

export const SinglePringleImage = (props: ImageProps) => (
  <img {...props} src={SinglePringle} alt="" />
);

export const BlueHdbBlockImage = (props: ImageProps) => (
  <img {...props} src={BlueHdbBlock} alt="" />
);

export const RedHdbBlockImage = (props: ImageProps) => <img {...props} src={RedHdbBlock} alt="" />;

export const PiggyBankWithCoinsImage = (props: ImageProps) => (
  <img {...props} src={PiggyBankWithCoins} alt="" />
);
export const PurpleFancyHouseImage = (props: ImageProps) => (
  <img {...props} src={PurpleFancyHouse} alt="" />
);
export const MoneyBagAndCoinsImage = (props: ImageProps) => (
  <img {...props} src={MoneyBagAndCoins} alt="" />
);
export const HdbFlatIconImage = (props: ImageProps) => <img {...props} src={HdbFlatIcon} alt="" />;

export const BankIconImage = (props: ImageProps) => <img {...props} src={BankIcon} alt="" />;

export const SingpassWaveImage = (props: ImageProps) => (
  <img {...props} src={SingpassWave} alt="" />
);

export const GraphWithMagnifyingGlassIconImage = (props: ImageProps) => (
  <img {...props} src={GraphWithMagnifyingGlassIcon} alt="" />
);

export const MsgBoxIconImage = (props: ImageProps) => <img {...props} src={MsgBoxIcon} alt="" />;

export const TargetsIconImage = (props: ImageProps) => <img {...props} src={TargetsIcon} alt="" />;

export const HdbFlatsImage = (props: ImageProps) => (
  <img {...props} src={HdbFlats} alt="HDB Flats" />
);

export const HdbFlatsSquareImage = (props: ImageProps) => (
  <img {...props} src={HdbFlatsSquare} alt="" />
);

export const HdbFlatsCircleImage = (props: ImageProps) => (
  <img {...props} src={HdbFlatsCircle} alt="" />
);

export const RedHdbFlatsImage = (props: ImageProps) => <img {...props} src={RedHdbFlats} alt="" />;

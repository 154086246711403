import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import {
  ValidationContainerRenderParams,
  maximumAmount,
  minimumAmount,
  numericalCurrency,
  required as requiredValidationRule,
  ValidationContainer,
  IValidationType,
} from 'components/Validations';
import { numberWithSeparator } from 'utilities/currencyUtilities';

import { MAX_AMOUNT_IN_SGD_CENTS } from '../CurrencyInputField/CurrencyInputField';
import { IInputProps, Input } from '../Input/Input';

interface IOwnProps {
  amount: number | null;

  /** This value will be used for validations and will not affect the user typing in any way */
  minAmount?: number; //in cents
  minAmountInclusive?: boolean;

  /** This value will be used for validations and will not affect the user typing in any way */
  maxAmount?: number; //in cents
  maxAmountInclusive?: boolean;

  /** This value will not be used for validations and will stop the user from typing values greater than this */
  maxAllowableAmount?: number; //in cents

  onInputChange: (amount: number) => void;
  required?: boolean;
  renderBefore?: (renderParams: ValidationContainerRenderParams) => React.ReactElement;
  renderAfter?: (renderParams: ValidationContainerRenderParams) => React.ReactElement;

  customValidations?: IValidationType[];

  /** To define if field is errored but no error message is shown */
  isErrored?: boolean;

  errorMessage?: string;
}

export type IAmountFieldProps = IOwnProps & IInputProps;

const addonStyle = css`
  padding: 4px 0;
`;

const DollarSign = styled.span`
  ${addonStyle}
  &::before {
    content: 'SGD';
  }
`;

const PercentageSign = styled.span`
  ${addonStyle}
  &::after {
    content: '%';
  }
`;

export const AmountFieldComponent = ({
  amount: amountInCents,
  onInputChange,
  required,
  renderBefore,
  renderAfter,
  customValidations,
  minAmount = -Infinity,
  maxAmount = Infinity,
  minAmountInclusive = true,
  maxAmountInclusive = true,
  maxAllowableAmount = MAX_AMOUNT_IN_SGD_CENTS,
  onFocus,
  onBlur,
  isErrored,
  errorMessage,
  ...otherProps
}: IAmountFieldProps) => {
  const [shouldFormatValue, setShouldFormatValue] = useState(true);
  const [trailingCharacters, setTrailingCharacters] = useState('');

  function handleAmountChange(amount: string) {
    onInputChange(Math.round(Number(amount) * 100));
  }

  function handleAmountFocus(e: React.FocusEvent<HTMLInputElement>) {
    onFocus && onFocus(e);
    setShouldFormatValue(false);
  }

  function handleAmountBlur(e: React.FocusEvent<HTMLInputElement>) {
    onBlur && onBlur(e);
    setShouldFormatValue(true);
    setTrailingCharacters('');
  }

  function filterInput(value: string) {
    const maxAllowableAmountDollars = maxAllowableAmount / 100;

    const allowedTrailingCharacters = ['.', '.0', '.00'];
    const trailingCharacters = allowedTrailingCharacters.reduce(
      (prevChars, chars) => (value.endsWith(chars) ? chars : prevChars),
      ''
    );
    const [, fractional] = value.split('.');
    const newValue = Math.round(Number(value) * 100) / 100;
    const isValidNumber =
      !isNaN(newValue) && (!fractional || fractional.length <= 2) && newValue >= 0;

    if (isValidNumber) {
      setTrailingCharacters(trailingCharacters);
    }

    return isValidNumber
      ? newValue > maxAllowableAmountDollars
        ? `${maxAllowableAmountDollars}`
        : `${newValue}`
      : null;
  }

  let amountInputValue: string;
  if (amountInCents !== null) {
    const amountInDollar = amountInCents / 100;
    if (shouldFormatValue) {
      const maxAllowableAmountInDollar = maxAllowableAmount / 100;
      amountInputValue = numberWithSeparator(
        Math.min(maxAllowableAmountInDollar, amountInDollar),
        2
      );
    } else {
      amountInputValue = `${amountInDollar}${trailingCharacters}`;
    }
  } else {
    amountInputValue = '';
  }

  const validations = [
    numericalCurrency,
    minimumAmount(minAmount, minAmountInclusive),
    maximumAmount(maxAmount, maxAmountInclusive),
  ];
  if (required) {
    validations.push(requiredValidationRule);
  }

  if (customValidations) {
    validations.push(...customValidations);
  }

  return (
    <ValidationContainer
      value={amountInputValue}
      onBlur={handleAmountBlur}
      onFocus={handleAmountFocus}
      validations={validations}
      render={(validationsParams) => {
        return (
          <React.Fragment>
            {renderBefore && renderBefore(validationsParams)}
            <Input
              errorMessage={
                validationsParams.errorMessage || errorMessage || (isErrored ? '' : undefined)
              }
              onBlur={validationsParams.onBlur}
              onFocus={validationsParams.onFocus}
              value={amountInputValue}
              wrapperRef={validationsParams.placerRef}
              type="text"
              inputMode="decimal"
              autoComplete="off"
              inputFilter={filterInput}
              handleChange={handleAmountChange}
              {...otherProps}
            />
            {renderAfter && renderAfter(validationsParams)}
          </React.Fragment>
        );
      }}
    />
  );
};

export const AmountField = Object.assign(AmountFieldComponent, { DollarSign, PercentageSign });

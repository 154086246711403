import mixpanel from 'mixpanel-browser';

import {
  getTargetMonthlySavings,
  getMonthlySavings,
  getAvailableFundsAndPaidOutAmounts,
  getAllocatedSavings,
} from 'redux/finances/selectors';
import { getProfileIsReturnUser } from 'redux/profile/selectors';
import {
  getRequiredMonthlySavingsForTargets,
  getEmergencyFund,
  getProjectedTargets,
  isEF,
} from 'redux/targets/selectors';

import { ThunkResult } from 'analytics';
import { IBankName } from 'components/Resources/SavingsResources/SaveBeforeYouSpendPage/constants';

/**
 * The following data tags are interlinked with the document "Mixpanel Tags MasterList"
 * under FPDS > 03 Development > 08 Investments > 04 Development > Data Analytics
 * https://docs.google.com/spreadsheets/d/1P5JAJDdIMu6uHjICNFnuU5i3d2jP-RPwn7u7_m8pglI/edit#gid=0
 *
 * Whenever a data tag function is added, get removed or is no longer being called in code,
 * the document should be updated accordingly too to sync up with business side.
 */

// S/N 001
export const captureBankLinkClick = (bankName: IBankName) => {
  mixpanel.track('Standing Order: Bank link clicked', { bankName });
};

// S/N 002
type IFinalAmount = 'current' | 'minimum' | 'recommended' | 'increased' | 'decreased';
export const captureCommittedAmountInfo = (recommended: number): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    const committed = getTargetMonthlySavings(store);
    const minimum = getRequiredMonthlySavingsForTargets(store, 100);
    const current = getMonthlySavings(store);

    let finalAmount: IFinalAmount = 'current';
    if (committed === minimum) {
      finalAmount = 'minimum';
    } else if (committed === recommended) {
      finalAmount = 'recommended';
    } else if (committed > current) {
      finalAmount = 'increased';
    } else if (committed < current) {
      finalAmount = 'decreased';
    }

    mixpanel.track('Review savings: Final committed amount status', {
      finalAmount,
    });
  };
};

// S/N 003
// The concept of historical data of totalSavings has been deprecated in FO.
// totalSavings is now availableFunds but availableFunds has no historical data tracking.
// If this function is revived, need to reimplement the logic.
// Keeping this unused function because business has not made any decision to delete it.
type IChanges = 'increased' | 'decreased or no change';
export const captureIncreasedTotalSavings = (): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    const newTotalSavingsAndPaidOutAmounts = getAvailableFundsAndPaidOutAmounts(store);
    const prevTotalSavingsAndPaidOutAmounts = (() => {
      // Keeping the old code commented out for easy reference for revival if time for it comes.

      // const allUserData = Object.values(store.userHistory.history);
      // if (allUserData.length) {
      //   const lastMonthUserData = allUserData[0];
      //   const totalSavings = lastMonthUserData.totalSavingsList[0]?.amount || 0;
      //   const paidOutAmounts = lastMonthUserData.goals.reduce((acc: number, goal: any) => {
      //     return goal.paidOut ? acc + goal.paidOutAmount : acc;
      //   }, 0);
      //   return totalSavings + paidOutAmounts;
      // }
      return 0;
    })();

    const changes: IChanges =
      newTotalSavingsAndPaidOutAmounts > prevTotalSavingsAndPaidOutAmounts
        ? 'increased'
        : 'decreased or no change';

    mixpanel.track('Total savings + completed amount', {
      changes,
    });
  };
};

// S/N 004
type IEFFinalAmount = 'higher than recommended' | 'equal to recommended' | 'lower than recommended';
export const captureSetEmergencyFund = (
  emergencyFundAmount: number,
  recommendedEmergencyFundAmount: number
) => {
  let finalAmount: IEFFinalAmount;
  if (emergencyFundAmount > recommendedEmergencyFundAmount) {
    finalAmount = 'higher than recommended';
  } else if (emergencyFundAmount === recommendedEmergencyFundAmount) {
    finalAmount = 'equal to recommended';
  } else {
    finalAmount = 'lower than recommended';
  }

  mixpanel.track('Targets page: Emergency fund added or edited', { finalAmount });
};

// S/N 005
type IAchievability = 'Achieved' | 'Achievable' | 'Unachievable';
export const captureEmergencyFundAchievability = (): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    const emergencyFund = getEmergencyFund(store);
    const targetMonthlySavings = getTargetMonthlySavings(store);
    const projectedEmergencyFund = getProjectedTargets(store, targetMonthlySavings).filter(isEF)[0];

    let achievability: IAchievability;
    if (emergencyFund.currentAmount >= emergencyFund.totalAmount) {
      achievability = 'Achieved';
    } else if (projectedEmergencyFund.currentAmount >= projectedEmergencyFund.totalAmount) {
      achievability = 'Achievable';
    } else {
      achievability = 'Unachievable';
    }
    mixpanel.track('Emergency Fund Achievability', { achievability });
  };
};

// S/N 006
export const captureAddGoal = (pageLocation: string): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    mixpanel.track('Targets page: Goal added', {
      pageLocation,
      isReturnUser: getProfileIsReturnUser(store),
    });
  };
};

// S/N 007
export const captureCompleteGoal = (
  source: 'edit-target-modal' | 'update-target-progress'
): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    mixpanel.track('Targets page: Goal completed', {
      source,
      isReturnUser: getProfileIsReturnUser(store),
    });
  };
};

// S/N 008
type ITotalAllocatedAmountChanges = 'increased' | 'decreased or no change';
export const captureIncreasedTotalAllocatedAmount = (
  prevAllocatedSavings: number
): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    const newAllocatedSavings = getAllocatedSavings(store);
    const changes: ITotalAllocatedAmountChanges =
      newAllocatedSavings > prevAllocatedSavings ? 'increased' : 'decreased or no change';

    mixpanel.track('Allocated amount', {
      changes,
    });
  };
};

// S/N 009
export const captureActionsNotificationsVisit = (notifications: string[]) => {
  mixpanel.track('Notifications: Visited page', {
    ...notifications.reduce((acc: { [title: string]: boolean }, ele) => {
      acc[ele] = true;
      return acc;
    }, {}),
  });
};

// S/N 010
export const captureActionsNotificationsClick = (title: string) => {
  mixpanel.track('Notifications: Clicked', { [title]: true }); //same shape
};

// S/N 011
export const captureActionsUpdateTargetStatusVisit = () => {
  mixpanel.track('Update target status: Visited page');
};

// S/N 012
export const captureActionsUpdateTargetStatusContinue = (
  hasExtendedEmergencyFund: boolean,
  numberCompletedGoals: number,
  numberExtendedGoals: number,
  numberDeletedGoals: number
) => {
  mixpanel.track('Update target status: Clicked continue', {
    hasExtendedEmergencyFund,
    numberCompletedGoals,
    numberExtendedGoals,
    numberDeletedGoals,
  });
};

// S/N 013
type IClickButtonTypes =
  | 'update-and-reallocate'
  | 'add-goal'
  | 'edit-goal'
  | 'edit-emergency-fund'
  | 'edit-completed-targets'
  | 'clicked-info-button-ongoing'
  | 'clicked-info-button-completed';
export const captureActionsTargets = (clickedButton: IClickButtonTypes) => {
  mixpanel.track('Targets page', { clickedButton });
};

// S/N 014
type IFinancialDataOptions = 'Government, bank and CDP data' | 'Government data only' | 'None';
export const captureTypeOfFinancialDataRetrieved = (option: IFinancialDataOptions) => {
  mixpanel.track('Retrieval of financial data', { option });
};

// S/N 016
export const captureNOACardClicked = () => {
  mixpanel.track('Onboarding Cashflow: NOA');
};

// S/N 017
type IExpensesType = 'total' | 'categorised';
export const captureTypeOfExpenses = (type: IExpensesType) => {
  mixpanel.track('Onboarding Cashflow: Expenses', { type });
};

// S/N 019
export type IIncomeSourceType =
  | 'employment'
  | 'other sources'
  | 'employment and other sources'
  | 'no income sources';
export const captureTypeOfIncomeSources = (type: IIncomeSourceType) => {
  mixpanel.track('Onboarding Cashflow: Income sources', { type });
};

// S/N 020
export const capturePDFDownloaded = () => {
  mixpanel.track('Dashboard: Download PDF');
};

// S/N 021
// Tracks number of times PDF was downloaded in a session
// A session here is defined as the time between logging in and manual/auto logging out
export const captureFrequencyOfPDFDownloadsAfterLogout = (count: number) => {
  mixpanel.track(`Dashboard: Download PDF Frequency (Logged Out)`, { count });
};

// S/N 023
// Tracks user dropoffs after pulling APIM data but before filling in the expenses page
// This is a temporary metric to collect data to make the decision whether to account for inaccurate UI currently being presented.
export const captureDropoffAfterApimPullBeforeExpenses = () => {
  mixpanel.track(`Drop-off after APIM data pull before filling in expenses page`);
};

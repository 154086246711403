import { INITIAL_DRAFT_HOUSING_PLAN_ID } from 'constants/housingConstants';
import { IHousingPlanResponse, IUserDataWithoutHistory } from 'constants/responseTypes';
import { IInvestingForBeginners } from 'constants/storeTypes';
import { mockApimData } from 'utilities/mockData/mockApimData';

import {
  mockInvestmentForBeginnersItem,
  mockInvestmentForBeginnersInsurance,
} from '../testData/mockInvestmentForBeginners';

import { mockMyinfoData } from './mockMyinfoData';

interface IInvestingForBeginnersData {
  investingForBeginners: IInvestingForBeginners;
}

interface IMockAppData {
  data: {
    user: IUserDataWithoutHistory & IInvestingForBeginnersData;
  };
}

export const mockDefaultPlanResponse: IHousingPlanResponse = {
  id: 'h13n92ei-c2f1-4cef-bb2d-4f723ca6f90d',
  isPlanCompleted: true,
  planType: { id: 'DEFAULT' },
  planStep: 1,
  planName: 'Recommended plan',
  planColor: { id: 'PURPLE' },
  firstTimeBuyer: true,
  householdType: { id: 'FAMILY' },
  noOfBuyers: 2,
  monthlyHouseholdIncome: 800000,
  cpfOABalance: '5000000',
  cashSavings: '1000000',
  estimatedFlatBudget: '2222222',
  flatValueAtTimeOfPurchase: '555555',
  flatType: { id: 'NEW' },
  flatSize: { id: 'FIVE_ROOM' },
  loanType: { id: 'HDB' },
  upfrontPaymentInCash: '123123',
  cpfHousingGrant: 80000,
  enhancedCpfHousingGrant: 55555,
  proximityGrant: 30000,
  interestRate: '2.6',
  loanPeriod: 25,
  cpfMonthlyInstalment: 1000,
  buyerStampDuty: {
    amount: 1127549,
    paymentMethod: 'CPF',
  },
  optionFee: {
    amount: 200000,
    paymentMethod: 'CASH',
  },
  conveyancingFee: {
    amount: 6484,
    paymentMethod: 'CPF',
  },
  propertyAgentCommission: {
    amount: 0,
    paymentMethod: 'CASH',
  },
  registrationFee: {
    amount: 0,
    paymentMethod: 'CPF',
  },
  surveyFee: {
    amount: 29425,
    paymentMethod: 'CPF',
  },
  leaseInEscrowFee: {
    amount: 3830,
    paymentMethod: 'CPF',
  },
  mortgageInEscrowFee: {
    amount: 3830,
    paymentMethod: 'CPF',
  },
  stampDutyDeedFee: {
    amount: 50000,
    paymentMethod: 'CPF',
  },
  renovationCost: {
    amount: 4400000,
    paymentMethod: 'CASH',
  },
  serviceAndConservancyCharges: {
    amount: 6300,
    paymentMethod: 'CASH',
  },
  annualPropertyTax: {
    amount: 6400,
    paymentMethod: 'CASH',
  },
  mortgageInsurancePremiums: {
    amount: 4032000,
    paymentMethod: 'CPF',
  },
  lastUpdatedAt: new Date(2021, 6, 14).toISOString(),
};

export const mockCustomPlanResponse: IHousingPlanResponse = {
  id: '7deb5192-c2f1-4cef-bb2d-4f723ca6f90d',
  isPlanCompleted: true,
  planType: { id: 'CUSTOM' },
  planStep: 1,
  planName: 'My first housing plan',
  planColor: { id: 'RED' },
  firstTimeBuyer: true,
  householdType: { id: 'SINGLE' },
  noOfBuyers: 1,
  monthlyHouseholdIncome: 10000,
  cpfOABalance: '123123',
  cashSavings: '4544444',
  estimatedFlatBudget: '2222222',
  flatValueAtTimeOfPurchase: '555555',
  flatType: { id: 'NEW' },
  flatSize: { id: 'FIVE_ROOM' },
  loanType: { id: 'HDB' },
  upfrontPaymentInCash: '123123',
  cpfHousingGrant: 80000,
  enhancedCpfHousingGrant: 55555,
  proximityGrant: 30000,
  interestRate: '20',
  loanPeriod: 10,
  cpfMonthlyInstalment: 1000,
  buyerStampDuty: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  optionFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  conveyancingFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  propertyAgentCommission: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  registrationFee: {
    amount: 10000,
    paymentMethod: 'CPF',
  },
  surveyFee: {
    amount: 10000,
    paymentMethod: 'CPF',
  },
  leaseInEscrowFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  mortgageInEscrowFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  stampDutyDeedFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  renovationCost: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  serviceAndConservancyCharges: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  annualPropertyTax: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  mortgageInsurancePremiums: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  lastUpdatedAt: new Date(2021, 6, 14).toISOString(),
};

export const mockDraftHousingPlanResponse: IHousingPlanResponse = {
  id: INITIAL_DRAFT_HOUSING_PLAN_ID,
  isPlanCompleted: true,
  planType: { id: 'CUSTOM' },
  planStep: 1,
  planName: 'My first housing plan',
  planColor: { id: 'RED' },
  firstTimeBuyer: true,
  householdType: { id: 'SINGLE' },
  noOfBuyers: 1,
  monthlyHouseholdIncome: 10000,
  cpfOABalance: '123123',
  cashSavings: '4544444',
  estimatedFlatBudget: '2222222',
  flatValueAtTimeOfPurchase: '555555',
  flatType: { id: 'NEW' },
  flatSize: { id: 'FIVE_ROOM' },
  loanType: { id: 'HDB' },
  upfrontPaymentInCash: '123123',
  cpfHousingGrant: 80000,
  enhancedCpfHousingGrant: 55555,
  proximityGrant: 30000,
  interestRate: '20',
  loanPeriod: 10,
  cpfMonthlyInstalment: 1000,
  buyerStampDuty: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  optionFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  conveyancingFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  propertyAgentCommission: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  registrationFee: {
    amount: 10000,
    paymentMethod: 'CPF',
  },
  surveyFee: {
    amount: 10000,
    paymentMethod: 'CPF',
  },
  leaseInEscrowFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  mortgageInEscrowFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  stampDutyDeedFee: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  renovationCost: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  serviceAndConservancyCharges: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  annualPropertyTax: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  mortgageInsurancePremiums: {
    amount: 10000,
    paymentMethod: 'CASH',
  },
  lastUpdatedAt: new Date(2021, 6, 14).toISOString(),
};

export const mockAppData: IMockAppData = {
  data: {
    user: {
      id: 'simulate-uuid-from-data-base00000000',
      name: 'Sally',
      dob: null,
      sex: null,
      maritalStatus: null,
      residentialStatus: null,
      email: null,
      age: null,
      deferEmailVerification: false,
      hasStartedPlanning: null,
      dayOfTransfer: null,
      hasCompletedFeedbackForm: false,
      consentedToAppTNCAt: '2021-03-23T02:38:28.943Z',
      latestConsentedToAppNOCAt: '2021-03-23T02:38:28.943Z',
      registeredAt: '2021-03-23T02:38:25.549Z',
      ageRange: null,
      workYearsRange: null,
      apimMyinfoDataSelectionState: { id: 'MYINFO', description: 'Myinfo' },
      employmentStatusRange: {
        id: 'FULL_PART_TIME',
        description: 'Full-time or part-time employee',
      },
      automaticTransferStatus: null,
      myinfo: {
        ...mockMyinfoData.data,
        noa: mockMyinfoData.data.noa
          ? {
              ...mockMyinfoData.data.noa,
              amount: String(mockMyinfoData.data.noa.amount),
              employment: String(mockMyinfoData.data.noa.employment),
              trade: String(mockMyinfoData.data.noa.trade),
              interest: String(mockMyinfoData.data.noa.interest),
              rent: String(mockMyinfoData.data.noa.rent),
            }
          : undefined,
        cpf: mockMyinfoData.data.cpf
          ? {
              ...mockMyinfoData.data.cpf,
              oa: String(mockMyinfoData.data.cpf.oa),
              ma: String(mockMyinfoData.data.cpf.ma),
              sa: String(mockMyinfoData.data.cpf.sa),
              ra: String(mockMyinfoData.data.cpf.ra),
            }
          : undefined,
        hdbOwnership: mockMyinfoData.data.hdbOwnership
          ? mockMyinfoData.data.hdbOwnership.map((hdbo) => ({
              ...hdbo,
              outstandingLoanBalance: String(hdbo.outstandingLoanBalance),
              monthlyLoanInstalment: String(hdbo.monthlyLoanInstalment),
            }))
          : undefined,
      },
      emergencyFund: {
        id: '62f79523-2b73-41fd-8e66-9f5c586e9440',
        totalAmount: 1500000,
        currentAmount: 1500000,
        date: '2021-03-23T02:44:39.017Z',
        prevAccumulatedMonths: 0,
      },
      moduleStatuses: [
        {
          id: '8daa9b1f-a95b-4a1f-bf60-737ce031d389',
          currentStep: null,
          lastUpdated: null,
          lastCompleted: null,
          module: {
            id: 'BUDGET_CALCULATOR',
            description: 'Manage your expenses and save more for your future.',
          },
        },
        {
          id: 'f7a97c08-3c10-4620-adc0-cb8542b748a0',
          currentStep: null,
          lastUpdated: '2021-03-23T02:41:11.894Z',
          lastCompleted: '2021-03-23T02:41:11.894Z',
          module: {
            id: 'CASHFLOW',
            description: 'How much you earn, spend and save monthly',
          },
        },
        {
          id: '7b287c9c-907f-43ce-a738-bdbcad72a0dc',
          currentStep: null,
          lastUpdated: '2021-03-23T02:41:30.094Z',
          lastCompleted: '2021-03-23T02:41:30.094Z',
          module: {
            id: 'WEALTH',
            description: 'The value of what you own and what you owe',
          },
        },
        {
          id: 'a590c2e2-dbed-4170-9036-572501e48474',
          currentStep: null,
          lastUpdated: '2021-03-23T02:45:37.802Z',
          lastCompleted: '2021-03-23T02:45:37.802Z',
          module: {
            id: 'SET_SAVING_TARGETS',
            description: 'Set savings targets',
          },
        },
      ],
      personalNotifications: [
        {
          id: '189520b4-ae22-40be-9b42-517c0f089268',
          title: 'Achieved 2.8 months of emergency fund',
          description: '',
          date: '2021-03-23T02:44:22.288Z',
          acknowledged: false,
        },
        {
          id: 'edc710cf-15bb-42dc-9f24-ed8a036916b5',
          title: 'Achieved 8.3 months of emergency fund',
          description: '',
          date: '2021-03-23T02:44:39.084Z',
          acknowledged: false,
        },
      ],
      todoNotificationAcknowledgements: [],
      inbuiltNotificationAcknowledgements: [],
      connectedBanks: [
        {
          id: '0aa8ec55-d661-4acc-952e-2a8f46ba8441',
          bankName: 'MAYBANK',
        },
        {
          id: '70d12981-8315-4e5e-89af-c7806d413aa9',
          bankName: 'OCBC',
        },
        {
          id: 'd53d396d-41ed-402a-adca-8a037f23a380',
          bankName: 'UOB',
        },
      ],
      totalBudget: null,
      myinfoLatestPullConsentAtDate: '2021-03-23T02:38:38.646Z',
      apimLatestPullConsentAtDate: '2021-03-23T02:38:38.652Z',
      expenses: [
        {
          id: '79efb3da-61c5-4794-8758-60dc900b708d',
          category: 'Food and drinks',
          amount: 30000,
          budgetAmount: 0,
          timeInterval: {
            id: 'MONTHLY',
            description: 'Monthly',
          },
          budgetTimeInterval: {
            id: 'MONTHLY',
            description: 'Monthly',
          },
        },
        {
          id: '297b3e25-6cb7-46ab-8b00-3db5cb027338',
          category: 'Transport',
          amount: 10000,
          budgetAmount: 0,
          timeInterval: {
            id: 'MONTHLY',
            description: 'Monthly',
          },
          budgetTimeInterval: {
            id: 'MONTHLY',
            description: 'Monthly',
          },
        },
        {
          id: 'd04a0b0c-42e0-40fc-b15f-439bbc47967b',
          category: 'Entertainment',
          amount: 50000,
          budgetAmount: 0,
          timeInterval: {
            id: 'MONTHLY',
            description: 'Monthly',
          },
          budgetTimeInterval: {
            id: 'MONTHLY',
            description: 'Monthly',
          },
        },
      ],
      incomeList: [
        {
          id: '227991ae-21e5-4e02-a647-955c879d42db',
          name: 'WORK INCORPORATED',
          netIncome: 508800,
          grossIncome: 550000,
          incomeCurrency: 'SGD',
          type: {
            id: 'EMPLOYMENT',
            description: 'Employment',
          },
        },
      ],
      investments: [
        {
          id: '9977571a-0c87-4ecd-86d3-ace11204ffde',
          name: 'Unit trust',
          amount: 10000,
          assetType: 'UNIT_TRUST',
          ccy: 'SGD',
        },
        {
          id: '4a98b873-5a54-4d56-9d5a-053496cfae99',
          name: 'Bond',
          amount: 10000,
          assetType: 'BOND',
          ccy: 'SGD',
        },
      ],
      liabilities: [
        {
          id: '9dbebcf5-19e7-457a-92e0-27741dafbd8a',
          name: 'HDB Housing Loan',
          amount: 12500000,
          ccy: 'SGD',
          category: {
            id: 'MORTGAGE',
            description: 'Mortgage',
          },
        },
        {
          id: '016e0229-da9a-4b19-804a-80ed4540fae9',
          name: 'Vehicle loan',
          amount: 4700000,
          ccy: 'SGD',
          category: {
            id: 'NON_MORTGAGE',
            description: 'Non-mortgage',
          },
        },
      ],
      loanRepaymentList: [
        {
          id: '9955ba28-2b6b-4d5b-8aec-272e87a483f6',
          name: 'HDB Housing Loan',
          amount: 59900,
          ccy: 'SGD',
          cash: 0,
          cpf: 59900,
          type: {
            id: 'MORTGAGE',
            description: 'Mortgage',
          },
        },
        {
          id: '9dfc7276-7fc6-489f-b2c1-f0402283fb82',
          name: 'Vehicle loan',
          amount: 90000,
          ccy: 'SGD',
          cash: 90000,
          cpf: 0,
          type: {
            id: 'NON_MORTGAGE',
            description: 'Non-mortgage',
          },
        },
      ],
      cashBalanceAssets: [],
      cashSavingsAssets: [
        {
          id: 'd972bee6-e9b0-46c5-9ef1-89c401d1f810',
          name: 'Cash savings',
          totalValueAmount: 2000000,
          currency: 'SGD',
          lastUpdatedAt: '2021-03-23T02:42:14.925Z',
        },
      ],
      goldAssets: [],
      bondAssets: [
        {
          id: '9192e8ca-0a64-419b-8257-f010ee9581c4',
          name: 'SSB',
          totalValueAmount: 200000,
          currency: 'SGD',
          investmentSource: 'CASH',
          quantity: 0,
          unitPrice: 0,
          enterOption: 'TOTAL_VALUE',
          estimatedGrowthRate: 12.34,
        },
      ],
      stockAssets: [],
      unitTrustAssets: [],
      fixedDepositAssets: [],
      insuranceAssets: [],
      structuredProductAssets: [],
      otherInvestmentAssets: [],
      otherNonInvestmentAssets: [],
      cpfAsset: {
        id: 'dd2be4a9-a209-49cc-a545-768086bcc628',
        oa: 0,
        sa: 0,
        ma: 0,
        ra: 0,
      },
      estimatedMonthlyExpenses: {
        id: '3a77b666-2596-46c9-8cc6-2a9bc909c777',
        enteredDate: '2021-03-23T02:40:20.167Z',
        amount: 90000,
        isDerived: true,
      },
      globalNotifications: [],
      goals: [
        {
          id: '6bd136e3-4d70-4928-9410-491e0bfb491c',
          name: 'Trip to Walt Disney World',
          totalAmount: 10000,
          currentAmount: 500000,
          date: '2023-03-31T16:00:00.000Z',
          archivedAcknowledged: false,
          paidOut: false,
          paidOutAmount: null,
          paidOutDate: null,
        },
      ],
      apimInfo: mockApimData.data.fpdata,
      recommendedActions: [
        {
          id: 'bff79654-5a8e-40b7-8e82-79d5cbc6894a',
          completedAt: '2021-03-23T02:44:39.127Z',
          key: {
            id: 'TRA_SR_D',
          },
          completedBy: {
            id: 'SYSTEM',
          },
        },
        {
          id: '97557511-27cc-4340-9826-50bbb902cb7a',
          completedAt: '2021-03-23T02:45:18.749Z',
          key: {
            id: 'TRA_SR_B',
          },
          completedBy: {
            id: 'SYSTEM',
          },
        },
      ],
      lastLogin: '2021-03-29T12:46:29.033Z',
      lastAccountStatus: null,
      isReturnUser: false,
      emailVerificationStatus: null,
      emailNotificationsStatus: null,
      housingPlans: [mockDefaultPlanResponse, mockCustomPlanResponse],
      investingForBeginners: {
        itemsCompleted: mockInvestmentForBeginnersItem,
        insurance: mockInvestmentForBeginnersInsurance,
      },
    },
  },
};

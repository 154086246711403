import styled from 'styled-components';

import { colorScale, typography } from 'themes';

export const Title = styled.span`
    ${typography('body-m-bold')}
  color: ${colorScale('grey', 90)};

`;

export const ProgressBarWrapper = styled.div`
  margin-top: 16px;
`;

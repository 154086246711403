import { StyleSheet } from '@react-pdf/renderer';

import { colors, fontSizes, padding, margin } from '../../styles';

export const BreakdownReminderSectionStyles = StyleSheet.create({
  reminderContainer: {
    marginTop: margin.large,
  },
  reminderMainTitle: {
    fontSize: fontSizes.body,
    fontWeight: 'bold',
    color: colors.grey90,
    marginBottom: margin.small,
  },
  reminderNumContainer: {
    marginRight: margin.xsmall,
  },
  remindersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  reminder: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 100,
    flex: 1,
    fontSize: fontSizes.body,
    paddingLeft: padding.small,
    paddingRight: padding.small,
  },
  reminderTitle: {
    fontSize: fontSizes.h2,
    fontWeight: 'bold',
    marginBottom: 2,
  },
});

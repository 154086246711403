import React from 'react';

import FamilyOnSofaWithUmbrella from './family-on-sofa-with-umbrella.svg';
import SampleInsuranceCard from './sample-insurance-card.svg';
import WellProtectedForSevenMonths from './well-protected-for-seven-months.svg';
import WomanHoldingUmbrellaInRain from './woman-holding-umbrella-in-rain.svg';

type ImageProps = React.HTMLAttributes<HTMLImageElement>;

export const WellProtectedForSevenMonthsImage = (props: ImageProps) => (
  <img {...props} src={WellProtectedForSevenMonths} alt="" />
);

export const SampleInsuranceCardImage = (props: ImageProps) => (
  <img {...props} src={SampleInsuranceCard} alt="" />
);

export const WomanHoldingUmbrellaInRainImage = (props: ImageProps) => (
  <img
    {...props}
    src={WomanHoldingUmbrellaInRain}
    alt="Rear view of woman in the rain holding an umbrella, with a cat on her left"
  />
);

export const FamilyOnSofaWithUmbrellaImage = (props: ImageProps) => (
  <img
    {...props}
    src={FamilyOnSofaWithUmbrella}
    alt="Mother and daughter is sitting on the sofa and the father is standing holding an umbrella"
  />
);

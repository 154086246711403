import React, { lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setGlobalModal } from 'redux/messages/actions';
import { getGlobalModal } from 'redux/messages/selectors';

import { GLOBAL_MODAL } from 'constants/enums';

const SessionExpiringModalContainer = lazy(() => import('./SessionExpiringModal'));

export const GlobalModalsContainer = () => {
  const dispatch = useDispatch();
  const globalModal = useSelector(getGlobalModal);

  const dismissModal = () => dispatch(setGlobalModal(null));

  return (
    <React.Fragment>
      <SessionExpiringModalContainer
        mounted={globalModal === GLOBAL_MODAL.SESSION_EXPIRING}
        dismissModal={dismissModal}
      />
    </React.Fragment>
  );
};

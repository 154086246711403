import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { SuperstarTemplate } from 'components/Templates';
import { SETTINGS } from 'constants/urls';
import { colorScale } from 'themes';
import { typography } from 'themesTypography';
import {
  APIM_MYINFO_RETRIEVAL_ENTRY_PAGE,
  setApimMyinfoRetrievalEntryPage,
} from 'utilities/sessionUtilities';

import { CTAButtons } from '../CTAButtons/CTAButtons';

const LockoutTextWrapper = styled.div`
  ${typography('body-m')};
  color: ${colorScale('grey', 90)};
  margin-bottom: 32px;
`;

const LockoutImageWrapper = styled.div`
  > img {
    width: 50%;
    height: auto;
  }
`;

const LockoutWrapper = styled.div`
  text-align: center;
`;

interface IProps {
  lockoutToolText: string;
  image: React.ReactElement;
  backCtaText: string;
  backCtaRoute: string;
  dataSelectionPageEntryPoint: APIM_MYINFO_RETRIEVAL_ENTRY_PAGE;
}

export const ToolLockoutPage = ({
  lockoutToolText,
  image,
  backCtaText,
  backCtaRoute,
  dataSelectionPageEntryPoint,
}: IProps) => {
  const history = useHistory();

  return (
    <SuperstarTemplate
      content={
        <LockoutWrapper>
          <LockoutImageWrapper>{image}</LockoutImageWrapper>
          <LockoutTextWrapper>
            <p>{lockoutToolText}</p>
          </LockoutTextWrapper>
          <CTAButtons
            primaryText={'Set up dashboard with SGFinDex'}
            primaryOnClick={() => {
              setApimMyinfoRetrievalEntryPage(dataSelectionPageEntryPoint);
              history.push(SETTINGS.DATASELECTION.main);
            }}
            secondaryText={backCtaText}
            secondaryOnClick={() => {
              history.push(backCtaRoute);
            }}
          />
        </LockoutWrapper>
      }
    />
  );
};

export default ToolLockoutPage;

import React from 'react';

import AliciaPersona from './alicia-persona.svg';
import BettyPersona from './betty-persona.svg';
import EnhancedRetirementSumMobile from './enhanced-retirement-sum-mobile.svg';
import EnhancedRetirementSumTablet from './enhanced-retirement-sum-tablet.svg';
import FinancialPlanningMobile from './financial-planning-mobile.svg';
import FinancialPlanningTablet from './financial-planning-tablet.svg';
import FullRetirementSumMobile from './full-retirement-sum-mobile.svg';
import FullRetirementSumTablet from './full-retirement-sum-tablet.svg';
import GrowingPotOfWealth from './growing-pot-of-wealth.svg';
import LongRoadAhead from './long-road-ahead.svg';
import PropertyMonetization from './property-monetization.svg';
import PuttingMoneyIntoCPF from './putting-money-into-cpf.svg';
import PuttingMoneyIntoSRS from './putting-money-into-srs.svg';
import RetirementSumSchemeMobile from './retirement-sum-scheme-mobile.svg';
import RetirementSumSchemeTablet from './retirement-sum-scheme-tablet.svg';

type ImageProps = React.HTMLAttributes<HTMLImageElement>;

export const LongRoadAheadImage = (props: ImageProps) => (
  <img {...props} src={LongRoadAhead} alt="" />
);

export const PuttingMoneyIntoCPFImage = (props: ImageProps) => (
  <img {...props} src={PuttingMoneyIntoCPF} alt="" />
);

export const PuttingMoneyIntoSRSImage = (props: ImageProps) => (
  <img {...props} src={PuttingMoneyIntoSRS} alt="" />
);

export const GrowingPotOfWealthImage = (props: ImageProps) => (
  <img {...props} src={GrowingPotOfWealth} alt="" />
);

export const AliciaPersonaImage = (props: ImageProps) => (
  <img {...props} src={AliciaPersona} alt="" />
);

export const BettyPersonaImage = (props: ImageProps) => (
  <img {...props} src={BettyPersona} alt="" />
);

export const PropertyMonetizationImage = (props: ImageProps) => (
  <img {...props} src={PropertyMonetization} alt="" />
);

export const RetirementSumSchemeMobileImage = (props: ImageProps) => (
  <img {...props} src={RetirementSumSchemeMobile} alt="" />
);

export const RetirementSumSchemeTabletImage = (props: ImageProps) => (
  <img {...props} src={RetirementSumSchemeTablet} alt="" />
);

export const EnhancedRetirementSumMobileImage = (props: ImageProps) => (
  <img {...props} src={EnhancedRetirementSumMobile} alt="" />
);

export const EnhancedRetirementSumTabletImage = (props: ImageProps) => (
  <img {...props} src={EnhancedRetirementSumTablet} alt="" />
);

export const FullRetirementSumMobileImage = (props: ImageProps) => (
  <img {...props} src={FullRetirementSumMobile} alt="" />
);

export const FullRetirementSumTabletImage = (props: ImageProps) => (
  <img {...props} src={FullRetirementSumTablet} alt="" />
);

export const FinancialPlanningMobileImage = (props: ImageProps) => (
  <img {...props} src={FinancialPlanningMobile} alt="" />
);

export const FinancialPlanningTabletImage = (props: ImageProps) => (
  <img {...props} src={FinancialPlanningTablet} alt="" />
);

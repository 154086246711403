import React from 'react';
import styled, { css } from 'styled-components';

import { IGoalForRender } from 'constants/storeTypes';
import { maxWidth, minWidth } from 'themes';

import { Modal } from '../Modal/Modal';

import { EditGoalModalTogglerContainer } from './EditGoalModalToggler';

const StyledModal = styled(Modal)`
  text-align: left;

  ${maxWidth(
    'mobile',
    css`
      width: 100vw;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      width: 480px;
    `
  )}
`;

interface IEditTargetModalProps {
  mounted: boolean;
  onExit: (event?: React.SyntheticEvent) => void;
  target: IGoalForRender;
  addToast: (message: string) => void;
  archivable?: boolean;
  analyticsSource: 'Targets page' | 'Review savings';
  monthlySavings?: number;
  setToMinimumMonthlySavingsAndCloseModal?: () => void;
  minimumMonthlySavings?: number;
}

interface IState {
  shouldAllowModalCloseFunctions: boolean;
  shouldShowWarningContent: boolean;
  hasDataChanged: boolean;
}

export class EditTargetModal extends React.Component<IEditTargetModalProps, IState> {
  public static defaultProps = {
    target: {
      id: 'default-goal',
      name: 'default-goal',
      totalAmount: 0,
      currentAmount: 0,
      date: new Date(),
      archivedAcknowledged: false,
      paidOut: false,
      paidOutAmount: null,
      paidOutDate: null,
    },
    archivable: false,
  };

  constructor(props: IEditTargetModalProps) {
    super(props);

    this.state = {
      shouldAllowModalCloseFunctions: true,
      shouldShowWarningContent: false,
      hasDataChanged: false,
    };

    this.allowModalCloseFunctions = this.allowModalCloseFunctions.bind(this);
    this.blockModalCloseFunctions = this.blockModalCloseFunctions.bind(this);
    this.handleExitAction = this.handleExitAction.bind(this);
    this.setHasDataChanged = this.setHasDataChanged.bind(this);
    this.cancelDiscardWarning = this.cancelDiscardWarning.bind(this);
  }

  public allowModalCloseFunctions() {
    this.setState({
      shouldAllowModalCloseFunctions: true,
    });
  }

  public blockModalCloseFunctions() {
    this.setState({
      shouldAllowModalCloseFunctions: false,
    });
  }

  public handleExitAction() {
    if (this.state.hasDataChanged) {
      this.blockModalCloseFunctions();
      this.setState({ shouldShowWarningContent: true });
    } else {
      this.props.onExit();
    }
  }

  public cancelDiscardWarning() {
    this.allowModalCloseFunctions();
    this.setState({ shouldShowWarningContent: false });
  }

  public setHasDataChanged(setValue: boolean) {
    this.setState({ hasDataChanged: setValue });
  }

  public render() {
    const {
      mounted,
      onExit,
      target,
      addToast,
      archivable,
      analyticsSource,
      monthlySavings,
      setToMinimumMonthlySavingsAndCloseModal,
      minimumMonthlySavings,
    } = this.props;
    const { shouldAllowModalCloseFunctions, shouldShowWarningContent } = this.state;

    return (
      <StyledModal
        mounted={mounted}
        underlayClickExits={shouldAllowModalCloseFunctions}
        showCloseButton={shouldAllowModalCloseFunctions}
        escapeExits={shouldAllowModalCloseFunctions}
        titleText="edit-target-modal"
        onExit={this.handleExitAction}
        alert={!shouldAllowModalCloseFunctions}
        renderChildren={(state) => (
          <EditGoalModalTogglerContainer
            onExit={onExit}
            goal={target}
            transitionStatus={state}
            allowModalCloseFunctions={this.allowModalCloseFunctions}
            blockModalCloseFunctions={this.blockModalCloseFunctions}
            setHasDataChanged={this.setHasDataChanged}
            cancelDiscardWarning={this.cancelDiscardWarning}
            shouldShowWarningContent={shouldShowWarningContent}
            addToast={addToast}
            archivable={archivable}
            analyticsSource={analyticsSource}
            monthlySavings={monthlySavings}
            setToMinimumMonthlySavingsAndCloseModal={setToMinimumMonthlySavingsAndCloseModal}
            minimumMonthlySavings={minimumMonthlySavings}
          />
        )}
      />
    );
  }
}

import React from 'react';
import { isBrowser } from 'react-device-detect';

import { Input } from 'components/Common';
import { ValidationContainer, required } from 'components/Validations';
import { ASSET_TYPE, INVESTMENT_SOURCE } from 'constants/enums';

import { Subtext } from '../Common/Subtext';

export const InvestmentSourceContext = React.createContext(INVESTMENT_SOURCE.CASH);

export interface INameInvestmentSourceProps {
  investmentSource: INVESTMENT_SOURCE;
  name: string;
  handleNameChange: (name: string) => void;
  investmentType: ASSET_TYPE;
  onClickInvestmentSource?: () => void;
  readOnly?: boolean;
  autoFocus?: boolean;
}

export const NameInvestmentSourceSection: React.FC<INameInvestmentSourceProps> = ({
  investmentSource,
  name,
  handleNameChange,
  investmentType,
  onClickInvestmentSource,
  readOnly,
  autoFocus,
}) => {
  return (
    <ValidationContainer
      value={name}
      validations={[required]}
      render={(validationsParams) => {
        return (
          <Input
            label="Name"
            errorMessage={validationsParams.errorMessage}
            onBlur={validationsParams.onBlur}
            onFocus={validationsParams.onFocus}
            value={name}
            minNumChars={24}
            maxNumChars={24}
            widthStyle="elastic"
            readOnly={readOnly}
            autoFocus={isBrowser && autoFocus}
            handleChange={(name) => handleNameChange(name)}
            subtext={
              <Subtext
                investmentType={investmentType}
                onClickInvestmentSource={onClickInvestmentSource}
                investmentSource={investmentSource}
              />
            }
          />
        );
      }}
    />
  );
};

import React, { useState, useContext } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { ifProp, ifNotProp, theme as fromTheme } from 'styled-tools';

import { IconButton } from 'components/Common';
import { shadowScale, colorScale } from 'themes';

import { FocusTrapContext } from './FocusTrapContext';
import { HamburgerIcon } from './HamburgerIcon';

const navBarHeight = fromTheme('heights.navbar');

export const GlobalStyle = createGlobalStyle<{ isMenuOpened: boolean }>`
  ${ifProp(
    'isMenuOpened',
    css`
      body {
        overflow: hidden;
      }
    `
  )};
`;

const Wrapper = styled.div`
  display: flex;
`;

const positionProperties = css`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  ${ifProp(
    'isMenuOpened',
    css`
      top: ${() => {
        const nav = document.querySelector('nav');
        let top = '0px';
        if (nav) {
          top = `${nav.getBoundingClientRect().bottom}px`;
        }
        return top;
      }};
    `
  )}
`;

const Underlay = styled.div<{ isMenuOpened: boolean }>`
  ${positionProperties}
  background-color: ${colorScale('grey', 80)};
  opacity: 0.5;
  ${ifNotProp(
    'isMenuOpened',
    css`
      display: none;
    `
  )};
`;

const Menu = styled.div<{ isMenuOpened: boolean }>`
  ${positionProperties}
  transition: ${fromTheme('transitions.durationLonger')} ease-in-out;
  clip-path: inset(0 0 -100% 0);
  pointer-events: none;

  ${ifProp(
    'isMenuOpened',
    css`
      opacity: 1;
    `,
    css`
      opacity: 0;
    `
  )};
`;

const Links = styled.div<{ isMenuOpened: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: ${fromTheme('transitions.durationLonger')} ease-in-out;
  width: 100%;
  max-height: 100%;
  background: white;
  box-shadow: ${shadowScale(2)};
  pointer-events: auto;
  padding: 16px;
  overflow-y: scroll;

  & > * {
    width: 100%;
  }

  & > button {
    margin: 16px 0;
  }

  ${ifProp(
    'isMenuOpened',
    css`
      visibility: visible;
      transform: translateY(0);
    `,
    css`
      visibility: hidden;
      transform: translateY(-100%);
    `
  )};
`;

const HamburgerButton = styled(IconButton)<{ isMenuOpened: boolean }>`
  align-self: center;
  padding: 0;
  border-radius: 0;
  width: ${navBarHeight};
  height: ${navBarHeight};

  &:focus {
    background: inherit;
  }
`;

interface IHamburgerMenuItem {
  setIsMenuOpened: (isMenuOpened: boolean) => void;
}

interface IProps {
  renderMenuItems: ({ setIsMenuOpened }: IHamburgerMenuItem) => React.ReactElement;
}

export const HamburgerMenu = ({ renderMenuItems }: IProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const setIsFocusTrapped = useContext(FocusTrapContext);
  const toggleMenuOpened = () => {
    setIsFocusTrapped(!isMenuOpened);
    setIsMenuOpened((prevIsMenuOpened) => !prevIsMenuOpened);
  };

  return (
    <Wrapper>
      <GlobalStyle isMenuOpened={isMenuOpened} />
      <Underlay isMenuOpened={isMenuOpened} onClick={toggleMenuOpened} />
      <Menu isMenuOpened={isMenuOpened}>
        <Links isMenuOpened={isMenuOpened}>
          {renderMenuItems({
            setIsMenuOpened: (isOpened: boolean) => {
              setIsMenuOpened(isOpened);
              setIsFocusTrapped(isOpened);
            },
          })}
        </Links>
      </Menu>
      <HamburgerButton
        isMenuOpened={isMenuOpened}
        onClick={toggleMenuOpened}
        ariaLabel="Hamburger button"
      >
        <HamburgerIcon isOpened={isMenuOpened} />
      </HamburgerButton>
    </Wrapper>
  );
};

import React from 'react';

import { BasePage } from '../BasePage/BasePage';
import { IBreakdownTableProps } from '../interfaces';

import { BreakdownReminderSection } from './BreakdownReminderSection/BreakdownReminderSection';
import { BreakdownTable } from './BreakdownTable/BreakdownTable';

interface IProps extends IBreakdownTableProps {
  pageTitle: string;
  pageSubtitle: string;
}

// Note: (MAGIC NUMBER) this is the max number of rows that
// can appear in the last Breakdown page to show
// the reminder section
const MAX_EXPENSES_LAST_PAGE_MAX = 13;

export const BreakdownPage = (props: IProps) => {
  const { expenses, showTotalRow } = props;
  const finalPageOverflow = showTotalRow && expenses.length > MAX_EXPENSES_LAST_PAGE_MAX;

  // checks if last page overflows as it contains the reminder section
  // if it overflows (i.e. > 13 rows), we manually break into 2 pages
  // since there's no auto wrapping :(
  if (finalPageOverflow) {
    const overflowLen = expenses.length - MAX_EXPENSES_LAST_PAGE_MAX;

    return (
      <React.Fragment>
        <BasePage title={props.pageTitle} subtitle={props.pageSubtitle}>
          <BreakdownTable
            {...props}
            expenses={expenses.slice(0, -overflowLen)}
            showTotalRow={false}
          />
        </BasePage>
        <BasePage title={props.pageTitle} subtitle={props.pageSubtitle}>
          <BreakdownTable {...props} expenses={expenses.slice(-overflowLen)} />
          {props.showTotalRow && <BreakdownReminderSection />}
        </BasePage>
      </React.Fragment>
    );
  }

  // no overflow in last page (i.e. <= 13 rows)
  // will render normally with reminder section
  return (
    <BasePage title={props.pageTitle} subtitle={props.pageSubtitle}>
      <BreakdownTable {...props} />
      {props.showTotalRow && <BreakdownReminderSection />}
    </BasePage>
  );
};

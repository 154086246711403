import React from 'react';
import styled from 'styled-components';

import { Tick, Dash } from 'assets/icons';
import { SkeletonLoading } from 'components/Common';
import { colorScale, typography } from 'themes';

type IProps = {
  hasItem: boolean;
  content: React.ReactNode;
}[];

export enum SIZES {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
}

// TODO: [FP-0000] Consider making the component more dynamic to more type scales
const sizeMapper = (enumValue: SIZES) => {
  switch (enumValue) {
    case SIZES.SMALL:
      return typography('body-s');
    case SIZES.MEDIUM:
      return typography('body-m');
    case SIZES.LARGE:
      return typography('body-l');
    default:
      return typography('body-m');
  }
};

const ListContainer = styled.div<{ size: SIZES }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${(props) => sizeMapper(props.size)}
`;

const StyledTick = styled(Tick)`
  color: ${colorScale('primary', 40)};
`;

const StyledDash = styled(Dash)`
  color: ${colorScale('grey', 50)};
`;

const StyledListItem = styled.span`
  display: flex;
`;

const StyledSkeletonLoading = styled(SkeletonLoading)`
  border-radius: 50%;
  width: 1em;
  height: 1em;
`;

// position: relative and top: 0.125em are used to pull the icon down to the typography baseline
const IconContainer = styled.span`
  margin-right: 8px;
  height: 1em;
  width: 1em;
  position: relative;
  top: 0.125em;
`;

export const IconCheckList = ({
  items,
  isLoading,
  size,
  ...otherProps
}: {
  items: IProps;
  isLoading: boolean;
  size: SIZES;
}) => {
  return (
    <ListContainer size={size} {...otherProps}>
      {items.map((item, index) => (
        <StyledListItem key={index} data-testid="icon-checklist-item">
          <IconContainer>
            <StyledSkeletonLoading key={index} guesstimateContent="✅" isLoading={isLoading}>
              {item.hasItem ? (
                <StyledTick data-testid="icon-checklist-check" />
              ) : (
                <StyledDash data-testid="icon-checklist-dash" />
              )}
            </StyledSkeletonLoading>
          </IconContainer>
          {item.content}
        </StyledListItem>
      ))}
    </ListContainer>
  );
};

import React from 'react';

import { useIdentifierCookie } from 'hooks/useIdentifierCookie';

import { FlexWrapper } from '../sharedStyles';

import { CallToActionSection } from './CallToActionSection';
import { DemoSection } from './DemoSection/DemoSection';
import { FLQAnnouncementBanner } from './FLQAnnouncementBanner';
import { InfoSection } from './InfoSection';
import { MaintenanceBanner } from './MaintenanceBanner';
import { ToolsSection } from './ToolsSection';
import { TopSection } from './TopSection';

export const MainLandingPage = () => {
  useIdentifierCookie();

  return (
    <FlexWrapper>
      <MaintenanceBanner />
      <FLQAnnouncementBanner />
      <TopSection />
      <DemoSection />
      <InfoSection />
      <ToolsSection />
      <CallToActionSection />
    </FlexWrapper>
  );
};

export default MainLandingPage;

import axios from 'axios';

import { captureEmergencyFundAchievability } from 'analytics';
import {
  RequestAction,
  ICreateGoal,
  IDeleteGoal,
  ISetEmergencyFund,
  IUpdateGoal,
} from 'constants/actionTypes';
import { TARGETS_ACTIONS } from 'constants/enums';
import {
  IEmergencyFund,
  IEmergencyFundForRender,
  IGoal,
  IGoalForRender,
} from 'constants/storeTypes';
import { getDateDiffInMonths } from 'utilities/dateUtilities';

import { getEmergencyFund } from './selectors';

export const createEmergencyFundRequest = (emergencyFundAmount: number): RequestAction => (
  dispatch
) => {
  const emergencyFund: IEmergencyFund = {
    currentAmount: emergencyFundAmount,
    totalAmount: emergencyFundAmount,
    date: new Date().toISOString(),
    prevAccumulatedMonths: 0,
  };

  const promise = axios.request({
    method: 'PUT',
    url: `${process.env.REACT_APP_API_URL}/v1/emergency-fund`,
    data: emergencyFund,
  });

  promise
    .then(() => {
      dispatch(setEmergencyFund(emergencyFund));
      dispatch(captureEmergencyFundAchievability());
    })
    .catch(() => {});

  return promise;
};

export const updateEmergencyFundRequest = (
  update: Partial<IEmergencyFundForRender>
): RequestAction => async (dispatch, getState) => {
  const emergencyFund = getEmergencyFund(getState());

  const newEmergencyFund: IEmergencyFund = {
    currentAmount: emergencyFund.currentAmount,
    totalAmount: emergencyFund.totalAmount,
    prevAccumulatedMonths: emergencyFund.prevAccumulatedMonths,
    ...update,
    date: (update.date || emergencyFund.date).toISOString(),
  };

  const { currentAmount, totalAmount } = newEmergencyFund;
  if (totalAmount < currentAmount) {
    newEmergencyFund.currentAmount = totalAmount;
  }

  const promise = axios.request({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}/v1/emergency-fund`,
    data: newEmergencyFund,
  });

  promise
    .then(() => {
      dispatch(setEmergencyFund(newEmergencyFund));
      dispatch(captureEmergencyFundAchievability());
    })
    .catch(() => {});

  return promise;
};

export const createGoalRequest = (goal: Omit<IGoal, 'id'>): RequestAction => async (dispatch) => {
  const promise = axios.post(`${process.env.REACT_APP_API_URL}/v1/goal`, goal);

  promise
    .then(({ data: { id } }) => {
      dispatch(createGoal({ ...goal, id }));
    })
    .catch(() => {});

  return promise;
};

export const updateGoalRequest = (
  id: string,
  goal: Partial<IGoalForRender>,
  currentDate: Date = new Date()
): RequestAction => async (dispatch, getState) => {
  const currentGoal = getState().targets.goals[id];
  const currentGoalDate = new Date(currentGoal.date);

  const isGoalRestored =
    goal.date &&
    getDateDiffInMonths(currentDate, currentGoalDate) > 0 &&
    getDateDiffInMonths(currentDate, goal.date) <= 0;

  const goalDetails = isGoalRestored
    ? { ...goal, currentAmount: 0, archivedAcknowledged: false }
    : goal;
  const goalDetailsRequest: Partial<IGoal> = {
    ...(goalDetails as Omit<typeof goalDetails, 'date' | 'paidOutDate'>),
    ...(goal.date && { date: goal.date.toISOString() }),
    ...(goal.paidOutDate && { paidOutDate: goal.paidOutDate.toISOString() }),
  };

  const promise = axios.patch(`${process.env.REACT_APP_API_URL}/v1/goal/${id}`, goalDetailsRequest);

  promise
    .then(() => {
      dispatch(updateGoal(id, goalDetailsRequest, currentDate));
    })
    .catch(() => {});

  return promise;
};

export const deleteGoalRequest = (id: string): RequestAction => async (dispatch) => {
  const promise = axios.delete(`${process.env.REACT_APP_API_URL}/v1/goal/${id}`);

  promise
    .then(() => {
      dispatch(deleteGoal(id));
    })
    .catch(() => {});

  return promise;
};

export const createGoal = (goal: IGoal): ICreateGoal => ({
  type: TARGETS_ACTIONS.CREATE_GOAL,
  goal,
});

export const updateGoal = (
  id: string,
  goal: Partial<IGoal>,
  currentDate: Date = new Date()
): IUpdateGoal => ({
  type: TARGETS_ACTIONS.UPDATE_GOAL,
  id,
  goal,
  currentDate,
});

export const deleteGoal = (id: string): IDeleteGoal => ({
  type: TARGETS_ACTIONS.DELETE_GOAL,
  id,
});

export const setEmergencyFund = (details: Partial<IEmergencyFund>): ISetEmergencyFund => ({
  type: TARGETS_ACTIONS.SET_EMERGENCY_FUND,
  details,
});

export const updatePrevAccumulatedMonths = (noOfMonths: number) => ({
  type: TARGETS_ACTIONS.UPDATE_PREV_ACCUMULATED_MONTHS,
  noOfMonths,
});

import {
  PLAN_TYPE,
  PLAN_COLOR,
  FLAT_TYPE,
  FLAT_SIZE,
  LOAN_TYPE,
  PAYMENT_METHOD,
  HOUSEHOLD_TYPE,
  PLAN_STEP,
} from 'constants/enums';
import { INITIAL_DRAFT_HOUSING_PLAN_ID } from 'constants/housingConstants';
import { IHousingPlan } from 'constants/storeTypes';

import { IHousingOutputs } from '../interfaces';

export const initialCustomizedDraftHousingPlan: IHousingPlan = {
  id: INITIAL_DRAFT_HOUSING_PLAN_ID,
  isPlanCompleted: false,
  planType: PLAN_TYPE.CUSTOM,
  planStep: PLAN_STEP.BASIC_AND_FINANCIAL_INFORMATION,
  planName: 'My new housing plan',
  planColor: PLAN_COLOR.RED,
  firstTimeBuyer: true,
  householdType: HOUSEHOLD_TYPE.FAMILY,
  noOfBuyers: 1,
  monthlyHouseholdIncome: 0,
  cpfOABalance: 0,
  cashSavings: 0,
  estimatedFlatBudget: 0,
  flatValueAtTimeOfPurchase: 0,
  flatType: FLAT_TYPE.NEW,
  flatSize: FLAT_SIZE.TWO_ROOM,
  loanType: LOAN_TYPE.HDB,
  upfrontPaymentInCash: 0,
  cpfHousingGrant: 0,
  enhancedCpfHousingGrant: 0,
  proximityGrant: 0,
  interestRate: 2.6,
  loanPeriod: 0,
  cpfMonthlyInstalment: 0,
  buyerStampDuty: {
    amount: 0,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  optionFee: {
    amount: 50000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  conveyancingFee: {
    amount: 2000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  propertyAgentCommission: {
    amount: 0,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  registrationFee: {
    amount: 0,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  surveyFee: {
    amount: 16050,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  leaseInEscrowFee: {
    amount: 3830,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  mortgageInEscrowFee: {
    amount: 3830,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  stampDutyDeedFee: {
    amount: 0,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  renovationCost: {
    amount: 3800000,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  serviceAndConservancyCharges: {
    amount: 3100,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  annualPropertyTax: {
    amount: 0,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  mortgageInsurancePremiums: {
    amount: 17920,
    paymentMethod: PAYMENT_METHOD.CASH,
  },
  lastUpdatedAt: new Date().toISOString(),
};

export const initialCustomizedHousingOutputs: IHousingOutputs = {
  minimumPayment: 0,
  paymentInCpf: 0,
  totalAmountLeftInCpfOA: 0,
  estimatedLoanAmount: 0,
  monthlyMortgageInstalment: NaN,
  mortgageServicingRatio: 0,
  totalLoanPayment: NaN,
  totalInterest: NaN,
  percentageOfTotalPayment: NaN,
  applicationFee: 1000,
  upfrontPaymentInCpf: 0,
  cashMonthlyInstalment: 0,
  additionalBuyerStampDuty: 0,
  totalBookingOfFlatPayments: 50000,
  totalSigningOfAgreementForLeasePayments: 2000,
  totalCollectionOfKeysPayments: 3823710,
  totalOneTimePayments: 3876710,
  totalOneTimePaymentsInCash: 3876710,
  totalOneTimePaymentsInCpf: 0,
  totalMonthlyPayment: NaN,
  totalMonthlyPaymentInCash: NaN,
  totalMonthlyPaymentInCpf: 0,
  fireInsurancePremium: 271,
  totalAnnualPayment: 18191,
  totalAnnualPaymentInCash: 18191,
  totalAnnualPaymentInCpf: 0,
  // Customized plan only fields
  requestForValueOfFlat: 0,
  cashOverValuation: 0,
  caveatRegistrationFee: 0,
  titleSearchFee: 0,
  valuationLimit: 0,
  withdrawalLimit: 0,
  noOfYearsToReachCpfUsageLimits: 0,
  noOfRemainingMonthsToReachCpf: 0,
};

import { View, Text } from '@react-pdf/renderer';
import React from 'react';

import { padding, margin } from '../../styles';

import { BreakdownReminderSectionStyles as styles } from './BreakdownReminderSectionStyles';

const reminders = [
  {
    title: 'Prioritise',
    desc: 'your expenses. Take care of\nyour needs before spending\non your wants.',
  },
  {
    title: 'Keep',
    desc: 'to your target amount and\nadjust it when your income\nor life circumstances\nchange.',
  },
  {
    title: 'Save',
    desc: 'a portion of your income\nor payouts. It will come in\nhandy in emergencies.',
  },
];
const remindersLen = reminders.length;

export const BreakdownReminderSection: React.FC = () => (
  <View style={styles.reminderContainer}>
    <Text style={styles.reminderMainTitle}>REMEMBER TO:</Text>
    <View style={styles.remindersContainer}>
      {reminders.map((r, idx) => {
        const children = (
          <React.Fragment>
            <View style={styles.reminderNumContainer}>
              <Text style={styles.reminderTitle}>{idx + 1}.</Text>
            </View>
            <View>
              <Text style={styles.reminderTitle}> {r.title}</Text>
              <Text>{r.desc}</Text>
            </View>
          </React.Fragment>
        );

        if (remindersLen === idx + 1) {
          return (
            <View key={r.title} style={styles.reminder}>
              {children}
            </View>
          );
        }
        return (
          <View
            key={r.title}
            style={[styles.reminder, { marginRight: margin.medium - padding.small * 2 }]}
          >
            {children}
          </View>
        );
      })}
    </View>
  </View>
);

import React from 'react';
import styled from 'styled-components';

import { SingpassLoginButtonImage } from 'assets/Singpass';
import { RoutedButton } from 'components/Common';
import { LOGIN } from 'constants/urls';

const StyledRoutedButton = styled(RoutedButton)`
  margin: 8px 0;
  padding: 0;
  height: 40px;
  background: transparent;
`;

export const LoginWithSingpassButton = ({
  className,
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <StyledRoutedButton to={LOGIN.singpassRedirect} className={className}>
    <SingpassLoginButtonImage />
  </StyledRoutedButton>
);

import React from 'react';

import { InfoModal, IInfoModalProps } from '../Common/InfoModal/InfoModal';

type IProps = Omit<IInfoModalProps, 'title' | 'titleText'>;

export const AvailableFundsModal: React.FC<IProps> = ({ mounted = false, onExit = () => {} }) => (
  <InfoModal
    titleText="Available funds info modal"
    title="Available funds"
    mounted={mounted}
    onExit={onExit}
    data-testid="available-funds-modal"
  >
    <p>This includes your cash savings and fixed deposits that can be quickly withdrawn in cash.</p>
  </InfoModal>
);

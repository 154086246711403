import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Panel, Slider, AmountField } from 'components/Common';
import { colorScale, minWidth, maxWidth, typography } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';
import { formatDate } from 'utilities/dateUtilities';
import { orderOfMagnitude } from 'utilities/mathUtilities';

const StyledPanel = styled(Panel)`
  ${maxWidth(
    'mobile',
    css`
      border-radius: 0;
    `
  )}
  padding-bottom: 36px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: 'date content';
  grid-template-columns: min-content auto;
  grid-column-gap: 28px;

  ${minWidth(
    'desktop',
    css`
      grid-template-columns: min-content 0.8fr;
    `
  )}
`;

const DateWrapper = styled.div`
  grid-area: date;
  display: flex;
  justify-self: center;
  align-self: flex-start;
  align-items: flex-start;
  height: 100%;
  font-weight: 600;
  text-align: center;
`;

const MonthText = styled.span`
  ${typography('heading-s')};
`;

const YearText = styled.span`
  ${typography('label')};
  font-weight: bold !important;
`;

const ContentWrapper = styled.div`
  grid-area: content;
`;

const Title = styled.div`
  grid-area: title;
  ${typography('heading-s')}
  margin-bottom: 24px;
`;

const TargetAmountWrapper = styled.div`
  grid-area: amount-field;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
`;

const TargetTotalAmountText = styled.span`
  margin-top: 4px;
  color: ${colorScale('grey', 80)};
  ${typography('body-s')}
`;

const StyledAmountField = styled(AmountField)`
  ${typography('body-m')}
`;

const SliderWrapper = styled.div`
  grid-area: slider;
`;

interface IProps {
  title: string;
  currentAmount: number;
  totalAmount: number;
  id: string;
  date: Date;
  onAmountChange: (amount: number, id: string) => void;
}

export const AllocateSavingsPanel: React.FC<IProps> = React.memo(
  ({ title, currentAmount, totalAmount, date, id, onAmountChange }) => {
    const step = useMemo(() => Math.max(1, Math.pow(10, orderOfMagnitude(totalAmount) - 2)), [
      totalAmount,
    ]);

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onAmountChange(Number(e.target.value), id);
    };

    const handleAmountFieldChange = (amount: number) => {
      onAmountChange(amount, id);
    };

    const [month, year] = formatDate(date).split(' ');

    return (
      <StyledPanel data-testid="alloc-savings-panel">
        <Wrapper>
          <DateWrapper>
            <div>
              <MonthText>{month.substr(0, 3).toUpperCase()}</MonthText>
              <br />
              <YearText>{year}</YearText>
            </div>
          </DateWrapper>
          <ContentWrapper>
            <Title data-testid={`alloc-savings-panel-title-${id}`}>{title}</Title>
            <TargetAmountWrapper>
              <StyledAmountField
                label={title}
                hideLabel={true}
                widthStyle="elastic"
                minNumChars={8}
                amount={currentAmount}
                minAmount={0}
                maxAmount={totalAmount}
                required={true}
                onInputChange={handleAmountFieldChange}
                addonBefore={<AmountField.DollarSign />}
              />
              <TargetTotalAmountText>out of {formatCurrency(totalAmount)}</TargetTotalAmountText>
            </TargetAmountWrapper>
            <SliderWrapper>
              <Slider
                value={currentAmount}
                onChange={handleSliderChange}
                min={0}
                max={totalAmount}
                step={step}
                hasTrail={true}
                height="16px"
                data-testid={`alloc-savings-panel-slider-${id}`}
              />
            </SliderWrapper>
          </ContentWrapper>
        </Wrapper>
      </StyledPanel>
    );
  }
);

import { Omit } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, withProp } from 'styled-tools';

import { colorScale, typography } from 'themes';
import { visuallyHiddenProperties } from 'utilities/sharedStyles';

import { uniqueIdGenerator } from '../uniqueIdGenerator/uniqueIdGenerator';

import { ClearButton } from './ClearButton';
import { Label, CustomLabelWrapper } from './Label';
import { MinusButton } from './MinusButton';
import { PlusButton } from './PlusButton';
import { baseProperties, sizeProperties, widthStyleProperties } from './sharedStyles';
import { inputWrapperProperties } from './styles/styleProperties';

interface IOwnProps {
  /** Input's value */
  value?: string;

  /**
   * Specify width of inputs, if not specified, fits to the
   * value, placeholder and label of the input
   */
  widthStyle?: 'elastic' | 'fluid';

  /** Defines the input's mininum number of characters regardless of width style */
  minNumChars?: number;

  /** Defines the input's maximum number of characters regardless of width style */
  maxNumChars?: number;

  /** Defines the size of input font. Defaults to matching the parent's font size */
  fontSize?: 'default' | 'medium' | 'large';

  /** Addon before the input (e.g. search icon, currency selector button) */
  addonBefore?: React.ReactElement;

  /** Addon after the input (e.g. search button, per month string) */
  addonAfter?: React.ReactElement;

  /** Addon before the input that is outside the input field */
  externalAddonBefore?: React.ReactElement;

  /** Addon after the input that is outside the input field */
  externalAddonAfter?: React.ReactElement;

  /**
   * Label for the input. It is required to ensure all inputs are labelled.
   * Hide the label visually by using the `hideLabel` prop.
   * Can be customised by passing in a ReactElement
   */
  label: string | React.ReactElement;

  /** Hides the label visually */
  hideLabel?: boolean;

  /** Whether label should collapse when input is empty */
  collapseLabel?: boolean;

  /** Show clear button which appears on typing */
  showClearButton?: boolean;

  /** Callback to handle clearing of input */
  onClearInput?: () => void;

  /** Error message */
  errorMessage?: string;

  /** Used to access input ref */
  inputRef?: React.RefObject<HTMLInputElement>;

  /** Used to access wrapper */
  wrapperRef?: React.RefObject<HTMLDivElement>;

  /** Used to override the default id for custom labels */
  overrideId?: string;

  /** Subtext shown beneath the input */
  subtext?: string | React.ReactElement;

  /** Filters input keyed in. Returns null if input is invalid */
  inputFilter?: (value: string) => string | null;

  handleChange?: (value: string) => void;
}

export type IInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id'> & IOwnProps;

interface IOwnState {
  inputWidth: number;
  isInputFocused: boolean;
}

const Wrapper = styled.div<{ widthStyle?: 'elastic' | 'fluid' }>`
  justify-self: start;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${ifProp(
    { widthStyle: 'fluid' },
    css`
      width: 100%;
    `
  )};

  &:focus-within {
    & > .input-label {
      transform: translateY(0);
      color: ${colorScale('primary', 40)};
    }
  }
`;

const InnerWrapper = styled.div<{ widthStyle?: 'elastic' | 'fluid'; maxNumChars?: number }>`
  display: grid;
  grid-template-areas:
    'external-addon-before input external-addon-after'
    'bottom-text bottom-text bottom-text';
  grid-template-columns: minmax(0, auto) 1fr minmax(0, auto);

  max-width: 100%;
  ${ifProp(
    { widthStyle: 'fluid' },
    ifNotProp(
      'maxNumChars',
      css`
        align-self: stretch;
      `
    )
  )}
`;

const SizerSpan = styled.span`
  ${visuallyHiddenProperties}
  width: auto;
  white-space: pre;
`;

const StyledInput = styled.input<{ minNumChars?: number; maxNumChars?: number }>`
  appearance: none;
  font: inherit;
  color: ${colorScale('grey', 90)};
  background: transparent;
  border: none;
  padding: 0;
  cursor: inherit;
  min-width: ${withProp('minNumChars', (chars) => (chars ? `${chars}ch` : 0))};
  max-width: ${withProp('maxNumChars', (chars) => (chars ? `${chars}ch` : '100%'))};
  text-overflow: ellipsis;

  &::placeholder {
    color: ${colorScale('grey', 50)};
  }

  &:focus {
    outline: none;
  }
`;

const BottomText = styled.div<{ variant?: 'subtext' | 'errorMessage' }>`
  grid-area: bottom-text;
  ${typography('body-s')}
  margin-top: 8px;
  color: ${colorScale('grey', 80)};

  ${ifProp(
    { variant: 'errorMessage' },
    css`
      color: ${colorScale('supporting-red', 50)};
    `
  )}
`;

const ExternalAddonBeforeWrapper = styled.div`
  grid-area: external-addon-before;
  align-self: center;
  margin-right: 8px;
`;

const ExternalAddonAfterWrapper = styled.div`
  grid-area: external-addon-after;
  align-self: center;
  margin-left: 8px;
`;

const InputWrapper = styled.div<Partial<IInputProps> & { hasError: boolean }>`
  ${baseProperties}
  ${sizeProperties}
  ${widthStyleProperties}
  ${inputWrapperProperties}
`;

export class Input extends React.PureComponent<IInputProps, IOwnState> {
  public static defaultProps = {
    collapseLabel: true,
    widthStyle: 'elastic',
    fontSize: 'default',
    hideLabel: false,
    showClearButton: false,
  };
  private static readonly idGenerator = uniqueIdGenerator('Input');
  public static MinusButton = MinusButton;
  public static PlusButton = PlusButton;

  private readonly id: string;
  private readonly sizerRef: React.RefObject<HTMLSpanElement>;
  private readonly internalInputRef: React.RefObject<HTMLInputElement>;

  private wasFocused = false;

  constructor(props: IInputProps) {
    super(props);
    this.state = {
      inputWidth: 0,
      isInputFocused: false,
    };
    this.id = Input.idGenerator.next().value as string;
    this.sizerRef = React.createRef<HTMLSpanElement>();

    // Used to ensure clicking anywhere in the input wrapper causes the input to be focused
    // This is used when no inputRef is provided
    this.internalInputRef = React.createRef<HTMLInputElement>();

    this.focusOnInput = this.focusOnInput.bind(this);
    this.updateInputWidth = this.updateInputWidth.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);

    if (props.showClearButton && !props.onClearInput) {
      throw new Error('Enabling showClearButton requires onClearInput to be defined too');
    }

    const value = props.value;
    const hasInput = Boolean(value);
    if (!hasInput && props.disabled) {
      throw new Error('Disabled Input components need to have a value');
    }
  }

  public componentDidMount() {
    this.updateInputWidth();
    window.addEventListener('resize', this.handleWindowResize);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  public componentDidUpdate(prevProps: IInputProps) {
    // These conditions affect the size of the input field and
    // should trigger a recalculation of the width based on the sizer span.
    // widthStyle needs to be checked because it
    // conditionally renders the sizer span.
    if (
      prevProps.value !== this.props.value ||
      prevProps.widthStyle !== this.props.widthStyle ||
      prevProps.fontSize !== this.props.fontSize
    ) {
      this.updateInputWidth();
    }
  }

  private handleWindowResize() {
    if (this.wasFocused && (this.props.inputRef || this.internalInputRef)) {
      const inputRef = this.props.inputRef ? this.props.inputRef : this.internalInputRef;
      inputRef.current && inputRef.current.scrollIntoView({ block: 'center' });
      this.wasFocused = false;
    }
  }

  private handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    this.wasFocused = true;
    this.props.onFocus && this.props.onFocus(e);
    this.setState({ isInputFocused: true });
  }

  private handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    this.wasFocused = false;
    this.props.onBlur && this.props.onBlur(e);
    this.setState({ isInputFocused: false });
  }

  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let newValue: string | null = event.target.value;
    const { handleChange, inputFilter } = this.props;

    if (inputFilter) {
      newValue = inputFilter(newValue);
    }

    if (newValue !== null) {
      handleChange && handleChange(newValue);
    }
  }

  public render() {
    const {
      widthStyle,
      fontSize,
      addonBefore,
      externalAddonBefore,
      addonAfter,
      externalAddonAfter,
      label,
      hideLabel,
      showClearButton,
      onClearInput,
      errorMessage,
      children,
      className,
      placeholder,
      value,
      inputRef,
      wrapperRef,
      subtext,
      onFocus,
      onBlur,
      required,
      onChange,
      collapseLabel,
      ...otherProps
    } = this.props;

    const width = this.determineWidth();
    const valueAsString = `${value || ''}`;
    const shouldShowClearButton = valueAsString.length > 0 && showClearButton;
    const hasInput = Boolean(value);

    const { isInputFocused } = this.state;
    const id = this.props.overrideId || this.id;

    const showError = !this.props.disabled && !isInputFocused && Boolean(errorMessage);

    return (
      <Wrapper ref={wrapperRef} widthStyle={widthStyle} className={className}>
        {typeof label === 'string' ? (
          <Label
            className="input-label"
            hideLabel={hideLabel}
            collapseLabel={collapseLabel}
            hasInput={hasInput}
            hasError={showError}
            htmlFor={id}
            disabled={this.props.disabled}
          >
            {label}
          </Label>
        ) : (
          <CustomLabelWrapper
            className="input-label"
            hideLabel={hideLabel}
            hasInput={hasInput}
            hasError={showError}
            disabled={this.props.disabled}
          >
            {React.cloneElement(label, {
              htmlFor: id,
            })}
          </CustomLabelWrapper>
        )}

        <InnerWrapper widthStyle={widthStyle} maxNumChars={this.props.maxNumChars}>
          {(isInputFocused || hasInput || hideLabel || !collapseLabel) && externalAddonBefore && (
            <ExternalAddonBeforeWrapper>{externalAddonBefore}</ExternalAddonBeforeWrapper>
          )}
          <InputWrapper
            data-testid="input-wrapper"
            className="input-wrapper"
            widthStyle={widthStyle}
            fontSize={fontSize}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            hasError={showError}
            {...otherProps}
          >
            {widthStyle === 'elastic' && (
              <SizerSpan ref={this.sizerRef} className={className} aria-hidden="true">
                {valueAsString.length > 0 ? valueAsString : this.props.placeholder}
              </SizerSpan>
            )}
            {(isInputFocused || hasInput || hideLabel || !collapseLabel) &&
              addonBefore &&
              React.cloneElement(addonBefore, {
                'data-testid': 'input-addon-before',
                disabled: this.props.disabled,
              })}
            <StyledInput
              id={id}
              ref={this.props.inputRef || this.internalInputRef}
              data-testid="input-styled-input"
              style={{ width }}
              value={value}
              placeholder={isInputFocused || hideLabel ? placeholder : ''}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              {...otherProps}
            />
            <ClearButton
              onMouseDown={this.handleMouseDown}
              onClick={this.props.onClearInput}
              hidden={!shouldShowClearButton}
              disabled={this.props.disabled || !shouldShowClearButton}
            />
            {addonAfter &&
              React.cloneElement(addonAfter, {
                disabled: this.props.disabled,
              })}
          </InputWrapper>
          {externalAddonAfter && (
            <ExternalAddonAfterWrapper>{externalAddonAfter}</ExternalAddonAfterWrapper>
          )}
          {(showError && (
            <BottomText
              onClick={this.focusOnInput}
              data-testid="input-error-message"
              variant="errorMessage"
            >
              {errorMessage}
            </BottomText>
          )) ||
            (subtext &&
              (typeof subtext === 'string' ? (
                <BottomText onClick={this.focusOnInput} data-testid="input-subtext">
                  {subtext}
                </BottomText>
              ) : (
                <BottomText>{React.cloneElement(subtext)}</BottomText>
              )))}
        </InnerWrapper>
      </Wrapper>
    );
  }

  private updateInputWidth() {
    if (this.sizerRef.current) {
      // The additional 1 pixel ensures the input has
      // sufficient width such that the text isn't cropped
      this.setState({ inputWidth: this.getSizerRefWidth(this.sizerRef) + 1 });
    }
  }

  private getSizerRefWidth(ref: React.RefObject<HTMLSpanElement>): number {
    return ref.current ? Math.ceil(ref.current.getBoundingClientRect().width) : 0;
  }

  private determineWidth() {
    switch (this.props.widthStyle) {
      case 'fluid':
        return '100%';
      case 'elastic':
      default:
        return this.state.inputWidth;
    }
  }

  private focusOnInput() {
    if (this.props.inputRef && this.props.inputRef.current) {
      this.props.inputRef.current.focus();
    } else if (this.internalInputRef && this.internalInputRef.current) {
      this.internalInputRef.current.focus();
    }
  }

  // Ensures that the input does not blur when clicking the clear button
  private handleMouseDown(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }
}

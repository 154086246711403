import React from 'react';
import styled from 'styled-components';

import { colorScale } from 'themes';

export const Em = styled.em`
  color: ${colorScale('grey', 80)};
`;

export const showBlankValues = (x: any) => {
  switch (x) {
    case null:
      return <Em>null</Em>;
    case undefined:
      // this is added from the mixing function -- JSON doesn't have undefined
      return <Em>-</Em>;
    case '':
      return <Em>&quot;&quot;</Em>;
    default:
      return typeof x === 'object' ? JSON.stringify(x) : x;
  }
};

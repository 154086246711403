import React from 'react';
import styled, { css } from 'styled-components';

import { TOP_GAP_PX_DESKTOP } from 'components/Templates';
import { theme, typography, colorScale, minWidth } from 'themes';

export const IMAGE_MAX_HEIGHT_PX = '300px';
const CONTENT_MAX_WIDTH_PX = '375px';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${CONTENT_MAX_WIDTH_PX};
  margin: 0 auto;

  ${minWidth(
    'desktop',
    css`
      position: sticky;
      top: calc(${theme.heights.navbar} + ${TOP_GAP_PX_DESKTOP});
    `
  )}
`;

const Title = styled.div`
  ${typography('title')}
  color: ${colorScale('primary', 40)};
  margin-bottom: 24px;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  height: ${IMAGE_MAX_HEIGHT_PX};

  img {
    width: 100%;
    height: 100%;
  }
`;

interface IProps {
  title: string;
  image: React.ReactElement;
}

/**
 * Renders a left-aligned purple colored title followed by a center-aligned image below it.
 *
 * @param title
 * @param image
 */
export const ScoliosisHeader = ({ title, image }: IProps) => {
  return (
    <HeaderWrapper>
      <Title>{title}</Title>
      <Image>{image}</Image>
    </HeaderWrapper>
  );
};

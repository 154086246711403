import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { ITotalDebtServicingRatioInputs } from '../interfaces';

export const TDSR2: IRule<ITotalDebtServicingRatioInputs> = {
  condition: ({ totalDebtServicingRatio }: ITotalDebtServicingRatioInputs) =>
    totalDebtServicingRatio !== null && totalDebtServicingRatio > 0.35,
  result: {
    key: RULE.TDSR2,
    indicatorStatus: indicatorStatuses.FAIR,
    recommendedActions: [recommendedActions.TRA_TDSR_B, recommendedActions.TRA_TDSR_C],
    resources: [
      // Some links
    ],
  },
};

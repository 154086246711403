export enum AGE_RANGE {
  UNDER_18 = 'UNDER_18',
  BETWEEN_18_AND_29 = 'BETWEEN_18_AND_29',
  BETWEEN_30_AND_49 = 'BETWEEN_30_AND_49',
  ABOVE_49 = 'ABOVE_49',
}

export enum APIM_MYINFO_DATA_SELECTION_STATE {
  MYINFO = 'MYINFO',
  APIM_AND_MYINFO = 'APIM_AND_MYINFO',
  MANAGE_SGFINDEX_CONNECTION = 'MANAGE_SGFINDEX_CONNECTIONS',
}

export enum EMPLOYMENT_STATUS_RANGE {
  FULL_PART_TIME = 'FULL_PART_TIME',
  SELF_FREELANCE = 'SELF_FREELANCE',
  UNEMPLOYED = 'UNEMPLOYED',
  NOT_WORKING = 'NOT_WORKING',
}

export enum WORK_YEARS_RANGE {
  UNDER_3 = 'UNDER_3',
  BETWEEN_3_AND_5 = 'BETWEEN_3_AND_5',
  ABOVE_5 = 'ABOVE_5',
  HAVE_NOT_WORKED = 'HAVE_NOT_WORKED',
}

export enum SEX {
  F = 'Female',
  M = 'Male',
}

export enum MARITAL_STATUS {
  S = 'Single',
  M = 'Married',
  W = 'Widowed',
  D = 'Divorced',
}

export enum RESIDENTIAL_STATUS {
  SC = 'Citizen',
  PR = 'Singapore permanent resident',
  FIN = 'Foreigner',
}

export enum ACCOUNT_STATUS {
  PENDING_DELETION = 'PENDING_DELETION',
  DELETED = 'DELETED',
}

export enum EMAIL_VERIFICATION_STATUS {
  UNVERIFIED = 'UNVERIFIED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
}

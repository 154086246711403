export enum MAIN_MODULE {
  CASHFLOW = 'CASHFLOW',
  WEALTH = 'WEALTH',
  SET_SAVING_TARGETS = 'SET_SAVING_TARGETS',
  BUDGET_CALCULATOR = 'BUDGET_CALCULATOR',
}

export enum MODULE_LITE {
  EXPENSE_MANAGER = 'EXPENSE_MANAGER',
}

export type MODULE = MAIN_MODULE | MODULE_LITE;

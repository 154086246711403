import { isEmpty } from 'lodash';
import React from 'react';

import { PanelWithDeleteIcon } from 'components/Common';
import { ASSET_TYPE } from 'constants/enums';

import {
  NameInvestmentSourceSection,
  INameInvestmentSourceProps,
} from '../Common/NameInvestmentSourceSection';
import { TotalValueSection, ITotalValueSectionProps } from '../Common/TotalValueSection';
import { Wrapper } from '../Common/Wrapper';

interface IOwnProps {
  onDelete: () => void;
  investmentType: Extract<
    ASSET_TYPE,
    ASSET_TYPE.FIXED_DEPOSIT | ASSET_TYPE.INSURANCE | ASSET_TYPE.STRUCTURED_PRODUCT
  >;
  totalValueFieldLabel?: string;
  autoFocusOnStart?: boolean;
}

type IProps = INameInvestmentSourceProps &
  ITotalValueSectionProps &
  IOwnProps &
  React.HTMLAttributes<HTMLDivElement>;

export const InvestmentWithTotalValueInputPanel: React.FC<IProps> = ({
  id,
  onDelete,
  investmentSource,
  onClickInvestmentSource,
  name,
  handleNameChange,
  totalValue,
  totalValueCurrency,
  handleTotalValueChange,
  handleTotalValueCurrencyChange,
  investmentType,
  totalValueFieldLabel = 'Value',
  autoFocusOnStart,
}) => {
  const isEmptyName = isEmpty(name);
  const isEmptyTotalValue = Number(totalValue) === 0;

  return (
    <PanelWithDeleteIcon
      data-testid="investment-with-total-value-input-panel"
      id={id}
      onDelete={onDelete}
    >
      <Wrapper investmentSource={investmentSource}>
        <NameInvestmentSourceSection
          investmentSource={investmentSource}
          name={name}
          handleNameChange={handleNameChange}
          investmentType={investmentType}
          onClickInvestmentSource={onClickInvestmentSource}
          autoFocus={autoFocusOnStart && isEmptyName && isEmptyTotalValue}
        />
        <TotalValueSection
          totalValueFieldLabel={totalValueFieldLabel}
          totalValue={totalValue}
          totalValueCurrency={totalValueCurrency}
          handleTotalValueChange={handleTotalValueChange}
          handleTotalValueCurrencyChange={handleTotalValueCurrencyChange}
          autoFocus={autoFocusOnStart && !isEmptyName}
        />
      </Wrapper>
    </PanelWithDeleteIcon>
  );
};

import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/Common';
import { IButtonRenderParams, ValidationButton, ValidationForm } from 'components/Validations';
import { typography } from 'themes';

import { SetEmergencyFundInputFields } from './SetEmergencyFundInputFields';

const Header = styled.div`
  ${typography('heading-m')}
  margin-bottom: 1.5rem;
`;

const SetButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`;

export interface ISetEmergencyFundContent {
  totalMonthlyCashOutflow: number;
  amount: number;
  numMonths: number;
  date: Date;
  saveToStore: () => void;
  updateAmount: (amount: number) => void;
  updateNumMonths: (value: string) => void;
  updateDate: (date: Date) => void;
  hasEmergencyFund: boolean;
  recommendedEmergencyFundMonths: number;
}

export class SetEmergencyFundContent extends React.Component<ISetEmergencyFundContent> {
  public render() {
    const {
      amount,
      numMonths,
      date,
      updateAmount,
      updateNumMonths,
      updateDate,
      saveToStore,
      hasEmergencyFund,
      totalMonthlyCashOutflow,
      recommendedEmergencyFundMonths,
    } = this.props;

    return (
      <React.Fragment>
        <Header>
          {hasEmergencyFund ? 'Edit emergency fund target' : 'Set emergency fund target'}
        </Header>
        <ValidationForm onSubmit={saveToStore} disableAfterSubmit={true}>
          <SetEmergencyFundInputFields
            totalMonthlyCashOutflow={totalMonthlyCashOutflow}
            amount={amount}
            numMonths={numMonths}
            date={date}
            updateAmount={updateAmount}
            updateNumMonths={updateNumMonths}
            updateDate={updateDate}
            recommendedEmergencyFundMonths={recommendedEmergencyFundMonths}
          />
          <ValidationButton
            render={(renderParams: IButtonRenderParams) => (
              <SetButton type="submit" {...renderParams}>
                {hasEmergencyFund ? 'Save' : 'Add'}
              </SetButton>
            )}
          />
        </ValidationForm>
      </React.Fragment>
    );
  }
}

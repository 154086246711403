import { keygen } from 'components/Apim-Myinfo/ApiMarketplace/Tables/ProductTables/genericTableHelpers';

/**
 * Flattens a nested object
 * @param {object} obj The object to be flattened
 * @param {string} [prefix] Only used when called recursively. See {@link keygen}
 * @returns {object} The same object, with all nested elements having a key that is the original property accessor.
 * @example
 * flatten({ obj: {key: 'value'}, arr: [1, 2] }) // returns { 'obj.key': 'value', 'arr[0]': 1, 'arr[1]': 2 }
 */
export const flatten = (obj: object, prefix?: string) =>
  Object.entries(obj).reduce((a, [k, v]) => {
    typeof v === 'object' && v !== null
      ? Object.assign(a, flatten(v, keygen(k, prefix)))
      : (a[keygen(k, prefix)] = v);
    return a;
  }, {});

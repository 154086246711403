import { MAIN_MODULE } from 'constants/enums';
import { IStore } from 'constants/storeTypes';
import { ONBOARDING } from 'constants/urls';

import { getCashflowModuleDetails } from './moduleDetails/cashflowDetails';
import { mainModules } from './moduleDetails/moduleDetails';
import { getWealthModuleDetails } from './moduleDetails/wealthDetails';

export const getModuleCurrentStepIndex = (store: IStore, module: MAIN_MODULE) => {
  return store.modules[module].currentStepIndex;
};

export const getModuleLastUpdatedDate = (store: IStore, module: MAIN_MODULE) => {
  const lastUpdatedDate = store.modules[module].lastUpdatedDate;
  return lastUpdatedDate ? new Date(lastUpdatedDate) : lastUpdatedDate;
};

export const getModuleLastCompletedDate = (store: IStore, module: MAIN_MODULE) => {
  const lastCompletedDate = store.modules[module].lastCompletedDate;
  return lastCompletedDate ? new Date(lastCompletedDate) : lastCompletedDate;
};

/**
 * Returns whether the module is in progress
 * @param store
 * @param moduleName
 */
export const getIsModuleInProgress = (store: IStore, moduleName: MAIN_MODULE) =>
  getModuleCurrentStepIndex(store, moduleName) !== null;

/**
 * Returns whether the module was completed before
 * @param store
 * @param moduleName
 */
export const getWasModuleCompleted = (store: IStore, moduleName: MAIN_MODULE) => {
  return getModuleLastCompletedDate(store, moduleName) !== undefined;
};

/**
 * Returns whether the module was completed and not currently in progress
 * @param store
 * @param moduleName
 */
export const getWasModuleCompletedAndNotInProgress = (store: IStore, moduleName: MAIN_MODULE) =>
  getWasModuleCompleted(store, moduleName) && !getIsModuleInProgress(store, moduleName);

/**
 * Returns a function that accepts a stepIndex and returns whether that stepIndex is allowed
 * @param store
 * @param moduleName
 */
export const getIsModuleStepAllowed = (store: IStore, moduleName: MAIN_MODULE) => {
  const currentStepIndex = getModuleCurrentStepIndex(store, moduleName);

  const wasModuleCompleted = getWasModuleCompleted(store, moduleName);

  const isModuleInProgress = currentStepIndex !== null;
  return (stepIndex: number) => {
    let isAllowed = false;
    if (wasModuleCompleted || stepIndex === 0) {
      isAllowed = true;
    } else if (isModuleInProgress) {
      isAllowed = (currentStepIndex as number) >= stepIndex;
    }

    return isAllowed;
  };
};

export const getWasCompletedAndNotInProgressModulesList = (store: IStore): MAIN_MODULE[] =>
  mainModules.reduce((modulesNameList, module) => {
    if (getWasModuleCompletedAndNotInProgress(store, module)) {
      modulesNameList.push(module);
    }
    return modulesNameList;
  }, [] as MAIN_MODULE[]);

export const getInProgressModulesList = (store: IStore): MAIN_MODULE[] =>
  mainModules.reduce((modulesNameList, module) => {
    if (getIsModuleInProgress(store, module)) {
      modulesNameList.push(module);
    }
    return modulesNameList;
  }, [] as MAIN_MODULE[]);

export const getIncompleteModulesList = (store: IStore): MAIN_MODULE[] =>
  mainModules.reduce((modulesNameList, module) => {
    if (!getWasModuleCompleted(store, module)) {
      modulesNameList.push(module);
    }
    return modulesNameList;
  }, [] as MAIN_MODULE[]);

/**
 * Returns the module names of the modules that needs to be completed.
 * Returns an empty array if there's no blocking module or if the given selectedModule
 * is before the blocking module
 * @param store
 * @param selectedModule
 */
export const getBlockingModules = (store: IStore, selectedModule: MAIN_MODULE): MAIN_MODULE[] => {
  return mainModules
    .filter((module) => !getWasModuleCompleted(store, module))
    .filter(
      (blockingModule) => mainModules.indexOf(blockingModule) < mainModules.indexOf(selectedModule)
    );
};

export const hasNotStartedModules = (store: IStore): boolean =>
  getIncompleteModulesList(store).length === Object.values(mainModules).length &&
  getInProgressModulesList(store).length === 0;

export const getHasCompletedAllModulesAndNotInProgress = (store: IStore) =>
  getWasCompletedAndNotInProgressModulesList(store).length === mainModules.length;

export const getOnboardingModulesNextPath = (store: IStore): string | null => {
  const incompleteModules = getIncompleteModulesList(store);
  const cashflowModuleIndex = getModuleCurrentStepIndex(store, MAIN_MODULE.CASHFLOW) || 0;
  const wealthModuleIndex = getModuleCurrentStepIndex(store, MAIN_MODULE.WEALTH) || 0;

  const cashflowNextPathList = getCashflowModuleDetails(store).subRoutes[cashflowModuleIndex]
    .nextPaths;
  const cashflowNextPath = cashflowNextPathList
    ? cashflowNextPathList[0]
    : ONBOARDING.CASHFLOW.inflow;

  const wealthNextPathList = getWealthModuleDetails(store).subRoutes[wealthModuleIndex].nextPaths;
  const wealthNextPath = wealthNextPathList ? wealthNextPathList[0] : ONBOARDING.WEALTH.assets;

  return incompleteModules.includes(MAIN_MODULE.CASHFLOW)
    ? cashflowNextPath
    : incompleteModules.includes(MAIN_MODULE.WEALTH)
    ? wealthNextPath
    : null;
};

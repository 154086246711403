import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Cross } from 'assets/icons';

import { IconButton, IIconButtonProps } from '../IconButton/IconButton';

const CustomButton = styled(IconButton)`
  margin: -24px 0;

  ${ifProp(
    'hidden',
    css`
      opacity: 0;
      width: 0;
      min-width: 0;
      padding-left: 0;
      padding-right: 0;
      border-left: none;
      border-right: none;
      visibility: hidden;
    `,
    css`
      opacity: 1;
    `
  )};

  ${ifProp(
    'disabled',
    css`
      display: none;
    `
  )}
`;

type IProps = Omit<IIconButtonProps, 'children' | 'ariaLabel'>;

export const ClearButton = (props: IProps) => {
  return (
    <CustomButton size="small" data-testid="clear-button" ariaLabel="Clear button" {...props}>
      <Cross />
    </CustomButton>
  );
};

import React from 'react';

import { INVESTMENT_SOURCE, ASSET_TYPE } from 'constants/enums';
import { IGoldAsset } from 'constants/storeTypes';

import { GoldInvestmentInputPanel } from './GoldInvestmentInputPanel';

interface IOwnProps {
  asset: IGoldAsset;
  updateManualInvestmentAsset: (id: string, update: Partial<IGoldAsset>) => void;
  deleteManualInvestmentAsset: (id: string) => void;
  onClickInvestmentSource: (
    id: string,
    name: string,
    investmentSource: INVESTMENT_SOURCE,
    assetType: ASSET_TYPE
  ) => void;
  autoFocusOnStart?: boolean;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

export const GoldInputPanel: React.FC<IProps> = ({
  asset,
  updateManualInvestmentAsset,
  deleteManualInvestmentAsset,
  onClickInvestmentSource,
  autoFocusOnStart,
}) => {
  const { id, name, investmentSource, totalValueAmount, quantity, currency, enterOption } = asset;
  return (
    <GoldInvestmentInputPanel
      investmentType={ASSET_TYPE.GOLD}
      id={id}
      onDelete={() => deleteManualInvestmentAsset(id)}
      name={name}
      handleNameChange={(name) => updateManualInvestmentAsset(id, { name })}
      investmentSource={investmentSource}
      totalValue={totalValueAmount}
      handleTotalValueChange={(totalValueAmount) =>
        updateManualInvestmentAsset(id, { totalValueAmount })
      }
      onClickInvestmentSource={() =>
        onClickInvestmentSource(id, name, investmentSource, ASSET_TYPE.GOLD)
      }
      totalQuantity={parseFloat(quantity)}
      handleTotalQuantityChange={(quantity) =>
        updateManualInvestmentAsset(id, { quantity: quantity.toString() })
      }
      totalValueCurrency={currency}
      handleTotalValueCurrencyChange={(currency) => updateManualInvestmentAsset(id, { currency })}
      enterOption={enterOption}
      handleEnterOptionChange={(enterOption) => updateManualInvestmentAsset(id, { enterOption })}
      autoFocusOnStart={autoFocusOnStart}
    />
  );
};

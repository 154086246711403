import { Omit } from 'lodash';

import { updateProfileRequest } from 'redux/profile/slice';

import { INotification } from 'constants/storeTypes';

import { NOTIFICATION, NOTIFICATION_TYPE, NOTIFICATION_CATEGORY } from './enums';
import { RESOURCES, INTERCEPT, SETTINGS, SGFINDEX } from './urls';

type INotificationVariables =
  | 'title'
  | 'type'
  | 'description'
  | 'onClick'
  | 'category'
  | 'preventAcknowledgeOnClick'
  | 'isExternalLink';
type INotificationDetails = Pick<INotification, INotificationVariables>;

export const NOTIFICATIONS_PAGE = 'NOTIFICATIONS_PAGE';

export const notificationDetails: { [module: string]: INotificationDetails } = {
  [NOTIFICATION.UPDATE_BANK_DATA]: {
    title: 'Update your available funds and target status',
    category: NOTIFICATION_CATEGORY.TODO,
    onClick: (history) => history.push('/update-bank-data'),
    type: NOTIFICATION_TYPE.TODO,
  },
  [NOTIFICATION.UPDATE_FUNDS_AND_TARGETS]: {
    title: 'Update your available funds and target status',
    category: NOTIFICATION_CATEGORY.TODO,
    onClick: (history) => history.push('/update-target-progress'),
    type: NOTIFICATION_TYPE.TODO,
  },
  [NOTIFICATION.GIVE_FEEDBACK]: {
    title: 'Leave a feedback',
    category: NOTIFICATION_CATEGORY.TODO,
    onClick: (_history, _openModal, dispatch) => {
      window.open(process.env.REACT_APP_FEEDBACK_FORM_URL, '_blank');
      dispatch(updateProfileRequest({ hasCompletedFeedbackForm: true }));
    },
    isExternalLink: true,
    type: NOTIFICATION_TYPE.TODO,
  },
  [NOTIFICATION.READ_ANTI_SCAM_ARTICLE]: {
    title: 'Learn how to spot investment scams and avoid them',
    category: NOTIFICATION_CATEGORY.TODO,
    onClick: (_history) => {
      _history.push({
        pathname: RESOURCES.INVESTMENT.antiScamArticle,
        state: { from: NOTIFICATIONS_PAGE },
      });
    },
    type: NOTIFICATION_TYPE.TODO,
  },
  [NOTIFICATION.INCOMPLETE_PROFILE]: {
    title: 'Complete your profile',
    category: NOTIFICATION_CATEGORY.TODO,
    onClick: (history) => history.push(INTERCEPT.emailInterceptNotification),
    type: NOTIFICATION_TYPE.TODO,
    preventAcknowledgeOnClick: false,
  },
  [NOTIFICATION.DBS_SYSTEM_UPDATE]: {
    // TODO: [FP-0000] this to-do can be removed after all of our users have pulled data after 28 nov
    title:
      'DBS System Update: Please reconnect with DBS after 12 Dec to retrieve and view your latest data',
    description: 'To reconnect, manage your SGFinDex connections via the settings page.',
    category: NOTIFICATION_CATEGORY.TODO,
    onClick: (history) =>
      history.push({
        pathname: SETTINGS.baseRoute,
        state: { from: NOTIFICATIONS_PAGE },
      }),
    type: NOTIFICATION_TYPE.TODO,
    preventAcknowledgeOnClick: false,
  },
};

type IInbuiltNotification = Omit<INotification, 'acknowledged'>;

export const INBUILT_NOTIFICATIONS: IInbuiltNotification[] = [
  {
    id: NOTIFICATION.TNC_UPDATE,
    title: 'Revisions to the Terms and Conditions governing your usage of SGFinDex',
    category: NOTIFICATION_CATEGORY.UPDATES,
    type: NOTIFICATION_TYPE.INBUILT_UPDATES,
    onClick: (history) =>
      history.push({
        pathname: SGFINDEX.termAndConditionsRevisions,
        state: { from: NOTIFICATIONS_PAGE },
      }),
    preventAcknowledgeOnClick: false,
    date: new Date('2023-12').toISOString(),
  },
];

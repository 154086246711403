import styled, { css } from 'styled-components';

import { Table, TableData } from 'components/Common';
import { ARTICLE_SIDE_SPACING_PX } from 'components/Templates';
import { maxWidth, colorScale } from 'themes';

export const ArticleTableWrapper = styled.div`
  overflow-x: scroll;
  margin: 24px 0;

  ${maxWidth(
    'tablet',
    css`
      margin-left: -${ARTICLE_SIDE_SPACING_PX};
      margin-right: -${ARTICLE_SIDE_SPACING_PX};
    `
  )}
`;

/**
 * To style a table in an article such that the table scrolls on smaller screens.
 * To be used with ArticleTableWrapper.
 */
export const StyledTable = styled(Table)`
  ${maxWidth(
    'tablet',
    css`
      margin: 0 ${ARTICLE_SIDE_SPACING_PX};
    `
  )}
`;

export const StyledTableData = styled(TableData)`
  padding: 24px;
  :nth-of-type(even) {
    background: ${colorScale('primary', 10)};
    border-left: 1px solid ${colorScale('primary', 20)};
  }
`;

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { MagnifyingGlass } from 'assets/icons';
import { Button } from 'components/Common';
import { colorScale, typeScale } from 'themes';

import { SearchWrapperWithRouter } from './SearchWrapper';

const SearchButton = styled(Button)`
  padding: 0 16px;
  font-size: ${typeScale(1)};
  color: ${colorScale('grey', 90)};
`;

const InlineMagnifyingGlass = styled(MagnifyingGlass)`
  font-size: ${typeScale(2)};
  flex-shrink: 0;
`;

export const DesktopSearchBar = () => {
  const history = useHistory();

  const toggleIsOpen = () => history.push('/search');

  return (
    <SearchWrapperWithRouter
      renderSearchComponent={() => (
        <SearchButton onClick={toggleIsOpen} variant="tertiary">
          <InlineMagnifyingGlass />
        </SearchButton>
      )}
    />
  );
};

import React from 'react';
import MediaQuery from 'react-responsive';
import { withTheme } from 'styled-components';

import { ITheme } from 'themes';

import { DesktopDemoSection } from './DesktopDemoSection';
import { MobileDemoSection } from './MobileDemoSection';

export const DemoSectionComponent: React.FC<ITheme> = ({ theme }) => (
  <MediaQuery maxWidth={theme.breakpoints.tabletMax}>
    {(matches) => (matches ? <MobileDemoSection /> : <DesktopDemoSection />)}
  </MediaQuery>
);

export const DemoSection = withTheme(DemoSectionComponent);

export default DemoSection;

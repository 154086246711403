import React from 'react';
import styled from 'styled-components';

import { Open } from 'assets/icons';
import { typography } from 'themes';

const Hyperlink = styled.a`
  ${typography('body-l')}

  > svg {
    vertical-align: middle;
    margin-left: 6px;
  }

  &:visited {
    color: #551a8b;
  }

  &:visited > svg {
    fill: #551a8b;
  }
`;

interface IOwnProps {
  url: string;
  children: React.ReactNode;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLAnchorElement>;

export const ArticleHyperlink = ({ url, children, ...otherProps }: IProps) => (
  <Hyperlink href={url} target="_blank" rel="noopener noreferrer" {...otherProps}>
    {children}
    <Open />
  </Hyperlink>
);

import React from 'react';

import { TableHeader, TableRow, TableData } from 'components/Common';
import { CurrencyPrefix } from 'components/Common';

import { StyledTable } from './sharedStyles';

const bodyData = [
  {
    title: 'Monthly investment',
    alicia: <CurrencyPrefix>500</CurrencyPrefix>,
    betty: <CurrencyPrefix>800</CurrencyPrefix>,
  },
  {
    title: 'Years to invest',
    alicia: '37 (from age 23 to 60)',
    betty: '25 (from age 35 to 60)',
  },
  {
    title: 'Amount invested',
    alicia: <CurrencyPrefix>222,000</CurrencyPrefix>,
    betty: <CurrencyPrefix>240,000</CurrencyPrefix>,
  },
  {
    title: 'Potential return',
    alicia: <CurrencyPrefix>490,200</CurrencyPrefix>,
    betty: <CurrencyPrefix>399,800</CurrencyPrefix>,
  },
];

export const ROIComparisonTable = () => (
  <StyledTable>
    <thead>
      <TableRow>
        <TableHeader colSpan={1}>{''}</TableHeader>
        <TableHeader colSpan={1}>Alicia</TableHeader>
        <TableHeader colSpan={1}>Betty</TableHeader>
      </TableRow>
    </thead>

    <tbody>
      {bodyData.map(({ title, alicia, betty }, index) => (
        <TableRow key={index}>
          <TableData>
            <b>{title}</b>
          </TableData>

          <TableData>
            <span>{alicia}</span>
          </TableData>

          <TableData>
            <span>{betty}</span>
          </TableData>
        </TableRow>
      ))}
    </tbody>
  </StyledTable>
);

import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import uuidv4 from 'uuid/v4';

import { typography } from 'themesTypography';

import { calculatePolygonVertices, mapChartLevel, maxGraphSpacePercentage } from './helper';
import { Polygon } from './Polygon';

const PolygonExpand = keyframes`
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 1 / 1;
`;

const Label = styled.p<{ x: number; y: number }>`
  ${typography('body-s-bold')};
  margin: 0;
  position: absolute;
  left: calc(${({ x }) => x}%);
  top: calc(${({ y }) => y}%);
  white-space: pre;
  transform: translateX(-50%);
  text-align: center;
  margin-top: -12px;
`;

const StyledSvg = styled.svg`
  > :first-child {
    transform-origin: center;
    animation: 0.6s ${PolygonExpand} forwards;
  }
`;

const StyledPolygon = styled(Polygon)`
  position: absolute;
`;

const GRADIENT_BACKGROUND_ID = 'polygon-chart-gradient';

interface IData {
  value: number;
  label: string;
}
export interface IRadarChartProps {
  data: IData[];
  level: number;
  displayOrder?: 'clockwise' | 'z-pattern';
}
export const RadarChart = ({
  data,
  level,
  displayOrder = 'z-pattern',
  ...otherProps
}: IRadarChartProps) => {
  let rawData = data;

  if (displayOrder === 'z-pattern') {
    const odd: IData[] = [];
    const even: IData[] = [];

    for (let i = 0; i < data.length; i += 2) {
      even.push(data[i]);
      data[i + 1] && odd.push(data[i + 1]);
    }

    rawData = even.concat(odd.reverse());
  }

  const positionOfLabel = maxGraphSpacePercentage + 10;
  const labelVertices = calculatePolygonVertices({
    numOfSides: rawData.length,
    radiusLevels: Array(rawData.length).fill(positionOfLabel),
  }).map(({ x, y }, index) => ({
    x,
    y,
    label: rawData[index].label,
  }));

  const { graphLevels, dataLevels } = mapChartLevel({ data: rawData, level });

  // To rerender the chart with animation when data changes
  const [key, setKey] = useState(uuidv4());
  useEffect(() => {
    setKey(uuidv4());
  }, [data]);

  return (
    <Wrapper {...otherProps}>
      {labelVertices.map(({ x, y, label }, idx) => (
        <Label key={idx} x={x} y={y}>
          {label}
        </Label>
      ))}

      <StyledSvg width="100%" height="100%" viewBox="0 0 100 100" key={key}>
        <StyledPolygon
          numOfSides={data.length}
          radiusLevels={dataLevels}
          backgroundFillId={GRADIENT_BACKGROUND_ID}
        />

        {graphLevels.map((graphLevel, idx) => (
          <StyledPolygon
            key={idx}
            numOfSides={data.length}
            radiusLevels={Array(data.length).fill(graphLevel)}
            isBorder
          />
        ))}

        <defs>
          <linearGradient id={GRADIENT_BACKGROUND_ID} gradientTransform="rotate(102.98)">
            <stop offset="-3.25%" stopColor="#5572F6" />
            <stop offset="39.08%" stopColor="#6361D1" />
            <stop offset="84.74%" stopColor="#8861D1" />
            <stop offset="108.16" stopColor="#DD74C4" />
          </linearGradient>
        </defs>
      </StyledSvg>
    </Wrapper>
  );
};

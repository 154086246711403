import React from 'react';
import styled from 'styled-components';

import { InfoButton } from 'components/Common';
import { typography } from 'themes';

import { PageHeading } from '../../Common/SectionHeading/Heading';

import { IModuleContentProps } from './interfaces';

const StyledPageHeading = styled(PageHeading)`
  grid-area: heading;
  margin-bottom: 32px;
`;

const Title = styled.p`
  ${typography('heading-l')}
  margin: 0 0 16px 0;
`;

const StyledInfoButton = styled(InfoButton)`
  vertical-align: middle;
`;

const Subtitle = styled.p`
  ${typography('body-l')}
  margin: 0;
`;

type IProps = Pick<IModuleContentProps, 'title' | 'titleInfoCB' | 'subtitle'>;

interface IOwnProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const ModuleContentPageHeader: React.FC<IProps & IOwnProps> = ({
  title,
  titleInfoCB,
  subtitle,
  className,
  children,
}) => (
  <StyledPageHeading className={className} data-testid="module-content-page-header">
    <Title>
      {title}
      {titleInfoCB && <StyledInfoButton onClick={() => titleInfoCB()} />}
    </Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    {children}
  </StyledPageHeading>
);

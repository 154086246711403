import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { colorScale, shadowScale } from 'themes';

const borderRadiusLarge = fromTheme('borders.radiusLarge');

export const Panel = styled.div`
  padding: 24px;
  background: white;
  border-radius: ${borderRadiusLarge};
  box-shadow: ${shadowScale(1)};
`;

export const PanelList = styled.div`
  border-radius: ${borderRadiusLarge};
  box-shadow: ${shadowScale(1)};

  ${Panel}:first-of-type {
    border-radius: ${borderRadiusLarge} ${borderRadiusLarge} 0 0;
  }

  ${Panel}:last-of-type {
    border-radius: 0 0 ${borderRadiusLarge} ${borderRadiusLarge};
  }

  ${Panel}:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }

  ${Panel}:not(:last-of-type) {
    border-bottom: 1px solid ${colorScale('grey', 20)};
  }

  ${Panel}:only-of-type {
    border-radius: ${borderRadiusLarge};
  }

  ${Panel} {
    box-shadow: none;
  }
`;

import React from 'react';

import ApimLoadingAnimation from './apim-loading-animation.gif';
import DyingFlower from './dying-flower-illustration.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const LoadingAnimation = (props: ImageProps) => (
  <img {...props} src={ApimLoadingAnimation} alt="Loading animation" />
);

export const DyingFlowerImage = (props: ImageProps) => (
  <img {...props} src={DyingFlower} alt="Wilting money flower" />
);

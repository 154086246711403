import styled, { css } from 'styled-components';

import { ArrowRight } from 'assets/icons';
import { Heading } from 'components/Common';
import { typography, colorScale } from 'themes';

export const MainHeading = styled(Heading)`
  ${typography('heading-l')};

  color: ${colorScale('primary', 50)};
`;

export const iconStyles = css`
  width: 24px;
  height: 24px;
`;

export const StyledArrowRight = styled(ArrowRight)`
  ${iconStyles};
`;

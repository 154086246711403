import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { colorScale } from 'themes';

export const Divider = styled.hr`
  border: none;
  border-top: ${fromTheme('borders.divider')} solid ${colorScale('grey', 20)};
  margin: 24px 0;
`;

import React from 'react';
import styled, { css } from 'styled-components';

import { colorScale, minWidth } from 'themes';
import { typography } from 'themesTypography';

const Wrapper = styled.div`
  background: ${colorScale('primary', 10)};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
`;

const Avatar = styled.div`
  margin-right: 8px;
`;

const Quotes = styled.div`
  ${typography('title')}
  color: ${colorScale('primary', 50)};
`;

const LeftQuotationMark = styled(Quotes)`
  margin-top: 4px;

  ${minWidth(
    'desktop',
    css`
      margin-top: -2px;
    `
  )}
`;

const RightQuotationMark = styled(Quotes)`
  margin-top: 12px;
  margin-left: auto;
`;

const Comment = styled.div`
  color: ${colorScale('primary', 50)};
  ${typography('body-l')}
  line-height: 2;
  margin: 0 8px;
`;

interface IProps {
  avatar: React.ReactNode;
  comment: string;
}
/**
 * Displays a panel with avatar on the left and comments in quotes on the right
 * @param avatar an avatar/image to be displayed
 * @param comment text to be displayed in quotes
 */
export const CommentWithAvatarPanel = ({ avatar, comment }: IProps) => (
  <Wrapper>
    <Avatar>{avatar}</Avatar>
    <LeftQuotationMark>&#8220;</LeftQuotationMark>
    <Comment>{comment}</Comment>
    <RightQuotationMark>&#8221;</RightQuotationMark>
  </Wrapper>
);

import React from 'react';
import MediaQuery from 'react-responsive';

import { theme } from 'themes';

import { ArticleImage } from './ArticleImage';

/**
 * @param mobileImage
 * @param tabletImage
 * @param desktopImage
 */
export const renderResponsiveGraphImages = (
  mobileImage: React.ReactElement,
  tabletImage: React.ReactElement,
  desktopImage: React.ReactElement
) => (
  <>
    <MediaQuery maxWidth={theme.breakpoints.mobileMax}>
      <ArticleImage image={mobileImage} />
    </MediaQuery>
    <MediaQuery minWidth={theme.breakpoints.mobileMax} maxWidth={theme.breakpoints.desktopMin}>
      <ArticleImage image={tabletImage} />
    </MediaQuery>
    <MediaQuery minWidth={theme.breakpoints.desktopMin}>
      <ArticleImage image={desktopImage} />
    </MediaQuery>
  </>
);

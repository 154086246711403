import { sortBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrencyFxRates } from 'redux/fxRates/selectors';

import { ascending } from 'utilities/sortingUtilities';

import { Dropdown } from '../Dropdown/Dropdown';
import { ISelectedOption, IDropdownOption } from '../Dropdown/Interfaces';

export const TOP_CURRENCIES = ['SGD', 'USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CNH', 'HKD'];

interface IProps {
  onChange: (currency: string) => void;
  selectedCurrency: string;
}

export const CurrencyDropdown: React.FC<IProps> = ({
  onChange,
  selectedCurrency,
  ...otherProps
}) => {
  const fxRates = useSelector(getCurrencyFxRates);

  const options = Object.keys(fxRates).map((currency) => ({
    label: currency,
    value: currency,
  }));

  const topOptions = sortBy(
    options.filter(({ value }) => TOP_CURRENCIES.includes(value)),
    ({ value }) => TOP_CURRENCIES.indexOf(value)
  );

  const otherOptions = options
    .filter(({ value }) => !TOP_CURRENCIES.includes(value))
    .sort((a, b) => ascending(a.value, b.value));

  const groupedOptions = [
    {
      label: 'Most popular currencies',
      options: topOptions,
    },
    {
      label: 'Other currencies',
      options: otherOptions,
    },
  ];

  return (
    <Dropdown
      label="currency-dropdown"
      hideLabel={true}
      options={groupedOptions}
      onChange={(selectedOption: ISelectedOption) =>
        onChange((selectedOption as IDropdownOption<string>)?.value)
      }
      isSearchable={true}
      value={{ label: selectedCurrency, value: selectedCurrency }}
      {...otherProps}
    />
  );
};

import React from 'react';
import styled from 'styled-components';

import { LevelContext } from './SectionHeaderContext';

const StyledSection = styled.section``;

interface ISectionProps {
  children: React.ReactNode;
  className?: string;
}

type IProps = ISectionProps & React.HTMLAttributes<HTMLDivElement>;

export class Section extends React.Component<IProps> {
  public render() {
    return (
      <LevelContext.Consumer>
        {(level) => (
          <LevelContext.Provider value={level + 1}>
            <StyledSection {...this.props} />
          </LevelContext.Provider>
        )}
      </LevelContext.Consumer>
    );
  }
}

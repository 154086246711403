import React from 'react';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { Panel } from 'components/Common';
import { colorScale, typography, minWidth } from 'themes';

import { INutritionalBreakdown, NutritionalBreakdown } from './NutritionalBreakdown';

const DISPLAY_PICTURE_MIN_WIDTH_PX = '90px';
const DISPLAY_PICTURE_MAX_WIDTH_PX = '120px';
const MIN_SPACING_FROM_DISPLAY_PICTURE_PX = '16px';
const MAX_SPACING_FROM_DISPLAY_PICTURE_PX = '24px';

const StyledPanel = styled(Panel)`
  padding: 16px;
  border-radius: ${fromTheme('borders.radiusExtraLarge')};
`;

const Header = styled.div`
  display: grid;
  padding-bottom: 20px;
  grid-template-columns: ${DISPLAY_PICTURE_MIN_WIDTH_PX} 1fr;
  column-gap: ${MIN_SPACING_FROM_DISPLAY_PICTURE_PX};

  ${minWidth(
    'tablet',
    css`
      grid-template-columns: ${DISPLAY_PICTURE_MAX_WIDTH_PX} 1fr;
      column-gap: ${MAX_SPACING_FROM_DISPLAY_PICTURE_PX};
    `
  )}
`;

const DisplayPicture = styled.div`
  > img {
    width: 100%;
  }
`;

const ProfileDetails = styled.div`
  margin: auto 0;
`;

const ProfileName = styled.p`
  ${typography('body-l-bold')};
  margin: 0;
`;

const ProfileDescription = styled.p`
  ${typography('body-s-italic')};
  color: ${colorScale('grey', 80)};
  margin: 0;
`;

const ProfileBreakdown = styled.div`
  ${minWidth(
    'tablet',
    css`
      margin-left: calc(${DISPLAY_PICTURE_MAX_WIDTH_PX} + ${MAX_SPACING_FROM_DISPLAY_PICTURE_PX});
    `
  )}
`;

interface IOwnProps {
  profileName: string;
  profileDescription: string;
  displayPicture: React.ReactNode;
  profileBreakdown: INutritionalBreakdown[];
  additionalInfo?: React.ReactNode;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

/**
 * Returns a Sample profile card, showing the profile name, description, display picture, a breakdown of the profile, and optional additional info
 * @param profileName
 * @param profileDescription A short description of this profile
 * @param displayPicture
 * @param profileBreakdown An array of profile headers and their respective descriptions
 * @param additionalInfo Additional info below the profile breakdown. Fills up the entire width of the card on bigger screens.
 * @returns
 */
export const SampleProfilePanel = ({
  profileName,
  profileDescription,
  displayPicture,
  profileBreakdown,
  additionalInfo,
  ...otherProps
}: IProps) => {
  return (
    <StyledPanel {...otherProps}>
      <Header>
        <DisplayPicture>{displayPicture}</DisplayPicture>
        <ProfileDetails>
          <ProfileName>{profileName}</ProfileName>
          <ProfileDescription>{profileDescription}</ProfileDescription>
        </ProfileDetails>
      </Header>
      <ProfileBreakdown>
        <NutritionalBreakdown content={profileBreakdown} />
      </ProfileBreakdown>

      {additionalInfo}
    </StyledPanel>
  );
};

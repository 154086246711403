import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

// Allows custom props to be passed to the form
const StyledForm = styled.form``;

/**
 * Form that ensures onSubmit can only be called once
 * @param props
 */

interface IOwnProps {
  disableAfterSubmit?: boolean;
}

export type IFormProps = React.FormHTMLAttributes<HTMLFormElement> & IOwnProps;

export const Form: React.FC<IFormProps> = ({ disableAfterSubmit, onSubmit, ...otherProps }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const onSubmitWithChecks = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!disableAfterSubmit || !hasSubmitted) {
        disableAfterSubmit && setHasSubmitted(true);
        if (onSubmit) {
          onSubmit(e);
        }
      }
    },
    [disableAfterSubmit, hasSubmitted, onSubmit]
  );
  return <StyledForm {...otherProps} onSubmit={onSubmitWithChecks} />;
};

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  getRecommendedDueDate,
  getRecommendedAmount,
  getProjectedTargets,
  isGoal,
} from 'redux/targets/selectors';

import { Caution } from 'assets/icons';
import { IStore } from 'constants/storeTypes';
import { colorScale, typeScale, theme } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';
import { formatDate, getDateDiffInMonths } from 'utilities/dateUtilities';

import { RecommendedActionsButton, ButtonWrapper } from './RecommendedActionsButton';

const colors = {
  background: colorScale('secondary', 10),
  font: colorScale('secondary', 40),
};

const BannerWrapper = styled.div`
  background: ${colors.background};
  border-radius: ${theme.borders.radiusLarge};
  padding: 16px 24px;
  font-size: ${typeScale(-2)};
  margin-bottom: 32px;
`;

const BannerCaption = styled.div`
  color: ${colors.font};
  font-weight: bold;
  line-height: 140%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const BannerContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrWrapper = styled(ButtonWrapper)`
  flex-basis: 12%;
`;

const StyledCaution = styled(Caution)`
  margin-right: 8px;
`;

interface IOwnProps {
  updateDate: (date: Date) => void;
  updateAmount: (amount: number) => void;
  setToMinimumMonthlySavingsAndCloseModal: () => void;
  minimumMonthlySavings: number;
  amount: number;
  date: Date;
  monthlySavings: number;
  goalId: string;
}

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  const recommendedDueDate = getRecommendedDueDate(state, ownProps.monthlySavings, ownProps.goalId);
  const recommendedAmount = getRecommendedAmount(state, ownProps.monthlySavings, ownProps.goalId);
  const projectedGoal = getProjectedTargets(state, ownProps.monthlySavings)
    .filter(isGoal)
    .find((ele) => ele.id === ownProps.goalId);
  return {
    recommendedDueDate,
    recommendedAmount,
    projectedGoal,
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

export class RecommendedActionsToAchieveTargetBanner extends React.Component<IProps> {
  public render() {
    const {
      updateDate,
      updateAmount,
      recommendedAmount,
      recommendedDueDate,
      setToMinimumMonthlySavingsAndCloseModal,
      minimumMonthlySavings,
      amount,
      date,
      projectedGoal,
    } = this.props;

    if (typeof recommendedAmount === 'undefined') {
      return null;
    }

    const userHasDecreasedAmountEnough = amount <= recommendedAmount;
    if (userHasDecreasedAmountEnough) {
      return null;
    }

    const userHasPostponedDateEnough =
      recommendedDueDate !== null && date.getTime() >= recommendedDueDate.getTime();
    if (userHasPostponedDateEnough) {
      return null;
    }

    const targetTooFar = recommendedDueDate === null;

    //edge case: shifted target too far && target date is this month => cannot increase monthly savings anymore
    const targetDateIsThisMonth = getDateDiffInMonths(date, new Date()) === 0;
    if (targetTooFar && targetDateIsThisMonth) {
      return null;
    }

    //if already achieveable, then don't show the box no matter what.
    if (projectedGoal && projectedGoal.totalAmount === projectedGoal.currentAmount) {
      return null;
    }

    return (
      <BannerWrapper>
        <BannerCaption>
          <StyledCaution />
          <span>To achieve this target, you can:</span>
        </BannerCaption>
        <BannerContent>
          <RecommendedActionsButton
            onClick={() => {
              updateAmount(recommendedAmount);
            }}
            topLine="Reduce amount to "
            bottomLine={formatCurrency(recommendedAmount)}
          />
          <OrWrapper>OR</OrWrapper>
          <RecommendedActionsButton
            onClick={
              targetTooFar
                ? setToMinimumMonthlySavingsAndCloseModal
                : () => {
                    updateDate(recommendedDueDate as Date);
                  }
            }
            topLine={targetTooFar ? 'Increase monthly savings to ' : 'Shift due date to '}
            bottomLine={
              targetTooFar
                ? formatCurrency(minimumMonthlySavings)
                : formatDate(recommendedDueDate as Date)
            }
          />
        </BannerContent>
      </BannerWrapper>
    );
  }
}

export const RecommendedActionsToAchieveTargetBannerContainer = connect(mapStateToProps)(
  RecommendedActionsToAchieveTargetBanner
);

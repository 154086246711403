export enum ARTICLE_TYPES {
  // "How much should your first flat cost?"
  FIRST_HOUSE_COST = 'FIRST_HOUSE_COST',
  // "Should you buy a new or resale flat?"
  NEW_OR_RESALE_FLAT = 'NEW_OR_RESALE_FLAT',
  // "What are the other things you'll need to pay for when you buy a home?"
  OTHER_THINGS_TO_PAY_FOR = 'OTHER_THINGS_TO_PAY_FOR',
  // "Should you take a housing loan from HDB or a bank"
  HDB_OR_BANK_LOAN = 'HDB_OR_BANK_LOAN',
  // "What housing loan amount and loan period should you go for?"
  LOAN_AMOUNT_AND_PERIOD = 'LOAN_AMOUNT_AND_PERIOD',
  // "Should you keep some money in your CPF Ordinary Account when you take up your housing loan?"
  LEAVE_MONEY_IN_CPF_OA = 'LEAVE_MONEY_IN_CPF_OA',
  // "Should you pay for your home with cash or CPF savings"
  CASH_OR_CPF = 'CASH_OR_CPF',
}

export enum FLAT_SIZE {
  TWO_ROOM = 'TWO_ROOM',
  THREE_ROOM = 'THREE_ROOM',
  FOUR_ROOM = 'FOUR_ROOM',
  FIVE_ROOM = 'FIVE_ROOM',
  EXECUTIVE = 'EXECUTIVE',
}

export enum FLAT_TYPE {
  NEW = 'NEW',
  RESALE = 'RESALE',
}

export enum PLAN_TYPE {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
}

export enum PLAN_COLOR {
  RED = 'RED',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
  TEAL = 'TEAL',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  PINK = 'PINK',
}

export enum PLAN_STEP {
  BASIC_AND_FINANCIAL_INFORMATION = 1,
  FLAT_AND_BUDGET_INFORMATION,
  UPFRONT_PAYMENT_AND_LOAN,
  ONE_TIME_PAYMENTS,
  RECURRING_PAYMENTS,
}

export enum HOUSEHOLD_TYPE {
  FAMILY = 'FAMILY',
  SINGLE = 'SINGLE',
}

export enum LOAN_TYPE {
  HDB = 'HDB',
  BANK = 'BANK',
}

export enum PAYMENT_METHOD {
  CASH = 'CASH',
  CPF = 'CPF',
}

import detectIt from 'detect-it';
import React from 'react';
import Select, { NonceProvider } from 'react-select';
import Creatable from 'react-select/creatable';
import { withTheme } from 'styled-components';

import { uniqueIdGenerator } from '../uniqueIdGenerator/uniqueIdGenerator';

import { dropdownStyles } from './DropdownStyles';
import { IDropdownProps, IDropdownOption, IGroupedOption } from './Interfaces';
import { CustomLabelWrapper, Label } from './Label';
import { NativeDropdown } from './NativeDropdown';
import { Subtext } from './Subtext';

interface IState {
  useNativeDropdown: boolean;
  isFocused: boolean;
}

function isGroupedOptions(
  options: IDropdownOption[] | IGroupedOption[]
): options is IGroupedOption[] {
  return (options as IGroupedOption[])[0]?.options !== undefined;
}

function flattenGroupedOptions(groupedOptions: IGroupedOption[]): IDropdownOption[] {
  return groupedOptions.reduce((a, c) => [...a, ...c.options], [] as IDropdownOption[]);
}

function combineLabelAndAfterText(options: IDropdownOption[]): IDropdownOption[] {
  return options.map((option) => ({
    ...option,
    label: option.afterText ? `${option.label}\xa0\xa0\xa0\xa0 ${option.afterText}` : option.label,
  }));
}

class DropdownComponent extends React.Component<IDropdownProps, IState> {
  public static defaultProps = {
    isClearable: false,
    placeholder: '',
    isSearchable: false,
    nativeOnMobile: true,
    isMulti: false,
    createable: false,
    isFluid: false,
    menuPlacement: 'auto',
    maxMenuHeight: 200,
    hideDisabledOptions: false,
  };

  private static readonly idGenerator = uniqueIdGenerator('Dropdown');
  private readonly id: string;

  constructor(props: IDropdownProps) {
    super(props);

    this.state = {
      useNativeDropdown: Boolean(detectIt.primaryInput === 'touch' && this.props.nativeOnMobile),
      isFocused: false,
    };

    this.id = DropdownComponent.idGenerator.next().value as string;

    if (props.isMulti && props.nativeOnMobile) {
      throw new Error('Enabling isMulti requires nativeOnMobile to be false');
    }

    if (props.createable && (props.nativeOnMobile || !props.isSearchable)) {
      throw new Error(
        'Enabling creatable requires nativeOnMobile to be false, and isSearchable to be true'
      );
    }
  }

  public render() {
    const {
      hideLabel,
      hideDisabledOptions,
      theme,
      label,
      disabled,
      value,
      createable,
      options,
      subtext,
      ...otherProps
    } = this.props;

    const filteredOptions = hideDisabledOptions
      ? isGroupedOptions(options)
        ? options.map((group) => ({
            ...group,
            options: group.options.filter(({ isDisabled }) => !isDisabled),
          }))
        : options.filter(({ isDisabled }) => !isDisabled)
      : options;

    const nativeOptions = isGroupedOptions(filteredOptions)
      ? combineLabelAndAfterText(flattenGroupedOptions(filteredOptions))
      : filteredOptions;

    return (
      <NonceProvider nonce={window.NONCE_ID}>
        <div
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
        >
          {typeof label === 'string' ? (
            <Label hideLabel={hideLabel} htmlFor={this.id} dropdownFocused={this.state.isFocused}>
              {label}
            </Label>
          ) : (
            <CustomLabelWrapper hideLabel={hideLabel}>
              {React.cloneElement(label, {
                htmlFor: this.id,
              })}
            </CustomLabelWrapper>
          )}
          {createable ? (
            <Creatable
              styles={dropdownStyles(theme)}
              inputId={this.id}
              isDisabled={disabled}
              value={value}
              options={filteredOptions}
              openOnFocus={true}
              {...otherProps}
            />
          ) : this.state.useNativeDropdown ? (
            <NativeDropdown
              disabled={disabled}
              id={this.id}
              selectedOption={value as IDropdownOption}
              options={nativeOptions}
              {...otherProps}
            />
          ) : (
            <Select
              styles={dropdownStyles(theme)}
              inputId={this.id}
              isDisabled={disabled}
              value={value}
              options={filteredOptions}
              openOnFocus={true}
              {...otherProps}
            />
          )}
          {subtext && <Subtext>{subtext}</Subtext>}
        </div>
      </NonceProvider>
    );
  }
}

const Dropdown = withTheme(DropdownComponent);
Dropdown.displayName = 'Dropdown';

export { Dropdown };

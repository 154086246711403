import { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { typography } from 'themes';

export const popupProperties = css`
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: ${fromTheme('borders.radiusRound')};
  ${typography('body-m-bold')};
`;

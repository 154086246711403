import React from 'react';
import { isBrowser } from 'react-device-detect';
import styled from 'styled-components';

import { Input, NumberField } from 'components/Common';
import { formatCurrency, convertToSGD } from 'utilities/currencyUtilities';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
`;

export interface IGoldUnitPriceSectionLabelProps {
  totalQuantityFieldLabel: string;
  unitPriceLabel: string;
  unitOfMeasurement: string;
  allowedDecimalPlaces?: number;
}

export interface IGoldUnitPriceSectionProps {
  totalQuantity: number | null;
  handleTotalQuantityChange: (value: number) => void;
  autoFocus?: boolean;
}

type IProps = IGoldUnitPriceSectionLabelProps & IGoldUnitPriceSectionProps;

export const GoldUnitPriceSection: React.FC<IProps> = ({
  totalQuantityFieldLabel,
  totalQuantity,
  handleTotalQuantityChange,
  unitPriceLabel,
  unitOfMeasurement,
  allowedDecimalPlaces = 0,
  autoFocus,
}) => {
  const unitPrice = convertToSGD(100, 'XAU');
  const totalValue = unitPrice * Number(totalQuantity);

  return (
    <Wrapper>
      <NumberField
        label={totalQuantityFieldLabel}
        widthStyle="elastic"
        minNumChars={5}
        allowedDecimalPlaces={allowedDecimalPlaces}
        addonAfter={<span>{unitOfMeasurement}</span>}
        numberValue={totalQuantity}
        type="numeric"
        onInputChange={handleTotalQuantityChange}
        collapseLabel={false}
        autoFocus={isBrowser && autoFocus}
      />
      <Input
        label={unitPriceLabel}
        value={formatCurrency(unitPrice)}
        readOnly={true}
        widthStyle="elastic"
        addonAfter={<span>per oz</span>}
      />
      <Input label="Estimated value" value={formatCurrency(totalValue)} readOnly={true} />
    </Wrapper>
  );
};

import axios from 'axios';

import {
  ICreateNotification,
  IAcknowledgeNotification,
  RequestAction,
} from 'constants/actionTypes';
import { NOTIFICATIONS_ACTIONS, NOTIFICATION_CATEGORY, NOTIFICATION_TYPE } from 'constants/enums';
import { INotification } from 'constants/storeTypes';

export const createNotification = (notification: INotification): ICreateNotification => ({
  type: NOTIFICATIONS_ACTIONS.CREATE_NOTIFICATION,
  notification,
});

export const createNotificationRequest = (
  title: string,
  description: string,
  date: Date
): RequestAction => async (dispatch) => {
  const notification = { title, description, date, acknowledged: false };
  const promise = axios.post(
    `${process.env.REACT_APP_API_URL}/v1/notification/personal`,
    notification
  );

  promise
    .then(({ data: { id } }) => {
      dispatch(
        createNotification({
          ...notification,
          id,
          date: date.toISOString(),
          category: NOTIFICATION_CATEGORY.UPDATES,
          type: NOTIFICATION_TYPE.PERSONAL_UPDATES,
        })
      );
    })
    .catch(() => {});

  return promise;
};

export const acknowledgeNotification = (
  id: string,
  notificationType: NOTIFICATION_TYPE
): IAcknowledgeNotification => ({
  type: NOTIFICATIONS_ACTIONS.ACKNOWLEDGE_NOTIFICATION,
  id,
  notificationType,
});

export const createAcknowledgeNotificationRequest = (
  id: string,
  notificationType: NOTIFICATION_TYPE
): RequestAction => async (dispatch) => {
  let promise;

  switch (notificationType) {
    case NOTIFICATION_TYPE.TODO:
      promise = axios.post(
        `${process.env.REACT_APP_API_URL}/v1/notification/todo-acknowledgement`,
        {
          notificationKey: id,
        }
      );
      break;

    case NOTIFICATION_TYPE.PERSONAL_UPDATES:
      promise = axios.patch(`${process.env.REACT_APP_API_URL}/v1/notification/personal/${id}`, {
        acknowledged: true,
      });
      break;

    case NOTIFICATION_TYPE.GLOBAL_UPDATES:
      promise = axios.post(
        `${process.env.REACT_APP_API_URL}/v1/notification/global-acknowledgement`,
        {
          globalNotificationId: id,
        }
      );
      break;

    case NOTIFICATION_TYPE.INBUILT_UPDATES:
    default:
      promise = axios.post(
        `${process.env.REACT_APP_API_URL}/v1/notification/inbuilt-acknowledgement`,
        {
          notificationKey: id,
        }
      );
      break;
  }

  promise
    .then(() => {
      dispatch(acknowledgeNotification(id, notificationType));
    })
    .catch(() => {});

  return promise;
};

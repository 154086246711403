import { MAIN_MODULE } from 'constants/enums';
import { IStore, IMainModuleDetails } from 'constants/storeTypes';
import { ONBOARDING } from 'constants/urls';
import { checkIfRoutesAreAllowed } from 'utilities/moduleUtilities';

import { getIsModuleStepAllowed } from '../selectors';

const moduleName = MAIN_MODULE.CASHFLOW;
const { CASHFLOW } = ONBOARDING;

export const moduleDetails = {
  module: moduleName,
  name: 'Cashflow',
  estimatedTime: '5 - 10 mins',
  question: '',
  summary: 'How much you earn and spend monthly',
  route: CASHFLOW.baseRoute,
  prerequisiteText:
    'Review your bank or credit card statements to get a good sense of your monthly expenses.',
  subRoutes: [
    {
      subPath: 'setup',
      isStepPage: false,
    },
    {
      name: 'Income (Inflow)',
      subPath: 'inflow',
      isStepPage: true,
      nextPaths: [CASHFLOW.outflowExpenses],
    },
    {
      name: 'Expenses (Outflow)',
      subPath: 'outflow-expense',
      isStepPage: true,
      nextPaths: [CASHFLOW.outflowLoans],
    },
    {
      name: 'Monthly loan repayments (Outflow)',
      subPath: 'outflow-loans',
      isStepPage: true,
      nextPaths: [CASHFLOW.outflowInvestments],
    },
    {
      name: 'Monthly investments (Outflow)',
      subPath: 'outflow-investments',
      isStepPage: true,
      nextPaths: [CASHFLOW.completed],
    },
    {
      subPath: 'completed',
      isStepPage: false,
    },
  ],
  confirmationButtonText: 'Dashboard',
};

export const getCashflowModuleDetails = (store: IStore) => {
  return checkIfRoutesAreAllowed<IMainModuleDetails>(
    moduleDetails,
    getIsModuleStepAllowed(store, moduleName)
  );
};

import { compact } from 'lodash';
import moment from 'moment';

import { IGetInvestmentsBreakdownInSGDOptions } from 'redux/finances/selectors';

import {
  INVESTMENT_SOURCE,
  ASSET_TYPE,
  LIABILITY_TYPE,
  CDP_SECURITY_TYPE_CODE,
  SERVICE_PROVIDER,
  APIM_ASSET_FORMATTED_TYPE,
} from 'constants/enums';
import {
  IStore,
  IApimAmount,
  IApimHolding,
  IApimCasa,
  IApimFixedDeposit,
  IApimSecuredLoan,
  IApimUnsecuredLoan,
  IApimCreditCard,
  IApimLoanInstalment,
  IAssetBreakdown,
  IInvestmentBreakdown,
  ILiabilityBreakdown,
  IApimSrs,
  IApimCpfis,
  IApimCdpAccountDetailAndHolding,
  IApimCdpHolding,
  IApimCdpHoldingBalance,
} from 'constants/storeTypes';
import { ILoanRepayment } from 'constants/storeTypes';
import {
  calcApimAssetsValueInSGD,
  calcApimCdpAmountAssetValueInSGD,
} from 'utilities/assetUtilities';
import { convertToSGD } from 'utilities/currencyUtilities';

import { calcApimAssetValueInSGD } from './../../utilities/assetUtilities';
import {
  formatDisplayName,
  formatMaskedAccountNumber,
  formatAccountType,
  formatBalanceMonth,
} from './formatHelper';

interface IApimProductFormatted {
  id: string;
  displayName: string;
  serviceProvider: SERVICE_PROVIDER;
  balanceMonth: string;
  estimatedGrowthRate: number;
}

interface IApimAggregatedProductFormatted extends IApimProductFormatted {
  aggregateCount: number;
  amountByCurrencyByProducts: {
    [ccy: string]: number;
  }[];
}

interface IApimAmountFormatted {
  accountNumber: string;
  amountByCurrency: {
    [ccy: string]: number;
  };
}

interface IApimAccountType {
  accountType: string;
  isJoint: boolean;
}

export interface IApimCasaFormatted
  extends IApimAccountType,
    IApimAggregatedProductFormatted,
    IApimAmountFormatted {
  percentageShare: number;
  assetType: APIM_ASSET_FORMATTED_TYPE;
}

export interface IApimFixedDepositFormatted
  extends IApimAccountType,
    IApimAggregatedProductFormatted,
    IApimAmountFormatted {
  percentageShare: number;
  assetType: APIM_ASSET_FORMATTED_TYPE;
}

export interface IApimCpfisSrsHoldingFormatted extends IApimProductFormatted {
  holdingCode: string;
  holdingType: string;
  quantity: number;
  unitPrice: number;
  source: INVESTMENT_SOURCE.CPFIS | INVESTMENT_SOURCE.SRS;
  assetType: APIM_ASSET_FORMATTED_TYPE;
  holdingCcy: string;
  assetHoldingId: string;
}

export interface IApimCpfisSrsAmountFormatted extends IApimProductFormatted {
  source: INVESTMENT_SOURCE.CPFIS | INVESTMENT_SOURCE.SRS;
  amount: number;
  amountType: string;
  assetType: APIM_ASSET_FORMATTED_TYPE;
  surrenderValue: number;
  ccy: string;
}

export interface IApimUnitTrustHoldingFormatted extends IApimProductFormatted, IApimAccountType {
  accountNumber: string;
  ccy: string;
  quantity: number;
  unitPrice: number;
  percentageShare: number;
  assetType: APIM_ASSET_FORMATTED_TYPE;
  assetHoldingId: string;
  assetId: string;
}

export interface IApimSecuredLoanFormatted
  extends IApimAccountType,
    IApimAggregatedProductFormatted,
    IApimAmountFormatted,
    IApimLoanInstalment {
  percentageShare: number;
  isSecured: boolean;
  liabilityType: APIM_ASSET_FORMATTED_TYPE;
}

export interface IApimUnsecuredLoanFormatted
  extends IApimAccountType,
    IApimAggregatedProductFormatted,
    IApimAmountFormatted,
    IApimLoanInstalment {
  percentageShare: number;
  isSecured: boolean;
  liabilityType: APIM_ASSET_FORMATTED_TYPE;
}

export interface IApimCreditCardFormatted extends IApimAmountFormatted, IApimProductFormatted {
  liabilityType: APIM_ASSET_FORMATTED_TYPE;
}

export interface IApimCdpHoldingFormatted
  extends Omit<IApimCdpAccountDetailAndHolding, 'holdings'>,
    Omit<IApimCdpHolding, 'holdingBalances'>,
    IApimCdpHoldingBalance,
    IApimProductFormatted {
  holdingId: string;
  holdingBalanceId: string;
  assetType: APIM_ASSET_FORMATTED_TYPE;
}

export type IApimLoanFormatted = IApimUnsecuredLoanFormatted | IApimSecuredLoanFormatted;
export type IApimLiabilityFormatted = IApimLoanFormatted | IApimCreditCardFormatted;
export type IApimAssetFormatted =
  | IApimCasaFormatted
  | IApimFixedDepositFormatted
  | IApimCpfisSrsHoldingFormatted
  | IApimCpfisSrsAmountFormatted
  | IApimUnitTrustHoldingFormatted
  | IApimCdpHoldingFormatted;

export interface IGetExcludePercentageShareOption {
  excludePercentageShare?: boolean;
}

export const getApim = (store: IStore) => store.apim;

export const getApimStatementLatestMonthYear = (store: IStore) =>
  store.apim.statementLatestMonthYear;
export const getApimStatementPrevLatestMonthYear = (store: IStore) =>
  store.apim.statementLatestPrevMonthYear;
export const getApimCalendarLatestMonthYear = (store: IStore) => store.apim.calendarLatestMonthYear;

export const getApimLatestAmounts = (
  store: IStore,
  type: APIM_ASSET_FORMATTED_TYPE | APIM_ASSET_FORMATTED_TYPE,
  amounts: IApimAmount[]
): IApimAmount[] => {
  const amountsByMonth = amounts.reduce((acc, curr) => {
    if (!acc[curr.date]) {
      acc[curr.date] = [];
    }
    acc[curr.date].push(curr);
    return acc;
  }, {});

  const calendarLatestMonthYear = getApimCalendarLatestMonthYear(store);
  const calendarLatestPrevMonthYear = moment(calendarLatestMonthYear, 'MMYYYY')
    .subtract(1, 'months')
    .format('MMYYYY');

  switch (type) {
    // calender month
    case APIM_ASSET_FORMATTED_TYPE.CASA:
    case APIM_ASSET_FORMATTED_TYPE.FD:
    case APIM_ASSET_FORMATTED_TYPE.SRS_AMOUNT:
    case APIM_ASSET_FORMATTED_TYPE.CPFIS_AMOUNT: {
      return amountsByMonth[calendarLatestMonthYear] ? amountsByMonth[calendarLatestMonthYear] : [];
    }
    // statement month
    case APIM_ASSET_FORMATTED_TYPE.SECURED_LOAN:
    case APIM_ASSET_FORMATTED_TYPE.UNSECURED_LOAN:
    case APIM_ASSET_FORMATTED_TYPE.CREDIT_CARD: {
      const statementLatestMonthYear = getApimStatementLatestMonthYear(store);
      const statementLatestPrevMonthYear = getApimStatementPrevLatestMonthYear(store);

      if (
        moment(statementLatestMonthYear, 'MMYYYY').isAfter(
          moment(calendarLatestMonthYear, 'MMYYYY')
        )
      ) {
        return amountsByMonth[statementLatestMonthYear]
          ? amountsByMonth[statementLatestMonthYear]
          : amountsByMonth[statementLatestPrevMonthYear]
          ? amountsByMonth[statementLatestPrevMonthYear]
          : [];
      }

      return amountsByMonth[calendarLatestMonthYear]
        ? amountsByMonth[calendarLatestMonthYear]
        : amountsByMonth[calendarLatestPrevMonthYear]
        ? amountsByMonth[calendarLatestPrevMonthYear]
        : [];
    }
    default:
      return [];
  }
};

export const getApimLatestHoldings = (
  store: IStore,
  type: APIM_ASSET_FORMATTED_TYPE,
  holdings: IApimHolding[]
): IApimHolding[] => {
  const holdingsByMonth = holdings.reduce((acc, curr) => {
    if (!acc[curr.balancesDate]) {
      acc[curr.balancesDate] = [];
    }
    acc[curr.balancesDate].push(curr);
    return acc;
  }, {});

  switch (type) {
    // calender month
    case APIM_ASSET_FORMATTED_TYPE.UNIT_TRUST_HOLDING:
    case APIM_ASSET_FORMATTED_TYPE.SRS_HOLDING:
    case APIM_ASSET_FORMATTED_TYPE.CPFIS_HOLDING: {
      const calendarLatestMonthYear = getApimCalendarLatestMonthYear(store);
      return holdingsByMonth[calendarLatestMonthYear]
        ? holdingsByMonth[calendarLatestMonthYear]
        : [];
    }
    default:
      return [];
  }
};

export const getApimAmountByCurrency = (amounts: IApimAmount[]) =>
  amounts.reduce((a, { ccy, amount }) => {
    if (ccy in a) {
      return a;
    } else {
      a[ccy] = parseInt(amount);
      return a;
    }
  }, {});

export const getApimAmountByCurrencyByProducts = (
  aggregateCount: number,
  amounts: IApimAmount[]
) => {
  if (aggregateCount > 1) {
    const res = Array.from({ length: aggregateCount }, () => ({}));
    amounts.forEach(({ ccy, aggregatedAmounts }) => {
      aggregatedAmounts.forEach(({ amount, productNumbers }) => {
        productNumbers.forEach((productNumber) => (res[productNumber][ccy] = parseInt(amount)));
      });
    });
    return res;
  }
  return [];
};

export const getApimCdpRaw = (store: IStore): IApimCdpAccountDetailAndHolding[] => store.apim.cdp;

export const getApimCdpHoldingWithLatestBalance = (store: IStore): IApimCdpHoldingFormatted[] => {
  const ret: IApimCdpHoldingFormatted[] = [];
  const calendarLatestMonthYear = getApimCalendarLatestMonthYear(store);

  getApimCdpRaw(store).forEach(({ id, accountNumber, accountRelationship, holdings }) => {
    holdings.forEach(
      ({
        id: holdingId,
        securityType,
        percentageShare,
        status,
        isin,
        holdingBalances,
        estimatedGrowthRate,
      }) => {
        if (!holdingBalances.length || calendarLatestMonthYear !== holdingBalances[0].date) {
          return;
        }

        if (holdingBalances.length) {
          ret.push({
            holdingBalanceId: holdingBalances[0].id,
            ...holdingBalances[0],
            holdingId,
            id,
            accountNumber,
            accountRelationship,
            securityType,
            percentageShare,
            status,
            isin,
            displayName: isin.primaryStockCode
              ? `${isin.name} (${isin.primaryStockCode})`
              : isin.name,
            balanceMonth: formatBalanceMonth(holdingBalances[0].date),
            assetType: APIM_ASSET_FORMATTED_TYPE.CDP,
            serviceProvider: SERVICE_PROVIDER.CDP,
            estimatedGrowthRate,
          });
        }
      }
    );
  });

  return ret;
};

export const getApimCasaFormatted = (store: IStore): IApimCasaFormatted[] =>
  compact(
    getApimCasaRaw(store).map(
      ({
        id,
        amounts,
        serviceProvider,
        accountNumber,
        accountRelationship,
        productName,
        accountType,
        percentageShare,
        aggregateCount,
      }) => {
        const latestAmounts = getApimLatestAmounts(store, APIM_ASSET_FORMATTED_TYPE.CASA, amounts);
        if (!latestAmounts.length) {
          return null;
        }

        const balanceMonth = formatBalanceMonth(latestAmounts[0].date);
        return {
          id,
          displayName: formatDisplayName(serviceProvider, productName),
          serviceProvider: SERVICE_PROVIDER[serviceProvider],
          accountNumber: formatMaskedAccountNumber(accountNumber),
          accountType: formatAccountType(accountRelationship, accountType),
          balanceMonth,
          amountByCurrency: getApimAmountByCurrency(latestAmounts),
          amountByCurrencyByProducts: getApimAmountByCurrencyByProducts(
            aggregateCount,
            latestAmounts
          ),
          percentageShare,
          isJoint: accountRelationship.toLowerCase().includes('joint'),
          assetType: APIM_ASSET_FORMATTED_TYPE.CASA,
          aggregateCount,
          estimatedGrowthRate: latestAmounts[0].estimatedGrowthRate,
        };
      }
    )
  );

export const getApimCasaByMonthInSGD = (
  store: IStore,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
): { [month: string]: number } => {
  const amountByMonth: { [month: string]: number } = {};
  getApimCasaRaw(store).forEach(({ amounts, percentageShare, accountRelationship }) => {
    const isJoint = accountRelationship.toLowerCase().includes('joint');
    const finalPercentage = !excludePercentageShare && isJoint ? percentageShare * 0.01 : 1;

    amounts.forEach(({ date, ccy, amount }) => {
      if (!amountByMonth[date]) {
        amountByMonth[date] = 0;
      }
      amountByMonth[date] += convertToSGD(Number(amount), ccy) * finalPercentage;
    });
  });

  return Object.entries(amountByMonth).reduce(
    (acc, [month, value]) => ({
      ...acc,
      [month]: Math.round(value),
    }),
    {}
  );
};

export const getJointAccCount = (store: IStore): number => {
  const resourcesToCheck = [
    ...getApimAssetsFormatted(store),
    ...getApimLiabilitiesFormatted(store),
  ];

  const jointAccs = resourcesToCheck.filter((account) => 'isJoint' in account && account.isJoint);

  return jointAccs.length;
};

export const getApimCasaRaw = (store: IStore): IApimCasa[] => store.apim.casa;

export const getApimCasaBreakdownInSGD = (
  store: IStore,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
): IAssetBreakdown[] => {
  return Object.entries(getApimCasaFormatted(store)).map(
    ([
      _,
      { displayName, amountByCurrency, isJoint, percentageShare, amountByCurrencyByProducts },
    ]) => {
      const finalPercentage = !excludePercentageShare && isJoint ? percentageShare * 0.01 : 1;
      return {
        name: displayName,
        amount: Math.round(
          Object.entries(amountByCurrency).reduce<number>(
            (acc, [ccy, amount]) => acc + finalPercentage * convertToSGD(amount, ccy),
            0
          )
        ),
        amountsByProducts: amountByCurrencyByProducts.map((acbp) =>
          Math.round(
            Object.entries(acbp).reduce<number>(
              (acc, [ccy, amount]) => acc + finalPercentage * convertToSGD(amount, ccy),
              0
            )
          )
        ),
      };
    }
  );
};

export const getApimFixedDepositFormatted = (store: IStore): IApimFixedDepositFormatted[] =>
  compact(
    getApimFixedDepositRaw(store).map(
      ({
        id,
        amounts,
        serviceProvider,
        accountNumber,
        accountRelationship,
        productName,
        percentageShare,
        aggregateCount,
      }) => {
        const latestAmounts = getApimLatestAmounts(store, APIM_ASSET_FORMATTED_TYPE.FD, amounts);
        if (!latestAmounts.length) {
          return null;
        }

        const balanceMonth = formatBalanceMonth(latestAmounts[0].date);
        return {
          id,
          displayName: formatDisplayName(serviceProvider, productName),
          serviceProvider: SERVICE_PROVIDER[serviceProvider],
          accountNumber: formatMaskedAccountNumber(accountNumber),
          accountType: formatAccountType(accountRelationship, 'Fixed deposit'),
          balanceMonth,
          amountByCurrency: getApimAmountByCurrency(latestAmounts),
          amountByCurrencyByProducts: getApimAmountByCurrencyByProducts(
            aggregateCount,
            latestAmounts
          ),
          percentageShare,
          isJoint: accountRelationship.toLowerCase().includes('joint'),
          assetType: APIM_ASSET_FORMATTED_TYPE.FD,
          aggregateCount,
          estimatedGrowthRate: latestAmounts[0].estimatedGrowthRate,
        };
      }
    )
  );

export const getApimFixedDepositRaw = (store: IStore): IApimFixedDeposit[] =>
  store.apim.fixedDeposit;

export const getApimSecuredLoanFormatted = (store: IStore): IApimSecuredLoanFormatted[] =>
  compact(
    getApimSecuredLoanRaw(store).map(
      ({
        id,
        amounts,
        serviceProvider,
        accountNumber,
        accountRelationship,
        productName,
        type,
        monthlyCashLoanInstalment,
        monthlyCPFLoanInstalment,
        percentageShare,
        aggregateCount,
      }) => {
        const latestAmounts = getApimLatestAmounts(
          store,
          APIM_ASSET_FORMATTED_TYPE.SECURED_LOAN,
          amounts
        );
        if (!latestAmounts.length) {
          return null;
        }

        const balanceMonth = formatBalanceMonth(latestAmounts[0].date);
        return {
          id,
          displayName: formatDisplayName(serviceProvider, productName),
          serviceProvider: SERVICE_PROVIDER[serviceProvider],
          accountNumber: formatMaskedAccountNumber(accountNumber),
          accountType: formatAccountType(accountRelationship, 'Loan'),
          balanceMonth,
          amountByCurrency: getApimAmountByCurrency(latestAmounts),
          amountByCurrencyByProducts: getApimAmountByCurrencyByProducts(
            aggregateCount,
            latestAmounts
          ),
          monthlyCashLoanInstalment,
          monthlyCPFLoanInstalment,
          percentageShare,
          type,
          isJoint: accountRelationship.toLowerCase().includes('joint'),
          isSecured: true,
          liabilityType: APIM_ASSET_FORMATTED_TYPE.SECURED_LOAN,
          aggregateCount,
          estimatedGrowthRate: latestAmounts[0].estimatedGrowthRate,
        };
      }
    )
  );

export const getApimSecuredLoanRaw = (store: IStore): IApimSecuredLoan[] => store.apim.securedLoan;

export const getApimUnsecuredLoanFormatted = (store: IStore): IApimUnsecuredLoanFormatted[] =>
  compact(
    getApimUnsecuredLoanRaw(store).map(
      ({
        id,
        amounts,
        serviceProvider,
        accountNumber,
        accountRelationship,
        productName,
        type,
        monthlyCashLoanInstalment,
        monthlyCPFLoanInstalment,
        percentageShare,
        aggregateCount,
      }) => {
        const latestAmounts = getApimLatestAmounts(
          store,
          APIM_ASSET_FORMATTED_TYPE.UNSECURED_LOAN,
          amounts
        );
        if (!latestAmounts.length) {
          return null;
        }

        const balanceMonth = formatBalanceMonth(latestAmounts[0].date);
        return {
          id,
          displayName: formatDisplayName(serviceProvider, productName),
          serviceProvider: SERVICE_PROVIDER[serviceProvider],
          accountNumber: formatMaskedAccountNumber(accountNumber),
          accountType: formatAccountType(accountRelationship, 'Loan'),
          balanceMonth,
          amountByCurrency: getApimAmountByCurrency(latestAmounts),
          amountByCurrencyByProducts: getApimAmountByCurrencyByProducts(
            aggregateCount,
            latestAmounts
          ),
          monthlyCashLoanInstalment,
          monthlyCPFLoanInstalment,
          percentageShare,
          type,
          isJoint: accountRelationship.toLowerCase().includes('joint'),
          isSecured: false,
          liabilityType: APIM_ASSET_FORMATTED_TYPE.UNSECURED_LOAN,
          aggregateCount,
          estimatedGrowthRate: latestAmounts[0].estimatedGrowthRate,
        };
      }
    )
  );

export const getApimUnsecuredLoanRaw = (store: IStore): IApimUnsecuredLoan[] =>
  store.apim.unsecuredLoan;

export const getApimAssetsFormatted = (store: IStore): IApimAssetFormatted[] => [
  ...getApimCasaFormatted(store),
  ...getApimFixedDepositFormatted(store),
  ...getApimCpfisHoldingsFormatted(store),
  ...getApimCpfisAmountsFormatted(store),
  ...getApimSrsHoldingsFormatted(store),
  ...getApimSrsAmountsFormatted(store),
  ...getApimUnitTrustHoldingsFormatted(store),
  ...getApimCdpHoldingWithLatestBalance(store),
];

export const getApimLiabilitiesFormatted = (store: IStore): IApimLiabilityFormatted[] => {
  return [...getApimLoansFormatted(store), ...getApimCreditCardsFormatted(store)];
};

export const getApimLoansFormatted = (store: IStore): IApimLoanFormatted[] => {
  const apimSecuredLoan = getApimSecuredLoanFormatted(store);
  const apimUnsecuredLoan = getApimUnsecuredLoanFormatted(store);
  const apimLoan = apimSecuredLoan.concat(apimUnsecuredLoan);
  return apimLoan;
};

export const getApimLoansAsLoanRepayments = (store: IStore): { [id: string]: ILoanRepayment } => {
  const apimLoans = getApimLoansFormatted(store);

  return apimLoans.reduce<{ [id: string]: ILoanRepayment }>((acc, curr) => {
    const {
      id,
      monthlyCashLoanInstalment,
      monthlyCPFLoanInstalment,
      amountByCurrency,
      type,
      displayName,
    } = curr;
    const [ccy] = Object.entries(amountByCurrency)[0];

    acc[id] = {
      id,
      name: displayName,
      amount: monthlyCashLoanInstalment + monthlyCPFLoanInstalment,
      cash: monthlyCashLoanInstalment,
      cpf: monthlyCPFLoanInstalment,
      ccy,
      type: type.id,
    };
    return acc;
  }, {});
};

export const getTotalApimAssetsValueInSGD = (store: IStore): number =>
  calcApimAssetsValueInSGD(getApimAssetsFormatted(store));

export const getTotalApimCasasInSGD = (
  store: IStore,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
): number => calcApimAssetsValueInSGD(getApimCasaFormatted(store), { excludePercentageShare });

export const getTotalApimCashFixedDepositInSGD = (store: IStore): number =>
  calcApimAssetsValueInSGD(getApimFixedDepositFormatted(store));

export const getTotalApimLiabilitiesInSGD = (store: IStore): number => {
  const totalLoans = getTotalApimLoansInSGD(store);
  const totalCreditCards = getTotalApimCreditCardsInSGD(store);
  return totalLoans + totalCreditCards;
};

export const getTotalApimLoansInSGD = (
  store: IStore,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
): number =>
  Math.round(
    getApimLoansFormatted(store).reduce<number>(
      (a, { amountByCurrency, percentageShare, isJoint }) => {
        const finalPercentage = !excludePercentageShare && isJoint ? percentageShare * 0.01 : 1;
        return (
          a +
          Object.entries(amountByCurrency).reduce<number>(
            (a, [ccy, amount]) => a + convertToSGD(amount, ccy) * finalPercentage,
            0
          )
        );
      },
      0
    )
  );

export const getApimLiabilitiesBreakdownInSGD = (store: IStore): ILiabilityBreakdown[] => {
  const loansBreakdown = getApimLoansBreakdownInSGD(store);
  const creditCardsBreakdown = getApimCreditCardBreakdownInSGD(store);
  return [...loansBreakdown, ...creditCardsBreakdown];
};

export const getApimLoansBreakdownInSGD = (
  store: IStore,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
): ILiabilityBreakdown[] =>
  getApimLoansFormatted(store).map(
    ({
      displayName,
      type,
      amountByCurrency,
      percentageShare,
      isJoint,
      amountByCurrencyByProducts,
      liabilityType,
    }) => {
      const finalPercentage = !excludePercentageShare && isJoint ? percentageShare * 0.01 : 1;
      return {
        name: displayName,
        amount: Math.round(
          Object.entries(amountByCurrency).reduce<number>(
            (a, [ccy, amount]) => a + convertToSGD(amount, ccy) * finalPercentage,
            0
          )
        ),
        amountsByProducts: amountByCurrencyByProducts.map((acbp) =>
          Math.round(
            Object.entries(acbp).reduce<number>(
              (acc, [ccy, amount]) => acc + finalPercentage * convertToSGD(amount, ccy),
              0
            )
          )
        ),
        type: type.id,
        liabilityType,
      };
    }
  );

export const getTotalApimCreditCardsInSGD = (store: IStore): number =>
  getApimCreditCardsFormatted(store).reduce<number>(
    (a, { amountByCurrency }) =>
      a +
      Object.entries(amountByCurrency).reduce<number>(
        (a, [ccy, amount]) => a + convertToSGD(amount, ccy),
        0
      ),
    0
  );

export const formattedAmountNames = {
  balanceFD: 'Fixed Deposit',
  balanceSD: 'Structured Deposit',
  balanceSP: 'Structured Products',
  premiumPaid: 'Insurance',
  balanceFM: 'Fund Management',
  balanceCash: 'Cash',
  balanceSSB: 'Bond',
};

export const formattedHoldingNames = {
  equity: 'Equity',
  unitTrust: 'Unit trust',
  bond: 'Bond',
  gold: 'Gold',
};

export const getApimSrs = (store: IStore) => store.apim.srs;

export const getApimLatestSrs = (store: IStore): IApimSrs => {
  const srsAccounts = getApimSrs(store);

  if (!srsAccounts.length) {
    return {} as IApimSrs;
  }

  return srsAccounts.reduce((acc, curr) => {
    const latestHoldings = getApimLatestHoldings(
      store,
      APIM_ASSET_FORMATTED_TYPE.SRS_HOLDING,
      curr.holdings
    );
    const latestAmounts = getApimLatestAmounts(
      store,
      APIM_ASSET_FORMATTED_TYPE.SRS_AMOUNT,
      curr.amounts
    );
    if (latestHoldings.length || latestAmounts.length) {
      acc = curr;
    }
    return acc;
  }, {} as IApimSrs);
};

export const getApimSrsAccountNumber = (store: IStore): string => {
  const srs = getApimLatestSrs(store);
  if (!Object.keys(srs).length) {
    return '';
  }
  return formatMaskedAccountNumber(srs.accountNumber);
};

export const getApimSrsHoldingsFormatted = (store: IStore): IApimCpfisSrsHoldingFormatted[] => {
  const srs = getApimLatestSrs(store);
  if (!Object.keys(srs).length) {
    return [];
  }

  const latestHoldings = getApimLatestHoldings(
    store,
    APIM_ASSET_FORMATTED_TYPE.SRS_HOLDING,
    srs.holdings
  );
  if (!latestHoldings.length) {
    return [];
  }

  const { serviceProvider } = srs;
  const balanceMonth = formatBalanceMonth(latestHoldings[0].balancesDate);
  return latestHoldings.map(
    ({
      id,
      holdingCode,
      holdingType,
      balancesQuantity,
      unitPrice,
      holdingCcy,
      assetHoldingId,
      estimatedGrowthRate,
    }) => ({
      id,
      serviceProvider: SERVICE_PROVIDER[serviceProvider],
      displayName: holdingCode,
      balanceMonth,
      holdingCode,
      holdingType: formattedHoldingNames[holdingType],
      quantity: parseFloat(balancesQuantity),
      unitPrice: unitPrice,
      source: INVESTMENT_SOURCE.SRS,
      assetType: APIM_ASSET_FORMATTED_TYPE.SRS_HOLDING,
      holdingCcy,
      assetHoldingId,
      estimatedGrowthRate,
    })
  );
};

export const getApimSrsAmountsFormatted = (store: IStore): IApimCpfisSrsAmountFormatted[] => {
  const srs = getApimLatestSrs(store);
  if (!Object.keys(srs).length) {
    return [];
  }

  const latestAmounts = getApimLatestAmounts(
    store,
    APIM_ASSET_FORMATTED_TYPE.SRS_AMOUNT,
    srs.amounts
  );
  if (!latestAmounts.length) {
    return [];
  }

  const { serviceProvider } = srs;
  const balanceMonth = formatBalanceMonth(latestAmounts[0].date);
  return latestAmounts.map((apimAmount) => {
    const {
      id,
      amountType,
      amount,
      description,
      surrenderValue,
      ccy,
      estimatedGrowthRate,
    } = apimAmount;
    return {
      id,
      displayName: description || '',
      serviceProvider: SERVICE_PROVIDER[serviceProvider],
      balanceMonth,
      amountType: formattedAmountNames[amountType],
      amount: parseInt(amount),
      source: INVESTMENT_SOURCE.SRS,
      assetType: APIM_ASSET_FORMATTED_TYPE.SRS_AMOUNT,
      surrenderValue,
      ccy,
      estimatedGrowthRate,
    };
  });
};

export const getApimCpfis = (store: IStore) => store.apim.cpfis;

export const getApimLatestCpfis = (store: IStore): IApimCpfis => {
  const cpfisAccounts = getApimCpfis(store);

  if (!cpfisAccounts.length) {
    return {} as IApimCpfis;
  }

  return cpfisAccounts.reduce((acc, curr) => {
    const latestHoldings = getApimLatestHoldings(
      store,
      APIM_ASSET_FORMATTED_TYPE.CPFIS_HOLDING,
      curr.holdings
    );
    const latestAmounts = getApimLatestAmounts(
      store,
      APIM_ASSET_FORMATTED_TYPE.CPFIS_AMOUNT,
      curr.amounts
    );
    if (latestHoldings.length || latestAmounts.length) {
      acc = curr;
    }
    return acc;
  }, {} as IApimCpfis);
};

export const getApimCpfisAccountNumber = (store: IStore): string => {
  const cpfis = getApimLatestCpfis(store);
  if (!Object.keys(cpfis).length) {
    return '';
  }
  return formatMaskedAccountNumber(cpfis.accountNumber);
};

export const getApimCpfisHoldingsFormatted = (store: IStore): IApimCpfisSrsHoldingFormatted[] => {
  const cpfis = getApimLatestCpfis(store);
  if (!Object.keys(cpfis).length) {
    return [];
  }

  const latestHoldings = getApimLatestHoldings(
    store,
    APIM_ASSET_FORMATTED_TYPE.CPFIS_HOLDING,
    cpfis.holdings
  );
  if (!latestHoldings.length) {
    return [];
  }

  const { serviceProvider } = cpfis;
  const balanceMonth = formatBalanceMonth(latestHoldings[0].balancesDate);
  return latestHoldings.map(
    ({
      id,
      holdingCode,
      holdingType,
      balancesQuantity,
      unitPrice,
      holdingCcy,
      assetHoldingId,
      estimatedGrowthRate,
    }) => ({
      id,
      displayName: holdingCode,
      serviceProvider: SERVICE_PROVIDER[serviceProvider],
      balanceMonth,
      holdingCode,
      holdingType: formattedHoldingNames[holdingType],
      quantity: parseFloat(balancesQuantity),
      unitPrice: unitPrice,
      source: INVESTMENT_SOURCE.CPFIS,
      assetType: APIM_ASSET_FORMATTED_TYPE.CPFIS_HOLDING,
      holdingCcy,
      assetHoldingId,
      estimatedGrowthRate,
    })
  );
};

export const getApimCpfisAmountsFormatted = (store: IStore): IApimCpfisSrsAmountFormatted[] => {
  const cpfis = getApimLatestCpfis(store);
  if (!Object.keys(cpfis).length) {
    return [];
  }

  const { serviceProvider } = cpfis;
  return getApimLatestAmounts(store, APIM_ASSET_FORMATTED_TYPE.CPFIS_AMOUNT, cpfis.amounts).map(
    (apimAmount) => {
      const {
        id,
        amountType,
        amount,
        date,
        description,
        surrenderValue,
        ccy,
        estimatedGrowthRate,
      } = apimAmount;
      return {
        id,
        displayName: description || '',
        serviceProvider: SERVICE_PROVIDER[serviceProvider],
        balanceMonth: formatBalanceMonth(date),
        amountType: formattedAmountNames[amountType],
        amount: parseInt(amount),
        source: INVESTMENT_SOURCE.CPFIS,
        assetType: APIM_ASSET_FORMATTED_TYPE.CPFIS_AMOUNT,
        surrenderValue,
        ccy,
        estimatedGrowthRate,
      };
    }
  );
};

export const getApimUnitTrustHoldingsFormatted = (
  store: IStore
): IApimUnitTrustHoldingFormatted[] => {
  const formatAccountType = (accountRelationship: string) => {
    if (accountRelationship.toLowerCase().includes('joint')) {
      return 'Joint unit trust';
    } else {
      return 'Unit trust';
    }
  };

  const unitTrusts = store.apim.unitTrust;
  if (!unitTrusts.length) {
    return [];
  }

  return compact(
    unitTrusts.flatMap(
      ({ id: assetId, accountNumber, serviceProvider, accountRelationship, holdings }) => {
        const latestHoldings = getApimLatestHoldings(
          store,
          APIM_ASSET_FORMATTED_TYPE.UNIT_TRUST_HOLDING,
          holdings
        );
        if (!latestHoldings.length) {
          return [];
        }

        const balanceMonth = formatBalanceMonth(latestHoldings[0].balancesDate);
        return latestHoldings.map(
          ({
            id,
            holdingCode,
            balancesQuantity,
            holdingCcy,
            unitPrice,
            percentageShare,
            assetHoldingId,
            estimatedGrowthRate,
          }) => ({
            id,
            displayName: holdingCode,
            serviceProvider: SERVICE_PROVIDER[serviceProvider],
            balanceMonth,
            accountNumber: formatMaskedAccountNumber(accountNumber),
            ccy: holdingCcy,
            quantity: parseFloat(balancesQuantity),
            unitPrice,
            percentageShare,
            accountType: formatAccountType(accountRelationship),
            isJoint: accountRelationship.toLowerCase().includes('joint'),
            assetType: APIM_ASSET_FORMATTED_TYPE.UNIT_TRUST_HOLDING,
            assetHoldingId,
            estimatedGrowthRate,
            assetId,
          })
        );
      }
    )
  );
};
const convertAmountTypeToAssetType = (amountType: string): ASSET_TYPE => {
  switch (amountType) {
    case 'Structured Deposit':
    case 'Structured Products':
      return ASSET_TYPE.STRUCTURED_PRODUCT;
    case 'Bond':
      return ASSET_TYPE.BOND;
    case 'Fixed Deposit':
      return ASSET_TYPE.FIXED_DEPOSIT;
    case 'Fund Management':
      return ASSET_TYPE.UNIT_TRUST;
    case 'Insurance':
      return ASSET_TYPE.INSURANCE;
    case 'Cash':
      return ASSET_TYPE.CASH_BALANCE;
    default:
      throw new Error('invalid AmountType');
  }
};

export const convertSecurityTypeToHoldingType = (securityType: number) => {
  switch (securityType) {
    case CDP_SECURITY_TYPE_CODE.ABC_SHARES:
    case CDP_SECURITY_TYPE_CODE.ORDINARY_SHARES:
    case CDP_SECURITY_TYPE_CODE.PREFERENCE_SHARE:
    case CDP_SECURITY_TYPE_CODE.DEPOSITORY_RECEIPTS:
    case CDP_SECURITY_TYPE_CODE.LOAN_STOCKS:
      return ASSET_TYPE.STOCK;
    case CDP_SECURITY_TYPE_CODE.CORPORATE_DEBT:
    case CDP_SECURITY_TYPE_CODE.TBOND:
    case CDP_SECURITY_TYPE_CODE.TBILL:
      return ASSET_TYPE.BOND;
    case CDP_SECURITY_TYPE_CODE.UNIT_TRUST:
    case CDP_SECURITY_TYPE_CODE.FUND:
    case CDP_SECURITY_TYPE_CODE.LOCAL_EXCHANGE_TRADED_FUNDS:
      return ASSET_TYPE.UNIT_TRUST;
    case CDP_SECURITY_TYPE_CODE.PLAIN_WARRANTS:
    case CDP_SECURITY_TYPE_CODE.RIGHTS:
    case CDP_SECURITY_TYPE_CODE.STAPLED_SECURITY:
    case CDP_SECURITY_TYPE_CODE.FOREIGN_OWNED:
    case CDP_SECURITY_TYPE_CODE.CALL_OPTION:
    case CDP_SECURITY_TYPE_CODE.PUT_OPTION:
    case CDP_SECURITY_TYPE_CODE.EXTENDED_SETTLEMENT:
      return ASSET_TYPE.OTHER_INVESTMENT;
    case CDP_SECURITY_TYPE_CODE.STRUCTURED_WARRANTS:
      return ASSET_TYPE.STRUCTURED_PRODUCT;
    default:
      throw new Error('invalid holdingType');
  }
};

export const convertHoldingTypeToAssetType = (holdingType: string): ASSET_TYPE => {
  switch (holdingType) {
    case 'Bond':
      return ASSET_TYPE.BOND;
    case 'Equity':
      return ASSET_TYPE.STOCK;
    case 'Unit trust':
      return ASSET_TYPE.UNIT_TRUST;
    case 'Gold':
      return ASSET_TYPE.GOLD;
    case 'Others':
      return ASSET_TYPE.OTHER_ASSETS;
    case 'Structured Product':
      return ASSET_TYPE.STRUCTURED_PRODUCT;
    default:
      throw new Error('invalid holdingType');
  }
};

export const getApimInvestmentsBreakdownInSGD = (
  store: IStore,
  {
    excludeCashBalances = false,
    excludePercentageShare = false,
  }: IGetInvestmentsBreakdownInSGDOptions & IGetExcludePercentageShareOption = {}
): IInvestmentBreakdown[] => {
  const fds = getApimFixedDepositFormatted(store);
  const uts = getApimUnitTrustHoldingsFormatted(store);
  const cdp = getApimCdpHoldingWithLatestBalance(store);

  const fdsBreakdown = fds.map((asset) => {
    const {
      id,
      displayName,
      serviceProvider,
      percentageShare,
      isJoint,
      amountByCurrencyByProducts,
      balanceMonth,
      accountNumber,
      estimatedGrowthRate,
    } = asset;
    const finalPercentage = !excludePercentageShare && isJoint ? percentageShare * 0.01 : 1;
    return {
      id,
      name: displayName,
      serviceProvider,
      accountNumber,
      amount: calcApimAssetValueInSGD(asset, { excludePercentageShare }),
      investmentSource: INVESTMENT_SOURCE.CASH,
      type: ASSET_TYPE.FIXED_DEPOSIT,
      balanceMonth: balanceMonth,
      estimatedGrowthRate,
      isJoint,
      amountsByProducts: amountByCurrencyByProducts.map((acbp) =>
        Math.round(
          Object.entries(acbp).reduce<number>(
            (acc, [ccy, amount]) => acc + finalPercentage * convertToSGD(amount, ccy),
            0
          )
        )
      ),
    };
  });

  const utsBreakdown = uts.map((asset) => ({
    id: asset.assetId,
    holdingId: asset.assetHoldingId,
    name: asset.displayName,
    serviceProvider: asset.serviceProvider,
    accountNumber: asset.accountNumber,
    amount: calcApimAssetValueInSGD(asset, { excludePercentageShare }),
    investmentSource: INVESTMENT_SOURCE.CASH,
    type: ASSET_TYPE.UNIT_TRUST,
    balanceMonth: asset.balanceMonth,
    estimatedGrowthRate: asset.estimatedGrowthRate,
    isJoint: asset.isJoint,
  }));

  const cdpBreakdown = cdp.map((asset) => ({
    id: asset.id,
    holdingId: asset.holdingId,
    name: asset.displayName,
    accountNumber: asset.accountNumber,
    amount: calcApimCdpAmountAssetValueInSGD(asset, { excludePercentageShare }),
    investmentSource: INVESTMENT_SOURCE.CASH,
    type: convertSecurityTypeToHoldingType(asset.securityType),
    balanceMonth: asset.balanceMonth,
    serviceProvider: SERVICE_PROVIDER.CDP,
    estimatedGrowthRate: asset.estimatedGrowthRate,
    isJoint: asset.accountRelationship.toLowerCase().includes('joint'),
  }));

  const cashBreakdowns = [...fdsBreakdown, ...utsBreakdown, ...cdpBreakdown];

  const srs = getApimLatestSrs(store);
  const cpfis = getApimLatestCpfis(store);

  const holdings = [...getApimSrsHoldingsFormatted(store), ...getApimCpfisHoldingsFormatted(store)];
  const holdingsBreakdown = holdings.map((asset) => ({
    id: asset.source === INVESTMENT_SOURCE.CPFIS ? cpfis.id : srs.id,
    holdingId: asset.assetHoldingId,
    name: asset.displayName,
    serviceProvider: asset.serviceProvider,
    amount: calcApimAssetValueInSGD(asset, { excludePercentageShare }),
    accountNumber:
      asset.source === INVESTMENT_SOURCE.CPFIS ? cpfis.accountNumber : srs.accountNumber,
    investmentSource: INVESTMENT_SOURCE[asset.source],
    type: convertHoldingTypeToAssetType(asset.holdingType),
    balanceMonth: asset.balanceMonth,
    estimatedGrowthRate: asset.estimatedGrowthRate,
  }));

  const amounts = [...getApimSrsAmountsFormatted(store), ...getApimCpfisAmountsFormatted(store)];
  const amountsBreakdown = amounts.map((asset) => ({
    id: asset.source === INVESTMENT_SOURCE.CPFIS ? cpfis.id : srs.id,
    amountId: asset.id,
    name: asset.displayName,
    serviceProvider: asset.serviceProvider,
    accountNumber:
      asset.source === INVESTMENT_SOURCE.CPFIS ? cpfis.accountNumber : srs.accountNumber,
    amount: calcApimAssetValueInSGD(asset, { excludePercentageShare }),
    investmentSource: INVESTMENT_SOURCE[asset.source],
    type: convertAmountTypeToAssetType(asset.amountType),
    balanceMonth: asset.balanceMonth,
    estimatedGrowthRate: asset.estimatedGrowthRate,
  }));

  const totalBreakdowns = [...cashBreakdowns, ...holdingsBreakdown, ...amountsBreakdown];

  return excludeCashBalances
    ? totalBreakdowns.filter(({ type }) => type !== ASSET_TYPE.CASH_BALANCE)
    : totalBreakdowns;
};

export const getApimCreditCards = (store: IStore): IApimCreditCard[] => store.apim.creditCard;

export const getApimCreditCardsFormatted = (store: IStore): IApimCreditCardFormatted[] =>
  compact(
    getApimCreditCards(store).map(
      ({ id, serviceProvider, productName, accountNumber, amounts }) => {
        const latestAmounts = getApimLatestAmounts(
          store,
          APIM_ASSET_FORMATTED_TYPE.CREDIT_CARD,
          amounts
        );
        if (!latestAmounts.length) {
          return null;
        }

        const balanceMonth = formatBalanceMonth(latestAmounts[0].date);
        return {
          id,
          displayName: formatDisplayName(serviceProvider, productName),
          serviceProvider: SERVICE_PROVIDER[serviceProvider],
          accountNumber: formatMaskedAccountNumber(accountNumber),
          balanceMonth,
          amountByCurrency: getApimAmountByCurrency(latestAmounts),
          liabilityType: APIM_ASSET_FORMATTED_TYPE.CREDIT_CARD,
          estimatedGrowthRate: latestAmounts[0].estimatedGrowthRate,
        };
      }
    )
  );

export const getApimCreditCardsExpensesByMonthInSGD = (
  store: IStore
): { [month: string]: number } => {
  const amountByMonth: { [month: string]: number } = {};
  getApimCreditCards(store).forEach(({ amounts }) =>
    amounts.forEach(({ date, ccy, amount }) => {
      if (!amountByMonth[date]) {
        amountByMonth[date] = 0;
      }
      amountByMonth[date] += convertToSGD(Number(amount), ccy);
    })
  );
  return amountByMonth;
};

export const getApimCreditCardBreakdownInSGD = (store: IStore): ILiabilityBreakdown[] =>
  getApimCreditCardsFormatted(store).map(({ displayName, amountByCurrency, liabilityType }) => ({
    name: displayName,
    amount: Object.entries(amountByCurrency).reduce<number>(
      (acc, [ccy, amount]) => acc + convertToSGD(amount, ccy),
      0
    ),
    type: LIABILITY_TYPE.NON_MORTGAGE,
    liabilityType,
  }));

import moment from 'moment';

import { getApimCalendarLatestMonthYear } from 'redux/apim/selectors';
import { getManualFundsLastUpdatedAt } from 'redux/finances/selectors';
import {
  getHasCompletedFeedbackForm,
  getProfileLastLogin,
  getHasCompletedOnboardingProfileQuestions,
  getApimLatestPullConsentAt,
  getEmailVerificationStatus,
} from 'redux/profile/selectors';
import { getOldestExpiredTargetDate } from 'redux/targets/selectors';

import { EMAIL_VERIFICATION_STATUS, NOTIFICATION } from 'constants/enums';
import { notificationDetails, INBUILT_NOTIFICATIONS } from 'constants/notificationConstants';
import { IStore, INotification } from 'constants/storeTypes';
import { INTERVAL_BEFORE_RETURN_FLOW } from 'constants/timeConstants';
import { getReturnFlowDateFrom } from 'utilities/dateUtilities';
import { descending } from 'utilities/sortingUtilities';

import { getTotalApimCasasInSGD, getTotalApimCashFixedDepositInSGD } from '../apim/selectors';

export const getTodoNotificationAcknowledgements = (store: IStore) =>
  store.notifications.todoNotificationAcknowledgements;

export const getNotifications = (store: IStore) =>
  [...getUpdateNotifications(store), ...getTodoNotifications(store)].sort((a, b) =>
    descending(new Date(a.date), new Date(b.date))
  );

export const getTodoNotifications = (store: IStore): INotification[] => {
  const todoNotifications = [
    getIncompleteProfileNotification(store),
    getUpdateDataNotification(store),
    getGiveFeedbackNotification(store),
    getInvestmentScamsArticleNotification(store),
    getDbsSystemUpdateNotification(store),
  ].filter((notification) => notification !== null);

  return todoNotifications as INotification[];
};

export const getNumberOfUnacknowledgedNotifications = (store: IStore) =>
  getNotifications(store).filter((notification) => !notification.acknowledged).length;

export const getUpdateNotifications = (store: IStore) => {
  const updateNotifications = [
    ...Object.values(store.notifications.updateNotifications),
    getTermAndConditionsRevisionNotification(store),
  ].filter((notification) => notification !== null);

  return updateNotifications as INotification[];
};

export const getInbuiltNotifications = (store: IStore): INotification[] =>
  INBUILT_NOTIFICATIONS.map((notification) => ({
    ...notification,
    acknowledged: store.notifications.inbuiltNotificationAcknowledgements.includes(notification.id),
  }));

export const getUpdateDataNotification = (store: IStore) => {
  const updateFundsAndTargetsNotification = getUpdateFundsAndTargetsNotification(store);

  if (!updateFundsAndTargetsNotification) {
    return null;
  }

  const updateBankDataNotification = getUpdateBankDataNotification(
    store,
    new Date(updateFundsAndTargetsNotification.date)
  );

  return updateBankDataNotification || updateFundsAndTargetsNotification;
};

export const getUpdateBankDataNotification = (store: IStore, date: Date): INotification | null => {
  const apimCalendarLatestMonthYear = getApimCalendarLatestMonthYear(store);
  if (!apimCalendarLatestMonthYear) {
    return null;
  }

  const apimCalendarLatestDate = moment(apimCalendarLatestMonthYear, 'MMYYYY').toDate();
  const hasLatestApimData = moment().diff(apimCalendarLatestDate, 'months') === 1;
  if (hasLatestApimData) {
    return null;
  }

  const todoNotificationAcknowledgements = getTodoNotificationAcknowledgements(store);
  const id = `${NOTIFICATION.UPDATE_BANK_DATA}_${moment(date).format('DD/MM/YYYY')}`;

  return {
    ...notificationDetails[NOTIFICATION.UPDATE_BANK_DATA],
    id,
    acknowledged: todoNotificationAcknowledgements.includes(id),
    date: date.toISOString(),
  };
};

export const getUpdateFundsAndTargetsNotification = (store: IStore): INotification | null => {
  const updateExpiredTargetsNotification = getUpdateExpiredTargetsNotification(store);
  const todoNotificationAcknowledgements = getTodoNotificationAcknowledgements(store);
  const updateFundsNotification = getUpdateFundsNotification(store);

  if (updateFundsNotification && updateExpiredTargetsNotification) {
    const earliestDate = moment
      .min(moment(updateFundsNotification.date), moment(updateExpiredTargetsNotification.date))
      .toDate();
    const id = `${NOTIFICATION.UPDATE_FUNDS_AND_TARGETS}_${moment(earliestDate).format(
      'DD/MM/YYYY'
    )}`;

    return {
      ...notificationDetails[NOTIFICATION.UPDATE_FUNDS_AND_TARGETS],
      id,
      acknowledged: todoNotificationAcknowledgements.includes(id),
      date: earliestDate.toISOString(),
    };
  }

  return updateFundsNotification || updateExpiredTargetsNotification;
};

export const getUpdateFundsNotification = (store: IStore): INotification | null => {
  const updateManualFundsNotification = getUpdateManualFundsNotification(store);
  const updateApimFundsNotification = getUpdateApimFundsNotification(store);

  if (!updateManualFundsNotification && !updateApimFundsNotification) {
    return null;
  }

  if (updateManualFundsNotification && updateApimFundsNotification) {
    if (updateManualFundsNotification.date < updateApimFundsNotification.date) {
      return updateManualFundsNotification;
    } else {
      return updateApimFundsNotification;
    }
  }

  return updateManualFundsNotification || updateApimFundsNotification;
};

export const getUpdateManualFundsNotification = (store: IStore): INotification | null => {
  const manualFundsLastUpdatedAt = getManualFundsLastUpdatedAt(store);
  if (!manualFundsLastUpdatedAt) {
    return null;
  }
  if (moment().diff(moment(manualFundsLastUpdatedAt), 'days') < INTERVAL_BEFORE_RETURN_FLOW) {
    return null;
  }

  const todoNotificationAcknowledgements = getTodoNotificationAcknowledgements(store);
  const nextDateToUpdateManualFunds = getReturnFlowDateFrom(manualFundsLastUpdatedAt);
  const id = `${NOTIFICATION.UPDATE_FUNDS_AND_TARGETS}_${moment(nextDateToUpdateManualFunds).format(
    'DD/MM/YYYY'
  )}`;

  return {
    ...notificationDetails[NOTIFICATION.UPDATE_FUNDS_AND_TARGETS],
    id,
    acknowledged: todoNotificationAcknowledgements.includes(id),
    date: nextDateToUpdateManualFunds.toISOString(),
  };
};

export const getUpdateApimFundsNotification = (store: IStore): INotification | null => {
  const apimLatestPullConsentAt = getApimLatestPullConsentAt(store);

  if (!apimLatestPullConsentAt) {
    return null;
  }

  if (moment().diff(moment(apimLatestPullConsentAt), 'days') < INTERVAL_BEFORE_RETURN_FLOW) {
    return null;
  }

  const totalApimCasaInSGD = getTotalApimCasasInSGD(store);
  const totalApimCashFixedDepositInSGD = getTotalApimCashFixedDepositInSGD(store);

  if (!totalApimCasaInSGD && !totalApimCashFixedDepositInSGD) {
    return null;
  }

  const todoNotificationAcknowledgements = getTodoNotificationAcknowledgements(store);
  const nextDateToUpdateApimFunds = getReturnFlowDateFrom(apimLatestPullConsentAt);
  const id = `${NOTIFICATION.UPDATE_FUNDS_AND_TARGETS}_${moment(nextDateToUpdateApimFunds).format(
    'DD/MM/YYYY'
  )}`;

  return {
    ...notificationDetails[NOTIFICATION.UPDATE_FUNDS_AND_TARGETS],
    id,
    acknowledged: todoNotificationAcknowledgements.includes(id),
    date: nextDateToUpdateApimFunds.toISOString(),
  };
};

export const getUpdateExpiredTargetsNotification = (store: IStore): INotification | null => {
  const oldestExpiredTargetDate = getOldestExpiredTargetDate(store);
  if (!oldestExpiredTargetDate) {
    return null;
  }

  const todoNotificationAcknowledgements = getTodoNotificationAcknowledgements(store);
  const id = `${NOTIFICATION.UPDATE_FUNDS_AND_TARGETS}_${moment(oldestExpiredTargetDate).format(
    'DD/MM/YYYY'
  )}`;

  return {
    ...notificationDetails[NOTIFICATION.UPDATE_FUNDS_AND_TARGETS],
    id,
    acknowledged: todoNotificationAcknowledgements.includes(id),
    date: oldestExpiredTargetDate.toISOString(),
  };
};

export const getTermAndConditionsRevisionNotification = (store: IStore): INotification | null => {
  const notification = getInbuiltNotifications(store).find(
    ({ id }) => id === NOTIFICATION.TNC_UPDATE
  );

  const completedOnboardingProfileQuestions = getHasCompletedOnboardingProfileQuestions(store);

  if (!completedOnboardingProfileQuestions) {
    return null;
  }

  return notification || null;
};

export const getGiveFeedbackNotification = (store: IStore): INotification | null => {
  const hasCompletedFeedbackForm = getHasCompletedFeedbackForm(store);
  const lastLoginDate = getProfileLastLogin(store);

  // if not return user or have completed feedback form
  if (lastLoginDate === null || hasCompletedFeedbackForm) {
    return null;
  }

  const id = `${NOTIFICATION.GIVE_FEEDBACK}_${moment(lastLoginDate).format('DD/MM/YYYY')}`;

  return {
    ...notificationDetails[NOTIFICATION.GIVE_FEEDBACK],
    id,
    acknowledged: hasCompletedFeedbackForm,
    date: lastLoginDate.toISOString(),
  };
};

export const getInvestmentScamsArticleNotification = (store: IStore): INotification | null => {
  const lastLoginDate = getProfileLastLogin(store);
  const completedOnboardingProfileQuestions = getHasCompletedOnboardingProfileQuestions(store);
  const todoNotificationAcknowledgements = getTodoNotificationAcknowledgements(store);

  const acknowledgedAntiScamArticle = todoNotificationAcknowledgements.find((notification) =>
    notification.includes(NOTIFICATION.READ_ANTI_SCAM_ARTICLE)
  );

  if (acknowledgedAntiScamArticle || !completedOnboardingProfileQuestions) {
    return null;
  }

  const notificationDate = lastLoginDate !== null ? lastLoginDate : new Date();
  const id = `${NOTIFICATION.READ_ANTI_SCAM_ARTICLE}_${moment(notificationDate).format(
    'DD/MM/YYYY'
  )}`;

  return {
    ...notificationDetails[NOTIFICATION.READ_ANTI_SCAM_ARTICLE],
    id,
    acknowledged: !!acknowledgedAntiScamArticle,
    date: notificationDate.toISOString(),
  };
};

export const getIncompleteProfileNotification = (store: IStore): INotification | null => {
  const lastLoginDate = getProfileLastLogin(store);
  const todoNotificationAcknowledgements = getTodoNotificationAcknowledgements(store);

  const acknowledgedIncompleteProfileNotification = todoNotificationAcknowledgements.find(
    (notification) => notification.includes(NOTIFICATION.INCOMPLETE_PROFILE)
  );
  const emailVerified = getEmailVerificationStatus(store) === EMAIL_VERIFICATION_STATUS.VERIFIED;

  if (acknowledgedIncompleteProfileNotification || emailVerified) {
    return null;
  }

  const notificationDate = lastLoginDate || new Date();
  const id = `${NOTIFICATION.INCOMPLETE_PROFILE}_${moment(notificationDate).format('DD/MM/YYYY')}`;

  return {
    ...notificationDetails[NOTIFICATION.INCOMPLETE_PROFILE],
    id,
    acknowledged: !!acknowledgedIncompleteProfileNotification,
    date: notificationDate.toISOString(),
  };
};

export const getDbsSystemUpdateNotification = (store: IStore): INotification | null => {
  const lastLoginDate = getProfileLastLogin(store);
  const apimLatestPullConsentAt = getApimLatestPullConsentAt(store);
  const todoNotificationAcknowledgements = getTodoNotificationAcknowledgements(store);

  const acknowledgedDbsSystemUpdateNotification = todoNotificationAcknowledgements.find(
    (notification) => notification.includes(NOTIFICATION.DBS_SYSTEM_UPDATE)
  );

  if (
    acknowledgedDbsSystemUpdateNotification ||
    apimLatestPullConsentAt === null ||
    // TODO: [FP-0000] To remove this notification once all user have pulled after the date below
    moment(apimLatestPullConsentAt).isAfter(new Date('2022-12-12T06:00:00.000Z'))
  ) {
    return null;
  }

  const notificationDate = lastLoginDate || new Date();
  const id = `${NOTIFICATION.DBS_SYSTEM_UPDATE}_${moment(notificationDate).format('DD/MM/YYYY')}`;
  return {
    ...notificationDetails[NOTIFICATION.DBS_SYSTEM_UPDATE],
    id,
    acknowledged: !!acknowledgedDbsSystemUpdateNotification,
    date: notificationDate.toISOString(),
  };
};

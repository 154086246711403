import React from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import {
  ValidationForm,
  ValidationContainer,
  required,
  ValidationButton,
  IButtonRenderParams,
} from 'components/Validations';
import { IGoalForRender } from 'constants/storeTypes';
import { getDateDiffInMonths } from 'utilities/dateUtilities';

import { AmountField } from '../AmountField/AmountField';
import { Button } from '../Button/Button';
import { Section } from '../SectionHeading/Section';

import { AmountLimitBanner } from './AmountLimitBanner';
import { DatePassedBanner } from './DatePassedBanner';
import { OverAllocatedBanner } from './OverAllocatedBanner';
import { RecommendedActionsToAchieveTargetBannerContainer } from './RecommendedActionsToAchieveTargetBanner';
import {
  StyledHeading,
  NameInputWrapper,
  AmountInputWrapper,
  DateFieldWrapper,
  PrimaryButtonGroup,
  SecondaryButtonGroup,
  SecondaryTargetButton,
  StyledAmountField,
  StyledDateField,
  StyledNameInput,
} from './sharedStyles';

interface IProps {
  goal: IGoalForRender;
  name: string;
  amount: number;
  date: Date;
  transitionStatus?: TransitionStatus;
  handleDeleteAction: () => void;
  saveToStore: () => void;
  updateDate: (date: Date) => void;
  updateName: (name: string) => void;
  updateAmount: (amount: number) => void;
  archivable?: boolean;
  thresholdAmount: number;
  setToMinimumMonthlySavingsAndCloseModal?: () => void;
  minimumMonthlySavings?: number;
  monthlySavings?: number;
  openPaidOutTargetModal: () => void;
}

export class EditGoalContent extends React.Component<IProps> {
  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.transitionStatus === 'entering' && this.props.transitionStatus === 'entered') {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  }

  public render() {
    const {
      goal,
      name,
      amount,
      date,
      handleDeleteAction,
      saveToStore,
      updateDate,
      updateName,
      updateAmount,
      archivable,
      thresholdAmount,
      setToMinimumMonthlySavingsAndCloseModal,
      minimumMonthlySavings,
      monthlySavings,
      openPaidOutTargetModal,
    } = this.props;

    return (
      <Section>
        <StyledHeading>Edit target</StyledHeading>
        {typeof monthlySavings !== 'undefined' &&
        typeof minimumMonthlySavings !== 'undefined' &&
        typeof setToMinimumMonthlySavingsAndCloseModal !== 'undefined' ? (
          <RecommendedActionsToAchieveTargetBannerContainer
            updateAmount={updateAmount}
            updateDate={updateDate}
            amount={amount}
            date={date}
            setToMinimumMonthlySavingsAndCloseModal={setToMinimumMonthlySavingsAndCloseModal}
            minimumMonthlySavings={minimumMonthlySavings}
            monthlySavings={monthlySavings}
            goalId={goal.id}
          />
        ) : null}
        <ValidationForm
          onSubmit={() => {
            const hasExpired = getDateDiffInMonths(date) < 0 && getDateDiffInMonths(goal.date) >= 0;

            if (!hasExpired || goal.paidOut) {
              saveToStore();
            } else {
              openPaidOutTargetModal();
            }
          }}
          disableAfterSubmit={true}
        >
          <NameInputWrapper>
            <ValidationContainer
              value={name}
              validations={[required]}
              render={(validationsParams) => (
                <StyledNameInput
                  inputRef={this.inputRef}
                  label="Name"
                  widthStyle="fluid"
                  errorMessage={validationsParams.errorMessage}
                  onBlur={validationsParams.onBlur}
                  onFocus={validationsParams.onFocus}
                  value={name}
                  wrapperRef={validationsParams.placerRef}
                  handleChange={updateName}
                />
              )}
            />
          </NameInputWrapper>
          <AmountInputWrapper>
            <StyledAmountField
              label="Amount"
              widthStyle="fluid"
              amount={amount}
              required={true}
              minAmount={0}
              minAmountInclusive={false}
              onInputChange={updateAmount}
              addonBefore={<AmountField.DollarSign />}
            />
          </AmountInputWrapper>
          <DateFieldWrapper>
            <StyledDateField
              label="Target due"
              date={date}
              onInputChange={updateDate}
              menuPlacement="top"
              earliestAllowedDate={archivable ? new Date(2019, 0) : undefined}
            />
          </DateFieldWrapper>
          {archivable &&
          getDateDiffInMonths(goal.date) >= 0 &&
          getDateDiffInMonths(date) < 0 &&
          !goal.paidOut ? (
            <DatePassedBanner />
          ) : goal.currentAmount > amount ? (
            <OverAllocatedBanner
              currentAllocatedAmount={goal.currentAmount}
              excessAmount={goal.currentAmount - amount}
            />
          ) : (
            amount > thresholdAmount && <AmountLimitBanner thresholdAmount={thresholdAmount} />
          )}
          <PrimaryButtonGroup>
            <ValidationButton
              render={(renderParams: IButtonRenderParams) => (
                <Button widthStyle="fluid" {...renderParams}>
                  Save
                </Button>
              )}
            />
          </PrimaryButtonGroup>
          <SecondaryButtonGroup>
            <SecondaryTargetButton
              variant="tertiary"
              onClick={openPaidOutTargetModal}
              type="button"
              widthStyle="fluid"
            >
              Complete target
            </SecondaryTargetButton>
            <SecondaryTargetButton
              variant="tertiary"
              onClick={handleDeleteAction}
              type="button"
              widthStyle="fluid"
            >
              Delete
            </SecondaryTargetButton>
          </SecondaryButtonGroup>
        </ValidationForm>
      </Section>
    );
  }
}

import { css } from 'styled-components';
import { ifNotProp, ifProp, switchProp } from 'styled-tools';

import { typography } from 'themes';

export const baseProperties = css`
  align-self: flex-start;
  display: flex;
  align-items: center;
  max-width: 100%;
  min-height: 2.5rem;
  cursor: ${ifProp('disabled', 'not-allowed', 'text')};

  & > *:not([aria-hidden='true']) + *:not([hidden]) {
    margin-left: 0.5rem;
  }
`;

export const sizeProperties = css`
  ${switchProp('fontSize', {
    medium: css`
      ${typography('body-m')}
    `,
    large: css`
      ${typography('body-l')}
    `,
    default: css`
      font-size: inherit;
    `,
  })};
`;

export const widthStyleProperties = css`
  ${ifProp(
    { widthStyle: 'fluid' },
    ifNotProp(
      'maxNumChars',
      css`
        width: 100%;
      `
    )
  )};
`;

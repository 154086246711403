import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { withTheme } from 'styled-components';

import { clearReduxData, setHasToken } from 'redux/dataStorage/actions';
import { getHasCompletedOnboardingProfileQuestions, getProfileName } from 'redux/profile/selectors';

import { captureLogout, captureFrequencyOfPDFDownloadsAfterLogout } from 'analytics';
import { applicationFlags } from 'applicationFlags';
import { Logo } from 'components/Common';
import { SessionContext } from 'components/Sessions/SessionContext';
import { navigationDetails } from 'constants/navigationConstants';
import { UAT_MOCK_NEW_USERS_KEY, UAT_USAGE_KEY } from 'constants/uatUsageConstants';
import {
  ONBOARDING,
  RESOURCES,
  MORE,
  PROFILE,
  NOTIFICATIONS,
  SETTINGS,
  INTERCEPT,
} from 'constants/urls';
import { getDynamicFeatureDecisions } from 'featureDecisions';
import { colorScale } from 'themes';
import { ITheme } from 'themes';
import { flatten } from 'utilities/objectUtilities';
import {
  getPDFDownloadsCount,
  handleSessionLogout,
  LOGOUT_STATUS_TYPES,
} from 'utilities/sessionUtilities';

import { NavigationBarComponents } from './NavigationBarComponents';

const {
  LogoWrapper,
  LinksWrapper,
  Link,
  NavLink,
  ExternalLink,
  VerticalDivider,
  HorizontalDivider,
  HamburgerMenu,
  TabletSearchBar,
  DesktopSearchBar,
  Dropdown,
  NotificationBell,
  SectionLabel,
  LogoutLink: Logout,
} = NavigationBarComponents;

const Circle = styled.span`
  display: inline-block;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: ${colorScale('primary', 30)};
`;

const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${colorScale('grey', 0)};
  font-weight: bold;
`;

const StyledLogoWrapper = styled(({ completedProfileQuestions, ...otherProps }) => (
  <LogoWrapper {...otherProps} />
))`
  cursor: ${({ completedProfileQuestions }) => (completedProfileQuestions ? 'cursor' : 'default')};
`;

type NestedNav = 'resources' | 'more' | 'profile';

const PostLoginNavigationBarComponent = ({ theme }: ITheme) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const sessionManager = useContext(SessionContext);

  const profileName = useSelector(getProfileName);
  const completedProfileQuestions = useSelector(getHasCompletedOnboardingProfileQuestions);
  const logoPath = completedProfileQuestions ? ONBOARDING.baseRoute : window.location.pathname;
  const isLoginInterceptFlow = Object.values(INTERCEPT.LOGIN).includes(location.pathname);

  const logout = () => {
    captureFrequencyOfPDFDownloadsAfterLogout(getPDFDownloadsCount());
    captureLogout();
    handleSessionLogout(LOGOUT_STATUS_TYPES.USER_INITIATED);
    dispatch(clearReduxData());
    dispatch(setHasToken(false));
    sessionManager.clearTimeouts();

    if (!applicationFlags.isProduction) {
      localStorage.removeItem(UAT_USAGE_KEY);
      localStorage.removeItem(UAT_MOCK_NEW_USERS_KEY);
    }
  };

  const navWithNestedItems = {
    resources: Object.values(flatten(RESOURCES)) as string[],
    more: [...Object.values(MORE), ...Object.values(flatten(SETTINGS))] as string[],
    profile: Object.values(PROFILE),
  };

  const isNestedPageActive = (tab: NestedNav) =>
    navWithNestedItems[tab].some((item: string) => location.pathname.includes(item));

  const LogoutLink = () => <Logout onClick={logout}>Log&nbsp;out</Logout>;

  const MobileTabletNavbar = () => (
    <>
      <StyledLogoWrapper to={logoPath} completedProfileQuestions={completedProfileQuestions}>
        <Logo />
      </StyledLogoWrapper>
      <LinksWrapper>
        <LogoutLink />
        {completedProfileQuestions && !isLoginInterceptFlow && (
          <>
            <Link to={NOTIFICATIONS.notifications}>
              <NotificationBell />
            </Link>
            <HamburgerMenu
              renderMenuItems={({ setIsMenuOpened }) => {
                const closeMenu = () => setIsMenuOpened(false);
                return (
                  <>
                    <TabletSearchBar closeMenu={closeMenu} />
                    <SectionLabel>Financial overview</SectionLabel>
                    <NavLink to={navigationDetails.DASHBOARD.urlPath} onClick={closeMenu}>
                      <span>{navigationDetails.DASHBOARD.navbarTitle}</span>
                    </NavLink>
                    <NavLink to={navigationDetails.TARGETS.urlPath} onClick={closeMenu}>
                      {navigationDetails.TARGETS.navbarTitle}
                    </NavLink>
                    <NavLink to={navigationDetails.PROFILE.urlPath} onClick={closeMenu}>
                      {navigationDetails.PROFILE.title}
                    </NavLink>
                    <SectionLabel>Resources by topics</SectionLabel>
                    <NavLink to={navigationDetails.SAVINGS.urlPath} onClick={closeMenu}>
                      {navigationDetails.SAVINGS.title}
                    </NavLink>
                    <NavLink to={navigationDetails.LOANS_AND_CREDIT.urlPath} onClick={closeMenu}>
                      {navigationDetails.LOANS_AND_CREDIT.title}
                    </NavLink>
                    <NavLink to={navigationDetails.INVESTMENTS.urlPath} onClick={closeMenu}>
                      {navigationDetails.INVESTMENTS.title}
                    </NavLink>
                    <NavLink to={navigationDetails.INSURANCE.urlPath} onClick={closeMenu}>
                      {navigationDetails.INSURANCE.title}
                    </NavLink>
                    <SectionLabel>Resources by milestones</SectionLabel>
                    <NavLink to={navigationDetails.HOUSING.urlPath} onClick={closeMenu}>
                      {navigationDetails.HOUSING.title}
                    </NavLink>
                    <NavLink to={navigationDetails.RETIREMENT.urlPath} onClick={closeMenu}>
                      {navigationDetails.RETIREMENT.title}
                    </NavLink>
                    <HorizontalDivider />
                    <NavLink to={navigationDetails.SETTINGS.urlPath} onClick={closeMenu}>
                      {navigationDetails.SETTINGS.title}
                    </NavLink>
                    <HorizontalDivider />
                    <NavLink to={navigationDetails.ABOUT_US.urlPath} onClick={closeMenu}>
                      {navigationDetails.ABOUT_US.title}
                    </NavLink>
                    <ExternalLink
                      href={navigationDetails.CONTACT_US.urlPath}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={closeMenu}
                    >
                      {navigationDetails.CONTACT_US.title}
                    </ExternalLink>
                  </>
                );
              }}
            />
          </>
        )}
      </LinksWrapper>
    </>
  );
  const DesktopNavbar = () => (
    <>
      <StyledLogoWrapper to={logoPath} completedProfileQuestions={completedProfileQuestions}>
        <Logo />
      </StyledLogoWrapper>
      {!getDynamicFeatureDecisions().showSGFinDexVanillaTables &&
      completedProfileQuestions &&
      !isLoginInterceptFlow ? (
        <LinksWrapper>
          <NavLink exact={true} to={navigationDetails.DASHBOARD.urlPath}>
            {navigationDetails.DASHBOARD.navbarTitle}
          </NavLink>
          <NavLink to={navigationDetails.TARGETS.urlPath}>
            {navigationDetails.TARGETS.navbarTitle}
          </NavLink>

          <Dropdown label="Resources" isChildActive={isNestedPageActive('resources')}>
            <SectionLabel>Resources by topics</SectionLabel>
            <NavLink to={navigationDetails.SAVINGS.urlPath}>
              {navigationDetails.SAVINGS.title}
            </NavLink>
            <NavLink to={navigationDetails.LOANS_AND_CREDIT.urlPath}>
              {navigationDetails.LOANS_AND_CREDIT.title}
            </NavLink>
            <NavLink to={navigationDetails.INVESTMENTS.urlPath}>
              {navigationDetails.INVESTMENTS.title}
            </NavLink>
            <NavLink to={navigationDetails.INSURANCE.urlPath}>
              {navigationDetails.INSURANCE.title}
            </NavLink>
            <SectionLabel>Resources by milestones</SectionLabel>
            <NavLink to={navigationDetails.HOUSING.urlPath}>
              {navigationDetails.HOUSING.title}
            </NavLink>
            <NavLink to={navigationDetails.RETIREMENT.urlPath}>
              {navigationDetails.RETIREMENT.title}
            </NavLink>
          </Dropdown>

          <Dropdown label="More" isChildActive={isNestedPageActive('more')}>
            <NavLink to={navigationDetails.SETTINGS.urlPath}>
              {navigationDetails.SETTINGS.title}
            </NavLink>
            <NavLink to={navigationDetails.ABOUT_US.urlPath}>
              {navigationDetails.ABOUT_US.title}
            </NavLink>
            <ExternalLink
              href={navigationDetails.CONTACT_US.urlPath}
              target="_blank"
              rel="noopener noreferrer"
            >
              {navigationDetails.CONTACT_US.title}
            </ExternalLink>
          </Dropdown>

          <VerticalDivider />
          <Link to={NOTIFICATIONS.notifications}>
            <NotificationBell />
          </Link>
          <DesktopSearchBar />
          {profileName ? (
            <Dropdown
              icon={
                <Circle>
                  <Name>{profileName.toLocaleUpperCase()[0]}</Name>
                </Circle>
              }
              isChildActive={isNestedPageActive('profile')}
            >
              <NavLink to={navigationDetails.PROFILE.urlPath}>
                {navigationDetails.PROFILE.title}
              </NavLink>
              <LogoutLink />
            </Dropdown>
          ) : (
            <LogoutLink />
          )}
        </LinksWrapper>
      ) : (
        <LogoutLink />
      )}
    </>
  );

  return (
    <MediaQuery maxWidth={theme.breakpoints.tabletMax}>
      {(matches) => (matches ? <MobileTabletNavbar /> : <DesktopNavbar />)}
    </MediaQuery>
  );
};

export const PostLoginNavigationBar = withTheme(PostLoginNavigationBarComponent);

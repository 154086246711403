import { uniqBy, flatten } from 'lodash';

import { getEmergencyFundRatioStatus } from './EmergencyFundRatio/getEmergencyFundRatioStatus';
import { getSortedResults } from './getSortedResults';
import { IEngineInputs } from './interfaces';
import { getCashflowIndicator } from './pillarIndicators/cashflowIndicator';
import { getProtectionIndicator } from './pillarIndicators/protectionIndicator';
import { getWealthIndicator } from './pillarIndicators/wealthIndicator';
import { getPortfolioDiversificationStatus } from './PortfolioDiversification/getPortfolioDiversificationStatus';
import { getSavingsRatioStatus } from './SavingsRatio/getSavingsRatioStatus';
import { getTotalDebtServicingRatioStatus } from './TotalDebtServicingRatio/getTotalDebtServicingRatioStatus';

export const getFinancialStatus = ({
  completedRecommendedActionKeys,
  ...inputs
}: IEngineInputs) => {
  const savingsRatioStatus = getSavingsRatioStatus(inputs);
  const totalDebtServicingRatioStatus = getTotalDebtServicingRatioStatus(inputs);
  const portfolioDiversificationStatus = getPortfolioDiversificationStatus(inputs);
  const emergencyFundRatioStatus = getEmergencyFundRatioStatus(inputs);

  const sortedResults = getSortedResults([
    savingsRatioStatus,
    totalDebtServicingRatioStatus,
    portfolioDiversificationStatus,
    emergencyFundRatioStatus,
  ]);

  const uniqueRecommendedActions = uniqBy(
    flatten(sortedResults.map(({ recommendedActions }) => recommendedActions)),
    (cta) => cta.key
  );

  const incompleteRecommendedActions = uniqueRecommendedActions.filter(
    ({ key }) => !completedRecommendedActionKeys.includes(key)
  );

  return {
    savingsRatioStatus,
    totalDebtServicingRatioStatus,
    portfolioDiversificationStatus,
    emergencyFundRatioStatus,
    incompleteRecommendedActions,
    cashflowIndicator: getCashflowIndicator(
      savingsRatioStatus.indicatorStatus,
      totalDebtServicingRatioStatus.indicatorStatus
    ),
    wealthIndicator: getWealthIndicator(portfolioDiversificationStatus.indicatorStatus),
    protectionIndicator: getProtectionIndicator(emergencyFundRatioStatus.indicatorStatus),
  };
};

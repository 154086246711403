import { UAT_USAGE_OPTIONS } from 'constants/enums';

export const UAT_USAGE_DROPDOWN_OPTIONS = [
  { label: 'SGFinDex Vanilla Tables', value: UAT_USAGE_OPTIONS.SGFINDEX_VANILLA_TABLES },
  { label: 'Mock Singpass', value: UAT_USAGE_OPTIONS.MOCK_SINGPASS },
  { label: 'Actual Singpass', value: UAT_USAGE_OPTIONS.ACTUAL_SINGPASS },
];

export const UAT_USAGE_KEY = 'uat-usage';
export const UAT_MOCK_NEW_USERS_KEY = 'uat-mock-new-users';

import React from 'react';
import styled, { css } from 'styled-components';

import { minWidth } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';

import { Dot } from './Bar';
import { BoxWrapper, Box, BoxText, BoxAmount } from './Box';
import { BulletWrapper, BulletText, BulletAmount } from './Bullet';
import { StyledTwinBars } from './TwinBars';

const GraphWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
  justify-content: center;
  align-items: center;
`;

const StyledDot = styled(Dot)`
  transform: translateY(4px);
`;
interface IProps {
  leftName: string;
  rightName: string;
  leftAmount: number;
  rightAmount: number;
  className?: string;
  variant: 'bullet' | 'box' | 'none';
}

export const TwinBarGraph: React.FC<IProps> = ({
  leftName,
  rightName,
  leftAmount,
  rightAmount,
  className,
  variant,
}) => {
  return (
    <GraphWrapper className={className}>
      <Auxillaries
        name={leftName}
        amount={leftAmount}
        variant={variant}
        color="main"
        alignment="end"
      />
      <StyledTwinBars leftAmount={leftAmount} rightAmount={rightAmount} barWidths={[15, 30]} />
      <Auxillaries
        name={rightName}
        amount={rightAmount}
        variant={variant}
        color="sub"
        alignment="start"
      />
    </GraphWrapper>
  );
};

interface IAuxillariesProps {
  name: string;
  amount: number;
  variant: 'bullet' | 'box' | 'none';
  color: 'main' | 'sub';
  alignment: 'start' | 'end';
}

const Auxillaries: React.FC<IAuxillariesProps> = ({ name, amount, variant, color, alignment }) =>
  variant === 'box' ? (
    <BoxWrapper alignment={alignment}>
      <Box variant={color}>
        <BoxText>{name}</BoxText>
        <BoxAmount>{formatCurrency(amount)}</BoxAmount>
      </Box>
    </BoxWrapper>
  ) : variant === 'bullet' ? (
    <BulletWrapper alignment={alignment}>
      <StyledDot type={color} />
      <BulletText>{name}</BulletText>
      <BulletAmount>{formatCurrency(amount)}</BulletAmount>
    </BulletWrapper>
  ) : null;

export const StyledTwinBarGraph = styled(TwinBarGraph)`
  grid-gap: 10px;
  ${minWidth(
    'desktop',
    css`
      grid-gap: 16px;
    `
  )}
`;

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { colorScale, typeScale } from 'themes';

import { Caret } from './Caret';
import { INativeDropdownProps } from './Interfaces';

const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  text-align: left;
  display: flex;
  min-height: 2.5rem;
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${colorScale('grey', 50)};
  border-radius: 0;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      border-color: ${colorScale('grey', 20)};
      color: ${colorScale('grey', 20)};
    `,
    css`
      &:focus,
      &:hover {
        border-color: ${colorScale('primary', 50)};
        z-index: 1;
      }
    `
  )};
`;

const StyledSelect = styled.select`
  position: absolute;
  width: 100%;
  background-color: transparent;
  color: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  appearance: none;
  outline: none;
  font-size: ${typeScale(0)};
`;

/** This is to give a text-overflow: ellipsis on the dropdown since selects cannot have that property */
const SelectTextContainer = styled.div<{ disabled?: boolean; isShowingPlaceholder: boolean }>`
  background-color: transparent;
  color: ${colorScale('grey', 90)};
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin-right: 0.6rem;
  flex-shrink: 0;

  ${ifProp(
    'isShowingPlaceholder',
    css`
      color: ${colorScale('grey', 50)};
    `
  )};

  ${ifProp(
    'disabled',
    css`
      color: ${colorScale('grey', 20)};
    `
  )};
`;

export class NativeDropdown extends React.Component<INativeDropdownProps> {
  constructor(props: INativeDropdownProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  public handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const chosenOption = this.props.options.find(
      (option) => option.value.toString() === event.target.value
    );

    if (this.props.onChange && chosenOption) {
      this.props.onChange(chosenOption);
    }
  }

  public render() {
    const { options, selectedOption, placeholder, disabled, id } = this.props;

    return (
      <Wrapper disabled={disabled}>
        <SelectTextContainer
          disabled={disabled}
          isShowingPlaceholder={Boolean(
            (selectedOption && selectedOption.label) === undefined && placeholder
          )}
        >
          {(selectedOption && selectedOption.label) === undefined
            ? placeholder
            : selectedOption && selectedOption.label}
        </SelectTextContainer>
        <StyledSelect
          id={id}
          disabled={disabled}
          onChange={this.handleChange}
          value={
            (selectedOption && selectedOption.value) === undefined
              ? placeholder
              : selectedOption && selectedOption.value
          }
        >
          {options &&
            options.map((option) => (
              <option key={option.value} value={option.value} disabled={option.isDisabled}>
                {option.label}
              </option>
            ))}
        </StyledSelect>
        <Caret disabled={disabled} />
      </Wrapper>
    );
  }
}

import { PLAN_TYPE, PLAN_COLOR, PAYMENT_METHOD } from 'constants/enums';
import { IHousingPlan } from 'constants/storeTypes';

import {
  initialCustomizedDraftHousingPlan,
  initialCustomizedHousingOutputs,
} from '../CustomizedPlan/constants';
import { IHousingOutputs } from '../interfaces';

export const ALLOWED_DECIMAL_PLACES = 2;

export const initialRecommendedDraftHousingPlan: IHousingPlan = {
  ...initialCustomizedDraftHousingPlan,
  planColor: PLAN_COLOR.PURPLE,
  planName: 'Recommended plan',
  interestRate: 2.6,
  loanPeriod: 25,
  planType: PLAN_TYPE.DEFAULT,
  enhancedCpfHousingGrant: 8000000,
  estimatedFlatBudget: 8000000,
  mortgageInsurancePremiums: {
    amount: 17920,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  buyerStampDuty: {
    amount: 80000,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  conveyancingFee: {
    amount: 6484.200000000001,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  surveyFee: {
    amount: 16050,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  stampDutyDeedFee: {
    amount: 0,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  leaseInEscrowFee: {
    amount: 3830,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
  mortgageInEscrowFee: {
    amount: 3830,
    paymentMethod: PAYMENT_METHOD.CPF,
  },
};

export const initialRecommendedHousingOutputs: IHousingOutputs = {
  ...initialCustomizedHousingOutputs,
  minimumPayment: 1600000, // 80_000_00 * 0.2
  paymentInCpf: 0,
  totalAmountLeftInCpfOA: 0,
  estimatedLoanAmount: 0,
  monthlyMortgageInstalment: 0,
  mortgageServicingRatio: 0,
  totalLoanPayment: NaN,
  totalInterest: NaN,
  percentageOfTotalPayment: NaN,
  applicationFee: 1000,
  upfrontPaymentInCpf: 8000000,
  additionalBuyerStampDuty: 0,
  totalBookingOfFlatPayments: 50000,
  totalCollectionOfKeysPayments: 3823710,
  totalSigningOfAgreementForLeasePayments: 8086484.2,
  totalOneTimePayments: 11961194.2,
  totalOneTimePaymentsInCash: 3851000,
  totalOneTimePaymentsInCpf: 8110194.2,
  totalMonthlyPayment: 3100,
  totalMonthlyPaymentInCash: 3100,
  totalMonthlyPaymentInCpf: 0,
  fireInsurancePremium: 271,
  totalAnnualPayment: 18191,
  totalAnnualPaymentInCash: 271,
  totalAnnualPaymentInCpf: 17920,
};

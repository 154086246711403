import React from 'react';
import { Switch, Redirect, Route } from 'react-router';

import { RESOURCES } from 'constants/urls';

import { AssessCurrentSituationArticle } from './Articles/AssessCurrentSituationArticle/AssessCurrentSituationArticle';
import { CloseSavingsGapArticle } from './Articles/CloseSavingsGapArticle/CloseSavingsGapArticle';
import { DefineRetirementGoalsArticle } from './Articles/DefineRetirementGoalsArticle/DefineRetirementGoalsArticle';
import { RetirementResourcesPage } from './RetirementResourcesPage/RetirementResourcesPage';

const RetirementResourcesRouter = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path={RESOURCES.RETIREMENT.baseRoute}
        component={RetirementResourcesPage}
      />
      <Route
        exact={true}
        path={RESOURCES.RETIREMENT.defineRetirementGoals}
        component={DefineRetirementGoalsArticle}
      />
      <Route
        exact={true}
        path={RESOURCES.RETIREMENT.assessCurrentSituation}
        component={AssessCurrentSituationArticle}
      />
      <Route
        exact={true}
        path={RESOURCES.RETIREMENT.closeSavingsGap}
        component={CloseSavingsGapArticle}
      />

      <Redirect to={RESOURCES.RETIREMENT.baseRoute} />
    </Switch>
  );
};

export default RetirementResourcesRouter;

import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { getHasToken } from 'redux/dataStorage/selectors';
import { getHasCompletedOnboardingProfileQuestions } from 'redux/profile/selectors';

import { Section, Container, PageHeading, Heading, RoutedButton } from 'components/Common';
import { INTERCEPT } from 'constants/urls';
import { typeScale, shadowScale, maxWidth, typography, colorScale } from 'themes';
import { getReturnToOnboardingRoute } from 'utilities/sessionUtilities';

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionWrapper = styled(Section)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

export const StyledContainer = styled(Container)`
  position: static;
  z-index: 1;
  margin: 24px 0;
  max-width: 768px;
`;

const StyledRoutedButton = styled(RoutedButton)`
  position: sticky;
  bottom: 32px;
  margin-top: 32px;
  align-self: center;
  box-shadow: ${shadowScale(3)};
  z-index: 1;
`;

export const StyledHeading = styled(PageHeading)`
  margin-bottom: 16px;
`;

export const StyledSubHeading = styled(Heading)`
  font-size: ${typeScale(2)};
  margin-top: 24px;
`;

interface IProps {
  children: React.ReactNode;
}

export const PageWrapper = ({
  children,
  ...otherProps
}: IProps & React.HTMLAttributes<HTMLDivElement>) => {
  const hasToken = useSelector(getHasToken);
  const hasCompletedOnboardingProfileQuestions = useSelector(
    getHasCompletedOnboardingProfileQuestions
  );
  const returnRoute = getReturnToOnboardingRoute();
  const isNOCFlow = document.referrer.includes(INTERCEPT.LOGIN.nocInterceptOnLogin);

  return (
    <FlexWrapper>
      <SectionWrapper>
        <StyledContainer {...otherProps}>
          {children}
          {hasToken && !isNOCFlow && !hasCompletedOnboardingProfileQuestions && (
            <StyledRoutedButton to={returnRoute} widthStyle="elastic">
              Return to onboarding
            </StyledRoutedButton>
          )}
        </StyledContainer>
      </SectionWrapper>
    </FlexWrapper>
  );
};

export const List = styled.ol`
  counter-reset: nested;
  counter-increment: main;
  padding-left: 0;
`;

export const SubList = styled.ol`
  counter-reset: nested;
  margin-top: 16px;
  padding-left: 0;
`;

export const Item = styled.li`
  list-style: none;
  margin-bottom: 16px;
  display: flex;

  &:before {
    content: counter(main) '.' counters(nested, '.') '. ';
    counter-increment: nested;
    margin-right: 8px;
  }
`;

export const StyledDiv = styled.div`
  margin: 0;
  line-height: normal;
`;

export const CounterWrapper = styled(Section)`
  counter-reset: main;
`;

export const ListItem: React.FC<{ children: React.ReactNode } & HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  return (
    <Item>
      <StyledDiv {...rest}>{children}</StyledDiv>
    </Item>
  );
};

export const ContentWrapper = styled(Container)`
  ${typography('body-m')}
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 auto;
  padding: 12px;
  color: ${colorScale('primary', 50)};

  ${maxWidth(
    'tablet',
    css`
      padding: 12px 16px;
    `
  )}
`;

export const MessageContainer = styled.div`
  width: 100%;
`;

import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';
import { switchProp } from 'styled-tools';

import { colorScale, typography } from 'themes';

// prettier-ignore
export const BoxWrapper = styled.div<{ alignment: 'start' | 'end' }>`
  flex: 1 1 0px;
  display: flex;
  justify-content: flex-${prop('alignment')};
`;

export const Box = styled.div<{ variant: 'main' | 'sub' }>`
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: bold;
  p:first-child {
    margin: 0;
  }
  p:last-child {
    margin: 0;
  }
  height: 100%;

  ${switchProp('variant', {
    main: css`
      background: ${colorScale('primary', 20)};
      color: ${colorScale('primary', 50)};
    `,
    sub: css`
      background: ${colorScale('secondary', 20)};
      color: ${colorScale('secondary', 50)};
    `,
  })}
`;

export const BoxText = styled.p`
  ${typography('body-s-bold')}
`;

export const BoxAmount = styled.p`
  ${typography('body-m-bold')}
`;

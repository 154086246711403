import { getSeededRandomOrderGenerator } from 'utilities/mathUtilities';

import { isUnallowedPairing } from './isUnallowedPairing';

/**
This algorithm does a seeded random depth-first search with constraints.

Considerations for the algorithm:
- There are certain pairs of colours that cannot be adjacent to each other
- Charts should have random orderings but maintain those orderings no matter the data
- Colours should be spread out as much as possible
- Algorithm should be able to handle impossible constraints
*/
export const getColorIndexes = (
  numElements: number,
  colorArrayLength: number,
  unallowedAdjacentColors: { [key: string]: string[] },
  seed = 'some-random-seed-value'
): number[] => {
  const getRandomOrder = getSeededRandomOrderGenerator<number>(seed);

  const stack: {
    colorOrder: number[];
    highPriorityIndexes: number[];
    lowPriorityIndexes: number[];
  }[] = [
    {
      colorOrder: [],
      highPriorityIndexes: Array(colorArrayLength)
        .fill(null)
        .map((_, i) => i),
      lowPriorityIndexes: [],
    },
  ];

  while (stack.length > 0) {
    const node = stack.pop() || { colorOrder: [], highPriorityIndexes: [], lowPriorityIndexes: [] };
    const colorOrder = node.colorOrder;

    if (colorOrder.length === numElements) {
      return colorOrder;
    }

    const nextColorIndexesToExpand = [
      ...getRandomOrder.next().value(node.lowPriorityIndexes),
      ...getRandomOrder.next().value(node.highPriorityIndexes),
    ];

    nextColorIndexesToExpand.forEach((index) => {
      if (
        !isUnallowedPairing(index, colorOrder[colorOrder.length - 1], unallowedAdjacentColors) &&
        !(
          colorOrder.length === numElements - 1 &&
          isUnallowedPairing(index, colorOrder[0], unallowedAdjacentColors)
        )
      ) {
        let highPriorityIndexes = node.highPriorityIndexes.filter((ele) => ele !== index);
        let lowPriorityIndexes = [...node.lowPriorityIndexes, index];

        if (highPriorityIndexes.length === 0) {
          const halfwayPointIndex = Math.ceil(lowPriorityIndexes.length / 2);
          highPriorityIndexes = lowPriorityIndexes.slice(0, halfwayPointIndex);
          lowPriorityIndexes = lowPriorityIndexes.slice(halfwayPointIndex);
        }

        stack.push({
          colorOrder: [...colorOrder, index],
          highPriorityIndexes,
          lowPriorityIndexes,
        });
      }
    });
  }

  return Array(numElements)
    .fill(null)
    .map((_, i) => i % colorArrayLength);
};

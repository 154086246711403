import { ReactComponent as Add } from './add.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowSwitch } from './arrow-switch.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as AutoTransfer } from './auto-transfer.svg';
import { ReactComponent as BarChart } from './bar-chart.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as CarouselLeft } from './carousel-left.svg';
import { ReactComponent as CarouselRight } from './carousel-right.svg';
import { ReactComponent as CautionRound } from './caution-round.svg';
import { ReactComponent as Caution } from './caution.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Dash } from './dash.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as DotsHorizontal } from './dots-horizontal.svg';
import { ReactComponent as DotsVertical } from './dots-vertical.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Duplicate } from './duplicate.svg';
import { ReactComponent as EyeClosed } from './eye-closed.svg';
import { ReactComponent as EyeOpened } from './eye-opened.svg';
import { ReactComponent as FaceHappy } from './face-happy.svg';
import { ReactComponent as FaceSad } from './face-sad.svg';
import { ReactComponent as Flag } from './flag.svg';
import { ReactComponent as Gear } from './gear.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as LightBulb } from './lightbulb.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as MagnifyingGlass } from './magnifying-glass.svg';
import { ReactComponent as MoneyReminder } from './money-reminder.svg';
import { ReactComponent as Open } from './open.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as Person } from './person.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Reload } from './reload.svg';
import { ReactComponent as ReviewProgress } from './review-progress.svg';
import { ReactComponent as SGLion } from './sg-lion.svg';
import { ReactComponent as Sliders } from './sliders.svg';
import { ReactComponent as Sparkle } from './sparkle.svg';
import { ReactComponent as SpeechBubbles } from './speech-bubbles.svg';
import { ReactComponent as StarOutline } from './star-outline.svg';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as Suitability } from './suitability.svg';
import { ReactComponent as ThumbsDown } from './thumbs-down.svg';
import { ReactComponent as ThumbsUp } from './thumbs-up.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as TickCircle } from './tickCircle.svg';
import { ReactComponent as Time } from './time.svg';
import { ReactComponent as Trophy } from './trophy.svg';
import { ReactComponent as Undo } from './undo.svg';
import { ReactComponent as Wallet } from './wallet.svg';

Add.displayName = 'icon';
ArrowDown.displayName = 'icon';
ArrowLeft.displayName = 'icon';
ArrowRight.displayName = 'icon';
ArrowUp.displayName = 'icon';
AutoTransfer.displayName = 'icon';
Bell.displayName = 'icon';
BarChart.displayName = 'icon';
Calendar.displayName = 'icon';
CarouselLeft.displayName = 'icon';
CarouselRight.displayName = 'icon';
Caution.displayName = 'icon';
CautionRound.displayName = 'icon';
Cross.displayName = 'icon';
Dash.displayName = 'icon';
Delete.displayName = 'icon';
DotsHorizontal.displayName = 'icon';
DotsVertical.displayName = 'icon';
Download.displayName = 'icon';
Duplicate.displayName = 'icon';
EyeClosed.displayName = 'icon';
EyeOpened.displayName = 'icon';
FaceHappy.displayName = 'icon';
FaceSad.displayName = 'icon';
Flag.displayName = 'icon';
Gear.displayName = 'icon';
Home.displayName = 'icon';
Info.displayName = 'icon';
LightBulb.displayName = 'icon';
Lock.displayName = 'icon';
MagnifyingGlass.displayName = 'icon';
MoneyReminder.displayName = 'icon';
Open.displayName = 'open';
Pencil.displayName = 'icon';
Person.displayName = 'icon';
Plus.displayName = 'icon';
Reload.displayName = 'icon';
ReviewProgress.displayName = 'icon';
SGLion.displayName = 'icon';
Sliders.displayName = 'icon';
Suitability.displayName = 'icon';
Sparkle.displayName = 'icon';
SpeechBubbles.displayName = 'icon';
Star.displayName = 'icon';
StarOutline.displayName = 'icon';
ThumbsDown.displayName = 'icon';
ThumbsUp.displayName = 'icon';
Tick.displayName = 'icon';
TickCircle.displayName = 'icon';
Time.displayName = 'icon';
Trophy.displayName = 'icon';
Undo.displayName = 'icon';
Wallet.displayName = 'icon';

export {
  Add,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowSwitch,
  ArrowUp,
  AutoTransfer,
  BarChart,
  Bell,
  Calendar,
  CarouselLeft,
  CarouselRight,
  Caution,
  CautionRound,
  Cross,
  Dash,
  Delete,
  DotsHorizontal,
  DotsVertical,
  Download,
  Duplicate,
  EyeClosed,
  EyeOpened,
  FaceHappy,
  FaceSad,
  Flag,
  Gear,
  Home,
  Info,
  LightBulb,
  Lock,
  MagnifyingGlass,
  MoneyReminder,
  Open,
  Pencil,
  Person,
  Plus,
  Reload,
  ReviewProgress,
  SGLion,
  Sliders,
  Suitability,
  Sparkle,
  SpeechBubbles,
  Star,
  StarOutline,
  ThumbsDown,
  ThumbsUp,
  Tick,
  TickCircle,
  Time,
  Trophy,
  Undo,
  Wallet,
};

export const mockFxRates = {
  data: [
    {
      id: 'eefb0a0a-3754-4294-8154-366366ea79f9',
      currencyCode: 'ADF',
      exchangeRate: '0.246825',
      retrievedDate: '2020-07-31T00:00:00.000Z',
    },
    {
      id: 'dddc123f-0fc5-40d9-b473-5584975bd159',
      currencyCode: 'ADP',
      exchangeRate: '0.009765',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '6ad9dbf9-ba46-4b15-8c76-bf53e9b65a5a',
      currencyCode: 'AED',
      exchangeRate: '0.379492',
      retrievedDate: '2020-06-30T00:00:00.000Z',
    },
    {
      id: '8f1e441f-f7d0-45eb-8eea-22360868650e',
      currencyCode: 'AFA',
      exchangeRate: '0.017679',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7a9b351a-e63d-4dc7-9381-a2bd1b148655',
      currencyCode: 'AFN',
      exchangeRate: '0.017679',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '45a85663-8d5b-4a5f-ab37-f50e8cebfef0',
      currencyCode: 'ALL',
      exchangeRate: '0.013074',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '86c67796-9aba-494e-b549-cb84a3a733e5',
      currencyCode: 'AMD',
      exchangeRate: '0.002806',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '9dfa88cb-6cce-4456-8b08-b11b53858a2a',
      currencyCode: 'ANG',
      exchangeRate: '0.759187',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '14ee9ce5-c538-48db-bcc9-d79485125db6',
      currencyCode: 'AOA',
      exchangeRate: '0.002256',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7a5f20d9-5a12-41cc-9452-284f228cc7e8',
      currencyCode: 'AON',
      exchangeRate: '0.002256',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'b04a1268-c631-4c39-a971-de6b0ff4fe84',
      currencyCode: 'ARS',
      exchangeRate: '0.018321',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '140b1e65-1886-4aee-9139-fb1322f07888',
      currencyCode: 'ATS',
      exchangeRate: '0.118076',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '54d72fd9-a2f7-46c0-b9c5-1ce0321223f3',
      currencyCode: 'AUD',
      exchangeRate: '1.003460',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '57604d38-fd46-4f10-b8b3-0d387c7a6475',
      currencyCode: 'AWG',
      exchangeRate: '0.754993',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '8f0aa537-f9d2-4e92-91f0-14ebb78a2014',
      currencyCode: 'AZM',
      exchangeRate: '0.000160',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '8e666828-9e4a-472e-84cc-67b58b7db71b',
      currencyCode: 'AZN',
      exchangeRate: '0.799379',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '36c7f0b7-f0c0-4958-9f9f-58f6c795d968',
      currencyCode: 'BAM',
      exchangeRate: '0.830733',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1f535dfb-0cf6-4b71-8f36-23091ad1f95c',
      currencyCode: 'BBD',
      exchangeRate: '0.679472',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '4a8d9b97-829d-4783-964b-96a099c1628c',
      currencyCode: 'BDT',
      exchangeRate: '0.016043',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '8bf3930b-7f78-4b6b-8518-7cc2d3b869ac',
      currencyCode: 'BEF',
      exchangeRate: '0.040277',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '8fb993f5-c0e4-4cff-8ef0-323320364a31',
      currencyCode: 'BGL',
      exchangeRate: '0.830733',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7db87b77-454a-4ccf-864f-bf63abdf2606',
      currencyCode: 'BGN',
      exchangeRate: '0.830733',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'd7861c58-0b41-4bbb-81df-0ede9674b9f4',
      currencyCode: 'BHD',
      exchangeRate: '3.604900',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'b6f01875-a8d7-403a-b1f9-15ac4f43e920',
      currencyCode: 'BIF',
      exchangeRate: '0.000705',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '65041ed7-d28b-473e-b860-0192c3f0ed96',
      currencyCode: 'BMD',
      exchangeRate: '1.358940',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '04731968-452c-4d8c-9659-2ec111d9acd6',
      currencyCode: 'BND',
      exchangeRate: '1.000000',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'edfb14a8-b540-4b90-8c2b-b8f31f7d6f9a',
      currencyCode: 'BOB',
      exchangeRate: '0.197198',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '3234b3c0-3b8b-4e46-988a-a9740bbdaea8',
      currencyCode: 'BRL',
      exchangeRate: '0.249882',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'ff1c00ab-12f8-4d08-a0bb-4edb795a8237',
      currencyCode: 'BSD',
      exchangeRate: '1.358940',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'ce736a29-e532-4210-8c48-788db386e004',
      currencyCode: 'BTN',
      exchangeRate: '0.018613',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'dc43b714-e69f-4b02-bd09-44ec03d90da9',
      currencyCode: 'BWP',
      exchangeRate: '0.118252',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'ce52d223-84b9-4dcf-b612-c8a1c3b78caa',
      currencyCode: 'BYN',
      exchangeRate: '0.513336',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'f456db41-fd9e-41f8-b556-80459138de38',
      currencyCode: 'BYR',
      exchangeRate: '0.000051',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '9c64ae8c-c2fd-4913-935a-14fd63ec455f',
      currencyCode: 'BZD',
      exchangeRate: '0.675188',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'a7448ce6-afbc-46a6-97cb-e5703c872f11',
      currencyCode: 'CAD',
      exchangeRate: '1.042570',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '5ba9cbc1-df76-440b-8cb8-c5f82a3cec71',
      currencyCode: 'CDF',
      exchangeRate: '0.000697',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'a6f31aed-961c-4808-8282-8598bea92f19',
      currencyCode: 'CFP',
      exchangeRate: '0.013616',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '055356ec-43cd-4d9a-a658-4bc4285da755',
      currencyCode: 'CHF',
      exchangeRate: '1.500940',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7af019f3-0cff-4e69-9c6e-d3981292b364',
      currencyCode: 'CLP',
      exchangeRate: '0.001756',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7d9063d9-8992-461c-b155-aef1ac58d23c',
      currencyCode: 'CNH',
      exchangeRate: '0.198977',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'c6373a77-8c32-4795-a4b6-e9d48c2360f8',
      currencyCode: 'CNY',
      exchangeRate: '0.199058',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '5eea24cd-be26-423a-831e-6d16490d86ef',
      currencyCode: 'COP',
      exchangeRate: '0.000365',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7317998c-a260-4f52-88a4-232b8676a5b0',
      currencyCode: 'CRC',
      exchangeRate: '0.002286',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '5b3e15b3-211d-47bb-8ecc-aee92061cf97',
      currencyCode: 'CSD',
      exchangeRate: '0.013818',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '5179c218-4299-4019-afd3-13fe3e7d297d',
      currencyCode: 'CSK',
      exchangeRate: '0.061942',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '45c745d8-2dd8-4756-99f8-311858c75190',
      currencyCode: 'CUC',
      exchangeRate: '1.358940',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'a4807257-e6b0-4f0b-a047-6fb20436e070',
      currencyCode: 'CUP',
      exchangeRate: '0.054358',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '6a1c3d62-44e2-49f4-b83a-babfc1faa262',
      currencyCode: 'CVE',
      exchangeRate: '0.014735',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '9ebe705c-de6b-450c-92e3-2ca437e39a2c',
      currencyCode: 'CYP',
      exchangeRate: '2.776090',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '325774a8-90ee-41c0-975d-ed6457d031a4',
      currencyCode: 'CZK',
      exchangeRate: '0.061942',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'c54dcd5a-9997-45d0-a067-39bd00d952ae',
      currencyCode: 'DEM',
      exchangeRate: '0.830733',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '0f45cd41-c7e6-4723-8833-28fb97cab43a',
      currencyCode: 'DJF',
      exchangeRate: '0.007647',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '0e8021fe-e1ac-4994-bb8f-975086350e95',
      currencyCode: 'DKK',
      exchangeRate: '0.218276',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '6993113d-9d66-4017-95b5-6807c27028ab',
      currencyCode: 'DOP',
      exchangeRate: '0.023289',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'e7f3ce0d-3eab-45cb-83a9-7c8ce50b80d6',
      currencyCode: 'DZD',
      exchangeRate: '0.010631',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '11e87ae5-ef40-4dcc-9f45-e42709626213',
      currencyCode: 'ECS',
      exchangeRate: '0.000055',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '99228138-202a-421e-aa2d-f63eb848c469',
      currencyCode: 'EEK',
      exchangeRate: '0.103842',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '0ca4fcb8-3e44-4ae0-8ffa-178462a5c4f8',
      currencyCode: 'EGP',
      exchangeRate: '0.085653',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2f40ad36-352d-458f-ba40-be2ed7a89de4',
      currencyCode: 'ERN',
      exchangeRate: '0.090596',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'a5dd3dc7-5df9-43bd-97a1-9cbc5614fc1f',
      currencyCode: 'ESP',
      exchangeRate: '0.009765',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '06af7cf9-7c0d-4bdc-8de4-7738bee2b290',
      currencyCode: 'ETB',
      exchangeRate: '0.037529',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'cef757ec-79bc-4b47-a511-a95bb220b82f',
      currencyCode: 'EUR',
      exchangeRate: '1.624770',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '12898b39-c8c4-4eae-998c-7fb5d322f05e',
      currencyCode: 'FIM',
      exchangeRate: '0.273268',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '29924d17-db17-40e7-84d6-f4f0c886ebfc',
      currencyCode: 'FJD',
      exchangeRate: '0.646734',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1e4a24eb-4f4b-485a-ba04-4c723312b107',
      currencyCode: 'FKP',
      exchangeRate: '1.822350',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '91465548-39bd-4c34-82ea-242ccc5737a6',
      currencyCode: 'FRF',
      exchangeRate: '0.247695',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'ba473537-3445-4855-8930-cfa83aec54f4',
      currencyCode: 'GBP',
      exchangeRate: '1.822350',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '0b2b4aaa-bc65-42f0-9d97-4869a42225b4',
      currencyCode: 'GEL',
      exchangeRate: '0.439830',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '707ff435-951f-46b9-8e60-d52c89e996f9',
      currencyCode: 'GHC',
      exchangeRate: '0.000024',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '322f8646-4aa4-44b5-8285-3196d2e4464b',
      currencyCode: 'GHS',
      exchangeRate: '0.235522',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '459e3704-8f67-4325-bebd-018eb9a343b9',
      currencyCode: 'GIP',
      exchangeRate: '1.822350',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '08253eec-9c12-4025-94c1-ad4779f191e6',
      currencyCode: 'GMD',
      exchangeRate: '0.026236',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '3cf4c4b0-f782-4ef5-a50d-dfbc0e25c767',
      currencyCode: 'GNF',
      exchangeRate: '0.000141',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '57680242-1faa-498c-b455-f305ada52c96',
      currencyCode: 'GRD',
      exchangeRate: '0.004768',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'bc5c6e58-933d-49e3-ad4a-fa9bd3de99a6',
      currencyCode: 'GTQ',
      exchangeRate: '0.176307',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '9e7ca9f2-669a-43df-9ba9-2d49801bf30c',
      currencyCode: 'GYD',
      exchangeRate: '0.006512',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '61e09b46-c993-4568-9a5b-63f93ee8c033',
      currencyCode: 'HKD',
      exchangeRate: '0.175346',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '20002417-c569-4d0c-8bd8-816c64d4f40f',
      currencyCode: 'HNL',
      exchangeRate: '0.055170',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'cbc5f428-09b3-429b-93bc-f1de7926a3bb',
      currencyCode: 'HRK',
      exchangeRate: '0.215612',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'a4df1150-efc6-4294-8d48-b58303ad1770',
      currencyCode: 'HTG',
      exchangeRate: '0.012137',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '084c8f5e-c1f3-41e4-87af-0d216fdd2b59',
      currencyCode: 'HUF',
      exchangeRate: '0.004572',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'c02f6b65-b9f7-4599-af41-3ec1e85682c9',
      currencyCode: 'IDR',
      exchangeRate: '0.000093',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '64fadb17-5c41-4d09-aa13-63810784f96d',
      currencyCode: 'IEP',
      exchangeRate: '2.063040',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '97a79add-236f-4115-8e06-de49a90e5484',
      currencyCode: 'ILS',
      exchangeRate: '0.405064',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '05fec41d-d45b-4c0c-b6f4-56025074c0cf',
      currencyCode: 'INR',
      exchangeRate: '0.018613',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'afff148b-513e-455e-b919-4d7ff234fb4a',
      currencyCode: 'IQD',
      exchangeRate: '0.001140',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '05bd3125-6d67-4720-93c0-411d0a2ca0b6',
      currencyCode: 'IRR',
      exchangeRate: '0.000032',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'bfdb50a0-18fc-4300-a817-873c2c512950',
      currencyCode: 'ISK',
      exchangeRate: '0.009892',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '83d19ace-1caf-4dd1-8145-c8532280676c',
      currencyCode: 'ITL',
      exchangeRate: '0.000839',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'f693478f-a086-479a-95dd-7a55ca4c68d2',
      currencyCode: 'JMD',
      exchangeRate: '0.009102',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2621826f-3382-4e08-90f2-00cb00167643',
      currencyCode: 'JOD',
      exchangeRate: '1.916710',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '6aff21fd-c382-4c7e-8bee-25fc31155e52',
      currencyCode: 'JPY',
      exchangeRate: '0.012838',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'e6af7efe-24f7-47f5-a03e-e71cd59e1dcb',
      currencyCode: 'KES',
      exchangeRate: '0.012554',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '36ef98a1-59bf-4722-bd97-1757b03c7bca',
      currencyCode: 'KGS',
      exchangeRate: '0.017346',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '34761bb3-594a-48e6-90a4-7f19c25807f8',
      currencyCode: 'KHR',
      exchangeRate: '0.000331',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '67d9278b-395d-4167-9f0e-01422c0247b4',
      currencyCode: 'KMF',
      exchangeRate: '0.003303',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '5052aa3a-e06d-4b4d-82e9-e1611190bc72',
      currencyCode: 'KPW',
      exchangeRate: '0.010066',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'a5c670b2-d560-40f5-99a5-15cc01462dd8',
      currencyCode: 'KRW',
      exchangeRate: '0.001147',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2c66a793-57eb-42f3-9b63-131cbbea89bb',
      currencyCode: 'KWD',
      exchangeRate: '4.451900',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '425997fb-1878-4407-a610-fd8848c6f756',
      currencyCode: 'KYD',
      exchangeRate: '1.630730',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2f8717d1-4ed7-4e99-89a1-de30e3b0a3aa',
      currencyCode: 'KZT',
      exchangeRate: '0.003245',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '38418690-e6dd-4f4d-8b87-6404a6509c62',
      currencyCode: 'LAK',
      exchangeRate: '0.000149',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '16bd74af-fce7-4c23-b4eb-c3e01a0baf99',
      currencyCode: 'LBP',
      exchangeRate: '0.000901',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '277371fc-bcaf-4ad7-abec-707262453210',
      currencyCode: 'LKR',
      exchangeRate: '0.007317',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '79b4c465-5a00-4ef4-a009-11fc06fca7d5',
      currencyCode: 'LRD',
      exchangeRate: '0.006819',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'b04d2a3a-af9b-4fa6-b8a4-baec2e8ecb91',
      currencyCode: 'LSL',
      exchangeRate: '0.081246',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '85762b3b-29df-47c7-886f-703421766d5c',
      currencyCode: 'LTL',
      exchangeRate: '0.470566',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1a8a80ec-419b-4402-b458-7909e0a1f6da',
      currencyCode: 'LUF',
      exchangeRate: '0.040277',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'c3d45e4a-214f-4885-913f-75ba312346ac',
      currencyCode: 'LVL',
      exchangeRate: '2.311840',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2b73a90d-0f9e-4b29-8f4a-dbf18927fb78',
      currencyCode: 'LYD',
      exchangeRate: '0.995160',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'bfdfa903-3a47-4b83-8fcf-b99769f601f9',
      currencyCode: 'MAD',
      exchangeRate: '0.148933',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'd9351b71-75df-4724-8308-34fec54c5eda',
      currencyCode: 'MDL',
      exchangeRate: '0.082027',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'a7b1a89f-9e85-4dc3-ab90-525bdc1546b4',
      currencyCode: 'MGA',
      exchangeRate: '0.000353',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'faee8f1e-efca-4499-a51f-5a1c1634faa5',
      currencyCode: 'MGF',
      exchangeRate: '0.000149',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '207808cc-c12b-47a8-bb6e-2cc184564094',
      currencyCode: 'MKD',
      exchangeRate: '0.026336',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '20f17709-9828-4432-b1c3-4266288f1d67',
      currencyCode: 'MMK',
      exchangeRate: '0.001011',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'f6b21080-5348-4477-a0e2-eaa0d0c8e949',
      currencyCode: 'MNT',
      exchangeRate: '0.000518',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'ee87954d-2591-4347-8912-dadc6069e523',
      currencyCode: 'MOP',
      exchangeRate: '0.170240',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2f739e86-85bc-4b0d-8c69-016a0e4a9d96',
      currencyCode: 'MRO',
      exchangeRate: '0.003568',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'dc0158f5-5a0d-4531-8cb5-688508f47106',
      currencyCode: 'MRU',
      exchangeRate: '0.035678',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'e77e85ac-59d1-49ca-853c-2e68a57e55f4',
      currencyCode: 'MTL',
      exchangeRate: '3.784700',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '01fa4fb9-5702-4ef9-a7e8-50e26642d5b5',
      currencyCode: 'MUR',
      exchangeRate: '0.034260',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'fef72ab3-0a34-425c-91ce-f3276eac66c4',
      currencyCode: 'MVR',
      exchangeRate: '0.088230',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'bdeda0df-4b22-4e89-9c9b-1abacc3275d1',
      currencyCode: 'MWK',
      exchangeRate: '0.001832',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '83c52d61-2d59-4bb3-93ab-e0b6c436f56b',
      currencyCode: 'MXN',
      exchangeRate: '0.062375',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'bf5855de-67c3-4f79-812b-0fb7bc52377a',
      currencyCode: 'MXP',
      exchangeRate: '0.062375',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'fc2f7cb8-859d-4239-9697-479e3549479d',
      currencyCode: 'MYR',
      exchangeRate: '0.327753',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '77b556a0-3724-4d6e-a1b4-6bd83b983d42',
      currencyCode: 'MZM',
      exchangeRate: '0.000019',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '70b70240-9170-4b9a-8c31-9332a7782ba7',
      currencyCode: 'MZN',
      exchangeRate: '0.018976',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '91020adf-eb6d-4864-bdc4-a98ddbaa08ea',
      currencyCode: 'NAD',
      exchangeRate: '0.081246',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '8c10e407-e23c-496b-85d0-03eea7670841',
      currencyCode: 'NGN',
      exchangeRate: '0.003524',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '24c0ce5a-dc28-4ff6-ad43-97720f4c20bb',
      currencyCode: 'NIO',
      exchangeRate: '0.039010',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '064103b6-29aa-46ef-ac5e-f50fa43bd216',
      currencyCode: 'NLG',
      exchangeRate: '0.737290',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'f9c3ac6e-413d-4600-ad91-9a673582ffe9',
      currencyCode: 'NOK',
      exchangeRate: '0.155808',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '16bf4548-3321-4744-9c0e-9f83d091240e',
      currencyCode: 'NPR',
      exchangeRate: '0.011501',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'd09ea28b-e566-4ae9-9bbc-0d4d10e11385',
      currencyCode: 'NZD',
      exchangeRate: '0.918502',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2212d7a8-c5e3-4c8b-a0eb-54de135d78e8',
      currencyCode: 'OMR',
      exchangeRate: '3.530060',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2fb3f0fe-d954-4389-b15b-cd5404f3fec1',
      currencyCode: 'PAB',
      exchangeRate: '1.358940',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '9baa9bf7-1f24-4e79-b814-91926434909e',
      currencyCode: 'PEN',
      exchangeRate: '0.382488',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'cc555a68-5c72-4a74-bdf9-17d2fc014374',
      currencyCode: 'PGK',
      exchangeRate: '0.387706',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '4beb4553-da9e-4006-9a07-1a2876dd0e3b',
      currencyCode: 'PHP',
      exchangeRate: '0.027999',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '47d3c22c-c199-4eda-b975-c34f6078106f',
      currencyCode: 'PKR',
      exchangeRate: '0.008214',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '0fcb338c-20b4-45de-8012-2e26f6e47da6',
      currencyCode: 'PLN',
      exchangeRate: '0.369975',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'a1ee1879-559d-4f6b-b528-c2f8f0ed7100',
      currencyCode: 'PLZ',
      exchangeRate: '0.369975',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '0f712d65-f335-4457-afb5-f81892342c9b',
      currencyCode: 'PTE',
      exchangeRate: '0.008104',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7adf791a-c7cf-494b-b49d-7f7c352b3f50',
      currencyCode: 'PYG',
      exchangeRate: '0.000195',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'cbf852bb-a5a3-4d07-a861-9fe5e09a6b96',
      currencyCode: 'QAR',
      exchangeRate: '0.373437',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'ec285a54-06df-4327-bee9-09e0813ffb1e',
      currencyCode: 'ROL',
      exchangeRate: '0.000034',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1376b6f8-722b-4542-9c7c-8763eafe627a',
      currencyCode: 'RON',
      exchangeRate: '0.335717',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '6b214c0d-f256-4072-98b5-455b69c38712',
      currencyCode: 'RSD',
      exchangeRate: '0.013818',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '4cb2e2eb-22f6-460e-b969-19c252eec1d2',
      currencyCode: 'RUB',
      exchangeRate: '0.018446',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'bddb1e62-f2b4-426e-896d-8ac96c4bee04',
      currencyCode: 'RWF',
      exchangeRate: '0.001407',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '0bb69168-f9c5-4dcd-8bf3-e64919d4be57',
      currencyCode: 'SAR',
      exchangeRate: '0.362314',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '590a0d97-d881-476c-b113-61c1cac15667',
      currencyCode: 'SBD',
      exchangeRate: '0.164432',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '9ef13237-3fd7-4479-86ae-4a0fbcd09487',
      currencyCode: 'SCR',
      exchangeRate: '0.076120',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '5452c42a-d582-4e17-a4db-06eafa63f9a0',
      currencyCode: 'SDD',
      exchangeRate: '0.000246',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '27add660-c429-41f0-bdaa-38616ebe0061',
      currencyCode: 'SDG',
      exchangeRate: '0.024574',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1d9a53a7-c075-482a-8bdf-8fb72e221ce4',
      currencyCode: 'SDP',
      exchangeRate: '0.000600',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'be1603d1-e8e4-4c6d-ad56-3c7a36892d7a',
      currencyCode: 'SEK',
      exchangeRate: '0.157139',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'e06a7fca-7991-41ee-8006-6911c2ada237',
      currencyCode: 'SGD',
      exchangeRate: '1.000000',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '847a51c6-a858-44e4-95f8-caf2eee13ba2',
      currencyCode: 'SHP',
      exchangeRate: '1.822350',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '70ce7f31-42c0-41e5-b1b5-59684f60fb42',
      currencyCode: 'SIT',
      exchangeRate: '0.006780',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'd79d4b92-dee5-412d-8be3-54eec3da977d',
      currencyCode: 'SKK',
      exchangeRate: '0.053933',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '400e4f38-77af-4dd2-86d4-e5f79ea081ae',
      currencyCode: 'SLL',
      exchangeRate: '0.000139',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1ce216a5-fa51-4f5a-a2e2-4db548de2532',
      currencyCode: 'SOS',
      exchangeRate: '0.002345',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '648075ff-fe4a-431d-be70-fdcbf82c7676',
      currencyCode: 'SRD',
      exchangeRate: '0.182226',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2c1e46de-a968-4030-8ca2-fe81ecaa8504',
      currencyCode: 'SRG',
      exchangeRate: '0.000182',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '68aeb761-d173-4f51-b93f-0915dbb66a93',
      currencyCode: 'STD',
      exchangeRate: '0.000066',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '9bd1b18c-1e3b-46a6-af92-b0133c1ceab4',
      currencyCode: 'STN',
      exchangeRate: '0.066303',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1f81d6c0-0d6d-496c-a232-38d4f38195ed',
      currencyCode: 'SVC',
      exchangeRate: '0.155308',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'b925d7d9-3fae-4bad-ab37-624f589d4f91',
      currencyCode: 'SYP',
      exchangeRate: '0.002657',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '59497efe-bedd-4479-b263-c62174f317c6',
      currencyCode: 'SZL',
      exchangeRate: '0.081246',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '960e7a9a-be7a-458a-8c42-71b4af033193',
      currencyCode: 'THB',
      exchangeRate: '0.043711',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '11d9f2c7-05cc-4131-8699-574a196eb45c',
      currencyCode: 'TJS',
      exchangeRate: '0.131885',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '3a8a31b0-169b-47a0-a1f0-c9b9cf40a27d',
      currencyCode: 'TMM',
      exchangeRate: '0.000077',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1606cb09-4c04-4699-b243-b7428d82d4d7',
      currencyCode: 'TMT',
      exchangeRate: '0.387242',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '87cce7bb-1969-4f62-98ef-27fb2c371b0e',
      currencyCode: 'TND',
      exchangeRate: '0.499229',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7dadea7c-cc94-4f58-a344-a12282df5d9d',
      currencyCode: 'TOP',
      exchangeRate: '0.600995',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'e77792b4-e8ec-45a9-b787-a0fbc313fae4',
      currencyCode: 'TRL',
      exchangeRate: '0.000000',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'd6f20d27-0647-460e-b61d-19779e285d42',
      currencyCode: 'TRY',
      exchangeRate: '0.184620',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'bf63b922-a70f-4319-ac8f-a874d4727ab0',
      currencyCode: 'TTD',
      exchangeRate: '0.200746',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'c3c34340-182d-4905-a6fe-94f65d95dfd8',
      currencyCode: 'TWD',
      exchangeRate: '0.046290',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '28f53a00-dcd9-44b8-8eb8-18170c02ccf2',
      currencyCode: 'TZS',
      exchangeRate: '0.000586',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '17867076-ae07-406f-9e95-c81c320228f2',
      currencyCode: 'UAH',
      exchangeRate: '0.049269',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '75e7a4c4-011f-4844-abd8-061f637f3a4a',
      currencyCode: 'UGS',
      exchangeRate: '0.000370',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '781a2c6e-2e17-45f0-95c1-c1e3d613d2ac',
      currencyCode: 'UGX',
      exchangeRate: '0.000370',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '25c2c942-a3aa-4ed9-a06c-406b60349b39',
      currencyCode: 'USD',
      exchangeRate: '1.358940',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '17c419a5-7d5d-435b-adab-b663f9d9754a',
      currencyCode: 'UYP',
      exchangeRate: '0.031932',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'b1ad25c4-3aff-4953-b6e0-280df9814b5a',
      currencyCode: 'UYU',
      exchangeRate: '0.031932',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '033806c8-3796-40f4-97f4-e63bbf6b18f2',
      currencyCode: 'UZS',
      exchangeRate: '0.000133',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '31eb4795-4ef8-496f-b96d-7d2e715de87c',
      currencyCode: 'VEB',
      exchangeRate: '0.000000',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '9c965b8c-d4c4-43a5-b806-016c66958c19',
      currencyCode: 'VEF',
      exchangeRate: '0.000000',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1957ce56-86a3-4d35-9b1d-f7d04592ad91',
      currencyCode: 'VES',
      exchangeRate: '0.000004',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'cdd2f281-7d31-44d3-a2a7-76f21c2078b9',
      currencyCode: 'VND',
      exchangeRate: '0.000059',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '5a8bb1fa-dcea-4c08-ad98-fc72016c62c4',
      currencyCode: 'VUV',
      exchangeRate: '0.012012',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '254afa46-4a12-49d2-98b1-9136e7281bce',
      currencyCode: 'WST',
      exchangeRate: '0.525763',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '7b9aebe5-7e17-4e9b-982c-5bec413fcfe0',
      currencyCode: 'XAF',
      exchangeRate: '0.002477',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '2f47e502-547f-460b-9796-b75809643a6f',
      currencyCode: 'XAU',
      exchangeRate: '2589.55',
      retrievedDate: '2020-09-30T00:00:00.000Z',
    },
    {
      id: '3150feac-3bca-4236-87ee-6a0cc9445bf6',
      currencyCode: 'XCD',
      exchangeRate: '0.503313',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '11333cd2-e62b-498d-b326-c4cc084af7b4',
      currencyCode: 'XEU',
      exchangeRate: '1.624770',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'e9475653-d438-45d4-88ac-5a679a9f5eb8',
      currencyCode: 'XOF',
      exchangeRate: '0.002477',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'b84237d8-05a0-4ed7-8bfd-50a5b8cbb3c1',
      currencyCode: 'XPF',
      exchangeRate: '0.013616',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '0a725752-3403-4ee7-8a77-9b47be317ae7',
      currencyCode: 'YER',
      exchangeRate: '0.005428',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'ee6d332a-1c42-4221-88ff-29554f261aab',
      currencyCode: 'YUN',
      exchangeRate: '0.013818',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '26900a97-7515-46e5-b0c4-bb27b72fdc72',
      currencyCode: 'ZAR',
      exchangeRate: '0.081246',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'e38443c0-bcad-4814-a4cc-821816d862f6',
      currencyCode: 'ZMK',
      exchangeRate: '0.000259',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'b43687e6-0a78-4073-9c7c-7248262a611d',
      currencyCode: 'ZMW',
      exchangeRate: '0.069617',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: '1e818a62-d4ca-46ba-9b5e-2253c6fef596',
      currencyCode: 'ZWD',
      exchangeRate: '0.003626',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
    {
      id: 'eeec4e72-c900-4ba6-b955-8407bf8d81f8',
      currencyCode: 'ZWL',
      exchangeRate: '0.003755',
      retrievedDate: '2020-08-31T00:00:00.000Z',
    },
  ],
};

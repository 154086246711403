import {
  INVESTING_FOR_BEGINNERS_ITEM_TYPES,
  INVESTING_FOR_BEGINNERS_INSURANCE_TYPES,
} from 'constants/enums';
import { IInvestingForBeginnersInsurance } from 'constants/storeTypes';

export const mockInvestmentForBeginnersItem: INVESTING_FOR_BEGINNERS_ITEM_TYPES[] = [
  INVESTING_FOR_BEGINNERS_ITEM_TYPES.ADVICE_FROM_FINANCIAL_ADVISERS,
  INVESTING_FOR_BEGINNERS_ITEM_TYPES.READY_TO_START_INVESTING,
];

export const mockInvestmentForBeginnersInsurance: IInvestingForBeginnersInsurance[] = [
  {
    id: 'some-uuid',
    insurance: INVESTING_FOR_BEGINNERS_INSURANCE_TYPES.HEALTH_INSURANCE,
  },
  {
    id: 'another-uuid',
    insurance: INVESTING_FOR_BEGINNERS_INSURANCE_TYPES.LIFE_INSURANCE,
  },
];

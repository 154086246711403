/* eslint-disable @typescript-eslint/no-explicit-any */
import { StylesConfig } from 'react-select';

import { IThemeObject, colorScale, typeScale } from 'themes';

const disabledProps = (theme: IThemeObject) => ({
  borderColor: colorScale('grey', 20)({ theme }),
  cursor: 'not-allowed',
  color: colorScale('grey', 20)({ theme }),
  '&:hover, &:focus': {
    borderColor: colorScale('grey', 20)({ theme }),
    color: colorScale('grey', 20)({ theme }),
  },
});

export const dropdownStyles = (theme: IThemeObject): StylesConfig => ({
  container: (provided) => ({
    ...provided,
    display: 'flex',
    pointerEvents: 'auto',
    minHeight: '2.5rem',
  }),

  control: (provided, { isDisabled, isFocused, menuIsOpen, selectProps }) => ({
    ...provided,
    display: 'flex',
    boxShadow: 'none',
    border: 'none',
    borderRadius: '0px',
    width: selectProps.isFluid ? '100%' : 'unset',
    borderBottom:
      menuIsOpen || isFocused
        ? `2px solid ${colorScale('primary', 50)({ theme })}`
        : `1px solid ${colorScale('grey', 50)({ theme })}`,
    backgroundColor: 'transparent',
    cursor: selectProps.isSearchable ? 'text' : 'pointer',

    '&:hover, &:active, &:focus': {
      borderColor: colorScale('primary', 50)({ theme }),
    },
    ...(isDisabled && { ...disabledProps(theme) }),
  }),

  menu: (provided: any) => ({
    ...provided,
    boxShadow: 'none',
    border: `1px solid ${colorScale('grey', 20)({ theme })}`,
    zIndex: theme.layers.dropdown,
    margin: '4px 0',
    minWidth: '100%',
    width: 'fit-content',
  }),

  menuList: (provided: any) => ({
    ...provided,
    borderRadius: theme.borders.radius,

    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '3px',
    },

    '&::-webkit-scrollbar-track': {
      background: `${colorScale('grey', 20)({ theme })}`,
      borderRadius: '3px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: `${colorScale('grey', 50)({ theme })}`,
      borderRadius: '3px',
    },

    '&::-webkit-scrollbar-thumb:hover': {
      background: `${colorScale('grey', 50)({ theme })}`,
      borderRadius: '3px',
    },
  }),

  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    color: colorScale('grey', 90)({ theme }),
    background: isSelected
      ? colorScale('primary', 20)({ theme })
      : isFocused
      ? colorScale('primary', 10)({ theme })
      : 'white',
    cursor: 'pointer',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',

    '&:hover, &:active, &:focus': {
      background: isSelected
        ? colorScale('primary', 20)({ theme })
        : colorScale('primary', 10)({ theme }),
      color: colorScale('grey', 90)({ theme }),
    },
    '&::after': {
      display: data.afterText ? 'block' : 'none',
      color: colorScale('grey', 80)({ theme }),
      content: `"${data.afterText}"`,
      marginLeft: '12px',
      whiteSpace: 'nowrap',
      fontSize: typeScale(-1),
    },
    ...(isDisabled && { ...disabledProps(theme) }),
  }),

  dropdownIndicator: (provided, { isDisabled, menuIsOpen }) => ({
    ...provided,
    color: menuIsOpen ? colorScale('primary', 50)({ theme }) : colorScale('grey', 50)({ theme }),
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    cursor: 'pointer',

    '&:hover, &:focus': {
      color: colorScale('primary', 50)({ theme }),
    },
    ...(isDisabled && { ...disabledProps(theme) }),
  }),

  clearIndicator: (provided) => ({
    ...provided,
    color: colorScale('grey', 50)({ theme }),
    cursor: 'pointer',

    '&:hover, &:focus': {
      color: colorScale('primary', 50)({ theme }),
    },
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),

  multiValue: (provided) => ({
    ...provided,
    backgroundColor: colorScale('grey', 20)({ theme }),
  }),

  singleValue: (provided) => ({
    ...provided,
    transform: 'unset',
    position: 'relative',
    top: 'unset',
    maxWidth: 'unset',
    marginLeft: '0',
  }),

  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    marginLeft: '0',
    transform: 'unset',
    position: 'relative',
    top: 'unset',
    color: isDisabled ? colorScale('grey', 20)({ theme }) : colorScale('grey', 50)({ theme }),
  }),

  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '0',
  }),
});

import React from 'react';
import styled from 'styled-components';

import { typography } from 'themes';
import { numberWithSeparator } from 'utilities/currencyUtilities';

import { SGDLabel } from './sharedStyles';

const AmountFractionWrapper = styled.div`
  ${typography('body-l')}
  margin: 6px 0 0;
  display: flex;
  align-items: center;
  line-height: 1;
  flex-wrap: wrap;
`;

interface IProps {
  amount: number;
}

export const TargetAmount: React.FC<IProps> = ({ amount }) => (
  <AmountFractionWrapper data-testid="target-amount-fraction">
    <SGDLabel>SGD</SGDLabel>
    {numberWithSeparator(amount * 0.01, 2)}
  </AmountFractionWrapper>
);

import * as React from 'react';
import styled from 'styled-components';

import { typeScale } from 'themes';

import { Button } from '../Button/Button';
import { RoutedButton } from '../Button/RoutedButton';
import { Heading } from '../SectionHeading/Heading';
import { Section } from '../SectionHeading/Section';

const StyledSection = styled(Section)`
  text-align: left;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 16px;
  font-size: ${typeScale(2)};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;

  button:last-child {
    margin-left: 16px;
  }
`;

export interface IWarningModalTexts {
  header?: string;
  description?: string;
  confirmationButtonText: string;
  confirmationButtonRoute?: string;
  rejectionButtonText?: string;
}

interface IProps extends IWarningModalTexts {
  confirmationButtonRoute?: string;
  handleConfirmation?: () => void;
  handleRejection?: () => void;
}

export class WarningModalContent extends React.Component<IProps> {
  public render() {
    const {
      header,
      description,
      handleConfirmation,
      handleRejection,
      confirmationButtonText,
      confirmationButtonRoute,
      rejectionButtonText,
    } = this.props;
    return (
      <StyledSection>
        {header && <StyledHeading>{header}</StyledHeading>}
        {description && <p>{description}</p>}
        <ButtonsWrapper>
          {rejectionButtonText && handleRejection && (
            <Button variant="tertiary" onClick={handleRejection}>
              {rejectionButtonText}
            </Button>
          )}
          {confirmationButtonRoute ? (
            <RoutedButton to={confirmationButtonRoute} onClick={handleConfirmation}>
              {confirmationButtonText}
            </RoutedButton>
          ) : (
            <Button onClick={handleConfirmation}>{confirmationButtonText}</Button>
          )}
        </ButtonsWrapper>
      </StyledSection>
    );
  }
}

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { ISavingsRatioInputs } from '../interfaces';

export const SR2: IRule<ISavingsRatioInputs> = {
  condition: ({ savingsRatio }: ISavingsRatioInputs) =>
    savingsRatio !== null && savingsRatio >= 0.05 && savingsRatio < 0.2,
  result: {
    key: RULE.SR2,
    indicatorStatus: indicatorStatuses.FAIR,
    recommendedActions: [recommendedActions.TRA_SR_E, recommendedActions.TRA_SR_A],
    resources: [
      // Some links
    ],
  },
};

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { TIMELINE_SECTIONS_GAP_PX, TIMELINE_SUBSECTIONS_GAP_PX, CIRCLE_SIZE_PX } from './constants';

const TimelineSectionsWrapper = styled.div<{ hasTimelineSection: boolean }>`
  display: grid;
  grid-template-columns: 1fr;

  ${ifProp(
    'hasTimelineSection',
    css`
      grid-gap: ${TIMELINE_SECTIONS_GAP_PX};

      // Reset the line of the first section of the entire timeline
      // Prevents the line from leaking out pass the top of its circle
      > .timeline-section:first-of-type .section-line {
        height: calc(100% - ${CIRCLE_SIZE_PX} / 2);
        top: calc(${CIRCLE_SIZE_PX} / 2);
      }

      // Reset the line of the last subsection of the entire timeline
      // Prevents the line from leaking out pass the bottom of its circle
      > .timeline-section:last-of-type .timeline-subsection:last-of-type .subsection-line {
        height: 50%;
        top: 0;
      }
    `,
    css`
      grid-gap: ${TIMELINE_SUBSECTIONS_GAP_PX};

      // Reset the line of the first subsection of the entire timeline
      // Prevents the line from leaking out pass the top of its circle
      > .timeline-subsection:first-of-type .subsection-line {
        height: calc(50% + ${TIMELINE_SUBSECTIONS_GAP_PX});
        bottom: -${TIMELINE_SUBSECTIONS_GAP_PX};
      }

      // Reset the line of the last subsection of the entire timeline
      // Prevents the line from leaking out pass the bottom of its circle
      > .timeline-subsection:last-of-type .subsection-line {
        height: 50%;
        top: 0;
      }
    `
  )}
`;

interface IProps {
  children: React.ReactNode;
  isSubsectionOnly?: boolean;
}

/**
 * Displays a timeline.
 * @param children A block of TimelineSections or TimelineSubsections
 * @param [isSubsectionOnly] Determines what css rules to use to render the timeline correctly
 */
export const Timeline = ({ children, isSubsectionOnly = false }: IProps) => {
  return (
    <TimelineSectionsWrapper hasTimelineSection={!isSubsectionOnly}>
      {children}
    </TimelineSectionsWrapper>
  );
};

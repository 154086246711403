import React from 'react';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { CautionRound } from 'assets/icons';
import { colorScale, typeScale, typography } from 'themes';

const supportingYellowColor = 'supporting-yellow';

const Banner = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 48px;
  border: 1px solid ${colorScale('supporting-yellow', 40)};
  border-radius: ${fromTheme('borders.radiusLarge')};
  background: ${colorScale(supportingYellowColor, 10)};
  color: ${colorScale(supportingYellowColor, 50)};
  padding: 10px 16px;
`;

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${typeScale(3)};
  min-height: ${typeScale(3)};
  border-radius: 50%;
  background: ${colorScale(supportingYellowColor, 30)};
  margin-right: 12px;
`;

const StyledCautionRoundIcon = styled(CautionRound)`
  flex-shrink: 0;
  font-size: ${typeScale(0)};
`;

const StyledP = styled.p`
  ${typography('body-s-semibold')}
  color: ${colorScale(supportingYellowColor, 50)};
  margin: 0;
`;

interface IProps {
  text: string | React.ReactNode;
}

export const WarningBanner: React.FC<IProps> = ({ text, ...otherProps }: IProps) => (
  <Banner {...otherProps}>
    <IconCircle>
      <StyledCautionRoundIcon />
    </IconCircle>
    <StyledP>{text}</StyledP>
  </Banner>
);

import React, { createRef } from 'react';
import styled from 'styled-components';

import { colorScale, typography } from 'themes';

import { IInputProps } from '../Input/Input';

import { InputField } from './InputField';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'fields'
    'error';
`;

const FieldsWrapper = styled.div`
  grid-area: fields;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  width: fit-content;
  align-items: end;
`;

const Separator = styled.span`
  padding-bottom: 8px;

  &::after {
    content: '/';
  }
`;

const ErrorWrapper = styled.div`
  grid-area: error;
  margin-top: 8px;
  ${typography('body-s')};
  color: ${colorScale('supporting-red', 50)};
`;

interface IOwnProps {
  date: Date;
  onInputChange: (date: Date) => void;
  errorMessage?: string;
}

export type IDateInputFieldProps = IOwnProps & IInputProps;

export const isDayCompleted = (day: string) => day.length === 2;

export const isMonthCompleted = (month: string) => month.length === 2 || Number(month) > 1;

export const DateInputField: React.FC<IDateInputFieldProps> = ({
  date,
  onInputChange,
  errorMessage,
  ...otherProps
}) => {
  const monthRef = createRef<HTMLInputElement>();
  const yearRef = createRef<HTMLInputElement>();
  return (
    <Wrapper {...otherProps}>
      <FieldsWrapper>
        <InputField
          date={date}
          onInputChange={onInputChange}
          dateModifier={(date: Date, value: number) => new Date(new Date(date).setDate(value))}
          label="Day"
          valueGetter={(date: Date) => date.getDate()}
          fieldLength={2}
          focusNext={() => monthRef.current?.select()}
          shouldFocusNext={isDayCompleted}
        />
        <Separator />
        <InputField
          date={date}
          onInputChange={onInputChange}
          dateModifier={(date: Date, value: number) => new Date(new Date(date).setMonth(value - 1))}
          label="Month"
          valueGetter={(date: Date) => date.getMonth() + 1}
          fieldLength={2}
          inputRef={monthRef}
          focusNext={() => yearRef.current?.select()}
          shouldFocusNext={isMonthCompleted}
        />
        <Separator />
        <InputField
          date={date}
          onInputChange={onInputChange}
          dateModifier={(date: Date, value: number) => new Date(new Date(date).setFullYear(value))}
          label="Year"
          valueGetter={(date: Date) => date.getFullYear()}
          fieldLength={4}
          inputRef={yearRef}
        />
      </FieldsWrapper>
      {errorMessage && <ErrorWrapper>{errorMessage}</ErrorWrapper>}
    </Wrapper>
  );
};

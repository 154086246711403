import React from 'react';
import styled from 'styled-components';

import { ComparisonBar } from './ComparisonBar';
import { ILegendProps, ComparisonBarLegend } from './ComparisonBarLegend';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
`;

interface IProps {
  current: ILegendProps;
  total: ILegendProps;
  percentage: number;
}

export const ComparisonBarSection = ({ current, total, percentage }: IProps) => (
  <Wrapper>
    <ComparisonBar
      currAmount={current.amount}
      percentage={percentage}
      currColor={current.bgColor}
      bgColor={total.bgColor}
    />
    <ComparisonBarLegend {...current} />
    <ComparisonBarLegend {...total} />
  </Wrapper>
);

export enum NAVIGATION_TYPE {
  DASHBOARD = 'DASHBOARD',
  PROFILE = 'PROFILE',
  TARGETS = 'TARGETS',
  SAVINGS = 'SAVINGS',
  LOANS_AND_CREDIT = 'LOANS_AND_CREDIT',
  INVESTMENT = 'INVESTMENTS',
  INSURANCE = 'INSURANCE',
  HOUSING = 'HOUSING',
  RETIREMENT = 'RETIREMENT',
  SETTINGS = 'SETTINGS',
  ABOUT_US = 'ABOUT_US',
  CONTACT_US = 'CONTACT_US',
}

import React from 'react';
import styled from 'styled-components';

import { typography } from 'themes';

const Label = styled.span<{ currency: string }>`
  &::before {
    ${typography('label')}
    content: '${({ currency }) => currency}';
    margin-right: 4px;
  }
`;

export interface ICurrencyPrefixProps {
  currency?: string;
  children: React.ReactNode;
}
export const CurrencyPrefix = ({
  currency = 'SGD',
  children,
  ...otherProps
}: ICurrencyPrefixProps) => (
  <Label currency={currency} {...otherProps}>
    {children}
  </Label>
);

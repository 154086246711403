import React from 'react';
import styled from 'styled-components';

import { getColoredArray } from '../colors';
import { Legend } from '../Legend/Legend';

import { DoughnutChart } from './DoughnutChart';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
`;

interface IProps {
  chartId: string;
  centerText?: string;
  chartData: {
    label: string;
    amount: number;
    hoverText: string;
  }[];
}

export const DoughnutChartWithLegend: React.FC<IProps> = ({
  chartId,
  chartData,
  centerText,
  ...otherProps
}) => {
  const coloredData = getColoredArray(chartData, { seed: chartId });

  return (
    <Wrapper {...otherProps}>
      <DoughnutChart centerText={centerText} chartData={coloredData} />
      <Legend data={coloredData} />
    </Wrapper>
  );
};

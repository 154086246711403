import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme as fromTheme } from 'styled-tools';

import { Caution } from 'assets/icons';
import { colorScale, typography } from 'themes';

const ICON_SIZE_PX = '24px';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: ${fromTheme('borders.radiusLarge')};
  border: 1px solid ${colorScale('secondary', 20)};
  background: ${colorScale('secondary', 10)};
  color: ${colorScale('secondary', 50)};
  padding: 16px;
`;

const CautionIcon = styled(Caution)`
  width: ${ICON_SIZE_PX};
  height: auto;
`;

const Text = styled.p<{ responsive: boolean }>`
  ${ifProp(
    'responsive',
    css`
      ${typography('body-m')};
    `,
    css`
      font-size: 0.875rem;
    `
  )}

  margin: 0 0 0 16px;
  width: calc(100% - ${ICON_SIZE_PX});
`;

export interface IWarningCardProps {
  text: string | ReactElement;
  responsive?: boolean;
}

type IProps = IWarningCardProps & React.HTMLAttributes<HTMLDivElement>;

export const WarningCard = ({ text, responsive = true, ...otherProps }: IProps) => (
  <Wrapper {...otherProps}>
    <CautionIcon />
    <Text responsive={responsive}>{text}</Text>
  </Wrapper>
);

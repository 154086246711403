import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp } from 'styled-tools';

import { colorScale, typography } from 'themes';

import { SkeletonLoading } from '../SkeletonLoading/SkeletonLoading';

const Wrapper = styled.div<{ variant?: variantType; selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  ${switchProp('variant', {
    tabHeader: css`
      color: ${colorScale('grey', 80)};
      ${ifProp(
        'selected',
        css`
          color: ${colorScale('primary', 50)};
        `
      )}
    `,
  })}
`;

const StyledSkeletonLoading = styled(SkeletonLoading)`
  margin: 0;
`;

export const IconCircle = styled.div<{ selected?: boolean; variant?: variantType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 24px;

  height: 24px;
  padding: 2px 8px;
  margin: auto;
  border-radius: 10px;

  ${switchProp('variant', {
    tabHeader: css`
      background: ${colorScale('grey', 15)};
      ${ifProp(
        'selected',
        css`
          ${typography('label-bold')}
        `,
        css`
          ${typography('label')}
        `
      )}
    `,
    accordion: css`
      background: white;
      color: ${colorScale('primary', 50)};
    `,
  })}
`;

type variantType = 'tabHeader' | 'accordion';

export interface IProps {
  tabName: string;
  variant: variantType;
  selected?: boolean;
  count?: number;
  isLoading?: boolean;
}

export const TabHeader = ({
  tabName,
  variant,
  selected,
  count,
  isLoading = true,
  ...otherProps
}: IProps) => {
  return (
    <Wrapper selected={selected} variant={variant} {...otherProps}>
      <div>{tabName}</div>
      {Number.isInteger(count) && (
        <span>
          <StyledSkeletonLoading guesstimateContent="10" isLoading={isLoading}>
            <IconCircle selected={selected} variant={variant}>
              {count}
            </IconCircle>
          </StyledSkeletonLoading>
        </span>
      )}
    </Wrapper>
  );
};

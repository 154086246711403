const resources = {
  finLitQuiz: {
    version: '1.0.0',
    quizContent: {
      habits: {
        category: 'Money Management',
        question: 'Which of the following are good money management habits?',
        selectVariant: 'multi-select',
        numberOfSelection: 3,
        options: [
          {
            answer: 'Spend within my means',
            value: 1,
          },
          {
            answer: 'Keep track of my spending',
            value: 1,
          },
          {
            answer: 'Set up automatic transfers to a separate savings account',
            value: 1,
          },
          {
            answer: 'None of the above',
            value: 0,
          },
        ],
        tip: {
          header: 'Good money management habits include:',
          list: [
            'Tracking your spending regularly to ensure you spend within your means',
            'Setting automatic transfers to a separate savings account to save with a peace of mind',
          ],
        },
      },
      emergencyFunds: {
        category: 'Money Management',
        question:
          'How much money should be set aside as emergency funds (liquid savings for emergencies)?',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer: 'Less than 1 month of expenses',
            value: 0,
          },
          {
            answer: '1 to less than 3 months of expenses',
            value: 0,
          },
          {
            answer: '3 to 6 months of expenses',
            value: 1,
          },
          {
            answer: 'More than 6 months of expenses',
            value: 2,
          },
          {
            answer: 'Not sure',
            value: 0,
          },
        ],
        tip: {
          header: 'Aim to save at least 3 to 6 months of expenses as emergency funds.',
          paragraph: [
            'This will help you handle unexpected financial shocks like medical bills or a loss of income.',
          ],
        },
      },
      inflation: {
        category: 'Money Management',
        question:
          'Suppose you have $500, and the inflation rate stays at 4 percent per annum. In 5 years, you will be able to buy:',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer: 'Less than what you would be able to buy today with $500',
            value: 1,
          },
          {
            answer: 'More than what you would be able to buy today with $500',
            value: 0,
          },
          {
            answer: 'Same amount as what you would be able to buy today with $500',
            value: 0,
          },
        ],
        tip: {
          header: 'Inflation is when the overall level of prices in the economy go up.',
          paragraph: [
            'Over time, the money you save today will buy you less than what you can buy today.',
            'You may adjust your budget and consider investments to account for rising prices.',
          ],
        },
      },
      interestRate: {
        category: 'Money Management',
        question:
          'If you have multiple loans with different interest rates, which would you pay off first?',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer: 'The loan with the lower interest rate',
            value: 0,
          },
          {
            answer: 'It doesn’t make a difference to me',
            value: 0,
          },
          {
            answer: 'The loan with the higher interest rate',
            value: 1,
          },
          {
            answer: 'Not sure',
            value: 0,
          },
        ],
        tip: {
          header: 'Focus on paying off loans with the highest interest rate first.',
          paragraph: [
            'It will help you save on interest charges over time, and bring down the overall interest you’ll end up paying.',
          ],
        },
      },
      compoundInterest: {
        category: 'Investment',
        question:
          'Suppose you have an investment of $10,000 that earns compound interest of 10% per annum. In 3 years, the total investment value will be:',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer: '$13,000',
            value: 0,
          },
          {
            answer: '$13,310',
            value: 1,
          },
          {
            answer: '$13,660',
            value: 0,
          },
        ],
        tip: {
          header:
            'Compound interest earns interest on the principal amount and accumulated interest over time.',
          paragraph: [
            'This means your total investment value would grow bigger compared to simple interest.',
            'The total investment value earned with compound interest can be calculated with this formula: \nPrincipal x (1 + Interest Rate)^(Time Period).',
          ],
        },
      },
      portfolio: {
        category: 'Investment',
        question: 'What should you consider when reviewing an investment portfolio?',
        selectVariant: 'multi-select',
        numberOfSelection: 3,
        options: [
          {
            answer: 'Reflect on your current life stage and life events',
            value: 1,
          },
          {
            answer: 'Review your asset allocation and diversification',
            value: 1,
          },
          {
            answer: 'Consider current affairs and market trends that might affect your investments',
            value: 1,
          },
          {
            answer: 'None of the above',
            value: 0,
          },
        ],
        tip: {
          header:
            'You should review and rebalance your investment portfolio regularly. Take into account:',
          list: [
            'Your current life stage and life events',
            'Your asset allocation and diversification',
            'Current affairs and market trends that might affect your investments',
            'Investment fees',
          ],
        },
      },
      considerations: {
        category: 'Insurance',
        question: 'What should you consider when buying insurance plans?',
        selectVariant: 'multi-select',
        numberOfSelection: 3,
        options: [
          {
            answer: 'Coverage from existing insurance plan(s)',
            value: 1,
          },
          {
            answer: 'If the insurance plan suits my needs',
            value: 1,
          },
          {
            answer: 'If I can afford the premiums in the long run',
            value: 1,
          },
          {
            answer: 'None of the above',
            value: 0,
          },
        ],
        tip: {
          header: 'Before buying a new insurance plan, you should:',
          list: [
            'Check if the coverage overlaps with any insurance plan(s) you already have',
            'Make sure the new plan meets your needs',
            'Think about whether you can handle the extra premiums in the long run',
          ],
        },
      },
      MediShield: {
        category: 'Insurance',
        question: 'Which of the following about MediShield Life is false?',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer:
              'It is automatically extended to all Singapore Citizens and Permanent Residents, regardless of age or health condition',
            value: 0,
          },
          {
            answer:
              'It is a health insurance plan that helps pay for large hospital bills and selected outpatient treatment costs',
            value: 0,
          },
          {
            answer: 'It does not cover treatments at private hospitals',
            value: 1,
          },
        ],
        tip: {
          header: 'MediShield Life is',
          list: [
            'Automatically extended to all Singapore Citizens and Permanent Residents, regardless of age or health condition',
            'A health insurance plan to help you with large hospital bills and selected outpatient treatment costs',
            'Not limited to specific ward class, for both public and private hospitals',
          ],
        },
      },
      planning: {
        category: 'Retirement Planning',
        question: 'What should you consider when planning for retirement?',
        selectVariant: 'multi-select',
        numberOfSelection: 3,
        options: [
          {
            answer: 'My current finances',
            value: 1,
          },
          {
            answer: 'My desired monthly retirement income',
            value: 1,
          },
          {
            answer: 'When I intend to retire',
            value: 1,
          },
          {
            answer: 'None of the above',
            value: 0,
          },
        ],
        tip: {
          header: 'Figure out how much you need for retirement, keeping in mind:',
          list: [
            'Your current financial situation',
            'How much monthly income you want in retirement',
            'When you want to start drawing down on your retirement funds',
          ],
        },
      },
      cpfLife: {
        category: 'Retirement Planning',
        question: 'With CPF LIFE, you can receive monthly payouts from age 65 till',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer: 'My CPF savings run out',
            value: 0,
          },
          {
            answer: 'I turn 80',
            value: 0,
          },
          {
            answer: 'I turn 90',
            value: 0,
          },
          {
            answer: 'I pass on',
            value: 1,
          },
        ],
        tip: {
          header:
            'With CPF LIFE, you can receive monthly payouts from age 65, no matter how long you live.',
          paragraph: ["That means you don't have to worry about outliving your savings!"],
        },
      },
      cpfDistribution: {
        category: 'Estate Planning',
        question:
          'Which of the following about the distribution of CPF savings when a person passes away is false?',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer:
              'If CPF nomination(s) had been made, it will be distributed to the CPF nominee(s)',
            value: 0,
          },
          {
            answer:
              'If no CPF nomination(s) had been made, it will be distributed according to their will',
            value: 1,
          },
        ],
        tip: {
          header: 'Your CPF savings do not form part of your estate and are not covered by a will.',
          list: [
            'You need to make a CPF nomination to specify who will receive them when you’re no longer around',
            "Otherwise, your CPF savings will be transferred to the Public Trustee's Office (PTO) for distribution to your family members according to intestacy laws or inheritance law (for Muslims)",
            'Your beneficiaries will also be charged an administration fee',
          ],
        },
      },
    },
    demographics: {
      residentialStatus: {
        category: 'Demographics',
        question: 'What is your residential status?',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer: 'Singapore Citizen',
          },
          {
            answer: 'Singapore Permanent Resident',
          },
          {
            answer: 'Others',
          },
        ],
      },
      yearOfBirth: {
        category: 'Demographics',
        question: 'What is your year of birth?',
        selectVariant: 'dropdown',
        numberOfSelection: 1,
        options: [
          {
            answer: 'placeholder',
          },
        ],
      },
      employmentStatus: {
        category: 'Demographics',
        question: 'What is your employment status?',
        selectVariant: 'dropdown',
        numberOfSelection: 1,
        options: [
          {
            answer: 'Full-time or part-time employee',
          },
          {
            answer: 'Self-employed or freelancing',
          },
          {
            answer: 'Unemployed',
          },
          {
            answer: 'Not working and not looking for work',
          },
          {
            answer: 'Student',
          },
          {
            answer: 'Retired',
          },
          {
            answer: 'Others',
          },
        ],
      },
      grossIncome: {
        category: 'Demographics',
        question: 'What is your monthly gross income?',
        selectVariant: 'single-select',
        numberOfSelection: 1,
        options: [
          {
            answer: 'Less than or equals to $2,499',
          },
          {
            answer: '$2,500 to $5,999',
          },
          {
            answer: '$6,000 to $11,999',
          },
          {
            answer: 'More than or equals to $12,000',
          },
          {
            answer: 'Prefer not to say',
          },
        ],
      },
    },
    selection: 'Select only {{count}}',
    selection_other: 'Select up to {{count}}',
    selection_yearOfBirth: 'Year of birth',
    selection_employmentStatus: 'Employment status',
    departureModal: {
      title: 'Leaving this page? Your quiz progress will be lost.',
      paragraph1: 'Your progress will be lost if you:',
      point1: 'Exit the quiz before completing it, or',
      point2: 'Choose not to login to MyMoneySense via the quiz results page',
      paragraph2:
        'You will have to start over the next time you come back. Close this message to continue with the quiz.',
      exitButton: 'Yes I want to leave this page',
    },
    resultPage: {
      badges: {
        goodStart: 'Financial Novice',
        estatePlanning: 'Legacy Steward',
        retirementPlanning: 'Wise Nest Planner',
        insurance: 'Shield Guardian',
        investment: 'Finance Sage',
        moneyManagement: 'Budgeting Crusader',
        badgeAchievement: 'You achieved {{count}} badge',
        badgeAchievement_other: 'You achieved {{count}} badges',
        hooray:
          'Hooray! You are now eligible to participate in the Giveaway. Look out for the instructions below.',
      },
      congratulation: {
        title: 'Your results',
        paragraph:
          'Congrats on starting your journey to improving your financial literacy! Continue learning more about financial planning and every lesson learned is a step towards a brighter and more secure financial future. You got this!',
        shareButton: 'Share quiz link',
      },
      GiveAwayInstructions: {
        step1:
          'To participate in the Giveaway, click on the button below to access your personalised application form requesting for your:',
        step2: 'Full name (as shown on your NRIC)',
        step3: 'Email address',
        step4: 'After submitting the application form,',
        step5:
          'Login to MyMoneySense using Singpass and verify your email address (ensure same email address as provided in the form).',
        step6: 'Things to note:',
        step7: 'Ensure that the form you are accessing starts with a “form.gov.sg” URL.',
        termsAndConditions: 'Terms & Conditions ',
        applyButton: 'apply',
        CTAButton: 'Participate in Giveaway',
      },
    },
  },
};

export default resources;

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { applicationFlags } from 'applicationFlags';
import { PeopleWithGlowingLightbulbImage } from 'assets/LandingPage';
import { WaveDesktopImage } from 'assets/LandingPage';
import { WaveMobileImage } from 'assets/LandingPage';
import {
  Dropdown,
  Heading,
  IDropdownOption,
  ISelectedOption,
  Panel,
  Toggle,
} from 'components/Common';
import { LoginWithSingpassButton } from 'components/Composite';
import {
  UAT_MOCK_NEW_USERS_KEY,
  UAT_USAGE_DROPDOWN_OPTIONS,
  UAT_USAGE_KEY,
} from 'constants/uatUsageConstants';
import { typography, minWidth, maxWidth, PAGE_SIDE_SPACING } from 'themes';

import { SectionWrapper, StyledContainer } from '../sharedStyles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 48px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageHeading = styled(Heading)`
  margin: 0;
  ${typography('title')};
`;

const PageDescription = styled.p`
  margin: 24px 0;
  ${typography('body-l')};
`;

const StyledInlineHeaderImage = styled(PeopleWithGlowingLightbulbImage)`
  max-width: 448px;

  ${maxWidth(
    'tablet',
    css`
      width: calc(100% + ${PAGE_SIDE_SPACING.DEFAULT_PX} * 2);
      margin: -48px -${PAGE_SIDE_SPACING.DEFAULT_PX} 0;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      display: none;
    `
  )}
`;

const StyledSiblingHeaderImage = styled(PeopleWithGlowingLightbulbImage)`
  margin: 0 0 24px 16px;
  height: 100%;

  ${maxWidth(
    'mobile',
    css`
      display: none;
    `
  )}

  ${maxWidth(
    'desktop',
    css`
      width: 50%;
    `
  )}
`;

const waveStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
`;

const StyledWaveMobileImage = styled(WaveMobileImage)`
  ${waveStyles};
  height: 640px;

  ${minWidth(
    'tablet',
    css`
      height: 384px;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      display: none;
    `
  )}
`;

const StyledWaveDesktopImage = styled(WaveDesktopImage)`
  ${waveStyles};
  height: 448px;

  ${maxWidth(
    'tablet',
    css`
      display: none;
    `
  )}

  ${minWidth(
    'largeScreen',
    css`
      height: 576px;
    `
  )}
`;

const UsageWrapper = styled(Panel)`
  display: grid;
  gap: 8px;
`;

const UsageParamsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
`;

const UsageTitle = styled.p`
  ${typography('body-l-bold')}
  margin: 0 0 12px 0;
`;

const StyledLoginWithSingpassButton = styled(LoginWithSingpassButton)`
  align-self: start;
`;

export const TopSection = () => {
  const uatUsageOptionFromLocalStorage = localStorage.getItem(UAT_USAGE_KEY);
  const uatMockNewUsersFromLocalStorage = localStorage.getItem(UAT_MOCK_NEW_USERS_KEY);

  const [uatUsageOption, setUatUsageOption] = useState<ISelectedOption>(
    uatUsageOptionFromLocalStorage
      ? UAT_USAGE_DROPDOWN_OPTIONS.find(({ value }) => value === uatUsageOptionFromLocalStorage)
      : UAT_USAGE_DROPDOWN_OPTIONS[1]
  );

  const [mockNewUser, setMockNewUser] = useState<boolean>(
    uatMockNewUsersFromLocalStorage ? uatMockNewUsersFromLocalStorage === 'true' : false
  );

  const onUatUsageOptionChange = (selectedUsageOption: ISelectedOption) => {
    const selectedUsageOptionValue = (selectedUsageOption as IDropdownOption<string>)?.value;
    localStorage.setItem(UAT_USAGE_KEY, selectedUsageOptionValue);
    setUatUsageOption(selectedUsageOption);
  };

  const onUatMockNewUsersChange = (checked: boolean) => {
    localStorage.setItem(UAT_MOCK_NEW_USERS_KEY, String(checked));
    setMockNewUser(checked);
  };

  useEffect(() => {
    if (!applicationFlags.isProduction) {
      localStorage.setItem(UAT_USAGE_KEY, (uatUsageOption as IDropdownOption<string>)?.value);
      localStorage.setItem(UAT_MOCK_NEW_USERS_KEY, String(mockNewUser));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionWrapper>
      <StyledWaveMobileImage />
      <StyledWaveDesktopImage />
      <StyledContainer>
        <Wrapper>
          <TextWrapper>
            <PageHeading>
              Money&nbsp;matters
              <br />
              made&nbsp;simple
            </PageHeading>
            <PageDescription>
              Get an overview of your finances and plan ahead for your life goals — all with one
              tool.
            </PageDescription>

            {!applicationFlags.isProduction && (
              <UsageWrapper>
                <UsageTitle>Today do what?</UsageTitle>
                <UsageParamsWrapper>
                  Usage
                  <Dropdown
                    label="uat-usage"
                    hideLabel={true}
                    value={uatUsageOption}
                    options={UAT_USAGE_DROPDOWN_OPTIONS as IDropdownOption[]}
                    onChange={onUatUsageOptionChange}
                  />
                </UsageParamsWrapper>

                <UsageParamsWrapper>
                  New user
                  <Toggle checked={mockNewUser} onChange={onUatMockNewUsersChange} />
                </UsageParamsWrapper>
              </UsageWrapper>
            )}

            <StyledLoginWithSingpassButton className={'singpass-btn'} />

            <StyledInlineHeaderImage />
          </TextWrapper>
          <StyledSiblingHeaderImage />
        </Wrapper>
      </StyledContainer>
    </SectionWrapper>
  );
};

import { createRecommendedActionRequest } from 'redux/financialStatus/actions';
import {
  getFinancialStatusFromState,
  getRecommendedActionsCompletedKeys,
} from 'redux/financialStatus/selectors';
import { getHasCompletedOnboarding } from 'redux/profile/selectors';

import { RECOMMENDED_ACTION, RECOMMENDED_ACTION_COMPLETER } from 'constants/enums';
import { IStore } from 'constants/storeTypes';
import { store } from 'store';

export const isRecommendedActionRelevantToUser = (store: IStore, key: RECOMMENDED_ACTION) => {
  const hasCompletedOnboarding = getHasCompletedOnboarding(store);
  if (!hasCompletedOnboarding) {
    return false;
  }

  const { incompleteRecommendedActions } = getFinancialStatusFromState(store);
  const incompleteRecommendedActionKeys = incompleteRecommendedActions.map(
    (recommendedActions) => recommendedActions.key
  );
  return incompleteRecommendedActionKeys.includes(key);
};

export const markSystemRecommendedActionAsCompleted = (key: RECOMMENDED_ACTION) => {
  const completedRecommendedActions = getRecommendedActionsCompletedKeys(store.getState());
  const isRecommendedActionCompleted = completedRecommendedActions.includes(key);

  if (!isRecommendedActionCompleted) {
    store.dispatch(createRecommendedActionRequest(key, RECOMMENDED_ACTION_COMPLETER.SYSTEM) as any);
  }
};

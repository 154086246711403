import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

import { TOP_GAP_PX, TOP_GAP_PX_DESKTOP } from 'components/Templates';
import {
  maxWidth,
  minWidth,
  typography,
  colorScale,
  ITheme,
  theme,
  PAGE_SIDE_SPACING,
} from 'themes';

export const IMAGE_MAX_HEIGHT_PX_MOBILE = '290px';
export const IMAGE_MAX_HEIGHT_PX_DESKTOP = '360px';

const CIRCLE_WIDTH_DEFAULT = 270;
const CIRCLE_WIDTH_DESKTOP = 300;
const CIRCLE_PADDING_DEFAULT = 32;

const HeaderWrapper = styled.div`
  position: relative;
  max-width: 420px;
  margin: 0 auto;

  ${minWidth(
    'desktop',
    css`
      position: sticky;
      top: calc(${theme.heights.navbar} + ${TOP_GAP_PX_DESKTOP});
    `
  )}
`;

const Circle = styled.div<{ circleColor: (props: ITheme) => string }>`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${prop('circleColor')};
  border-radius: 50%;
  width: ${CIRCLE_WIDTH_DEFAULT}px;
  height: ${CIRCLE_WIDTH_DEFAULT}px;
  padding: ${CIRCLE_PADDING_DEFAULT}px;

  ${maxWidth(
    'mobile',
    css`
      top: calc(-${TOP_GAP_PX} - 72px);
      left: calc(-${PAGE_SIDE_SPACING.DEFAULT_PX} - 32px);
      padding: 64px 24px 24px 64px;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      top: calc(-${TOP_GAP_PX} - 48px);
    `
  )}

  ${minWidth(
    'desktop',
    css`
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: ${CIRCLE_WIDTH_DESKTOP}px;
      height: ${CIRCLE_WIDTH_DESKTOP}px;
    `
  )}
`;

const Title = styled.p<{
  titleColor: (props: ITheme) => string;
  isMultiline: boolean;
  pageHasBackButton: boolean;
}>`
  ${typography('title')}
  color: ${prop('titleColor')};
  white-space: nowrap;

  ${ifProp(
    'isMultiline',
    css`
      white-space: normal;
      width: min-content;
      min-width: 200px;
    `
  )};

  ${ifProp(
    'pageHasBackButton',
    css`
      ${maxWidth(
        'mobile',
        css`
          margin-top: 64px;
        `
      )}
    `
  )};
`;

const Image = styled.div`
  position: relative;
  box-sizing: content-box;

  display: flex;

  ${maxWidth(
    'tablet',
    css`
      justify-content: flex-end;
      height: ${IMAGE_MAX_HEIGHT_PX_MOBILE};
      padding-top: calc(${CIRCLE_WIDTH_DEFAULT}px / 3);
    `
  )}

  ${minWidth(
    'desktop',
    css`
      justify-content: center;
      height: ${IMAGE_MAX_HEIGHT_PX_DESKTOP};
      padding-top: calc(${CIRCLE_WIDTH_DESKTOP}px - 32px);
    `
  )}
`;

interface IProps {
  title: string;
  image: React.ReactElement;
  titleColor?: (props: ITheme) => string;
  bubbleColor?: (props: ITheme) => string;
  pageHasBackButton?: boolean;
}

/**
 * Renders given title in a circle with an image.
 * On desktop, the circle and image are centered in a single column.
 * On mobile, the circle renders on the left and the image on the right.
 *
 * @param title
 * @param image
 * @param [titleColor] Determines the text color of the title
 * @param [bubbleColor] Determines the color of the circle
 * @param [pageHasBackButton] Applicable for mobile only.
 *                            Determines if the title should be rendered lower
 *                            in position to accommodate a back button above it.
 */
export const BubbleHeader = ({
  title,
  image,
  titleColor = colorScale('primary', 40),
  bubbleColor = colorScale('primary', 20),
  pageHasBackButton = false,
}: IProps) => {
  const [isMultilineTitle, setIsMultilineTitle] = useState(false);

  useEffect(() => {
    const pageTitle = document.getElementById('page-title');
    if (pageTitle) {
      const pageTitleWidth = pageTitle.offsetWidth;
      if (pageTitleWidth > CIRCLE_WIDTH_DESKTOP - CIRCLE_PADDING_DEFAULT * 2) {
        setIsMultilineTitle(true);
      }
    }
  }, []);

  return (
    <HeaderWrapper>
      <Circle circleColor={bubbleColor}>
        <Title
          id="page-title"
          titleColor={titleColor}
          isMultiline={isMultilineTitle}
          pageHasBackButton={pageHasBackButton}
        >
          {title}
        </Title>
      </Circle>
      <Image>{image}</Image>
    </HeaderWrapper>
  );
};

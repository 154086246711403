import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ifProp, switchProp } from 'styled-tools';

import { ArrowDown } from 'assets/icons';
import { Section, Heading } from 'components/Common';
import { typeScale, typography, PAGE_SIDE_SPACING, colorScale } from 'themes';

const Wrapper = styled(Section)`
  margin: 24px 0px 24px;
`;

const ToggleButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 0;
  cursor: pointer;
  font-size: ${typeScale(1)};
  outline: none;
  position: relative;
  z-index: 1;

  ::before {
    content: '';
    position: absolute;
    top: -12px;
    left: -12px;
    width: calc(100% + ${PAGE_SIDE_SPACING.DEFAULT_PX});
    height: calc(100% + ${PAGE_SIDE_SPACING.DEFAULT_PX});
    background: ${colorScale('grey', 15)};
    z-index: -1;
    opacity: 0;
    transition: 0.2s;
  }

  &:hover {
    ::before {
      opacity: 1;
    }
  }
`;

const StyledHeading = styled(Heading)<{ isExpanded: boolean; typography: string }>`
  ${switchProp('typography', {
    label: css`
      ${ifProp(
        'isExpanded',
        css`
          div:first-child {
            ${typography('body-l-bold')}
          }
          span:last-child > div {
            ${typography('label-bold')}
          }
        `,
        css`
          div:first-child {
            ${typography('body-l')}
          }
          span:last-child > div {
            ${typography('label')}
          }
        `
      )}
    `,
    body: css`
      ${ifProp('isExpanded', typography('body-l-bold'), typography('body-l'))}
    `,
  })}

  margin-bottom: 0;
  margin-right: 16px;
  flex: 1;
`;

const ArrowWrapper = styled.div<{ isExpanded: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  transition: 0.2s;
  width: 18px;

  ${ifProp(
    'isExpanded',
    css`
      transform: rotate(180deg);
    `
  )}
`;

const ContentWrapper = styled.div`
  overflow: hidden;
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  };
  to {
    opacity: 1;
    transform: translateY(0);
    };
`;

const Content = styled.div<{ includeBar: boolean }>`
  margin-top: 24px;
  ${ifProp(
    'includeBar',
    css`
      border-left: 1px solid ${colorScale('grey', 50)};
      padding-left: 24px;
    `
  )}
  
  animation: ${slideDown} 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  line-height: 1.6;

  > i {
    color: ${colorScale('grey', 80)};
    font-size: ${typeScale(-1)};
  }

  > ul {
    padding-inline-start: 16px;
  }

  li {
    ${typography('body-l')}
    margin-bottom: 12px;
  }

  > p {
    ${typography('body-l')}
  }
`;

export interface IAccordion {
  heading: React.ReactNode;
  content: React.ReactNode;
  includeBar?: boolean;
  typography?: 'label' | 'body';
}

export const Accordion = ({
  heading,
  content,
  includeBar = true,
  typography = 'body',
  ...otherProps
}: IAccordion) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Wrapper {...otherProps}>
      <ToggleButton onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}>
        <StyledHeading isExpanded={isExpanded} typography={typography}>
          {heading}
        </StyledHeading>
        <ArrowWrapper isExpanded={isExpanded}>
          <ArrowDown />
        </ArrowWrapper>
      </ToggleButton>
      {isExpanded && (
        <ContentWrapper>
          <Content includeBar={includeBar}>{content}</Content>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

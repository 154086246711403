import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { addDefaultErrorMessage } from 'redux/messages/actions';
import { markSystemRecommendedActionAsCompleted } from 'redux/recommendedActions/helpers';

import { Button, Modal } from 'components/Common';
import { ReminderChoicesContent } from 'components/Composite';
import { RECOMMENDED_ACTION } from 'constants/enums';
import { minWidth } from 'themes';

import { SetInvestmentRemindersModalContent } from './SetInvestmentRemindersModalContent';

const StyledModal = styled(Modal)`
  text-align: left;

  ${minWidth(
    'tablet',
    css`
      max-width: 384px;
    `
  )}
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

interface IProps {
  buttonText: string;
}

export const PortfolioReviewReminderButton = ({ buttonText }: IProps) => {
  const [modalOpened, setModalOpened] = useState<
    'set-investment-reminder' | 'add-cal-investment-reminder' | null
  >(null);

  const [dateOfReminder, setDateOfReminder] = useState<Date>(new Date());

  const dispatch = useDispatch();

  const handleMarkAsComplete = async () => {
    try {
      markSystemRecommendedActionAsCompleted(RECOMMENDED_ACTION.TRA_PD_A);
    } catch (error) {
      dispatch(addDefaultErrorMessage(error));
    }
  };

  return (
    <>
      <StyledModal
        titleText="Which day would you like to set the investment reminders on?"
        mounted={modalOpened === 'set-investment-reminder'}
        enableExitAnimation={modalOpened !== 'add-cal-investment-reminder'}
        onExit={() => setModalOpened(null)}
      >
        <SetInvestmentRemindersModalContent
          onSubmit={(date: Date) => {
            setDateOfReminder(date);
            setModalOpened('add-cal-investment-reminder');
          }}
        />
      </StyledModal>
      <StyledModal
        mounted={modalOpened === 'add-cal-investment-reminder'}
        titleText="add-cal-investment-reminder"
        onExit={() => setModalOpened(null)}
      >
        <ReminderChoicesContent
          onClickReminderCTA={() => setModalOpened(null)}
          eventData={{
            title: 'Review your investment portfolio',
            startDate: dateOfReminder,
            endDate: dateOfReminder,
            recurrence: 'RRULE:FREQ=MONTHLY;INTERVAL=6',
          }}
          headingText="Which calendar do you want to add the reminders to?"
          onIconClick={handleMarkAsComplete}
        />
      </StyledModal>

      <StyledButton
        variant="secondary"
        widthStyle="fluid"
        onClick={() => setModalOpened('set-investment-reminder')}
      >
        {buttonText}
      </StyledButton>
    </>
  );
};

import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import { Section } from 'components/Common';
import { minWidth, maxWidth, theme, PAGE_SIDE_SPACING, layers } from 'themes';

import { BACK_BUTTON_HEIGHT_PX } from '../Composite/BackButton/constants';

import { TEMPLATE_BACKGROUND_ID } from './Backgrounds/constants';
import {
  COMPONENT_GAP_PX,
  TOP_GAP_PX,
  BOTTOM_GAP_PX,
  TOP_GAP_PX_DESKTOP,
  BOTTOM_GAP_PX_DESKTOP,
} from './constants';
import { IPageSideSpacing } from './interfaces';

/**
 * backgroundStyles is responsible for ensuring the overall content
 * sits above a background prop that gets passed into a template.
 */
const backgroundStyles = css`
  position: relative; // For absolute backgrounds to anchor to

  // Applies to immediate children, the children of these children will inherit the properties
  > *:not(#${TEMPLATE_BACKGROUND_ID}) {
    position: relative; // For z-index to work
    z-index: ${layers.templateContent}; // Ensures that content is above any background that the template is rendering
  }
`;

/**
 * TemplateWrapper is responsible for ensuring consistent spacing between
 * the overall page content to the navigation bar and footer.
 *
 * It finds the first and last children on the page and override their
 * spacing values.
 */
export const TemplateWrapper = styled(Section)<{ pageHasBackButton?: boolean }>`
  ${backgroundStyles}

  > div:first-of-type {
    padding-top: ${ifProp(
      'pageHasBackButton',
      css`calc(${TOP_GAP_PX} + ${BACK_BUTTON_HEIGHT_PX})`,
      TOP_GAP_PX
    )};

    ${minWidth(
      'desktop',
      css`
        padding-top: ${ifProp(
          'pageHasBackButton',
          css`calc(${TOP_GAP_PX_DESKTOP} + ${BACK_BUTTON_HEIGHT_PX})`,
          TOP_GAP_PX_DESKTOP
        )};
      `
    )}
  }

  > div:not(:last-child):not(#${TEMPLATE_BACKGROUND_ID}) {
    margin-bottom: ${COMPONENT_GAP_PX};
  }

  > div:last-of-type {
    margin-bottom: ${BOTTOM_GAP_PX};

    ${minWidth(
      'desktop',
      css`
        margin-bottom: ${BOTTOM_GAP_PX_DESKTOP};
      `
    )}
  }
`;

/**
 * TemplateContainers are responsible for ensuring consistent spacing between
 * the overall content to the left/right edges of the screen. It also centers
 * the overall content, and ensure it does not extend beyond a certain width
 * on wider screens.
 */

// Meant to be used in template components only
export const BaseTemplateContainer = styled.div<{ pageSideSpacing?: IPageSideSpacing }>`
  width: 100vw;
  transition: ${theme.transitions.duration};

  margin: 0 auto;

  ${maxWidth(
    'tablet',
    css`
      padding: 0 ${ifProp('pageSideSpacing', prop('pageSideSpacing'), PAGE_SIDE_SPACING.DEFAULT_PX)};
      max-width: 640px;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      width: 768px;
    `
  )}

  ${minWidth(
    'largeScreen',
    css`
      width: 1024px;
    `
  )}
`;

export const TwinsTemplateContainer = BaseTemplateContainer;
export const TallManFatBoyContainer = BaseTemplateContainer;
export const SuperstarTemplateContainer = styled(BaseTemplateContainer)`
  ${minWidth(
    'desktop',
    css`
      max-width: 670px;
    `
  )}
`;

// https://webdelving.com/blog/styled-components-ampersand/
export const FooterContent = styled.div<{ isFlush?: boolean }>`
  ${ifProp(
    'isFlush',
    css`
      &&& {
        margin-bottom: 0;
      }
    `
  )}
`;

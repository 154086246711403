import React from 'react';

import AmericanGothicStyledOldCouple from './american-gothic-styled-old-couple.svg';
import BankBuilding from './bank-building.svg';
import BriefCaseWithMedicalShield from './brief-case-with-medical-shield.svg';
import ConfusedDude from './confused-dude.svg';
import CouplePlanningForRetirement from './couple-planning-for-retirement.svg';
import DebtFreeShop from './debt-free-shop.svg';
import EarlyHousingLoanPaymentMobile from './early-housing-loan-payments-mobile.svg';
import EarlyHousingLoanPaymentTabletDesktop from './early-housing-loan-payments-tablet-desktop.svg';
import ManSittingOnCoins from './man-sitting-on-coins.svg';
import ManWithTalkingPoints from './man-with-talking-points.svg';
import MoneyBagWithAShield from './money-bag-with-a-shield.svg';
import MoneyBags from './money-bags.svg';
import PeopleInFrontOfLaptop from './people-in-front-of-laptop.svg';
import PuttingCoinInInvestment from './putting-coin-in-investment.svg';
import SmilingMan from './smiling-man.svg';
import WindingRoad from './winding-road.svg';

type ImageProps = React.HTMLAttributes<HTMLImageElement>;

export const ManSittingOnCoinsImage = (props: ImageProps) => (
  <img {...props} src={ManSittingOnCoins} alt="" />
);

export const ManWithTalkingPointsImage = (props: ImageProps) => (
  <img {...props} src={ManWithTalkingPoints} alt="" />
);

export const AmericanGothicStyledOldCoupleImage = (props: ImageProps) => (
  <img {...props} src={AmericanGothicStyledOldCouple} alt="" />
);

export const BankBuildingImage = (props: ImageProps) => (
  <img {...props} src={BankBuilding} alt="" />
);

export const MoneyBagWithAShieldImage = (props: ImageProps) => (
  <img {...props} src={MoneyBagWithAShield} alt="" />
);

export const PuttingCoinInInvestmentImage = (props: ImageProps) => (
  <img {...props} src={PuttingCoinInInvestment} alt="" />
);

export const MoneyBagsImage = (props: ImageProps) => <img {...props} src={MoneyBags} alt="" />;

export const DebtFreeShopImage = (props: ImageProps) => (
  <img {...props} src={DebtFreeShop} alt="" />
);

export const EarlyHousingLoanPaymentMobileImage = (props: ImageProps) => (
  <img
    {...props}
    src={EarlyHousingLoanPaymentMobile}
    alt="Make early housing loan payments to hdb with no penalty while early payments to bank might incur charges"
  />
);

export const EarlyHousingLoanPaymentTabletDesktopImage = (props: ImageProps) => (
  <img
    {...props}
    src={EarlyHousingLoanPaymentTabletDesktop}
    alt="Make early housing loan payments to hdb with no penalty while early payments to bank might incur charges"
  />
);

export const BriefCaseWithMedicalShieldImage = (props: ImageProps) => (
  <img {...props} src={BriefCaseWithMedicalShield} alt="" />
);
export const ConfusedDudeImage = (props: ImageProps) => (
  <img {...props} src={ConfusedDude} alt="" />
);

export const CouplePlanningForRetirementImage = (props: ImageProps) => (
  <img {...props} src={CouplePlanningForRetirement} alt="" />
);

export const PeopleInFrontOfLaptopImage = (props: ImageProps) => (
  <img {...props} src={PeopleInFrontOfLaptop} alt="" />
);

export const SmilingManImage = (props: ImageProps) => <img {...props} src={SmilingMan} alt="" />;

export const WindingRoadImage = (props: ImageProps) => <img {...props} src={WindingRoad} alt="" />;

import React from 'react';
import MediaQuery from 'react-responsive';

import { Pencil } from 'assets/icons';
import { Button, IconButton } from 'components/Common';
import { theme } from 'themes';

interface IProps {
  onClick: () => void;
}

export const EditButton = ({ onClick }: IProps) => {
  return (
    <MediaQuery minWidth={theme.breakpoints.desktopMin} maxWidth={theme.breakpoints.desktopMax}>
      {(matches) =>
        matches ? (
          <IconButton size="small" edge="right" ariaLabel="Edit button" onClick={onClick}>
            <Pencil />
          </IconButton>
        ) : (
          <Button variant="tertiary" size="small" edge="right" icon={<Pencil />} onClick={onClick}>
            Edit
          </Button>
        )
      }
    </MediaQuery>
  );
};

import { omit } from 'lodash';
import { Reducer } from 'redux';

import { IMyinfoActions } from 'constants/actionTypes';
import { MYINFO_ACTIONS, MYINFO_RESET_ACTION_SCOPE } from 'constants/enums';
import { IMyinfoStore } from 'constants/storeTypes';

export const initialState: IMyinfoStore = {
  id: null,
  usePersonFullData: true,
};

export const myinfoReducer: Reducer<IMyinfoStore, IMyinfoActions> = (
  state: IMyinfoStore = initialState,
  action: IMyinfoActions
) => {
  switch (action.type) {
    case MYINFO_ACTIONS.SET_ID:
      return { ...state, id: action.id };
    case MYINFO_ACTIONS.SET_NAME:
      return { ...state, name: action.name };
    case MYINFO_ACTIONS.SET_DOB:
      return { ...state, dob: action.dob };
    case MYINFO_ACTIONS.SET_SEX:
      return { ...state, sex: action.sex };
    case MYINFO_ACTIONS.SET_MARITAL_STATUS:
      return { ...state, maritalStatus: action.maritalStatus };
    case MYINFO_ACTIONS.SET_RESIDENTIAL_STATUS:
      return { ...state, residentialStatus: action.residentialStatus };
    case MYINFO_ACTIONS.SET_EMAIL:
      return { ...state, email: action.email };
    case MYINFO_ACTIONS.SET_NOA:
      return { ...state, noa: action.noa };
    case MYINFO_ACTIONS.SET_CPF:
      return { ...state, cpf: action.cpf };
    case MYINFO_ACTIONS.SET_HDB_OWNERSHIP:
      return { ...state, hdbOwnership: action.hdbOwnership };
    case MYINFO_ACTIONS.UPDATE_HDB_OWNERSHIP:
      return {
        ...state,
        hdbOwnership: state.hdbOwnership?.map((hdbo) => {
          if (hdbo.id === action.id) {
            return { ...hdbo, ...action.update };
          }
          return hdbo;
        }),
      };
    case MYINFO_ACTIONS.RESET_MYINFO: {
      const scope = action.scope;
      switch (scope) {
        default:
          return initialState;
        case MYINFO_RESET_ACTION_SCOPE.FULL:
          return omit(state, ['noa', 'hdbOwnership', 'cpf']);
        case MYINFO_RESET_ACTION_SCOPE.BASIC:
          return omit(state, ['name', 'dob', 'sex', 'maritalStatus', 'residentialStatus', 'email']);
      }
    }
    case MYINFO_ACTIONS.SET_USE_PERSON_FULL_DATA:
      return { ...state, usePersonFullData: action.usePersonFullData };
    default:
      return state;
  }
};

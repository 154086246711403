import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { ArrowRight } from 'assets/icons';
import { Button } from 'components/Common';
import { typeScale, colorScale, shadowScale, typography } from 'themes';

const supportingCobalt = 'supporting-cobalt';

const Wrapper = styled(Button)`
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 24px;
  border-radius: ${fromTheme('borders.radiusLarge')};
  background: ${colorScale(supportingCobalt, 10)};
  border: 1px solid ${colorScale(supportingCobalt, 20)};
  box-shadow: ${shadowScale(1)};
  color: ${colorScale(supportingCobalt, 50)};
  justify-content: flex-start;
  overflow: hidden;
`;

const StyledImage = styled.div`
  max-width: 100px;
  max-height: 64px;
  margin-right: 16px;

  > img {
    max-width: 80px;
  }
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BannerTitle = styled.p`
  margin: 0 0 2px 0;
  text-align: left;
  ${typography('body-m-bold')}
`;

const BannerText = styled.p`
  margin: 0;
  text-align: left;
  ${typography('body-s')}
`;

const StyledArrowRight = styled(ArrowRight)`
  font-size: ${typeScale(1)};
  color: ${colorScale(supportingCobalt, 50)};
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-self: center;
  padding-left: 12px;
  margin-left: auto;
`;

export interface IProps {
  title: string;
  text: string;
  toPath: string;
  image: React.ReactElement;
}

export const Banner = ({ title, text, toPath, image }: IProps) => {
  const history = useHistory();
  return (
    <Wrapper onClick={() => history.push(toPath)}>
      <StyledImage>{image}</StyledImage>
      <BannerContent>
        <BannerTitle>{title}</BannerTitle>
        <BannerText>{text}</BannerText>
      </BannerContent>
      <ArrowWrapper>
        <StyledArrowRight />
      </ArrowWrapper>
    </Wrapper>
  );
};

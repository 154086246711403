import React from 'react';
import styled, { css } from 'styled-components';

import { maxWidth, minWidth } from 'themes';

import { IFullButtonProps } from './constants';
import { StyledButton as sharedStyledButton } from './sharedStyles';

const DinnerRollWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${maxWidth(
    'mobile',
    css`
      justify-content: center;
    `
  )}

  > :first-child {
    margin-right: 24px;
  }
`;

const StyledButton = styled(sharedStyledButton)`
  ${maxWidth(
    'mobile',
    css`
      width: 100%;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      min-width: 170px;
    `
  )}
`;

export const DinnerRollCTAButtons = ({
  primaryText,
  primaryOnClick,
  primaryVariant,
  primaryProps,
  secondaryText,
  secondaryOnClick,
  secondaryVariant = 'secondary',
  secondaryProps,
  ...otherProps
}: IFullButtonProps) => {
  return (
    <DinnerRollWrapper data-testid="dinnerroll" {...otherProps}>
      <StyledButton
        onClick={secondaryOnClick}
        variant={secondaryVariant}
        data-testid="cta-secondary"
        {...secondaryProps}
      >
        {secondaryText}
      </StyledButton>
      <StyledButton
        onClick={primaryOnClick}
        variant={primaryVariant}
        data-testid="cta-primary"
        {...primaryProps}
      >
        {primaryText}
      </StyledButton>
    </DinnerRollWrapper>
  );
};

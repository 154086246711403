import {
  PLAN_TYPE,
  PLAN_COLOR,
  FLAT_TYPE,
  FLAT_SIZE,
  LOAN_TYPE,
  HOUSEHOLD_TYPE,
  PAYMENT_METHOD,
} from 'constants/enums';
import { IHousingPlanResponse } from 'constants/responseTypes';
import { IHousingPlan } from 'constants/storeTypes';

export const convertHousingPlanResponse = (housingPlan: IHousingPlanResponse): IHousingPlan => {
  const parsePaymentDetails = ({
    amount,
    paymentMethod,
  }: {
    amount: number;
    paymentMethod: string;
  }) => ({
    amount,
    paymentMethod: PAYMENT_METHOD[paymentMethod],
  });

  return {
    ...housingPlan,
    cashSavings: parseInt(housingPlan.cashSavings),
    cpfOABalance: parseInt(housingPlan.cpfOABalance),
    estimatedFlatBudget: parseInt(housingPlan.estimatedFlatBudget),
    flatValueAtTimeOfPurchase: parseInt(housingPlan.flatValueAtTimeOfPurchase),
    upfrontPaymentInCash: parseInt(housingPlan.upfrontPaymentInCash),
    planType: PLAN_TYPE[housingPlan.planType.id],
    planColor: PLAN_COLOR[housingPlan.planColor.id],
    householdType: HOUSEHOLD_TYPE[housingPlan.householdType.id],
    flatType: FLAT_TYPE[housingPlan.flatType.id],
    flatSize: FLAT_SIZE[housingPlan.flatSize.id],
    loanType: LOAN_TYPE[housingPlan.loanType.id],
    buyerStampDuty: parsePaymentDetails(housingPlan.buyerStampDuty),
    optionFee: parsePaymentDetails(housingPlan.optionFee),
    conveyancingFee: parsePaymentDetails(housingPlan.conveyancingFee),
    propertyAgentCommission: parsePaymentDetails(housingPlan.propertyAgentCommission),
    registrationFee: parsePaymentDetails(housingPlan.registrationFee),
    surveyFee: parsePaymentDetails(housingPlan.surveyFee),
    leaseInEscrowFee: parsePaymentDetails(housingPlan.leaseInEscrowFee),
    mortgageInEscrowFee: parsePaymentDetails(housingPlan.mortgageInEscrowFee),
    stampDutyDeedFee: parsePaymentDetails(housingPlan.stampDutyDeedFee),
    renovationCost: parsePaymentDetails(housingPlan.renovationCost),
    serviceAndConservancyCharges: parsePaymentDetails(housingPlan.serviceAndConservancyCharges),
    annualPropertyTax: parsePaymentDetails(housingPlan.annualPropertyTax),
    mortgageInsurancePremiums: parsePaymentDetails(housingPlan.mortgageInsurancePremiums),
    interestRate: Number(housingPlan.interestRate),
  };
};

import React from 'react';

import MyMoneySenseViewDashboardDesktop from './mymoneysense-view-dashboard-desktop.svg';
import MyMoneySenseViewDashboardMobile from './mymoneysense-view-dashboard-mobile.svg';
import SavingsRatioPage from './savings-ratio-page.svg';
import SavingsTargetsPage from './savings-targets-page.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const MyMoneySenseDashboardDesktopImage = (props: ImageProps) => (
  <img
    {...props}
    src={MyMoneySenseViewDashboardDesktop}
    alt="Screenshot of MyMoneySense desktop view dashboard"
  />
);

export const MyMoneySenseDashboardMobileImage = (props: ImageProps) => (
  <img
    {...props}
    src={MyMoneySenseViewDashboardMobile}
    alt="Screenshot of MyMoneySense mobile view dashboard"
  />
);

export const SavingsRatioPageImage = (props: ImageProps) => (
  <img {...props} src={SavingsRatioPage} alt="Screenshot of Savings Ratio Page on MyMoneySense" />
);

export const SavingsTargetsPageImage = (props: ImageProps) => (
  <img {...props} src={SavingsTargetsPage} alt="Screenshot of Savings Targets Page" />
);

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { RoutedButton } from 'components/Common';
import { typeScale } from 'themes';

const StyledDiv = styled.div<{ stacked: boolean }>`
  display: flex;

  ${ifProp(
    'stacked',
    css`
      flex-direction: column-reverse;
    `
  )}
`;

const StyledButton = styled(RoutedButton)<{ stacked: boolean }>`
  font-size: ${typeScale(-1)};
  ${ifProp(
    'stacked',
    css`
      margin-top: 10px;
    `,
    css`
      margin-right: 20px;
    `
  )}
`;

interface IOwnProps {
  children: React.ReactChild;
  stacked?: boolean;
  className?: string;
  backRoute: string;
  onBack?: () => void;
}

type IProps = IOwnProps;

export class BackButtonWrapper extends React.Component<IProps> {
  public render() {
    const { children, stacked, className, backRoute, onBack } = this.props;

    return (
      <StyledDiv stacked={stacked || false} className={className}>
        <StyledButton
          stacked={stacked || false}
          widthStyle="fluid"
          variant="secondary"
          type="button"
          to={backRoute}
          onClick={onBack}
        >
          Back
        </StyledButton>
        {children}
      </StyledDiv>
    );
  }
}

import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { IButtonVariant } from 'components/Common';
import { StyledButton } from 'components/Composite/CTAButtons/sharedStyles';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

interface IProps {
  route: string;
  variant?: IButtonVariant;
  children?: React.ReactChild;
}

/**
 * Call to action button for use in ArticleTemplate only
 *
 * @param route The relative route to navigate to
 * @param children The element to display in the button, usually plain text
 * @param variant The colour for the button, primary - purple, secondary - white
 */
export const ArticleCTAButton = ({ route, children = 'Back', variant = 'secondary' }: IProps) => {
  const history = useHistory();
  return (
    <Wrapper>
      <StyledButton variant={variant} widthStyle="fluid" onClick={() => history.push(route)}>
        {children}
      </StyledButton>
    </Wrapper>
  );
};

import axios from 'axios';
import { debounce, throttle } from 'lodash';

import { GLOBAL_MODAL } from 'constants/enums';
import { featureDecisions } from 'featureDecisions';
import {
  getHasValidToken,
  getLogoutPageStatusType,
  getTokenExpiry,
  LOGOUT_STATUS_TYPES,
} from 'utilities/sessionUtilities';

const BLOCKOUT_PERIOD_IN_MIN = 5;
const AUTO_RESET_TIMEOUT_IN_MIN = 8;
const TIME_TO_SESSION_EXPIRY_WARNING_IN_MIN = 5;
export const TOKEN_LIFETIME_IN_MIN = 10;
const PAGE_EVENT_DEBOUNCE_IN_MILLIS = 5000;
const REFRESH_THROTTLE_IN_MILLIS = 10000;
export const MINUTE_IN_MS = 1000 * 60;

interface IParams {
  handleAutoLogout?: (logoutType: LOGOUT_STATUS_TYPES, duplicateSession?: boolean) => void;
  showGlobalModal?: (type: GLOBAL_MODAL) => void;
}

export class SessionManager {
  private autoRefreshTimer?: number;
  private autoLogoutTimer?: number;
  private sessionExpiringTimer?: number;
  private wasIdle = false;
  private isSessionActive = false;
  private blockoutTime: number = Date.now();
  private readonly handleAutoLogout: (
    logoutType: LOGOUT_STATUS_TYPES,
    duplicateSession?: boolean
  ) => void;
  private readonly showGlobalModal: (type: GLOBAL_MODAL) => void;

  constructor({ handleAutoLogout = () => null, showGlobalModal = () => null }: IParams = {}) {
    this.handleAutoLogout = handleAutoLogout;
    this.showGlobalModal = showGlobalModal;

    this.handleEvent = debounce(this.handleEvent.bind(this), PAGE_EVENT_DEBOUNCE_IN_MILLIS, {
      leading: true,
      trailing: false,
    }) as typeof this.handleEvent;
    this.clearTimeouts = this.clearTimeouts.bind(this);
    this.resetSessionManager = this.resetSessionManager.bind(this);
    this.resetSessionExpiringTimer = this.resetSessionExpiringTimer.bind(this);
    this.resetAutoLogoutTimer = this.resetAutoLogoutTimer.bind(this);
    this.resetBlockoutTime = this.resetBlockoutTime.bind(this);
    this.refreshSession = throttle(
      this.refreshSession.bind(this),
      REFRESH_THROTTLE_IN_MILLIS
    ) as typeof this.refreshSession;
    this.logout = this.logout.bind(this);
  }

  public async handleEvent() {
    this.wasIdle = false;

    if (!this.isSessionActive) {
      return;
    }

    const hasValidToken = await getHasValidToken();
    const duplicateSession = getLogoutPageStatusType() === LOGOUT_STATUS_TYPES.DUPLICATE_SESSION;
    if (!hasValidToken && !featureDecisions.mockServerResponses) {
      this.logout(
        duplicateSession
          ? LOGOUT_STATUS_TYPES.DUPLICATE_SESSION
          : LOGOUT_STATUS_TYPES.ACCESS_TOKEN_EXPIRED
      );
    }

    if (Date.now() > this.blockoutTime && hasValidToken) {
      await this.resetBlockoutTime();
      await this.refreshSession();
    }

    if (this.autoLogoutTimer) {
      clearTimeout(this.autoLogoutTimer);
      this.resetAutoLogoutTimer();
    }

    if (this.sessionExpiringTimer) {
      clearTimeout(this.sessionExpiringTimer);
      this.resetSessionExpiringTimer();
    }
  }

  public clearTimeouts() {
    if (this.sessionExpiringTimer) {
      clearTimeout(this.sessionExpiringTimer);
      this.sessionExpiringTimer = undefined;
    }

    if (this.autoLogoutTimer) {
      clearTimeout(this.autoLogoutTimer);
      this.autoLogoutTimer = undefined;
    }

    if (this.autoRefreshTimer) {
      clearTimeout(this.autoRefreshTimer);
      this.autoRefreshTimer = undefined;
    }

    this.isSessionActive = false;
  }

  public async resetSessionManager() {
    if (this.autoRefreshTimer) {
      clearTimeout(this.autoRefreshTimer);
    }

    const tokenExpiry = await getTokenExpiry();

    this.isSessionActive = true;
    this.wasIdle = true;
    await this.resetBlockoutTime();

    if (tokenExpiry) {
      const millisToNextRefresh = Math.max(
        0,
        tokenExpiry -
          MINUTE_IN_MS * (TOKEN_LIFETIME_IN_MIN - AUTO_RESET_TIMEOUT_IN_MIN) -
          Date.now()
      );

      this.autoRefreshTimer = window.setTimeout(
        () => !this.wasIdle && this.refreshSession(),
        millisToNextRefresh
      );
    }

    if (!this.autoLogoutTimer) {
      this.resetAutoLogoutTimer();
    }

    if (!this.sessionExpiringTimer) {
      this.resetSessionExpiringTimer();
    }
  }

  private resetSessionExpiringTimer() {
    this.sessionExpiringTimer = window.setTimeout(
      () => this.showGlobalModal(GLOBAL_MODAL.SESSION_EXPIRING),
      MINUTE_IN_MS * (TOKEN_LIFETIME_IN_MIN - TIME_TO_SESSION_EXPIRY_WARNING_IN_MIN)
    );
  }

  private resetAutoLogoutTimer() {
    this.autoLogoutTimer = window.setTimeout(
      () => this.logout(LOGOUT_STATUS_TYPES.INACTIVITY_EXPIRED),
      MINUTE_IN_MS * TOKEN_LIFETIME_IN_MIN
    );
  }

  private async resetBlockoutTime() {
    const tokenExpiry = await getTokenExpiry();

    if (tokenExpiry) {
      this.blockoutTime =
        tokenExpiry - MINUTE_IN_MS * (TOKEN_LIFETIME_IN_MIN - BLOCKOUT_PERIOD_IN_MIN);
    }
  }

  private async refreshSession() {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/refresh`);
      this.resetSessionManager();
    } catch (e) {
      if (e.response.status === 440) {
        this.logout(LOGOUT_STATUS_TYPES.DUPLICATE_SESSION);
      } else {
        this.logout(LOGOUT_STATUS_TYPES.ACCESS_TOKEN_EXPIRED);
      }
    }
  }

  private logout(logoutType: LOGOUT_STATUS_TYPES) {
    this.clearTimeouts();
    this.handleAutoLogout(logoutType);
  }
}

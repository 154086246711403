import React from 'react';

import AnnouncementHighlightByTypeInner from './announcement-highlight-by-type-inner.svg';
import AnnouncementHighlightByTypeOuter from './announcement-highlight-by-type-outer.svg';
import AnnouncementHighlightDetailsInner from './announcement-highlight-details-inner.svg';
import AnnouncementHighlightDetailsOuter from './announcement-highlight-details-outer.svg';
import AnnouncementHighlightSummaryInner from './announcement-highlight-summary-inner.svg';
import AnnouncementHighlightSummaryOuter from './announcement-highlight-summary-outer.svg';
import AnnouncementHighlightsDesktopOuter from './announcement-highlights-desktop-outer.svg';
import GeneralInsurance from './general-insurance.svg';
import Heart from './heart.svg';
import LifeInsurance from './life-insurance.svg';
import MedicalInsurance from './medical-insurance.svg';
import OopsAreYouLost from './oops-are-you-lost.svg';
import Plus from './plus.svg';
import Shield from './shield.svg';

type ImageProps = React.HTMLAttributes<HTMLImageElement>;

export const AnnouncementHighlightsDesktopOuterImage = (props: ImageProps) => (
  <img {...props} src={AnnouncementHighlightsDesktopOuter} alt="" />
);

export const AnnouncementHighlightByTypeInnerImage = (props: ImageProps) => (
  <img {...props} src={AnnouncementHighlightByTypeInner} alt="" />
);
export const AnnouncementHighlightByTypeOuterImage = (props: ImageProps) => (
  <img {...props} src={AnnouncementHighlightByTypeOuter} alt="" />
);

export const AnnouncementHighlightDetailsInnerImage = (props: ImageProps) => (
  <img {...props} src={AnnouncementHighlightDetailsInner} alt="" />
);
export const AnnouncementHighlightDetailsOuterImage = (props: ImageProps) => (
  <img {...props} src={AnnouncementHighlightDetailsOuter} alt="" />
);

export const AnnouncementHighlightSummaryInnerImage = (props: ImageProps) => (
  <img {...props} src={AnnouncementHighlightSummaryInner} alt="" />
);
export const AnnouncementHighlightSummaryOuterImage = (props: ImageProps) => (
  <img {...props} src={AnnouncementHighlightSummaryOuter} alt="" />
);

export const GeneralInsuranceImage = (props: ImageProps) => (
  <img {...props} src={GeneralInsurance} alt="" />
);

export const LifeInsuranceImage = (props: ImageProps) => (
  <img {...props} src={LifeInsurance} alt="" />
);

export const MedicalInsuranceImage = (props: ImageProps) => (
  <img {...props} src={MedicalInsurance} alt="" />
);

export const HeartImage = (props: ImageProps) => <img {...props} src={Heart} alt="" />;

export const PluesImage = (props: ImageProps) => <img {...props} src={Plus} alt="" />;

export const ShieldImage = (props: ImageProps) => <img {...props} src={Shield} alt="" />;

export const OopsAreYouLostImage = (props: ImageProps) => (
  <img {...props} src={OopsAreYouLost} alt="" />
);

import FocusTrap from 'focus-trap-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { getHasToken } from 'redux/dataStorage/selectors';

import { Container } from 'components/Common';
import { maxWidth } from 'themes';

import { FocusTrapContext } from './FocusTrapContext';
import { PostLoginNavigationBar } from './PostLoginNavigationBar';
import { PreLoginNavigationBar } from './PreLoginNavigationBar';

const NavigationBarWrapper = styled.nav`
  grid-area: nav-bar;
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${fromTheme('layers.navbar')};
  height: ${fromTheme('heights.navbar')};

  a {
    display: flex;
    align-items: center;
    height: 100%;
    transition: ${fromTheme('transitions.duration')};
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }
`;

const Wrapper = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  height: ${fromTheme('heights.navbar')};

  ${maxWidth(
    'tablet',
    css`
      padding-right: 0;
    `
  )}
`;

export const NavigationBar = () => {
  const [isFocusTrapped, setIsFocusTrapped] = useState(false);
  const hasToken = useSelector(getHasToken);

  return (
    <FocusTrapContext.Provider value={setIsFocusTrapped}>
      <FocusTrap active={isFocusTrapped}>
        <NavigationBarWrapper>
          <Wrapper>{hasToken ? <PostLoginNavigationBar /> : <PreLoginNavigationBar />}</Wrapper>
        </NavigationBarWrapper>
      </FocusTrap>
    </FocusTrapContext.Provider>
  );
};

import { ReactElement } from 'react';

interface IProps {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
}

export const ConditionalWrapper: React.FC<IProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

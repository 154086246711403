import { uniqBy } from 'lodash';

import { IEmergencyFundForRender, IGoalForRender, IStore } from 'constants/storeTypes';
import { getDateDiffInMonths } from 'utilities/dateUtilities';
import { ascending, descending } from 'utilities/sortingUtilities';

import {
  getAvailableFunds,
  getProjectedAvailableFunds,
  getUnallocatedSavings,
  getTotalCashOutflow,
} from '../finances/selectors';

export const getGoals = (store: IStore): { [id: string]: IGoalForRender } =>
  Object.entries(store.targets.goals).reduce<{ [id: string]: IGoalForRender }>(
    (acc, [key, goal]) => ({
      ...acc,
      [key]: {
        ...goal,
        date: new Date(goal.date),
        paidOutDate: goal.paidOutDate ? new Date(goal.paidOutDate) : null,
      },
    }),
    {}
  );

export const getEmergencyFund = (store: IStore): IEmergencyFundForRender => ({
  ...store.targets.emergencyFund,
  date: new Date(store.targets.emergencyFund.date),
});

/**
 * Return an array of goals sorted by ascending date then descending amount
 * @param store
 */
export const getSortedGoals = (store: IStore): IGoalForRender[] =>
  getSortedGoalsByTotalAmount(store).sort((a, b) => ascending(a.date, b.date));

const getSortedGoalsByTotalAmount = (store: IStore): IGoalForRender[] =>
  Object.values(getGoals(store)).sort((a, b) => descending(a.totalAmount, b.totalAmount));

export const getOngoingGoals = (store: IStore, currentDate: Date = new Date()): IGoalForRender[] =>
  getSortedGoals(store).filter(
    (goal) => getDateDiffInMonths(goal.date, currentDate) >= 0 && !goal.paidOut
  );

/**
 * This was previously getSortedTargets
 */
export const getOngoingTargetsAndEF = (
  store: IStore,
  currentDate: Date = new Date()
): (IEmergencyFundForRender | IGoalForRender)[] => {
  const shouldIncludeEmergencyFund = getEmergencyFund(store).totalAmount > 0;
  return genericGetOngoingTargets(store, currentDate, shouldIncludeEmergencyFund);
};

/* Not for export */
const genericGetOngoingTargets = (
  store: IStore,
  currentDate: Date = new Date(),
  shouldIncludeEmergencyFund: boolean
): (IEmergencyFundForRender | IGoalForRender)[] => {
  const emergencyFund = getEmergencyFund(store);
  const sortedTargets: (IEmergencyFundForRender | IGoalForRender)[] = getOngoingGoals(
    store,
    currentDate
  );
  shouldIncludeEmergencyFund && sortedTargets.unshift(emergencyFund);
  sortedTargets.sort((a, b) => ascending(a.date, b.date));
  return sortedTargets;
};

export const getOngoingTargets = (
  store: IStore,
  currentDate: Date = new Date()
): (IEmergencyFundForRender | IGoalForRender)[] => {
  const emergencyFund = getEmergencyFund(store);
  const shouldIncludeEmergencyFund =
    getDateDiffInMonths(emergencyFund.date, currentDate) >= 0 && emergencyFund.totalAmount > 0;
  return genericGetOngoingTargets(store, currentDate, shouldIncludeEmergencyFund);
};

export function isEF(
  target: IEmergencyFundForRender | IGoalForRender
): target is IEmergencyFundForRender {
  return (target as IEmergencyFundForRender).prevAccumulatedMonths !== undefined;
}

export function isGoal(target: IEmergencyFundForRender | IGoalForRender): target is IGoalForRender {
  return (target as IGoalForRender).id !== undefined;
}

// this is for completed || expired goals
export const getArchivedGoals = (store: IStore, currentDate: Date = new Date()): IGoalForRender[] =>
  getSortedGoalsByTotalAmount(store)
    .filter((goal) => getDateDiffInMonths(goal.date, currentDate) < 0 || goal.paidOut)
    .sort((a, b) => descending(a.paidOutDate, b.paidOutDate));

export const isEFExpiredAndNotAchieved = (
  store: IStore,
  currentDate: Date = new Date()
): boolean => {
  const emergencyFund = getEmergencyFund(store);
  return (
    getDateDiffInMonths(emergencyFund.date, currentDate) < 0 &&
    emergencyFund.currentAmount < emergencyFund.totalAmount
  );
};

export const getExpiredAndNotPaidOutGoals = (
  store: IStore,
  currentDate: Date = new Date()
): IGoalForRender[] =>
  getSortedGoalsByTotalAmount(store)
    .filter((goal) => getDateDiffInMonths(goal.date, currentDate) < 0 && !goal.paidOut)
    .sort((a, b) => descending(a.date, b.date));

export const getOldestExpiredTargetDate = (
  store: IStore,
  currentDate: Date = new Date()
): Date | null => {
  const expiredTargets: (IEmergencyFundForRender | IGoalForRender)[] = getExpiredAndNotPaidOutGoals(
    store,
    currentDate
  );
  const emergencyFund = getEmergencyFund(store);
  isEFExpiredAndNotAchieved(store, currentDate) && expiredTargets.unshift(emergencyFund);
  expiredTargets.sort((a, b) => ascending(a.date, b.date));
  return expiredTargets.length > 0 ? expiredTargets[0].date : null;
};

const getProjectedSavingsNotSpentOnGoals = (
  store: IStore,
  targetDate: Date,
  monthlySavings: number,
  initialDate: Date = new Date()
) => {
  const goals = getOngoingGoals(store, initialDate);
  const goalsWithinTargetRange = goals.filter(
    (goal) =>
      getDateDiffInMonths(goal.date, initialDate) > 0 &&
      getDateDiffInMonths(targetDate, goal.date) >= 0
  );

  let dates = goalsWithinTargetRange.map((goal) => new Date(goal.date));
  dates.push(new Date(targetDate));
  dates = uniqBy(dates, (date) => date.toISOString());

  let dateRangeStart = new Date(initialDate);
  let currentAmountSetAsideForGoals = 0;

  const totalAmountNotGoingToGoals = dates.reduce((sum, date) => {
    const amountCarriedOverToGoals =
      currentAmountSetAsideForGoals -
      totalAmountSpentForTargets(
        goalsWithinTargetRange.filter((goal) => getDateDiffInMonths(date, goal.date) > 0),
        monthlySavings,
        initialDate
      );

    const monthlyAmountGoingToGoals = Math.min(
      monthlySavings,
      getRequiredMonthlySavingsForTargets(store, 1, dateRangeStart, amountCarriedOverToGoals)
    );

    const monthlyAmountNotGoingToGoals = monthlySavings - monthlyAmountGoingToGoals;

    const amountNotGoingToGoals =
      getDateDiffInMonths(date, dateRangeStart) * monthlyAmountNotGoingToGoals;

    currentAmountSetAsideForGoals +=
      getDateDiffInMonths(date, dateRangeStart) * monthlyAmountGoingToGoals;

    dateRangeStart = new Date(date);

    return sum + amountNotGoingToGoals;
  }, 0);

  const amountLeftoverFromGoals =
    goals.length > 0 && getDateDiffInMonths(targetDate, goals[goals.length - 1].date) >= 0
      ? currentAmountSetAsideForGoals -
        totalAmountSpentForTargets(goalsWithinTargetRange, monthlySavings, initialDate)
      : 0;

  return amountLeftoverFromGoals + totalAmountNotGoingToGoals;
};

const getProjectedRemainingSavings = (
  store: IStore,
  date: Date,
  monthlySavings: number,
  initialDate: Date = new Date()
) => {
  const projectedSavingsNotSpentOnGoals = getProjectedSavingsNotSpentOnGoals(
    store,
    date,
    monthlySavings,
    initialDate
  );
  const { totalAmount, currentAmount } = getEmergencyFund(store);

  const remainingSavings = Math.max(
    0,
    projectedSavingsNotSpentOnGoals - (totalAmount - currentAmount)
  );

  return getUnallocatedSavings(store) + remainingSavings;
};

export const getRequiredMonthlySavingsForTargets = (
  store: IStore,
  roundingInterval = 1000,
  initialDate: Date = new Date(),
  amountCarriedOver = 0
): number => {
  const sortedTargetsArray = getOngoingTargets(store, initialDate);
  if (sortedTargetsArray.length === 0) {
    return 0;
  }

  const targetByNoOfMonths = sortedTargetsArray
    .map((target) => {
      return {
        noOfMonths: getDateDiffInMonths(target.date, initialDate),
        amount: target.totalAmount - target.currentAmount,
      };
    })
    .filter((t) => t.noOfMonths > 0);

  const amountsNeeded = [];
  let accumulator = -amountCarriedOver;
  for (const target of targetByNoOfMonths) {
    accumulator += target.amount;
    amountsNeeded.push(Math.max(0, accumulator / target.noOfMonths));
  }

  return Math.ceil(Math.max(...amountsNeeded, 0) / roundingInterval) * roundingInterval;
};

export const areAllGoalsAttainable = (
  store: IStore,
  givenMonthlySavings: number,
  initialDate: Date = new Date()
): boolean => givenMonthlySavings >= getRequiredMonthlySavingsForTargets(store, 1, initialDate);

export const getNumberOfUnattainableTargets = (
  store: IStore,
  givenMonthlySavings: number
): number =>
  getTargetsShortfall(
    store,
    givenMonthlySavings,
    getOngoingTargets(store).map((g) => (isEF(g) ? 'EF' : g.id))
  ).reduce((sum, shortfall) => (sum += shortfall > 0 ? 1 : 0), 0);

const getTargetsShortfall = (
  store: IStore,
  givenMonthlySavings: number,
  ids: string[],
  initialDate: Date = new Date()
): number[] => {
  const targetsArray = getOngoingTargets(store, initialDate);
  const targetsShortfallObject = {};
  const targetSpentArray = amountSpentForEachTarget(targetsArray, givenMonthlySavings, initialDate);
  targetsArray.forEach((target, i) => {
    targetsShortfallObject[isEF(target) ? 'EF' : target.id] =
      target.totalAmount - target.currentAmount - targetSpentArray[i];
  });
  return ids.map((id) => targetsShortfallObject[id]);
};

// The goals must be passed in sorted by ascending date and descending totalAmount for correct calculations
export const totalAmountSpentForTargets = (
  targets: (IEmergencyFundForRender | IGoalForRender)[],
  monthlySavings: number,
  initialDate: Date
) =>
  amountSpentForEachTarget(targets, monthlySavings, initialDate).reduce(
    (sum, spentAmount) => sum + spentAmount,
    0
  );

// The targets must be passed in sorted by ascending date and descending totalAmount for correct calculations
const amountSpentForEachTarget = (
  targets: (IEmergencyFundForRender | IGoalForRender)[],
  monthlySavings: number,
  initialDate: Date
) => {
  const amountSpentArray = [];
  let amountSpentOnPreviousTargets = 0;
  for (const target of targets) {
    const amountAtTargetDate = getDateDiffInMonths(target.date, initialDate) * monthlySavings;
    // assume when target passes you spend everything if you have not enough money
    const amountSpentForTarget = Math.min(
      target.totalAmount - target.currentAmount,
      amountAtTargetDate - amountSpentOnPreviousTargets
    );
    amountSpentArray.push(amountSpentForTarget);

    amountSpentOnPreviousTargets += amountSpentForTarget;
  }

  return amountSpentArray;
};

export const areAllTargetsAttained = (store: IStore, date: Date = new Date()) => {
  const { emergencyFund } = store.targets;
  const goals = getOngoingGoals(store, date);
  return (
    emergencyFund.currentAmount >= emergencyFund.totalAmount &&
    !Object.values(goals).find((goal) => goal.currentAmount < goal.totalAmount)
  );
};

export const getRecommendedDueDate = (
  store: IStore,
  givenMonthlySavings: number,
  goalId: string,
  initialDate: Date = new Date()
) => {
  if (givenMonthlySavings === 0) {
    return null;
  }

  const id = goalId;
  const storeWithProjectedGoal: IStore = {
    ...store,
    targets: {
      ...store.targets,
      goals: {
        ...store.targets.goals,
        [id]: {
          ...store.targets.goals[id],
          date: store.targets.goals[id].date,
        },
      },
    },
  };

  const DATE_FIELD_LAST_DATE = new Date(2058, 11); // from src/components/Common/DateField/DateField.tsx
  const MAX_NUMBER_OF_LOOPS_ALLOWED = 500;
  let hasReachedProjectedGoal = false;
  let numberOfLoopsRemaining = MAX_NUMBER_OF_LOOPS_ALLOWED;

  while (!hasReachedProjectedGoal && numberOfLoopsRemaining > 0) {
    const goalDate = new Date(storeWithProjectedGoal.targets.goals[id].date);

    numberOfLoopsRemaining -= 1;
    if (goalDate > DATE_FIELD_LAST_DATE) {
      numberOfLoopsRemaining = 0;
    }
    const projectedGoalsArray = getProjectedTargets(
      storeWithProjectedGoal,
      givenMonthlySavings,
      initialDate
    ).filter(isGoal);
    const projectedGoal = projectedGoalsArray.find((ele) => ele.id === id);
    if (projectedGoal && projectedGoal.currentAmount === projectedGoal.totalAmount) {
      hasReachedProjectedGoal = true;
    } else {
      goalDate.setMonth(goalDate.getMonth() + 1);
      storeWithProjectedGoal.targets.goals[id].date = goalDate.toISOString();
    }
  }

  if (numberOfLoopsRemaining === 0) {
    return null;
  }

  return new Date(storeWithProjectedGoal.targets.goals[id].date);
};

export const getRecommendedAmount = (
  store: IStore,
  givenMonthlySavings: number,
  goalId: string,
  initialDate: Date = new Date()
) => {
  const projectedGoalsArray = getProjectedTargets(store, givenMonthlySavings, initialDate).filter(
    isGoal
  );
  const projectedGoal = projectedGoalsArray.find((ele) => ele.id === goalId);
  return projectedGoal ? projectedGoal.currentAmount : undefined;
};

export const getProjectedTargets = (
  store: IStore,
  givenMonthlySavings: number,
  initialDate: Date = new Date()
): (IEmergencyFundForRender | IGoalForRender)[] => {
  const ongoingTargets = getOngoingTargets(store, initialDate);
  const lastTargetDate =
    ongoingTargets.length > 0 ? ongoingTargets[ongoingTargets.length - 1].date : new Date();
  return Object.values(
    getProjectedTargetsSnapshot(store, lastTargetDate, givenMonthlySavings, initialDate)
  );
};

export const getRecommendedEmergencyFundMonths = (store: IStore): number => {
  const totalMonthlyOutFlow = getTotalCashOutflow(store, { excludeInvestments: true });
  const amountSetAsideForEF = getEmergencyFund(store).currentAmount;

  return amountSetAsideForEF < 3 * totalMonthlyOutFlow ? 3 : 6;
};

const getProjectedTargetsSnapshot = (
  store: IStore,
  date: Date,
  givenMonthlySavings: number,
  initialDate: Date = new Date()
): (IEmergencyFundForRender | IGoalForRender)[] => {
  const targetsArray: (IEmergencyFundForRender | IGoalForRender)[] = getOngoingTargets(
    store,
    initialDate
  );
  const targetsBeforeDate = targetsArray.filter(
    (target) => getDateDiffInMonths(date, target.date) >= 0
  );
  const targetsAfterDate = targetsArray.filter(
    (target) => getDateDiffInMonths(date, target.date) < 0
  );

  const targetsSnapshot: { [key: string]: IEmergencyFundForRender | IGoalForRender } = {};
  let amountToSpendOnCurrentTargets = 0;
  const targetSpentArray = amountSpentForEachTarget(
    targetsBeforeDate,
    givenMonthlySavings,
    initialDate
  );
  targetsBeforeDate.forEach((target, i) => {
    const totalAmountSpentForTarget = targetSpentArray[i] + target.currentAmount;
    targetsSnapshot[isEF(target) ? 'EF' : target.id] = {
      ...target,
      currentAmount: totalAmountSpentForTarget,
    };
    if (getDateDiffInMonths(date, target.date) === 0) {
      amountToSpendOnCurrentTargets += totalAmountSpentForTarget;
    }
  });

  const amountPreAllocatedToTargetsAfterDate = targetsAfterDate.reduce(
    (sumToSubtract, target) => sumToSubtract + target.currentAmount,
    0
  );
  let amountSetAsideForFutureTargets =
    getProjectedAvailableFunds(store, date, givenMonthlySavings, initialDate) -
    amountToSpendOnCurrentTargets -
    amountPreAllocatedToTargetsAfterDate -
    getProjectedRemainingSavings(store, date, givenMonthlySavings, initialDate);
  for (const target of targetsAfterDate) {
    const targetAmount = target.totalAmount - target.currentAmount;
    const amountSetAsideForTarget = Math.min(targetAmount, amountSetAsideForFutureTargets);
    targetsSnapshot[isEF(target) ? 'EF' : target.id] = {
      ...target,
      currentAmount: amountSetAsideForTarget + target.currentAmount,
    };
    amountSetAsideForFutureTargets -= amountSetAsideForTarget;
  }

  return Object.values(targetsSnapshot)
    .sort((a, b) => descending(a.totalAmount, b.totalAmount))
    .sort((a, b) => ascending(a.date, b.date));
};

interface IEmergencyFundWithReallocatedAmount extends IEmergencyFundForRender {
  reallocatedAmount: number;
}

interface IGoalWithReallocatedAmount extends IGoalForRender {
  reallocatedAmount: number;
}

export const getReallocatedTargets = (
  store: IStore,
  newAvailableFunds: number
): (IEmergencyFundWithReallocatedAmount | IGoalWithReallocatedAmount)[] => {
  const ongoingTargets = getOngoingTargetsAndEF(store); // need to include EF even if expired

  const prevAvailableFunds = getAvailableFunds(store);

  // note: cannot use Math.max(getUnallocatedSavings(store), 0) because unallocatedSavings is a derived value
  const unallocatedSavings = getUnallocatedSavings(store);

  const reallocatedTargets = ongoingTargets.map((target) => ({
    ...target,
    reallocatedAmount: target.currentAmount, //initialize
  }));

  if (prevAvailableFunds === null || prevAvailableFunds === 0) {
    return reallocatedTargets; //nothing to compare
  }

  const deltaAvailableFunds = newAvailableFunds + unallocatedSavings - prevAvailableFunds;

  if (deltaAvailableFunds > 0) {
    let remainingAvailableFundsToBeAllocated = deltaAvailableFunds;
    return reallocatedTargets.map((target) => {
      //if nothing further to allocate
      if (remainingAvailableFundsToBeAllocated <= 0) {
        return target;
      }

      //if target has been achieved
      const { totalAmount, currentAmount } = target;
      if (totalAmount === currentAmount) {
        return target;
      }

      const remainingRequiredForTarget = totalAmount - currentAmount;

      const newCurrentAmount =
        Math.min(remainingAvailableFundsToBeAllocated, remainingRequiredForTarget) + currentAmount;
      remainingAvailableFundsToBeAllocated -= remainingRequiredForTarget;

      return {
        ...target,
        reallocatedAmount: newCurrentAmount,
      };
    });
  } else if (deltaAvailableFunds < 0) {
    let remainingAvailableFundsToBeDeducted = Math.abs(deltaAvailableFunds);

    return reallocatedTargets
      .reverse()
      .map((target) => {
        //if nothing further to deduct
        if (remainingAvailableFundsToBeDeducted <= 0) {
          return target;
        }

        const { currentAmount } = target;
        const newCurrentAmount = Math.max(0, currentAmount - remainingAvailableFundsToBeDeducted);
        remainingAvailableFundsToBeDeducted -= currentAmount;
        return {
          ...target,
          reallocatedAmount: newCurrentAmount,
        };
      })
      .reverse();
  } else {
    return reallocatedTargets;
  }
};

export const getNumEmergencyFundMonths = (store: IStore): number => {
  const numMonthsOfEmergencyFund =
    getEmergencyFund(store).currentAmount /
    getTotalCashOutflow(store, { excludeInvestments: true });

  return isNaN(numMonthsOfEmergencyFund) ? 0 : numMonthsOfEmergencyFund;
};

export const getNumAvailableFundsMonths = (store: IStore): number => {
  const numMonthsOfAvailableFund =
    getAvailableFunds(store) / getTotalCashOutflow(store, { excludeInvestments: true });

  return isNaN(numMonthsOfAvailableFund) ? 0 : numMonthsOfAvailableFund;
};

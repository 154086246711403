import moment from 'moment';
import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { setApimData } from 'redux/apim/actions';
import {
  createIncome,
  setLoanRepayments,
  setInvestment,
  setLiabilities,
  setDayOfTransfer,
  setTargetMonthlySavings,
  setAutomaticTransferStatus,
  setEstimatedMonthlyExpenses,
  createExpense,
  setCpfAsset,
  setCashBalanceAsset,
  setCashSavingsAsset,
  setBondAsset,
  setGoldAsset,
  setFixedDepositAsset,
  setInsuranceAsset,
  setStockAsset,
  setStructuredProductAsset,
  setUnitTrustAsset,
  setOtherInvestmentAsset,
  setOtherNonInvestmentAsset,
  setTotalBudget,
} from 'redux/finances/actions';
import { ThunkAppDispatch } from 'redux/hook';

import { ISetHasToken, IClearReduxData } from 'constants/actionTypes';
import {
  DATA_STORAGE_ACTIONS,
  TIME_INTERVAL,
  NOTIFICATION_CATEGORY,
  NOTIFICATION_TYPE,
  LIABILITY_TYPE,
  ASSET_TYPE,
  MARITAL_STATUS,
  SEX,
  RESIDENTIAL_STATUS,
  AGE_RANGE,
  WORK_YEARS_RANGE,
  EMPLOYMENT_STATUS_RANGE,
  AUTOMATIC_TRANSFER_STATUS,
  MAIN_MODULE,
  INCOME_TYPE,
  INVESTMENT_SOURCE,
  ENTER_OPTION,
  APIM_MYINFO_DATA_SELECTION_STATE,
  ACCOUNT_STATUS,
  EMAIL_VERIFICATION_STATUS,
} from 'constants/enums';
import { IUserData } from 'constants/responseTypes';
import { IStore, IFxRate } from 'constants/storeTypes';
import { descending } from 'utilities/sortingUtilities';

import { setRecommendedActions } from '../financialStatus/actions';
import { setFxRates } from '../fxRates/actions';
import { setCurrentStepIndex, setLastUpdatedDate, setLastCompletedDate } from '../modules/actions';
import { setMyinfo } from '../myinfo/actions';
import { createNotification, acknowledgeNotification } from '../notifications/actions';
import { updateProfile } from '../profile/slice';
import { createGoal, setEmergencyFund } from '../targets/actions';
import { setUserHistory } from '../userHistory/actions';

export const setHasToken = (hasToken: boolean): ISetHasToken => ({
  type: DATA_STORAGE_ACTIONS.SET_HAS_TOKEN,
  hasToken,
});

export const clearReduxData = (): IClearReduxData => ({
  type: DATA_STORAGE_ACTIONS.CLEAR_REDUX_DATA,
});

export const hydrateApp: ActionCreator<ThunkAction<
  void,
  IStore,
  undefined,
  AnyAction
>> = (dataResponse: { user: IUserData }) => (dispatch) => {
  const { user } = dataResponse;
  [
    loadDateStringsFromUserData,
    loadUserEmailVerificationStatusFromData,
    loadUserEmailNotificationsStatusFromData,
    loadUserIdFromData,
    loadNameFromUserData,
    loadSexFromUserData,
    loadMaritalStatusFromUserData,
    loadResidentialStatusFromUserData,
    loadEmailFromUserData,
    loadAgeRangeFromUserData,
    loadWorkYearsRangeFromUserData,
    loadEmploymentStatusRangeFromUserData,
    loadHasStartedPlanningFromUserData,
    loadIncomeListFromUserData,
    loadDayOfTransferFromUserData,
    loadTargetMonthlySavingsFromUserData,
    loadAutomaticTransferStatusFromUserData,
    loadEstimatedMonthlyExpensesFromUserData,
    loadExpensesFromUserData,
    loadGoalsFromUserData,
    loadEmergencyFundFromUserData,
    loadModuleStatusesFromUserData,
    loadNotificationsFromUserData,
    loadHasCompletedFeedbackFormFromUserData,
    loadLoanRepaymentsFromUserData,
    loadInvestmentsFromUserData,
    loadLiabilitiesFromUserData,
    loadApimFromUserData,
    loadMyinfoFromUserData,
    loadRecommendedActionsFromUserData,
    loadUserHistoryFromUserData,
    loadCpfAssetsFromUserData,
    loadCashBalanceAssetsFromUserData,
    loadCashSavingAssetsFromUserData,
    loadBondAssetsFromUserData,
    loadGoldAssetsFromUserData,
    loadFixedDepositAssetsFromUserData,
    loadInsuranceAssetsFromUserData,
    loadStockAssetsFromUserData,
    loadStructuredProductAssetsFromUserData,
    loadUnitTrustAssetsFromUserData,
    loadOtherInvestmentAssetsFromUserData,
    loadOtherNonInvestmentAssetsFromUserData,
    loadTotalBudgetFromUserData,
    loadApimMyinfoDataSelectionStateFromUserData,
    loadAccountStatusFromUserData,
    loadIsReturnUserFromUserData,
    loadDeferEmailVerificationFromUserData,
  ].forEach((loadFunction: (user: IUserData, dispatch: ThunkAppDispatch) => void) => {
    try {
      loadFunction(user, dispatch);
    } catch (e) {
      throw new Error(`Hydration failed with ${loadFunction.name}`);
    }
  });
};

export const loadDateStringsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) => {
  const dateStringToDateValues = {
    ...(user.dob && { dob: new Date(user.dob) }),
    ...(user.apimLatestPullConsentAtDate && {
      apimLatestPullConsentAt: new Date(user.apimLatestPullConsentAtDate),
    }),
    ...(user.myinfoLatestPullConsentAtDate && {
      myinfoLatestPullConsentAt: new Date(user.myinfoLatestPullConsentAtDate),
    }),
    ...(user.consentedToAppTNCAt && {
      consentedToAppTNCAt: new Date(user.consentedToAppTNCAt),
    }),
    ...(user.latestConsentedToAppNOCAt && {
      latestConsentedToAppNOCAt: new Date(user.latestConsentedToAppNOCAt),
    }),
    ...(user.lastLogin && { lastLogin: new Date(user.lastLogin) }),
  };
  dispatch(updateProfile(dateStringToDateValues));
};

const loadUserEmailVerificationStatusFromData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.emailVerificationStatus &&
  dispatch(
    updateProfile({
      emailVerificationStatus:
        (user.emailVerificationStatus?.id as EMAIL_VERIFICATION_STATUS) || null,
    })
  );

const loadUserEmailNotificationsStatusFromData = (user: IUserData, dispatch: ThunkAppDispatch) => {
  return (
    user.emailNotificationsStatus &&
    dispatch(updateProfile({ emailNotificationsStatus: user.emailNotificationsStatus }))
  );
};

const loadUserIdFromData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.id && dispatch(updateProfile({ userId: user.id }));

const loadNameFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.name && dispatch(updateProfile({ name: user.name }));

const loadSexFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.sex && dispatch(updateProfile({ sex: SEX[user.sex] }));

const loadMaritalStatusFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.maritalStatus &&
  dispatch(updateProfile({ maritalStatus: MARITAL_STATUS[user.maritalStatus] }));

const loadResidentialStatusFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.residentialStatus &&
  dispatch(updateProfile({ residentialStatus: RESIDENTIAL_STATUS[user.residentialStatus] }));

const loadEmailFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.email && dispatch(updateProfile({ email: user.email }));

const loadAgeRangeFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.ageRange && dispatch(updateProfile({ ageRange: user.ageRange.id as AGE_RANGE }));

const loadApimMyinfoDataSelectionStateFromUserData = (
  user: IUserData,
  dispatch: ThunkAppDispatch
) =>
  user.apimMyinfoDataSelectionState &&
  dispatch(
    updateProfile({
      apimMyinfoDataSelectionState: user.apimMyinfoDataSelectionState
        .id as APIM_MYINFO_DATA_SELECTION_STATE,
    })
  );

export const loadAccountStatusFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) => {
  user.lastAccountStatus &&
    dispatch(
      updateProfile({
        accountStatus: {
          status: user.lastAccountStatus.accountStatus.id as ACCOUNT_STATUS,
          createdAt: user.lastAccountStatus.updatedAt,
        },
      })
    );
};

const loadIsReturnUserFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  dispatch(updateProfile({ isReturnUser: user.isReturnUser }));

const loadDeferEmailVerificationFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  dispatch(updateProfile({ deferEmailVerification: user.deferEmailVerification }));

const loadWorkYearsRangeFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.workYearsRange &&
  dispatch(updateProfile({ workYearsRange: user.workYearsRange.id as WORK_YEARS_RANGE }));

const loadEmploymentStatusRangeFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.employmentStatusRange &&
  dispatch(
    updateProfile({
      employmentStatusRange: user.employmentStatusRange.id as EMPLOYMENT_STATUS_RANGE,
    })
  );

const loadHasStartedPlanningFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.hasStartedPlanning !== null &&
  dispatch(updateProfile({ hasStartedPlanning: user.hasStartedPlanning }));

const loadIncomeListFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.incomeList.forEach((income) =>
    dispatch(
      createIncome({ ...income, type: { ...income.type, id: income.type.id as INCOME_TYPE } })
    )
  );

const loadLoanRepaymentsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.loanRepaymentList &&
  dispatch(
    setLoanRepayments(
      user.loanRepaymentList.map((lr) => ({
        ...lr,
        type: { ...lr.type, id: lr.type.id as LIABILITY_TYPE },
      }))
    )
  );

const loadInvestmentsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.investments &&
  user.investments.forEach((investment) =>
    dispatch(
      setInvestment({
        ...investment,
        assetType: investment.assetType as ASSET_TYPE,
      })
    )
  );

const loadLiabilitiesFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.liabilities &&
  dispatch(
    setLiabilities(
      user.liabilities.reduce(
        (liabilityObject, liability) => ({
          ...liabilityObject,
          [liability.id]: {
            ...liability,
            category: LIABILITY_TYPE[liability.category.id],
          },
        }),
        {}
      )
    )
  );

const loadMyinfoFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.myinfo && dispatch<any>(setMyinfo(user.myinfo));

const loadDayOfTransferFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.dayOfTransfer !== null && dispatch(setDayOfTransfer(user.dayOfTransfer));

const loadTargetMonthlySavingsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.targetMonthlySavings !== undefined &&
  dispatch(setTargetMonthlySavings(user.targetMonthlySavings.targetMonthlySavings));

const loadAutomaticTransferStatusFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.automaticTransferStatus &&
  dispatch(
    setAutomaticTransferStatus(user.automaticTransferStatus.id as AUTOMATIC_TRANSFER_STATUS)
  );

const loadEstimatedMonthlyExpensesFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.estimatedMonthlyExpenses !== undefined &&
  !user.estimatedMonthlyExpenses.isDerived &&
  dispatch(setEstimatedMonthlyExpenses(user.estimatedMonthlyExpenses.amount || 0));

const loadExpensesFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.expenses.forEach((expense) => {
    const { id, category, timeInterval, amount, budgetAmount, budgetTimeInterval } = expense;

    dispatch(
      createExpense({
        id,
        category,
        timeInterval: Number(TIME_INTERVAL[timeInterval.id]),
        amount,
        budgetAmount,
        budgetTimeInterval: Number(TIME_INTERVAL[budgetTimeInterval.id]),
      })
    );
  });

const loadGoalsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.goals.forEach((goal) => dispatch(createGoal(goal)));

const loadEmergencyFundFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) => {
  if (user.emergencyFund) {
    const { totalAmount, currentAmount, date, prevAccumulatedMonths } = user.emergencyFund;
    dispatch(
      setEmergencyFund({
        totalAmount,
        currentAmount,
        date,
        prevAccumulatedMonths,
      })
    );
  }
};

const loadModuleStatusesFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.moduleStatuses.forEach((moduleStatus) => {
    const { module, currentStep, lastUpdated, lastCompleted } = moduleStatus;

    dispatch(setCurrentStepIndex(module.id as MAIN_MODULE, currentStep));
    lastUpdated && dispatch(setLastUpdatedDate(module.id as MAIN_MODULE, new Date(lastUpdated)));
    lastCompleted &&
      dispatch(setLastCompletedDate(module.id as MAIN_MODULE, new Date(lastCompleted)));
  });

const loadNotificationsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) => {
  user.personalNotifications.forEach((notification) => {
    dispatch(
      createNotification({
        ...notification,
        type: NOTIFICATION_TYPE.PERSONAL_UPDATES,
        category: NOTIFICATION_CATEGORY.UPDATES,
      })
    );
  });

  user.globalNotifications.forEach((notification) => {
    const { acknowledgements, ...otherProps } = notification;
    const acknowledged = acknowledgements.length > 0;
    dispatch(
      createNotification({
        ...otherProps,
        type: NOTIFICATION_TYPE.GLOBAL_UPDATES,
        category: NOTIFICATION_CATEGORY.UPDATES,
        acknowledged,
      })
    );
  });

  user.todoNotificationAcknowledgements.forEach((acknowledgement) => {
    const { notificationKey } = acknowledgement;
    dispatch(acknowledgeNotification(notificationKey, NOTIFICATION_TYPE.TODO));
  });

  user.inbuiltNotificationAcknowledgements.forEach((acknowledgement) => {
    const { notificationKey } = acknowledgement;
    dispatch(acknowledgeNotification(notificationKey, NOTIFICATION_TYPE.INBUILT_UPDATES));
  });
};

const loadHasCompletedFeedbackFormFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  dispatch(updateProfile({ hasCompletedFeedbackForm: user.hasCompletedFeedbackForm }));

const loadApimFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.apimInfo && dispatch(setApimData(user.apimInfo));

interface IFxRates {
  [ccy: string]: IFxRate[];
}

export const loadFxRates: ActionCreator<ThunkAction<void, IStore, undefined, AnyAction>> = (
  fxRateResponses: IFxRate[]
) => (dispatch) => {
  const fxRates = fxRateResponses.reduce<IFxRates>((acc, fxRateResponse) => {
    const { currencyCode, exchangeRate, retrievedDate } = fxRateResponse;

    return {
      ...acc,
      [currencyCode]: [
        ...(acc[currencyCode] || []),
        {
          currencyCode,
          retrievedDate,
          exchangeRate: Number(exchangeRate),
        },
      ],
    };
  }, {});

  Object.values(fxRates).forEach((f) =>
    f.sort((a, b) => descending(new Date(a.retrievedDate), new Date(b.retrievedDate)))
  );

  dispatch(setFxRates(fxRates));
};

const loadRecommendedActionsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.recommendedActions &&
  dispatch(
    setRecommendedActions(
      user.recommendedActions.reduce(
        (acc, recommendedAction) => ({
          ...acc,
          [recommendedAction.id]: {
            ...recommendedAction,
            key: recommendedAction.key.id,
            completedAt: recommendedAction.completedAt
              ? new Date(recommendedAction.completedAt)
              : null,
            completedBy: recommendedAction.completedBy?.id || null,
          },
        }),
        {}
      )
    )
  );

const loadUserHistoryFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.history &&
  dispatch(
    setUserHistory(
      Object.entries(user.history).reduce(
        (acc, [versionAt, user]) => ({
          ...acc,
          [moment(versionAt).format('YYYY-MM-DD')]: user,
        }),
        {}
      )
    )
  );

const loadCpfAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  dispatch(setCpfAsset({ ...user.cpfAsset, type: ASSET_TYPE.CPF }));

const loadCashBalanceAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.cashBalanceAssets.forEach((cashBalanceAsset) =>
    dispatch(
      setCashBalanceAsset({
        ...cashBalanceAsset,
        type: ASSET_TYPE.CASH_BALANCE,
        investmentSource: cashBalanceAsset.investmentSource as INVESTMENT_SOURCE,
      })
    )
  );

const loadCashSavingAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.cashSavingsAssets.forEach((cashSavingsAsset) =>
    dispatch(
      setCashSavingsAsset({
        ...cashSavingsAsset,
        type: ASSET_TYPE.CASH_SAVINGS,
      })
    )
  );

const loadBondAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.bondAssets.forEach((bondAsset) =>
    dispatch(
      setBondAsset({
        ...bondAsset,
        type: ASSET_TYPE.BOND,
        investmentSource: bondAsset.investmentSource as INVESTMENT_SOURCE,
        enterOption: bondAsset.enterOption as ENTER_OPTION,
      })
    )
  );

const loadGoldAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.goldAssets.forEach((goldAsset) =>
    dispatch(
      setGoldAsset({
        ...goldAsset,
        type: ASSET_TYPE.GOLD,
        investmentSource: goldAsset.investmentSource as INVESTMENT_SOURCE,
        enterOption: goldAsset.enterOption as ENTER_OPTION,
      })
    )
  );

const loadFixedDepositAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.fixedDepositAssets.forEach((fixedDepositAsset) =>
    dispatch(
      setFixedDepositAsset({
        ...fixedDepositAsset,
        type: ASSET_TYPE.FIXED_DEPOSIT,
        investmentSource: fixedDepositAsset.investmentSource as INVESTMENT_SOURCE,
      })
    )
  );

const loadInsuranceAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.insuranceAssets.forEach((insuranceAsset) =>
    dispatch(
      setInsuranceAsset({
        ...insuranceAsset,
        type: ASSET_TYPE.INSURANCE,
        investmentSource: insuranceAsset.investmentSource as INVESTMENT_SOURCE,
      })
    )
  );

const loadStockAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.stockAssets.forEach((stockAsset) =>
    dispatch(
      setStockAsset({
        ...stockAsset,
        type: ASSET_TYPE.STOCK,
        investmentSource: stockAsset.investmentSource as INVESTMENT_SOURCE,
        enterOption: stockAsset.enterOption as ENTER_OPTION,
      })
    )
  );

const loadStructuredProductAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.structuredProductAssets.forEach((structuredProductAsset) =>
    dispatch(
      setStructuredProductAsset({
        ...structuredProductAsset,
        type: ASSET_TYPE.STRUCTURED_PRODUCT,
        investmentSource: structuredProductAsset.investmentSource as INVESTMENT_SOURCE,
      })
    )
  );

const loadUnitTrustAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.unitTrustAssets.forEach((unitTrustAsset) =>
    dispatch(
      setUnitTrustAsset({
        ...unitTrustAsset,
        type: ASSET_TYPE.UNIT_TRUST,
        investmentSource: unitTrustAsset.investmentSource as INVESTMENT_SOURCE,
        enterOption: unitTrustAsset.enterOption as ENTER_OPTION,
      })
    )
  );

const loadOtherInvestmentAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.otherInvestmentAssets.forEach((otherInvestmentAsset) =>
    dispatch(
      setOtherInvestmentAsset({
        ...otherInvestmentAsset,
        type: ASSET_TYPE.OTHER_INVESTMENT,
        investmentSource: otherInvestmentAsset.investmentSource as INVESTMENT_SOURCE,
        enterOption: otherInvestmentAsset.enterOption as ENTER_OPTION,
      })
    )
  );

const loadOtherNonInvestmentAssetsFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) =>
  user.otherNonInvestmentAssets.forEach((otherNonInvestmentAsset) =>
    dispatch(
      setOtherNonInvestmentAsset({
        ...otherNonInvestmentAsset,
        type: ASSET_TYPE.OTHER_NON_INVESTMENT,
      })
    )
  );

const loadTotalBudgetFromUserData = (user: IUserData, dispatch: ThunkAppDispatch) => {
  if (user.totalBudget) {
    const { amount } = user.totalBudget;
    dispatch(setTotalBudget(amount));
  }
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { Button, Panel } from 'components/Common';
import { minWidth, typeScale, typography } from 'themes';

const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${minWidth(
    'largeScreen',
    css`
      flex-direction: row;
      justify-content: space-between;
      padding: 24px;
    `
  )}
`;

const TitleWrapper = styled.section`
  display: flex;
  column-gap: 8px;
`;

const Pill = styled.div`
  ${typography('label-bold')}
  color: white;
  background: linear-gradient(
    102.98deg,
    #5572f6 -3.25%,
    #6361d1 39.08%,
    #8861d1 84.74%,
    #dd74c4 108.16%
  );
  border-radius: ${fromTheme('borders.radiusRound')};
  margin: 4px 0;
  height: fit-content;
  width: fit-content;
  padding: 4px 8px;

  ${minWidth(
    'desktop',
    css`
      margin: auto 0;
      padding: 2px 8px;
    `
  )}
`;

const Title = styled.div`
  ${typography('heading-s')}
`;

const Description = styled.div`
  ${typography('body-m')}
  margin-top: 8px;
`;

const ButtonGroupWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  margin-top: 16px;

  button:first-child {
    ${typography('body-m')}
  }

  ${minWidth(
    'largeScreen',
    css`
      margin-top: 0;
      align-items: center;
      button:last-child {
        font-size: ${typeScale(0)};
        width: max-content;
      }
    `
  )}
`;

type TStatus = 'NEW';
interface IAnnouncementCard {
  title: string;
  description: string;
  status?: TStatus;
  handleDismiss: () => void;
  nextPath: string;
}

export const AnnouncementCard = ({
  title,
  description,
  status,
  nextPath,
  handleDismiss,
  ...otherProps
}: IAnnouncementCard) => {
  const history = useHistory();

  return (
    <StyledPanel {...otherProps}>
      <div>
        <TitleWrapper>
          <Title>{title}</Title>
          {status && <Pill>{status}</Pill>}
        </TitleWrapper>
        <Description>{description}</Description>
      </div>
      <ButtonGroupWrapper>
        <Button variant={'tertiary'} widthStyle={'elastic'} onClick={() => handleDismiss()}>
          Dismiss
        </Button>
        <Button variant={'secondary'} onClick={() => history.push(nextPath)}>
          Learn More
        </Button>
      </ButtonGroupWrapper>
    </StyledPanel>
  );
};

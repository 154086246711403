import { Image } from '@react-pdf/renderer';
import React from 'react';

import SampleBreakdownTableImg from 'assets/PDF/SampleBreakdownTable.png';

import { BasePage } from '../BasePage/BasePage';

export const SampleBreakdownPage = () => (
  <BasePage title="INSTRUCTION" isSamplePage={true}>
    <Image src={SampleBreakdownTableImg} />
  </BasePage>
);

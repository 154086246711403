import React from 'react';
import MediaQuery from 'react-responsive';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Heading } from 'components/Common';
import { TOP_GAP_PX, TOP_GAP_PX_DESKTOP } from 'components/Templates';
import { maxWidth, minWidth, theme, typography, breakpoints } from 'themes';

export const IMAGE_DIMENSION_SMALL_PX = '96px';
export const IMAGE_MAX_WIDTH_PX_DESKTOP = '200px';
const HEADER_CONTENT_GAP_PX = '16px';

const HeaderWrapper = styled.div`
  display: grid;

  ${maxWidth(
    'tablet',
    css`
      grid-template-columns: auto auto;
      justify-content: space-between;
      align-items: center;
      margin-top: -${TOP_GAP_PX};
    `
  )}

  ${minWidth(
    'desktop',
    css`
      grid-template-columns: 1fr;

      position: sticky;
      top: calc(${theme.heights.navbar} + ${TOP_GAP_PX_DESKTOP});
    `
  )}
`;

/** Mobile/Tablet Only Components */

const TitleDescriptionWrapper = styled.div`
  margin: 16px 16px ${HEADER_CONTENT_GAP_PX} 0;
`;

const Title = styled(Heading)`
  ${typography('heading-l')}
`;

const Description = styled.p`
  ${typography('body-l')}
  margin: 16px 0 0;
`;

const MobileImage = styled.div<{ hasDescription: boolean }>`
  img {
    width: 100%;
  }

  width: ${IMAGE_DIMENSION_SMALL_PX};
  height: ${IMAGE_DIMENSION_SMALL_PX};
  margin: 12px 0 4px;

  ${ifProp(
    'hasDescription',
    css`
      margin-bottom: ${HEADER_CONTENT_GAP_PX};
      align-self: center;
    `
  )}
`;

/** Desktop Only Components */

const DesktopImage = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: ${IMAGE_MAX_WIDTH_PX_DESKTOP};
  }
`;

interface IProps {
  title: string;
  description?: string;
  image: React.ReactElement | { mobile: React.ReactElement; desktop: React.ReactElement };
}

/**
 * On desktop, renders a sticky image that is horizontally centered.
 * On mobile, renders a title and description (if provided) side by side with an image.
 *
 * @param title Title of the page
 * @param [description] Description of what the page is about
 * @param image Determines what image to render on mobile and desktop.
 */
export const BannerHeader = ({ title, description, image }: IProps) => {
  let mobileImage;
  let desktopImage;

  if (React.isValidElement(image)) {
    mobileImage = image;
    desktopImage = image;
  } else {
    const { mobile, desktop } = image;
    mobileImage = mobile;
    desktopImage = desktop;
  }

  return (
    <HeaderWrapper>
      <MediaQuery maxWidth={breakpoints.tabletMax}>
        <TitleDescriptionWrapper>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </TitleDescriptionWrapper>

        <MobileImage hasDescription={Boolean(description)}>{mobileImage}</MobileImage>
      </MediaQuery>

      <MediaQuery minWidth={breakpoints.desktopMin}>
        <DesktopImage>{desktopImage}</DesktopImage>
      </MediaQuery>
    </HeaderWrapper>
  );
};

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { ITotalDebtServicingRatioInputs } from '../interfaces';

export const TDSR1: IRule<ITotalDebtServicingRatioInputs> = {
  condition: ({ totalDebtServicingRatio }: ITotalDebtServicingRatioInputs) =>
    totalDebtServicingRatio !== null &&
    totalDebtServicingRatio > 0 &&
    totalDebtServicingRatio <= 0.35,
  result: {
    key: RULE.TDSR1,
    indicatorStatus: indicatorStatuses.GOOD,
    recommendedActions: [recommendedActions.TRA_TDSR_A],
    resources: [
      // Some links
    ],
  },
};

export enum MYINFO_NOA_INCOME_TYPE {
  employment = 'Employment',
  trade = 'Trade',
  rent = 'Rent',
  interest = 'Interest',
}

export enum MYINFO_NOA_NOTICE_TYPE {
  ORIGINAL = 'Notice of Original Assessment',
  AMENDED = 'Notice of Amended Assessment',
  ADDITIONAL = 'Notice of Additional Assessment',
  REPAYMENT = 'Notice of Repayment',
}

export enum TIME_INTERVAL {
  MONTHLY = 1,
  YEARLY = 12,
}

export enum AUTOMATIC_TRANSFER_STATUS {
  DONE = 'DONE',
  NOT_NEEDED = 'NOT_NEEDED',
}

export enum OUTFLOW_TYPE {
  EXPENSES = 'expenses',
  LOAN_REPAYMENTS = 'loan-repayments',
  INVESTMENTS = 'investments',
  MORTGAGE = 'mortgage',
  NON_MORTGAGE = 'non-mortgage',
}

export enum EXPENSES {
  TOTAL_EXPENSES = 'Total expenses',
  CATEGORISED_EXPENSES = 'Categorised expenses',
}

export enum INCOME_TYPE {
  EMPLOYMENT = 'EMPLOYMENT',
  OTHERS = 'OTHERS',
}

export enum INCOME_NAME {
  EMPLOYMENT = 'Employment',
  OTHERS = 'Other sources',
}

export enum ASSET_TYPE {
  CASH_BALANCE = 'CASH_BALANCE',
  CASH_AND_NEAR_CASH = 'CASH_AND_NEAR_CASH',
  CASH_SAVINGS = 'CASH_SAVINGS',
  INVESTMENTS = 'INVESTMENTS',
  CPF = 'CPF',
  CPF_OA = 'CPF_OA',
  CPF_SA = 'CPF_SA',
  CPF_MA = 'CPF_MA',
  CPF_RA = 'CPF_RA',
  BOND = 'BOND',
  FIXED_DEPOSIT = 'FIXED_DEPOSIT',
  GOLD = 'GOLD',
  INSURANCE = 'INSURANCE',
  STOCK = 'STOCK',
  STRUCTURED_PRODUCT = 'STRUCTURED_PRODUCT',
  UNIT_TRUST = 'UNIT_TRUST',
  OTHER_INVESTMENT = 'OTHER_INVESTMENT',
  OTHER_NON_INVESTMENT = 'OTHER_NON_INVESTMENT',
  OTHER_ASSETS = 'OTHER_ASSETS',
}

export enum ASSET_NAME {
  CASH_SAVINGS = 'Cash savings',
  INVESTMENTS = 'Investments',
  CPF = 'CPF',
  OTHER_ASSETS = 'Other assets',
}

export enum INVESTMENT_TYPE {
  NEAR_CASH = 'NEAR_CASH',
  OTHER = 'OTHER',
}

export enum LIABILITY_TYPE {
  MORTGAGE = 'MORTGAGE',
  NON_MORTGAGE = 'NON_MORTGAGE',
}

export enum LIABILITY_NAME {
  MORTGAGE = 'Mortgage',
  NON_MORTGAGE = 'Non-mortgage',
}

export enum INVESTMENT_SOURCE {
  CASH = 'CASH',
  CPFIS = 'CPFIS',
  SRS = 'SRS',
}

export enum ENTER_OPTION {
  TOTAL_VALUE = 'TOTAL_VALUE',
  UNIT_PRICE = 'UNIT_PRICE',
}

export enum CDP_SECURITY_TYPE_CODE {
  CORPORATE_DEBT = 0,
  TBILL = 8,
  TBOND = 9,
  STRUCTURED_WARRANTS = 17,
  ABC_SHARES = 18,
  DEPOSITORY_RECEIPTS = 19,
  LOCAL_EXCHANGE_TRADED_FUNDS = 20,
  FOREIGN_OWNED = 21,
  LOAN_STOCKS = 24,
  ORDINARY_SHARES = 25,
  RIGHTS = 26,
  STAPLED_SECURITY = 27,
  EXTENDED_SETTLEMENT = 28,
  UNIT_TRUST = 29,
  PREFERENCE_SHARE = 30,
  FUND = 31,
  PLAIN_WARRANTS = 32,
  CALL_OPTION = 33,
  PUT_OPTION = 34,
}

export enum SERVICE_PROVIDER {
  CDP = 'CDP',
  CITIBANK = 'CITIBANK',
  DBS = 'DBS',
  HSBC = 'HSBC',
  MAYBANK = 'MAYBANK',
  OCBC = 'OCBC',
  SCB = 'SCB',
  UOB = 'UOB',
  OTHER = 'OTHER',
}

export const CPF_INCOME_RANGE = {
  FIVE_HUNDRED: 50000,
  SEVEN_HUNDRED_FIFTY: 75000,
};

export enum CPF_AGE_GROUP {
  AGE_55_YEARS_AND_BELOW = 'AGE_55_YEARS_AND_BELOW',
  ABOVE_55_TO_60_YEARS = 'ABOVE_55_TO_60_YEARS',
  ABOVE_60_TO_65_YEARS = 'ABOVE_60_TO_65_YEARS',
  ABOVE_65_TO_70_YEARS = 'ABOVE_65_TO_70_YEARS',
  ABOVE_70_YEARS = 'ABOVE_70_YEARS',
}

export enum INCOME_OPTION {
  ESTIMATED = 'estimated-income',
  MANUAL = 'manual-income',
  NONE = 'none',
}

export enum EXPENSE_CATEGORIES {
  FOOD_AND_DRINKS = 'Food and drinks',
  TRANSPORT = 'Transport',
  UTILITIES = 'Utilities',
  DONATIONS = 'Donations',
  ENTERTAINMENT = 'Entertainment',
  FAMILY = 'Family',
  HEALTH = 'Health',
  INSURANCE = 'Insurance',
  LOAN_REPAYMENT = 'Loan repayment',
  PERSONAL_GROOMING = 'Personal grooming',
  SELF_IMPROVEMENT = 'Self-improvement',
  SHOPPING = 'Shopping',
  TAXES = 'Taxes',
}

export enum APIM_ASSET_FORMATTED_TYPE {
  CASA = 'CASA',
  FD = 'FD',
  SRS_HOLDING = 'SRS_HOLDING',
  SRS_AMOUNT = 'SRS_AMOUNT',
  CPFIS_HOLDING = 'CPFIS_HOLDING',
  CPFIS_AMOUNT = 'CPFIS_AMOUNT',
  UNIT_TRUST_HOLDING = 'UNIT_TRUST_HOLDING',
  SECURED_LOAN = 'SECURED_LOAN',
  UNSECURED_LOAN = 'UNSECURED_LOAN',
  CREDIT_CARD = 'CREDIT_CARD',
  CDP = 'CDP',
}

import React from 'react';
import MediaQuery from 'react-responsive';
import styled, { css } from 'styled-components';

import { MyMoneySenseDashboardDesktopImage } from 'assets/LandingPage/DemoSection';
import { MyMoneySenseDashboardMobileImage } from 'assets/LandingPage/DemoSection';
import { SavingsRatioPageImage } from 'assets/LandingPage/DemoSection';
import { SavingsTargetsPageImage } from 'assets/LandingPage/DemoSection';
import { Carousel } from 'components/Common';
import { theme } from 'themes';

const Wrapper = styled.div`
  position: relative;
  width: 420px;
  text-align: center;
`;

const imageStyle = css`
  pointer-events: none;
  -webkit-filter: drop-shadow(3px 3px 3px rgb(0 0 0 / 0.2));
  filter: drop-shadow(3px 3px 3px rgb(0 0 0 / 0.2));
`;

const StyledMyMoneySenseDashboardDesktopImage = styled(MyMoneySenseDashboardDesktopImage)`
  ${imageStyle}
`;

const StyledMyMoneySenseDashboardMobileImage = styled(MyMoneySenseDashboardMobileImage)`
  ${imageStyle}
`;

const StyledMyMoneySenseSavingsRatioPageImage = styled(SavingsRatioPageImage)`
  ${imageStyle}
`;

const StyledSavingsTargetsPageImage = styled(SavingsTargetsPageImage)`
  ${imageStyle}
`;

const CarouselWrapper = styled.div`
  margin: 0 22px 0;
`;

interface IProps {
  selectedIndex: number;
  setSelectedIndex: (selectedIndex: number) => void;
}

export const DemoCarousel = ({ selectedIndex, setSelectedIndex }: IProps) => (
  <Wrapper>
    <CarouselWrapper>
      <Carousel
        targetNumberOfItemsToShow={1}
        showDots={false}
        showArrows={false}
        peekAmount={0}
        targetOverflowAmount={0}
        targetSelectedItemIndex={selectedIndex}
        setSelectedItemIndex={setSelectedIndex}
        gridGap={0}
      >
        <MediaQuery maxWidth={theme.breakpoints.mobileMax}>
          {(matches) =>
            matches ? (
              <StyledMyMoneySenseDashboardMobileImage />
            ) : (
              <StyledMyMoneySenseDashboardDesktopImage />
            )
          }
        </MediaQuery>
        <StyledSavingsTargetsPageImage />
        <StyledMyMoneySenseSavingsRatioPageImage />
      </Carousel>
    </CarouselWrapper>
  </Wrapper>
);

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getLatestMessage } from 'redux/messages/selectors';

export interface IMessageManagerContextValue {
  add: (content: React.ReactNode, options?: {}) => void;
}

const MessageHandler: React.FC<IMessageManagerContextValue> = ({ add }) => {
  const latestMessage = useSelector(getLatestMessage);

  useEffect(() => {
    if (latestMessage) {
      const { content, options } = latestMessage;
      add(content, options);
    }
  }, [add, latestMessage]);

  return null;
};

export interface IMessageManagerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  provider: React.ComponentType<any>;
  consumer: React.ComponentType<React.ConsumerProps<IMessageManagerContextValue>>;
}

export const MessageManager: React.FC<IMessageManagerProps> = ({
  provider: Provider,
  consumer: Consumer,
}) => {
  return (
    <Provider>
      <Consumer>{({ add }) => <MessageHandler add={add} />}</Consumer>
    </Provider>
  );
};

import React from 'react';

import { ManWithTalkingPointsImage } from 'assets/Retirement';
import { Breadcrumbs } from 'components/Common';
import {
  ArticleCTAButton,
  ArticleHeading,
  ArticleHyperlink,
  ArticleParagraph,
  ArticleTemplate,
} from 'components/Templates';
import { RESOURCES } from 'constants/urls';
import { colorScale } from 'themes';

import { RetirementCtaPanel } from '../../RetirementCtaPanel';

import { FinancialGoals } from './FinancialGoals';
import { RetirementIncome } from './RetirementIncome';

export const AssessCurrentSituationArticle = () => {
  return (
    <div>
      <Breadcrumbs
        backgroundColor={{
          default: colorScale('grey', 0),
        }}
        crumbs={[
          {
            urlPath: RESOURCES.RETIREMENT.baseRoute,
            label: 'Retirement planning guide',
          },
          {
            urlPath: RESOURCES.RETIREMENT.assessCurrentSituation,
            label: 'Step 2: Assess current situation',
          },
        ]}
      />

      <ArticleTemplate
        heroIllustration={<ManWithTalkingPointsImage />}
        heroIllustrationPosition="bottom"
        heading="Step 2: Assess current situation"
        content={
          <>
            <RetirementIncome />

            <ArticleHeading>
              What are the things to consider while saving for retirement?
            </ArticleHeading>
            <ArticleParagraph>
              As you save for retirement, it is also important to ensure your finances are in good
              shape for other needs. You may wish to do a{' '}
              <ArticleHyperlink url="https://www.moneysense.gov.sg/fhc/main">
                financial health check
              </ArticleHyperlink>{' '}
              to understand your gaps and get customised recommendations on taking steps to improve
              your financial health.
            </ArticleParagraph>

            <FinancialGoals />

            <ArticleParagraph>
              Review your insurance policies regularly as your needs and premiums may change as you
              age. Ensure that your premiums are affordable in the long run. If you require more
              insurance coverage, you can use{' '}
              <ArticleHyperlink url="https://www.comparefirst.sg/wap/homeEvent.action">
                compareFIRST
              </ArticleHyperlink>{' '}
              to compare the insurance policies.
            </ArticleParagraph>

            <RetirementCtaPanel />
          </>
        }
        additionalContent={<ArticleCTAButton route={RESOURCES.RETIREMENT.baseRoute} />}
      />
    </div>
  );
};

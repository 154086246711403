import React from 'react';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import {
  ABSLogo,
  AIALogo,
  CDPLogo,
  CitibankLogo,
  DBSLogo,
  GELogo,
  HSBCLifeLogo,
  HSBCLogo,
  IncomeLogo,
  LIALogo,
  ManulifeLogo,
  MonetaryAuthorityOfSingaporeLogo,
  MaybankLogo,
  OCBCLogo,
  PrudentialLogo,
  SinglifeLogo,
  SmartNationLogo,
  StandardCharteredLogo,
  UOBLogo,
} from 'assets/partnerLogos';
import { colorScale, maxWidth, minWidth, typography } from 'themes';

const PartnersWrapper = styled.div`
  width: 100%;
`;

const PartnerText = styled.p`
  ${typography('heading-s')};
  margin-bottom: 4px;
`;

export const PartnerLogosSection = styled.div`
  display: grid;
  grid-gap: 8px;
  justify-items: center;
  background: white;
  padding: 16px;
  border: 1px solid ${colorScale('grey', 20)};

  ${maxWidth(
    'mobile',
    css`
      grid-template-columns: repeat(2, 1fr);
    `
  )}

  ${maxWidth(
    'tablet',
    css`
      width: 100vw;
      border-left: none;
      border-right: none;
      margin: 0 -16px;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      grid-template-columns: repeat(3, 1fr);
    `
  )}

  ${minWidth(
    'desktop',
    css`
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      margin: 0;
      border-radius: ${fromTheme('borders.radiusLarge')};
    `
  )}
`;

export const PartnerLogo = styled.div`
  height: 88px;

  > img {
    height: 100%;
  }
`;

export const PartneringBanksSection = () => (
  <PartnersWrapper>
    <PartnerText>Participating banks:</PartnerText>
    <PartnerLogosSection>
      <PartnerLogo>{<CitibankLogo />}</PartnerLogo>
      <PartnerLogo>{<DBSLogo />}</PartnerLogo>
      <PartnerLogo>{<HSBCLogo />}</PartnerLogo>
      <PartnerLogo>{<MaybankLogo />}</PartnerLogo>
      <PartnerLogo>{<OCBCLogo />}</PartnerLogo>
      <PartnerLogo>{<StandardCharteredLogo />}</PartnerLogo>
      <PartnerLogo>{<UOBLogo />}</PartnerLogo>
    </PartnerLogosSection>
  </PartnersWrapper>
);

export const PartneringSgxCdpSection = () => (
  <PartnersWrapper>
    <PartnerText>Partnering SGX CDP:</PartnerText>
    <PartnerLogosSection>
      <PartnerLogo>{<CDPLogo />}</PartnerLogo>
    </PartnerLogosSection>
  </PartnersWrapper>
);

export const PartneringInsurersSection = () => (
  <PartnersWrapper>
    <PartnerText>Partnering insurers:</PartnerText>
    <PartnerLogosSection>
      <PartnerLogo>{<AIALogo />}</PartnerLogo>
      <PartnerLogo>{<GELogo />}</PartnerLogo>
      <PartnerLogo>{<HSBCLifeLogo />}</PartnerLogo>
      <PartnerLogo>{<IncomeLogo />}</PartnerLogo>
      <PartnerLogo>{<ManulifeLogo />}</PartnerLogo>
      <PartnerLogo>{<PrudentialLogo />}</PartnerLogo>
      <PartnerLogo>{<SinglifeLogo />}</PartnerLogo>
    </PartnerLogosSection>
  </PartnersWrapper>
);

export const CollaborationSection = () => (
  <PartnersWrapper>
    <PartnerText>In collaboration with:</PartnerText>
    <PartnerLogosSection>
      <PartnerLogo>{<MonetaryAuthorityOfSingaporeLogo />}</PartnerLogo>
      <PartnerLogo>{<SmartNationLogo />}</PartnerLogo>
      <PartnerLogo>{<ABSLogo />}</PartnerLogo>
      <PartnerLogo>{<LIALogo />}</PartnerLogo>
    </PartnerLogosSection>
  </PartnersWrapper>
);

import React from 'react';
import styled from 'styled-components';

import { Add } from 'assets/icons';
import { WomanHoldingUmbrellaInRainImage } from 'assets/ProtectionCard';
import { Section, Panel, Heading, Button } from 'components/Common';
import { typeScale, colorScale } from 'themes';

const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #ebf6ff;
  z-index: 1;
`;

const StyledSection = styled(Section)`
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
`;

const StyledHeading = styled(Heading)`
  font-size: ${typeScale(0)};
  margin: 0 0 8px;
`;

const StyledP = styled.p`
  font-size: ${typeScale(-1)};
  margin: 0 128px 16px 0;
`;

const AddButton = styled(Button)`
  position: absolute;
  top: -8px;
  right: -16px;
  font-size: ${typeScale(0)};
  color: ${colorScale('primary', 40)};
`;

const StyledEmergencyFundImage = styled(WomanHoldingUmbrellaInRainImage)`
  position: absolute;
  top: 52px;
  right: 16px;
  width: 96px;
`;

export const AddEmergencyFundCard: React.FC<{ openSetEmergencyFundModal: () => void }> = ({
  openSetEmergencyFundModal,
}) => (
  <StyledPanel data-testid="add-ef-card">
    <StyledSection>
      <StyledHeading>Emergency fund</StyledHeading>
      <StyledP>Never let large unexpected expenses catch you off guard.</StyledP>
      <AddButton
        variant="tertiary"
        size="medium"
        icon={<Add />}
        onClick={openSetEmergencyFundModal}
      >
        Add
      </AddButton>
    </StyledSection>
    <Background>
      <StyledEmergencyFundImage />
    </Background>
  </StyledPanel>
);

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { ArrowRight, Tick } from 'assets/icons';
import { Button } from 'components/Common';
import { colorScale, typography, typeScale, shadowScale } from 'themes';

import { TIMELINE_SUBSECTIONS_GAP_PX } from './constants';
import { GridWrapper, circleProperties, lineProperties } from './sharedStyles';

const StyledGridWrapper = styled(GridWrapper)`
  align-items: center;
`;

const Line = styled.div`
  ${lineProperties}

  height: calc(100% + ${TIMELINE_SUBSECTIONS_GAP_PX} * 2);
`;

const Circle = styled.div`
  ${circleProperties}
  background: ${colorScale('grey', 10)};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTick = styled(Tick)`
  color: ${colorScale('primary', 50)};
  stroke: ${colorScale('primary', 50)};
  stroke-width: 1px;
`;

const StyledButton = styled(Button)<{ disabled?: boolean }>`
  ${typography('body-m')}
  padding: 24px 16px;
  align-items: center;

  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-column-start: 2;

  ${ifProp(
    'disabled',
    css`
      background: ${colorScale('grey', 0)};
      color: ${colorScale('grey', 90)};
      border: none;
      box-shadow: ${shadowScale(1)};

      > #icon {
        color: ${colorScale('grey', 30)};
      }
    `
  )}
`;

const StyledArrowRight = styled(ArrowRight)`
  font-size: ${typeScale(3)};
`;

interface IProps {
  children: React.ReactNode;
  onClick: () => void;
  isChecked?: boolean;
  disabled?: boolean;
}

/**
 * Displays a button under a timeline section, completed with a circular
 * indicator at the left side of the panel button.
 * @param children Content to display in the panel button
 * @param toPath Path to route to on button click
 * @param [isChecked] Determines if the circular indicator should have a checkmark
 * @param [disabled] Determines if the button is interactable or not
 */
export const TimelineSubsection = ({
  children,
  onClick,
  isChecked = false,
  disabled = false,
}: IProps) => {
  return (
    <StyledGridWrapper className="timeline-subsection">
      <Line className="subsection-line" />
      {!disabled && <Circle>{isChecked && <StyledTick />}</Circle>}
      <StyledButton
        variant="panel"
        icon={!disabled ? <StyledArrowRight aria-label="Arrow right" /> : undefined}
        iconPosition="end"
        onClick={onClick}
        disabled={disabled}
      >
        <div>{children}</div>
      </StyledButton>
    </StyledGridWrapper>
  );
};

import styled from 'styled-components';

import { CurrencyPrefix, TableData } from 'components/Common';
import { NutritionalValueText } from 'components/Composite';
import { typography } from 'themes';

export const StyledCurrencyPrefix = styled(CurrencyPrefix)`
  &::before {
    ${typography('label-bold')}
  }
`;

export const StyledNutritionalValueText = styled(NutritionalValueText)`
  ${typography('body-s')}
  a {
    ${typography('body-s')};
  }
`;

export const StyledTableData = styled(TableData)`
  width: 45%;
`;

import React from 'react';
import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import { colorScale, ITheme, theme } from 'themes';

const ProgressBarWrapper = styled.div<{ spacingBetweenBarsInPixels: number }>`
  display: flex;

  & > :not(:last-child) {
    margin-right: ${prop('spacingBetweenBarsInPixels')}px;
  }
`;

const Bar = styled.div<{ isActive: boolean; activeColor: (props: ITheme) => string }>`
  width: 32px;
  height: 4px;
  opacity: ${ifProp('isActive', 1.0, 0.5)};
  border-radius: ${theme.borders.radiusLarge};
  background: ${prop('activeColor')};
`;

export interface IProgressBarProps {
  current: number;
  total: number;
  activeColor?: (props: ITheme) => string;
  spacingBetweenBarsInPixels?: number;
}

/**
 * Progress bar indicator
 *
 * @param current Current active step
 * @param total Total number of steps
 * @param [activeColor] Color of the active bars, inactive bars are half the opacity of this prop
 * @param [spacingBetweenBarsInPixels] Spacing between each bars
 */
export const ProgressBar = ({
  current,
  total,
  activeColor = colorScale('primary', 50),
  spacingBetweenBarsInPixels = 4,
}: IProgressBarProps) => {
  return (
    <ProgressBarWrapper spacingBetweenBarsInPixels={spacingBetweenBarsInPixels}>
      {Array.from(Array(total)).map((_, index) => (
        <Bar
          data-testid="child-bar"
          key={index}
          isActive={index + 1 <= current}
          activeColor={activeColor}
        />
      ))}
    </ProgressBarWrapper>
  );
};

import React from 'react';
import styled, { css } from 'styled-components';

import { LeftAlignedBigWaveImage } from 'assets/Apim/IntroPrivacy';
import { Container, Section, Heading } from 'components/Common';
import { minWidth, maxWidth, colorScale, typography } from 'themes';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${minWidth(
    'desktop',
    css`
      padding-top: 64px;
      grid-column-start: 6;
      grid-column-end: 12;
    `
  )}
`;

const Content = styled.div`
  margin: 24px 0;
`;

const DesktopImageWrapper = styled.div`
  position: relative;
  grid-column-start: 1;
  grid-column-end: 5;

  ${maxWidth(
    'tablet',
    css`
      display: none;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      padding-left: calc((100vw - 768px) / 2);
      margin-left: calc((768px - 100vw) / 2);
    `
  )}

  ${minWidth(
    'largeScreen',
    css`
      padding-left: calc((100vw - 1024px) / 2);
      margin-left: calc((1024px - 100vw) / 2);
    `
  )}
`;

const PageHeading = styled(Heading)`
  ${typography('heading-l')}
`;

const StyledLeftAlignedBigWaveImage = styled(LeftAlignedBigWaveImage)`
  ${minWidth(
    'desktop',
    css`
      height: 100%;
      width: calc(100% + (100vw - 768px) / 2);
      margin-left: calc((768px - 100vw) / 2);
    `
  )}

  ${minWidth(
    'largeScreen',
    css`
      width: calc(100% + (100vw - 1024px) / 2);
      margin-left: calc((1024px - 100vw) / 2);
    `
  )}
`;

const Wrapper = styled(Section)`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(12, 1fr);
`;

const ImageCircle = styled.div`
  position: absolute;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: ${colorScale('supporting-cobalt', 20)};
  top: 192px;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DesktopPageHeading = styled(PageHeading)`
  ${maxWidth(
    'tablet',
    css`
      display: none;
    `
  )};
`;

const MobileImageWrapper = styled.div`
  background: ${colorScale('grey', 15)};
  width: 100vw;
  min-height: 128px;
  margin: 0 -16px;
  display: flex;
  align-items: center;
  padding: 16px;

  ${minWidth(
    'desktop',
    css`
      display: none;
    `
  )};
`;

const MobileImage = styled.div`
  float: right;
  max-width: 40%;
  > img {
    width: 100%;
    height: 100%;
  }
`;

interface IProps {
  title: string;
  image: React.ReactElement;
  children: React.ReactNode;
  ctaPanel: React.ReactNode;
}

export const SingleContentPage: React.FC<IProps> = ({ title, image, children, ctaPanel }) => {
  return (
    <Container>
      <Wrapper>
        <DesktopImageWrapper>
          <StyledLeftAlignedBigWaveImage />
          <ImageCircle>{image}</ImageCircle>
        </DesktopImageWrapper>
        <ContentWrapper>
          <MobileImageWrapper>
            <PageHeading>
              <MobileImage>{image}</MobileImage>
              {title}
            </PageHeading>
          </MobileImageWrapper>
          <DesktopPageHeading>{title}</DesktopPageHeading>
          <Content>{children}</Content>
          {ctaPanel}
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

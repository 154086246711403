import { css } from 'styled-components';

export const visuallyHiddenProperties = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  pointer-events: none;
`;

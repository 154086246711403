import styled, { css } from 'styled-components';

import { Heading, OrderedList, UnorderedList } from 'components/Common';
import { typography, colorScale } from 'themes';

import {
  DEFAULT_BETWEEN_COMPONENT_SPACING_PX,
  LIST_BETWEEN_COMPONENT_SPACING_PX,
} from './constants';

/** Shared Styles */

export const disableMarginCollapsing = css`
  display: flex;
  flex-direction: column;
`;

/** Headings */

const StyledHeading = styled(Heading)`
  margin: ${DEFAULT_BETWEEN_COMPONENT_SPACING_PX} 0 0;
`;

export const ArticleHeading = styled(StyledHeading)`
  ${typography('heading-m')}
`;

export const ArticleSubHeading = styled(StyledHeading)`
  ${typography('heading-s')}
`;

/** Tagline */

export const ArticleTagline = styled.p`
  ${typography('body-s-italic')}
  color: ${colorScale('grey', 80)};
  margin: ${DEFAULT_BETWEEN_COMPONENT_SPACING_PX} 0;

  > a {
    ${typography('body-s-italic')}
  }
`;

/** Paragraph */

export const ArticleParagraph = styled.div`
  margin: ${DEFAULT_BETWEEN_COMPONENT_SPACING_PX} 0;
`;

/** Ordered and Unordered Lists */

const articleListStyles = css`
  margin: 0 0 ${LIST_BETWEEN_COMPONENT_SPACING_PX};
`;

export const ArticleOrderedList = styled(OrderedList)`
  ${articleListStyles}
  > li {
    font-weight: normal;
  }
`;

export const ArticleUnorderedList = styled(UnorderedList)`
  ${articleListStyles}
`;

/**
 * ArticleListItem is intentionally a div rather than a li.
 *
 * ArticleOrderedList and ArticleUnorderedList wraps its children in li tag
 * behind the scene, and ArticleListItem is intended to be used as the child
 * of those lists.
 */
export const ArticleListItem = styled.div`
  margin: 0 0 8px 0;
`;

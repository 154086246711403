import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { ArrowRight, Open } from 'assets/icons';
import { Button } from 'components/Common';
import { colorScale, typography, typeScale } from 'themes';

const StyledImage = styled.div`
  width: 60px;

  > img {
    width: 50px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const TextWrapper = styled.div`
  margin: 0 8px 0 16px;
  flex-grow: 1;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Number = styled.div`
  background: ${colorScale('grey', 15)};
  padding: 2px 12px;
  margin-left: 8px;
  align-self: flex-start;
  border-radius: ${fromTheme('borders.radiusExtraLarge')};
  ${typography('body-l-bold')}
`;

const Title = styled.p`
  color: ${colorScale('grey', 90)};
  ${typography('heading-s')}
  margin: 0 0 8px;
`;

const Description = styled.p`
  color: ${colorScale('grey', 80)};
  ${typography('body-m')}
  margin: 0;
`;

const StyledArrowRight = styled(ArrowRight)`
  font-size: ${typeScale(3)};
`;

const StyledOpen = styled(Open)`
  font-size: ${typeScale(3)};
`;

interface IProps {
  title: string;
  description: string;
  toPath: string;
  entryPath?: string;
  image: React.ReactElement;
  className?: string;
  badge?: {
    count: number;
  };
}

export const ResourcesCard = ({
  title,
  description,
  toPath,
  entryPath,
  image,
  className,
  badge,
}: IProps) => {
  const history = useHistory();
  const regexCheck = new RegExp('^(http|https)://', 'i');
  const isExternalLink = regexCheck.test(toPath);

  return (
    <Button
      className={className}
      widthStyle="fluid"
      onClick={() => {
        isExternalLink
          ? window.open(toPath)
          : entryPath
          ? history.push({
              pathname: toPath,
              state: { from: entryPath },
            })
          : history.push(toPath);
      }}
      variant="panel"
      icon={isExternalLink ? <StyledOpen /> : <StyledArrowRight />}
      iconPosition="end"
    >
      <Wrapper>
        <StyledImage>{image}</StyledImage>
        <TextWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
            {badge && <Number>{badge.count}</Number>}
          </TitleWrapper>
          <Description>{description}</Description>
        </TextWrapper>
      </Wrapper>
    </Button>
  );
};

import { MAIN_MODULE } from 'constants/enums';
import { IStore, IMainModuleDetails } from 'constants/storeTypes';
import { getModuleDetailsFromMap } from 'utilities/moduleUtilities';

import { getCashflowModuleDetails } from './cashflowDetails';
import { getWealthModuleDetails } from './wealthDetails';

const moduleMap = {
  [MAIN_MODULE.CASHFLOW]: getCashflowModuleDetails,
  [MAIN_MODULE.WEALTH]: getWealthModuleDetails,
};

export const mainModules = Object.keys(moduleMap) as MAIN_MODULE[];

export const getMainModuleDetails = (store: IStore) =>
  getModuleDetailsFromMap<IMainModuleDetails>(store, moduleMap);

import { Omit, remove } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { ICheckboxProps } from './Checkbox';

const StyledDiv = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-auto-flow: column;
  grid-auto-columns: auto;
`;

interface IOwnProps {
  /** Name of checkbox button children to group them together */
  name: string;

  /** Defines the look of the checkbox label for all children */
  variant?: 'default' | 'styled' | 'custom';

  /** Values passed in must be a array of strings which the internal children checkbox will use */
  values: string[];

  /** Limits the number of choices a user can make with the same named checkboxes */
  choiceLimit?: number;

  onChange: (newValues: string[], addedValue?: string) => void;

  children: React.ReactElement<ICheckboxProps>[] | React.ReactElement<ICheckboxProps>;
}

type ICheckboxGroupProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> &
  IOwnProps;

export class CheckboxGroup extends React.Component<ICheckboxGroupProps> {
  constructor(props: ICheckboxGroupProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  public handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { values, onChange } = this.props;

    const isRemoved = values.includes(e.target.value);
    const newValues = values;
    isRemoved
      ? remove(newValues, (value: string) => value === e.target.value)
      : newValues.push(e.target.value);

    onChange(newValues, isRemoved ? undefined : e.target.value);
  }

  public renderChildren() {
    const { children, name, variant, values, choiceLimit } = this.props;
    if (!children) {
      return null;
    }

    return React.Children.map(children, (child: React.ReactElement<ICheckboxProps>) => {
      if (React.isValidElement(child)) {
        const isChosen = values.includes(child.props.value);
        const disabled =
          child.props.disabled || Boolean(choiceLimit && values.length >= choiceLimit && !isChosen);

        return React.cloneElement(child, {
          name,
          variant,
          onChange: this.handleChange,
          disabled,
          checked: isChosen,
        });
      } else {
        return null;
      }
    });
  }

  public render() {
    return <StyledDiv className={this.props.className}>{this.renderChildren()}</StyledDiv>;
  }
}

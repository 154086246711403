import React from 'react';

import { AchievedEmergencyFundMonthsListener } from './AchievedEmergencyFundMonthsListener';

export const NotificationsListener: React.FC = () => {
  return (
    <React.Fragment>
      <AchievedEmergencyFundMonthsListener />
    </React.Fragment>
  );
};

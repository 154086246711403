import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { colorScale } from 'themes';

import { CIRCLE_SIZE_PX, CIRCLE_BORDER_PX, LINE_WIDTH_PX } from './constants';

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${CIRCLE_SIZE_PX} auto;
  grid-gap: 16px;

  position: relative;
`;

export const circleProperties = css`
  width: ${CIRCLE_SIZE_PX};
  height: ${CIRCLE_SIZE_PX};

  border: ${CIRCLE_BORDER_PX} solid ${colorScale('grey', 0)};
  border-radius: ${fromTheme('borders.radiusRound')};
  z-index: 1;
`;

export const lineProperties = css`
  background: ${colorScale('grey', 20)};
  width: ${LINE_WIDTH_PX};

  position: absolute;
  left: calc(${CIRCLE_SIZE_PX} / 2 - ${LINE_WIDTH_PX} / 2);
`;

import { transparentize } from 'polished';
import { createGlobalStyle, css } from 'styled-components';

export * from './themesTypography';

export type typeScaleLevel = -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

/**
 * Returns the font size given the level
 *
 * @param level
 *    -4 (9px), -3 (10px), -2 (12px), -1 (14px), 0 (16px),
 *    1 (18px), 2 (20px), 3 (24px), 4 (32px), 5 (40px), 6 (48px), 7 (56px)
 */
export const typeScale = (level: typeScaleLevel) => {
  if (level < 0) {
    return ['0.875rem', '0.75rem', '0.625rem', '0.5625rem'][Math.abs(level) - 1];
  } else {
    return ['1rem', '1.125rem', '1.25rem', '1.5rem', '2rem', '2.5rem', '3rem', '3.5rem'][level];
  }
};

export type colorName =
  | 'grey'
  | 'primary'
  | 'secondary'
  | 'supporting-green'
  | 'supporting-pink'
  | 'supporting-red'
  | 'supporting-yellow'
  | 'supporting-blue'
  | 'supporting-cobalt'
  | 'supporting-teal';
export type toneLevel = 0 | 10 | 15 | 20 | 30 | 40 | 50 | 80 | 90;

const palette: { [key in colorName]: { [tone in toneLevel]?: string } } = {
  grey: {
    '0': '#FFFFFF',
    '10': '#F8F9FC',
    '15': '#F1F3F9',
    '20': '#E9EDF5',
    '30': '#D6DBE2',
    '50': '#A3ABB8',
    '80': '#6E747E',
    '90': '#363B43',
  },
  primary: {
    '10': '#F5F5FF',
    '20': '#E7E5FF',
    '30': '#7A65E4',
    '40': '#644DCF',
    '50': '#523BB7',
  },
  secondary: {
    '10': '#FFF7F0',
    '20': '#FCE9D7',
    '30': '#EA9442',
    '40': '#DE7612',
    '50': '#C53C02',
  },
  'supporting-green': {
    '10': '#F6FEFA',
    '20': '#E5FAF0',
    '30': '#4EC59E',
    '40': '#16A17C',
    '50': '#00805D',
  },
  'supporting-pink': {
    '10': '#FDF3FB',
    '20': '#F8E2F3',
    '30': '#DD74C4',
    '40': '#D34BB2',
    '50': '#B0118A',
  },
  'supporting-red': {
    '10': '#FFF5F5',
    '20': '#FFE3E3',
    '30': '#F86A6A',
    '40': '#DF2D2D',
    '50': '#CF1124',
  },
  'supporting-yellow': {
    '10': '#FFFDF5',
    '20': '#FFF7D6',
    '30': '#FADB5F',
    '40': '#FFC72B',
    '50': '#B25900',
  },
  'supporting-blue': {
    '10': '#F0FAFF',
    '20': '#C7EDFF',
    '30': '#53C5FA',
    '40': '#2C9CD0',
    '50': '#276D8D',
  },
  'supporting-cobalt': {
    '10': '#F5F7FF',
    '20': '#E7EBFE',
    '30': '#5572F6',
    '40': '#4561E3',
    '50': '#3853CC',
  },
  'supporting-teal': {
    '10': '#F5FEFF',
    '20': '#E2FCFE',
    '30': '#4BCCD3',
    '40': '#1E9CA3',
    '50': '#05757B',
  },
};

type scaleColorName =
  | 'primary'
  | 'secondary'
  | 'supporting-green'
  | 'supporting-pink'
  | 'supporting-red'
  | 'supporting-yellow'
  | 'supporting-blue'
  | 'supporting-cobalt'
  | 'supporting-teal';
type scaleColorTone = 10 | 20 | 30 | 40 | 50;
type scaleGreyTone = 0 | 10 | 15 | 20 | 30 | 50 | 80 | 90;
export function colorScale(
  name: scaleColorName,
  tone: scaleColorTone,
  modifier?: (color: string) => string
): (props: ITheme) => string;
export function colorScale(
  name: 'grey',
  tone: scaleGreyTone,
  modifier?: (color: string) => string
): (props: ITheme) => string;
/**
 * Returns the color according to the given color name and tone.
 *
 * @param colorName
 * @param tone based on the colorName
 */
export function colorScale(name: colorName, tone: toneLevel, modifier?: (color: string) => string) {
  return (props: ITheme) => {
    if (modifier) {
      return modifier(props.theme.palette[name][tone] as string);
    } else {
      return props.theme.palette[name][tone];
    }
  };
}

export type gradientName = 'main';
interface IGradientStep {
  percentage: string;
  color: string;
}

const gradients: { [key in gradientName]: IGradientStep[] } = {
  main: [
    {
      percentage: '0%',
      color: '#6361D1',
    },
    {
      percentage: '100%',
      color: '#8861D1',
    },
  ],
};

export const gradient = (name: gradientName, angle: string) => {
  const step = gradients[name];
  return `linear-gradient(${angle}, ${step
    .map((gs) => `${gs.color} ${gs.percentage}`)
    .join(', ')})`;
};

export type shadowLevel = 1 | 2 | 3;
export const shadowScale = (level: shadowLevel, color = colorScale('grey', 90)) => (
  props: ITheme
) => {
  const shadowColor = color(props);
  switch (level) {
    case 1:
      return `
        0px 0px 2px ${transparentize(0.9, shadowColor)},
        0px 1px 2px ${transparentize(0.85, shadowColor)};
      `;
    case 2:
      return `
        0px 2px 4px ${transparentize(0.9, shadowColor)},
        0px 6px 16px ${transparentize(0.92, shadowColor)};

      `;
    case 3:
      return `
        0px 3px 6px ${transparentize(0.9, shadowColor)},
        0px 16px 48px ${transparentize(0.9, shadowColor)};
      `;
  }
};

const fonts = {
  mainFontFamily: `'Libre Franklin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
  Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  alternateFontFamily: `'Playfair Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
  Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
};

const sizes = {
  pageHeading: typeScale(6),
  pageSubHeading: typeScale(2),
};

const transitions = {
  duration: '0.2s',
  durationLonger: '0.4s',
};

const borders = {
  radius: '4px',
  radiusLarge: '6px',
  radiusExtraLarge: '12px',
  radiusRound: '1000px',
  divider: '1px',
};

const mobileBreakpoint = 512;
const tabletBreakpoint = 768;
const desktopBreakpoint = 1024;
// padding is counted as both sides
const tabletPadding = 48;
const desktopPadding = 80;

export const breakpoints = {
  mobileMax: `${mobileBreakpoint}px`,
  tabletMin: `${mobileBreakpoint + 1}px`,
  tabletMax: `${tabletBreakpoint + tabletPadding}px`,
  desktopMin: `${tabletBreakpoint + tabletPadding + 1}px`,
  desktopMax: `${desktopBreakpoint + desktopPadding}px`,
  largeScreen: `${desktopBreakpoint + desktopPadding + 1}px`,
};

// FlattenSimpleInterpolation is supposed to be used but produces a type error
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const maxWidth = (device: 'mobile' | 'tablet' | 'desktop', args: any) =>
  css`
    @media only screen and (max-width: ${breakpoints[`${device}Max`]}) {
      ${args}
    }
  `;

// FlattenSimpleInterpolation is supposed to be used but produces a type error
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const minWidth = (device: 'tablet' | 'desktop' | 'largeScreen', args: any) =>
  device === 'largeScreen'
    ? css`
        @media only screen and (min-width: ${breakpoints['largeScreen']}) {
          ${args}
        }
      `
    : css`
        @media only screen and (min-width: ${breakpoints[`${device}Min`]}) {
          ${args}
        }
      `;

export const heights = {
  governmentBanner: '28px',
  navbar: '60px',
};

export const layers = {
  modal: 100,
  notification: 90,
  dropdown: 80,
  navbar: 75,
  tooltip: 70,
  footer: 69,
  moduleHeader: 65,
  sticky: 60,
  templateContent: 2,
  templateBackgroundFront: 1,
  templateBackgroundBack: 0,
};

export const theme = {
  palette,
  fonts,
  sizes,
  transitions,
  borders,
  breakpoints,
  heights,
  layers,
};

export interface ITheme {
  theme: {
    palette: typeof palette;
    fonts: {
      [fontType: string]: string;
    };
    sizes: {
      [prop: string]: string;
    };
    transitions: {
      [prop: string]: string;
    };
    borders: {
      [prop: string]: string;
    };
    breakpoints: {
      [prop: string]: string;
    };
    heights: {
      [prop: string]: string;
    };
    layers: {
      [layer: string]: number;
    };
  };
}

export type IThemeObject = ITheme['theme'];

export enum PAGE_SIDE_SPACING {
  ZERO_PX = '0px',
  DEFAULT_PX = '16px',
  WIDE_PX = '24px',
}

// iOS Safari: Provides some space to ensure buttons above this amount can be clicked
// without causing the bottom navigation bar to appear
export const safeBottomArea = `calc(env(safe-area-inset-bottom) * 1.8)`;

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${fonts.mainFontFamily};
    font-size: 100%;
    line-height: 1.2;
    color: ${colorScale('grey', 90)({ theme })};

    p {
      line-height: 1.4;
    }

    svg {
      transition: inherit;
    }

    & ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    & ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
    }

    & ::-webkit-scrollbar-track {
      all: unset;
    }
  }
`;

import * as React from 'react';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { colorScale, maxWidth, typography } from 'themes';

import { ISideBarProps } from './interfaces';

const SideBarWrapper = styled.div`
  grid-area: sidebar;
`;

const SideBarContent = styled.div`
  position: sticky;
  top: calc(${fromTheme('heights.navbar')} + ${fromTheme('heights.governmentBanner')} + 24px);
`;

const SideBarTitle = styled.p`
  color: ${colorScale('grey', 80)};
  margin: 0 0 16px 0;
  ${typography('heading-s')}
  ${maxWidth(
    'tablet',
    css`
      display: none;
    `
  )}
`;

export const ModuleContentSideBar: React.FC<ISideBarProps> = ({ title, children }) => (
  <SideBarWrapper data-testid="module-content-sidebar">
    <SideBarContent>
      {title && <SideBarTitle>{title}</SideBarTitle>}
      {children}
    </SideBarContent>
  </SideBarWrapper>
);

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { IEmergencyFundRatioInputs } from '../interfaces';

export const EF1B: IRule<IEmergencyFundRatioInputs> = {
  condition: ({
    emergencyFundMonths,
    savingsRatio,
    isWorking,
    numInvestmentClasses,
  }: IEmergencyFundRatioInputs) =>
    emergencyFundMonths >= 6 &&
    isWorking &&
    !(savingsRatio !== null && savingsRatio >= 0.2 && numInvestmentClasses === 0),
  result: {
    key: RULE.EF1B,
    indicatorStatus: indicatorStatuses.GOOD,
    recommendedActions: [recommendedActions.TRA_EF_A],
    resources: [
      // Some links
    ],
  },
};

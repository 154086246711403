import React from 'react';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { colorScale, typeScale, maxWidth } from 'themes';

import { Button, IButtonProps } from '../Button/Button';

const StyledButton = styled(Button)`
  color: ${colorScale('primary', 40)};
  font-size: ${typeScale(2)};
  font-weight: bold;
  border: 1px solid ${colorScale('primary', 40)};
  border-radius: ${fromTheme('borders.radiusLarge')};
  padding: 0 8px 3px;
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  margin: 4px;

  ${maxWidth(
    'mobile',
    css`
      margin: 0;
      &:active {
        background: none;
      }
    `
  )};
`;

export const MinusButton = (props: IButtonProps) => (
  <StyledButton type="button" variant="tertiary" {...props}>
    &mdash;
  </StyledButton>
);

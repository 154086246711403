import React from 'react';

import { IExpense } from 'constants/storeTypes';

import { getBudgetColoredArray } from '../Charts/StackedBarChart/BudgetColorArray';

import { CategoryBreakdownRow } from './CategoryBreakdownRow';
import { CategoryBreakdownTitle } from './CategoryBreakdownTitle';

interface IProps {
  data: IExpense[];
  groupAtIndex?: number;
}

interface IChartData extends IExpense {
  color: {
    main: string;
    text: string;
  };
}

export const CategoryBreakdown = ({ data, groupAtIndex }: IProps) => {
  const chartDataWithColor = getBudgetColoredArray(data, { groupAtIndex });

  return (
    <>
      <CategoryBreakdownTitle />
      {chartDataWithColor.map((v: IChartData) => (
        <CategoryBreakdownRow
          key={v.category}
          amount={v.amount / v.timeInterval}
          category={v.category}
          budget={v.budgetAmount / v.budgetTimeInterval}
          mainColor={v.color.main}
          textColor={v.color.text}
        />
      ))}
    </>
  );
};

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Cross } from 'assets/icons';

import { IconButton } from '../IconButton/IconButton';

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;

  ${ifProp(
    'hidden',
    css`
      opacity: 0;
      width: 0;
      visibility: hidden;
    `,
    css`
      opacity: 1;
    `
  )};
`;

interface IProps {
  /** Hides the close button from the modal. Note that the modal can still be closed via the esc button or clicking outside the modal */
  hidden: boolean;

  /** This function handles the state change of exiting (or deactivating) the modal.
   *  It will be invoked when the user clicks outside the modal (if underlayClickExits={true}) or hits Escape (if escapeExits={true}),
   *  and it receives the event that triggered it as its only argument
   */
  onExit: (event?: React.SyntheticEvent) => void;

  colourVariant: 'light' | 'dark';
}

export class CloseButton extends React.PureComponent<IProps> {
  public render() {
    const { hidden, onExit, colourVariant } = this.props;
    return (
      <StyledIconButton
        data-testid="close-button"
        aria-label="Close button"
        variant={colourVariant === 'light' ? 'white' : 'grey'}
        ariaLabel="Close button"
        onClick={onExit}
        hidden={hidden}
      >
        <Cross />
      </StyledIconButton>
    );
  }
}

import { Reducer } from 'redux';

import { INotificationActions } from 'constants/actionTypes';
import { NOTIFICATIONS_ACTIONS, NOTIFICATION_TYPE } from 'constants/enums';
import { INotificationsStore } from 'constants/storeTypes';

export const initialState: INotificationsStore = {
  updateNotifications: {},
  todoNotificationAcknowledgements: [],
  inbuiltNotificationAcknowledgements: [],
};

export const notificationsReducer: Reducer<INotificationsStore, INotificationActions> = (
  state: INotificationsStore = initialState,
  action: INotificationActions
) => {
  switch (action.type) {
    case NOTIFICATIONS_ACTIONS.CREATE_NOTIFICATION:
      return {
        ...state,
        updateNotifications: {
          ...state.updateNotifications,
          [action.notification.id]: action.notification,
        },
      };

    case NOTIFICATIONS_ACTIONS.ACKNOWLEDGE_NOTIFICATION: {
      if (action.notificationType === NOTIFICATION_TYPE.TODO) {
        return {
          ...state,
          todoNotificationAcknowledgements: [...state.todoNotificationAcknowledgements, action.id],
        };
      }
      if (action.notificationType === NOTIFICATION_TYPE.INBUILT_UPDATES) {
        return {
          ...state,
          inbuiltNotificationAcknowledgements: [
            ...state.inbuiltNotificationAcknowledgements,
            action.id,
          ],
        };
      }
      return {
        ...state,
        updateNotifications: {
          ...state.updateNotifications,
          [action.id]: { ...state.updateNotifications[action.id], acknowledged: true },
        },
      };
    }

    default:
      return state;
  }
};

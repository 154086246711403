import React from 'react';
import styled from 'styled-components';

import { typography, colorScale } from 'themes';

import { Wrapper, Text } from './CategoryBreakdownRow';

const StyledTitleWrapper = styled(Wrapper)`
  border-bottom: 1px solid ${colorScale('grey', 30)};
`;

const StyledTitle = styled(Text)`
  ${typography('body-s-bold')}
  color: ${colorScale('grey', 80)};
  max-width: 75%;
`;

export const CategoryBreakdownTitle = () => {
  return (
    <StyledTitleWrapper>
      <StyledTitle>Category</StyledTitle>
      <StyledTitle>Current Expenses</StyledTitle>
      <StyledTitle>Budget</StyledTitle>
    </StyledTitleWrapper>
  );
};

import { formatCurrency } from 'utilities/currencyUtilities';

export function maximumAmount(max: number, inclusive = true, currency = true) {
  return function maximumAmountInner(fieldValue: string) {
    const strippedFieldValue = fieldValue && fieldValue.replace(/[,]/g, '');
    const fieldValueNumber = Math.round(Number(strippedFieldValue) * (currency ? 100 : 1));

    const isValidationPassed =
      strippedFieldValue !== '' && (inclusive ? fieldValueNumber > max : fieldValueNumber >= max);

    return isValidationPassed
      ? `Must be less than ${inclusive ? 'or equal to ' : ''}${
          currency ? formatCurrency(max) : max
        }`
      : undefined;
  };
}

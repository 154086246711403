import React from 'react';

import { TableHeader, TableRow, Table, CurrencyPrefix } from 'components/Common';

import { StyledTableData, StyledCurrencyPrefix } from './sharedStyles';

export const CurrentAnnualIncomeTable = () => (
  <Table>
    <thead>
      <TableRow>
        <TableHeader colSpan={1}>
          <span>Current annual income</span>
        </TableHeader>
        <TableHeader colSpan={1}>
          <StyledCurrencyPrefix>54,000</StyledCurrencyPrefix>
        </TableHeader>
      </TableRow>
    </thead>
    <tbody>
      <TableRow>
        <StyledTableData colSpan={1}>
          <b>Annual retirement income (50% to 70% of current annual income)</b>
        </StyledTableData>
        <StyledTableData colSpan={2}>
          <CurrencyPrefix>27,000</CurrencyPrefix> to <CurrencyPrefix>37,800</CurrencyPrefix>
        </StyledTableData>
      </TableRow>
      <TableRow>
        <StyledTableData colSpan={1}>
          <b>Total retirement income needed (for 19 years) without adjusting for inflation</b>
        </StyledTableData>
        <StyledTableData colSpan={2}>
          <CurrencyPrefix>513,000</CurrencyPrefix> to <CurrencyPrefix>718,200</CurrencyPrefix>
        </StyledTableData>
      </TableRow>
    </tbody>
  </Table>
);

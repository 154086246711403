import React from 'react';
import { connect } from 'react-redux';
import { TransitionStatus } from 'react-transition-group/Transition';
import { bindActionCreators, Dispatch, ActionCreatorsMapObject } from 'redux';

import { getTargetAmountLimit } from 'redux/finances/selectors';
import { addMessage, addDefaultErrorMessage } from 'redux/messages/actions';
import { updateGoalRequest, deleteGoalRequest } from 'redux/targets/actions';

import { captureGoalEdited, captureTargetDeleted, captureCompleteGoal } from 'analytics';
import { ThunkActionCreator } from 'constants/actionTypes';
import { IGoalForRender, IStore } from 'constants/storeTypes';
import { getDateDiffInMonths, addMonthsToDate } from 'utilities/dateUtilities';

import { WarningModalContent } from '../WarningModalContent/WarningModalContent';

import { CompletedGoalContent } from './CompletedGoalContent';
import { EditGoalContent } from './EditGoalContent';
import { EditGoalContentPaidOutContainer } from './EditGoalContentPaidOut';
import { RestoreGoalContent } from './RestoreGoalContent';

interface IStateProps {
  targetAmountLimit: number;
}

const mapStateToProps: (state: IStore) => IStateProps = (state) => ({
  targetAmountLimit: getTargetAmountLimit(state),
});

interface IDispatchProps {
  captureCompleteGoal: ThunkActionCreator<typeof captureCompleteGoal>;
  updateGoalRequest: ThunkActionCreator<typeof updateGoalRequest>;
  deleteGoalRequest: ThunkActionCreator<typeof deleteGoalRequest>;
  addMessage: typeof addMessage;
  addDefaultErrorMessage: typeof addDefaultErrorMessage;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators<{}, ActionCreatorsMapObject & IDispatchProps>(
    {
      captureCompleteGoal,
      updateGoalRequest,
      deleteGoalRequest,
      addMessage,
      addDefaultErrorMessage,
    },
    dispatch
  );

interface IOwnProps {
  shouldShowWarningContent: boolean;
  goal: IGoalForRender;
  transitionStatus?: TransitionStatus;
  setHasDataChanged: (setValue: boolean) => void;
  allowModalCloseFunctions: () => void;
  blockModalCloseFunctions: () => void;
  cancelDiscardWarning: () => void;
  onExit: (event?: React.SyntheticEvent) => void;
  addToast: (message: string) => void;
  archivable?: boolean;
  analyticsSource: 'Targets page' | 'Review savings';
  monthlySavings?: number;
  setToMinimumMonthlySavingsAndCloseModal?: () => void;
  minimumMonthlySavings?: number;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

type IModal = 'edit' | 'restore' | 'paid-out' | 'completed' | 'delete-warning';

interface IState {
  name: string;
  amount: number;
  date: Date;
  paidOut: boolean;
  paidOutAmount: number | null;
  paidOutDate: Date | null;
  modalStack: IModal[];
}

export class EditGoalModalToggler extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { name, totalAmount, date, paidOut, paidOutAmount, paidOutDate } = props.goal;

    this.state = {
      name,
      amount: totalAmount,
      date,
      paidOut,
      paidOutAmount,
      paidOutDate,
      modalStack: [paidOut ? 'completed' : 'edit'],
    };

    this.updateName = this.updateName.bind(this);
    this.updateAmount = this.updateAmount.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.updatePaidOut = this.updatePaidOut.bind(this);
    this.updatePaidOutAmount = this.updatePaidOutAmount.bind(this);
    this.saveToStore = this.saveToStore.bind(this);
    this.handleDeleteAction = this.handleDeleteAction.bind(this);
    this.handleRestoreAction = this.handleRestoreAction.bind(this);
    this.handlePaidOutAction = this.handlePaidOutAction.bind(this);
    this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
    this.handleDeleteRejection = this.handleDeleteRejection.bind(this);
    this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
    this.pushModalOntoStack = this.pushModalOntoStack.bind(this);
    this.popModalOffStack = this.popModalOffStack.bind(this);
    this.revertChanges = this.revertChanges.bind(this);
  }

  public updateName(name: string) {
    this.setState({ name });
  }

  public updateAmount(amount: number) {
    this.setState({ amount: amount });
  }

  public updateDate(date: Date) {
    this.setState({ date });
  }

  public updatePaidOut(paidOut: boolean) {
    this.setState({ paidOut });
  }

  public updatePaidOutAmount(paidOutAmount: number) {
    this.setState({ paidOutAmount });
  }

  public pushModalOntoStack(modal: IModal) {
    this.setState((prevState: IState) => {
      const modalStack = [...prevState.modalStack];
      modalStack.push(modal);
      return { modalStack };
    });
  }

  public popModalOffStack() {
    this.setState((prevState: IState) => {
      const modalStack = [...prevState.modalStack];
      modalStack.pop();
      return { modalStack };
    });
  }

  public saveToStore() {
    const {
      goal,
      analyticsSource,
      updateGoalRequest,
      onExit,
      addMessage,
      addDefaultErrorMessage,
      captureCompleteGoal,
    } = this.props;
    const { name, amount, date, paidOut, paidOutAmount, paidOutDate } = this.state;
    const currentAmount = Math.min(amount, goal.currentAmount);
    const targetCompleted = !goal.paidOut && paidOut;
    const targetRestored = goal.paidOut && !paidOut;

    const promise = updateGoalRequest(goal.id, {
      name,
      totalAmount: amount,
      currentAmount: targetRestored ? 0 : currentAmount,
      date,
      ...((targetCompleted || targetRestored) && { paidOutDate, paidOut, paidOutAmount }),
    });

    promise
      .then(() => {
        targetCompleted
          ? captureCompleteGoal('edit-target-modal')
          : captureGoalEdited(goal.totalAmount, amount, goal.date, date, analyticsSource);

        addMessage(
          targetCompleted
            ? `'${name}' completed`
            : targetRestored
            ? `'${name}' restored`
            : `'${name}' updated`,
          {
            variant: 'success',
          }
        );
      })
      .catch(addDefaultErrorMessage);

    onExit();

    return promise;
  }

  public handleDeleteAction() {
    this.pushModalOntoStack('delete-warning');
    this.props.blockModalCloseFunctions();
  }

  public handleRestoreAction() {
    this.pushModalOntoStack('restore');
    this.setState({
      amount: this.props.goal.paidOutAmount || 0,
      date: addMonthsToDate(1, new Date()),
      paidOut: false,
      paidOutAmount: null,
      paidOutDate: null,
    });
  }

  public revertChanges() {
    const { date, totalAmount, paidOut, paidOutAmount, paidOutDate } = this.props.goal;
    this.setState({ amount: totalAmount, date, paidOut, paidOutAmount, paidOutDate });
  }

  /**
   * OK: paid out, expired
   * OK: paid out, nonexpired
   * OK: nonpaid out, nonexpired
   * if they change it to expired, then it must be paid out
   * if they unpay out, then it must be NONEXPIRED.
   */

  public handlePaidOutAction() {
    this.pushModalOntoStack('paid-out');
    const hasExpired =
      getDateDiffInMonths(this.state.date) < 0 && getDateDiffInMonths(this.props.goal.date) >= 0;
    this.setState({
      paidOut: true,
      paidOutAmount: this.props.goal.currentAmount || 0,
      paidOutDate: hasExpired ? this.state.date : new Date(),
    });
  }

  public handleDeleteConfirmation() {
    const {
      deleteGoalRequest,
      goal,
      onExit,
      analyticsSource,
      addMessage,
      addDefaultErrorMessage,
    } = this.props;

    deleteGoalRequest(goal.id)
      .then(() => {
        addMessage(`'${this.state.name}' deleted`, { variant: 'success' });
        captureTargetDeleted(analyticsSource);
      })
      .catch(addDefaultErrorMessage);

    onExit();
  }

  public handleDeleteRejection() {
    this.props.allowModalCloseFunctions();
    this.popModalOffStack();
  }

  public handleCloseConfirmation() {
    this.props.onExit();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { goal, setHasDataChanged } = this.props;
    const { name, amount, date } = this.state;
    const hasNoChanges =
      name === goal.name &&
      amount === goal.totalAmount &&
      getDateDiffInMonths(date, goal.date) === 0;

    if (hasNoChanges) {
      setHasDataChanged(false);
    } else {
      setHasDataChanged(true);
    }

    if (prevProps.transitionStatus === 'exiting' && this.props.transitionStatus === 'exited') {
      this.props.cancelDiscardWarning();
    }
  }

  componentWillUnmount() {
    const { allowModalCloseFunctions, setHasDataChanged } = this.props;

    allowModalCloseFunctions();
    setHasDataChanged(false);
  }

  public render() {
    const {
      transitionStatus,
      archivable,
      goal,
      targetAmountLimit,
      setToMinimumMonthlySavingsAndCloseModal,
      minimumMonthlySavings,
      monthlySavings,
      shouldShowWarningContent,
      cancelDiscardWarning,
    } = this.props;
    const { name, amount, date, paidOutAmount, paidOutDate, modalStack } = this.state;

    const currentModal = shouldShowWarningContent
      ? 'discard-warning'
      : modalStack[modalStack.length - 1];

    return (
      <React.Fragment>
        {currentModal === 'delete-warning' && (
          <WarningModalContent
            header="Delete target?"
            description="You cannot undo this action."
            handleConfirmation={this.handleDeleteConfirmation}
            handleRejection={this.handleDeleteRejection}
            confirmationButtonText="Delete"
            rejectionButtonText="Cancel"
          />
        )}
        {currentModal === 'discard-warning' && (
          <WarningModalContent
            header="Discard changes?"
            handleConfirmation={this.handleCloseConfirmation}
            handleRejection={cancelDiscardWarning}
            confirmationButtonText="Discard"
            rejectionButtonText="Cancel"
          />
        )}
        {currentModal === 'paid-out' && (
          <EditGoalContentPaidOutContainer
            goal={goal}
            name={name}
            paidOutAmount={paidOutAmount as number}
            paidOutDate={paidOutDate as Date}
            transitionStatus={transitionStatus}
            saveToStore={this.saveToStore}
            updatePaidOutAmount={this.updatePaidOutAmount}
            closePaidOutTargetModal={() => {
              this.popModalOffStack();
              this.revertChanges();
            }}
          />
        )}
        {currentModal === 'edit' && (
          <EditGoalContent
            goal={goal}
            name={name}
            amount={amount}
            date={date}
            transitionStatus={transitionStatus}
            handleDeleteAction={this.handleDeleteAction}
            saveToStore={this.saveToStore}
            updateAmount={this.updateAmount}
            updateDate={this.updateDate}
            updateName={this.updateName}
            archivable={archivable}
            thresholdAmount={targetAmountLimit}
            setToMinimumMonthlySavingsAndCloseModal={setToMinimumMonthlySavingsAndCloseModal}
            minimumMonthlySavings={minimumMonthlySavings}
            monthlySavings={monthlySavings}
            openPaidOutTargetModal={this.handlePaidOutAction}
          />
        )}
        {currentModal === 'restore' && (
          <RestoreGoalContent
            name={name}
            amount={amount}
            date={date}
            transitionStatus={transitionStatus}
            handleBackAction={() => {
              this.popModalOffStack();
              this.revertChanges();
            }}
            updateAmount={this.updateAmount}
            updateDate={this.updateDate}
            updateName={this.updateName}
            thresholdAmount={targetAmountLimit}
            saveToStore={this.saveToStore}
          />
        )}
        {currentModal === 'completed' && (
          <CompletedGoalContent
            goal={goal}
            transitionStatus={transitionStatus}
            handleDeleteAction={this.handleDeleteAction}
            handleRestoreAction={this.handleRestoreAction}
          />
        )}
      </React.Fragment>
    );
  }
}

export const EditGoalModalTogglerContainer = connect<
  IStateProps,
  IDispatchProps,
  IOwnProps,
  IStore
>(
  mapStateToProps,
  mapDispatchToProps
)(EditGoalModalToggler);

import React, { useState } from 'react';
import styled from 'styled-components';

import { SwirlImage } from 'assets/LandingPage';
import { Heading, Carousel, TabHeaderGroup } from 'components/Common';
import { typography, colorScale } from 'themes';

import { SectionWrapper, StyledContainer } from '../../sharedStyles';

import { infoText, tabHeaderList } from './constants';
import { DemoCarousel } from './DemoCarousel';

const Wrapper = styled(SectionWrapper)`
  overflow: hidden;
  background: linear-gradient(${colorScale('grey', 10)} 35%, ${colorScale('primary', 20)});
`;

const InnerWrapper = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  width: 100%;
`;

const SwirlWrapper = styled.div`
  position: relative;
  width: 60%;
`;

const StyledSwirlImage = styled(SwirlImage)`
  position: absolute;
  left: 24px;
  width: fit-content;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const TabHeaderGroupWrapper = styled.div`
  margin-top: 24px;
`;

const StyledHeading = styled(Heading)`
  ${typography('heading-l')};
  color: ${colorScale('primary', 50)};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px 16px 0;
`;

const Text = styled.span`
  ${typography('heading-m')};
  text-align: center;
`;

export const MobileDemoSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Wrapper>
      <InnerWrapper>
        <HeadingWrapper>
          <StyledHeading>What can you do on MyMoneySense?</StyledHeading>
          <SwirlWrapper>
            <StyledSwirlImage />
          </SwirlWrapper>
        </HeadingWrapper>
        <TabHeaderGroupWrapper>
          <TabHeaderGroup
            variant="non-scrollable"
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            tabs={tabHeaderList}
          />
        </TabHeaderGroupWrapper>
        <Carousel
          targetNumberOfItemsToShow={1}
          showDots={false}
          showArrows={false}
          peekAmount={0}
          targetOverflowAmount={0}
          gridGap={0}
          targetSelectedItemIndex={selectedIndex}
          setSelectedItemIndex={setSelectedIndex}
          isControllable={false}
        >
          {infoText.map((text, i) => (
            <TextWrapper key={i}>
              <Text>{text}</Text>
            </TextWrapper>
          ))}
        </Carousel>
        <ContentWrapper>
          <DemoCarousel selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        </ContentWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getMainModuleDetails } from 'redux/modules/moduleDetails/moduleDetails';

import { Lock } from 'assets/icons';
import { Panel } from 'components/Common';
import { MAIN_MODULE } from 'constants/enums';
import { typeScale, colorScale } from 'themes';

const Banner = styled(Panel)`
  background: ${colorScale('supporting-yellow', 10)};
  border: 1px solid ${colorScale('supporting-yellow', 20)};
  display: flex;
  margin: 24px 0;
  padding: 24px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background: ${colorScale('supporting-yellow', 20)};
  margin-right: 16px;
`;

const StyledLock = styled(Lock)`
  flex-shrink: 0;
  font-size: ${typeScale(2)};
  color: ${colorScale('secondary', 30)};
`;

const WarningText = styled.p`
  font-size: ${typeScale(-1)};
  color: ${colorScale('supporting-yellow', 50)};
  margin: 0;
`;

interface IProps {
  blockingModules: MAIN_MODULE[];
}

export const BlockedBanner: React.FC<IProps> = ({ blockingModules }) => {
  const mainModulesDetails = useSelector(getMainModuleDetails);
  const moduleNames = blockingModules.map(
    (blockingModule) => mainModulesDetails[blockingModule].name
  );
  return (
    <Banner>
      <Circle>
        <StyledLock />
      </Circle>
      <WarningText>
        Unlock this section by completing {moduleNames.length === 2 ? 'both' : ''} the{' '}
        <strong>
          {moduleNames.reduce((acc, curr, index) => {
            return index !== moduleNames.length - 1 ? `${acc}, ${curr}` : `${acc} and ${curr}`;
          })}
        </strong>{' '}
        section
        {moduleNames.length > 1 ? 's' : ''}
      </WarningText>
    </Banner>
  );
};

import React from 'react';
import { ToastProvider as DefaultToastProvider } from 'react-toast-notifications';

import { Toast } from './Toast';
import { ToastContainer } from './ToastContainer';

interface IToastProviderProps {
  autoDismiss: boolean;
  autoDismissTimeout: number;
  children: React.ReactNode;
  components: {
    Toast: typeof Toast;
    ToastContainer: typeof ToastContainer;
  };
  pauseOnHover: boolean;
  transitionDuration: number;
  showProgressBar: boolean;
  showCloseButton: boolean;
  variant: 'default' | 'success' | 'error';
}

export class ToastProvider extends React.PureComponent<IToastProviderProps> {
  public static defaultProps = {
    autoDismiss: true,
    autoDismissTimeout: 3500,
    components: { Toast, ToastContainer },
    pauseOnHover: false,
    transitionDuration: 220,
    showProgressBar: false,
    showCloseButton: false,
    variant: 'default',
  };

  public render() {
    return <DefaultToastProvider {...this.props} />;
  }
}

import { formatDate } from 'utilities/dateUtilities';

export const formatDisplayName = (sp: string, productName: string) => {
  if (sp === 'DBS' && productName.includes('POSB')) {
    return productName;
  } else if (productName.includes(sp)) {
    return productName;
  } else {
    return sp + ' ' + productName;
  }
};

export const formatMaskedAccountNumber = (accountNumber: string) =>
  accountNumber.replace(/x/g, 'X');

export const formatAccountType = (accountRelationship: string, accountType: string) => {
  if (accountType === 'Loan') {
    if (accountRelationship.startsWith('Joint')) {
      return 'Joint loan';
    } else {
      return '';
    }
  }

  const suffix = accountType === 'Fixed deposit' ? '' : ' account';

  if (accountRelationship.startsWith('Joint')) {
    return 'Joint ' + accountType.toLowerCase() + suffix;
  } else {
    return accountType + suffix;
  }
};

export const formatBalanceMonth = (dateString: string) => {
  const month = parseInt(dateString.substring(0, 2));
  const year = parseInt(dateString.substring(2, 6));
  const date: Date = new Date(year, month - 1);
  return formatDate(date, {
    withDay: true,
    shouldConvertToOrdinalNumber: false,
    forceLastDay: true,
  });
};

export const capitalise = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const formatMortgageType = (productName: string) => {
  const pn = productName.toLowerCase();
  const isMortgage =
    pn.includes('mortgage') ||
    pn.includes('home') ||
    pn.includes('property') ||
    pn.includes('housing');
  return isMortgage ? 'Mortgage' : 'Non-mortgage';
};

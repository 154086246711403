import { getSortedMonthlyExpenses } from 'redux/finances/selectors';
import { ISubscription, ISubscriptionCallback } from 'redux/subscriptionManager/interfaces';
import { getNumEmergencyFundMonths } from 'redux/targets/selectors';
import { getGoals } from 'redux/targets/selectors';

import { RECOMMENDED_ACTION } from 'constants/enums';
import { IStore } from 'constants/storeTypes';

import {
  isRecommendedActionRelevantToUser,
  markSystemRecommendedActionAsCompleted,
} from './helpers';

export const traSRBCallback: ISubscriptionCallback = (prevStore: IStore, currentStore: IStore) => {
  const key = RECOMMENDED_ACTION.TRA_SR_B;
  const isRelevant = isRecommendedActionRelevantToUser(prevStore, key);
  if (!isRelevant) {
    return;
  }

  const hasGoals = Object.values(getGoals(currentStore)).length > 0;
  if (hasGoals) {
    markSystemRecommendedActionAsCompleted(key);
  }
};

export const traSRCCallback: ISubscriptionCallback = (prevStore: IStore, currentStore: IStore) => {
  const key = RECOMMENDED_ACTION.TRA_SR_C;
  const isRelevant = isRecommendedActionRelevantToUser(prevStore, key);
  if (!isRelevant) {
    return;
  }

  const numMonthsEmergencyFundCanLast = getNumEmergencyFundMonths(currentStore);
  if (numMonthsEmergencyFundCanLast >= 6) {
    markSystemRecommendedActionAsCompleted(key);
  }
};

export const traSRDCallback: ISubscriptionCallback = (prevStore: IStore, currentStore: IStore) => {
  const key = RECOMMENDED_ACTION.TRA_SR_D;
  const isRelevant = isRecommendedActionRelevantToUser(prevStore, key);
  if (!isRelevant) {
    return;
  }

  const numMonthsEmergencyFundCanLast = getNumEmergencyFundMonths(currentStore);
  if (numMonthsEmergencyFundCanLast >= 3) {
    markSystemRecommendedActionAsCompleted(key);
  }
};

export const traSRECallback: ISubscriptionCallback = (prevStore: IStore, currentStore: IStore) => {
  const key = RECOMMENDED_ACTION.TRA_SR_E;
  const isRelevant = isRecommendedActionRelevantToUser(prevStore, key);
  if (!isRelevant) {
    return;
  }

  const monthlyExpenses = getSortedMonthlyExpenses(currentStore);
  const incompleteBudgets = monthlyExpenses.filter(({ budgetAmount }) => !budgetAmount);

  if (incompleteBudgets.length === 0) {
    markSystemRecommendedActionAsCompleted(key);
  }
};

const traSRB: ISubscription = { stateKeys: ['targets.goals'], callback: traSRBCallback };
const traSRC: ISubscription = {
  stateKeys: [
    'targets.emergencyFund',
    'finances.expenses',
    'finances.estimatedMonthlyExpenses',
    'finances.loanRepayments',
  ],
  callback: traSRCCallback,
};
const traSRD: ISubscription = {
  stateKeys: [
    'targets.emergencyFund',
    'finances.expenses',
    'finances.estimatedMonthlyExpenses',
    'finances.loanRepayments',
  ],
  callback: traSRDCallback,
};
const traSRE: ISubscription = { stateKeys: ['finances.expenses'], callback: traSRECallback };

export const recommendedActionsSubscriptions = [traSRB, traSRC, traSRD, traSRE];

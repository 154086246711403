import React from 'react';
import styled, { css } from 'styled-components';

import { WarningModalContent, Modal } from 'components/Common';
import { minWidth } from 'themes';

import { DEFAULT_WARNING_MODAL_INFO } from './defaultWarningModalInfo';

const StyledModal = styled(Modal)`
  ${minWidth(
    'tablet',
    css`
      width: 480px;
    `
  )}
`;

interface IProps {
  mounted: boolean;
  setMounted: (mounted: boolean) => void;
  message: string;
  callback: (answer: boolean) => void;
}

export const NavigationBlockerModal: React.FC<IProps> = ({
  mounted,
  setMounted,
  message,
  callback,
}) => {
  const warningModalInfo = {
    ...DEFAULT_WARNING_MODAL_INFO,
    description: message,
  };

  const handleConfirmation = () => {
    callback(true);
    setMounted(false);
  };

  const handleRejection = () => {
    callback(false);
    setMounted(false);
  };

  return (
    <StyledModal
      mounted={mounted}
      titleText="warning-modal"
      alert={true}
      onExit={handleRejection}
      showCloseButton={false}
      escapeExits={false}
      underlayClickExits={false}
    >
      {warningModalInfo && (
        <WarningModalContent
          {...warningModalInfo}
          handleConfirmation={handleConfirmation}
          handleRejection={handleRejection}
        />
      )}
    </StyledModal>
  );
};

import 'index.css';
import 'intersection-observer';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import './nonce';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ToastConsumer } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';

import { subscriptions } from 'redux/reduxSubscriptions';
import { subscriptionManager } from 'redux/subscriptionManager/subscriptionManager';

import { initialiseAnalytics } from 'analytics';
import { withPageTracker } from 'analytics/withPageTracker';
import { SessionWrapperWithRouter } from 'components/Sessions/SessionContext';
import { configureRequests } from 'utilities/requestUtilities';
import { getSentryAuthorized } from 'utilities/sentryUtilities';

import { App } from './components/App/App';
import { CacheBuster, ToastProvider, MessageManager, RouterWithBlocker } from './components/Common';
import { queryClient } from './queryClient';
import registerServiceWorker from './registerServiceWorker';
import { store } from './store';
import { GlobalStyle, theme } from './themes';
import './i18n';

subscriptionManager.init(store);
subscriptionManager.subscribe(subscriptions);

initialiseAnalytics();
configureRequests();

if (getSentryAuthorized()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <CookiesProvider>
    <CacheBuster>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <ToastProvider>
                <React.Fragment>
                  <GlobalStyle />
                  <RouterWithBlocker>
                    <SessionWrapperWithRouter>
                      <Route component={withPageTracker(App)} />
                    </SessionWrapperWithRouter>
                  </RouterWithBlocker>
                </React.Fragment>
              </ToastProvider>
              <MessageManager provider={ToastProvider} consumer={ToastConsumer} />
            </React.Fragment>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </CacheBuster>
  </CookiesProvider>,
  document.getElementById('root') as HTMLElement
);
registerServiceWorker();

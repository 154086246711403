import React from 'react';
import styled, { css } from 'styled-components';

import { LadyWithClipboardImage } from 'assets/expenseManager/ExpenseManagerPage';
import { HdbFlatsImage } from 'assets/Housing';
import { Banner } from 'components/Composite/Banner/Banner';
import { maxWidth, typography } from 'themes';

import { SectionWrapper, StyledContainer } from '../sharedStyles';

import { MainHeading } from './sharedStyles';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Text = styled.p`
  ${maxWidth(
    'mobile',
    css`
      ${typography('body-s')}
    `
  )}
  ${typography('body-l')}
`;

export const ToolsSection: React.FC = () => (
  <SectionWrapper>
    <StyledContainer>
      <MainHeading>Quick tools and guides</MainHeading>
      <Text>Check out some of our tools and content before logging in.</Text>
      <Grid>
        <Banner
          title="Try our expense manager"
          text="Find out how you can reduce your expenses."
          toPath="/expense-manager"
          image={<LadyWithClipboardImage />}
        />
        <Banner
          title="Buying your first home"
          text="Read our purchase guide and create a home financing plan."
          toPath="/buying-a-house"
          image={<HdbFlatsImage />}
        />
      </Grid>
    </StyledContainer>
  </SectionWrapper>
);

export default ToolsSection;

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import { View } from '@react-pdf/renderer';
import React from 'react';

import { formatCurrency } from 'utilities/currencyUtilities';

import { IBreakdownTableProps } from '../../interfaces';

import { BreakdownTableStyles as styles } from './BreakdownTableStyles';

export const BreakdownTable: React.FC<IBreakdownTableProps> = ({
  expenses,
  startMonth,
  endMonth,
  showTotalRow,
  totalCurrentAmount,
  totalBudgetAmount,
}) => (
  <View>
    <Table data={expenses}>
      <TableHeader
        includeTopBorder={false}
        includeRightBorder={false}
        includeBottomBorder={false}
        includeLeftBorder={false}
      >
        <TableCell weighting={0.3} style={[styles.headerCell, { borderTopLeftRadius: 6 }]}>
          Category
        </TableCell>
        <TableCell weighting={0.25} style={styles.headerCell}>
          Current expenses
        </TableCell>
        <TableCell weighting={0.2} style={styles.headerCell}>
          Budget
        </TableCell>
        <TableCell weighting={0.125} style={styles.headerCell}>
          {startMonth}
        </TableCell>
        <TableCell weighting={0.125} style={[styles.headerCell, { borderTopRightRadius: 6 }]}>
          {endMonth}
        </TableCell>
      </TableHeader>
      <TableBody
        includeTopBorder={false}
        includeRightBorder={false}
        includeBottomBorder={false}
        includeLeftBorder={false}
      >
        <DataTableCell
          weighting={0.3}
          style={styles.tableCellAltFirst}
          getContent={(r) => r.category}
        />
        <DataTableCell
          weighting={0.25}
          style={styles.tableCell}
          getContent={(r) => formatCurrency(r.amount)}
        />
        <DataTableCell
          weighting={0.2}
          style={styles.tableCellAlt}
          getContent={(r) => formatCurrency(r.budgetAmount)}
        />
        <DataTableCell weighting={0.125} style={styles.tableCell} getContent={() => ''} />
        <DataTableCell weighting={0.125} style={styles.tableCellAltLast} getContent={() => ''} />
      </TableBody>
    </Table>
    {showTotalRow && (
      <TableBody
        includeTopBorder={false}
        includeRightBorder={false}
        includeBottomBorder={false}
        includeLeftBorder={false}
        data={[
          {
            category: 'Total',
            currentAmount: totalCurrentAmount,
            budgetAmount: totalBudgetAmount,
          },
        ]}
      >
        <DataTableCell
          weighting={0.3}
          style={[styles.tableCellAltFirst, { fontWeight: 'bold' }]}
          getContent={(r) => r.category}
        />
        <DataTableCell
          weighting={0.25}
          style={[styles.tableCell, { fontWeight: 'bold' }]}
          getContent={(r) => formatCurrency(r.currentAmount)}
        />
        <DataTableCell
          weighting={0.2}
          style={[styles.tableCellAlt, { fontWeight: 'bold' }]}
          getContent={(r) => formatCurrency(r.budgetAmount)}
        />
        <DataTableCell weighting={0.125} style={styles.tableCell} getContent={() => ''} />
        <DataTableCell weighting={0.125} style={styles.tableCellAltLast} getContent={() => ''} />
      </TableBody>
    )}
  </View>
);

import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { ArrowDown, ArrowUp } from 'assets/icons';
import { Button, Section, Heading } from 'components/Common';
import { typography, colorScale } from 'themes';
import { scrollElementIntoViewById } from 'utilities/animationUtilities';

import {
  NUMBERING_CIRCLE_SIZE_PX,
  GAP_BETWEEN_NUMBERING_AND_TITLE_PX,
  GAP_BETWEEN_BUTTON_AND_CONTENT_PX,
} from './constants';

// Grid css is in a div inside the button instead of in the button itself
// because it doesn't work for Safari when it's done in the button
// https://stackoverflow.com/questions/44301065/button-with-display-flex-does-not-work-in-safari-causing-size-and-alignment-is
const ButtonContent = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  background: none;
  padding: 0;
  margin-bottom: ${GAP_BETWEEN_BUTTON_AND_CONTENT_PX};
  box-shadow: none;
  border: 0;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const CircleCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${NUMBERING_CIRCLE_SIZE_PX};
  height: ${NUMBERING_CIRCLE_SIZE_PX};
  padding: 8px;
  
  background: ${colorScale('primary', 50)};
  border-radius: ${fromTheme('borders.radiusRound')};
  ${typography('heading-s')}
  color: ${colorScale('grey', 0)};
`;

const Title = styled(Heading)`
  ${typography('heading-m')}
  margin: 0 ${GAP_BETWEEN_NUMBERING_AND_TITLE_PX};
`;

const arrowStyles = css`
  ${typography('heading-l')};
  color: ${colorScale('primary', 50)};
  justify-self: flex-end;
`;

const StyledArrowDown = styled(ArrowDown)`
  ${arrowStyles}
`;

const StyledArrowUp = styled(ArrowUp)`
  ${arrowStyles}
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-${GAP_BETWEEN_BUTTON_AND_CONTENT_PX});
  };
  to {
    opacity: 1;
    transform: translateY(0);
  };
`;

const ContentWrapper = styled.div`
  animation: ${slideDown} ${fromTheme('transitions.durationLonger')}
    cubic-bezier(0.165, 0.84, 0.44, 1);
`;

interface IProps {
  numbering: number;
  title: string;
  openedSection: number | null;
  setOpenedSection: (openedSection: number | null) => void;
  children: React.ReactNode;
}

/**
 * Displays a section in the housing accordion, completed with section numbering and a
 * button to expand or collapse its content.
 * @param numbering Number to show the order of the section, akin to the numbering in a numbered list
 * @param title
 * @param openedSection For determining if the section should be expanded or collapsed
 * @param setOpenedSection For determining how to expand or collapse the section
 * @param children A block of HousingAccordionSubsection
 */
export const HousingAccordionSection = ({
  numbering,
  title,
  openedSection,
  setOpenedSection,
  children,
}: IProps) => {
  const isOpened = openedSection === numbering;

  return (
    <Section id={numbering.toString()}>
      <StyledButton
        variant="panel"
        widthStyle="fluid"
        onClick={() => {
          setOpenedSection(isOpened ? null : numbering);
          scrollElementIntoViewById(numbering.toString());
        }}
      >
        <ButtonContent>
          <CircleCounter>{numbering}</CircleCounter>
          <Title>{title}</Title>
          {isOpened ? <StyledArrowUp /> : <StyledArrowDown />}
        </ButtonContent>
      </StyledButton>

      {isOpened && <ContentWrapper>{children}</ContentWrapper>}
    </Section>
  );
};

import React from 'react';
import MediaQuery from 'react-responsive';
import { useLocation } from 'react-router';
import { withTheme } from 'styled-components';

import { Logo } from 'components/Common';
import { navigationDetails } from 'constants/navigationConstants';
import { LOGIN, RESOURCES } from 'constants/urls';
import { ITheme } from 'themes';
import { flatten } from 'utilities/objectUtilities';

import { NavigationBarComponents } from './NavigationBarComponents';

const PreLoginNavigationBarComponent = ({ theme }: ITheme) => {
  const {
    LogoWrapper,
    LinksWrapper,
    HamburgerMenu,
    NavLink,
    ExternalLink,
    VerticalDivider,
    TabletSearchBar,
    DesktopSearchBar,
    SectionLabel,
    Dropdown,
    HorizontalDivider,
  } = NavigationBarComponents;

  const location = useLocation();

  type NestedNav = 'resources';

  const navWithNestedItems = {
    resources: Object.values(flatten(RESOURCES)) as string[],
  };
  const isNestedPageActive = (tab: NestedNav) =>
    navWithNestedItems[tab].some((item: string) => location.pathname.includes(item));

  return (
    <MediaQuery maxWidth={theme.breakpoints.tabletMax}>
      {(matches) =>
        matches ? (
          <React.Fragment>
            <LogoWrapper to="/">
              <Logo />
            </LogoWrapper>
            <LinksWrapper>
              <NavLink to={LOGIN.singpassRedirect}>Log in</NavLink>
              <HamburgerMenu
                renderMenuItems={({ setIsMenuOpened }) => {
                  const closeMenu = () => setIsMenuOpened(false);
                  return (
                    <React.Fragment>
                      <TabletSearchBar closeMenu={closeMenu} />
                      <SectionLabel>Resources by topics</SectionLabel>
                      <NavLink to={navigationDetails.SAVINGS.urlPath} onClick={closeMenu}>
                        {navigationDetails.SAVINGS.title}
                      </NavLink>
                      <NavLink to={navigationDetails.LOANS_AND_CREDIT.urlPath} onClick={closeMenu}>
                        {navigationDetails.LOANS_AND_CREDIT.title}
                      </NavLink>
                      <NavLink to={navigationDetails.INVESTMENTS.urlPath} onClick={closeMenu}>
                        {navigationDetails.INVESTMENTS.title}
                      </NavLink>
                      <NavLink to={navigationDetails.INSURANCE.urlPath} onClick={closeMenu}>
                        {navigationDetails.INSURANCE.title}
                      </NavLink>
                      <SectionLabel>Resources by milestones</SectionLabel>
                      <NavLink to={navigationDetails.HOUSING.urlPath} onClick={closeMenu}>
                        {navigationDetails.HOUSING.title}
                      </NavLink>
                      <NavLink to={navigationDetails.RETIREMENT.urlPath} onClick={closeMenu}>
                        {navigationDetails.RETIREMENT.title}
                      </NavLink>
                      <HorizontalDivider />
                      <NavLink to={navigationDetails.ABOUT_US.urlPath} onClick={closeMenu}>
                        {navigationDetails.ABOUT_US.title}
                      </NavLink>
                      <ExternalLink
                        href={process.env.REACT_APP_CONTACT_US_FORM_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={closeMenu}
                      >
                        Contact us
                      </ExternalLink>
                    </React.Fragment>
                  );
                }}
              />
            </LinksWrapper>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <LogoWrapper to="/">
              <Logo />
            </LogoWrapper>
            <LinksWrapper>
              <Dropdown label="Resources" isChildActive={isNestedPageActive('resources')}>
                <SectionLabel>Resources by topics</SectionLabel>
                <NavLink to={navigationDetails.SAVINGS.urlPath}>
                  {navigationDetails.SAVINGS.title}
                </NavLink>
                <NavLink to={navigationDetails.LOANS_AND_CREDIT.urlPath}>
                  {navigationDetails.LOANS_AND_CREDIT.title}
                </NavLink>
                <NavLink to={navigationDetails.INVESTMENTS.urlPath}>
                  {navigationDetails.INVESTMENTS.title}
                </NavLink>
                <NavLink to={navigationDetails.INSURANCE.urlPath}>
                  {navigationDetails.INSURANCE.title}
                </NavLink>
                <SectionLabel>Resources by milestones</SectionLabel>
                <NavLink to={navigationDetails.HOUSING.urlPath}>
                  {navigationDetails.HOUSING.title}
                </NavLink>
                <NavLink to={navigationDetails.RETIREMENT.urlPath}>
                  {navigationDetails.RETIREMENT.title}
                </NavLink>
              </Dropdown>
              <NavLink to={navigationDetails.ABOUT_US.urlPath}>
                {navigationDetails.ABOUT_US.title}
              </NavLink>
              <ExternalLink
                href={process.env.REACT_APP_CONTACT_US_FORM_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us
              </ExternalLink>
              <VerticalDivider />
              <NavLink to={LOGIN.singpassRedirect}>Log in</NavLink>
              <DesktopSearchBar />
            </LinksWrapper>
          </React.Fragment>
        )
      }
    </MediaQuery>
  );
};

export const PreLoginNavigationBar = withTheme(PreLoginNavigationBarComponent);

import { Reducer } from 'redux';

import { IModulesLiteActions } from 'constants/actionTypes';
import { MODULE_LITE, MODULES_LITE_ACTIONS } from 'constants/enums';
import { IModulesLiteStore } from 'constants/storeTypes';

import { mockInitialStates } from '../mockInitialStates';

export const initialState: IModulesLiteStore = {
  [MODULE_LITE.EXPENSE_MANAGER]: {
    module: MODULE_LITE.EXPENSE_MANAGER,
    currentStepIndex: null,
    completed: false,
  },
};

export const modulesLiteReducer: Reducer<IModulesLiteStore, IModulesLiteActions> = (
  state: IModulesLiteStore = process.env.REACT_APP_DEPLOYMENT_ENV &&
  ['development', 'staging'].includes(process.env.REACT_APP_DEPLOYMENT_ENV) &&
  mockInitialStates.modulesLite !== null
    ? mockInitialStates.modulesLite
    : initialState,
  action: IModulesLiteActions
) => {
  switch (action.type) {
    case MODULES_LITE_ACTIONS.SET_CURRENT_STEP_INDEX:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          currentStepIndex: action.currentStepIndex,
        },
      };

    case MODULES_LITE_ACTIONS.SET_MODULE_COMPLETED:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          completed: true,
        },
      };

    default:
      return state;
  }
};

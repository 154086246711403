import { theme, colorScale } from 'themes';

import { getColorIndexes } from './getColorIndexes';

const defaultColorArray = [
  // 0
  {
    main: colorScale('supporting-blue', 30)({ theme }),
    text: colorScale('supporting-blue', 50)({ theme }),
  },
  // 1
  {
    main: colorScale('supporting-teal', 50)({ theme }),
    text: colorScale('supporting-teal', 50)({ theme }),
  },
  // 2
  {
    main: colorScale('supporting-pink', 30)({ theme }),
    text: colorScale('supporting-pink', 50)({ theme }),
  },
  // 3
  {
    main: colorScale('primary', 50)({ theme }),
    text: colorScale('primary', 40)({ theme }),
  },
  // 4
  {
    main: colorScale('secondary', 30)({ theme }),
    text: colorScale('secondary', 50)({ theme }),
  },
  // 5
  {
    main: colorScale('supporting-cobalt', 30)({ theme }),
    text: colorScale('supporting-cobalt', 50)({ theme }),
  },
  // 6
  {
    main: colorScale('supporting-red', 50)({ theme }),
    text: colorScale('supporting-red', 50)({ theme }),
  },
  // 7
  {
    main: colorScale('supporting-yellow', 30)({ theme }),
    text: colorScale('supporting-yellow', 50)({ theme }),
  },
  // 8
  {
    main: colorScale('grey', 90)({ theme }),
    text: colorScale('grey', 90)({ theme }),
  },
];

const defaultUnallowedAdjacentColors = {
  '0': ['2'],
  '4': ['7'],
  '1': ['6', '3'],
};

interface IGetColoredArrayOptions {
  colorArray?: { main: string; text: string }[];
  unallowedAdjacentColors?: { [key: string]: string[] };
  seed?: string;
}

export const getColoredArray = <T extends {}>(
  entries: T[],
  {
    colorArray = defaultColorArray,
    unallowedAdjacentColors = defaultUnallowedAdjacentColors,
    seed,
  }: IGetColoredArrayOptions = {}
): (T & { color: { main: string; text: string } })[] => {
  const colorIndexes = getColorIndexes(
    entries.length,
    colorArray.length,
    unallowedAdjacentColors,
    seed
  );

  const coloredEntries = entries.map((entry, i) => ({
    ...entry,
    color: colorArray[colorIndexes[i]],
  }));

  return coloredEntries;
};

import { Reducer } from 'redux';

import { IAllActions } from 'constants/actionTypes';
import { DATA_STORAGE_ACTIONS } from 'constants/enums';
import { IStore } from 'constants/storeTypes';

export const crossSliceReducer: Reducer<IStore | undefined, IAllActions> = (state, action) => {
  if (DATA_STORAGE_ACTIONS.CLEAR_REDUX_DATA === action.type) {
    return undefined;
  }
  return state;
};

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { getNumberOfUnacknowledgedNotifications } from 'redux/notifications/selectors';

import { Bell } from 'assets/icons';
import { typeScale, colorScale } from 'themes';

const Wrapper = styled.div`
  position: relative;
`;

const StyledBell = styled(Bell)`
  font-size: ${typeScale(3)};
`;

const NumberTag = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${fromTheme('borders.radiusRound')};
  padding: 6px;
  min-width: 20px;
  height: 20px;
  background-color: ${colorScale('supporting-red', 40)};
  color: white;
  font-weight: 600;
  font-size: ${typeScale(-4)};
`;

export const NotificationBell = () => {
  const numberOfUnacknowledgedNotifications = useSelector(getNumberOfUnacknowledgedNotifications);
  return (
    <Wrapper>
      <StyledBell />
      {numberOfUnacknowledgedNotifications > 0 && (
        <NumberTag>{numberOfUnacknowledgedNotifications}</NumberTag>
      )}
    </Wrapper>
  );
};

import { IAddToastOptions } from 'components/Common';
import { IAddMessage, ISetGlobalModal } from 'constants/actionTypes';
import { MESSAGES_ACTIONS, GLOBAL_MODAL } from 'constants/enums';
import { IError } from 'constants/storeTypes';

export const addMessage = (
  content: React.ReactNode,
  options: IAddToastOptions = {}
): IAddMessage => ({
  type: MESSAGES_ACTIONS.ADD_MESSAGE,
  content,
  options,
});

export const addDefaultErrorMessage = (error?: IError): IAddMessage => {
  const errorResponse = error && error.response;
  const status = errorResponse && errorResponse.status;
  let errorMessage = errorResponse && errorResponse.data && errorResponse.data.errorMessage;

  if (!errorMessage) {
    switch (status) {
      case 400:
        errorMessage = "There's a problem submitting your request. Please try again.";
        break;

      case 401:
        errorMessage = 'Your session has expired. Refresh the page to sign in again.';
        break;

      case 500:
      default:
        errorMessage =
          "Something went wrong and we couldn't complete your request. Please try again later.";
        break;
    }
  }

  return addMessage(errorMessage, { variant: 'error', autoDismiss: false });
};

export const setGlobalModal = (globalModal: GLOBAL_MODAL | null): ISetGlobalModal => ({
  type: MESSAGES_ACTIONS.SET_GLOBAL_MODAL,
  globalModal,
});

import React from 'react';
import styled, { css } from 'styled-components';

import { CurrencyPrefix } from 'components/Common';
import {
  ArticleHyperlink,
  ArticleParagraph,
  ArticleOrderedList,
  ArticleListItem,
} from 'components/Templates';
import { minWidth } from 'themes';

const StyledArticleOrderedList = styled(ArticleOrderedList)`
  ${minWidth(
    'tablet',
    css`
      padding-left: 48px;
    `
  )}
`;

export const MonetiseYourProperty = () => {
  return (
    <>
      <ArticleParagraph>
        With some planning, your property can be a source of stable income to supplement your
        retirement needs. You can consider the following options:
      </ArticleParagraph>

      <StyledArticleOrderedList variant="alpha">
        <ArticleListItem>
          Move to a 3-room or smaller HDB flat and consider joining the{' '}
          <ArticleHyperlink url="https://www.hdb.gov.sg/residential/living-in-an-hdb-flat/for-our-seniors/monetising-your-flat-for-retirement/silver-housing-bonus">
            Silver Housing Bonus (SHB) scheme
          </ArticleHyperlink>
          . The SHB offers a cash bonus of up <CurrencyPrefix>30,000</CurrencyPrefix> to when you
          use some of your sale proceeds to top-up your CPF Retirement Account (RA) and join CPF
          LIFE.
        </ArticleListItem>

        <ArticleListItem>
          <ArticleHyperlink url="https://www.hdb.gov.sg/residential/renting-out-a-flat-bedroom">
            Rent out your property or spare bedroom(s)
          </ArticleHyperlink>
          .
        </ArticleListItem>

        <ArticleListItem>
          If you own an HDB flat, sell part of your flat’s remaining lease back to HDB through the{' '}
          <ArticleHyperlink url="https://www.hdb.gov.sg/residential/living-in-an-hdb-flat/for-our-seniors/monetising-your-flat-for-retirement/lease-buyback-scheme">
            Lease Buyback Scheme (LBS)
          </ArticleHyperlink>{' '}
          if you meet the prevailing eligibility conditions. When you use the net proceeds to top up
          your CPF RA and join CPF LIFE, you can receive a cash bonus of up to{' '}
          <CurrencyPrefix>30,000</CurrencyPrefix>.
        </ArticleListItem>

        <ArticleListItem>
          If you own a private property, consider consulting your financial institution or advisor
          on the available products in the market that allow you to monetise your property.
        </ArticleListItem>
      </StyledArticleOrderedList>
    </>
  );
};

import React from 'react';
import styled, { css } from 'styled-components';

import { PeopleInFrontOfLaptopImage, SmilingManImage, WindingRoadImage } from 'assets/Retirement';
import {
  ResourcesPageContentTitle,
  StyledResourcesCard,
} from 'components/Composite/ResourcesPage/sharedStyles';
import { RESOURCES } from 'constants/urls';
import { maxWidth } from 'themes';
import { typography } from 'themesTypography';

export const DESCRIPTION =
  'To enjoy your retirement, you need to have enough retirement funds to sustain your desired retirement lifestyle for the rest of your life. Start your retirement planning in 3 steps.';

const Description = styled.p`
  ${typography('body-l')}
  text-align: left;

  ${maxWidth(
    'tablet',
    css`
      display: none;
    `
  )}
`;

export const RetirementResourcesContent = () => {
  return (
    <div>
      <ResourcesPageContentTitle>Getting started on retirement planning</ResourcesPageContentTitle>
      <Description>{DESCRIPTION}</Description>
      <StyledResourcesCard
        title="Step 1: Define retirement goals"
        description="Think about when you wish to retire and how much you need."
        toPath={RESOURCES.RETIREMENT.defineRetirementGoals}
        image={<PeopleInFrontOfLaptopImage />}
      />
      <StyledResourcesCard
        title="Step 2: Assess current situation"
        description="Things to consider while saving for retirement."
        toPath={RESOURCES.RETIREMENT.assessCurrentSituation}
        image={<SmilingManImage />}
      />
      <StyledResourcesCard
        title="Step 3: Close savings gap"
        description="Ways to build your retirement income."
        toPath={RESOURCES.RETIREMENT.closeSavingsGap}
        image={<WindingRoadImage />}
      />
    </div>
  );
};

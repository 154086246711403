import React from 'react';

import { colorScale, theme } from 'themes';

import { calculatePolygonVertices } from './helper';

interface IProps {
  numOfSides: number;
  radiusLevels: number[];
  isBorder?: boolean;
  backgroundFillId?: string;
}

export const Polygon = ({
  numOfSides = 5,
  radiusLevels,
  isBorder = false,
  backgroundFillId,
  ...otherProps
}: IProps) => {
  const vertices = calculatePolygonVertices({
    numOfSides,
    radiusLevels,
  });
  const points = vertices.map(({ x, y }) => `${x}, ${y}`).join(',');

  return (
    <polygon
      points={points}
      opacity={0.8}
      fill={isBorder ? 'none' : `url(#${backgroundFillId})`}
      stroke={isBorder ? colorScale('grey', 80)({ theme }) : 'none'}
      strokeOpacity={isBorder ? 0.8 : 0}
      strokeWidth={isBorder ? 0.3 : 0}
      {...otherProps}
    />
  );
};

import React from 'react';

import InvertedWave from './inverted-wave.svg';
import MoneyPlantBackgroundLeft from './money-plant-background-left.svg';
import MoneyPlantsAndWateringCanBackground from './money-plants-and-watering-can-background.svg';
import WateringCanBackgroundRight from './watering-can-background-right.svg';
import Wave from './wave.svg';
import WhiteBackground from './white-background.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const WhiteBackgroundImage = (props: ImageProps) => (
  <img {...props} src={WhiteBackground} alt="White background" />
);

export const MoneyPlantsAndWateringCanBackgroundImage = (props: ImageProps) => (
  <img
    {...props}
    src={MoneyPlantsAndWateringCanBackground}
    alt="Background illustration of money plants and watering can"
  />
);

export const MoneyPlantBackgroundLeftImage = (props: ImageProps) => (
  <img
    {...props}
    src={MoneyPlantBackgroundLeft}
    alt="Left background illustration of plant with money symbol"
  />
);

export const WateringCanBackgroundRightImage = (props: ImageProps) => (
  <img
    {...props}
    src={WateringCanBackgroundRight}
    alt="Right background illustration of watering can with money symbol on the right"
  />
);

export const BackgroundWaveImage = (props: ImageProps) => <img {...props} src={Wave} alt="Wave" />;

export { InvertedWave };

import { pickBy } from 'lodash';

import {
  getNumInvestmentClasses,
  getSavingsRatio,
  getTotalDebtServicingRatio,
} from 'redux/finances/selectors';
import { getProfileEmploymentStatusRange } from 'redux/profile/selectors';
import { getNumEmergencyFundMonths } from 'redux/targets/selectors';

import { EMPLOYMENT_STATUS_RANGE, RECOMMENDED_ACTION } from 'constants/enums';
import { IRecommendedAction, IStore } from 'constants/storeTypes';
import { getFinancialStatus } from 'financialStatus/getFinancialStatus';
import {
  IRecommendedActionForRender,
  recommendedActions as allRecommendedActions,
} from 'financialStatus/recommendedActions';
import { descending } from 'utilities/sortingUtilities';

type ICompletedRecommendedAction = Omit<IRecommendedAction, 'completedAt'> & {
  completedAt: string;
};

export const getRecommendedActionsCompleted = (store: IStore) =>
  pickBy(
    store.financialStatus.recommendedActions,
    (recommendedAction: IRecommendedAction): recommendedAction is ICompletedRecommendedAction =>
      Boolean(recommendedAction.completedAt)
  );

export const getSortedRecommendedActionsCompleted = (
  store: IStore
): IRecommendedActionForRender[] =>
  Object.values(getRecommendedActionsCompleted(store))
    .sort((a, b) => descending(new Date(b.completedAt), new Date(a.completedAt)))
    .map(({ key }) => allRecommendedActions[key]);

export const getRecommendedActionsCompletedKeys = (store: IStore): RECOMMENDED_ACTION[] =>
  Object.values(getRecommendedActionsCompleted(store)).map(({ key }) => key);

export const getFinancialStatusFromState = (store: IStore) => {
  const employmentStatusRange = getProfileEmploymentStatusRange(store);

  return getFinancialStatus({
    completedRecommendedActionKeys: getRecommendedActionsCompletedKeys(store),
    emergencyFundMonths: getNumEmergencyFundMonths(store),
    savingsRatio: getSavingsRatio(store),
    isWorking: employmentStatusRange
      ? [EMPLOYMENT_STATUS_RANGE.FULL_PART_TIME, EMPLOYMENT_STATUS_RANGE.SELF_FREELANCE].includes(
          employmentStatusRange
        )
      : false,
    numInvestmentClasses: getNumInvestmentClasses(store),
    totalDebtServicingRatio: getTotalDebtServicingRatio(store),
  });
};

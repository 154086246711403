import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  LongRoadAheadImage,
  PuttingMoneyIntoCPFImage,
  PuttingMoneyIntoSRSImage,
  GrowingPotOfWealthImage,
  PropertyMonetizationImage,
} from 'assets/Retirement/CloseSavingsGapArticle';
import { Breadcrumbs, CurrencyPrefix } from 'components/Common';
import {
  ArticleHeading,
  ArticleParagraph,
  ArticleImageWithText,
  ArticleTemplate,
  ArticleCTAButton,
  ArticleOrderedList,
  ArticleListItem,
  ArticleSubHeading,
} from 'components/Templates';
import { RESOURCES } from 'constants/urls';
import { colorScale, breakpoints, minWidth } from 'themes';

import { RetirementCtaPanel } from '../../RetirementCtaPanel';

import { BuildCPFSavings } from './BuildCPFSavings';
import { ContributeToSRS } from './ContributeToSRS';
import { MonetiseYourProperty } from './MonetiseYourProperty';
import { PersonaComparisonTable } from './PersonaComparisonTable';
import { ROIComparisonTable } from './ROIComparisonTable';
import { ArticleTableWrapper } from './sharedStyles';

const ArticleOverview = styled(ArticleOrderedList)`
  ${minWidth(
    'tablet',
    css`
      margin-left: 64px;
    `
  )}
  margin-top: 16px;
`;

export const CloseSavingsGapArticle = () => {
  const isMobileWidth = useMediaQuery({ query: `(max-width: ${breakpoints.mobileMax})` });
  return (
    <div>
      <Breadcrumbs
        backgroundColor={{ default: colorScale('grey', 0) }}
        crumbs={[
          {
            urlPath: RESOURCES.RETIREMENT.baseRoute,
            label: 'Retirement planning guide',
          },
          {
            urlPath: RESOURCES.RETIREMENT.closeSavingsGap,
            label: 'Step 3: Close savings gap',
          },
        ]}
      />

      <ArticleTemplate
        heroIllustration={<LongRoadAheadImage />}
        heroIllustrationPosition="bottom"
        bannerColor={colorScale('grey', 0)}
        heading="Step 3: Close savings gap"
        content={
          <>
            <ArticleHeading>Ways to build your retirement income:</ArticleHeading>

            <ArticleOverview variant="roman">
              <ArticleListItem>Build up your CPF savings</ArticleListItem>
              <ArticleListItem>
                Contribute to the Supplementary Retirement Scheme (SRS)
              </ArticleListItem>
              <ArticleListItem>Save regulary and invest wisely</ArticleListItem>
              <ArticleListItem>Monetise your property</ArticleListItem>
            </ArticleOverview>

            <ArticleImageWithText
              orientation={isMobileWidth ? 'vertical' : 'horizontal'}
              image={<PuttingMoneyIntoCPFImage />}
              heading="i. Build up your CPF savings"
              description={<BuildCPFSavings />}
            />

            <ArticleImageWithText
              orientation={isMobileWidth ? 'vertical' : 'horizontal'}
              image={<PuttingMoneyIntoSRSImage />}
              heading="ii. Contribute to the Supplementary Retirement Scheme (SRS)"
              description={<ContributeToSRS />}
            />

            <ArticleImageWithText
              orientation={isMobileWidth ? 'vertical' : 'horizontal'}
              image={<GrowingPotOfWealthImage />}
              heading="iii. Save regularly and invest wisely"
              description={
                <ArticleParagraph>
                  Commit to saving 10% to 20% of your income each month by setting up automatic
                  transfers to a separate bank account. While it is never too late to start saving
                  and{' '}
                  <Link
                    to={RESOURCES.INVESTMENT.baseRoute}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    investing
                  </Link>{' '}
                  for retirement, the earlier you start, the better. Starting earlier means more
                  time for your savings to benefit from the effects of compound interest.
                </ArticleParagraph>
              }
            />

            <ArticleSubHeading>Example:</ArticleSubHeading>
            <ArticleTableWrapper>
              <PersonaComparisonTable />
            </ArticleTableWrapper>

            <ArticleParagraph>Who would have accumulated more at age 60?</ArticleParagraph>

            <ArticleTableWrapper>
              <ROIComparisonTable />
            </ArticleTableWrapper>

            <ArticleParagraph>
              At age 60, Alicia would have accumulated around{' '}
              <CurrencyPrefix>90,000</CurrencyPrefix> more than Betty. She saved more by starting
              earlier, even though she saved a smaller amount of monies each month. Therefore, start
              investing as early as you can to enjoy the effects of compounding interest.
            </ArticleParagraph>

            <ArticleImageWithText
              orientation={isMobileWidth ? 'vertical' : 'horizontal'}
              image={<PropertyMonetizationImage />}
              heading="iv. Monetise your property"
              description={<MonetiseYourProperty />}
            />

            <RetirementCtaPanel />
          </>
        }
        additionalContent={<ArticleCTAButton route={RESOURCES.RETIREMENT.baseRoute} />}
      />
    </div>
  );
};

/**
 * Combines two strings into one using property accessor notation
 * @param {string} key The key string
 * @param {string} [prefix] The prefix string
 * @returns {string} The combined string
 * @example
 * keygen(1,b); // returns 'b[1]'
 * @example
 * keygen(a,b); // returns 'b.a'
 */
export const keygen = (key: string, prefix?: string) =>
  !prefix
    ? key
    : isCastableToNumber(key)
    ? `${prefix}[${key}]`
    : startsWithNumber(key)
    ? `${prefix}['${key}']`
    : `${prefix}.${key}`;

const isCastableToNumber = (key: string) => !isNaN(Number(key));

const startsWithNumber = (key: string) => /^[0-9]/.test(key);

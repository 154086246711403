import { Reducer } from 'redux';

import { IApimActions } from 'constants/actionTypes';
import { APIM_ACTIONS } from 'constants/enums';
import { IApimStore } from 'constants/storeTypes';

export const initialState: IApimStore = {
  casa: [],
  fixedDeposit: [],
  securedLoan: [],
  unsecuredLoan: [],
  cpfis: [],
  creditCard: [],
  srs: [],
  unitTrust: [],
  cdp: [],
  statementLatestMonthYear: '',
  statementLatestPrevMonthYear: '',
  calendarLatestMonthYear: '',
};

export const apimReducer: Reducer<IApimStore, IApimActions> = (
  state: IApimStore = initialState,
  action: IApimActions
) => {
  switch (action.type) {
    case APIM_ACTIONS.SET_APIM_DATA:
      return {
        ...state,
        casa: action.casa,
        fixedDeposit: action.fixedDeposit,
        securedLoan: action.securedLoan,
        unsecuredLoan: action.unsecuredLoan,
        cpfis: action.cpfis,
        creditCard: action.creditCard,
        srs: action.srs,
        unitTrust: action.unitTrust,
        cdp: action.cdp,
        statementLatestMonthYear: action.statementLatestMonthYear,
        statementLatestPrevMonthYear: action.statementLatestPrevMonthYear,
        calendarLatestMonthYear: action.calendarLatestMonthYear,
      };
    case APIM_ACTIONS.UPDATE_CDP_HOLDING:
      return {
        ...state,
        cdp: state.cdp.map((c) => {
          if (c.id === action.accountId && c.holdings.length) {
            return {
              ...c,
              holdings: c.holdings.map((holding) => {
                if (holding.id === action.holdingId) {
                  const { percentageShare, marketValue, estimatedGrowthRate } = action.update;
                  return {
                    ...holding,
                    percentageShare: percentageShare ?? holding.percentageShare,
                    estimatedGrowthRate: estimatedGrowthRate ?? holding.estimatedGrowthRate,
                    holdingBalances: holding.holdingBalances.map((bal, idx) =>
                      idx === 0
                        ? {
                            ...bal,
                            marketValue: marketValue ?? bal.marketValue,
                          }
                        : bal
                    ),
                  };
                }
                return holding;
              }),
            };
          }
          return c;
        }),
      };
    case APIM_ACTIONS.UPDATE_CASA:
      return {
        ...state,
        casa: state.casa.map((c) => {
          if (c.id === action.id) {
            return { ...c, ...action.update };
          }
          return c;
        }),
      };
    case APIM_ACTIONS.UPDATE_FIXED_DEPOSIT:
      return {
        ...state,
        fixedDeposit: state.fixedDeposit.map((fd) => {
          if (fd.id === action.id) {
            return { ...fd, ...action.update };
          }
          return fd;
        }),
      };
    case APIM_ACTIONS.UPDATE_SECURED_LOAN:
      return {
        ...state,
        securedLoan: state.securedLoan.map((sl) => {
          if (sl.id === action.id) {
            return { ...sl, ...action.update };
          }
          return sl;
        }),
      };
    case APIM_ACTIONS.UPDATE_UNSECURED_LOAN:
      return {
        ...state,
        unsecuredLoan: state.unsecuredLoan.map((usl) => {
          if (usl.id === action.id) {
            return { ...usl, ...action.update };
          }
          return usl;
        }),
      };
    case APIM_ACTIONS.UPDATE_SRS_AMOUNT:
      return {
        ...state,
        srs: state.srs.map((srs) => {
          if (srs.id === action.accountId) {
            const updatedAmounts = srs.amounts.map((amount) => {
              if (amount.id === action.id) {
                return { ...amount, ...action.update };
              }
              return amount;
            });
            return { ...srs, amounts: updatedAmounts };
          }
          return srs;
        }),
      };
    case APIM_ACTIONS.UPDATE_SRS_HOLDING:
      return {
        ...state,
        srs: state.srs.map((srs) => {
          if (srs.id === action.accountId) {
            const updatedHoldings = srs.holdings.map((holding) => {
              if (holding.id === action.id) {
                return { ...holding, ...action.update };
              }
              return holding;
            });
            return { ...srs, holdings: updatedHoldings };
          }
          return srs;
        }),
      };
    case APIM_ACTIONS.UPDATE_CPFIS_AMOUNT:
      return {
        ...state,
        cpfis: state.cpfis.map((cpfis) => {
          if (cpfis.id === action.accountId) {
            const updatedAmounts = cpfis.amounts.map((amount) => {
              if (amount.id === action.id) {
                return { ...amount, ...action.update };
              }
              return amount;
            });
            return { ...cpfis, amounts: updatedAmounts };
          }
          return cpfis;
        }),
      };
    case APIM_ACTIONS.UPDATE_CPFIS_HOLDING:
      return {
        ...state,
        cpfis: state.cpfis.map((cpfis) => {
          if (cpfis.id === action.accountId) {
            const updatedHoldings = cpfis.holdings.map((holding) => {
              if (holding.id === action.id) {
                return { ...holding, ...action.update };
              }
              return holding;
            });
            return { ...cpfis, holdings: updatedHoldings };
          }
          return cpfis;
        }),
      };
    case APIM_ACTIONS.UPDATE_UNIT_TRUST_HOLDING:
      return {
        ...state,
        unitTrust: state.unitTrust.map((unitTrust) => {
          if (unitTrust.id === action.accountId) {
            const updatedHoldings = unitTrust.holdings.map((holding) => {
              if (holding.id === action.id) {
                return { ...holding, ...action.update };
              }
              return holding;
            });
            return { ...unitTrust, holdings: updatedHoldings };
          }
          return unitTrust;
        }),
      };
    default:
      return state;
  }
};

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme as fromTheme } from 'styled-tools';

import { colorScale, typeScale } from 'themes';

const Icon = styled.div<{ isOpened: boolean }>`
  position: relative;
  width: 1.5em;
  height: calc(3px + 1em);
  font-size: ${typeScale(-2)};

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    transition: ${fromTheme('transitions.durationLonger')} ease-in-out;
    transform-origin: left center;

    ${ifProp(
      'isOpened',
      css`
        background: ${colorScale('primary', 50)};
      `,
      css`
        background: ${colorScale('grey', 80)};
      `
    )}
  }

  span:nth-child(1) {
    top: 0;

    ${ifProp(
      'isOpened',
      css`
        transform: translate(3px, -1px) rotate(45deg);
      `
    )}
  }

  span:nth-child(2) {
    top: 0.5em;

    ${ifProp(
      'isOpened',
      css`
        width: 0;
        opacity: 0;
      `
    )}
  }

  span:nth-child(3) {
    top: 1em;

    ${ifProp(
      'isOpened',
      css`
        transform: translateX(3px) rotate(-45deg);
      `
    )}
  }
`;

interface IProps {
  isOpened: boolean;
}

export const HamburgerIcon = ({ isOpened }: IProps) => (
  <Icon isOpened={isOpened}>
    <span />
    <span />
    <span />
  </Icon>
);

import React from 'react';
import styled from 'styled-components';

import { IMAGE_BETWEEN_COMPONENT_SPACING_PX } from './constants';

const Wrapper = styled.div`
  display: block;
  margin: ${IMAGE_BETWEEN_COMPONENT_SPACING_PX} auto;
  width: 100%;

  > img {
    width: 100%;
  }
`;

interface IOwnProps {
  image: React.ReactElement;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

export const ArticleImage = ({ image, ...otherProps }: IProps) => (
  <Wrapper {...otherProps}>{image}</Wrapper>
);

import React from 'react';
import styled, { css } from 'styled-components';

import { CouplePlanningForRetirementImage, SmilingManImage } from 'assets/Retirement';
import { Breadcrumbs } from 'components/Common';
import { SampleProfilePanel } from 'components/Composite';
import {
  ArticleHeading,
  ArticleSubHeading,
  ArticleHyperlink,
  ArticleParagraph,
  ArticleTemplate,
  ArticleTemplateContainer,
  ArticleCTAButton,
  ArticleTagline,
  ArticleImageWithText,
} from 'components/Templates';
import { RESOURCES } from 'constants/urls';
import { colorScale, typography, minWidth } from 'themes';

import { RetirementCtaPanel } from '../../RetirementCtaPanel';

import { AnnualExpensesAtSixtyFiveTable } from './AnnualExpensesAtSixtyFiveTable';
import { CurrentAnnualIncomeTable } from './CurrentAnnualIncomeTable';
import {
  incomeReplacementRatioProfileRows,
  adjustExpenseProfileRows,
} from './ExampleProfileRowData';
import { StyledCurrencyPrefix } from './sharedStyles';

const StyledArticleSubHeading = styled(ArticleSubHeading)`
  margin: 16px 0 12px;
  ${minWidth(
    'desktop',
    css`
      margin: 32px 0 12px;
    `
  )}
`;

const StyledDescription = styled.span`
  ${typography('body-s-bold')}
`;

const StyledArticleNoteTagline = styled(ArticleTagline)`
  ${typography('body-s')}
`;

const StyledArticleExampleWrapper = styled.div`
  background: ${colorScale('grey', 20)};
  padding-bottom: 32px;
  white-space: pre-line;
`;

const ArticleTableWrapper = styled.div`
  overflow-x: scroll;
  margin: 24px 0px 16px;
`;

const StyledArticleVerticalImageWithText = styled(ArticleImageWithText)`
  justify-items: center;
  text-align: center;
`;

export const DefineRetirementGoalsArticle = () => {
  return (
    <div>
      <Breadcrumbs
        backgroundColor={{ default: colorScale('grey', 0) }}
        crumbs={[
          {
            urlPath: RESOURCES.RETIREMENT.baseRoute,
            label: 'Retirement planning guide',
          },
          {
            urlPath: RESOURCES.RETIREMENT.defineRetirementGoals,
            label: 'Step 1: Define retirement goals',
          },
        ]}
      />
      <ArticleTemplate
        bannerColor={colorScale('primary', 10)}
        flushContentToEdges={true}
        heroIllustration={<CouplePlanningForRetirementImage />}
        heading="Step 1: Define retirement goals"
        content={
          <>
            <ArticleTemplateContainer>
              <ArticleHeading>When do you plan to retire?</ArticleHeading>
              <ArticleParagraph>
                Your desired retirement age is the age you intend to stop working. Some may use the
                statutory retirement age of 63 (to be raised to 65 by 2030) as a reference, while
                others may choose to work beyond that. If you plan to retire at a younger age, you
                would need a larger amount of retirement funds than if you retire later, while
                having less time to save for it.
              </ArticleParagraph>
              <ArticleHeading>How much do you need to retire?</ArticleHeading>
              <ArticleParagraph>
                You can use two common industry practices to help you estimate how much funds you
                need for retirement.
              </ArticleParagraph>
              <ArticleSubHeading>i. Income replacement ratio method</ArticleSubHeading>
              <ArticleParagraph>
                Most people will be spending less on certain expenses during retirement. A quick way
                to estimate your retirement income is by using{' '}
                <ArticleHyperlink url="https://www.oecd-ilibrary.org/sites/pension_asia-2018-9-en/index.html?itemId=/content/component/pension_asia-2018-9-en">
                  50%
                </ArticleHyperlink>{' '}
                to{' '}
                <ArticleHyperlink url="https://www.mom.gov.sg/~/media/mom/documents/statistics-publications/occasional-paper-cpf-irr.pdf">
                  70%
                </ArticleHyperlink>{' '}
                of your current income. The proportion of income depends on your retirement
                lifestyle. As your income may change with age, do review your estimates regularly.
              </ArticleParagraph>
            </ArticleTemplateContainer>

            <StyledArticleExampleWrapper>
              <ArticleTemplateContainer>
                <StyledArticleSubHeading>Example:</StyledArticleSubHeading>
                <SampleProfilePanel
                  profileName="John"
                  profileDescription={'30 years old and wants to work out his retirement income.'}
                  displayPicture={<SmilingManImage />}
                  profileBreakdown={incomeReplacementRatioProfileRows}
                />
              </ArticleTemplateContainer>
            </StyledArticleExampleWrapper>

            <ArticleTemplateContainer>
              <ArticleTableWrapper>
                <CurrentAnnualIncomeTable />
              </ArticleTableWrapper>

              <StyledArticleNoteTagline>
                *Note: While current income is used in this example, an alternative is to use
                pre-retirement income in computing your income replacement ratio.
              </StyledArticleNoteTagline>

              <StyledArticleVerticalImageWithText
                orientation="vertical"
                image={<SmilingManImage />}
                description={
                  <>
                    Retirement income that John needs:
                    <br />
                    <StyledCurrencyPrefix>
                      <b>513,000</b>
                    </StyledCurrencyPrefix>{' '}
                    <StyledDescription>to</StyledDescription>{' '}
                    <StyledCurrencyPrefix>
                      <b>718,200</b>
                    </StyledCurrencyPrefix>
                  </>
                }
              />

              <ArticleSubHeading>ii. Adjusted expense method</ArticleSubHeading>
              <ArticleParagraph>
                Another method for estimating your retirement income is to benchmark against the
                monthly retiree household expenses while adjusting for inflation. As the retiree
                household expenses may change over time, do review your estimates regularly.
              </ArticleParagraph>
            </ArticleTemplateContainer>

            <StyledArticleExampleWrapper>
              <ArticleTemplateContainer>
                <StyledArticleSubHeading>Example:</StyledArticleSubHeading>
                <SampleProfilePanel
                  profileName="John"
                  profileDescription={'30 years old and wants to work out his retirement income.'}
                  displayPicture={<SmilingManImage />}
                  profileBreakdown={adjustExpenseProfileRows}
                />
              </ArticleTemplateContainer>
            </StyledArticleExampleWrapper>

            <ArticleTemplateContainer>
              <ArticleTableWrapper>
                <AnnualExpensesAtSixtyFiveTable />
              </ArticleTableWrapper>

              <StyledArticleVerticalImageWithText
                orientation="vertical"
                image={<SmilingManImage />}
                description={
                  <>
                    Retirement income that John needs:
                    <br />
                    <StyledCurrencyPrefix>
                      <b>462,100</b>
                    </StyledCurrencyPrefix>
                  </>
                }
              />
              <ArticleParagraph>
                As John may live beyond the life expectancy of 84 years, he may need more than what
                is estimated. Hence, it is important for him to review his finances regularly and
                adjust accordingly even in retirement.
              </ArticleParagraph>
              <RetirementCtaPanel />
            </ArticleTemplateContainer>
          </>
        }
        additionalContent={<ArticleCTAButton route={RESOURCES.RETIREMENT.baseRoute} />}
      />
    </div>
  );
};

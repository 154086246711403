import axios from 'axios';
import { differenceWith, isEqual, groupBy, omitBy, isNil } from 'lodash';
import moment from 'moment';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
  RequestAction,
  ISetApim,
  IUpdateApimCasa,
  IUpdateApimFixedDeposit,
  IUpdateApimSecuredLoan,
  IUpdateApimUnsecuredLoan,
  IUpdateApimSrsAmount,
  IUpdateApimSrsHolding,
  IUpdateApimCpfisAmount,
  IUpdateApimCpfisHolding,
  IUpdateApimUnitTrustHolding,
  IUpdateApimCdp,
} from 'constants/actionTypes';
import {
  APIM_ACTIONS,
  APIM_ASSET_FORMATTED_TYPE,
  LIABILITY_NAME,
  LIABILITY_TYPE,
  MYINFO_RESET_ACTION_SCOPE,
} from 'constants/enums';
import { IApimResponse, IApimAssetHoldingResponse } from 'constants/responseTypes';
import {
  IStore,
  IApimCasa,
  IApimFixedDeposit,
  IApimSecuredLoan,
  IApimUnsecuredLoan,
  IApimHolding,
  IApimAmount,
} from 'constants/storeTypes';
import { featureDecisions } from 'featureDecisions';
import { getMaxDate } from 'utilities/dateUtilities';

import { setMyinfo, setMyinfoUsePersonFullData, resetMyinfo } from '../myinfo/actions';
import { updateProfile } from '../profile/slice';

import {
  getApimCasaRaw,
  getApimFixedDepositRaw,
  getApimSecuredLoanRaw,
  getApimUnsecuredLoanRaw,
  IApimAssetFormatted,
  IApimCasaFormatted,
  IApimSecuredLoanFormatted,
  IApimUnsecuredLoanFormatted,
  IApimCpfisSrsAmountFormatted,
  IApimCpfisSrsHoldingFormatted,
  IApimUnitTrustHoldingFormatted,
  getApimCpfisAmountsFormatted,
  getApimCpfisHoldingsFormatted,
  getApimSrsAmountsFormatted,
  getApimSrsHoldingsFormatted,
  getApimUnitTrustHoldingsFormatted,
  getApimLatestSrs,
  getApimLatestCpfis,
  IApimCdpHoldingFormatted,
  getApimCdpHoldingWithLatestBalance,
} from './selectors';

const apiUrl = process.env.REACT_APP_API_URL as string;

export const convertAssetHoldingsAndBalancesToHoldings = <
  T extends {
    assetHoldings: IApimAssetHoldingResponse[];
  }
>(
  asset: T
) => {
  const holdings: IApimHolding[] = [];
  const { assetHoldings, ...rest } = asset;
  assetHoldings.forEach(
    ({
      id: assetHoldingId,
      balanceType,
      holdingCcy,
      holdingCode,
      holdingCodeType,
      holdingType,
      percentageShare,
      assetHoldingBalances,
      estimatedGrowthRate,
    }) => {
      assetHoldingBalances.forEach(({ balancesDate, balancesQuantity, id, unitPrice }) => {
        holdings.push({
          id,
          balancesQuantity,
          balancesDate,
          balanceType,
          assetHoldingId,
          unitPrice,
          holdingCcy,
          holdingCode,
          holdingCodeType,
          holdingType,
          percentageShare,
          estimatedGrowthRate,
        });
      });
    }
  );

  return {
    ...rest,
    holdings,
  };
};

export const setApimData = ({
  casa,
  fixedDeposit,
  securedLoan,
  unsecuredLoan,
  cpfis,
  creditCard,
  srs,
  unitTrust,
  cdp,
}: Omit<IApimResponse, 'latestConsentAt' | 'bankResponseStatuses'>): ISetApim => {
  /*
   * USL, SL, CC follows statement month
   * - The statement can close at any day of the month depending on asset
   * - Therefore, we will not know if the asset has been removed or have not
   *   reached the statement date thus we have to consider the latest month
   *   and the month before that
   *
   * The rest follows calendar month
   * - The statement will close only at the last day of the month
   * - Therefore, we can assume that if there's a latest calendar date for these asset,
   *   it will mean that thats the latest calendar month
   */
  const statementDatesSet = new Set<string>();
  const calendarDatesSet = new Set<string>();

  creditCard.forEach((asset) =>
    asset.amounts.map((assetAmount) => statementDatesSet.add(assetAmount.date))
  );

  cpfis.forEach((asset) =>
    asset.amounts.map((assetAmount) => calendarDatesSet.add(assetAmount.date))
  );
  cpfis.forEach((asset) =>
    asset.assetHoldings.forEach(({ assetHoldingBalances }) =>
      assetHoldingBalances.forEach((assetHoldingBalance) => {
        calendarDatesSet.add(assetHoldingBalance.balancesDate);
      })
    )
  );

  srs.forEach((asset) =>
    asset.amounts.map((assetAmount) => calendarDatesSet.add(assetAmount.date))
  );
  srs.forEach((asset) =>
    asset.assetHoldings.forEach(({ assetHoldingBalances }) =>
      assetHoldingBalances.forEach((assetHoldingBalance) => {
        calendarDatesSet.add(assetHoldingBalance.balancesDate);
      })
    )
  );

  unitTrust.forEach((asset) =>
    asset.assetHoldings.forEach(({ assetHoldingBalances }) =>
      assetHoldingBalances.forEach((assetHoldingBalance) => {
        calendarDatesSet.add(assetHoldingBalance.balancesDate);
      })
    )
  );

  const ret = {
    casa: casa.map((c) => {
      c.amounts.forEach((casaAmount) => calendarDatesSet.add(casaAmount.date));
      return c;
    }),
    fixedDeposit: fixedDeposit.map((fd) => {
      fd.amounts.forEach((fdAmount) => calendarDatesSet.add(fdAmount.date));
      return fd;
    }),
    securedLoan: securedLoan.map((sl) => {
      sl.amounts.forEach((slAmount) => statementDatesSet.add(slAmount.date));
      return {
        ...sl,
        type: {
          id: sl.type.id as LIABILITY_TYPE,
          description: sl.type.description as LIABILITY_NAME,
        },
      };
    }),
    unsecuredLoan: unsecuredLoan.map((usl) => {
      usl.amounts.forEach((uslAmount) => statementDatesSet.add(uslAmount.date));
      return {
        ...usl,
        type: {
          id: usl.type.id as LIABILITY_TYPE,
          description: usl.type.description as LIABILITY_NAME,
        },
      };
    }),
    cpfis: cpfis.map(convertAssetHoldingsAndBalancesToHoldings),
    creditCard,
    srs: srs.map(convertAssetHoldingsAndBalancesToHoldings),
    unitTrust: unitTrust.map(convertAssetHoldingsAndBalancesToHoldings),
    cdp: cdp.map((account) => {
      account.holdings.forEach(
        (holding) =>
          holding.holdingBalances.length && calendarDatesSet.add(holding.holdingBalances[0].date)
      );
      return account;
    }),
  };

  const statementLatestDate =
    statementDatesSet.size &&
    getMaxDate(Array.from(statementDatesSet).map((d) => moment(d, 'MMYYYY').toDate()));
  const statementLatestPrevDate =
    statementLatestDate && moment(statementLatestDate).add(-1, 'month');

  return {
    ...ret,
    type: APIM_ACTIONS.SET_APIM_DATA,
    statementLatestMonthYear: statementLatestDate
      ? moment(statementLatestDate).format('MMYYYY')
      : '',
    statementLatestPrevMonthYear: statementLatestPrevDate
      ? statementLatestPrevDate.format('MMYYYY')
      : '',
    calendarLatestMonthYear: calendarDatesSet.size
      ? moment(
          getMaxDate(Array.from(calendarDatesSet).map((d) => moment(d, 'MMYYYY').toDate()))
        ).format('MMYYYY')
      : '',
  };
};

export const getApimTokenRequest = (code: string) =>
  axios.post(`${apiUrl}/v1/apim/token`, { code });

export const getApimUserDataRequest = (): RequestAction => async (dispatch) => {
  const promise = axios.post(`${apiUrl}/v1/apim/user-data`);
  promise
    .then(({ data }) => {
      dispatch(setApimData(data.data.fpdata));

      dispatch(resetMyinfo(MYINFO_RESET_ACTION_SCOPE.FULL));
      dispatch(setMyinfoUsePersonFullData(true));
      dispatch(setMyinfo(data.data.myinfo));

      if (data.data.fpdata.latestConsentAt) {
        dispatch(
          updateProfile({ apimLatestPullConsentAt: new Date(data.data.fpdata.latestConsentAt) })
        );
      }
      if (data.data.myinfo.latestConsentAt) {
        dispatch(
          updateProfile({ myinfoLatestPullConsentAt: new Date(data.data.myinfo.latestConsentAt) })
        );
      }
    })
    .catch(() => {});
  return promise;
};

export const logApimRejectionRequest = (error: string, description: string) =>
  axios.post(`${apiUrl}/v1/apim/user-data-rejection`, { error, description });

export const setApimAssetsRequest = (
  apimAssets: IApimAssetFormatted[]
): ThunkAction<Promise<RequestAction[]>, IStore, undefined, AnyAction> => (dispatch) => {
  const {
    CASA,
    FD,
    SRS_AMOUNT,
    SRS_HOLDING,
    CPFIS_AMOUNT,
    CPFIS_HOLDING,
    UNIT_TRUST_HOLDING,
    CDP,
  } = APIM_ASSET_FORMATTED_TYPE;

  const assetsByType = groupBy(apimAssets, (asset) => asset.assetType);
  const casa = (assetsByType[CASA] || []) as IApimCasaFormatted[];
  const fixedDeposit = (assetsByType[FD] || []) as IApimCasaFormatted[];
  const srsHoldings = (assetsByType[SRS_HOLDING] || []) as IApimCpfisSrsHoldingFormatted[];
  const srsAmounts = (assetsByType[SRS_AMOUNT] || []) as IApimCpfisSrsAmountFormatted[];
  const cpfisHoldings = (assetsByType[CPFIS_HOLDING] || []) as IApimCpfisSrsHoldingFormatted[];
  const cpfisAmounts = (assetsByType[CPFIS_AMOUNT] || []) as IApimCpfisSrsAmountFormatted[];
  const unitTrust = (assetsByType[UNIT_TRUST_HOLDING] || []) as IApimUnitTrustHoldingFormatted[];
  const cdp = (assetsByType[CDP] || []) as IApimCdpHoldingFormatted[];

  const promises = [
    dispatch(updateApimCasaRequest(casa)),
    dispatch(updateApimFixedDepositsRequest(fixedDeposit)),
    dispatch(updateApimSrsAmountsRequest(srsAmounts)),
    dispatch(updateApimSrsHoldingsAndBalancesRequest(srsHoldings)),
    dispatch(updateApimCpfisAmountsRequest(cpfisAmounts)),
    dispatch(updateApimCpfisHoldingsAndBalancesRequest(cpfisHoldings)),
    dispatch(updateApimUnitTrustHoldingsAndBalancesRequest(unitTrust)),
    dispatch(updateApimCdpRequest(cdp)),
  ];

  return Promise.all(promises);
};

export const updateApimCasaRequest = (
  casas: {
    id: string;
    percentageShare?: number;
    estimatedGrowthRate?: number;
  }[]
): RequestAction => (dispatch, getState) => {
  const currCasas: IApimCasa[] = getApimCasaRaw(getState());
  const casaWithChanges = differenceWith(
    casas,
    currCasas,
    (a, b) =>
      a.id === b.id &&
      a.percentageShare === b.percentageShare &&
      b.amounts.every((bAmt) => a.estimatedGrowthRate === bAmt.estimatedGrowthRate)
  );
  const promises = casaWithChanges.map((casa) => {
    return axios.patch(`${apiUrl}/v1/apim/casa/${encodeURIComponent(casa.id as string)}`, {
      percentageShare: casa.percentageShare,
      estimatedGrowthRate: casa.estimatedGrowthRate,
    });
  });
  promises.forEach((promise) =>
    promise.then(({ data: { casa } }) => dispatch(updateApimCasa(casa.id, casa))).catch(() => {})
  );
  return Promise.all(promises);
};

export const updateApimCdpHoldingsRequest = (
  holdingsToUpdate: {
    holdingId: string;
    percentageShare?: number;
    estimatedGrowthRate?: number;
  }[]
): RequestAction => (dispatch) => {
  const promises = holdingsToUpdate.map(({ holdingId, percentageShare, estimatedGrowthRate }) =>
    axios.patch(`${apiUrl}/v1/apim/sgx/holding/${holdingId}`, {
      percentageShare,
      estimatedGrowthRate,
    })
  );

  promises.forEach((promise) =>
    promise
      .then(
        ({
          data: {
            data: { account, id, percentageShare, estimatedGrowthRate },
          },
        }) => {
          dispatch(
            updateApimCdp(account, id, {
              percentageShare,
              estimatedGrowthRate,
            })
          );
        }
      )
      .catch(() => {})
  );

  return Promise.all(promises);
};

export const updateApimCdpRequest = (
  cdp: {
    id: string;
    holdingId: string;
    holdingBalanceId: string;
    percentageShare?: number;
    estimatedGrowthRate?: number;
    marketValue?: string | null;
  }[]
): RequestAction => (dispatch, getState) => {
  const currCdp = getApimCdpHoldingWithLatestBalance(getState());
  const cdpHoldingsChanges = differenceWith(
    cdp,
    currCdp,
    (a, b) =>
      a.holdingId === b.holdingId &&
      a.percentageShare === b.percentageShare &&
      a.estimatedGrowthRate === b.estimatedGrowthRate
  );

  const cdpHoldingBalancesChanges = differenceWith(
    cdp,
    currCdp,
    (a, b) => a.holdingBalanceId === b.holdingBalanceId && a.marketValue === b.marketValue
  );

  const accountIds: {
    [holdingId: string]: string;
  } = {};

  getState().apim.cdp.forEach((cdp) => {
    cdp.holdings.forEach((holding) => {
      accountIds[holding.id] = cdp.id;
    });
  });

  if (featureDecisions.mockServerResponses) {
    cdpHoldingsChanges.forEach((account) => {
      dispatch(
        updateApimCdp(account.id, account.holdingId, {
          percentageShare: account.percentageShare,
          estimatedGrowthRate: account.estimatedGrowthRate,
        })
      );
    });

    cdpHoldingBalancesChanges.forEach((account) => {
      dispatch(
        updateApimCdp(account.id, account.holdingId, {
          marketValue: account.marketValue,
        })
      );
    });

    return Promise.resolve();
  }

  const promisesHoldingBalancesChanges = cdpHoldingBalancesChanges.map((holding) => {
    return axios.patch(`${apiUrl}/v1/apim/sgx/holding/balance/${holding.holdingBalanceId}`, {
      marketValue: holding.marketValue,
    });
  });

  promisesHoldingBalancesChanges.forEach((promise) => {
    promise
      .then(
        ({
          data: {
            data: { holding, marketValue },
          },
        }) => {
          dispatch(
            updateApimCdp(accountIds[holding], holding, {
              marketValue,
            })
          );
        }
      )
      .catch(() => {});
  });

  return Promise.all([
    dispatch(
      updateApimCdpHoldingsRequest(
        cdpHoldingsChanges.map(({ holdingId, percentageShare, estimatedGrowthRate }) => ({
          holdingId,
          percentageShare,
          estimatedGrowthRate,
        }))
      )
    ),
    ...promisesHoldingBalancesChanges,
  ]);
};

type IApimFixedDepositToUpdate = {
  id: string;
  percentageShare?: number;
  estimatedGrowthRate?: number;
};

export const updateApimFixedDepositRequest = (fd: IApimFixedDepositToUpdate): RequestAction => (
  dispatch
) => {
  const promise = axios.patch(
    `${apiUrl}/v1/apim/fixed-deposit/${encodeURIComponent(fd.id as string)}`,
    {
      percentageShare: fd.percentageShare,
      estimatedGrowthRate: fd.estimatedGrowthRate,
    }
  );

  promise
    .then(({ data: { fixedDeposit } }) =>
      dispatch(updateApimFixedDeposit(fixedDeposit.id, fixedDeposit))
    )
    .catch(() => {});

  return promise;
};

export const updateApimFixedDepositsRequest = (fds: IApimFixedDepositToUpdate[]): RequestAction => (
  dispatch,
  getState
) => {
  const currFds: IApimFixedDeposit[] = getApimFixedDepositRaw(getState());

  const fdsWithChanges = differenceWith(fds, currFds, (a, b) => {
    let withChange = a.id === b.id;

    if (a.percentageShare !== undefined) {
      withChange = withChange && a.percentageShare === b.percentageShare;
    }

    if (a.estimatedGrowthRate !== undefined) {
      withChange =
        withChange && b.amounts.every((bAmt) => a.estimatedGrowthRate === bAmt.estimatedGrowthRate);
    }

    return withChange;
  });

  return Promise.all(fdsWithChanges.map((fd) => dispatch(updateApimFixedDepositRequest(fd))));
};

export const updateApimSecuredLoanRequest = (
  securedLoans: IApimSecuredLoanFormatted[]
): RequestAction => (dispatch, getState) => {
  const currSecuredLoans: IApimSecuredLoan[] = getApimSecuredLoanRaw(getState());
  const securedLoansWithChanges = differenceWith(
    securedLoans,
    currSecuredLoans,
    (a, b) =>
      a.id === b.id &&
      a.percentageShare === b.percentageShare &&
      a.monthlyCPFLoanInstalment === b.monthlyCPFLoanInstalment &&
      a.monthlyCashLoanInstalment === b.monthlyCashLoanInstalment &&
      isEqual(a.type, b.type) &&
      b.amounts.every((bAmt) => a.estimatedGrowthRate === bAmt.estimatedGrowthRate)
  );

  if (featureDecisions.mockServerResponses) {
    securedLoansWithChanges.forEach(
      ({ id, percentageShare, monthlyCashLoanInstalment, monthlyCPFLoanInstalment, type }) => {
        if (id !== undefined) {
          dispatch(
            updateApimSecuredLoan(id, {
              percentageShare,
              monthlyCashLoanInstalment,
              monthlyCPFLoanInstalment,
              type,
            })
          );
        }
      }
    );
    return Promise.resolve();
  }

  const promises = securedLoansWithChanges.map((securedLoan) => {
    return axios.patch(
      `${apiUrl}/v1/apim/secured-loan/${encodeURIComponent(securedLoan.id as string)}`,
      {
        percentageShare: securedLoan.percentageShare,
        monthlyCashLoanInstalment: securedLoan.monthlyCashLoanInstalment,
        monthlyCPFLoanInstalment: securedLoan.monthlyCPFLoanInstalment,
        type: securedLoan.type.id,
        estimatedGrowthRate: securedLoan.estimatedGrowthRate,
      }
    );
  });

  promises.forEach((promise) =>
    promise
      .then(({ data: { securedLoan } }) =>
        dispatch(updateApimSecuredLoan(securedLoan.id, securedLoan))
      )
      .catch(() => {})
  );

  return Promise.all(promises);
};

export const updateApimUnsecuredLoanRequest = (
  unsecuredLoans: IApimUnsecuredLoanFormatted[]
): RequestAction => (dispatch, getState) => {
  const currUnsecuredLoans: IApimUnsecuredLoan[] = getApimUnsecuredLoanRaw(getState());
  const unsecuredLoansWithChanges = differenceWith(
    unsecuredLoans,
    currUnsecuredLoans,
    (a, b) =>
      a.id === b.id &&
      a.percentageShare === b.percentageShare &&
      a.monthlyCPFLoanInstalment === b.monthlyCPFLoanInstalment &&
      a.monthlyCashLoanInstalment === b.monthlyCashLoanInstalment &&
      isEqual(a.type, b.type) &&
      b.amounts.every((bAmt) => a.estimatedGrowthRate === bAmt.estimatedGrowthRate)
  );

  if (featureDecisions.mockServerResponses) {
    unsecuredLoansWithChanges.forEach(
      ({ id, percentageShare, monthlyCashLoanInstalment, monthlyCPFLoanInstalment, type }) => {
        if (id !== undefined) {
          dispatch(
            updateApimUnsecuredLoan(id, {
              percentageShare,
              monthlyCashLoanInstalment,
              monthlyCPFLoanInstalment,
              type,
            })
          );
        }
      }
    );
    return Promise.resolve();
  }

  const promises = unsecuredLoansWithChanges.map((unsecuredLoan) => {
    return axios.patch(
      `${apiUrl}/v1/apim/unsecured-loan/${encodeURIComponent(unsecuredLoan.id as string)}`,
      {
        percentageShare: unsecuredLoan.percentageShare,
        monthlyCashLoanInstalment: unsecuredLoan.monthlyCashLoanInstalment,
        monthlyCPFLoanInstalment: unsecuredLoan.monthlyCPFLoanInstalment,
        type: unsecuredLoan.type.id,
        estimatedGrowthRate: unsecuredLoan.estimatedGrowthRate,
      }
    );
  });

  promises.forEach((promise) =>
    promise
      .then(({ data: { unsecuredLoan } }) =>
        dispatch(updateApimUnsecuredLoan(unsecuredLoan.id, unsecuredLoan))
      )
      .catch(() => {})
  );

  return Promise.all(promises);
};

export const updateCpfisSrsAmountsRequest = (
  accountId: string,
  amountsToUpdate: {
    id: string;
    surrenderValue?: number;
    estimatedGrowthRate?: number;
  }[],
  updateAssetAmount: (
    accountId: string,
    amountId: string,
    update: Partial<IApimAmount>
  ) => IUpdateApimSrsAmount | IUpdateApimCpfisAmount
): RequestAction => (dispatch) => {
  if (featureDecisions.mockServerResponses) {
    amountsToUpdate.forEach(({ id, surrenderValue, estimatedGrowthRate }) =>
      dispatch(
        updateAssetAmount(accountId, id, omitBy({ surrenderValue, estimatedGrowthRate }, isNil))
      )
    );
    return Promise.resolve();
  }

  const promises = amountsToUpdate.map(({ id, surrenderValue, estimatedGrowthRate }) => {
    return axios.patch(`${apiUrl}/v1/apim/asset-amount/${id}`, {
      surrenderValue,
      estimatedGrowthRate,
    });
  });

  promises.forEach((promise) =>
    promise
      .then(({ data: { data: { id, surrenderValue, estimatedGrowthRate } } }) =>
        dispatch(updateAssetAmount(accountId, id, { surrenderValue, estimatedGrowthRate }))
      )
      .catch(() => {})
  );

  return Promise.all(promises);
};

export const updateCpfisSrsHoldingsRequest = (
  accountId: string,
  holdingsToUpdate: {
    assetHoldingId: string;
    estimatedGrowthRate?: number;
  }[],
  updateAssetHolding: (
    accountId: string,
    holdingBalanceId: string,
    update: Partial<IApimHolding>
  ) => IUpdateApimSrsHolding | IUpdateApimCpfisHolding
): RequestAction => (dispatch, getState) => {
  if (featureDecisions.mockServerResponses) {
    const currHoldings = [
      ...getApimSrsHoldingsFormatted(getState()),
      ...getApimCpfisHoldingsFormatted(getState()),
    ];
    holdingsToUpdate.forEach(({ assetHoldingId, estimatedGrowthRate }) => {
      const holdingInStore = currHoldings.find(
        (holding) => holding.assetHoldingId === assetHoldingId
      );
      if (holdingInStore) {
        dispatch(updateAssetHolding(accountId, holdingInStore.id, { estimatedGrowthRate }));
      }
    });
  }

  const promises = holdingsToUpdate.map(({ assetHoldingId, estimatedGrowthRate }) => {
    return axios.patch(`${apiUrl}/v1/apim/asset-holding/${assetHoldingId}`, {
      estimatedGrowthRate,
    });
  });

  promises.forEach((promise) =>
    promise
      .then(
        ({
          data: {
            data: { estimatedGrowthRate, assetHoldingBalances },
          },
        }) => {
          assetHoldingBalances.forEach((assetHoldingBalanceId: string) => {
            dispatch(updateAssetHolding(accountId, assetHoldingBalanceId, { estimatedGrowthRate }));
          });
        }
      )
      .catch(() => {})
  );
  return Promise.all(promises);
};

export const updateApimSrsAmountsRequest = (
  amounts: IApimCpfisSrsAmountFormatted[]
): RequestAction => (dispatch, getState) => {
  const accountId = getApimLatestSrs(getState()).id;
  if (accountId === undefined) {
    return Promise.resolve();
  }

  const currAmounts = getApimSrsAmountsFormatted(getState());
  const amountsWithChanges = differenceWith(
    amounts,
    currAmounts,
    (a, b) =>
      a.id === b.id &&
      a.surrenderValue === b.surrenderValue &&
      a.estimatedGrowthRate === b.estimatedGrowthRate
  );

  if (featureDecisions.mockServerResponses) {
    amountsWithChanges.forEach(({ id, surrenderValue, estimatedGrowthRate }) =>
      dispatch(updateApimSrsAmount(accountId, id, { surrenderValue, estimatedGrowthRate }))
    );

    return Promise.resolve();
  }

  return dispatch(
    updateCpfisSrsAmountsRequest(
      accountId,
      amountsWithChanges.map(({ id, surrenderValue, estimatedGrowthRate }) => ({
        id,
        surrenderValue,
        estimatedGrowthRate,
      })),
      updateApimSrsAmount
    )
  );
};

export const updateApimSrsHoldingsAndBalancesRequest = (
  holdings: IApimCpfisSrsHoldingFormatted[]
): RequestAction => (dispatch, getState) => {
  const accountId = getApimLatestSrs(getState()).id;
  if (accountId === undefined) {
    return Promise.resolve();
  }

  const currHoldings = getApimSrsHoldingsFormatted(getState());
  const holdingBalancesWithChanges = differenceWith(
    holdings,
    currHoldings,
    (a, b) => a.id === b.id && a.unitPrice === b.unitPrice
  );

  const holdingsChanges = differenceWith(
    holdings,
    currHoldings,
    (a, b) => a.id === b.id && a.estimatedGrowthRate === b.estimatedGrowthRate
  );

  const accountIds = {};
  getState().apim.srs.forEach((srs) => {
    srs.holdings.forEach((holding) => {
      accountIds[holding.id] = srs.id;
    });
  });

  if (featureDecisions.mockServerResponses) {
    holdingBalancesWithChanges.forEach((holding) => {
      dispatch(updateApimSrsHolding(accountId, holding.id, { unitPrice: holding.unitPrice }));
    });

    holdingsChanges.forEach((holding) => {
      dispatch(
        updateApimSrsHolding(accountIds[holding.id], holding.id, {
          estimatedGrowthRate: holding.estimatedGrowthRate,
        })
      );
    });

    return Promise.resolve();
  }

  const promisesHoldingBalancesChanges = holdingBalancesWithChanges.map((holding) => {
    return axios.patch(`${apiUrl}/v1/apim/asset-holding/balance/${holding.id}`, {
      unitPrice: holding.unitPrice,
    });
  });

  promisesHoldingBalancesChanges.forEach((promise) =>
    promise
      .then(
        ({
          data: {
            data: { id, unitPrice },
          },
        }) => {
          dispatch(
            updateApimSrsHolding(accountId, id, {
              unitPrice,
            })
          );
        }
      )
      .catch(() => {})
  );

  return Promise.all([
    dispatch(
      updateCpfisSrsHoldingsRequest(
        accountId,
        holdingsChanges.map(({ id, estimatedGrowthRate }) => ({
          assetHoldingId: id,
          estimatedGrowthRate,
        })),
        updateApimSrsHolding
      )
    ),
    ...promisesHoldingBalancesChanges,
  ]);
};

export const updateApimCpfisAmountsRequest = (
  amounts: IApimCpfisSrsAmountFormatted[]
): RequestAction => (dispatch, getState) => {
  const accountId = getApimLatestCpfis(getState()).id;
  if (accountId === undefined) {
    return Promise.resolve();
  }

  const currAmounts = getApimCpfisAmountsFormatted(getState());
  const amountsWithChanges = differenceWith(
    amounts,
    currAmounts,
    (a, b) =>
      a.id === b.id &&
      a.surrenderValue === b.surrenderValue &&
      a.estimatedGrowthRate === b.estimatedGrowthRate
  );

  if (featureDecisions.mockServerResponses) {
    amountsWithChanges.forEach(({ id, surrenderValue, estimatedGrowthRate }) =>
      dispatch(updateApimCpfisAmount(accountId, id, { surrenderValue, estimatedGrowthRate }))
    );

    return Promise.resolve();
  }

  return dispatch(
    updateCpfisSrsAmountsRequest(
      accountId,
      amountsWithChanges.map(({ id, surrenderValue, estimatedGrowthRate }) => ({
        id,
        surrenderValue,
        estimatedGrowthRate,
      })),
      updateApimCpfisAmount
    )
  );
};

export const updateApimCpfisHoldingsAndBalancesRequest = (
  holdings: IApimCpfisSrsHoldingFormatted[]
): RequestAction => (dispatch, getState) => {
  const accountId = getApimLatestCpfis(getState()).id;
  if (accountId === undefined) {
    return Promise.resolve();
  }

  const currHoldings = getApimCpfisHoldingsFormatted(getState());
  const holdingBalancesWithChanges = differenceWith(
    holdings,
    currHoldings,
    (a, b) => a.id === b.id && a.unitPrice === b.unitPrice
  );

  const holdingsChanges = differenceWith(
    holdings,
    currHoldings,
    (a, b) => a.id === b.id && a.estimatedGrowthRate === b.estimatedGrowthRate
  );

  const accountIds = {};
  getState().apim.cpfis.forEach((cpfis) => {
    cpfis.holdings.forEach((holding) => {
      accountIds[holding.id] = cpfis.id;
    });
  });

  if (featureDecisions.mockServerResponses) {
    holdingBalancesWithChanges.forEach((holding) => {
      dispatch(updateApimCpfisHolding(accountId, holding.id, { unitPrice: holding.unitPrice }));
    });

    holdingsChanges.forEach((holding) => {
      dispatch(
        updateApimCpfisHolding(accountIds[holding.id], holding.id, {
          estimatedGrowthRate: holding.estimatedGrowthRate,
        })
      );
    });

    return Promise.resolve();
  }

  const promisesHoldingBalancesChanges = holdingBalancesWithChanges.map((holding) => {
    return axios.patch(`${apiUrl}/v1/apim/asset-holding/balance/${holding.id}`, {
      unitPrice: holding.unitPrice,
    });
  });

  promisesHoldingBalancesChanges.forEach((promise) =>
    promise
      .then(
        ({
          data: {
            data: { id, unitPrice },
          },
        }) => {
          dispatch(
            updateApimCpfisHolding(accountId, id, {
              unitPrice,
            })
          );
        }
      )
      .catch(() => {})
  );

  return Promise.all([
    dispatch(
      updateCpfisSrsHoldingsRequest(
        accountId,
        holdingsChanges.map(({ id, estimatedGrowthRate }) => ({
          assetHoldingId: id,
          estimatedGrowthRate,
        })),
        updateApimCpfisHolding
      )
    ),
    ...promisesHoldingBalancesChanges,
  ]);
};

export const updateApimUnitTrustHoldingsRequest = (
  holdings: {
    assetHoldingId: string;
    percentageShare?: number;
    estimatedGrowthRate?: number;
  }[]
): RequestAction => (dispatch) => {
  const promises = holdings.map((holding) => {
    return axios.patch(`${apiUrl}/v1/apim/asset-holding/${holding.assetHoldingId}`, {
      percentageShare: holding.percentageShare,
      estimatedGrowthRate: holding.estimatedGrowthRate,
    });
  });

  promises.forEach((promise) =>
    promise
      .then(
        ({
          data: {
            data: { apimUnitTrust, assetHoldingBalances, percentageShare, estimatedGrowthRate },
          },
        }) => {
          assetHoldingBalances.forEach((balanceId: string) => {
            dispatch(
              updateApimUnitTrustHolding(apimUnitTrust, balanceId, {
                percentageShare,
                estimatedGrowthRate,
              })
            );
          });
        }
      )
      .catch(() => {})
  );

  return Promise.all(promises);
};

export const updateApimUnitTrustHoldingsAndBalancesRequest = (
  holdings: IApimUnitTrustHoldingFormatted[]
): RequestAction => (dispatch, getState) => {
  const currHoldings = getApimUnitTrustHoldingsFormatted(getState());
  const holdingBalancesChanges = differenceWith(
    holdings,
    currHoldings,
    (a, b) => a.id === b.id && a.unitPrice === b.unitPrice
  );

  const holdingsChanges = differenceWith(
    holdings,
    currHoldings,
    (a, b) =>
      a.id === b.id &&
      a.percentageShare === b.percentageShare &&
      a.estimatedGrowthRate === b.estimatedGrowthRate
  );

  const accountIds = {};
  getState().apim.unitTrust.forEach((unitTrust) => {
    unitTrust.holdings.forEach((holding) => {
      accountIds[holding.id] = unitTrust.id;
    });
  });

  if (featureDecisions.mockServerResponses) {
    holdingBalancesChanges.forEach((holding) => {
      dispatch(
        updateApimUnitTrustHolding(accountIds[holding.id], holding.id, {
          unitPrice: holding.unitPrice,
        })
      );
    });

    holdingsChanges.forEach((holding) => {
      dispatch(
        updateApimUnitTrustHolding(accountIds[holding.id], holding.id, {
          percentageShare: holding.percentageShare,
          estimatedGrowthRate: holding.estimatedGrowthRate,
        })
      );
    });

    return Promise.resolve();
  }

  const promisesHoldingBalancesChanges = holdingBalancesChanges.map((holding) => {
    return axios.patch(`${apiUrl}/v1/apim/asset-holding/balance/${holding.id}`, {
      unitPrice: holding.unitPrice,
    });
  });

  promisesHoldingBalancesChanges.forEach((promise) =>
    promise
      .then(
        ({
          data: {
            data: { id, unitPrice },
          },
        }) => {
          dispatch(
            updateApimUnitTrustHolding(accountIds[id], id, {
              unitPrice,
            })
          );
        }
      )
      .catch(() => {})
  );

  return Promise.all([
    ...promisesHoldingBalancesChanges,
    dispatch(updateApimUnitTrustHoldingsRequest(holdingsChanges)),
  ]);
};

export const updateApimCasa = (id: string, update: Partial<IApimCasa>): IUpdateApimCasa => ({
  type: APIM_ACTIONS.UPDATE_CASA,
  id,
  update,
});

export const updateApimCdp = (
  accountId: string,
  holdingId: string,
  update: Partial<IApimCdpHoldingFormatted>
): IUpdateApimCdp => ({
  type: APIM_ACTIONS.UPDATE_CDP_HOLDING,
  accountId,
  holdingId,
  update,
});

export const updateApimFixedDeposit = (
  id: string,
  update: Partial<IApimFixedDeposit>
): IUpdateApimFixedDeposit => ({
  type: APIM_ACTIONS.UPDATE_FIXED_DEPOSIT,
  id,
  update,
});

export const updateApimSecuredLoan = (
  id: string,
  update: Partial<IApimSecuredLoan>
): IUpdateApimSecuredLoan => ({
  type: APIM_ACTIONS.UPDATE_SECURED_LOAN,
  id,
  update,
});

export const updateApimUnsecuredLoan = (
  id: string,
  update: Partial<IApimUnsecuredLoan>
): IUpdateApimUnsecuredLoan => ({
  type: APIM_ACTIONS.UPDATE_UNSECURED_LOAN,
  id,
  update,
});

export const updateApimSrsAmount = (
  accountId: string,
  id: string,
  update: Partial<IApimAmount>
): IUpdateApimSrsAmount => ({
  type: APIM_ACTIONS.UPDATE_SRS_AMOUNT,
  accountId,
  id,
  update,
});

export const updateApimSrsHolding = (
  accountId: string,
  id: string,
  update: Partial<IApimHolding>
): IUpdateApimSrsHolding => ({
  type: APIM_ACTIONS.UPDATE_SRS_HOLDING,
  accountId,
  id,
  update,
});

export const updateApimCpfisAmount = (
  accountId: string,
  id: string,
  update: Partial<IApimAmount>
): IUpdateApimCpfisAmount => ({
  type: APIM_ACTIONS.UPDATE_CPFIS_AMOUNT,
  accountId,
  id,
  update,
});

export const updateApimCpfisHolding = (
  accountId: string,
  id: string,
  update: Partial<IApimHolding>
): IUpdateApimCpfisHolding => ({
  type: APIM_ACTIONS.UPDATE_CPFIS_HOLDING,
  accountId,
  id,
  update,
});

export const updateApimUnitTrustHolding = (
  accountId: string,
  id: string,
  update: Partial<IApimHolding>
): IUpdateApimUnitTrustHolding => ({
  type: APIM_ACTIONS.UPDATE_UNIT_TRUST_HOLDING,
  accountId,
  id,
  update,
});

import mixpanel from 'mixpanel-browser';

/**
 * The following data tags are interlinked with the document "Mixpanel Tags MasterList"
 * under FPDS > 03 Development > 08 Investments > 04 Development > Data Analytics
 * https://docs.google.com/spreadsheets/d/1P5JAJDdIMu6uHjICNFnuU5i3d2jP-RPwn7u7_m8pglI/edit#gid=0
 *
 * Whenever a data tag function is added, get removed or is no longer being called in code,
 * the document should be updated accordingly too to sync up with business side.
 */

// S/N 023
export const captureReviewDaySet = (dayOfTheMonth: number) => {
  mixpanel.track('Review day: Review day button clicked', { dayOfTheMonth });
};

// S/N 024
export const captureCalendarReminderSet = (calendarType: 'apple' | 'google' | 'ics') => {
  mixpanel.track('Calendar reminder set', { calendarType });
};

import React, { useState, useLayoutEffect } from 'react';
import MediaQuery from 'react-responsive';
import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';

import { VerticalWaveSvg, HorizontalWaveSvg } from 'assets/Templates';
import { ITheme, colorScale, breakpoints, minWidth, heights, layers } from 'themes';

import { TEMPLATE_BACKGROUND_ID } from './constants';
import { convertPxStringToNumber } from './helpers';

const CURTAIN_HORIZONTAL_EDGE_HEIGHT_PX = '64px';
const ADDITIONAL_GAP_PX = '16px';
export const CURTAIN_HORIZONTAL_EDGE_TOTAL_HEIGHT_PX =
  convertPxStringToNumber(CURTAIN_HORIZONTAL_EDGE_HEIGHT_PX) +
  convertPxStringToNumber(ADDITIONAL_GAP_PX);
export const CURTAIN_STOP_POINT_ID = 'curtain-stop-point-id';

const DEFAULT_CURTAIN_HEIGHT_PX = '0px';

const Curtain = styled.span`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${layers.templateBackgroundBack};

  ${minWidth(
    'desktop',
    css`
      flex-direction: row;
      position: absolute;
      top: 0;
      right: auto;
      bottom: 0;
      left: 0;
    `
  )}
`;

const CurtainBody = styled.span<{ curtainColor: (props: ITheme) => string; curtainHeight: string }>`
  display: inline-block;
  background: ${prop('curtainColor')};
  height: ${prop('curtainHeight')};

  ${minWidth(
    'desktop',
    css`
      width: 30vw;
      height: 100%;
    `
  )}
`;

const CurtainHorizontalEdge = styled(HorizontalWaveSvg)<{
  $curtainColor: (props: ITheme) => string;
}>`
  color: ${prop('$curtainColor')};
  width: 100%;
  max-height: ${CURTAIN_HORIZONTAL_EDGE_HEIGHT_PX};
`;

const CurtainVerticalEdge = styled(VerticalWaveSvg)<{ $curtainColor: (props: ITheme) => string }>`
  color: ${prop('$curtainColor')};
  height: 100%;
`;

interface IProps {
  curtainColor?: (props: ITheme) => string;
}

/**
 * Requires the page to have an element with the id CURTAIN_STOP_POINT_ID.
 *
 * On desktop, it renders a vertical wave on the left side of the screen.
 *
 * On mobile/tablet, it renders a horizontal wave from top down to the
 * position of where the aforementioned element ends.
 *
 * @param curtainColor Determines the color of the whole curtain background
 */
export const HalfCurtain = ({ curtainColor = colorScale('supporting-red', 50) }: IProps) => {
  const [curtainHeight, setCurtainHeight] = useState(DEFAULT_CURTAIN_HEIGHT_PX);

  useLayoutEffect(() => {
    const adjustCurtainHeight = () => {
      const curtainStopPoint = document.getElementById(CURTAIN_STOP_POINT_ID);

      if (curtainStopPoint) {
        const navBarHeight = convertPxStringToNumber(heights.navbar);
        const curtainHeightPosition = curtainStopPoint.getBoundingClientRect().bottom;

        const curtainHeight = curtainHeightPosition + window.scrollY - navBarHeight;
        setCurtainHeight(`${curtainHeight}px`);
      } else {
        // Fallback for pages using templates with no predetermined curtain height
        // and has not specified the curtain height in its content,
        // set curtain height to half of the viewport's height
        setCurtainHeight('50vh');
      }
    };

    if (curtainHeight === DEFAULT_CURTAIN_HEIGHT_PX) {
      adjustCurtainHeight();
    }

    window.addEventListener('resize', adjustCurtainHeight);

    return () => {
      window.removeEventListener('resize', adjustCurtainHeight);
    };
  }, [curtainHeight]);

  return (
    <Curtain id={TEMPLATE_BACKGROUND_ID}>
      <CurtainBody curtainColor={curtainColor} curtainHeight={curtainHeight} />

      <MediaQuery maxWidth={breakpoints.tabletMax}>
        <CurtainHorizontalEdge $curtainColor={curtainColor} />
      </MediaQuery>

      <MediaQuery minWidth={breakpoints.desktopMin}>
        <CurtainVerticalEdge $curtainColor={curtainColor} />
      </MediaQuery>
    </Curtain>
  );
};

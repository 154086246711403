import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { colorScale, typography } from 'themes';
import { visuallyHiddenProperties } from 'utilities/sharedStyles';

const labelOnFocusProperties = css`
  ${ifProp(
    { collapseLabel: true },
    css`
      transform: translateY(2rem);
      transition: transform 0.05s linear;
    `
  )}

  ${ifProp(
    { hasInput: true },
    css`
      transform: translateY(0);
    `
  )}
`;

const labelColorProperties = css`
  color: ${colorScale('grey', 80)};

  ${ifProp(
    'hasError',
    css`
      color: ${colorScale('supporting-red', 50)};
    `
  )}
`;

const labelDisabledProperties = css`
  ${ifProp(
    'disabled',
    css`
      color: ${colorScale('grey', 80)};
    `
  )}
`;

interface ILabelProps {
  hideLabel?: boolean;
  hasInput: boolean;
  hasError: boolean;
  disabled?: boolean;
  collapseLabel?: boolean;
}

export const Label = styled.label<ILabelProps>`
  ${ifProp(
    'hideLabel',
    visuallyHiddenProperties,
    css`
      ${typography('body-s-semibold')}
      display: block;
    `
  )}
  ${labelColorProperties};
  ${labelOnFocusProperties};
  ${labelDisabledProperties};
`;

export const CustomLabelWrapper = styled.div<ILabelProps>`
  ${ifProp('hideLabel', visuallyHiddenProperties)}
  ${labelColorProperties}
  ${labelOnFocusProperties};
`;

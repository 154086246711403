import detectIt from 'detect-it';
import { clamp } from 'lodash';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { ifProp, prop, withProp, ifNotProp } from 'styled-tools';

import { positiveModulo } from 'utilities/mathUtilities';

import { LeftButton, RightButton } from './Buttons';
import { Dot } from './Dot';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerWrapper = styled.div<{
  isTouchDevice: boolean;
  innerWrapperPadding: number | undefined;
}>`
  position: relative;

  ${ifProp(
    'innerWrapperPadding',
    ifNotProp(
      'isTouchDevice',
      css`
        padding: 0 ${prop('innerWrapperPadding')}px;
      `
    )
  )}
`;

const PaddingMask = styled.div<{ overflowAmount: number }>`
  margin-left: -${prop('overflowAmount')}px;
  margin-right: -${prop('overflowAmount')}px;
  padding-left: ${prop('overflowAmount')}px;
  padding-right: ${prop('overflowAmount')}px;
  overflow-x: hidden;
`;

const MainContainer = styled.div<{ overflowAmount: number; gridGap: number }>`
  overflow-x: hidden;
  margin-left: calc(-${prop('overflowAmount')}px * 2 - ${prop('gridGap')}px);
  margin-right: calc(-${prop('overflowAmount')}px * 2 - ${prop('gridGap')}px);

  &:focus {
    outline: none;
  }
`;

interface IItemListProps {
  numberOfItemsToShow: number;
  gridGap: number;
  overflowAmount: number;
  peekAmount: number;
  shouldTransition: boolean;
}
const ItemList = styled.div<IItemListProps>`
  display: grid;
  transition: ${ifProp('shouldTransition', 'transform 300ms ease')};
  grid-gap: ${prop('gridGap')}px;
  grid-template-columns: 0px;
  grid-auto-flow: column;
  grid-auto-columns: ${css`
    // Formula: W = [100% - (1 + N) * G - 4 * O] / [N + P]
    // W: Item Width, N: numberOfItemsToShow, G: gridGap, O: overflowAmount, P: peekAmount
    ${withProp(
      ['numberOfItemsToShow', 'gridGap', 'overflowAmount', 'peekAmount'],
      (numberOfItemsToShow, gridGap, overflowAmount, peekAmount) => `
        calc(${100 / (peekAmount + numberOfItemsToShow)}% - ${
        ((numberOfItemsToShow + 1) * gridGap + 4 * overflowAmount) /
        (peekAmount + numberOfItemsToShow)
      }px)`
    )};
  `};

  &:before,
  &:after {
    content: '';
    width: ${prop('gridGap')}px;
  }
`;

const DotContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-self: center;
  margin: 1rem 0;
`;

const VELOCITY_MULTIPLIER = 35;

interface IOwnProps {
  children: React.ReactNode;

  /** Whether to show indicator dots (Only applicable if
   * `targetNumberOfItemsToShow` is 1)
   */
  showDots: boolean;

  /** Whether to show control arrows for non-touch devices */
  showArrows: boolean;

  /** Whether carousel should infinitely wrap (Only applicable if the number of
   * items inside is greater than `targetNumberOfItemsToShow`)
   */
  wrap: boolean;

  /** Number of items to show in view */
  targetNumberOfItemsToShow: number;

  /** Number of items to scroll in one click */
  targetNumberOfItemsToScroll?: number;

  /** Gap size between items in pixels */
  gridGap: number;

  /** Fraction of item to show as next item in touch view */
  peekAmount: number;

  /** Amount to overflow on the left and right in pixels */
  targetOverflowAmount: number;

  /** scrollWidth to be used */
  scrollWidth?: number;

  /** offsetWidth to be used */
  offsetWidth?: number;

  /** index of item to be displayed */
  targetSelectedItemIndex?: number;

  /** callback to set the index for externally controlled carousels*/
  setSelectedItemIndex?: (index: number) => void;

  /** whether the carousel is controllable */
  isControllable?: boolean;

  /** padding between arrows & content for non-touch devices */
  innerWrapperPadding?: number;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

interface IState {
  /** The index of the left most item in view port (accounts for the multiple copies in DOM) */
  selectedItemIndex: number;

  /** The current X-value of the item list transform in pixels */
  deltaX: number;

  /** The desired X-value of the item list transform in pixels (Will only be applied on next render) */
  targetDeltaX: number | null;

  /** The initial value of the touch point in pixels */
  startingPoint: {
    x: number;
    y: number;
  };

  /** Whether the carousel is currently being dragged */
  isDragging: boolean;

  /** The X-value of the item list transform at the start of the drag in pixels */
  startingDeltaX: number;

  /** The lowerbound of where the user can scroll to in pixels */
  lowerBound: number;

  /** The upperbound of where the user can scroll to in pixels */
  upperBound: number;

  /** Whether the carousel should apply a smooth transition when transforming */
  shouldTransition: boolean;

  /** Derived number of items to show in view port */
  numberOfItemsToShow: number;

  /** Derived number of items to scroll by in view port */
  numberOfItemsToScroll: number;

  /** Derived value of whether the element should infinitely wrap */
  shouldWrap: boolean;

  /** Derived value of overflow amount as mouse device will not use this */
  overflowAmount: number;

  /** Scroll velocity for velocity scrolling */
  velocity: number;

  /** Scroll direction */
  scrollDirection: SCROLL_DIRECTION;
}

enum SCROLL_DIRECTION {
  NONE,
  X,
  Y,
}

export class Carousel extends React.PureComponent<IProps, IState> {
  public static defaultProps = {
    showDots: true,
    showArrows: true,
    wrap: false,
    gridGap: 10,
    peekAmount: 0,
    targetOverflowAmount: 40,
    isControllable: true,
  };

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const {
      targetNumberOfItemsToShow,
      targetNumberOfItemsToScroll,
      wrap,
      children,
      targetOverflowAmount,
    } = props;
    const childCount = React.Children.count(children);

    const numberOfItemsToShow = Math.ceil(Math.min(targetNumberOfItemsToShow, childCount));
    const shouldWrap = wrap && childCount !== numberOfItemsToShow;
    const numberOfItemsToScroll = Math.ceil(
      Math.min(targetNumberOfItemsToScroll || numberOfItemsToShow, numberOfItemsToShow)
    );

    const overflowAmount = detectIt.primaryInput === 'mouse' ? 0 : targetOverflowAmount;

    if (
      numberOfItemsToShow === state.numberOfItemsToShow &&
      numberOfItemsToScroll === state.numberOfItemsToScroll &&
      overflowAmount === state.overflowAmount &&
      shouldWrap === state.shouldWrap
    ) {
      return null;
    }

    return {
      numberOfItemsToShow,
      shouldWrap,
      numberOfItemsToScroll,
      selectedItemIndex: shouldWrap ? childCount : 0,
      lowerBound: shouldWrap ? -Infinity : 0,
      upperBound: shouldWrap ? Infinity : 0,
      deltaX: overflowAmount * 2,
      overflowAmount,
    };
  }

  private readonly mainContainerRef: React.RefObject<HTMLDivElement>;
  private readonly itemListRef: React.RefObject<HTMLDivElement>;
  private innerWidth = 0;

  constructor(props: IProps) {
    super(props);

    const {
      targetNumberOfItemsToShow,
      targetNumberOfItemsToScroll,
      targetOverflowAmount,
      gridGap,
      peekAmount,
      children,
      targetSelectedItemIndex,
      setSelectedItemIndex,
    } = this.props;

    if (targetNumberOfItemsToShow < 1) {
      throw Error('targetNumberOfItemsToShow must be greater than 0.');
    }

    if (targetNumberOfItemsToScroll && targetNumberOfItemsToScroll < 1) {
      throw Error('targetNumberOfItemsToScroll must be greater than 0.');
    }

    if (gridGap && gridGap < 0) {
      throw Error('gridGap must not be less than 0.');
    }

    if (peekAmount && peekAmount < 0) {
      throw Error('peekAmount must not be less than 0.');
    }

    if (targetOverflowAmount && targetOverflowAmount < 0) {
      throw Error('targetOverflowAmount must not be less than 0.');
    }

    const childCount = React.Children.count(children);
    if (childCount < 1) {
      throw Error('Carousel component must contain children.');
    }

    if (targetSelectedItemIndex !== undefined && setSelectedItemIndex === undefined) {
      throw Error('Controlled carousels must specify setSelectedItemIndex');
    }

    this.itemListRef = React.createRef();
    this.mainContainerRef = React.createRef();

    this.state = {
      selectedItemIndex: 0,
      deltaX: 0,
      startingPoint: { x: 0, y: 0 },
      startingDeltaX: 0,
      isDragging: false,
      shouldTransition: false,
      lowerBound: 0,
      upperBound: 0,
      targetDeltaX: null,
      numberOfItemsToShow: 0,
      numberOfItemsToScroll: 0,
      shouldWrap: false,
      overflowAmount: 0,
      scrollDirection: SCROLL_DIRECTION.NONE,
      velocity: 0,
    };

    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.shiftToTargetFrame = this.shiftToTargetFrame.bind(this);
    this.snap = this.snap.bind(this);
    this.shouldShowItem = this.shouldShowItem.bind(this);
    this.isAbleToGoLeft = this.isAbleToGoLeft.bind(this);
    this.isAbleToGoRight = this.isAbleToGoRight.bind(this);
    this.handleLeftButtonPress = this.handleLeftButtonPress.bind(this);
    this.handleRightButtonPress = this.handleRightButtonPress.bind(this);
    this.getScrollAmount = this.getScrollAmount.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.scrollBy = this.scrollBy.bind(this);
    this.getItemIndexFromDeltaX = this.getItemIndexFromDeltaX.bind(this);
    this.getItemWidth = this.getItemWidth.bind(this);
    this.getClampedRoundedValue = this.getClampedRoundedValue.bind(this);
    this.getShouldShift = this.getShouldShift.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.recalculateBounds = this.recalculateBounds.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.getItemListSizing = this.getItemListSizing.bind(this);
  }

  public componentDidMount() {
    if (this.mainContainerRef.current) {
      this.mainContainerRef.current.addEventListener('touchmove', this.handleDrag);
    }

    window.addEventListener('resize', this.handleResize);
    this.recalculateBounds();
    if (this.state.shouldWrap) {
      this.setState({
        deltaX:
          -this.getItemWidth() * React.Children.count(this.props.children) +
          this.state.overflowAmount * 2,
      });
    }
    this.innerWidth = window.innerWidth;
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public componentDidUpdate(_: IProps, previousState: IState) {
    const { targetSelectedItemIndex, children } = this.props;

    this.recalculateBounds();

    if (this.state.shouldWrap !== previousState.shouldWrap) {
      this.setState({
        deltaX:
          -this.getItemWidth() * React.Children.count(children) + this.state.overflowAmount * 2,
      });
    }

    const { isDragging, velocity, selectedItemIndex } = this.state;

    if (!isDragging) {
      if (velocity !== 0) {
        const maxScrollAmount = this.getScrollAmount();
        this.scrollBy(clamp(velocity, -maxScrollAmount, maxScrollAmount));
        this.setState({ velocity: 0 });
      }

      this.snap();

      this.setState((prevState: IState) => {
        const { deltaX, targetDeltaX } = prevState;
        if (targetDeltaX !== null) {
          return {
            shouldTransition: true,
            deltaX: deltaX + targetDeltaX,
            targetDeltaX: null,
          };
        } else {
          return null;
        }
      });
    }

    if (targetSelectedItemIndex !== undefined && targetSelectedItemIndex !== selectedItemIndex) {
      this.goToIndex(targetSelectedItemIndex);
    }
  }

  public render() {
    const {
      children,
      showDots,
      showArrows,
      wrap,
      targetNumberOfItemsToShow,
      targetNumberOfItemsToScroll,
      gridGap,
      peekAmount,
      isControllable,
      innerWrapperPadding,
      ...otherProps
    } = this.props;

    const {
      shouldWrap,
      numberOfItemsToShow,
      shouldTransition,
      selectedItemIndex,
      overflowAmount,
      deltaX,
    } = this.state;

    const {
      mainContainerRef,
      itemListRef,
      shouldShowItem,
      isAbleToGoLeft,
      isAbleToGoRight,
      handleLeftButtonPress,
      handleRightButtonPress,
      handleDragStart,
      handleDragEnd,
      handleKeyPress,
      goToIndex,
    } = this;
    const childCount = React.Children.count(children);

    return (
      <Wrapper {...otherProps}>
        <InnerWrapper isTouchDevice={isMobile} innerWrapperPadding={innerWrapperPadding}>
          {detectIt.primaryInput === 'mouse' && showArrows && (
            <LeftButton
              data-testid={`left-button${!isAbleToGoLeft() ? '-disabled' : ''}`}
              onClick={handleLeftButtonPress}
              disabled={!isAbleToGoLeft()}
            />
          )}
          <PaddingMask overflowAmount={overflowAmount}>
            <MainContainer
              data-testid="main-container"
              ref={mainContainerRef}
              onTouchStart={isControllable ? handleDragStart : () => null}
              onTouchEnd={isControllable ? handleDragEnd : () => null}
              onKeyDown={isControllable ? handleKeyPress : () => null}
              overflowAmount={overflowAmount}
              gridGap={gridGap}
              tabIndex={0}
            >
              <ItemList
                data-testid="item-list"
                ref={itemListRef}
                numberOfItemsToShow={numberOfItemsToShow}
                gridGap={gridGap}
                overflowAmount={overflowAmount}
                peekAmount={peekAmount}
                style={{ transform: `translate(${deltaX}px, 0)` }}
                shouldTransition={shouldTransition}
              >
                {[0, 1, 2].map((n) => (
                  <React.Fragment key={n}>
                    {(shouldWrap || n === 0) &&
                      React.Children.map(children, (child, i) => {
                        const childIndex = i + childCount * n;
                        return (
                          <div
                            data-testid={`child-${childIndex}`}
                            aria-hidden={!shouldShowItem(childIndex)}
                          >
                            {child}
                          </div>
                        );
                      })}
                  </React.Fragment>
                ))}
              </ItemList>
            </MainContainer>
          </PaddingMask>
          {detectIt.primaryInput === 'mouse' && showArrows && (
            <RightButton
              data-testid={`right-button${!isAbleToGoRight() ? '-disabled' : ''}`}
              onClick={handleRightButtonPress}
              disabled={!isAbleToGoRight()}
            />
          )}
        </InnerWrapper>
        {showDots && numberOfItemsToShow === 1 && (
          <DotContainer>
            {React.Children.map(children, (_, i) => (
              <Dot
                data-testid={`dot-${i}`}
                key={i}
                selected={positiveModulo(selectedItemIndex, childCount) === i}
                onClick={goToIndex.bind(this, i)}
              />
            ))}
          </DotContainer>
        )}
      </Wrapper>
    );
  }

  private handleDragStart(event: React.TouchEvent<HTMLDivElement>) {
    const { clientX: selectedX, clientY: selectedY } = event.touches[0];
    this.setState((prevState: IState) => ({
      shouldTransition: false,
      isDragging: true,
      startingDeltaX: prevState.deltaX,
      startingPoint: { x: selectedX, y: selectedY },
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleDrag(event: any) {
    const { clientX: selectedX, clientY: selectedY } = event.touches[0];
    const { isDragging, scrollDirection, startingPoint } = this.state;
    if (isDragging) {
      let newScrollDirection: SCROLL_DIRECTION = SCROLL_DIRECTION.NONE;

      if (scrollDirection === SCROLL_DIRECTION.NONE) {
        newScrollDirection =
          Math.abs(selectedX - startingPoint.x) > Math.abs(selectedY - startingPoint.y)
            ? SCROLL_DIRECTION.X
            : SCROLL_DIRECTION.Y;
        this.setState({ scrollDirection: newScrollDirection });
      }

      if (scrollDirection === SCROLL_DIRECTION.X || newScrollDirection === SCROLL_DIRECTION.X) {
        event.preventDefault();

        let index: number;
        this.setState(
          (prevState: IState) => {
            const deltaX = prevState.startingDeltaX + selectedX - startingPoint.x;
            index = this.getItemIndexFromDeltaX(deltaX);

            return { deltaX, velocity: (deltaX - prevState.deltaX) * VELOCITY_MULTIPLIER };
          },
          () => {
            this.shiftToTargetFrame(index);
          }
        );
      }
    }
  }

  private handleDragEnd() {
    if (this.state.isDragging) {
      this.setState({ isDragging: false, scrollDirection: SCROLL_DIRECTION.NONE });
    }
  }

  private shiftToTargetFrame(index: number) {
    const { setSelectedItemIndex, children } = this.props;

    const childCount = React.Children.count(children);
    const shouldShift = this.getShouldShift(index);
    const frameWidth = this.getItemWidth() * childCount;

    if (shouldShift) {
      this.setState((prevState: IState) => {
        const shiftMultiplier = index < prevState.selectedItemIndex ? -1 : 1;

        return {
          shouldTransition: false,
          deltaX: prevState.deltaX + frameWidth * shiftMultiplier,
          startingPoint: {
            x: prevState.startingPoint.x - frameWidth * shiftMultiplier,
            y: prevState.startingPoint.y,
          },
          selectedItemIndex: index - shiftMultiplier * childCount,
        };
      });
    } else {
      setSelectedItemIndex && setSelectedItemIndex(index);
      this.setState({ selectedItemIndex: index });
    }
  }

  private snap() {
    this.setState((prevState: IState) => {
      const { deltaX, isDragging } = prevState;
      const clampedDeltaX = -this.getClampedRoundedValue(-deltaX);

      if (!isDragging && clampedDeltaX !== deltaX) {
        return {
          deltaX: clampedDeltaX,
          shouldTransition: true,
        };
      } else {
        return null;
      }
    });
  }

  private shouldShowItem(index: number) {
    const { selectedItemIndex, numberOfItemsToShow } = this.state;
    return index >= selectedItemIndex && index < selectedItemIndex + numberOfItemsToShow;
  }

  private isAbleToGoLeft() {
    return this.state.shouldWrap || this.state.selectedItemIndex > 0;
  }

  private isAbleToGoRight() {
    const { shouldWrap, numberOfItemsToShow } = this.state;
    const { children } = this.props;
    return (
      shouldWrap ||
      this.state.selectedItemIndex < React.Children.count(children) - numberOfItemsToShow
    );
  }

  private handleLeftButtonPress() {
    this.scrollBy(this.getScrollAmount());
  }

  private handleRightButtonPress() {
    this.scrollBy(-this.getScrollAmount());
  }

  private getScrollAmount() {
    return this.getItemWidth() * this.state.numberOfItemsToScroll;
  }

  private handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    const { isAbleToGoLeft, isAbleToGoRight, handleLeftButtonPress, handleRightButtonPress } = this;

    switch (event.key) {
      case 'ArrowLeft':
        if (isAbleToGoLeft()) {
          handleLeftButtonPress();
        }
        break;
      case 'ArrowRight':
        if (isAbleToGoRight()) {
          handleRightButtonPress();
        }
        break;
      default:
    }
  }

  private scrollBy(scrollAmount: number) {
    const scrollPosition = -this.state.deltaX - scrollAmount;
    const deltaX = -this.getClampedRoundedValue(scrollPosition);
    const indexToScrollTo = this.getItemIndexFromDeltaX(deltaX);

    this.shiftToTargetFrame(indexToScrollTo);
    this.setState({ targetDeltaX: scrollAmount });
  }

  private getItemIndexFromDeltaX(deltaX: number) {
    const { lowerBound, upperBound, overflowAmount } = this.state;
    const itemWidth = this.getItemWidth();
    return Math.round(clamp(-deltaX + overflowAmount * 2, lowerBound, upperBound) / itemWidth);
  }

  private getItemWidth() {
    const repeatCount = this.state.shouldWrap ? 3 : 1;
    return (
      (this.getItemListSizing().scrollWidth - this.props.gridGap * 2) /
      React.Children.count(this.props.children) /
      repeatCount
    );
  }

  private getClampedRoundedValue(value: number) {
    const { lowerBound, upperBound, overflowAmount } = this.state;
    const itemWidth = this.getItemWidth();

    return (
      clamp(this.getItemIndexFromDeltaX(-value) * itemWidth, lowerBound, upperBound) -
      overflowAmount * 2
    );
  }

  private getShouldShift(index: number) {
    const childCount = React.Children.count(this.props.children);

    if (!this.state.shouldWrap) {
      return positiveModulo(index, childCount) !== index;
    }

    if (index > 0 && index < childCount * 3 - this.state.numberOfItemsToShow) {
      return false;
    }

    return positiveModulo(index + childCount - 1, childCount) + 1 !== index;
  }

  private handleResize() {
    const newWidth = window.innerWidth;

    if (newWidth !== this.innerWidth) {
      this.innerWidth = newWidth;
      this.recalculateBounds();
      this.goToIndex(this.state.selectedItemIndex);
    }
  }

  private recalculateBounds() {
    if (!this.state.shouldWrap) {
      const { offsetWidth, scrollWidth } = this.getItemListSizing();
      this.setState((prevState: IState) => {
        const upperBound =
          scrollWidth + prevState.overflowAmount * 4 - offsetWidth - this.props.gridGap;

        if (upperBound !== prevState.upperBound) {
          return { upperBound };
        }

        return null;
      });
    }
  }

  private getItemListSizing() {
    if (this.itemListRef.current) {
      const {
        offsetWidth: trueOffsetWidth,
        scrollWidth: trueScrollWidth,
      } = this.itemListRef.current;
      const offsetWidth = this.props.offsetWidth || trueOffsetWidth;
      const scrollWidth = this.props.scrollWidth || trueScrollWidth;
      return { offsetWidth, scrollWidth };
    } else {
      const offsetWidth = this.props.offsetWidth || 0;
      const scrollWidth = this.props.scrollWidth || 0;
      return { offsetWidth, scrollWidth };
    }
  }

  private goToIndex(targetIndex: number) {
    const { selectedItemIndex, shouldWrap } = this.state;
    const childCount = React.Children.count(this.props.children);
    const indexToGoTo = shouldWrap
      ? Math.round((selectedItemIndex - targetIndex) / childCount) * childCount + targetIndex // Calculate the nearest equivalent index
      : targetIndex;

    this.scrollBy((selectedItemIndex - indexToGoTo) * this.getItemWidth());
  }
}

/**
 * Expands an object containing an array to an array of duplicated objects
 * @kind curried function
 * @param {string} key The key of the object that contains the array to be expanded
 * @param {object} obj The actual input object
 * @returns {object[] | object} An array of duplicated objects whose particular keys are now elements of the original array. If no key exists, returns the object itself.
 * @example
 * explode('b')({ a: 1, b: [1, 2] }) // returns [{ a: 1, b: 1 }, { a: 1, b: 2 }]
 */
export const explode = (key: string) => (obj: object) =>
  key in obj && Array.isArray(obj[key])
    ? obj[key].map((ele: unknown) => ({
        ...obj,
        [key]: ele,
      }))
    : obj;

import { ASSET_TYPE } from 'constants/enums';

export const mockAppDataEmpty = {
  data: {
    user: {
      id: 'simulate-uuid-from-data-baseempty000',
      name: null,
      email: null,
      hasStartedPlanning: null,
      dayOfTransfer: null,
      hasCompletedFeedbackForm: false,
      registeredAt: '2020-06-11T00:16:14.889Z',
      ageRange: null,
      workYearsRange: null,
      automaticTransferStatus: null,
      expenses: [],
      emergencyFund: null,
      goals: [],
      apimMyinfoDataSelectionState: null,
      moduleStatuses: [
        {
          id: '8daa9b1f-a95b-4a1f-bf60-737ce031d389',
          currentStep: null,
          lastUpdated: null,
          lastCompleted: null,
          module: {
            id: 'BUDGET_CALCULATOR',
            description: 'Manage your expenses and save more for your future.',
          },
        },
        {
          id: 'f7a97c08-3c10-4620-adc0-cb8542b748a0',
          currentStep: null,
          lastUpdated: null,
          lastCompleted: null,
          module: {
            id: 'CASHFLOW',
            description: 'How much you earn, spend and save monthly',
          },
        },
        {
          id: '7b287c9c-907f-43ce-a738-bdbcad72a0dc',
          currentStep: null,
          lastUpdated: null,
          lastCompleted: null,
          module: {
            id: 'WEALTH',
            description: 'The value of what you own and what you owe',
          },
        },
        {
          id: 'aecc282f-93dd-4e69-9035-4250fc17f5a1',
          currentStep: null,
          lastUpdated: null,
          lastCompleted: null,
          module: {
            id: 'SET_SAVING_TARGETS',
            description: 'Set savings targets',
          },
        },
      ],
      personalNotifications: [],
      todoNotificationAcknowledgements: [],
      inbuiltNotificationAcknowledgements: [],
      globalNotifications: [],
      loanRepaymentList: [],
      incomeList: [],
      assets: {},
      cpfAsset: {
        id: '0',
        oa: 0,
        sa: 0,
        ma: 0,
        ra: 0,
        type: ASSET_TYPE.CPF,
      },
      cashBalanceAssets: [],
      cashSavingsAssets: [],
      bondAssets: [],
      goldAssets: [],
      fixedDepositAssets: [],
      insuranceAssets: [],
      stockAssets: [],
      structuredProductAssets: [],
      unitTrustAssets: [],
      otherInvestmentAssets: [],
      otherNonInvestmentAssets: [],
      recommendedActions: [],
      isReturnUser: false,
      articlesCompleted: [],
      housingPlans: [],
      investingForBeginners: {
        itemsCompleted: [],
        insurance: [],
      },
    },
  },
};

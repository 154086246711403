import { Page, View, Text, Image } from '@react-pdf/renderer';
import React from 'react';

import LogoImage from 'assets/Miscellaneous/logo.png';

import { commonStyles, margin } from '../styles';

import { BasePageStyles as styles } from './BasePageStyles';

interface IBasePageProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  isSamplePage?: boolean;
}

export const BasePage = ({ title, subtitle, children, isSamplePage }: IBasePageProps) => (
  // default A4, Portrait
  <Page style={styles.page}>
    <View style={styles.header} fixed>
      {isSamplePage && <View style={styles.sampleContainer} />}
      <View style={[styles.titleContainer, { marginTop: isSamplePage ? margin.medium : 0 }]}>
        <Text style={commonStyles.title}>{title}</Text>
        <Image src={LogoImage} style={styles.logo} />
      </View>
      {subtitle && <Text style={commonStyles.subtitle}>{subtitle}</Text>}
    </View>
    <View style={styles.pageContentContainer}>{children}</View>
    <View style={styles.footer} fixed>
      <Text>www.mymoneysense.gov.sg</Text>
    </View>
  </Page>
);

import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { ArrowDown } from 'assets/icons';
import { colorScale } from 'themes';

const StyledArrowDown = styled(ArrowDown)<{ disabled?: boolean }>`
  line-height: 1;
  margin: 0 8px;
  pointer-events: none;
  color: ${ifProp('disabled', colorScale('grey', 20), colorScale('grey', 50))};
  margin-left: auto;
`;

interface IProps {
  disabled?: boolean;
}
export class Caret extends React.PureComponent<IProps> {
  public render() {
    return <StyledArrowDown aria-hidden="true" focusable="false" disabled={this.props.disabled} />;
  }
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { omit } from 'lodash';
import uuidv4 from 'uuid/v4';

import { addMessage } from 'redux/messages/actions';

import { initialCustomizedDraftHousingPlan } from 'components/Housing/FinancingPlan/CustomizedPlan/constants';
import { pickHousingPlan, getRandomPlanColor } from 'components/Housing/FinancingPlan/helpers';
import { IHousingOutputs } from 'components/Housing/FinancingPlan/interfaces';
import { initialRecommendedDraftHousingPlan } from 'components/Housing/FinancingPlan/RecommendedPlan/constants';
import { ARTICLE_TYPES, PLAN_TYPE } from 'constants/enums';
import { INITIAL_DRAFT_HOUSING_PLAN_ID } from 'constants/housingConstants';
import { IHousingArticleResponse, IHousingPlanResponse } from 'constants/responseTypes';
import { IHousingStore, IHousingPlan } from 'constants/storeTypes';
import { featureDecisions } from 'featureDecisions';
import { mockDefaultPlanResponse, mockCustomPlanResponse } from 'utilities/mockData/mockAppData';
import { mockArticlesCompleted } from 'utilities/testData/mockHousingData';

import { convertHousingPlanResponse, validateHousingPlanResponse } from './helpers';

export const initialState: IHousingStore = {
  articlesCompleted: null,
  housingPlans: null,
};

const name = 'housing';

type IHousingPlanOutputs = IHousingPlan & Partial<IHousingOutputs>;

const housingSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setArticleCompletedRequest.fulfilled, (state, { payload }) => {
      const articlesCompleted = state.articlesCompleted || [];
      state.articlesCompleted = [...articlesCompleted, payload.article as ARTICLE_TYPES];
    });
    builder.addCase(getAllArticlesCompletedRequest.fulfilled, (state, { payload }) => {
      state.articlesCompleted = payload;
    });
    builder.addCase(upsertHousingPlanRequest.fulfilled, (state, { payload }) => {
      state.housingPlans = {
        ...state.housingPlans,
        [payload.id]: convertHousingPlanResponse(payload),
      };
    });
    builder.addCase(getAllHousingPlansRequest.fulfilled, (state, { payload }) => {
      state.housingPlans = payload.reduce<{ [id: string]: IHousingPlan }>((acc, housingPlan) => {
        acc[housingPlan.id] = convertHousingPlanResponse(housingPlan);
        return acc;
      }, {});
    });
    builder.addCase(deleteHousingPlanRequest.fulfilled, (state, { payload }) => {
      state.housingPlans = omit(state.housingPlans, payload);
    });
  },
});

export const housingReducer = housingSlice.reducer;

export const setArticleCompletedRequest = createAsyncThunk<IHousingArticleResponse, ARTICLE_TYPES>(
  `${name}/setArticleCompletedRequest`,
  async (article) => {
    if (featureDecisions.mockServerResponses) {
      return article;
    }

    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1/article`, {
      article,
    });
    return data.data;
  }
);

export const getAllArticlesCompletedRequest = createAsyncThunk<ARTICLE_TYPES[]>(
  `${name}/getAllArticlesCompletedRequest`,
  async () => {
    if (featureDecisions.mockServerResponses) {
      return mockArticlesCompleted;
    }

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/article`);
    return data.data;
  }
);

export const getAllHousingPlansRequest = createAsyncThunk<IHousingPlanResponse[]>(
  `${name}/getAllHousingPlansRequest`,
  async (_, { dispatch, rejectWithValue }) => {
    if (featureDecisions.mockServerResponses) {
      return [mockDefaultPlanResponse, mockCustomPlanResponse];
    }

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/housing-plan`);

    try {
      return validateHousingPlanResponse(data.data);
    } catch (e) {
      dispatch(addMessage(e.message, { variant: 'error' }));
      return rejectWithValue(e);
    }
  }
);

export const upsertHousingPlanRequest = createAsyncThunk<IHousingPlanResponse, IHousingPlanOutputs>(
  `${name}/upsertHousingPlanRequest`,
  async (draftHousingPlanOutputs) => {
    if (featureDecisions.mockServerResponses) {
      const id = draftHousingPlanOutputs.id;
      return {
        ...draftHousingPlanOutputs,
        lastUpdatedAt: new Date().toISOString(),
        id: id === INITIAL_DRAFT_HOUSING_PLAN_ID ? uuidv4() : id,
      };
    }

    const draftHousingPlan = pickHousingPlan(
      draftHousingPlanOutputs,
      draftHousingPlanOutputs.planType === PLAN_TYPE.DEFAULT
        ? initialRecommendedDraftHousingPlan
        : initialCustomizedDraftHousingPlan
    );

    const id = draftHousingPlan.id;

    const requestData = {
      ...omit(draftHousingPlan, 'id'),
      lastUpdatedAt: new Date(),
    };

    const promise =
      id === INITIAL_DRAFT_HOUSING_PLAN_ID
        ? axios.post(`${process.env.REACT_APP_API_URL}/v1/housing-plan`, requestData)
        : axios.patch(`${process.env.REACT_APP_API_URL}/v1/housing-plan/${id}`, requestData);

    const {
      data: { data: housingPlan },
    } = await promise;

    return housingPlan;
  }
);

export const duplicateHousingPlanRequest = createAsyncThunk(
  `${name}/duplicateHousingPlanRequest`,
  async (draftHousingPlan: IHousingPlan, { dispatch }) => {
    await dispatch(
      upsertHousingPlanRequest({
        ...draftHousingPlan,
        planName: `${draftHousingPlan.planName} copy`,
        planType: PLAN_TYPE.CUSTOM,
        planColor: getRandomPlanColor(),
        id: INITIAL_DRAFT_HOUSING_PLAN_ID,
      })
    );
  }
);

export const deleteHousingPlanRequest = createAsyncThunk<string, string>(
  `${name}/deleteHousingPlanRequest`,
  async (id) => {
    if (featureDecisions.mockServerResponses) {
      return id;
    }

    await axios.delete(`${process.env.REACT_APP_API_URL}/v1/housing-plan/${id}`);

    return id;
  }
);

import React from 'react';
import styled, { css } from 'styled-components';

import { MoneyPlantBackgroundLeftImage } from 'assets/Background';
import { WateringCanBackgroundRightImage } from 'assets/Background';
import { BackgroundWaveImage } from 'assets/Background';
import { MoneyPlantsAndWateringCanBackgroundImage } from 'assets/Background';
import { Container } from 'components/Common';
import { minWidth, maxWidth } from 'themes';

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 320px;
  align-self: center;
  height: 100%;
  padding-top: 64px;
`;

const StyledMoneyPlantBackgroundLeftImage = styled(MoneyPlantBackgroundLeftImage)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  height: 180px;
  display: none;

  ${minWidth(
    'tablet',
    css`
      display: block;
    `
  )}
`;

const StyledWateringCanBackgroundRightImage = styled(WateringCanBackgroundRightImage)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  height: 180px;
  display: none;

  ${minWidth(
    'tablet',
    css`
      display: block;
    `
  )}
`;

const StyledBackgroundWaveImage = styled(BackgroundWaveImage)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
  height: 100px;
  display: none;

  ${minWidth(
    'tablet',
    css`
      display: block;
    `
  )}
`;

const StyledMoneyPlantsAndWateringCanBackgroundImage = styled(
  MoneyPlantsAndWateringCanBackgroundImage
)`
  position: absolute;
  bottom: 0;
  width: 100%;

  ${minWidth(
    'tablet',
    css`
      display: none;
    `
  )};
`;

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentWrapper = styled(Wrapper)`
  max-width: 384px;

  ${minWidth(
    'tablet',
    css`
      padding-top: 0;
    `
  )}
  ${maxWidth(
    'tablet',
    css`
      padding: 0;
    `
  )};
`;

const BottomWrapper = styled.div`
  position: relative;
  justify-self: end;
`;

interface IProps {
  children?: React.ReactNode;
}

export const PageWithBackground = ({ children }: IProps) => {
  return (
    <StyledWrapper>
      <ContentWrapper>{children}</ContentWrapper>

      <BottomWrapper>
        <StyledBackgroundWaveImage />
        <StyledMoneyPlantBackgroundLeftImage />
        <StyledWateringCanBackgroundRightImage />
        <StyledMoneyPlantsAndWateringCanBackgroundImage />
      </BottomWrapper>
    </StyledWrapper>
  );
};

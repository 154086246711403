import styled from 'styled-components';

import { typeScale, typography } from 'themes';

import { AmountField } from '../AmountField/AmountField';
import { Button } from '../Button/Button';
import { DateField } from '../DateField/DateField';
import { Input } from '../Input/Input';
import { Heading } from '../SectionHeading/Heading';

export const StyledHeading = styled(Heading)`
  ${typography('heading-m')}
  margin-bottom: 28px;
`;

export const FieldWrapper = styled.div`
  ${typography('body-m')}
  margin-bottom: 24px;
`;

export const FieldLabel = styled.div`
  ${typography('body-s-semibold')}
  display: block;
  margin-bottom: 0.5em;
`;

export const NameInputWrapper = styled.div`
  margin-bottom: 32px;
  width: 70%;
`;

export const StyledNameInput = styled(Input)`
  ${typography('body-m')}
  label {
    ${typography('body-s-semibold')}
  }
`;

export const AmountInputWrapper = styled.div`
  margin-bottom: 32px;
  width: calc(50% - 8px);
`;

export const StyledAmountField = styled(AmountField)`
  ${typography('body-m')}
  label {
    ${typography('body-s-semibold')}
  }
`;

export const DateFieldWrapper = styled.div`
  margin-bottom: 34px;
`;

export const StyledDateField = styled(DateField)`
  ${typography('body-m')}
  label {
    ${typography('body-s-semibold')}
  }
`;

export const PrimaryButtonGroup = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackButton = styled(Button)`
  margin-right: 16px;
`;

export const SecondaryButtonGroup = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SecondaryTargetButton = styled(Button)`
  padding: 16px 12px;
  font-size: ${typeScale(-1)};
  font-weight: 600;
`;

import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { colorScale, typography } from 'themes';

import { Button, IButtonProps } from '../Button/Button';

const StyledButton = styled(Button)<{ selected: boolean }>`
  background: ${ifProp('selected', colorScale('secondary', 20), colorScale('secondary', 10))};
  color: ${ifProp('selected', colorScale('grey', 90), colorScale('grey', 80))};
  border: 1px solid ${colorScale('secondary', 20)};
  height: 2rem;
  min-height: 2rem;
  border-radius: 1rem;
  padding: 0 1.25rem;
  margin: 0 0.5rem 0.5rem 0;
  line-height: 1;
  ${typography('body-s')}

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
`;

interface IProps extends IButtonProps {
  isSelected: boolean;
}

export class Pill extends React.Component<IProps> {
  public render() {
    const { isSelected, children, ...otherProps } = this.props;

    return (
      <StyledButton selected={isSelected} type="button" {...otherProps}>
        {children}
      </StyledButton>
    );
  }
}

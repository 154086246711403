import { colorScale, theme } from 'themes';

interface IGetColoredArrayOptions {
  colorArray?: { main: string; text: string }[];
  groupAtIndex?: number;
}

export const getBudgetColoredArray = <T extends {}>(
  entries: T[],
  { colorArray = budgetColorArray, groupAtIndex }: IGetColoredArrayOptions = {}
): (T & { color: { main: string; text: string } })[] => {
  const chartDataWithColor = entries.map((entry, i) => ({
    ...entry,
    color: groupAtIndex
      ? i > groupAtIndex
        ? colorArray[groupAtIndex]
        : colorArray[i % colorArray.length]
      : colorArray[i % colorArray.length],
  }));

  return chartDataWithColor;
};

export const budgetColorArray = [
  // 0
  {
    main: colorScale('primary', 40)({ theme }),
    text: colorScale('primary', 50)({ theme }),
  },
  // 1
  {
    main: colorScale('supporting-yellow', 40)({ theme }),
    text: colorScale('supporting-yellow', 50)({ theme }),
  },
  // 2
  {
    main: colorScale('supporting-blue', 30)({ theme }),
    text: colorScale('supporting-blue', 40)({ theme }),
  },
  // 3
  {
    main: colorScale('secondary', 40)({ theme }),
    text: colorScale('secondary', 40)({ theme }),
  },
  // 4
  {
    main: colorScale('supporting-green', 40)({ theme }),
    text: colorScale('supporting-green', 50)({ theme }),
  },
  // 5
  {
    main: colorScale('supporting-pink', 40)({ theme }),
    text: colorScale('supporting-pink', 40)({ theme }),
  },
  // 6
  {
    main: colorScale('supporting-cobalt', 40)({ theme }),
    text: colorScale('supporting-cobalt', 40)({ theme }),
  },
  // 7
  {
    main: colorScale('supporting-teal', 30)({ theme }),
    text: colorScale('supporting-teal', 40)({ theme }),
  },
  // 8
  {
    main: colorScale('grey', 80)({ theme }),
    text: colorScale('grey', 80)({ theme }),
  },
];

import React from 'react';

import { IGoalForRender } from 'constants/storeTypes';
import { formatDate } from 'utilities/dateUtilities';

import { StyledPanel, BaseWrapper, DateWrapper, Year } from './sharedStyles';
import { TargetContent } from './TargetContent';

interface IProps {
  goal: IGoalForRender;
  projectedGoal?: IGoalForRender;
  openEditTargetModal: (editingTarget: IGoalForRender) => void;
  showProgress?: boolean;
}

export class GoalDetailsCard extends React.Component<IProps> {
  public render() {
    const { goal, projectedGoal, openEditTargetModal, showProgress = true } = this.props;

    const [month, year] = goal.date ? formatDate(goal.date).split(' ') : [null, null];

    return (
      <StyledPanel>
        <DateWrapper data-testid="date-wrapper">
          <div>{month && month.substring(0, 3)}</div>
          <Year>{year}</Year>
        </DateWrapper>
        <BaseWrapper data-testid="goal-card">
          <TargetContent
            editCallback={() => openEditTargetModal(goal)}
            target={goal}
            projectedTarget={projectedGoal}
            showProgress={showProgress}
          />
        </BaseWrapper>
      </StyledPanel>
    );
  }
}

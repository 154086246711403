import styled from 'styled-components';
import { prop } from 'styled-tools';

import { typography } from 'themes';

export const BulletText = styled.p`
  text-align: left;
  grid-area: bullet-text;
  margin: 0;
  white-space: nowrap;
  ${typography('body-s-bold')}
`;

export const BulletAmount = styled(BulletText)`
  grid-area: bullet-amount;
  ${typography('body-m-bold')}
`;

export const BulletWrapper = styled.div<{ alignment: 'start' | 'end' }>`
  flex: 1 1 0px;

  display: grid;
  grid-column-gap: 6px;
  grid-template-areas:
    'none bullet-text'
    '. bullet-amount';
  justify-content: ${prop('alignment')};
`;

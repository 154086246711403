import { Omit } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, switchProp, theme as fromTheme } from 'styled-tools';

import { minWidth, typeScale, colorScale, shadowScale } from 'themes';
import { visuallyHiddenProperties } from 'utilities/sharedStyles';

import { uniqueIdGenerator } from '../uniqueIdGenerator/uniqueIdGenerator';

const borderRadiusPX = fromTheme('borders.radius');
const HiddenCheckboxInput = styled.input`
  ${visuallyHiddenProperties}
`;

const DefaultCheckboxCheckmark = styled.span<{ checked: boolean; disabled?: boolean }>`
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  background: white;
  border: 1px solid ${colorScale('grey', 80)};
  border-radius: ${borderRadiusPX};
  cursor: pointer;
  color: ${colorScale('primary', 50)};
  margin-right: 0.7em;

  ${ifProp(
    'checked',
    css`
      ::before {
        width: 100%;
        height: 100%;
        content: '✓';
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `
  )}

  ${ifProp(
    'disabled',
    css`
      border-color: ${colorScale('grey', 50)};
      cursor: not-allowed;
    `
  )}
`;

export const StyledCheckboxCheckmark = styled.span<{ checked: boolean; disabled?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: ${borderRadiusPX};
  background: ${colorScale('grey', 15)};
  border: 2px solid ${colorScale('primary', 30)};
  margin-right: 16px;

  ${ifProp(
    'checked',
    css`
      background: ${colorScale('primary', 30)};
      ::before {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        content: '✓';
        font-size: ${typeScale(-2)};
        font-weight: 600;
        color: white;
      }
    `
  )}

   ${ifProp(
     'disabled',
     css`
       border-color: ${colorScale('grey', 50)};
       cursor: not-allowed;
     `
   )}

  ${minWidth(
    'tablet',
    css`
      margin-right: 24px;
    `
  )}
`;

const styledProperties = css`
  background: white;
  border-radius: ${borderRadiusPX};
  padding: 24px;
  box-shadow: ${shadowScale(1)};
  font-size: ${typeScale(0)};

  ${ifProp(
    'checked',
    css`
      background: ${colorScale('primary', 10)};
    `
  )};

  ${minWidth(
    'tablet',
    css`
      font-size: ${typeScale(1)};
      padding: 22px 16px;
    `
  )}
`;

interface ICheckboxLabelProps {
  disabled?: boolean;
  checked: boolean;
  variant: 'default' | 'styled' | 'custom';
}
const CheckboxLabel = styled.label<ICheckboxLabelProps>`
  ${ifNotProp(
    { variant: 'custom' },
    css`
      color: ${colorScale('grey', 90)};
      cursor: pointer;
      font-weight: 600;
      font-size: ${typeScale(0)};
      display: flex;
      justify-content: center;
      align-items: center;

      ${ifProp({ variant: 'styled' }, styledProperties)};

      ${ifProp(
        'disabled',
        css`
          cursor: not-allowed;
          color: ${colorScale('grey', 20)};
          border-color: ${colorScale('grey', 20)};
        `,
        css`
          ${switchProp('variant', {
            styled: css`
              &:hover,
              &:focus,
              &:focus-within {
                box-shadow: ${shadowScale(2)};
                z-index: 1;
              }
            `,
            default: css`
              &:hover,
              &:focus,
              &:focus-within {
                span {
                  box-shadow: ${shadowScale(2)};
                  z-index: 1;
                }
              }
            `,
          })};
        `
      )}
    `
  )}
`;

interface IOwnProps {
  /** To ensure that value is always provided as a prop */
  value: string;

  /** Defines the look of the checkbox label */
  variant: 'default' | 'styled' | 'custom';

  /** Text or custom component in label */
  label: string | React.ReactElement;
}

export type ICheckboxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id'> & IOwnProps;

export class Checkbox extends React.Component<ICheckboxProps> {
  public static defaultProps = {
    variant: 'default',
  };

  private static readonly idGenerator = uniqueIdGenerator('Checkbox');

  private readonly id: string;

  constructor(props: ICheckboxProps) {
    super(props);

    this.id = Checkbox.idGenerator.next().value as string;
  }

  public render() {
    const { variant, label, name, className, value, checked, disabled, ...otherProps } = this.props;
    const isChecked = Boolean(checked);

    return (
      <CheckboxLabel
        htmlFor={this.id}
        variant={variant}
        disabled={disabled}
        className={className}
        checked={isChecked}
      >
        <HiddenCheckboxInput
          type="checkbox"
          id={this.id}
          value={value}
          disabled={disabled}
          name={name}
          checked={checked}
          {...otherProps}
        />
        {variant === 'default' && (
          <DefaultCheckboxCheckmark checked={isChecked} disabled={disabled} />
        )}
        {variant === 'styled' && (
          <StyledCheckboxCheckmark checked={isChecked} disabled={disabled} />
        )}
        {variant !== 'custom' && label}
        {variant === 'custom' &&
          typeof label !== 'string' &&
          React.cloneElement(label, { disabled, checked })}
      </CheckboxLabel>
    );
  }
}

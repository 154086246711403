import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from 'redux/rootReducer';

// TODO: [FP-0000] remove redux, redux-devtools-extension, redux-thunk from package json once everything have been migrated
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: {
        // TODO: [FP-2250] To remove this check after we fix dates to string in redux
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isImmutable: (value: any) =>
          typeof value !== 'object' ||
          value === null ||
          typeof value === 'undefined' ||
          !(value instanceof Date),
      },
    }),
  devTools: process.env.NODE_ENV === 'development',
});

import { PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react';
import styled, { css } from 'styled-components';

import { Download } from 'assets/icons';
import { Button } from 'components/Common';
import { colorScale, typeScale, maxWidth } from 'themes';

import { IExpenseBudgetPDFDocumentProps } from './ExpenseBudgetPDFDocument';

const StyledDownloadIcon = styled(Download)`
  color: ${colorScale('primary', 40)};
  margin-right: 8px;

  ${maxWidth(
    'tablet',
    css`
      margin-right: 4px;
      transform: translateY(-2px);
    `
  )}
`;

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  border: 0;
  min-height: unset;
  text-decoration: none;
  font-size: ${typeScale(1)};

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    text-decoration: underline;
  }

  ${maxWidth(
    'tablet',
    css`
      font-size: ${typeScale(-1)};
    `
  )}
`;

const StyledPDFDownloadLink = styled(PDFDownloadLink)`
  text-decoration: none;
  font-size: ${typeScale(1)};
  font-weight: bold;
  color: ${colorScale('primary', 40)};

  ${maxWidth(
    'tablet',
    css`
      font-size: ${typeScale(-1)};
    `
  )}
`;

const PDFDownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  title: string;
  pdfDocument: React.ReactElement<IExpenseBudgetPDFDocumentProps>;
  onClickFn?: () => void;
}

export const ExpenseBudgetPDFDownloadButton = ({ title, pdfDocument, onClickFn }: IProps) => {
  return (
    <StyledPDFDownloadLink document={pdfDocument} fileName={`MyMoneySense ${title}.pdf`}>
      {({ loading }) =>
        loading ? (
          `Loading ${title} (PDF)`
        ) : (
          <PDFDownloadWrapper>
            <StyledDownloadIcon />
            <StyledButton variant="secondary" onClick={onClickFn}>
              {`Download ${title.toLocaleLowerCase()} (PDF)`}
            </StyledButton>
          </PDFDownloadWrapper>
        )
      }
    </StyledPDFDownloadLink>
  );
};

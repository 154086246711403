import React from 'react';

import { CurrencyPrefix } from 'components/Common';
import {
  ArticleHyperlink,
  ArticleParagraph,
  ArticleUnorderedList,
  ArticleListItem,
} from 'components/Templates';

export const ContributeToSRS = () => {
  return (
    <>
      <ArticleParagraph>
        The{' '}
        <ArticleHyperlink url="https://www.iras.gov.sg/taxes/individual-income-tax/basics-of-individual-income-tax/special-tax-schemes/srs-contributions">
          Supplementary Retirement Scheme (SRS)
        </ArticleHyperlink>{' '}
        is a voluntary scheme to encourage saving for retirement, in addition to your CPF savings.
        Contributions to SRS are eligible for tax relief. You can make a maximum contribution of{' '}
        <CurrencyPrefix>15,300</CurrencyPrefix> every year but note that there is a personal income
        tax relief cap of <CurrencyPrefix>80,000</CurrencyPrefix>, which is applied to the total
        amount of all tax reliefs claimed. If your SRS funds are not invested, they will earn an
        interest rate of 0.05% per annum. SRS withdrawals are taxed differently based on when the
        withdrawals are performed. The taxable amount is:
      </ArticleParagraph>

      <ArticleUnorderedList>
        <ArticleListItem>
          100% of the withdrawal value if withdrawn before the prescribed retirement age; or
        </ArticleListItem>
        <ArticleListItem>
          50% of the withdrawal value if withdrawn on or after the prescribed retirement age
        </ArticleListItem>
      </ArticleUnorderedList>
    </>
  );
};

import { Reducer } from 'redux';

import { IUserHistoryActions } from 'constants/actionTypes';
import { USER_HISTORY_ACTIONS } from 'constants/enums';
import { IUserHistoryStore } from 'constants/responseTypes';

export const initialState: IUserHistoryStore = {
  history: {},
};

export const userHistoryReducer: Reducer<IUserHistoryStore, IUserHistoryActions> = (
  state: IUserHistoryStore = initialState,
  action: IUserHistoryActions
) => {
  switch (action.type) {
    case USER_HISTORY_ACTIONS.SET_HISTORY:
      return { ...state, history: action.history };

    default:
      return state;
  }
};

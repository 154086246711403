import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, IButtonProps } from './Button';

interface IOwnProps {
  to: string;
  onClick?: () => void;
}

type IProps = IButtonProps & RouteComponentProps & IOwnProps;

export class RoutedButtonClass extends React.PureComponent<IProps> {
  public constructor(props: IProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick() {
    const { history, to, onClick } = this.props;
    history.push(to);
    if (onClick) {
      onClick();
    }
  }

  public render() {
    const { to, history, location, match, onClick, ...otherProps } = this.props;

    return <Button onClick={this.handleClick} {...otherProps} />;
  }
}

export const RoutedButton = withRouter(RoutedButtonClass);

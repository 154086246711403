import { omit } from 'lodash';

import { ascending } from 'utilities/sortingUtilities';

interface IForm {
  setFormState: (valid: boolean) => void;
}

interface IErrorToggler {
  displayError: () => void;
}

interface IButton {
  setValid: (valid: boolean) => void;
}

interface IValidator {
  getCurrentY: () => number;
  scrollIntoView: () => void;
}

export class ValidationMessenger {
  public displayErrors: { [name: string]: () => void } = {};

  private valid = true;
  private form: IForm = { setFormState: () => null };
  private errorTogglers: { [keyname: string]: IErrorToggler } = {};
  private buttons: { [keyname: string]: IButton } = {};
  private validators: { [keyname: string]: IValidator } = {};
  private validatorValidity: { [keyname: string]: boolean } = {};

  constructor() {
    this.registerErrorToggler = this.registerErrorToggler.bind(this);
    this.unregisterErrorToggler = this.unregisterErrorToggler.bind(this);
    this.registerButton = this.registerButton.bind(this);
    this.unregisterButton = this.unregisterButton.bind(this);
    this.registerValidator = this.registerValidator.bind(this);
    this.unregisterValidator = this.unregisterValidator.bind(this);
    this.registerForm = this.registerForm.bind(this);
    this.unregisterForm = this.unregisterForm.bind(this);
    this.updateValidatorValidity = this.updateValidatorValidity.bind(this);
    this.checkIsFormValid = this.checkIsFormValid.bind(this);
    this.displayAllErrors = this.displayAllErrors.bind(this);
    this.reset = this.reset.bind(this);
  }

  public reset() {
    this.form = { setFormState: () => null };
    this.errorTogglers = {};
    this.buttons = {};
    this.validators = {};
    this.validatorValidity = {};

    return this;
  }

  public registerErrorToggler(name: string, inputField: IErrorToggler) {
    this.errorTogglers[name] = inputField;
    this.checkIsFormValid();
  }

  public unregisterErrorToggler(name: string) {
    this.errorTogglers = omit(this.errorTogglers, name);
    this.checkIsFormValid();
  }

  public registerButton(name: string, button: IButton) {
    this.buttons[name] = button;
    button.setValid(this.valid);
  }

  public unregisterButton(name: string) {
    this.buttons = omit(this.buttons, name);
  }

  public registerValidator(name: string, validator: IValidator, validatorValidity: boolean) {
    this.validators[name] = validator;
    this.updateValidatorValidity(name, validatorValidity);
  }

  public unregisterValidator(name: string) {
    this.validators = omit(this.validators, name);
    this.validatorValidity = omit(this.validatorValidity, name);
    this.checkIsFormValid();
  }

  public registerForm(form: IForm) {
    this.form = form;
    return this.valid;
  }

  public unregisterForm() {
    this.form = { setFormState: () => null };
  }

  public updateValidatorValidity(name: string, valid: boolean) {
    this.validatorValidity[name] = valid;
    this.checkIsFormValid();
  }

  public checkIsFormValid() {
    const isNowValid = !Object.values(this.validatorValidity).includes(false);
    if (isNowValid !== this.valid) {
      this.valid = isNowValid;

      this.form.setFormState(isNowValid);

      Object.values(this.buttons).forEach(({ setValid }) => setValid(isNowValid));
    }
  }

  public displayAllErrors() {
    const erroredFields = Object.entries(this.validatorValidity)
      .filter(([_keyname, validated]) => !validated)
      .map(([keyname]) => keyname);
    const sortedYValues = Object.entries(this.validators)
      .filter(([keyname]) => erroredFields.includes(keyname))
      .sort((a, b) => ascending(a[1], b[1]));
    const highestErroredField = sortedYValues.length > 0 && sortedYValues[0][0];

    Object.values(this.errorTogglers).forEach(({ displayError }) => displayError());

    highestErroredField && this.validators[highestErroredField].scrollIntoView();
  }
}

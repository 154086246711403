interface IValidationParams {
  monthlyInstalment: number;
  cashAmount: number;
  cpfAmount: number;
}

export function mortgageLoanRepayment({
  monthlyInstalment,
  cashAmount,
  cpfAmount,
}: IValidationParams) {
  const errMsg = 'Sum of cash and CPF cannot exceed total monthly instalment';

  return cashAmount + cpfAmount <= monthlyInstalment ? undefined : errMsg;
}

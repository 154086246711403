import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ifProp } from 'styled-tools';

import { ArrowDown } from 'assets/icons';
import { Section } from 'components/Common';
import { typeScale } from 'themes';

const ToggleButton = styled.button`
  background-color: inherit;
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-family: inherit;
  font-weight: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
`;

const ArrowWrapper = styled.div<{ isExpanded: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  transition: 0.2s;
  width: 18px;
  font-size: ${typeScale(0)};

  ${ifProp(
    'isExpanded',
    css`
      transform: rotate(180deg);
    `
  )}
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  };
  to {
    opacity: 1;
    transform: translateY(0);
    };
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  };
  to {
    opacity: 1;
    transform: translateY(0);
    };
`;

const ContentWrapper = styled.div<{ slideDirection: string }>`
  animation: ${(props) => (props.slideDirection === 'up' ? slideUp : slideDown)} 0.4s
    cubic-bezier(0.165, 0.84, 0.44, 1);
  line-height: 1.333;
`;

const ClipWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

interface IProps {
  title: React.ReactNode;
  reverseTitle?: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  collapseDirection?: 'up' | 'down';
  slideDirection?: 'up' | 'down';
  onExpand?: (isExpanded: boolean) => void;
  isDefaultExpanded?: boolean;
}

export const Collapsible = ({
  title,
  reverseTitle,
  content,
  className,
  collapseDirection = 'down',
  slideDirection,
  onExpand,
  isDefaultExpanded = false,
  ...otherProps
}: IProps) => {
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
  return (
    <Section className={className} {...otherProps}>
      <Reverse shouldReverse={collapseDirection === 'up'}>
        <ToggleButton
          data-testid="toggle-button"
          onClick={(e) => {
            e.preventDefault();
            setIsExpanded((prevIsExpanded) => !prevIsExpanded);
            onExpand && onExpand(!isExpanded);
          }}
        >
          {isExpanded && reverseTitle ? reverseTitle : title}
          <ArrowWrapper isExpanded={isExpanded}>
            <ArrowDown aria-label="Arrow down" />
          </ArrowWrapper>
        </ToggleButton>
        <ClipWrapper className="clip-wrapper">
          {isExpanded && (
            <ContentWrapper
              slideDirection={slideDirection === undefined ? collapseDirection : slideDirection}
            >
              {content}
            </ContentWrapper>
          )}
        </ClipWrapper>
      </Reverse>
    </Section>
  );
};

const Reverse: React.FC<{ shouldReverse: boolean }> = ({ children, shouldReverse }) => {
  const childrenArray = React.Children.toArray(children);
  return <>{shouldReverse ? childrenArray.reverse() : childrenArray}</>;
};

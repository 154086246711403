import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { pick } from 'lodash';
import uuidv4 from 'uuid/v4';

import { featureDecisions } from 'featureDecisions';
import { parseObjectWithDates } from 'utilities/dateUtilities';
import { mockMaintenanceSchedules } from 'utilities/mockData/mockMaintenanceSchedules';

import { mockDataEmpty } from './mockData/mockData';

/**
The application frontend only expects responses with data for the following requests:
- Authorization control (logging in via Singpass)
- IDs of resources created by POST
*/

const MOCK_DATA_KEY = 'MOCK_DATA';

export const setMockData = (newData: any) => {
  window.sessionStorage.setItem(MOCK_DATA_KEY, JSON.stringify(newData));
};

export const getMockData = () => {
  const data = window.sessionStorage.getItem(MOCK_DATA_KEY);

  return data ? parseObjectWithDates(data) : data;
};

export const configureMockServerResponses = () => {
  if (featureDecisions.mockServerResponses) {
    const mockAxios = new MockAdapter(axios);

    if (getMockData() === null) {
      setMockData(mockDataEmpty);
    }

    mockAxios
      .onGet(/.+(meta.json)$/gi)
      //eslint-disable-next-line @typescript-eslint/no-var-requires
      .reply(() => [200, { version: require('../../package.json').version }]);

    mockAxios.onPost(`${process.env.REACT_APP_API_URL}/v1/auth/refresh`).reply(200, {});

    // Singpass responses
    mockAxios.onPost(/.+\/auth\/token$/).reply(() => [200, getMockData().app]);

    // Myinfo responses
    mockAxios.onPost(`${process.env.REACT_APP_API_URL}/v1/myinfo/token`).reply(200);
    mockAxios.onGet(`${process.env.REACT_APP_API_URL}/v1/myinfo/person`).reply(() => [
      200,
      {
        data: pick(getMockData().myinfo.data, ['cpf', 'noa', 'hdbOwnership', 'id']),
      },
    ]);
    mockAxios.onGet(`${process.env.REACT_APP_API_URL}/v1/myinfo/person-basic`).reply(() => [
      200,
      {
        data: pick(getMockData().myinfo.data, [
          'name',
          'sex',
          'dob',
          'maritalStatus',
          'residentialStatus',
          'email',
          'id',
        ]),
      },
    ]);

    // User responses
    mockAxios
      .onGet(`${process.env.REACT_APP_API_URL}/v1/user`)
      .reply(() => [200, getMockData().app]);
    mockAxios
      .onGet(`${process.env.REACT_APP_API_URL}/v1/user/nric`)
      .reply(() => [200, 'S9400234Q']);

    // APIM responses
    mockAxios.onPost(`${process.env.REACT_APP_API_URL}/v1/apim/token`).reply(200);
    mockAxios
      .onPost(`${process.env.REACT_APP_API_URL}/v1/apim/user-data`)
      .reply(() => [200, getMockData().apim]);

    // FX rates responses
    mockAxios
      .onGet(`${process.env.REACT_APP_API_URL}/v1/fx-rates/latest`)
      .reply(() => [200, getMockData().fxRates]);

    mockAxios.onPost().reply(() => [201, { id: uuidv4() }]);

    // Maintenance notices responses
    mockAxios
      .onGet(`${process.env.REACT_APP_API_URL}/v1/maintenance-schedule`)
      .reply(() => [200, mockMaintenanceSchedules]);

    // Other responses
    mockAxios.onAny().reply(200);
  }
};

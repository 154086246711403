import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, ActionCreatorsMapObject } from 'redux';

import { getTotalCashOutflow } from 'redux/finances/selectors';
import { addMessage, addDefaultErrorMessage } from 'redux/messages/actions';
import { createEmergencyFundRequest, updateEmergencyFundRequest } from 'redux/targets/actions';
import { getEmergencyFund, getRecommendedEmergencyFundMonths } from 'redux/targets/selectors';

import { captureSetEmergencyFund } from 'analytics';
import { WarningModalContent } from 'components/Common';
import { ThunkActionCreator } from 'constants/actionTypes';
import { IStore, IEmergencyFundForRender } from 'constants/storeTypes';
import { addMonthsToDate } from 'utilities/dateUtilities';

import { SetEmergencyFundContent } from './SetEmergencyFundContent';

interface IStateProps {
  emergencyFund: IEmergencyFundForRender;
  totalMonthlyCashOutflow: number;
  recommendedEmergencyFundMonths: number;
}

const mapStateToProps = (state: IStore) => ({
  emergencyFund: getEmergencyFund(state),
  totalMonthlyCashOutflow: Math.max(1, getTotalCashOutflow(state, { excludeInvestments: true })),
  recommendedEmergencyFundMonths: getRecommendedEmergencyFundMonths(state),
});

interface IDispatchProps {
  updateEmergencyFundRequest: ThunkActionCreator<typeof updateEmergencyFundRequest>;
  createEmergencyFundRequest: ThunkActionCreator<typeof createEmergencyFundRequest>;
  addMessage: typeof addMessage;
  addDefaultErrorMessage: typeof addDefaultErrorMessage;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators<{}, ActionCreatorsMapObject & IDispatchProps>(
    {
      updateEmergencyFundRequest,
      createEmergencyFundRequest,
      addMessage,
      addDefaultErrorMessage,
    },
    dispatch
  );

interface IOwnProps {
  onExit: (event?: React.SyntheticEvent) => void;
  showTarget: () => void;
  setHasDataChanged: (setValue: boolean) => void;
  shouldShowWarningContent: boolean;
  addToast: (message: string) => void;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
  initialAmount: number;
  amount: number;
  initialNumMonths: number;
  numMonths: number;
  date: Date;
}

export class SetEmergencyFundModalToggler extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { totalMonthlyCashOutflow, emergencyFund, recommendedEmergencyFundMonths } = this.props;

    let { totalAmount: initialAmount, date } = emergencyFund;

    if (initialAmount === 0) {
      initialAmount = totalMonthlyCashOutflow * recommendedEmergencyFundMonths;
      date = addMonthsToDate(1);
    }

    const initialNumMonths = Math.floor(initialAmount / totalMonthlyCashOutflow);

    this.state = {
      initialAmount,
      amount: initialAmount,
      initialNumMonths,
      numMonths: initialNumMonths,
      date,
    };

    this.updateAmount = this.updateAmount.bind(this);
    this.updateNumMonths = this.updateNumMonths.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.saveToStore = this.saveToStore.bind(this);
    this.updateHasDataChanged = this.updateHasDataChanged.bind(this);
  }

  public updateAmount(amount: number) {
    const numMonths = Math.floor(amount / this.props.totalMonthlyCashOutflow);

    this.setState({ amount });
    this.setState({ numMonths });
  }

  public updateNumMonths(value: string) {
    const numMonths = Number(value);
    const amount = numMonths * this.props.totalMonthlyCashOutflow;

    this.setState({ numMonths });
    this.setState({ amount });
  }

  public updateDate(date: Date) {
    this.setState({ date });
  }

  public saveToStore() {
    const { amount, date } = this.state;
    const {
      updateEmergencyFundRequest,
      createEmergencyFundRequest,
      onExit,
      emergencyFund,
      addMessage,
      addDefaultErrorMessage,
      recommendedEmergencyFundMonths,
      totalMonthlyCashOutflow,
    } = this.props;

    this.setState({ initialAmount: amount });
    const recommendedEmergencyFundAmount = recommendedEmergencyFundMonths * totalMonthlyCashOutflow;
    const hasEmergencyFund = emergencyFund.totalAmount !== 0 && !emergencyFund.initByStore;

    let promise: Promise<any>;
    if (hasEmergencyFund) {
      promise = updateEmergencyFundRequest({ totalAmount: amount, date });
    } else {
      promise = createEmergencyFundRequest(amount);
    }
    promise
      .then(() => {
        captureSetEmergencyFund(amount, recommendedEmergencyFundAmount);
        addMessage(`'Emergency fund' ${emergencyFund.totalAmount === 0 ? 'added' : 'updated'}`, {
          variant: 'success',
        });
      })
      .catch(addDefaultErrorMessage);

    onExit();
  }

  public updateHasDataChanged() {
    const { numMonths, initialNumMonths, amount, initialAmount } = this.state;

    this.props.setHasDataChanged(amount !== initialAmount || numMonths !== initialNumMonths);
  }

  componentDidMount() {
    this.updateHasDataChanged();
  }

  componentDidUpdate() {
    this.updateHasDataChanged();
  }

  componentWillUnmount() {
    this.props.showTarget();
  }

  public render() {
    const {
      onExit,
      showTarget,
      shouldShowWarningContent,
      emergencyFund,
      totalMonthlyCashOutflow,
      recommendedEmergencyFundMonths,
    } = this.props;
    const { amount, numMonths, date } = this.state;

    return shouldShowWarningContent ? (
      <WarningModalContent
        header={emergencyFund.totalAmount === 0 ? 'Discard emergency fund?' : 'Discard changes?'}
        handleConfirmation={onExit}
        handleRejection={showTarget}
        confirmationButtonText="Discard"
        rejectionButtonText="Cancel"
      />
    ) : (
      <SetEmergencyFundContent
        totalMonthlyCashOutflow={totalMonthlyCashOutflow}
        amount={amount}
        numMonths={numMonths}
        date={date}
        saveToStore={this.saveToStore}
        updateAmount={this.updateAmount}
        updateNumMonths={this.updateNumMonths}
        updateDate={this.updateDate}
        hasEmergencyFund={emergencyFund.totalAmount !== 0 && !emergencyFund.initByStore}
        recommendedEmergencyFundMonths={recommendedEmergencyFundMonths}
      />
    );
  }
}

export const SetEmergencyFundModalTogglerContainer = connect<
  IStateProps,
  IDispatchProps,
  IOwnProps,
  IStore
>(
  mapStateToProps,
  mapDispatchToProps
)(SetEmergencyFundModalToggler);

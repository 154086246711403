export enum INVESTING_FOR_BEGINNERS_ITEM_TYPES {
  // "Are you ready to start investing?"
  READY_TO_START_INVESTING = 'READY_TO_START_INVESTING',
  // "Setting up CDP and/or brokerage accounts"
  SET_UP_CDP_BROKERAGE = 'SET_UP_CDP_BROKERAGE',
  // "Seeking advice from financial advisers"
  ADVICE_FROM_FINANCIAL_ADVISERS = 'ADVICE_FROM_FINANCIAL_ADVISERS',
}

export enum INVESTING_FOR_BEGINNERS_INSURANCE_TYPES {
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
}

import axios from 'axios';
import React, { useEffect } from 'react';
import { compare as semverCompare } from 'semver';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const pkg = require('../../../../package.json');

interface IMetaJson {
  version: string;
}

export const CacheBuster: React.FC = ({ children }) => {
  const clearCache = async () => {
    if ('caches' in window) {
      const cacheKeys = await window.caches.keys();
      await Promise.all(cacheKeys.map((cacheKey) => window.caches.delete(cacheKey)));
    }
  };

  const unregisterServiceWorkers = async () => {
    if ('navigator' in window) {
      const registeredSvcWrkers = await navigator.serviceWorker.getRegistrations();
      await Promise.all(
        registeredSvcWrkers.map((registeredSvcWrker) => registeredSvcWrker.unregister())
      );
    }
  };

  const getMetaAndCompare = async () => {
    try {
      const meta = await axios.get<IMetaJson>('/meta.json');

      if (!meta || !meta.data || !meta.data.version) {
        throw new Error('Unable to get meta version');
      }
      const latestVersion = meta.data.version;
      const currentVersion = pkg.version;

      const semverCompareRes = semverCompare(latestVersion, currentVersion);
      if (semverCompareRes === 0) {
        // eslint-disable-next-line no-console
        console.info(`Version (${latestVersion}) is latest`);
      } else {
        await unregisterServiceWorkers();
        await clearCache();

        if (semverCompareRes === -1) {
          // eslint-disable-next-line no-console
          console.info(`Latest version (${latestVersion}) is older (current: ${currentVersion}).`);
        } else {
          // eslint-disable-next-line no-console
          console.info(`Busting cache (Old: ${currentVersion}, New: ${latestVersion})`);
        }

        window.location.reload();
      }
    } catch (e) {
      if (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } else {
        // eslint-disable-next-line no-console
        console.error('Unable to get meta.json');
      }
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.addEventListener('load', () => {
        getMetaAndCompare();
      });

      return () => {
        window.removeEventListener('load', () => {});
      };
    }
    // eslint-disable-next-line no-console
    return console.info('Skipping cache buster');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

import React from 'react';
import styled from 'styled-components';

import { MONTH_NAMES_TRUNC, getDateDiffInMonths } from 'utilities/dateUtilities';

import { Dropdown } from '../Dropdown/Dropdown';
import { ISelectedOption, IDropdownOption } from '../Dropdown/Interfaces';
import { IDropdownProps } from '../Dropdown/Interfaces';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & > * {
    margin-right: 16px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

const NUMBER_OF_YEARS_TO_SHOW = 40;

const YEARS = Array.from(Array(NUMBER_OF_YEARS_TO_SHOW).keys());
const FIRST_YEAR = 2019;

interface IOwnProps {
  date: Date;
  label: string | React.ReactElement;
  onInputChange: (date: Date) => void;
  earliestAllowedDate: Date;
  className?: string;
}

type IProps = IOwnProps & Partial<IDropdownProps>;

export class DateField extends React.Component<IProps> {
  static defaultProps = {
    earliestAllowedDate: new Date(),
  };

  public constructor(props: IProps) {
    super(props);

    this.updateMonth = this.updateMonth.bind(this);
    this.updateYear = this.updateYear.bind(this);
  }

  public updateMonth(data: ISelectedOption) {
    const newMonth = (data as IDropdownOption).value as string;
    const date = new Date(this.props.date.getFullYear(), MONTH_NAMES_TRUNC.indexOf(newMonth));
    this.props.onInputChange(date);
  }

  public updateYear(data: ISelectedOption) {
    const newYear = Number((data as IDropdownOption).value);
    const date = new Date(newYear, this.props.date.getMonth());
    this.props.onInputChange(date);
  }

  public render() {
    const {
      date,
      label,
      onInputChange,
      earliestAllowedDate,
      className,
      ...otherProps
    } = this.props;

    const monthOptions: IDropdownOption[] = MONTH_NAMES_TRUNC.map((monthName, i) => {
      const fieldDate = new Date(
        this.props.date.getFullYear(),
        MONTH_NAMES_TRUNC.indexOf(monthName)
      );

      return {
        key: i,
        value: monthName,
        label: monthName,
        isDisabled: getDateDiffInMonths(earliestAllowedDate, fieldDate) > 0,
      };
    });

    const yearOptions = YEARS.map((i: number) => {
      const year = FIRST_YEAR + i;
      const fieldDate = new Date(year, this.props.date.getMonth());

      return {
        key: i,
        value: year.toString(),
        label: year.toString(),
        isDisabled: earliestAllowedDate
          ? getDateDiffInMonths(earliestAllowedDate, fieldDate) > 0
          : getDateDiffInMonths(new Date(), fieldDate) > 0,
      };
    });

    return (
      <Wrapper className={className}>
        <Dropdown
          {...otherProps}
          label={label}
          value={monthOptions.find((option) => option.value === MONTH_NAMES_TRUNC[date.getMonth()])}
          options={monthOptions}
          onChange={this.updateMonth}
        />
        <Dropdown
          {...otherProps}
          label={`${label} (year)`}
          hideLabel={true}
          value={yearOptions.find((option) => option.value === date.getFullYear().toString())}
          options={yearOptions}
          onChange={this.updateYear}
        />
      </Wrapper>
    );
  }
}

import { Omit } from 'lodash';

import { MAIN_MODULE, MODULE_LITE } from 'constants/enums';
import { IModuleDetails, ISubRoute, IStore } from 'constants/storeTypes';

type IPartialModuleDetails<T extends IModuleDetails> = Omit<T, 'subRoutes'> & {
  subRoutes: Omit<ISubRoute, 'isAllowed'>[];
  module: MAIN_MODULE | MODULE_LITE;
};

export const checkIfRoutesAreAllowed = <T extends IModuleDetails>(
  moduleDetails: IPartialModuleDetails<T>,
  isModuleStepAllowed: (stepIndex: number) => boolean
) => {
  const stepRoutes = moduleDetails.subRoutes.filter((s) => s.isStepPage);

  const completedArray = moduleDetails.subRoutes
    .map((subRoute) => {
      const currentStepIndex = stepRoutes.findIndex(
        (stepRoute) => stepRoute.subPath === subRoute.subPath
      );
      return (
        !subRoute.isUpcoming && (!subRoute.isStepPage || isModuleStepAllowed(currentStepIndex + 1))
      );
    })
    .reduce((a, c, idx) => {
      return [...a, c && (a.length === 0 || a[idx - 1])];
    }, [] as boolean[]);

  const subRoutes = moduleDetails.subRoutes.map((subRoute, routeIndex) => {
    const currentStepIndex = stepRoutes.findIndex(
      (stepRoute) => stepRoute.subPath === subRoute.subPath
    );

    const isAllowed =
      (routeIndex === 0 || completedArray[routeIndex - 1]) &&
      (!subRoute.isStepPage || isModuleStepAllowed(currentStepIndex));

    return {
      ...subRoute,
      isAllowed,
    };
  });

  return { ...moduleDetails, subRoutes };
};

export const getModuleDetailsFromMap = <T extends IModuleDetails>(
  store: IStore,
  moduleMap: { [key: string]: (store: IStore) => T }
): { [key: string]: T } =>
  Object.entries(moduleMap).reduce((details, [key, value]) => {
    if (value) {
      details[key] = value(store);
    }
    return details;
  }, {});

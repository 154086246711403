import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Container, Section } from 'components/Common';
import { ValidationForm } from 'components/Validations';
import { maxWidth, minWidth } from 'themes';

import { IModuleContentProps } from './interfaces';
import { ModuleContentPageHeader } from './ModuleContentPageHeader';

const StyledContainer = styled(Container)`
  height: 100%;

  ${minWidth(
    'desktop',
    css`
      width: 100%;
    `
  )}
`;

const StyledForm = styled(ValidationForm)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledSection = styled(Section)<{ title?: string; sideBar: boolean }>`
  display: grid;

  ${maxWidth(
    'tablet',
    css`
      grid-template-areas: ${ifProp('title', "'heading'", '')} 'main' ${ifProp(
          'sideBar',
          "'sidebar'",
          ''
        )};
      padding: 0 calc((100vw - 512px) / 2);
      padding-bottom: 24px;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      padding: 0 calc((100vw - 768px) / 2) 64px;
      grid-template-columns: ${ifProp('sideBar', '1fr 2fr', '1fr')};
      grid-template-areas: ${ifProp('title', ifProp('sideBar', "'. heading'", "'heading'"), '')} ${ifProp(
          'sideBar',
          "'sidebar main'",
          "'main'"
        )};
      grid-column-gap: 16px;
    `
  )}

  ${minWidth(
    'largeScreen',
    css`
      padding: 0 calc((100vw - 1024px) / 2);
      padding-bottom: 64px;
    `
  )};
`;

const MainContent = styled.div`
  grid-area: main;
`;

export const ModuleContent: React.FC<IModuleContentProps> = ({
  title,
  titleInfoCB,
  subtitle,
  children,
  sideBar,
  ctaPanel,
  onSubmitValidation,
}) => (
  <StyledContainer>
    <StyledForm data-testid="module-content-form" onSubmit={onSubmitValidation}>
      <StyledSection title={title} sideBar={sideBar !== null}>
        <ModuleContentPageHeader title={title} titleInfoCB={titleInfoCB} subtitle={subtitle} />
        <MainContent data-testid="module-content-main">{children}</MainContent>
        {sideBar}
      </StyledSection>
      {ctaPanel}
    </StyledForm>
  </StyledContainer>
);

import React from 'react';

import {
  ValidationErrorToggler,
  IValidationErrorTogglerProps,
} from './ValidationErrorToggler/ValidationErrorToggler';
import { Validator, IValidatorProps } from './Validator/Validator';

type Unwrap<T> = T extends Array<infer U> ? U : T;

export type ValidationContainerRenderParams<T extends any = string> = Unwrap<
  Parameters<IValidatorProps<T>['render']>
> &
  Unwrap<Parameters<IValidationErrorTogglerProps['render']>>;

export type ValidationContainerProps<T> = Omit<IValidatorProps<T>, 'render'> &
  Omit<IValidationErrorTogglerProps, 'render'> & {
    render: (renderParams: ValidationContainerRenderParams<T>) => JSX.Element;
  };

export const ValidationContainer = <T extends any = string>({
  value,
  validations,
  render,
  ...baseValidationContainerProps
}: ValidationContainerProps<T>) => (
  <Validator
    value={value}
    validations={validations}
    render={({ errorMessage, placerRef }) => (
      <ValidationErrorToggler
        errorMessage={errorMessage}
        {...baseValidationContainerProps}
        render={(props) => render({ placerRef, ...props })}
      />
    )}
  />
);

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { IEmergencyFundRatioInputs } from '../interfaces';

export const EF3: IRule<IEmergencyFundRatioInputs> = {
  condition: ({ emergencyFundMonths, isWorking }: IEmergencyFundRatioInputs) =>
    emergencyFundMonths < 3 && isWorking,
  result: {
    key: RULE.EF3,
    indicatorStatus: indicatorStatuses.NEEDS_ATTENTION,
    recommendedActions: [recommendedActions.TRA_SR_D],
    resources: [
      // Some links
    ],
  },
};

import { MAIN_MODULE } from 'constants/enums';
import { IStore, IMainModuleDetails } from 'constants/storeTypes';
import { ONBOARDING } from 'constants/urls';
import { checkIfRoutesAreAllowed } from 'utilities/moduleUtilities';

import { getIsModuleStepAllowed } from '../selectors';

const moduleName = MAIN_MODULE.WEALTH;
const { WEALTH } = ONBOARDING;
export const moduleDetails = {
  module: moduleName,
  name: 'Wealth',
  estimatedTime: '5 - 10 mins',
  question: '',
  summary: 'What you own and what you owe',
  route: WEALTH.baseRoute,
  prerequisiteText: 'Check the estimated market value of your property, car and investments.',
  subRoutes: [
    {
      subPath: 'setup',
      isStepPage: false,
    },
    {
      name: 'Assets (what you own)',
      subPath: 'assets',
      isStepPage: true,
      nextPaths: [WEALTH.liabilities],
    },
    {
      name: 'Liabilities (what you owe)',
      subPath: 'liabilities',
      isStepPage: true,
      nextPaths: [WEALTH.completed],
    },
    {
      subPath: 'completed',
      isStepPage: false,
    },
  ],
  confirmationButtonText: 'Dashboard',
};

export const getWealthModuleDetails = (store: IStore) => {
  return checkIfRoutesAreAllowed<IMainModuleDetails>(
    moduleDetails,
    getIsModuleStepAllowed(store, moduleName)
  );
};

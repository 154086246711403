const tooltipOpacity = '0.9';
const escapeKey = 'Escape';

interface ITooltipOptions {
  enabled: boolean;
  custom: (tooltipModel: {
    opacity: number;
    yAlign: string;
    body: { lines: any }[];
    title: string[];
    labelColors: { [x: string]: any };
    caretX: any;
    caretY: any;
  }) => void;
  callbacks: { label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) => string };
}

interface ITooltipModel {
  opacity: number;
  yAlign: string;
  body: { lines: any }[];
  title: string[];
  labelColors: { [x: string]: any };
  caretX: any;
  caretY: any;
}

export const customTooltipStyle = `
  .custom-tooltip {
    background-color: black;
    color: white;
    position: absolute;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 12px;
    padding: 3px 6px 7px;
    font-style: normal;
    border-radius: 5px;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
  }
  .custom-tooltip:before {
      border: solid;
      border-color: #111 transparent;
      border-color: rgba(0, 0, 0, .9) transparent;
      border-width: 8px 8px 0 8px;
      bottom: 1em;
      content:"";
      display: block;
      left: 50%;
      top: 100%;
      position: absolute;
      z-index: 99;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
  }
`;

export const CustomTooltip = function (this: ITooltipOptions, tooltipModel: ITooltipModel) {
  // Tooltip Element
  let tooltipEl = document.getElementById('custom-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'custom-tooltip';

    tooltipEl.innerHTML = '<table></table>';

    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem: { lines: any }) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [''];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHTML = '<thead>';

    titleLines.forEach(function (title: string) {
      innerHTML += '<tr><th>' + title + '</th></tr>';
    });

    innerHTML += '</thead><tbody>';

    bodyLines.forEach(function (body: string, i: number) {
      const colors = tooltipModel.labelColors[i];
      const style = `background : ${colors.backgroundColor};
                       border: 1px solid ${colors.borderColor};
                       width: 12px;
                       height: 12px;
                       display: inline-block;
                       vertical-align: middle;
                       margin-right: 5px;
                       `;

      const span = '<span style="' + style + '"></span>';
      innerHTML += '<tr><td>' + span + body + '</td></tr>';
    });

    innerHTML += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');

    if (tableRoot) tableRoot.innerHTML = innerHTML;
  }

  const position = this['_chart'].canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.classList.add('custom-tooltip');
  tooltipEl.style.opacity = tooltipOpacity;
  tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px';

  tooltipEl.addEventListener('mouseout', () => {
    if (tooltipEl) tooltipEl.style.opacity = '0';
  });

  tooltipEl.addEventListener('mouseover', () => {
    if (tooltipEl) tooltipEl.style.opacity = tooltipOpacity;
  });

  window.addEventListener('keyup', (evt) => {
    if (tooltipEl && evt.key === escapeKey) tooltipEl.style.opacity = '0';
  });
};

import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { maxWidth, minWidth, PAGE_SIDE_SPACING } from 'themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100vw;
  transition: ${fromTheme('transitions.duration')};

  ${maxWidth(
    'tablet',
    css`
      padding: 0 ${PAGE_SIDE_SPACING.DEFAULT_PX};
    `
  )}

  ${minWidth(
    'desktop',
    css`
      width: 768px;
    `
  )}

  ${minWidth(
    'largeScreen',
    css`
      width: 1024px;
    `
  )}
`;

//in preparation of layout design system
export const ContainerMobileOnly = styled.div`
  ${maxWidth(
    'tablet',
    css`
      display: flex;
      flex-direction: column;
      justify-self: center;
      width: 100vw;
      transition: ${fromTheme('transitions.duration')};
      padding: 0 ${PAGE_SIDE_SPACING.DEFAULT_PX};
    `
  )}
`;

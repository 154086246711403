import React from 'react';
import styled from 'styled-components';

import { CarouselLeft, CarouselRight } from 'assets/icons';

import { IconButton } from '../IconButton/IconButton';

const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
`;

const LeftButtonWrapper = styled(ButtonWrapper)`
  left: 0;
  transform: translate3d(0, -50%, 0);
`;

const RightButtonWrapper = styled(ButtonWrapper)`
  right: 0;
  transform: translate3d(0, -50%, 0);
`;

interface IOwnProps {
  onClick: () => void;
}

type IProps = IOwnProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const LeftButton = (props: IProps) => (
  <LeftButtonWrapper>
    <IconButton {...props} ariaLabel="left button">
      <CarouselLeft />
    </IconButton>
  </LeftButtonWrapper>
);

export const RightButton = (props: IProps) => (
  <RightButtonWrapper>
    <IconButton {...props} ariaLabel="right button">
      <CarouselRight />
    </IconButton>
  </RightButtonWrapper>
);

import React from 'react';

import { WarningBanner } from 'components/Common';
import { formatCurrency } from 'utilities/currencyUtilities';

interface IProps {
  thresholdAmount: number;
}

export const AmountLimitBanner: React.FC<IProps> = ({ thresholdAmount }: IProps) => (
  <WarningBanner
    text={
      <React.Fragment>
        For targets above {formatCurrency(thresholdAmount)} consider breaking it down into smaller
        milestones.
      </React.Fragment>
    }
  />
);

import React from 'react';

import PaperPlane from './ArticleTemplate/paper-plane.svg';
import Butterfly from './Backgrounds/butterfly.svg';
import FlowersLeft from './Backgrounds/flowers-left.svg';
import FlowersRight from './Backgrounds/flowers-right.svg';
import { ReactComponent as ShallowWaveSvg } from './Backgrounds/shallow-wave.svg';
import WaveBackground from './Backgrounds/wave-background.svg';
import { ReactComponent as HorizontalWaveSvg } from './Backgrounds/wave-horizontal.svg';
import { ReactComponent as VerticalWaveSvg } from './Backgrounds/wave-vertical.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const PaperPlaneImage = (props: ImageProps) => <img {...props} src={PaperPlane} alt="" />;

export const FlowersLeftImage = (props: ImageProps) => <img {...props} src={FlowersLeft} alt="" />;

export const FlowersRightImage = (props: ImageProps) => (
  <img {...props} src={FlowersRight} alt="" />
);

export const ButterflyImage = (props: ImageProps) => <img {...props} src={Butterfly} alt="" />;

export const WaveBackgroundImage = (props: ImageProps) => (
  <img {...props} src={WaveBackground} alt="" />
);

export { ShallowWaveSvg, HorizontalWaveSvg, VerticalWaveSvg };

import React, { ReactNode, ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import { getUserId } from 'redux/profile/selectors';

import {
  StyledDyingFlowerImage,
  Title,
  Wrapper,
} from 'components/Apim-Myinfo/ErrorPage/sharedStyles';
import { Button, Footer, LogoBar } from 'components/Common';
import { IStore } from 'constants/storeTypes';
import { colorScale, typography } from 'themes';
import { theme } from 'themes';
import { getStandardisedFormatDateTime } from 'utilities/dateUtilities';

const mapStateToProps = (state: IStore) => ({
  userId: getUserId(state),
});

interface IOwnProps {
  children: ReactNode;
}

type IProps = ReturnType<typeof mapStateToProps> & RouteComponentProps & IOwnProps;

interface IState {
  hasError: boolean;
  error?: Error;
  errorComponentStack?: string;
}

const RootWrapper = styled.div`
  background: ${colorScale('grey', 10)};
  display: grid;
  min-height: calc(100vh - ${theme.heights.governmentBanner});
  min-height: calc(100dvh - ${theme.heights.governmentBanner});
  grid-template-rows: min-content auto auto;
  grid-template-areas:
    'navbar'
    'main-content'
    'footer';
`;

const StyledLogoBar = styled(LogoBar)`
  position: inherit;
  grid-area: navbar;
`;

const ContentWrapper = styled.div`
  grid-area: main-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
`;

const Description = styled.p`
  ${typography('body-l')};
  margin: 0;
`;

const ErrorText = styled.p`
  ${typography('body-m')};
  color: ${colorScale('grey', 80)};
  margin: 0;
  white-space: pre-wrap;
`;

const CTAButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CTAButton = styled(Button)`
  margin-top: 16px;
  max-width: 324px;
`;

const parseErrorInfo = (errorInfo: ErrorInfo) =>
  'Component stack:\n' +
  errorInfo.componentStack
    .split('\n')
    .filter((s) => s && s.length)
    .slice(0, 5)
    .map((s) => {
      const lastSpace = s.lastIndexOf(' ');
      const lastBracket = s.lastIndexOf(')');
      return s.slice(lastSpace + 1, lastBracket).replace('at', '');
    })
    .join('\n');

class ErrorBoundaryComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorComponentStack: parseErrorInfo(errorInfo),
    });
  }

  render() {
    const { hasError, error, errorComponentStack } = this.state;
    const { history, location, userId } = this.props;

    if (hasError) {
      return (
        <RootWrapper>
          <StyledLogoBar />
          <ContentWrapper>
            <Wrapper>
              <StyledDyingFlowerImage />
              <Title>It's not you, it's us...</Title>
              <Description>
                Please try again later. If the error still occurs, contact us and we'll work to fix
                it as soon as possible.
              </Description>
              <ErrorText data-testid="dt">
                Occurred on {getStandardisedFormatDateTime(new Date())}
              </ErrorText>
              <ErrorText data-testid="error-msg">
                {error && error.message
                  ? error.message
                  : errorComponentStack && errorComponentStack.length
                  ? errorComponentStack
                  : 'Unexpected error occurred'}
              </ErrorText>
              <div>
                {location && (
                  <ErrorText data-testid="location">Location: {location.pathname}</ErrorText>
                )}
                {userId && <ErrorText data-testid="uid">User ID: {userId}</ErrorText>}
              </div>
              <CTAButtonWrapper>
                <CTAButton
                  widthStyle="fluid"
                  data-testid="contact-btn"
                  onClick={() =>
                    window.open(
                      process.env.REACT_APP_CONTACT_US_FORM_URL as string,
                      '_blank',
                      'noopener noreferrer'
                    )
                  }
                >
                  Contact us
                </CTAButton>
                <CTAButton
                  widthStyle="fluid"
                  variant="secondary"
                  onClick={() => {
                    this.setState({ hasError: false });
                    history.push('/');
                  }}
                >
                  Back to home
                </CTAButton>
              </CTAButtonWrapper>
            </Wrapper>
          </ContentWrapper>
          <Footer />
        </RootWrapper>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = connect(mapStateToProps)(withRouter(ErrorBoundaryComponent));

import React from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import { ArrowLeft } from 'assets/icons';
import { Button, IButtonProps } from 'components/Common';
import { BaseTemplateContainer } from 'components/Templates';
import { typography, maxWidth } from 'themes';

const BackButtonContainer = styled(BaseTemplateContainer)`
  position: relative;
  z-index: 3;

  ${maxWidth(
    'tablet',
    css`
      max-width: none;
    `
  )}
`;

const StyledButton = styled(Button)`
  position: absolute;
  ${typography('body-m-bold')}
`;

interface IOwnProps {
  backPath: string;
}

export type IBackButtonProps = IOwnProps &
  Omit<
    IButtonProps,
    'variant' | 'size' | 'widthStyle' | 'icon' | 'iconPosition' | 'edge' | 'onClick'
  >;

/**
 * Renders a back button on the top left corner of the content area.
 * Its position is absolute so no additional height is added to the content area.
 * Meant to be rendered above a template just like the component Breadcrumbs.
 *
 * @param backPath Path to route to on click
 */
export const BackButton = ({ backPath, children }: IBackButtonProps) => {
  const history = useHistory();

  return (
    <BackButtonContainer>
      <StyledButton
        aria-label="Back button"
        variant="tertiary"
        icon={<ArrowLeft aria-label="Arrow left" />}
        edge="left"
        onClick={() => history.push(backPath)}
      >
        {children}
      </StyledButton>
    </BackButtonContainer>
  );
};

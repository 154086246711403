import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { TransitionStatus } from 'react-transition-group/Transition';
import { bindActionCreators, Dispatch, ActionCreatorsMapObject } from 'redux';

import { getTargetAmountLimit } from 'redux/finances/selectors';
import { addMessage, addDefaultErrorMessage } from 'redux/messages/actions';
import { createGoalRequest } from 'redux/targets/actions';

import { captureAddGoal } from 'analytics';
import { ThunkActionCreator } from 'constants/actionTypes';
import { IStore } from 'constants/storeTypes';
import { addMonthsToDate } from 'utilities/dateUtilities';

import { WarningModalContent } from '../WarningModalContent/WarningModalContent';

import { AddTargetModalContent } from './AddTargetModalContent';

interface IStateProps {
  targetAmountLimit: number;
}

const mapStateToProps: (state: IStore) => IStateProps = (state) => ({
  targetAmountLimit: getTargetAmountLimit(state),
});

interface IDispatchProps {
  captureAddGoal: ThunkActionCreator<typeof captureAddGoal>;
  createGoalRequest: ThunkActionCreator<typeof createGoalRequest>;
  addMessage: typeof addMessage;
  addDefaultErrorMessage: typeof addDefaultErrorMessage;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators<{}, ActionCreatorsMapObject & IDispatchProps>(
    {
      captureAddGoal,
      createGoalRequest,
      addMessage,
      addDefaultErrorMessage,
    },
    dispatch
  );

interface IOwnProps {
  onExit: (event?: React.SyntheticEvent) => void;
  transitionStatus?: TransitionStatus;
  shouldShowWarningContent: boolean;
  showTarget: () => void;
  setHasDataChanged: (setValue: boolean) => void;
  addToast: (message: string) => void;
}

type IProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps;

interface IState {
  name: string;
  amount: number | null;
  date: Date;
}

export class AddTargetModalToggler extends React.Component<IProps, IState> {
  private readonly initialState: IState;

  constructor(props: IProps) {
    super(props);

    this.initialState = {
      name: '',
      amount: null,
      date: addMonthsToDate(12),
    };

    this.state = this.initialState;

    this.handlePillClick = this.handlePillClick.bind(this);
    this.updateName = this.updateName.bind(this);
    this.updateAmount = this.updateAmount.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.addToStore = this.addToStore.bind(this);
  }

  public handlePillClick(e: React.SyntheticEvent<HTMLButtonElement>) {
    this.updateName((e.target as HTMLButtonElement).value);
  }

  public updateName(name: string) {
    this.setState({ name });
  }

  public updateAmount(amount: number) {
    this.setState({ amount: amount });
  }

  public updateDate(date: Date) {
    this.setState({ date });
  }

  public addToStore() {
    const { name, amount, date } = this.state;
    if (amount !== null) {
      this.props
        .createGoalRequest({
          name,
          totalAmount: amount,
          currentAmount: 0,
          date,
          archivedAcknowledged: false,
          paidOut: false,
          paidOutAmount: null,
          paidOutDate: null,
        })
        .then(() => {
          this.props.captureAddGoal(this.props.location.pathname);
          this.props.addMessage(`'${name}' added`, { variant: 'success' });
        })
        .catch(this.props.addDefaultErrorMessage);
      this.props.onExit();
    }
  }

  componentDidUpdate() {
    const { setHasDataChanged } = this.props;
    const hasNoChanges = isEqual(this.state, this.initialState);

    if (hasNoChanges) {
      setHasDataChanged(false);
    } else {
      setHasDataChanged(true);
    }
  }

  componentWillUnmount() {
    const { showTarget, setHasDataChanged } = this.props;

    showTarget();
    setHasDataChanged(false);
  }

  public render() {
    const { name, amount, date } = this.state;
    const {
      shouldShowWarningContent,
      onExit,
      showTarget,
      transitionStatus,
      targetAmountLimit,
    } = this.props;

    return shouldShowWarningContent ? (
      <WarningModalContent
        header="Discard new target?"
        handleConfirmation={onExit}
        handleRejection={showTarget}
        confirmationButtonText="Discard"
        rejectionButtonText="Cancel"
      />
    ) : (
      <AddTargetModalContent
        name={name}
        amount={amount}
        date={date}
        addToStore={this.addToStore}
        handlePillClick={this.handlePillClick}
        updateName={this.updateName}
        updateAmount={this.updateAmount}
        updateDate={this.updateDate}
        transitionStatus={transitionStatus}
        thresholdAmount={targetAmountLimit}
      />
    );
  }
}

export const AddTargetModalTogglerContainer = connect<
  IStateProps,
  IDispatchProps,
  IOwnProps,
  IStore
>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddTargetModalToggler));

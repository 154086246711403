/* eslint-disable @typescript-eslint/no-explicit-any */
import { pick } from 'lodash';

import { PLAN_COLOR } from 'constants/enums';
import { IHousingPlan } from 'constants/storeTypes';

import { IHousingOutputs } from './interfaces';

export const getBuyerStampDuty = (value: number) => {
  if (value <= 18000000) {
    return 0.01 * value;
  }

  if (value <= 36000000) {
    return 180000 + 0.02 * (value - 18000000);
  }

  if (value <= 100000000) {
    return 540000 + 0.03 * (value - 36000000);
  }

  return 2460000 + 0.04 * (value - 100000000);
};

export const getConveyancingFee = (value: number) => {
  value = Math.ceil(value / 100000) * 100000; // round value up to closest thousand dollars

  if (value <= 3000000) {
    return (90 / 100000) * value;
  }

  if (value <= 6000000) {
    return 2700 + (72 / 100000) * (value - 3000000);
  }

  return 4860 + (60 / 100000) * (value - 6000000);
};

const customizedPlanColors = Object.keys(PLAN_COLOR).filter((key) => key !== 'PURPLE');

export const getRandomPlanColor = () =>
  customizedPlanColors[Math.floor(Math.random() * customizedPlanColors.length)] as PLAN_COLOR;

export const pickHousingPlan = (
  source: (IHousingPlan & IHousingOutputs) | IHousingPlan,
  reference: IHousingPlan
): IHousingPlan =>
  pick<(IHousingPlan & IHousingOutputs) | IHousingPlan, keyof IHousingPlan>(
    source,
    Object.keys(reference) as Array<keyof typeof reference>
  );

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp } from 'styled-tools';

import { colorScale, PAGE_SIDE_SPACING } from 'themes';

import { IPageSideSpacing } from '../interfaces';
import { TemplateWrapper, SuperstarTemplateContainer, FooterContent } from '../sharedStyles';

const StyledTemplateWrapper = styled(TemplateWrapper)<{ variant: ISuperstarTemplateVariant }>`
  ${switchProp('variant', {
    snow: css`
      background: ${colorScale('grey', 0)};
    `,
    purple: css`
      background: ${colorScale('primary', 10)};
    `,
  })}
`;

// https://webdelving.com/blog/styled-components-ampersand/
const Content = styled.div<{ isFlush?: boolean }>`
  ${ifProp(
    'isFlush',
    css`
      &&& {
        margin-bottom: 0;
      }
    `
  )}
`;

export type ISuperstarTemplateVariant = 'default' | 'snow' | 'purple';

export interface ISuperstarTemplateProps {
  variant?: ISuperstarTemplateVariant;
  background?: React.ReactElement;

  content: React.ReactChild;
  flushContentToEdges?: boolean;

  additionalFooterContent?: React.ReactChild;
  flushFooterToEdges?: boolean;

  pageSideSpacing?: IPageSideSpacing;
  pageHasBackButton?: boolean;
}

/**
 * Display content in a single column with an option to fill the entire screen.
 *
 * @param [variant] Determines the background color of the page
 * @param [background] A component from Templates/Backgrounds
 *
 * @param content
 * @param [flushContentToEdges] Determines if the content area should flush and
 *                              touch the left right & bottom edge of the screen.
 *                              Useful for when the page needs a full width background.
 *                              Wrap any content that doesn't need to be flushed with
 *                              SuperstarTemplateContainer when this flag is set to true.
 *
 * @param [additionalFooterContent] Useful for putting call to action buttons
 * @param [flushFooterToEdges] Determines if the footer area should flush and
 *                             touch the left right & bottom edge of the screen
 *
 * @param [pageSideSpacing] Determines the left right spacing of the content to the
 *                          edge of the screen
 * @param [pageHasBackButton] Set to true if Composite/BackButton is used with this template
 *                            to ensure the button doesn't overlap with the content
 */
export const SuperstarTemplate = ({
  variant = 'default',
  background,
  content,
  flushContentToEdges = false,
  additionalFooterContent,
  flushFooterToEdges = false,
  pageSideSpacing = PAGE_SIDE_SPACING.DEFAULT_PX,
  pageHasBackButton = false,
}: ISuperstarTemplateProps) => {
  return (
    <StyledTemplateWrapper variant={variant} pageHasBackButton={pageHasBackButton}>
      {background}

      {flushContentToEdges ? (
        <Content isFlush={flushContentToEdges && !additionalFooterContent}>{content}</Content>
      ) : (
        <SuperstarTemplateContainer pageSideSpacing={pageSideSpacing}>
          <Content>{content}</Content>
        </SuperstarTemplateContainer>
      )}

      {additionalFooterContent &&
        (flushFooterToEdges ? (
          <FooterContent isFlush={flushFooterToEdges}>{additionalFooterContent}</FooterContent>
        ) : (
          <SuperstarTemplateContainer pageSideSpacing={pageSideSpacing}>
            <FooterContent>{additionalFooterContent}</FooterContent>
          </SuperstarTemplateContainer>
        ))}
    </StyledTemplateWrapper>
  );
};

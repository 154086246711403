import { css } from 'styled-components';
import { ifProp, switchProp, theme as fromTheme } from 'styled-tools';

import { typeScale } from 'themes';

const SMALL_PADDING_PX = '16px';
const MEDIUM_PADDING_PX = '24px';
const LARGE_PADDING_PX = '32px';

/**
 * Includes properties that don't change based on
 * variant, width style and size
 */
export const baseProperties = css`
  appearance: none;
  transition: ${fromTheme('transitions.duration')};
  font-family: inherit;
  text-decoration: none; /* Ensure buttons as links have no underline */
  display: inline-flex;
  position: relative;
  border: 1px solid transparent;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
    `,
    css`
      cursor: pointer;

      &:hover,
      &:focus {
        outline: 0;
        z-index: 1;
      }
    `
  )}
`;

export const sizeProperties = css`
  ${switchProp('size', {
    small: css`
      font-size: ${typeScale(-1)};
      padding: 0 ${SMALL_PADDING_PX};
      min-width: 32px;
      min-height: 32px;
    `,
    medium: css`
      font-size: ${typeScale(-1)};
      padding: 0 ${MEDIUM_PADDING_PX};
      min-width: 40px;
      min-height: 40px;
    `,
    large: css`
      font-size: ${typeScale(-1)};
      padding: 0 ${LARGE_PADDING_PX};
      min-width: 48px;
      min-height: 48px;
    `,
  })};
`;

const applyEdgeProperties = (padding: string) =>
  css`
    ${switchProp('edge', {
      left: css`
        margin-left: -${padding};
      `,
      right: css`
        margin-right: -${padding};
      `,
    })}
  `;

export const edgeProperties = css`
  ${switchProp('size', {
    small: css`
      ${ifProp('edge', applyEdgeProperties(SMALL_PADDING_PX))}
    `,
    medium: css`
      ${ifProp('edge', applyEdgeProperties(MEDIUM_PADDING_PX))}
    `,
    large: css`
      ${ifProp('edge', applyEdgeProperties(LARGE_PADDING_PX))}
    `,
  })};
`;

export const widthStyleProperties = css`
  ${ifProp(
    { widthStyle: 'fluid' },
    css`
      width: 100%;
    `
  )};
`;

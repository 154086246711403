import { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { colorScale } from 'themes';

import { IInputProps } from '../Input';

export const inputWrapperProperties = css`
  grid-area: input;
  border-bottom: 1px solid ${colorScale('grey', 80)};
  color: ${colorScale('grey', 90)};

  &:focus-within {
    border-bottom: 2px solid ${colorScale('primary', 40)};
  }

  ${ifProp(
    (props: IInputProps) => props.addonBefore || props.addonAfter,
    css`
      padding-top: 3px;
      padding-bottom: 3px;
    `
  )};

  ${ifProp(
    'disabled',
    css`
      border-bottom: none;
    `
  )};

  ${ifProp(
    'readOnly',
    css`
      border-bottom: none;
    `
  )};

  ${ifProp(
    { hasError: true },
    css`
      border-bottom: 2px solid ${colorScale('supporting-red', 50)};
    `
  )};
`;

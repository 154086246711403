import _ from 'lodash';
import moment from 'moment';

import { INTERVAL_BEFORE_RETURN_FLOW } from 'constants/timeConstants';

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MONTH_NAMES_TRUNC = MONTH_NAMES.map((month) => month.substring(0, 3));

export const MIN_DATE = new Date(-8640000000000000);

interface IFormatDateOptions {
  withDay?: boolean;
  truncateMonthName?: boolean;
  shouldConvertToOrdinalNumber?: boolean;
  forceLastDay?: boolean;
}

export const formatDate = (
  date: Date,
  {
    withDay = false,
    truncateMonthName = true,
    shouldConvertToOrdinalNumber = true,
    forceLastDay = false,
  }: IFormatDateOptions = {}
) => {
  if (forceLastDay && withDay) {
    date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  const monthString = truncateMonthName
    ? MONTH_NAMES_TRUNC[date.getMonth()]
    : MONTH_NAMES[date.getMonth()];
  const dateString = `${monthString} ${date.getFullYear()}`;
  if (withDay) {
    return `${(shouldConvertToOrdinalNumber ? convertToOrdinalNumber : _.identity)(
      date.getDate()
    )} ${dateString}`;
  }
  return dateString;
};

export type IAddDateIntervalsFn = (numIntervals: number, fromDate?: Date) => Date;

export const addYearsToDate: IAddDateIntervalsFn = (
  numYears: number,
  date: Date = new Date()
): Date => moment(date).add(numYears, 'years').toDate();

export const addMonthsToDate: IAddDateIntervalsFn = (
  numMonths: number,
  date: Date = new Date()
): Date => moment(date).add(numMonths, 'months').toDate();

export const addDaysToDate: IAddDateIntervalsFn = (
  numDays: number,
  date: Date = new Date()
): Date => moment(date).add(numDays, 'days').toDate();

export type IDateDiffFn = (endDate: Date, startDate?: Date) => number;

export const getDateDiffInYears: IDateDiffFn = (a: Date, b: Date = new Date()): number => {
  return a.getFullYear() - b.getFullYear();
};

export const getDateDiffInMonths: IDateDiffFn = (a: Date, b: Date = new Date()): number => {
  const aCopy: Date = new Date(a.getFullYear(), a.getMonth());
  const bCopy: Date = new Date(b.getFullYear(), b.getMonth());

  return (aCopy.getFullYear() - bCopy.getFullYear()) * 12 + aCopy.getMonth() - bCopy.getMonth();
};

export const getDateDiffInDays: IDateDiffFn = (a: Date, b: Date = new Date()): number => {
  const aCopy: Date = new Date(a.getFullYear(), a.getMonth(), a.getDate());
  const bCopy: Date = new Date(b.getFullYear(), b.getMonth(), b.getDate());

  const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

  return (aCopy.getTime() - bCopy.getTime()) / ONE_DAY_IN_MILLIS;
};

// Helper function to work around javascript not deserialising dates properly
export const parseObjectWithDates = (data: string) => {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

  return JSON.parse(data, (_, value: string) => {
    if (typeof value === 'string') {
      if (reISO.exec(value)) {
        return new Date(value);
      }
    }
    return value;
  });
};

export const convertToLocalDateString = (date: Date) => {
  return new Date(date.valueOf() - date.getTimezoneOffset() * 60000).toISOString().substring(0, 10);
};

// https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
export const convertToOrdinalNumber = (num: number) => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const v = num % 100;

  return num < 0 ? null : num + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
};

export const convertMonthsToYearsAndMonths = (noOfMonths: number) => {
  const returnArray: string[] = [];
  const noOfYears = Math.floor(noOfMonths / 12);
  const remainingMonths = noOfMonths % 12;

  if (noOfYears > 0) {
    returnArray.push(`${noOfYears} year${noOfYears === 1 ? '' : 's'}`);
  }
  if (remainingMonths > 0) {
    returnArray.push(`${remainingMonths} month${remainingMonths === 1 ? '' : 's'}`);
  }

  return returnArray.join(' ');
};

export const getMaxDate = (dates: Date[]) =>
  new Date(
    Math.max.apply(
      null,
      dates.map((d) => d.getTime())
    )
  );

export const getReturnFlowDateFrom = (date: Date) => {
  return moment(date).add(INTERVAL_BEFORE_RETURN_FLOW, 'days').toDate();
};

export const getStandardisedFormatDateTime = (date: Date | moment.Moment | null) =>
  date === null ? '-' : moment(date).format('DD MMM YYYY, hh:mm a');

import styled, { css } from 'styled-components';

import { Radio, RadioGroup, Button, Section, Heading } from 'components/Common';
import { typography, colorScale, maxWidth, minWidth } from 'themes';

export const QuantityValueRadioGroup = styled(RadioGroup)`
  grid-auto-flow: row;
`;

export const QuantityValueRadio = styled(Radio)`
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  padding: 8px;
`;

export const ModalWrapper = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 256px;
`;

export const ModalTitle = styled(Heading)`
  ${typography('heading-m')};
  color: ${colorScale('grey', 90)};
  margin-bottom: 0.33em;
`;

export const ModalText = styled.p`
  ${typography('body-m')};
  color: ${colorScale('grey', 90)};
`;

export const ModalStyledRadioGroup = styled(RadioGroup)`
  grid-auto-flow: row;
  grid-auto-columns: 100%;
  grid-gap: 0;
  width: 100%;
  margin-bottom: 8px;
`;

export const ModalStyledRadio = styled(Radio)`
  box-shadow: none;
  border-radius: 0;

  ${maxWidth(
    'mobile',
    css`
      margin-left: -16px;
      margin-right: -16px;
      width: calc(100% + 32px);
    `
  )};

  ${minWidth(
    'tablet',
    css`
      margin-left: -24px;
      margin-right: -24px;
      width: calc(100% + 48px);
    `
  )};

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    outline: none;
    box-shadow: none;
  }
`;

export const ModalButtonModalWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ModalCancelButton = styled(Button)`
  margin-right: 8px;
`;

import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/Common';
import { colorScale, typeScale } from 'themes';

const colors = {
  background: colorScale('secondary', 10),
  font: colorScale('secondary', 40),
};

export const ButtonWrapper = styled.div`
  text-align: center;
  align-self: center;
  color: ${colors.font};
  flex-basis: 44%;
`;

const StyledButton = styled(Button)`
  padding: 12px;
  color: ${colors.font};
  height: 72px;
`;

const Instruction = styled.p`
  font-size: ${typeScale(-2)};
  margin: 0;
`;

const RecommendedValue = styled.p`
  font-weight: bold;
  font-size: ${typeScale(0)};
  margin: 0;
`;

const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

interface IProps {
  onClick: () => void;
  topLine: string;
  bottomLine: string;
}

export class RecommendedActionsButton extends React.Component<IProps> {
  public render() {
    const { onClick, topLine, bottomLine } = this.props;

    return (
      <ButtonWrapper>
        <StyledButton variant="panel" widthStyle="fluid" onClick={onClick}>
          <ButtonText>
            <Instruction>{topLine}</Instruction>
            <RecommendedValue>{bottomLine}</RecommendedValue>
          </ButtonText>
        </StyledButton>
      </ButtonWrapper>
    );
  }
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import uuid from 'uuid';

import {
  INVESTING_FOR_BEGINNERS_ITEM_TYPES,
  INVESTING_FOR_BEGINNERS_INSURANCE_TYPES,
} from 'constants/enums';
import {
  IInvestingForBeginners,
  IInvestingForBeginnersItemResponse,
  IInvestingForBeginnersInsurance,
  IInvestingForBeginnerInsuranceResponse,
} from 'constants/storeTypes';
import { featureDecisions } from 'featureDecisions';

export const initialState: IInvestingForBeginners = {
  itemsCompleted: null,
  insurance: null,
};

const name = 'investingForBeginners';

const investingForBeginnersSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvestingForBeginnersItemsRequest.fulfilled, (state, { payload }) => {
      state.itemsCompleted = payload;
    });
    builder.addCase(setInvestingForBeginnersItemRequest.fulfilled, (state, { payload }) => {
      const itemCompleted = state.itemsCompleted || [];
      state.itemsCompleted = [...itemCompleted, payload.item];
    });
    builder.addCase(getInvestingForBeginnersInsurancesRequest.fulfilled, (state, { payload }) => {
      state.insurance = payload;
    });
    builder.addCase(
      setInvestingForBeginnersInsuranceRequest.fulfilled,
      (state, { payload: { id, insurance } }) => {
        const existingInsurance = state.insurance || [];
        state.insurance = [...existingInsurance, { id, insurance }];
      }
    );
    builder.addCase(deleteInvestingForBeginnersInsuranceRequest.fulfilled, (state, { payload }) => {
      if (state.insurance) {
        state.insurance = state.insurance.filter((insurance) => insurance.id !== payload);
      }
    });
  },
});

export const investingForBeginnersReducer = investingForBeginnersSlice.reducer;

export const getInvestingForBeginnersItemsRequest = createAsyncThunk<
  INVESTING_FOR_BEGINNERS_ITEM_TYPES[]
>(`${name}/getItem`, async () => {
  if (featureDecisions.mockServerResponses) {
    return [];
  }

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/investing-for-beginners/guide`
  );
  return data.data;
});

export const setInvestingForBeginnersItemRequest = createAsyncThunk<
  IInvestingForBeginnersItemResponse,
  INVESTING_FOR_BEGINNERS_ITEM_TYPES
>(`${name}/setItem`, async (item) => {
  if (featureDecisions.mockServerResponses) {
    const result: IInvestingForBeginnersItemResponse = {
      id: uuid.v4(),
      user: uuid.v4(),
      item: item,
      createdBy: uuid.v4(),
      updatedBy: uuid.v4(),
      createdAt: '2020-07',
      updatedAt: '2020-07',
    };
    return result;
  }

  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/v1/investing-for-beginners/guide`,
    {
      item,
    }
  );
  return data.data;
});

export const getInvestingForBeginnersInsurancesRequest = createAsyncThunk<
  IInvestingForBeginnersInsurance[]
>(`${name}/getInsurance`, async () => {
  if (featureDecisions.mockServerResponses) {
    return [];
  }

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/investing-for-beginners/insurance`
  );
  return data.data;
});

export const setInvestingForBeginnersInsuranceRequest = createAsyncThunk<
  IInvestingForBeginnerInsuranceResponse,
  INVESTING_FOR_BEGINNERS_INSURANCE_TYPES
>(`${name}/setInsurance`, async (insurance) => {
  if (featureDecisions.mockServerResponses) {
    const result: IInvestingForBeginnerInsuranceResponse = {
      id: uuid.v4(),
      user: uuid.v4(),
      insurance: insurance,
      createdBy: uuid.v4(),
      updatedBy: uuid.v4(),
      createdAt: '2020-07',
      updatedAt: '2020-07',
    };
    return result;
  }

  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/v1/investing-for-beginners/insurance`,
    {
      insurance,
    }
  );
  return data.data;
});

export const deleteInvestingForBeginnersInsuranceRequest = createAsyncThunk<string, string>(
  `${name}/deleteInsurance`,
  async (id) => {
    if (featureDecisions.mockServerResponses) {
      return id;
    }

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/v1/investing-for-beginners/insurance/${id}`
    );
    return id;
  }
);

import React from 'react';

import { CurrencyPrefix } from 'components/Common';
import {
  NutritionalValueText,
  INutritionalBreakdown,
} from 'components/Composite/SampleProfilePanel/NutritionalBreakdown';
import { ArticleHyperlink } from 'components/Templates';

import { StyledNutritionalValueText } from './sharedStyles';

export const incomeReplacementRatioProfileRows: INutritionalBreakdown[] = [
  {
    nutrient: 'Current monthly income*',
    nutritionalValue: (
      <>
        <NutritionalValueText>
          <CurrencyPrefix>4,500</CurrencyPrefix>
        </NutritionalValueText>
        <StyledNutritionalValueText>
          (based on the{' '}
          <ArticleHyperlink url="https://stats.mom.gov.sg/Pages/Labour-Force-In-Singapore-2021.aspx">
            2021's median gross monthly income
          </ArticleHyperlink>{' '}
          for ages between 30 and 34 years old, excluding employer's CPF contributions)
        </StyledNutritionalValueText>
      </>
    ),
  },
  {
    nutrient: 'Expected retirement age',
    nutritionalValue: <NutritionalValueText>65 years old</NutritionalValueText>,
  },
  {
    nutrient: 'Expected years in retirement',
    nutritionalValue: (
      <>
        <NutritionalValueText>19</NutritionalValueText>
        <StyledNutritionalValueText>
          (based on the{' '}
          <ArticleHyperlink url="https://www.singstat.gov.sg/find-data/search-by-theme/population/death-and-life-expectancy/visualising-data/lifeexpectancy">
            male life expectancy at 65
          </ArticleHyperlink>{' '}
          i.e. 84 years - expected retirement age of 65)
        </StyledNutritionalValueText>
      </>
    ),
  },
];

export const adjustExpenseProfileRows: INutritionalBreakdown[] = [
  {
    nutrient: 'Monthly pre-retirement expenses',
    nutritionalValue: (
      <>
        <NutritionalValueText>
          <CurrencyPrefix>1,800</CurrencyPrefix>
        </NutritionalValueText>
        <StyledNutritionalValueText>
          (based on the{' '}
          <ArticleHyperlink url="https://www.singstat.gov.sg/publications/households/household-expenditure-survey">
            average monthly retiree household expenses per member in 2017/2018
          </ArticleHyperlink>
          )
        </StyledNutritionalValueText>
      </>
    ),
  },
  {
    nutrient: 'Inflation rate',
    nutritionalValue: (
      <>
        <NutritionalValueText>1.3%</NutritionalValueText>
        <StyledNutritionalValueText>
          (based on the{' '}
          <ArticleHyperlink url="https://www.mas.gov.sg/news?content_type=Consumer%20Price%20Developments">
            average MAS Core Inflation changes in % from 2011 to 2021
          </ArticleHyperlink>
          )
        </StyledNutritionalValueText>
      </>
    ),
  },
  {
    nutrient: 'Expected retirement age',
    nutritionalValue: <NutritionalValueText>65 years old</NutritionalValueText>,
  },
  {
    nutrient: 'Expected years in retirement',
    nutritionalValue: (
      <>
        <NutritionalValueText>19</NutritionalValueText>
        <StyledNutritionalValueText>
          (based on the{' '}
          <ArticleHyperlink url="https://www.singstat.gov.sg/find-data/search-by-theme/population/death-and-life-expectancy/visualising-data/lifeexpectancy">
            male life expectancy at 65
          </ArticleHyperlink>{' '}
          i.e. 84 years - expected retirement age of 65)
        </StyledNutritionalValueText>
      </>
    ),
  },
];

import React from 'react';

import { PanelWithDeleteIcon } from 'components/Common';

import {
  NameInvestmentSourceSection,
  INameInvestmentSourceProps,
} from '../Common/NameInvestmentSourceSection';
import { ITotalValueSectionProps } from '../Common/TotalValueSection';
import { Wrapper } from '../Common/Wrapper';

import { GoldQuantityValueSection, IQuantityValueSectionProps } from './GoldQuantityValueSection';
import { IGoldUnitPriceSectionProps } from './GoldUnitPriceSection';

interface IOwnProps {
  onDelete: () => void;
  name: string;
  handleNameChange: (name: string) => void;
  autoFocusOnStart?: boolean;
}

type IProps = INameInvestmentSourceProps &
  IQuantityValueSectionProps &
  IGoldUnitPriceSectionProps &
  ITotalValueSectionProps &
  IOwnProps &
  React.HTMLAttributes<HTMLDivElement>;

export const GoldInvestmentInputPanel: React.FC<IProps> = ({
  id,
  enterOption,
  handleEnterOptionChange,
  onDelete,
  investmentSource,
  name,
  handleNameChange,
  totalValue,
  totalValueCurrency,
  handleTotalValueChange,
  handleTotalValueCurrencyChange,
  totalQuantity,
  handleTotalQuantityChange,
  investmentType,
  onClickInvestmentSource,
  autoFocusOnStart,
}) => (
  <>
    <PanelWithDeleteIcon id={id} data-testid="gold-investment-input-panel" onDelete={onDelete}>
      <Wrapper investmentSource={investmentSource}>
        <NameInvestmentSourceSection
          investmentSource={investmentSource}
          name={name}
          handleNameChange={handleNameChange}
          investmentType={investmentType}
          onClickInvestmentSource={onClickInvestmentSource}
        />
        <GoldQuantityValueSection
          enterOption={enterOption}
          handleEnterOptionChange={handleEnterOptionChange}
          totalValueFieldLabel="Value"
          totalValue={totalValue}
          totalValueCurrency={totalValueCurrency}
          handleTotalValueChange={handleTotalValueChange}
          handleTotalValueCurrencyChange={handleTotalValueCurrencyChange}
          totalQuantityFieldLabel="Weight"
          unitPriceLabel="Estimated market price per oz"
          unitOfMeasurement="oz"
          totalQuantity={totalQuantity}
          handleTotalQuantityChange={handleTotalQuantityChange}
          allowedDecimalPlaces={3}
          autoFocus={autoFocusOnStart}
        />
      </Wrapper>
    </PanelWithDeleteIcon>
  </>
);

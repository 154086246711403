import mixpanel from 'mixpanel-browser';
import React from 'react';
import { RouteComponentProps } from 'react-router';

const PAGE_CHANGE_EVENT_NAME = 'Page Change';
const PAGE_VIEW_EVENT_NAME = 'Page View';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withPageTracker = (WrappedComponent: any) => {
  class TrackerComponent extends React.Component<RouteComponentProps> {
    public componentDidMount() {
      mixpanel.track('App Entered');
      mixpanel.track(PAGE_VIEW_EVENT_NAME);
      mixpanel.time_event(PAGE_CHANGE_EVENT_NAME);
    }

    public componentDidUpdate(prevProps: RouteComponentProps) {
      const prevPage = prevProps.location.pathname;
      const currentPage = this.props.location.pathname;

      if (prevPage !== currentPage) {
        mixpanel.track(PAGE_CHANGE_EVENT_NAME, { 'From URL': prevPage });
        mixpanel.time_event(PAGE_CHANGE_EVENT_NAME);
        mixpanel.track(PAGE_VIEW_EVENT_NAME);
      }
    }

    public render() {
      return <WrappedComponent />;
    }
  }

  return TrackerComponent;
};

import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { getHasToken } from 'redux/dataStorage/selectors';

interface ISearchComponentProps {
  searchText: string;
  onSearchInputChange: (searchQuery: string) => void;
  handleSearch: (e: React.FormEvent) => void;
  onClearInput: () => void;
}

interface IOwnProps {
  previousSearchText?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  renderSearchComponent: ({
    searchText,
    onSearchInputChange,
    handleSearch,
    onClearInput,
  }: ISearchComponentProps) => React.ReactElement;
}

type IProps = IOwnProps & RouteComponentProps;

const SearchWrapper = ({
  previousSearchText,
  renderSearchComponent,
  history,
  inputRef,
}: IProps) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (previousSearchText) {
      setSearchText(previousSearchText);
    }
  }, [previousSearchText]);

  useEffect(() => {
    if (inputRef?.current && !previousSearchText) {
      inputRef.current.focus();
    }
  });

  const hasToken = useSelector(getHasToken);

  const onSearchInputChange = (searchQuery: string) => {
    setSearchText(searchQuery);
  };

  const createSearchQueryRequest = (searchQuery: string) => {
    if (hasToken)
      axios.post(`${process.env.REACT_APP_API_URL}/v1/user-search-query`, {
        searchQuery,
      });
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();

    onClearInput();
    hideMobileKeyboard();
    createSearchQueryRequest(searchText);
    history.push(`/search?${qs.stringify({ search: searchText })}`);
  };

  const hideMobileKeyboard = () => {
    const activeElement = document.activeElement;
    if (activeElement instanceof HTMLElement) {
      activeElement.blur();
    }
  };

  const onClearInput = () => setSearchText('');

  return (
    <React.Fragment>
      {renderSearchComponent({ searchText, onSearchInputChange, handleSearch, onClearInput })}
    </React.Fragment>
  );
};

export const SearchWrapperWithRouter = withRouter(SearchWrapper);

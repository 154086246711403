import React from 'react';
import styled from 'styled-components';

import { IFullButtonProps } from './constants';
import { StyledButton } from './sharedStyles';

const SandwichWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > :first-child {
    margin-bottom: 16px;
  }
`;

export const SandwichCTAButtons = ({
  primaryText,
  primaryOnClick,
  primaryVariant,
  primaryProps,
  secondaryText,
  secondaryOnClick,
  secondaryVariant = 'secondary',
  secondaryProps,
  ...otherProps
}: IFullButtonProps) => {
  return (
    <SandwichWrapper data-testid="sandwich" {...otherProps}>
      <StyledButton
        widthStyle="fluid"
        onClick={primaryOnClick}
        variant={primaryVariant}
        data-testid="cta-primary"
        {...primaryProps}
      >
        {primaryText}
      </StyledButton>
      <StyledButton
        widthStyle="fluid"
        onClick={secondaryOnClick}
        variant={secondaryVariant}
        data-testid="cta-secondary"
        {...secondaryProps}
      >
        {secondaryText}
      </StyledButton>
    </SandwichWrapper>
  );
};

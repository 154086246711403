export interface ICalendarEventData {
  title: string;
  details?: string;
  startDate: Date;
  endDate: Date;
  address?: string;
  // A recurrence rule encoded in the iCalendar format, e.g. "RRULE:FREQ=DAILY;COUNT=1000" https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
  recurrence?: string;
}

const formatTime = (date: Date) => date.toISOString().replace(/-|:|\.\d+/g, '');

export function generateGoogleLink(event: ICalendarEventData) {
  const startTime = formatTime(event.startDate);
  const endTime = formatTime(event.endDate);

  const uriConstituents = [
    'https://www.google.com/calendar/render',
    '?action=TEMPLATE',
    `&text=${event.title}`,
    `&dates=${startTime}/${endTime}`,
    `&details=${event.details || ''}`,
    `&location=${event.address || ''}`,
    `&recur=${event.recurrence || ''}`,
  ];

  return encodeURI(uriConstituents.join(''));
}

function generateIcsData(event: ICalendarEventData) {
  const startTime = formatTime(event.startDate);
  const endTime = formatTime(event.endDate);
  const data = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `DTSTART:${startTime}`,
    `DTEND:${endTime}`,
    `SUMMARY:${event.title}`,
    `DESCRIPTION:${event.details || ''}`,
    `LOCATION:${event.address || ''}`,
    event.recurrence,
    'END:VEVENT',
    'END:VCALENDAR',
  ];
  return data.join('\n');
}

export function generateIcsLink(event: ICalendarEventData) {
  const metadata = 'data:text/calendar;charset=utf8';
  const icsData = generateIcsData(event);

  if (isIE) {
    return undefined;
  }
  return encodeURI(`${metadata},${icsData}`);
}

export function generateAndDownloadIcsOnIE(event: ICalendarEventData, filename: string) {
  if (isIE) {
    const icsData = generateIcsData(event);
    const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
    // TODO: [FP-0000] Method have been removed from web standards, to remove when we drop IE support
    (window.navigator as any).msSaveOrOpenBlob(blob, filename);
  }
}

// TODO: [FP-0000] Method have been removed from web standards, to remove when we drop IE support
const isIE =
  typeof window !== 'undefined' && (window.navigator as any).msSaveOrOpenBlob && window.Blob;

import { isEmpty } from 'lodash';
import React from 'react';

import { ApimMarketPriceInputLabel } from 'components/Apim-Myinfo/APIMCards/ApimCommon/ApimMarketPriceInputLabel';
import { PanelWithDeleteIcon } from 'components/Common';
import { ASSET_TYPE, ENTER_OPTION } from 'constants/enums';

import {
  NameInvestmentSourceSection,
  INameInvestmentSourceProps,
} from '../Common/NameInvestmentSourceSection';
import { QuantityValueSection, IQuantityValueSectionProps } from '../Common/QuantityValueSection';
import { ITotalValueSectionProps } from '../Common/TotalValueSection';
import { IUnitPriceSectionProps } from '../Common/UnitPriceSection';
import { Wrapper } from '../Common/Wrapper';

interface IOwnProps {
  onDelete: () => void;
  investmentType: Extract<
    ASSET_TYPE,
    ASSET_TYPE.BOND | ASSET_TYPE.STOCK | ASSET_TYPE.UNIT_TRUST | ASSET_TYPE.OTHER_INVESTMENT
  >;
  autoFocusOnStart?: boolean;
}

type IProps = INameInvestmentSourceProps &
  IQuantityValueSectionProps &
  IUnitPriceSectionProps &
  ITotalValueSectionProps &
  IOwnProps &
  React.HTMLAttributes<HTMLDivElement>;

export const InvestmentWithUnitPriceInputPanel: React.FC<IProps> = ({
  id,
  enterOption,
  handleEnterOptionChange,
  onDelete,
  investmentSource,
  onClickInvestmentSource,
  name,
  handleNameChange,
  totalValue,
  totalValueCurrency,
  handleTotalValueChange,
  handleTotalValueCurrencyChange,
  totalQuantity,
  handleTotalQuantityChange,
  unitPrice,
  unitPriceCurrency,
  handleUnitPriceChange,
  handleUnitPriceCurrencyChange,
  investmentType,
  autoFocusOnStart,
}) => {
  const isEmptyName = isEmpty(name);
  const isEmptyValues =
    enterOption === ENTER_OPTION.TOTAL_VALUE
      ? Number(totalValue) === 0
      : Number(totalQuantity) === 0 || Number(unitPrice) === 0;

  return (
    <>
      <PanelWithDeleteIcon
        id={id}
        data-testid="investment-with-unit-price-input-panel"
        onDelete={onDelete}
      >
        <Wrapper investmentSource={investmentSource}>
          <NameInvestmentSourceSection
            investmentSource={investmentSource}
            name={name}
            handleNameChange={handleNameChange}
            investmentType={investmentType}
            onClickInvestmentSource={onClickInvestmentSource}
            autoFocus={autoFocusOnStart && isEmptyName && isEmptyValues}
          />
          <QuantityValueSection
            enterOption={enterOption}
            handleEnterOptionChange={handleEnterOptionChange}
            totalValueFieldLabel="Value"
            totalValue={totalValue}
            totalValueCurrency={totalValueCurrency}
            handleTotalValueChange={handleTotalValueChange}
            handleTotalValueCurrencyChange={handleTotalValueCurrencyChange}
            totalQuantityFieldLabel="Units"
            unitPriceLabel={<ApimMarketPriceInputLabel assetType={investmentType} />}
            unitOfMeasurement=""
            totalQuantity={totalQuantity}
            handleTotalQuantityChange={handleTotalQuantityChange}
            unitPrice={unitPrice}
            unitPriceCurrency={unitPriceCurrency}
            handleUnitPriceChange={handleUnitPriceChange}
            handleUnitPriceCurrencyChange={handleUnitPriceCurrencyChange}
            autoFocus={autoFocusOnStart && !isEmptyName}
          />
        </Wrapper>
      </PanelWithDeleteIcon>
    </>
  );
};

import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';

import { applicationFlags } from 'applicationFlags';

const IDENTIFIER_COOKIE_KEY = 'fpds-identifier';

export const useIdentifierCookie = () => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies([IDENTIFIER_COOKIE_KEY]);

  useEffect(() => {
    if (!applicationFlags.isProduction) {
      const identifierCookie =
        cookies[IDENTIFIER_COOKIE_KEY] === 'undefined' ? null : `${cookies[IDENTIFIER_COOKIE_KEY]}`;

      const queryParams = new URLSearchParams(history.location.search);
      const paramIdentifier = (queryParams.get('identifier') || '').toLowerCase();

      if (paramIdentifier.length > 0) {
        if (identifierCookie !== paramIdentifier) {
          setCookie(IDENTIFIER_COOKIE_KEY, paramIdentifier, { maxAge: 2147483647 });
        }
        window.document.title = `MyMoneySense (${paramIdentifier})`;
      } else {
        removeCookie(IDENTIFIER_COOKIE_KEY);
        window.document.title = 'MyMoneySense';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

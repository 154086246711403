import { StyleSheet } from '@react-pdf/renderer';

import { fontSizes, margin } from '../styles';

export const TipsPagesStyles = StyleSheet.create({
  tipsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tipsCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  tipContainer: {
    display: 'flex',
    marginBottom: margin.medium,
  },
  tipTitle: {
    fontSize: fontSizes.h2,
    fontWeight: 'bold',
    marginBottom: margin.xsmall * 1.5,
  },
  tipBody: {
    lineHeight: '120%',
  },
});

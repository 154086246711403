import mixpanel from 'mixpanel-browser';

import {
  getMonthlySavings,
  getTargetDeltaSavings,
  getTargetMonthlySavings,
} from 'redux/finances/selectors';
import { areAllGoalsAttainable, areAllTargetsAttained } from 'redux/targets/selectors';

import { ThunkResult } from 'analytics';

/**
 * The following data tags are interlinked with the document "Mixpanel Tags MasterList"
 * under FPDS > 03 Development > 08 Investments > 04 Development > Data Analytics
 * https://docs.google.com/spreadsheets/d/1P5JAJDdIMu6uHjICNFnuU5i3d2jP-RPwn7u7_m8pglI/edit#gid=0
 *
 * Whenever a data tag function is added, get removed or is no longer being called in code,
 * the document should be updated accordingly too to sync up with business side.
 */

// S/N 025
export const captureGoalEdited = (
  prevAmt: number,
  newAmt: number,
  prevDate: Date,
  newDate: Date,
  analyticsSource: 'Targets page' | 'Review savings'
) => {
  let amountChange = 'no change';
  if (newAmt > prevAmt) {
    amountChange = 'increased';
  }
  if (newAmt < prevAmt) {
    amountChange = 'decreased';
  }

  let dateChange = 'no change';
  if (newDate > prevDate) {
    dateChange = 'increased';
  }
  if (newDate < prevDate) {
    dateChange = 'decreased';
  }

  mixpanel.track(`${analyticsSource}: Target edited`, {
    amountChange,
    dateChange,
  });
};

// S/N 026
export const captureTargetDeleted = (analyticsSource: 'Targets page' | 'Review savings') => {
  mixpanel.track(`${analyticsSource}: Target deleted`);
};

// S/N 027
export const captureRealityCheck = (): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    const monthlySavings = getMonthlySavings(store);
    const allGoalsAttainable = areAllGoalsAttainable(store, monthlySavings);
    const allTargetsAttained = areAllTargetsAttained(store);

    const trackerName = 'Reality check: Reality check modal';
    if (monthlySavings <= 0) {
      mixpanel.track(trackerName, {
        realityCheckType: 'Saving less than you earn',
      });
    } else if (!allGoalsAttainable) {
      mixpanel.track(trackerName, {
        realityCheckType: 'Goals not achievable',
      });
    } else if (allTargetsAttained) {
      mixpanel.track(trackerName, {
        realityCheckType: 'All targets achieved',
      });
    } else {
      mixpanel.track(trackerName, {
        realityCheckType: 'On the way to achieving targets',
      });
    }
  };
};

// S/N 028
export const captureCommittedToIncreasedMonthlySavings = (
  committedSavings: number
): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    const currentMonthlySavings = getMonthlySavings(store);
    mixpanel.track('Committed to Monthly Savings', {
      isAmountIncreased: committedSavings > currentMonthlySavings,
    });
  };
};

// S/N 029
export const captureMetCommittedSavings = (): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    // getAmountBetweenLatestAndComparison() was deleted in FP-1715 because it is no longer possible to derive an amount.
    // availableFunds (FO) is not stored in historical data format like totalSavings (Sally), making it impossible to compare the amounts.

    // Keeping this unused function because business has not made any decision to delete it.
    // Keeping the old code commented out for easy reference for revival if time for it comes.
    // const amountBetweenLatestAndComparison = getAmountBetweenLatestAndComparison(store) || 0;

    const amountBetweenLatestAndComparison = 0;
    const targetDeltaSavings = getTargetDeltaSavings(store) || 0;
    const targetShortfall = targetDeltaSavings - amountBetweenLatestAndComparison;
    mixpanel.track('Updated committed savings (Met committed savings)', {
      isMet: targetShortfall <= 0,
    });
  };
};

// S/N 030
export const captureOnTrackToAchieveTargets = (): ThunkResult<void> => {
  return (_, getState) => {
    const store = getState();
    mixpanel.track('Updated committed savings (Targets achievability)', {
      targetsAchievable: areAllGoalsAttainable(store, getTargetMonthlySavings(store)),
    });
  };
};

import { IGenericProductDetails } from './interfaces';

export const spreadProductDetails = (productDetails: IGenericProductDetails[]) =>
  productDetails.map((ele) => {
    const { decryptedPayload, ...rest } = ele;

    if (typeof decryptedPayload === 'object' && decryptedPayload !== null) {
      return {
        ...rest,
        ...decryptedPayload,
      };
    } else {
      return ele;
    }
  });

import { clamp, throttle } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp, theme } from 'styled-tools';

import { colorScale, minWidth } from 'themes';
import { smoothScroll } from 'utilities/animationUtilities';

const SCROLLBAR_THICKNESS = '6px';

const OuterWrapper = styled.div`
  transition: ${theme('transitions.durationLonger')};
`;

const ScrollWrapper = styled.div<{ paddingBottom: number; isDragging: boolean }>`
  overflow-x: scroll;
  overflow-y: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding-bottom: ${prop('paddingBottom')}px;
  cursor: ${ifProp('isDragging', 'grabbing', 'grab')};
  z-index: 0;

  ${minWidth(
    'tablet',
    css`
      padding-bottom: calc(${prop('paddingBottom')}px - ${SCROLLBAR_THICKNESS});

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: ${SCROLLBAR_THICKNESS};
      }

      ::-webkit-scrollbar-thumb {
        border-radius: ${SCROLLBAR_THICKNESS};
        background-color: ${colorScale('grey', 50)};
      }
    `
  )}

  & > * {
    flex: none;
  }
`;

interface IOwnProps {
  /**
   * A floating point index will cause the treadmill
   * to not be snapped to any boundary
   */
  index: number;

  /**
   * newIndex will always be an integer
   */
  onIndexChange: (newIndex: number) => void;

  /**
   * Function that is called once the treadmill has
   * finished moving to the current index position
   */
  onMoveEnd: () => void;
}

interface IOwnState {
  isDragging: boolean;
  childHeight: number;
  childWidth: number;

  /**
   * This pushes the scrollbar all the way to the
   * bottom of the parent container
   */
  scrollWrapperPaddingBottom: number;

  extraSpaceBeforeChildren: number | string;
  extraSpaceAfterChildren: number;
}

export class Treadmill extends React.Component<IOwnProps, IOwnState> {
  private readonly outerRef: React.RefObject<HTMLDivElement>;
  private readonly scrollRef: React.RefObject<HTMLDivElement>;

  private scrollTimer: number | undefined;
  private userScrollTimer: number | undefined;
  private snappingAnimation: { cancel: () => void } | undefined;

  /**
   * The current index is stored internally so that we can differentiate
   * between an internal (when a user scrolls) and external (when a user
   * clicks on one of the suggestion indicators) change to the current index.
   * These events can then be handled differently.
   */
  private currentIndex: number;
  private dragStartX: number | undefined;
  private referenceScrollLeft: number;

  constructor(props: IOwnProps) {
    super(props);

    this.scrollRef = React.createRef();
    this.outerRef = React.createRef();

    this.scrollTimer = undefined;
    this.userScrollTimer = undefined;
    this.snappingAnimation = undefined;
    this.currentIndex = props.index;
    this.dragStartX = 0;
    this.referenceScrollLeft = 0;

    this.handleScroll = this.handleScroll.bind(this);
    this.handleUserScroll = this.handleUserScroll.bind(this);
    this.handleScrollStart = this.handleScrollStart.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.moveToCurrentIndex = this.moveToCurrentIndex.bind(this);
    this.calcDimensions = this.calcDimensions.bind(this);
    this.handleResize = throttle(this.handleResize.bind(this), 300);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);

    this.state = {
      isDragging: false,
      childHeight: 0,
      childWidth: 0,
      scrollWrapperPaddingBottom: 0,
      extraSpaceBeforeChildren: 0,
      extraSpaceAfterChildren: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    if (this.scrollRef.current) {
      this.scrollRef.current.addEventListener('scroll', this.handleScroll);
      this.scrollRef.current.addEventListener('wheel', this.handleUserScroll);
      this.scrollRef.current.addEventListener('touchmove', this.handleUserScroll);
      this.scrollRef.current.addEventListener('mousedown', this.handleDragStart);
      this.scrollRef.current.addEventListener('mousemove', this.handleDrag);
      this.scrollRef.current.addEventListener('mouseup', this.handleDragEnd);
      this.scrollRef.current.addEventListener('mouseleave', this.handleDragEnd);
      this.calcDimensions(() => {
        this.moveToCurrentIndex(800);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.scrollRef.current) {
      this.scrollRef.current.removeEventListener('scroll', this.handleScroll);
      this.scrollRef.current.removeEventListener('wheel', this.handleUserScroll);
      this.scrollRef.current.removeEventListener('touchmove', this.handleUserScroll);
      this.scrollRef.current.removeEventListener('mousedown', this.handleDragStart);
      this.scrollRef.current.removeEventListener('mousemove', this.handleDrag);
      this.scrollRef.current.removeEventListener('mouseup', this.handleDragEnd);
      this.scrollRef.current.removeEventListener('mouseleave', this.handleDragEnd);
    }
  }

  componentDidUpdate() {
    if (this.currentIndex !== this.props.index) {
      this.currentIndex = this.props.index;
      this.moveToCurrentIndex(400);
    }
  }

  handleResize() {
    this.calcDimensions(this.moveToCurrentIndex);
  }

  handleDragStart(e: MouseEvent) {
    e.preventDefault();
    this.setState({ isDragging: true }); // Part of the state so that the cursor can be switched
    this.referenceScrollLeft = (this.scrollRef.current as HTMLDivElement).scrollLeft;
    this.dragStartX = e.clientX;
  }

  handleDrag(e: MouseEvent) {
    e.preventDefault(); // Prevents highlighting of children text
    if (this.state.isDragging) {
      if (this.dragStartX === undefined) {
        this.dragStartX = e.clientX;
      }
      (this.scrollRef.current as HTMLDivElement).scrollLeft =
        this.referenceScrollLeft - (e.clientX - this.dragStartX);
    }
  }

  handleDragEnd(e: Event) {
    e.preventDefault();
    if (this.state.isDragging) {
      this.setState({ isDragging: false });
      this.moveToCurrentIndex();
    }
  }

  handleUserScroll(e: WheelEvent | TouchEvent) {
    if (e.type === 'wheel') {
      e = e as WheelEvent;
      // Convert vertical scroll to horizontal scroll
      if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
        (this.scrollRef.current as HTMLDivElement).scrollLeft += e.deltaY;
        e.preventDefault();
      }
    }
    window.clearTimeout(this.userScrollTimer);
    this.userScrollTimer = window.setTimeout(() => {
      this.userScrollTimer = undefined;
    }, 150);
  }

  handleScroll() {
    if (this.snappingAnimation) {
      if (this.userScrollTimer) {
        this.snappingAnimation.cancel();
        this.snappingAnimation = undefined;
      } else {
        return;
      }
    }

    if (this.scrollTimer === undefined) {
      this.handleScrollStart();
    }

    // The currentIndex is snapped to the nearest boundary
    const currentScrollLeft = (this.scrollRef.current as HTMLDivElement).scrollLeft;
    const snappedScrollLeft = this.getNearestSnapValue(currentScrollLeft);
    const currentIndex = Math.floor(snappedScrollLeft / this.state.childWidth);

    if (currentIndex >= 0 && this.currentIndex !== currentIndex) {
      this.currentIndex = currentIndex;
      this.props.onIndexChange(this.currentIndex);
    }

    window.clearTimeout(this.scrollTimer);

    if (!this.state.isDragging) {
      this.scrollTimer = window.setTimeout(this.handleScrollEnd, 150);
    }
  }

  handleScrollStart() {}

  handleScrollEnd() {
    this.scrollTimer = undefined;
    this.moveToCurrentIndex();
  }

  moveToCurrentIndex(durationMS = 200) {
    if (this.snappingAnimation) {
      this.snappingAnimation.cancel();
      this.snappingAnimation = undefined;
    }

    const currentScrollLeft = (this.scrollRef.current as HTMLDivElement).scrollLeft;
    const targetScrollLeft = this.clampToBounds(this.currentIndex * this.state.childWidth);

    if (currentScrollLeft !== targetScrollLeft) {
      this.snappingAnimation = smoothScroll(
        currentScrollLeft,
        targetScrollLeft,
        durationMS,
        this.scrollRef.current,
        'x',
        () => {
          this.snappingAnimation = undefined;
          this.props.onMoveEnd();
        }
      );
    } else {
      this.props.onMoveEnd();
    }
  }

  getNearestSnapValue(value: number) {
    const { childWidth } = this.state;
    return this.clampToBounds(Math.round(value / childWidth) * childWidth);
  }

  clampToBounds(value: number) {
    const { childWidth } = this.state;
    return clamp(value, 0, childWidth * (React.Children.count(this.props.children) - 1));
  }

  public calcDimensions(callback?: () => void) {
    if (!this.outerRef.current || !this.scrollRef.current) {
      return;
    }
    const outerRefElem = this.outerRef.current;
    const firstChild = this.scrollRef.current.children[0];
    const { height: childHeight, width: childWidth } = firstChild.getBoundingClientRect();

    this.setState(
      {
        childHeight,
        childWidth,
        scrollWrapperPaddingBottom: outerRefElem.offsetParent
          ? outerRefElem.offsetParent.clientHeight -
            outerRefElem.clientHeight -
            outerRefElem.offsetTop
          : 0,
        extraSpaceBeforeChildren: '50%',
        extraSpaceAfterChildren: outerRefElem.offsetParent
          ? outerRefElem.offsetParent.clientWidth - outerRefElem.offsetLeft - childWidth
          : 0,
      },
      callback
    );
  }

  render() {
    const {
      isDragging,
      childHeight,
      scrollWrapperPaddingBottom,
      extraSpaceBeforeChildren,
      extraSpaceAfterChildren,
    } = this.state;

    return (
      <OuterWrapper ref={this.outerRef} style={{ height: childHeight }}>
        <ScrollWrapper
          ref={this.scrollRef}
          paddingBottom={scrollWrapperPaddingBottom}
          isDragging={isDragging}
        >
          {this.props.children}
          <div
            style={{
              width: extraSpaceBeforeChildren,
              order: -1, // appears before the children
            }}
          />
          <div style={{ width: extraSpaceAfterChildren }} />
        </ScrollWrapper>
      </OuterWrapper>
    );
  }
}

import React from 'react';
import styled from 'styled-components';
import { prop, theme as fromTheme } from 'styled-tools';

const Bar = styled.div`
  height: 8px;
  transition: ${fromTheme('transitions.durationLonger')};
  min-width: 4px;
  border-radius: 4px;
`;

const StyledComparisonBar = styled.div<{ bgColor: string }>`
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  margin-bottom: 8px;
  background: ${prop('bgColor')};
  border-radius: 4px;
  height: 8px;
`;

const CurrAmountBar = styled(Bar)<{
  percentage: number;
  bgColor: string;
}>`
  background: ${prop('bgColor')};
  flex-basis: ${prop('percentage')}%;
`;

export interface IComparisonBarProps {
  currAmount: number;
  percentage: number;
  currColor: string;
  bgColor: string;
}

export const ComparisonBar = ({
  currAmount,
  percentage,
  currColor,
  bgColor,
}: IComparisonBarProps) => (
  <StyledComparisonBar bgColor={bgColor}>
    {currAmount > 0 && <CurrAmountBar percentage={percentage} bgColor={currColor} />}
  </StyledComparisonBar>
);

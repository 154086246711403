import {
  RECOMMENDED_ACTION,
  RECOMMENDED_ACTION_COMPLETER,
  RECOMMENDED_ACTION_LABEL,
} from 'constants/enums';
import { BUDGET_CALCULATOR, EXTERNAL_URL, RESOURCES } from 'constants/urls';

export interface IRecommendedActionForRender {
  key: RECOMMENDED_ACTION;
  heading: string;
  message?: string;
  link: IRecommendedActionLink;
  label: RECOMMENDED_ACTION_LABEL;
  completer: RECOMMENDED_ACTION_COMPLETER;
  completedAt: string | null;
}

export interface IRecommendedActionLink {
  url: string;
  external: boolean;
  buttonTextOverwrite?: string;
}

export const recommendedActions: { [key: string]: IRecommendedActionForRender } = {
  [RECOMMENDED_ACTION.TRA_SR_A]: {
    key: RECOMMENDED_ACTION.TRA_SR_A,
    heading: 'Automate your savings',
    message: 'Save more consistently by setting up automatic transfer arrangements with your bank.',
    link: { url: RESOURCES.SAVINGS.saveBeforeYouSpend, external: false },
    label: RECOMMENDED_ACTION_LABEL.SAVINGS,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_SR_B]: {
    key: RECOMMENDED_ACTION.TRA_SR_B,
    heading: 'Set savings targets',
    message:
      'Targets help you stay focused and motivated. Plan to save enough every month to achieve all your targets by their due dates.',
    link: { url: '/targets/setup', external: false, buttonTextOverwrite: 'Set targets' },
    label: RECOMMENDED_ACTION_LABEL.SAVINGS,
    completer: RECOMMENDED_ACTION_COMPLETER.SYSTEM,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_SR_C]: {
    key: RECOMMENDED_ACTION.TRA_SR_C,
    heading: 'Boost your emergency fund',
    message:
      'Be ready for anything life puts in your path. Aim to have 6 months of your monthly expenses and loan repayments in cash savings for emergencies.',
    link: { url: '/targets/setup', external: false, buttonTextOverwrite: 'Set target' },
    label: RECOMMENDED_ACTION_LABEL.SAVINGS,
    completer: RECOMMENDED_ACTION_COMPLETER.SYSTEM,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_SR_D]: {
    key: RECOMMENDED_ACTION.TRA_SR_D,
    heading: 'Set aside enough emergency funds',
    message:
      'Aim to have at least 3 months of your monthly expenses and loan repayments in cash savings for emergencies.',
    link: { url: '/targets/setup', external: false, buttonTextOverwrite: 'Set target' },
    label: RECOMMENDED_ACTION_LABEL.SAVINGS,
    completer: RECOMMENDED_ACTION_COMPLETER.SYSTEM,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_SR_E]: {
    key: RECOMMENDED_ACTION.TRA_SR_E,
    heading: 'Set a budget',
    message:
      'Budgeting ensures that you have enough money for what you need now, while saving for your future.',
    link: { url: BUDGET_CALCULATOR.baseRoute, external: false },
    label: RECOMMENDED_ACTION_LABEL.SAVINGS,
    completer: RECOMMENDED_ACTION_COMPLETER.SYSTEM,
    completedAt: null,
  },

  [RECOMMENDED_ACTION.TRA_TDSR_A]: {
    key: RECOMMENDED_ACTION.TRA_TDSR_A,
    heading: 'Repay your loans early',
    message: 'Save on interest by prepaying your loans or increasing your monthly instalment.',
    link: {
      url: RESOURCES.LOANS_AND_CREDIT.repayYourLoansEarlyPage,
      external: false,
    },
    label: RECOMMENDED_ACTION_LABEL.LOANS_AND_CREDIT,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_TDSR_B]: {
    key: RECOMMENDED_ACTION.TRA_TDSR_B,
    heading: 'Manage your loans better',
    message: 'Explore loan repayment strategies to keep your debts under control.',
    link: {
      url: 'https://www.moneysense.gov.sg/taking-stock-of-your-finances/',
      external: true,
    },
    label: RECOMMENDED_ACTION_LABEL.LOANS_AND_CREDIT,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_TDSR_C]: {
    key: RECOMMENDED_ACTION.TRA_TDSR_C,
    heading: 'Tide over this Covid-19 period with temporary relief',
    message:
      'Learn more about the available loan repayment options, if you need temporary cashflow relief.',
    link: {
      url:
        'https://www.mas.gov.sg/regulation/covid-19/supporting-individuals-through-covid-19/helping-individuals-transit-to-full-loan-repayments',
      external: true,
    },
    label: RECOMMENDED_ACTION_LABEL.LOANS_AND_CREDIT,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },

  [RECOMMENDED_ACTION.TRA_PD_A]: {
    key: RECOMMENDED_ACTION.TRA_PD_A,
    heading: 'Review your investments regularly',
    message: 'Manage risks while ensuring that your investment portfolio meets your needs.',
    link: { url: RESOURCES.INVESTMENT.setReminders, external: false },
    label: RECOMMENDED_ACTION_LABEL.INVESTMENTS,
    completer: RECOMMENDED_ACTION_COMPLETER.SYSTEM,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_PD_B]: {
    key: RECOMMENDED_ACTION.TRA_PD_B,
    heading: 'Diversify your investments',
    message:
      'Don’t put all your eggs in one basket. Learn more about the different types of investment products.',
    link: {
      url: 'https://www.moneysense.gov.sg/investments/types-of-investments/',
      external: true,
    },
    label: RECOMMENDED_ACTION_LABEL.INVESTMENTS,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_PD_C]: {
    key: RECOMMENDED_ACTION.TRA_PD_C,
    heading: 'Learn about investing',
    message:
      'Understand your investment objectives, risk profile and time horizon. Put together an investment portfolio that works for you.',
    link: {
      url: 'https://www.moneysense.gov.sg/investments/investment-portfolio/',
      external: true,
    },
    label: RECOMMENDED_ACTION_LABEL.INVESTMENTS,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_PD_D]: {
    key: RECOMMENDED_ACTION.TRA_PD_D,
    heading: 'Start investing',
    message:
      'Find out how you can set up a Central Depository account (CDP) and brokerage account.',
    link: {
      url: 'https://www.sgx.com/securities/retail-investor/apply-cdp-securities-account',
      external: true,
    },
    label: RECOMMENDED_ACTION_LABEL.INVESTMENTS,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },
  [RECOMMENDED_ACTION.TRA_PD_E]: {
    key: RECOMMENDED_ACTION.TRA_PD_E,
    heading: 'Invest some of your savings',
    message:
      'Buying Singapore Savings Bonds (SSB) is suitable for beginners who want to start investing in smaller amounts and keep risks low.',
    link: { url: 'https://www.mas.gov.sg/bonds-and-bills/Singapore-Savings-Bonds', external: true },
    label: RECOMMENDED_ACTION_LABEL.INVESTMENTS,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },

  [RECOMMENDED_ACTION.TRA_EF_A]: {
    key: RECOMMENDED_ACTION.TRA_EF_A,
    heading: 'Grow your CPF retirement savings',
    message:
      'Earn interest rates of up to 5% and enjoy tax relief by topping up your Special Account using cash or CPF transfers.',
    link: {
      url: EXTERNAL_URL.cpfTopUpSavings,
      external: true,
    },
    label: RECOMMENDED_ACTION_LABEL.SAVINGS,
    completer: RECOMMENDED_ACTION_COMPLETER.USER,
    completedAt: null,
  },
};

import moment from 'moment';

import { applicationFlags } from 'applicationFlags';
import {
  AGE_RANGE,
  WORK_YEARS_RANGE,
  MAIN_MODULE,
  EMAIL_VERIFICATION_STATUS,
} from 'constants/enums';
import { IStore } from 'constants/storeTypes';

import { getIncompleteModulesList, getWasModuleCompleted } from '../modules/selectors';
import {
  getMyinfoName,
  getMyinfoDob,
  getMyinfoSexDesc,
  getMyinfoMaritalStatusDesc,
  getMyinfoResidentialStatusDesc,
  getHasMyinfoBasic,
} from '../myinfo/selectors';

export const getUserId = (store: IStore) => store.profile.userId;

export const getProfileName = (store: IStore) =>
  getHasMyinfoBasic(store) ? getMyinfoName(store) || '' : getManualProfileName(store);

export const getProfileDob = (store: IStore) =>
  getHasMyinfoBasic(store) ? getMyinfoDob(store) : getManualProfileDob(store);

export const getProfileSex = (store: IStore) =>
  getHasMyinfoBasic(store) ? getMyinfoSexDesc(store) : getManualProfileSex(store);

export const getProfileMaritalStatus = (store: IStore) =>
  getHasMyinfoBasic(store)
    ? getMyinfoMaritalStatusDesc(store)
    : getManualProfileMaritalStatus(store);

export const getProfileResidentialStatus = (store: IStore) =>
  getHasMyinfoBasic(store)
    ? getMyinfoResidentialStatusDesc(store)
    : getManualProfileResidentialStatus(store);

export const getProfileEmail = (store: IStore) => store.profile.email;

export const getProfileEmploymentStatusRange = (store: IStore) =>
  store.profile.employmentStatusRange;

export const getManualProfileName = (store: IStore) => store.profile.name;

export const getManualProfileDob = (store: IStore) =>
  store.profile.dob ? new Date(store.profile.dob) : null;

export const getManualProfileSex = (store: IStore) => store.profile.sex;

export const getManualProfileMaritalStatus = (store: IStore) => store.profile.maritalStatus;

export const getManualProfileResidentialStatus = (store: IStore) => store.profile.residentialStatus;

export const getEmailVerificationStatus = (store: IStore) => store.profile.emailVerificationStatus;

export const getEmailNotificationsStatus = (store: IStore) =>
  store.profile.emailNotificationsStatus?.newsletter;

export const getProfileAge = (store: IStore) => {
  if (getHasMyinfoBasic(store)) {
    const myinfoDob = getMyinfoDob(store);
    return moment().diff(moment(myinfoDob, 'YYYY-MM-DD'), 'years');
  }
  return moment().diff(getManualProfileDob(store), 'years');
};

// DEPRECATING
export const getProfileAgeRange = (store: IStore) => store.profile.ageRange;

export const getProfileWorkYearsRange = (store: IStore) => store.profile.workYearsRange;

export const getProfileHasStartedPlanning = (store: IStore) => store.profile.hasStartedPlanning;

export const getIsTargetAudience = (store: IStore) => {
  const { ageRange, workYearsRange, hasStartedPlanning } = store.profile;

  return (
    ageRange === AGE_RANGE.BETWEEN_18_AND_29 &&
    workYearsRange === WORK_YEARS_RANGE.UNDER_3 &&
    hasStartedPlanning === false
  );
};

export const getHasCompletedOnboardingProfileQuestions = (store: IStore) => {
  const employmentStatusRange = getProfileEmploymentStatusRange(store);
  return employmentStatusRange !== undefined;
};

export const getHasCompletedOnboardingModules = (store: IStore) => {
  return getIncompleteModulesList(store).length === 0;
};

export const getHasCompletedOnboarding = (store: IStore) =>
  getHasCompletedOnboardingProfileQuestions(store) && getHasCompletedOnboardingModules(store);

export const getHasCompletedTargetsSetup = (store: IStore) =>
  getWasModuleCompleted(store, MAIN_MODULE.SET_SAVING_TARGETS);

export const getHasCompletedFeedbackForm = (store: IStore) =>
  store.profile.hasCompletedFeedbackForm;

export const getHasConsentedToAppTNCAt = (store: IStore) =>
  Boolean(store.profile.consentedToAppTNCAt);

export const getDeferEmailVerification = (store: IStore) =>
  store.profile.emailVerificationStatus === EMAIL_VERIFICATION_STATUS.VERIFIED ||
  Boolean(store.profile.deferEmailVerification);

export const getNeedsNOCIntercept = (store: IStore) => {
  // TODO: [FP-0000] Remove condition after 2023-10-30T00:00:00Z and set as this as release date
  const releaseDate = applicationFlags.isProduction
    ? '2023-10-29T00:00:00Z'
    : '2023-10-25T00:00:00Z';
  const latestConsentedToAppNOCAt = store.profile.latestConsentedToAppNOCAt;
  return (
    latestConsentedToAppNOCAt === null ||
    moment(latestConsentedToAppNOCAt).isSameOrBefore(releaseDate)
  );
};

export const getMyinfoLatestPullConsentAt = (store: IStore) =>
  store.profile.myinfoLatestPullConsentAt
    ? new Date(store.profile.myinfoLatestPullConsentAt)
    : null;

export const getApimLatestPullConsentAt = (store: IStore) =>
  store.profile.apimLatestPullConsentAt ? new Date(store.profile.apimLatestPullConsentAt) : null;

export const getProfileApimMyinfoDataSelectionState = (store: IStore) =>
  store.profile.apimMyinfoDataSelectionState;

export const getProfileLastLogin = (store: IStore) =>
  store.profile.lastLogin ? new Date(store.profile.lastLogin) : null;

export const getAccountStatus = (store: IStore) =>
  store.profile.accountStatus
    ? {
        ...store.profile.accountStatus,
        createdAt: new Date(store.profile.accountStatus.createdAt),
      }
    : null;

export const getProfileIsReturnUser = (store: IStore) => store.profile.isReturnUser;

import { css } from 'styled-components';

import { typeScale, minWidth, typeScaleLevel } from 'themes';

enum SCREEN_TYPE {
  MOBILE = 0,
  DESKTOP = 1,
}

type lineHeightValues = 0 | 1.4 | 1.6 | 1.8;
type fontWeightValues = 'normal' | 600 | 'bold';
type fontStyleValues = 'normal' | 'italic';

export type typographyCategories =
  | 'title'
  | 'heading-l'
  | 'heading-m'
  | 'heading-s'
  | 'body-l'
  | 'body-l-bold'
  | 'body-m'
  | 'body-m-bold'
  | 'body-s'
  | 'body-s-bold'
  | 'body-s-semibold'
  | 'body-s-italic'
  | 'label'
  | 'label-bold'
  | 'numbers-l';

export const typography = (category: typographyCategories) => {
  const isTitle = category === 'title';
  const isHeading = category.startsWith('heading');
  const isBold = category.endsWith('bold') && !category.endsWith('semibold');
  const isSemibold = category.endsWith('semibold');
  const isItalic = category.endsWith('italic');

  const fontSize: [typeScaleLevel, typeScaleLevel] =
    category === 'title'
      ? [4, 5]
      : category === 'heading-l'
      ? [3, 4]
      : category === 'heading-m'
      ? [2, 3]
      : category === 'heading-s'
      ? [1, 2]
      : category.startsWith('body-l')
      ? [0, 1]
      : category.startsWith('body-m')
      ? [-1, 0]
      : category.startsWith('body-s')
      ? [-2, -1]
      : category.startsWith('label')
      ? [-3, -2]
      : category === 'numbers-l'
      ? [3, 4]
      : [0, 0];

  const lineHeight: [lineHeightValues, lineHeightValues] =
    category === 'title'
      ? [1.4, 1.4]
      : category === 'heading-l'
      ? [1.4, 1.4]
      : category === 'heading-m'
      ? [1.4, 1.4]
      : category === 'heading-s'
      ? [1.6, 1.4]
      : category.startsWith('body-l')
      ? [1.8, 1.6]
      : category.startsWith('body-m')
      ? [1.8, 1.8]
      : category.startsWith('body-s')
      ? [1.6, 1.8]
      : category.startsWith('label')
      ? [1.4, 1.6]
      : category === 'numbers-l'
      ? [1.4, 1.4]
      : [0, 0];

  const fontWeight: [fontWeightValues, fontWeightValues] =
    isTitle || isHeading || isBold
      ? ['bold', 'bold']
      : isSemibold
      ? [600, 600]
      : ['normal', 'normal'];

  const fontStyle: [fontStyleValues, fontStyleValues] = isItalic
    ? ['italic', 'italic']
    : ['normal', 'normal'];

  return css`
    font-size: ${typeScale(fontSize[SCREEN_TYPE.MOBILE])};
    font-weight: ${fontWeight[SCREEN_TYPE.MOBILE]};
    font-style: ${fontStyle[SCREEN_TYPE.MOBILE]};
    line-height: ${lineHeight[SCREEN_TYPE.MOBILE]};

    ${minWidth(
      'desktop',
      css`
        font-size: ${typeScale(fontSize[SCREEN_TYPE.DESKTOP])};
        font-weight: ${fontWeight[SCREEN_TYPE.DESKTOP]};
        font-style: ${fontStyle[SCREEN_TYPE.DESKTOP]};
        line-height: ${lineHeight[SCREEN_TYPE.DESKTOP]};
      `
    )}
  `;
};

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { featureDecisions } from './featureDecisions';
import finLitQuizResources from './utilities/mockData/mockFinlitData';

const mockServerResponsesAdditionalInitOptions = featureDecisions.mockServerResponses
  ? {
      resources: {
        en: finLitQuizResources,
      },
    }
  : {};

export const defaultNS = 'finLitQuiz';

const i18Instance = i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    ns: ['finLitQuiz'],
    defaultNS,
    returnNull: false,
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/v1/fin-lit-quiz/serve-file`,
    },
    ...mockServerResponsesAdditionalInitOptions,
  });

export default i18Instance;

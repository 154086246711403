import { formatCurrency } from 'utilities/currencyUtilities';

export function minimumAmount(min: number, inclusive = true, currency = true) {
  return function minimumAmountInner(fieldValue: string) {
    const strippedFieldValue = fieldValue && fieldValue.replace(/[,]/g, '');
    const fieldValueNumber = Number(strippedFieldValue) * (currency ? 100 : 1);
    const isValidationPassed =
      strippedFieldValue !== '' && (inclusive ? fieldValueNumber < min : fieldValueNumber <= min);

    return isValidationPassed
      ? `Must be more than ${inclusive ? 'or equal to ' : ''}${
          currency ? formatCurrency(min) : min
        }`
      : undefined;
  };
}

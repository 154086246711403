import { css } from 'styled-components';
import { ifProp, theme as fromTheme } from 'styled-tools';

import { colorScale, shadowScale } from 'themes';

export const secondaryProperties = css`
  align-items: center;
  justify-content: center;
  background: white;
  color: ${colorScale('primary', 40)};
  border-color: ${colorScale('primary', 50)};
  border-radius: ${fromTheme('borders.radiusRound')};
  font-weight: 600;

  ${ifProp(
    'disabled',
    css`
      color: ${colorScale('grey', 50)};
      border-color: ${colorScale('grey', 50)};
    `,
    css`
      &:hover,
      &:focus {
        transform: translateY(-1px);
        box-shadow: ${shadowScale(2, colorScale('primary', 30))};
      }

      &:active {
        transform: translateY(0);
        box-shadow: ${shadowScale(1, colorScale('primary', 50))};
      }
    `
  )}
`;

import React from 'react';

import ABS from './abs.svg';
import AIA from './aia.svg';
import CDP from './cdp.svg';
import Citibank from './citibank.svg';
import DBS from './dbs.svg';
import GE from './ge.svg';
import HSBCLife from './hsbc-life.svg';
import HSBC from './hsbc.svg';
import Income from './income.svg';
import LIA from './lia.svg';
import Manulife from './manulife.svg';
import MonetaryAuthorityOfSingapore from './mas.svg';
import Maybank from './maybank.svg';
import MyInfo from './myinfo.svg';
import OCBC from './ocbc.svg';
import Prudential from './prudential.svg';
import Singlife from './singlife.svg';
import SmartNation from './smart-nation.svg';
import AIASquare from './squareVersion/aia_square.svg';
import GESquare from './squareVersion/ge_square.svg';
import HSBCLifeSquare from './squareVersion/hsbc_life_square.svg';
import IncomeSquare from './squareVersion/income_square.svg';
import ManulifeSquare from './squareVersion/manulife_square.svg';
import PrudentialSquare from './squareVersion/prudential_square.svg';
import SinglifeSquare from './squareVersion/singlife_square.svg';
import StandardChartered from './standard-chartered.svg';
import UOB from './uob.svg';

type ImageProps = React.HTMLAttributes<HTMLImageElement>;

export const ABSLogo = (props: ImageProps) => (
  <img {...props} src={ABS} alt="The Association of Banks in Singapore logo" />
);

export const AIALogo = (props: ImageProps) => <img {...props} src={AIA} alt="AIA logo" />;

export const CDPLogo = (props: ImageProps) => <img {...props} src={CDP} alt="CDP logo" />;

export const CitibankLogo = (props: ImageProps) => (
  <img {...props} src={Citibank} alt="Citibank logo" />
);

export const DBSLogo = (props: ImageProps) => <img {...props} src={DBS} alt="DBS logo" />;

export const GELogo = (props: ImageProps) => <img {...props} src={GE} alt="GE logo" />;

export const HSBCLifeLogo = (props: ImageProps) => (
  <img {...props} src={HSBCLife} alt="HSBC Life Logo" />
);

export const HSBCLogo = (props: ImageProps) => <img {...props} src={HSBC} alt="HSBC logo" />;

export const IncomeLogo = (props: ImageProps) => <img {...props} src={Income} alt="Income logo" />;

export const LIALogo = (props: ImageProps) => (
  <img {...props} src={LIA} alt="Life Insurance Association Singapore logo" />
);

export const ManulifeLogo = (props: ImageProps) => (
  <img {...props} src={Manulife} alt="Manulife logo" />
);

export const MonetaryAuthorityOfSingaporeLogo = (props: ImageProps) => (
  <img {...props} src={MonetaryAuthorityOfSingapore} alt="Monetary Authority of Singapore logo" />
);

export const MaybankLogo = (props: ImageProps) => (
  <img {...props} src={Maybank} alt="Maybank logo" />
);

export const MyInfoLogo = (props: ImageProps) => <img {...props} src={MyInfo} alt="MyInfo logo" />;

export const OCBCLogo = (props: ImageProps) => <img {...props} src={OCBC} alt="OCBC logo" />;

export const PrudentialLogo = (props: ImageProps) => (
  <img {...props} src={Prudential} alt="Prudential logo" />
);

export const SinglifeLogo = (props: ImageProps) => (
  <img {...props} src={Singlife} alt="Singlife logo" />
);

export const AIASquareLogo = (props: ImageProps) => (
  <img {...props} src={AIASquare} alt="AIA logo" />
);

export const HSBCLifeSquareLogo = (props: ImageProps) => (
  <img {...props} src={HSBCLifeSquare} alt="HSBC Life Logo" />
);

export const GESquareLogo = (props: ImageProps) => <img {...props} src={GESquare} alt="GE logo" />;

export const IncomeSquareLogo = (props: ImageProps) => (
  <img {...props} src={IncomeSquare} alt="Income logo" />
);

export const ManulifeSquareLogo = (props: ImageProps) => (
  <img {...props} src={ManulifeSquare} alt="Manulife logo" />
);

export const PrudentialSquareLogo = (props: ImageProps) => (
  <img {...props} src={PrudentialSquare} alt="Prudential logo" />
);

export const SinglifeSquareLogo = (props: ImageProps) => (
  <img {...props} src={SinglifeSquare} alt="Singlife logo" />
);
export const SmartNationLogo = (props: ImageProps) => (
  <img {...props} src={SmartNation} alt="Smart Nation logo" />
);

export const StandardCharteredLogo = (props: ImageProps) => (
  <img {...props} src={StandardChartered} alt="Standard Chartered logo" />
);

export const UOBLogo = (props: ImageProps) => <img {...props} src={UOB} alt="UOB logo" />;

import React, { useEffect, useContext } from 'react';
import { isBrowser } from 'react-device-detect';

import { CurrencyInputField, AmountField } from 'components/Common';
import { INVESTMENT_SOURCE } from 'constants/enums';

import { InvestmentSourceContext } from './Wrapper';

export interface ITotalValueSectionLabelProps {
  totalValueFieldLabel: string;
}

export interface ITotalValueSectionProps {
  totalValue: number | null;
  totalValueCurrency: string;
  handleTotalValueChange: (amount: number) => void;
  handleTotalValueCurrencyChange: (currency: string) => void;
  autoFocus?: boolean;
}

type IProps = ITotalValueSectionLabelProps & ITotalValueSectionProps;

export const TotalValueSection: React.FC<IProps> = ({
  totalValueFieldLabel,
  totalValue,
  totalValueCurrency,
  handleTotalValueChange,
  handleTotalValueCurrencyChange,
  autoFocus,
}) => {
  const investmentSource = useContext(InvestmentSourceContext);

  useEffect(() => {
    if (investmentSource !== INVESTMENT_SOURCE.CASH && totalValueCurrency !== 'SGD') {
      handleTotalValueCurrencyChange('SGD');
    }
  }, [handleTotalValueCurrencyChange, investmentSource, totalValueCurrency]);

  return (
    <div>
      {investmentSource === INVESTMENT_SOURCE.CASH ? (
        <CurrencyInputField
          label={totalValueFieldLabel}
          amount={totalValue}
          selectedCurrency={totalValueCurrency}
          onInputChange={handleTotalValueChange}
          onChangeCurrency={handleTotalValueCurrencyChange}
          minNumChars={8}
          widthStyle="elastic"
          maxAllowableAmount={999999999}
          autoFocus={isBrowser && autoFocus}
        />
      ) : (
        <AmountField
          label={totalValueFieldLabel}
          amount={totalValue}
          onInputChange={handleTotalValueChange}
          minNumChars={8}
          widthStyle="elastic"
          addonBefore={<AmountField.DollarSign />}
          maxAllowableAmount={999999999}
          autoFocus={isBrowser && autoFocus}
        />
      )}
    </div>
  );
};

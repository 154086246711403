import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { colorScale, typography } from 'themes';
import { visuallyHiddenProperties } from 'utilities/sharedStyles';

export const Label = styled.label<{ hideLabel?: boolean; dropdownFocused: boolean }>`
  ${ifProp(
    'hideLabel',
    visuallyHiddenProperties,
    css`
      ${typography('body-s-semibold')}
      color: ${colorScale('grey', 80)};
      display: block;
      margin-bottom: 0.5em;
      cursor: pointer;

      ${ifProp(
        'dropdownFocused',
        css`
          color: ${colorScale('primary', 50)};
        `
      )}

      &:active,
      &:hover,
      &:focus {
        color: ${colorScale('primary', 50)};
      }
    `
  )}
`;

export const CustomLabelWrapper = styled.div<{ hideLabel?: boolean }>`
  ${ifProp('hideLabel', visuallyHiddenProperties)}
`;

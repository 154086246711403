import React from 'react';

import { RedHdbFlatsImage } from 'assets/Housing';
import {
  AmericanGothicStyledOldCoupleImage,
  BankBuildingImage,
  PuttingCoinInInvestmentImage,
  MoneyBagWithAShieldImage,
} from 'assets/Retirement';
import { CurrencyPrefix } from 'components/Common';
import { ArticleHyperlink, ArticleImageWithText, ArticleParagraph } from 'components/Templates';

export const RetirementIncome = () => {
  return (
    <>
      <ArticleParagraph>
        Review and assess the retirement income you will have when you retire. Possible sources of
        retirement income include:
      </ArticleParagraph>
      <ArticleImageWithText
        image={<AmericanGothicStyledOldCoupleImage />}
        heading="CPF LIFE"
        description={
          <>
            <ArticleParagraph>
              <b>
                <ArticleHyperlink url="https://www.cpf.gov.sg/member/retirement-income/monthly-payouts/cpf-life">
                  A national annuity scheme for Singapore Citizens and PRs
                </ArticleHyperlink>{' '}
                that provides you with monthly payouts for life.
              </b>{' '}
              You will be automatically included if you are born in 1958 or after and have at least{' '}
              <CurrencyPrefix>60,000</CurrencyPrefix> in your retirement savings when you start your
              monthly payouts.
            </ArticleParagraph>
            <ArticleParagraph>
              The payouts you will receive depend on the retirement sum you set aside in your
              Retirement Account and the CPF LIFE plan you choose. The more savings you have, the
              higher your monthly payouts.
            </ArticleParagraph>
          </>
        }
      />

      <ArticleImageWithText
        image={<BankBuildingImage />}
        heading="Private savings"
        description={
          <ArticleParagraph>
            Savings that come from your bank savings accounts and fixed deposits, which typically
            provides lower interest rates than CPF accounts.
          </ArticleParagraph>
        }
      />

      <ArticleImageWithText
        image={<PuttingCoinInInvestmentImage />}
        heading="Investments"
        description={
          <>
            <ArticleParagraph>
              Bonds, unit trusts, stocks, Exchange Traded Funds (ETFs), and Real Estate Investment
              Trusts (REITs), which typically give you higher returns than savings accounts and
              fixed deposits but also pose higher risks.
            </ArticleParagraph>
            <ArticleParagraph>
              You should understand your investment objectives, risk profile and time horizon when{' '}
              <ArticleHyperlink url="https://www.moneysense.gov.sg/investments/investment-portfolio/">
                putting together an investment portfolio
              </ArticleHyperlink>
              .
            </ArticleParagraph>
          </>
        }
      />

      <ArticleImageWithText
        image={<MoneyBagWithAShieldImage />}
        heading="Annuity plans"
        description={
          <ArticleParagraph>
            Insurance that provide guaranteed and/or non-guaranteed regular payouts for a fixed
            period of time or for as long as you live.
          </ArticleParagraph>
        }
      />

      <ArticleImageWithText
        image={<RedHdbFlatsImage />}
        heading="Housing monetisation options"
        description={
          <ArticleParagraph>
            Housing monetisation options such as moving to a 3-room or smaller HDB flat and
            qualifying for the{' '}
            <ArticleHyperlink url="https://www.hdb.gov.sg/residential/living-in-an-hdb-flat/for-our-seniors/monetising-your-flat-for-retirement/silver-housing-bonus">
              <b>Silver Housing Bonus (SHB)</b>
            </ArticleHyperlink>
            ,{' '}
            <ArticleHyperlink url="https://www.hdb.gov.sg/residential/renting-out-a-flat-bedroom">
              <b>renting out your property or spare bedroom(s)</b>
            </ArticleHyperlink>
            , selling the tail-end lease to HDB through the{' '}
            <ArticleHyperlink url="https://www.hdb.gov.sg/residential/living-in-an-hdb-flat/for-our-seniors/monetising-your-flat-for-retirement/lease-buyback-scheme">
              <b>Lease Buyback Scheme (LBS)</b>
            </ArticleHyperlink>{' '}
            or <b>considering suitable products available in the market</b> that can offer you
            either a lump sum or recurring income.
          </ArticleParagraph>
        }
      />

      <ArticleParagraph>
        While you prepare for your retirement needs, you should also consider your current needs and
        what changes you intend to make to your lifestyle down the road as you approach retirement.
      </ArticleParagraph>
    </>
  );
};

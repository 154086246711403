import { mockApimData } from './mockApimData';
import { mockAppData } from './mockAppData';
import { mockAppDataEmpty } from './mockAppDataEmpty';
import { mockFxRates } from './mockFxRates';
import { mockMyinfoData } from './mockMyinfoData';

const baseMockResponses = {
  myinfo: mockMyinfoData,
  apim: mockApimData,
  fxRates: mockFxRates,
};

export const mockData = {
  ...baseMockResponses,
  app: mockAppData,
};

export const mockDataEmpty = {
  ...baseMockResponses,
  app: mockAppDataEmpty,
};

import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Divider } from 'components/Common';
import { typeScale, colorScale, minWidth } from 'themes';

import { DesktopSearchBar } from './DesktopSearchBar';
import { Dropdown } from './Dropdown';
import { HamburgerMenu } from './HamburgerMenu';
import { NotificationBell } from './NotificationBell';
import { TabletSearchBar } from './TabletSearchBar';

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled(Link)`
  color: ${colorScale('grey', 90)};

  &:hover {
    color: ${colorScale('primary', 50)};
  }
`;

const linkStyles = css`
  color: ${colorScale('grey', 90)};
  font-size: ${typeScale(0)};
  font-weight: 500;
  margin: 8px;
  padding: 8px;

  &:focus,
  &:hover,
  &.active {
    color: ${colorScale('primary', 50)};
  }
`;

const StyledLogoutLink = styled.button`
  appearance: none;
  background: none;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;

  ${linkStyles}
`;

const StyledNavLink = styled(NavLink)`
  ${linkStyles};
  width: max-content;
  position: relative;

  &.active {
    font-weight: bold;
    ::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: ${colorScale('primary', 30)};
    }
  }
`;

const StyledLink = styled(Link)`
  ${linkStyles};
  padding: 12px 8px;
`;

const ExternalLink = styled.a`
  ${linkStyles};
`;

export const VerticalDivider = styled.hr`
  width: 0px;
  height: calc(100% - 2 * 16px);
  border: none;
  border-right: 1px solid ${colorScale('grey', 80)};
  margin: 0 12px;

  ${minWidth(
    'largeScreen',
    css`
      margin: 0 16px;
    `
  )}
`;

export const HorizontalDivider = styled(Divider)`
  width: 100%;
  align-self: center;
  margin: 16px 0;
`;

const SectionLabel = styled.div`
  text-transform: uppercase;
  font-size: ${typeScale(-2)};
  font-weight: 500;
  margin-top: 8px;
  padding: 12px 0;
  color: ${colorScale('grey', 80)};
`;

export class NavigationBarComponents {
  public static LinksWrapper = LinksWrapper;
  public static LogoWrapper = LogoWrapper;
  public static NavLink = StyledNavLink;
  public static Link = StyledLink;
  public static ExternalLink = ExternalLink;
  public static VerticalDivider = VerticalDivider;
  public static HorizontalDivider = HorizontalDivider;
  public static HamburgerMenu = HamburgerMenu;
  public static TabletSearchBar = TabletSearchBar;
  public static DesktopSearchBar = DesktopSearchBar;
  public static Dropdown = Dropdown;
  public static NotificationBell = NotificationBell;
  public static SectionLabel = SectionLabel;
  public static LogoutLink = StyledLogoutLink;
}

import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { colorScale } from 'themes';

const StyledDot = styled.button<{ selected: boolean }>`
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 0 3px;
  cursor: pointer;
  border: 1px solid ${colorScale('primary', 30)};
  border-radius: 50%;
  background: ${ifProp('selected', colorScale('primary', 30), colorScale('grey', 20))};

  appearance: none;

  :focus {
    outline: none;
  }
`;

interface IOwnProps {
  selected: boolean;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLButtonElement>;

export class Dot extends React.Component<IProps> {
  public shouldComponentUpdate(nextProps: IProps) {
    // Ensures a change in onClick does not cause a re-render
    return this.props.selected !== nextProps.selected;
  }

  public render() {
    return <StyledDot {...this.props} />;
  }
}

import Chart, { ChartTooltipItem, ChartData } from 'chart.js';
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { colorScale, theme } from 'themes';

import { CustomTooltip, customTooltipStyle } from '../CustomToolTip';

const ChartArea = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 32px 0;
  justify-self: center;
  max-width: 512px;
  width: 100%;
`;

const Canvas = styled.canvas`
  width: 100%;
`;

interface IProps {
  centerText?: string;
  chartData: {
    label: string;
    amount: number;
    color: { main: string };
    hoverText: string;
  }[];
}

export const DoughnutChart: React.FC<IProps> = ({ chartData, centerText = '', ...otherProps }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);

  const initialiseChartService = () => {
    Chart.pluginService.register({
      beforeDraw: function (chart: Chart) {
        const width = chart.chartArea.right;
        const height = chart.chartArea.bottom;
        const ctx = chart.ctx as CanvasRenderingContext2D;

        const chartConfigOptions = chart.config.options as Chart.ChartOptions;
        const text = (chartConfigOptions.title as Chart.ChartTitleOptions).text as string;

        ctx.restore();
        const fontSize = (height / Math.max(6, text.length) / 18).toFixed(2);
        ctx.font = `${fontSize}em ${theme.fonts.mainFontFamily}`;
        ctx.textBaseline = 'middle';
        ctx.fillStyle = colorScale('grey', 80)({ theme });

        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    });
  };

  const buildChart = () => {
    const ctx = chartRef.current?.getContext('2d') || null;

    if (ctx === null) {
      return;
    }

    const labels = chartData.map((x) => x.label);
    const amounts = chartData.map((x) => x.amount);
    const colors = chartData.map((x) => x.color.main);

    new Chart(ctx, {
      type: 'doughnut',
      options: {
        title: {
          display: false,
          text: centerText,
        },
        legend: { display: false },
        cutoutPercentage: 80,
        tooltips: {
          enabled: false,
          custom: CustomTooltip,
          callbacks: {
            label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
              const index = tooltipItem.index as number;
              const dataLabels = data.labels as string[];
              const category = dataLabels[index];

              return chartData.find(({ label }) => label === category)?.hoverText || '';
            },
          },
        },
      },
      data: {
        labels,
        datasets: [
          {
            data: amounts,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            borderWidth: 0,
          },
        ],
      },
    });
  };

  useEffect(() => {
    initialiseChartService();
  }, []);

  useEffect(() => {
    buildChart();
  });

  return (
    <ChartArea {...otherProps}>
      <style>{customTooltipStyle}</style>
      <Canvas ref={chartRef} aria-label="expense chart" role="img" />
    </ChartArea>
  );
};

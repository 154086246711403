import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { IEmergencyFundRatioInputs } from '../interfaces';

export const EF6: IRule<IEmergencyFundRatioInputs> = {
  condition: ({ emergencyFundMonths, isWorking }: IEmergencyFundRatioInputs) =>
    emergencyFundMonths < 3 && !isWorking,
  result: {
    key: RULE.EF6,
    indicatorStatus: indicatorStatuses.NEEDS_ATTENTION,
    recommendedActions: [],
    resources: [
      // Some links
    ],
  },
};

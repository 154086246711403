import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/Common';
import { IButtonProps } from 'components/Common/Button/Button';

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 16px 0 48px;
`;

const ContinueButton = styled(Button)`
  margin-left: 16px;
`;

interface IProps {
  primaryButtonText: string;
  primaryOnClick: () => void;
  secondaryButton?: React.ReactElement<IButtonProps>;
}

export const SingleContentPageCTAPanel: React.FC<IProps> = ({
  primaryButtonText,
  primaryOnClick,
  secondaryButton,
}) => {
  return (
    <ButtonWrapper>
      {secondaryButton}
      <ContinueButton widthStyle="fluid" onClick={primaryOnClick}>
        {primaryButtonText}
      </ContinueButton>
    </ButtonWrapper>
  );
};

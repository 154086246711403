import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { ISavingsRatioInputs } from '../interfaces';

export const SR3: IRule<ISavingsRatioInputs> = {
  condition: ({ savingsRatio }: ISavingsRatioInputs) =>
    savingsRatio !== null && savingsRatio < 0.05,
  result: {
    key: RULE.SR3,
    indicatorStatus: indicatorStatuses.NEEDS_ATTENTION,
    recommendedActions: [recommendedActions.TRA_SR_E, recommendedActions.TRA_SR_A],
    resources: [
      // Some links
    ],
  },
};

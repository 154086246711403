import { HOUSING } from 'constants/urls';

import { FLAT_SIZE, FLAT_TYPE, HOUSEHOLD_TYPE, LOAN_TYPE, PAYMENT_METHOD } from './enums';

export const INITIAL_DRAFT_HOUSING_PLAN_ID = 'draft-housing-plan';

export const FINANCING_PLAN_TOOL_TOTAL_NUM_PAGES = Object.keys(HOUSING.CUSTOMPLAN).filter((route) =>
  route.startsWith('step')
).length;

export const paymentOptions = {
  [PAYMENT_METHOD.CASH]: { label: 'Cash', value: PAYMENT_METHOD.CASH },
  [PAYMENT_METHOD.CPF]: { label: 'CPF', value: PAYMENT_METHOD.CPF },
};

export const flatTypeLabels = {
  [FLAT_TYPE.NEW]: 'New flat from HDB',
  [FLAT_TYPE.RESALE]: 'Resale flat',
};

export const flatSizeLabels = {
  [FLAT_SIZE.TWO_ROOM]: '2-room',
  [FLAT_SIZE.THREE_ROOM]: '3-room',
  [FLAT_SIZE.FOUR_ROOM]: '4-room',
  [FLAT_SIZE.FIVE_ROOM]: '5-room',
  [FLAT_SIZE.EXECUTIVE]: 'Executive',
};

export const newFlatAndSingleFlatSizeOptions = [
  {
    label: flatSizeLabels[FLAT_SIZE.TWO_ROOM],
    value: FLAT_SIZE.TWO_ROOM,
  },
];

export const allFlatSizeOptions = [
  {
    label: flatSizeLabels[FLAT_SIZE.TWO_ROOM],
    value: FLAT_SIZE.TWO_ROOM,
  },
  {
    label: flatSizeLabels[FLAT_SIZE.THREE_ROOM],
    value: FLAT_SIZE.THREE_ROOM,
  },
  {
    label: flatSizeLabels[FLAT_SIZE.FOUR_ROOM],
    value: FLAT_SIZE.FOUR_ROOM,
  },
  {
    label: flatSizeLabels[FLAT_SIZE.FIVE_ROOM],
    value: FLAT_SIZE.FIVE_ROOM,
  },
  {
    label: flatSizeLabels[FLAT_SIZE.EXECUTIVE],
    value: FLAT_SIZE.EXECUTIVE,
  },
];

export const loanTypeLabels = {
  [LOAN_TYPE.HDB]: 'HDB housing loan',
  [LOAN_TYPE.BANK]: 'Bank housing loan',
};

export const householdTypeLabels = {
  [HOUSEHOLD_TYPE.FAMILY]: 'Family',
  [HOUSEHOLD_TYPE.SINGLE]: 'Single',
};

export const housingPlanLabels: { [key: string]: { main: string; sub?: string } } = {
  applicationFee: { main: 'Application fee' },
  optionFee: { main: 'Option fee' },
  propertyAgentCommission: { main: 'Property agent’s commission' },
  requestForValueOfFlat: { main: 'Request for value of flat' },
  cashOverValuation: { main: 'Cash over valuation' },
  buyerStampDuty: { main: 'Buyer stamp duty' },
  conveyancingFee: { main: 'Conveyancing fee', sub: 'Inclusive of GST' },
  registrationFee: { main: 'Registration fee', sub: 'Inclusive of GST' },
  additionalBuyerStampDuty: { main: 'Additional buyer stamp duty' },
  caveatRegistrationFee: { main: 'Caveat registration fee', sub: 'Inclusive of GST' },
  titleSearchFee: { main: 'Title search fee' },
  upfrontPayment: { main: 'Upfront payment' },
  upfrontPaymentInCash: { main: 'In cash' },
  upfrontPaymentInCPF: { main: 'In CPF', sub: 'Inclusive of grants' },
  renovationCost: { main: 'Renovation' },
  surveyFee: { main: 'Survey fee', sub: 'Inclusive of GST' },
  leaseInEscrowFee: { main: 'Lease in-escrow registration fee' },
  mortgageInEscrowFee: { main: 'Mortgage in-escrow registration fee' },
  stampDutyDeedFee: { main: 'Stamp duty on deed of assignment' },
};

import React from 'react';

import { ICTAPanelProps } from 'components/Composite/ModuleContent/interfaces';
import { TARGETS } from 'constants/urls';

import { AllocateSavingsContent } from './AllocateSavingsContent';

interface ILocalProps {
  nextRoute?: string;
}

type IProps = ILocalProps & Pick<ICTAPanelProps, 'backRoute' | 'continueBtnText'>;

export const AllocateSavingsPage = ({
  backRoute = TARGETS.SETUP.step2,
  nextRoute = TARGETS.SETUP.completed,
  continueBtnText,
}: IProps) => {
  return (
    <AllocateSavingsContent
      backRoute={backRoute}
      nextRoute={nextRoute}
      continueBtnText={continueBtnText}
      title={'Allocate your total savings to your targets'}
      subtitle={''}
    />
  );
};

export default AllocateSavingsPage;

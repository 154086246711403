import React from 'react';
import styled from 'styled-components';

import { Heading } from 'components/Common';
import { LoginWithSingpassButton } from 'components/Composite';
import { typography } from 'themes';

import { SectionWrapper, StyledContainer } from '../sharedStyles';

const Wrapper = styled(SectionWrapper)`
  background: white;
`;

const Content = styled(StyledContainer)`
  align-items: center;
  margin: 48px 0;
`;

const StyledHeading = styled(Heading)`
  ${typography('heading-m')};
  text-align: center;
  margin-bottom: 24px;
`;

export const CallToActionSection: React.FC = () => (
  <Wrapper>
    <Content>
      <StyledHeading>Manage your finances with confidence</StyledHeading>
      <LoginWithSingpassButton />
    </Content>
  </Wrapper>
);

export default CallToActionSection;

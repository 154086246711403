import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { maxWidth, minWidth } from 'themes';

const Container = styled.div`
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: ${fromTheme('layers.notification')};
  top: 0;
  right: 0;

  ${maxWidth(
    'mobile',
    css`
      left: 0;
    `
  )}

  ${minWidth(
    'tablet',
    css`
      max-width: 400px;
    `
  )}
`;

export class ToastContainer extends React.PureComponent {
  public render() {
    return (
      <Container>
        <TransitionGroup component={null}>{this.props.children}</TransitionGroup>
      </Container>
    );
  }
}

import React from 'react';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

export type IOrderedListVariant = 'roman' | 'alpha' | 'decimal';

const StyledOl = styled.ol<{ variant?: IOrderedListVariant }>`
  ${switchProp('variant', {
    roman: css`
      list-style-type: lower-roman;
    `,
    alpha: css`
      list-style-type: lower-alpha;
    `,
    decimal: css`
      list-style-type: decimal;
    `,
  })};

  padding-inline-start: 16px;
  > li {
    padding-left: 12px;
    font-weight: bold;
  }
`;

export interface IOrderedListProps {
  variant?: IOrderedListVariant;
  children: React.ReactChild | React.ReactChild[];
}

export const OrderedList = ({
  variant = 'decimal',
  children,
  ...otherProps
}: IOrderedListProps & Omit<React.HTMLAttributes<HTMLOListElement>, 'children'>) => (
  <StyledOl variant={variant} {...otherProps}>
    {Array.isArray(children) ? (
      children.map((item: React.ReactNode, index: number) => <li key={index}>{item}</li>)
    ) : (
      <li>{children}</li>
    )}
  </StyledOl>
);

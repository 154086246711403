import styled from 'styled-components';

import { typography, colorScale } from 'themes';

export const Table = styled.table`
  border-spacing: 0;
  border: 1px solid ${colorScale('primary', 20)};
  border-radius: 6px;
`;

export const TableHeader = styled.th`
  background: ${colorScale('primary', 50)};
  color: white;
  ${typography('body-l-bold')}
  border-bottom: 1px solid white;
  text-align: left;
  padding: 16px 24px;
  vertical-align: top;
  :first-child {
    border-top-left-radius: 6px;
  }
  :last-child {
    border-top-right-radius: 6px;
  }
`;

export const TableSubHeader = styled(TableHeader)`
  ${typography('body-s-bold')}
`;

export const TableRow = styled.tr`
  :last-child {
    td {
      border-bottom: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
`;

export const TableData = styled.td`
  margin: 0;
  padding: 16px 24px;
  border-bottom: 1px solid ${colorScale('primary', 20)};
  color: ${colorScale('grey', 90)};
  ${typography('body-l')}
  vertical-align: top;

  :nth-of-type(odd) {
    background: ${colorScale('primary', 10)};
  }

  :nth-of-type(even) {
    background: white;
  }
`;

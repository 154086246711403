import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getAvailableFunds } from 'redux/finances/selectors';

import { InfoButton, Panel } from 'components/Common';
import { typography, colorScale } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';

const StyledPanelWrapper = styled(Panel)`
  grid-area: available-funds;
  padding: 0;

  & > div:not(:last-child) {
    border-bottom: 1px solid ${colorScale('grey', 20)};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
const StyledTitlePanel = styled(Panel)`
  box-shadow: none;
  padding: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  ${typography('heading-s')}
  margin: 0;
`;

const AmountText = styled.span`
  ${typography('numbers-l')}
`;

interface IProps {
  setModalOpened: (modalName: 'info' | null) => void;
}

export const AvailableFundsCard: React.FC<IProps & React.HTMLAttributes<HTMLDivElement>> = ({
  setModalOpened,
  ...otherProps
}) => {
  const availableFunds = useSelector(getAvailableFunds);

  return (
    <StyledPanelWrapper data-testid="avail-funds-card" {...otherProps}>
      <StyledTitlePanel>
        <TitleWrapper>
          <Title>Available funds</Title>
          <InfoButton onClick={() => setModalOpened('info')} />
        </TitleWrapper>
        <AmountText>{formatCurrency(availableFunds)}</AmountText>
      </StyledTitlePanel>
    </StyledPanelWrapper>
  );
};

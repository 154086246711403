import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { MagnifyingGlass } from 'assets/icons';
import { colorScale, typography } from 'themes';

import { SearchWrapperWithRouter } from './SearchWrapper';

const Wrapper = styled.div`
  padding-bottom: 16px;
`;

const MagnifyingGlassIcon = styled(MagnifyingGlass)`
  color: ${colorScale('grey', 80)};
`;

const SearchPanel = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid ${colorScale('grey', 50)};
  color: ${colorScale('grey', 90)};
  gap: 8px;
`;

const PlaceholderText = styled.p`
  color: ${colorScale('grey', 50)};
  ${typography('body-l')}
  margin: 0;
`;

interface IProps {
  closeMenu?: () => void;
}

export const TabletSearchBar = ({ closeMenu }: IProps) => {
  const history = useHistory();

  const handleOnClick = () => {
    closeMenu && closeMenu();
    history.push('/search');
  };

  return (
    <SearchWrapperWithRouter
      renderSearchComponent={() => (
        <Wrapper>
          <SearchPanel onClick={handleOnClick}>
            <MagnifyingGlassIcon />
            <PlaceholderText>What are you looking for?</PlaceholderText>
          </SearchPanel>
        </Wrapper>
      )}
    />
  );
};

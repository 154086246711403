import { Omit } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, switchProp, theme as fromTheme } from 'styled-tools';

import { minWidth, colorScale, shadowScale } from 'themes';
import { visuallyHiddenProperties } from 'utilities/sharedStyles';

import { uniqueIdGenerator } from '../uniqueIdGenerator/uniqueIdGenerator';

const HiddenRadioInput = styled.input`
  ${visuallyHiddenProperties}
`;

interface IDefaultRadioCheckmarkProps {
  checked: boolean;
  disabled?: boolean;
  error?: boolean;
}

const DefaultRadioCheckmark = styled.span<IDefaultRadioCheckmarkProps>`
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  background: white;
  border: 1px solid ${colorScale('grey', 80)};
  border-radius: 50%;
  cursor: pointer;
  color: ${colorScale('primary', 50)};
  margin-right: 0.7em;

  ${ifProp(
    'checked',
    css`
      ::before {
        width: 100%;
        height: 100%;
        content: '✓';
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `
  )}

  ${ifProp(
    'disabled',
    css`
      border-color: ${colorScale('grey', 20)};
      cursor: not-allowed;
    `
  )}

  ${ifProp(
    'error',
    css`
      border: 1px solid ${colorScale('supporting-red', 50)};
      color: ${colorScale('supporting-red', 50)};
    `
  )}
`;

const CheckedCircle = styled.div<{ checked: boolean; error?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${colorScale('grey', 15)};
  border: 2px solid ${colorScale('primary', 30)};
  margin-right: 16px;
  flex-shrink: 0;

  ${ifProp(
    'disabled',
    css`
      border: 2px solid ${colorScale('grey', 50)};
    `
  )}


  ${ifProp(
    'checked',
    css`
      ::before {
        display: flex;
        justify-content: center;
        align-items: center;
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${colorScale('primary', 30)};
      }
    `
  )}

  ${ifProp(
    'error',
    css`
      border: 2px solid ${colorScale('supporting-red', 50)};
      ::before {
        background: ${colorScale('supporting-red', 50)};
      }
    `
  )}

  ${minWidth(
    'tablet',
    css`
      margin-right: 24px;
    `
  )}
`;

const styledProperties = css`
  position: relative;
  background: white;
  border-radius: ${fromTheme('borders.radiusLarge')};
  padding: 24px;
  justify-content: flex-start;
  box-shadow: ${shadowScale(1)};

  ${ifProp(
    'checked',
    css`
      background: ${colorScale('primary', 10)};

      ${ifProp(
        'error',
        css`
          background: ${colorScale('supporting-red', 10)};
        `
      )}
    `
  )};

  ${minWidth(
    'tablet',
    css`
      padding: 22px 16px;
    `
  )}
`;

interface IRadioLabelProps {
  disabled?: boolean;
  checked: boolean;
  variant: 'default' | 'styled' | 'custom';
  error?: boolean;
}

const RadioLabel = styled.label<IRadioLabelProps>`
  ${ifNotProp(
    { variant: 'custom' },
    css`
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      ${ifProp({ variant: 'styled' }, styledProperties)};

      ${ifProp(
        'disabled',
        css`
          cursor: not-allowed;
          color: ${colorScale('grey', 50)};
          border-color: ${colorScale('grey', 20)};
        `,
        css`
          ${switchProp('variant', {
            styled: css`
              &:hover,
              &:focus,
              &:focus-within {
                outline: ${colorScale('primary', 20)} auto 1px;
                border-color: ${colorScale('primary', 20)};
                box-shadow: ${shadowScale(1, colorScale('primary', 40))};
                z-index: 1;
              }
            `,
            default: css`
              &:hover,
              &:focus,
              &:focus-within {
                span {
                  box-shadow: ${shadowScale(2)};
                  z-index: 1;
                }
              }
            `,
          })};
        `
      )}
    `
  )}
`;

type IRadioValueTypes = string | number | string[] | undefined;
interface IOwnProps {
  /** To ensure that value is always provided as a prop */
  value: IRadioValueTypes;

  /** Defines the look of the radio label */
  variant: 'default' | 'styled' | 'custom';

  /** Text or custom component in label */
  label: string | React.ReactElement;

  /** Parent RadioGroup value */
  selectedValue?: IRadioValueTypes;

  error?: boolean;
}

export type IRadioProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id'> & IOwnProps;

export class Radio extends React.Component<IRadioProps> {
  public static defaultProps = {
    variant: 'default',
  };

  private static readonly idGenerator = uniqueIdGenerator('Radio');

  private readonly id: string;

  constructor(props: IRadioProps) {
    super(props);

    this.id = Radio.idGenerator.next().value as string;
  }

  public render() {
    const {
      variant,
      label,
      name,
      className,
      value,
      disabled,
      selectedValue,
      onBlur,
      onFocus,
      error,
      ...otherProps
    } = this.props;
    const checked = value === selectedValue;

    return (
      <RadioLabel
        htmlFor={this.id}
        variant={variant}
        disabled={disabled}
        className={className}
        checked={checked}
        error={error}
      >
        <HiddenRadioInput
          onBlur={onBlur}
          onFocus={onFocus}
          type="radio"
          id={this.id}
          value={value}
          disabled={disabled}
          name={name}
          checked={checked}
          {...otherProps}
        />
        {variant === 'default' && (
          <DefaultRadioCheckmark checked={checked} disabled={disabled} error={error} />
        )}
        {variant === 'styled' && (
          <CheckedCircle checked={checked} disabled={disabled} error={error} />
        )}
        {variant !== 'custom' && label}
        {variant === 'custom' &&
          typeof label !== 'string' &&
          React.cloneElement(label, { disabled, checked })}
      </RadioLabel>
    );
  }
}

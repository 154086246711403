import React from 'react';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { Section, Heading } from 'components/Common';
import { typography, colorScale } from 'themes';

import { NUMBERING_CIRCLE_SIZE_PX, GAP_BETWEEN_NUMBERING_AND_TITLE_PX } from './constants';

const SUBSECTION_CIRCLE_SIZE_PX = '16px';

// minmax(0, 1fr) is for preventing content from leaking out of the housing accordion
// https://css-tricks.com/preventing-a-grid-blowout/
const StyledSection = styled(Section)`
  display: grid;
  grid-template-columns: ${NUMBERING_CIRCLE_SIZE_PX} minmax(0, 1fr);
  grid-gap: ${GAP_BETWEEN_NUMBERING_AND_TITLE_PX};
  margin-bottom: 32px;
`;

const SubsectionCircle = styled.div`
  background: ${colorScale('primary', 50)};
  border-radius: ${fromTheme('borders.radiusRound')};
  width: ${SUBSECTION_CIRCLE_SIZE_PX};
  height: ${SUBSECTION_CIRCLE_SIZE_PX};
  margin-top: 2px;
  justify-self: center;
  z-index: 1;
`;

const Title = styled(Heading)`
  ${typography('heading-s')}
`;

const Subtitle = styled.p`
  ${typography('body-s-italic')}
  color: ${colorScale('grey', 80)};
  margin-top: 4px;
`;

const Content = styled.div`
  overflow-x: scroll; // Fallback for when content exceeds content width
`;

interface IProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}

/**
 * Displays a section under an housing accordion section, completed with a circular
 * indicator at the start of the section.
 * @param title
 * @param [subtitle] Optional subtext that is shown under title
 * @param children Content to display
 */
export const HousingAccordionSubsection = ({ title, subtitle, children }: IProps) => {
  return (
    <StyledSection>
      <SubsectionCircle />
      <div>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Content>{children}</Content>
      </div>
    </StyledSection>
  );
};

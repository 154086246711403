import { diff } from 'deep-object-diff';
import { omit } from 'lodash';
import { has } from 'object-path';
import { Store } from 'redux';

import { getHasToken } from 'redux/dataStorage/selectors';

import { IAllActions } from 'constants/actionTypes';
import { IStore } from 'constants/storeTypes';

import { ISubscription, ISubscriptionCallback } from './interfaces';

export class SubscriptionManager {
  private static _instance: SubscriptionManager;
  private static _subscriptions: { [stateKey: string]: ISubscriptionCallback[] };
  private static _prevState: IStore;

  constructor() {
    if (!SubscriptionManager._instance) {
      SubscriptionManager._instance = this;
      SubscriptionManager._subscriptions = {};
    }

    return SubscriptionManager._instance;
  }

  init(store: Store<IStore, IAllActions>) {
    SubscriptionManager._prevState = store.getState();
    return store.subscribe(() => {
      const newState = store.getState();
      if (getHasToken(newState)) {
        this._notifySubscribers(newState);
      }
      SubscriptionManager._prevState = newState;
    });
  }

  private _notifySubscribers(newState: IStore) {
    const prevState = SubscriptionManager._prevState;
    const delta = diff(prevState, newState);

    Object.entries(SubscriptionManager._subscriptions).forEach(([stateKey, callbacks]) => {
      const isSubscribedToDelta = has(delta, stateKey);
      if (isSubscribedToDelta) {
        callbacks.forEach((callback) => callback(prevState, newState));
      }
    });
  }

  /**
   * @param stateKey Path to the redux data
   *
   *                 Recommended to keep it specific and on an indentifier that is less likely to change.
   *                 Although possible, it is not recommended to subscribe to an index of an array as the
   *                 behavior becomes unpredictable if the array gets sorted.
   *
   *                 Examples of what you can subscribe to:
   *                 (1) Root level (e.g. 'profile') (Not as recommended)
   *                 (2) Nested objects (e.g. 'profile.name', 'targets.emergencyFund')
   * @param callback Function to invoke whenever the redux data @stateKey gets updated
   */
  private _subscribe(stateKey: string, callback: ISubscriptionCallback): () => void {
    if (stateKey in SubscriptionManager._subscriptions) {
      SubscriptionManager._subscriptions[stateKey].push(callback);
    } else {
      SubscriptionManager._subscriptions[stateKey] = [callback];
    }

    const unsubscribe = () => {
      const callbacks = SubscriptionManager._subscriptions[stateKey];
      SubscriptionManager._subscriptions[stateKey] = callbacks.filter(
        (subcriptionCallback) => subcriptionCallback !== callback
      );

      const hasNoSubscriptionsToStateKey =
        SubscriptionManager._subscriptions[stateKey].length === 0;
      if (hasNoSubscriptionsToStateKey) {
        SubscriptionManager._subscriptions = omit(SubscriptionManager._subscriptions, stateKey);
      }
    };

    return unsubscribe;
  }

  subscribe(subscriptions: ISubscription[]) {
    const unsubscribeFunctions = subscriptions.flatMap(({ stateKeys, callback }) => {
      const unsubscribeFunctions = stateKeys.map((stateKey) => this._subscribe(stateKey, callback));
      return unsubscribeFunctions;
    });

    const unsubscribeAll = () => unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());

    return unsubscribeAll;
  }

  getSubscriptions() {
    return SubscriptionManager._subscriptions;
  }

  clearSubscriptions() {
    SubscriptionManager._subscriptions = {};
  }
}

export const subscriptionManager = new SubscriptionManager();

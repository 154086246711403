import { some, isEmpty } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

import { Heading } from 'components/Common';
import { minWidth, colorScale, typography, PAGE_SIDE_SPACING } from 'themes';

import { SEA_SURFACE_ID } from '../Backgrounds';
import { COMPONENT_GAP_PX } from '../constants';
import { IPageSideSpacing } from '../interfaces';
import { TemplateWrapper, TwinsTemplateContainer, FooterContent } from '../sharedStyles';

const Header = styled.div<{ variant: ITwinsTemplateVariant }>`
  ${switchProp('variant', {
    yinyang: css`
      background: ${colorScale('grey', 0)};
      padding-bottom: ${COMPONENT_GAP_PX};
    `,
  })}
`;

const HeaderContainer = styled(TwinsTemplateContainer)`
  > div:not(:last-child) {
    margin-bottom: ${COMPONENT_GAP_PX};
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  ${minWidth(
    'desktop',
    css`
      > *:not(:last-child) {
        margin-right: 16px;
      }
    `
  )}
`;

const StyledHeading = styled(Heading)`
  ${typography('heading-l')}
`;

const HeadingBefore = styled.div``;
const HeadingAfter = styled.div``;

const Description = styled.div`
  ${typography('body-l')}
  margin: 0;
`;

const DescriptionBefore = styled.div``;
const DescriptionAfter = styled.div`
  display: inline-block;
`;

const AdditionalHeaderContent = styled.div``;

const AdditionalContent = styled.div``;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${COMPONENT_GAP_PX};

  ${minWidth(
    'desktop',
    css`
      grid-template-columns: 1fr 1fr;
    `
  )}
`;

const SeaSurface = styled.span``;

/**
 * Variants
 * @type default  Default background color for the entire page
 * @type yinyang  White background for header, default background color for the rest of the page
 */
export type ITwinsTemplateVariant = 'default' | 'yinyang';

export interface ITwinsTemplatesProps {
  variant?: ITwinsTemplateVariant;
  background?: React.ReactElement;

  heading?: string;
  headingBefore?: React.ReactChild;
  headingAfter?: React.ReactChild;

  description?: string;
  descriptionBefore?: React.ReactChild;
  descriptionAfter?: React.ReactChild;

  additionalHeaderContent?: React.ReactChild;
  additionalContent?: React.ReactChild;

  contentLeft: React.ReactChild;
  contentRight: React.ReactChild;

  additionalFooterContent?: React.ReactChild;
  flushFooterToEdges?: boolean;

  pageSideSpacing?: IPageSideSpacing;
  pageHasBackButton?: boolean;
}

/**
 * Display content in two equal columns which collapses into a single column on smaller screens.
 *
 * @param [variant] Determines the theme and color of the page
 * @param [background] A component from Templates/Backgrounds
 *
 * @param [heading] Page heading
 * @param [headingBefore] An element that will display before the heading
 * @param [headingAfter] An element that will display after the heading
 *
 * @param [description] Page description, renders below heading
 * @param [descriptionBefore] An element that will display before the description
 * @param [descriptionAfter] An element that will display before the description
 *
 * Toggle the variant to yinyang to see the difference in placement
 * between additionalHeaderContent and additionalContent
 * @param [additionalHeaderContent] Renders below description,
 *                                  unaffected by the two column layout
 * @param [additionalContent] Renders below additionalHeaderContent,
 *                            unaffected by the two column layout
 *
 * @param contentLeft Renders on the left column on desktop, on top of contentRight on mobile
 * @param contentRight Renders on the right column on desktop, below of contentLeft on mobile
 *
 * @param [additionalFooterContent] Useful for putting call to action buttons
 * @param [flushFooterToEdges] Determines if the footer area should flush and
 *                             touch the left right & bottom edge of the screen
 *
 * @param [pageSideSpacing] Determines the left right spacing of the content to the
 *                          edge of the screen
 * @param [pageHasBackButton] Set to true if Composite/BackButton is used with this template
 *                            to ensure the button doesn't overlap with the content
 */
export const TwinsTemplate = ({
  variant = 'default',
  background,
  heading,
  headingBefore,
  headingAfter,
  description,
  descriptionBefore,
  descriptionAfter,
  additionalHeaderContent,
  additionalContent,
  contentLeft,
  contentRight,
  additionalFooterContent,
  flushFooterToEdges = false,
  pageSideSpacing = PAGE_SIDE_SPACING.DEFAULT_PX,
  pageHasBackButton = false,
  ...otherProps
}: ITwinsTemplatesProps) => {
  return (
    <TemplateWrapper pageHasBackButton={pageHasBackButton} {...otherProps}>
      {some([heading, description, additionalHeaderContent], (ele) => !isEmpty(ele)) && (
        <Header variant={variant}>
          <HeaderContainer>
            {heading && (
              <HeadingWrapper>
                {headingBefore && <HeadingBefore>{headingBefore}</HeadingBefore>}
                <StyledHeading>{heading}</StyledHeading>
                {headingAfter && <HeadingAfter>{headingAfter}</HeadingAfter>}
              </HeadingWrapper>
            )}

            {description && (
              <HeadingWrapper>
                {descriptionBefore && <DescriptionBefore>{descriptionBefore}</DescriptionBefore>}
                <Description>
                  {description}&nbsp;
                  {descriptionAfter && <DescriptionAfter>{descriptionAfter}</DescriptionAfter>}
                </Description>
              </HeadingWrapper>
            )}

            {additionalHeaderContent && (
              <AdditionalHeaderContent>{additionalHeaderContent}</AdditionalHeaderContent>
            )}
          </HeaderContainer>
        </Header>
      )}

      <SeaSurface id={SEA_SURFACE_ID} />

      {background}

      {additionalContent && (
        <TwinsTemplateContainer pageSideSpacing={pageSideSpacing}>
          <AdditionalContent>{additionalContent}</AdditionalContent>
        </TwinsTemplateContainer>
      )}

      <TwinsTemplateContainer pageSideSpacing={pageSideSpacing}>
        <Content>
          <div>{contentLeft}</div>
          <div>{contentRight}</div>
        </Content>
      </TwinsTemplateContainer>

      {additionalFooterContent &&
        (flushFooterToEdges ? (
          <FooterContent isFlush={flushFooterToEdges}>{additionalFooterContent}</FooterContent>
        ) : (
          <TwinsTemplateContainer pageSideSpacing={pageSideSpacing}>
            <FooterContent>{additionalFooterContent}</FooterContent>
          </TwinsTemplateContainer>
        ))}
    </TemplateWrapper>
  );
};

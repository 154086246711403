import React from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { ArrowLeft } from 'assets/icons';
import { typeScale, maxWidth, minWidth, typography } from 'themes';

import { ProgressBar, IProgressBarProps } from '..';
import { Button } from '../Button/Button';
import { Container } from '../Container/Container';

import { Title, ProgressBarWrapper } from './sharedStyles';

const Wrapper = styled(Container)`
  grid-area: module-header;
  z-index: ${fromTheme('layers.moduleHeader')};

  ${maxWidth(
    'tablet',
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 32px 0;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-areas: 'home-button main';
      grid-column-gap: 16px;
      justify-items: flex-start;
      align-items: center;
      margin: 64px 0 32px 0;
    `
  )}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${maxWidth(
    'tablet',
    css`
      align-items: center;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      grid-area: main;
    `
  )}
`;

const HomeButton = styled(Button)`
  font-size: ${typeScale(1)};
  padding: 0;

  ${maxWidth(
    'tablet',
    css`
      align-self: flex-start;
      margin: 16px 0 8px;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      margin: 0;
    `
  )}
`;

const HomeText = styled.div`
  margin-left: 8px;
  ${typography('body-m-bold')}
`;

interface IProps {
  /** Title text */
  title: string;

  /** Progress bar values */
  progressValues?: IProgressBarProps;

  backButtonText?: string;
  backButtonRoute: string;

  className?: string;
}

export const ModuleHeaderWithBackButton = ({
  title,
  progressValues,
  backButtonText = 'Home',
  backButtonRoute,
  className,
}: IProps) => {
  const history = useHistory();

  return (
    <Wrapper className={className}>
      <HomeButton variant="tertiary" onClick={() => history.push(backButtonRoute)}>
        <ArrowLeft />
        <HomeText>{backButtonText}</HomeText>
      </HomeButton>
      <InnerWrapper>
        <Title>{title}</Title>
        {progressValues && (
          <ProgressBarWrapper>
            <ProgressBar current={progressValues.current} total={progressValues.total} />
          </ProgressBarWrapper>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

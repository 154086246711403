interface IValidationParams {
  name: string;
  firstValue: number | null;
  secondValue: number | null;
  inclusive?: boolean;
  customErrorMsg?: string;
}

export function lessThanField({
  name,
  firstValue,
  secondValue,
  inclusive = true,
  customErrorMsg,
}: IValidationParams) {
  const nonInclusiveErrMsg = customErrorMsg || `Value must be less than ${name}`;
  const inclusiveErrMsg = customErrorMsg || `Value must be less or equal to ${name}`;

  if (firstValue === null || secondValue === null) {
    return undefined;
  }

  if (inclusive) {
    return firstValue <= secondValue ? undefined : inclusiveErrMsg;
  } else {
    return firstValue < secondValue ? undefined : nonInclusiveErrMsg;
  }
}

import React, { useState } from 'react';

import { IInputProps, Input } from '../Input/Input';

interface IOwnProps {
  date: Date;
  onInputChange: (date: Date) => void;
  dateModifier: (date: Date, value: number) => Date;
  valueGetter: (date: Date) => number;
  fieldLength: number;
  focusNext?: () => void;
  shouldFocusNext?: (value: string) => boolean;
}

export const InputField: React.FC<IOwnProps & IInputProps> = ({
  date,
  onInputChange,
  dateModifier,
  valueGetter,
  fieldLength,
  focusNext,
  shouldFocusNext,
  inputRef,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  const value = valueGetter(date);

  const [shouldFormatValue, setShouldFormatValue] = useState(true);
  const [tempValue, setTempValue] = useState('');
  const [initialValue, setInitialValue] = useState(value);

  const handleInputChange = (value: string) => {
    if (shouldRejectValue(value)) {
      return;
    }

    setTempValue(value);

    if (getIsValidValue(value)) {
      onInputChange(dateModifier(date, Number(value)));

      if (shouldFocusNext && shouldFocusNext(value)) {
        focusNext && focusNext();
      }
    } else {
      onInputChange(dateModifier(date, initialValue));
    }
  };

  const shouldRejectValue = (value: string) => isNaN(Number(value)) || value.length > fieldLength;

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(e);
    setTempValue(`${value}`);
    setInitialValue(value);
    setShouldFormatValue(false);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e);
    setTempValue('');
    setShouldFormatValue(true);
  };

  const getIsValidValue = (value: string) => {
    const numberValue = Number(value);
    const newDate = dateModifier(date, numberValue);

    const numberOfDifferences = [
      newDate.getDate() !== date.getDate(),
      newDate.getMonth() !== date.getMonth(),
      newDate.getFullYear() !== date.getFullYear(),
    ].filter((e) => e).length;

    return valueGetter(newDate) !== valueGetter(date)
      ? numberOfDifferences === 1
      : numberOfDifferences === 0;
  };

  const inputDisplayValue = shouldFormatValue ? `${value}`.padStart(fieldLength, '0') : tempValue;

  return (
    <Input
      handleChange={handleInputChange}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      value={inputDisplayValue}
      inputRef={inputRef}
      type="text"
      inputMode="numeric"
      autoComplete="off"
      minNumChars={4}
      {...otherProps}
    />
  );
};

import React from 'react';
import styled, { css } from 'styled-components';

import { Modal } from 'components/Common';
import { maxWidth, minWidth } from 'themes';

import { SetEmergencyFundModalTogglerContainer } from './SetEmergencyFundModalToggler';

const StyledModal = styled(Modal)`
  text-align: left;

  ${maxWidth(
    'mobile',
    css`
      width: 100vw;
    `
  )};

  ${minWidth(
    'tablet',
    css`
      width: 480px;
    `
  )};
`;

interface IOwnProps {
  mounted: boolean;
  onExit: (event?: React.SyntheticEvent) => void;
  addToast: (message: string) => void;
}

interface IState {
  shouldAllowModalCloseFunctions: boolean;
  hasDataChanged: boolean;
}

export class SetEmergencyFundModal extends React.Component<IOwnProps, IState> {
  constructor(props: IOwnProps) {
    super(props);

    this.state = {
      shouldAllowModalCloseFunctions: true,
      hasDataChanged: false,
    };

    this.allowModalCloseFunctions = this.allowModalCloseFunctions.bind(this);
    this.blockModalCloseFunctions = this.blockModalCloseFunctions.bind(this);
    this.handleExitAction = this.handleExitAction.bind(this);
    this.setHasDataChanged = this.setHasDataChanged.bind(this);
  }

  public allowModalCloseFunctions() {
    this.setState({
      shouldAllowModalCloseFunctions: true,
    });
  }

  public blockModalCloseFunctions() {
    this.setState({
      shouldAllowModalCloseFunctions: false,
    });
  }

  public handleExitAction() {
    if (this.state.hasDataChanged) {
      this.blockModalCloseFunctions();
    } else {
      this.props.onExit();
    }
  }

  public setHasDataChanged(setValue: boolean) {
    this.setState({ hasDataChanged: setValue });
  }

  public render() {
    const { mounted, onExit, addToast } = this.props;
    const { shouldAllowModalCloseFunctions } = this.state;

    return (
      <StyledModal
        mounted={mounted}
        underlayClickExits={shouldAllowModalCloseFunctions}
        showCloseButton={shouldAllowModalCloseFunctions}
        escapeExits={shouldAllowModalCloseFunctions}
        titleText="set-emergency-fund-modal"
        onExit={this.handleExitAction}
        alert={!shouldAllowModalCloseFunctions}
      >
        <SetEmergencyFundModalTogglerContainer
          onExit={onExit}
          showTarget={this.allowModalCloseFunctions}
          setHasDataChanged={this.setHasDataChanged}
          shouldShowWarningContent={!shouldAllowModalCloseFunctions}
          addToast={addToast}
        />
      </StyledModal>
    );
  }
}

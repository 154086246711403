import { omit } from 'lodash';
import { Reducer } from 'redux';

import { ITargetsActions } from 'constants/actionTypes';
import { TARGETS_ACTIONS } from 'constants/enums';
import { ITargetsStore } from 'constants/storeTypes';

import { mockInitialStates } from './../mockInitialStates';

export const initialEmergencyFund = {
  totalAmount: 0,
  currentAmount: 0,
  date: new Date().toISOString(),
  prevAccumulatedMonths: 0,
  initByStore: true,
};

export const initialState: ITargetsStore = {
  emergencyFund: initialEmergencyFund,
  goals: {},
};

export const targetsReducer: Reducer<ITargetsStore, ITargetsActions> = (
  state: ITargetsStore = process.env.REACT_APP_DEPLOYMENT_ENV &&
  ['development', 'staging'].includes(process.env.REACT_APP_DEPLOYMENT_ENV) &&
  mockInitialStates.targets !== null
    ? mockInitialStates.targets
    : initialState,
  action: ITargetsActions
) => {
  switch (action.type) {
    case TARGETS_ACTIONS.CREATE_GOAL: {
      return {
        ...state,
        goals: { ...state.goals, [action.goal.id]: { ...action.goal } },
      };
    }
    case TARGETS_ACTIONS.UPDATE_GOAL: {
      const goalInStore = state.goals[action.id];

      if (goalInStore === undefined) {
        return { ...state };
      }

      return {
        ...state,
        goals: {
          ...state.goals,
          [action.id]: {
            ...goalInStore,
            ...action.goal,
          },
        },
      };
    }

    case TARGETS_ACTIONS.DELETE_GOAL:
      return {
        ...state,
        goals: omit(state.goals, action.id),
      };

    case TARGETS_ACTIONS.SET_EMERGENCY_FUND:
      return {
        ...state,
        emergencyFund: {
          ...state.emergencyFund,
          ...action.details,
          initByStore: false,
        },
      };

    case TARGETS_ACTIONS.UPDATE_PREV_ACCUMULATED_MONTHS:
      return {
        ...state,
        emergencyFund: {
          ...state.emergencyFund,
          prevAccumulatedMonths: action.noOfMonths,
        },
      };

    default:
      return state;
  }
};

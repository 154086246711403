import { IApimResponse, IMyinfoResponse } from 'constants/responseTypes';

interface IMockApimData {
  data: {
    fpdata: IApimResponse;
    myinfo: IMyinfoResponse;
  };
}

const apimCurrMonth = '092020';
const apimPrevOneMonth = '082020';
const apimPrevTwoMonths = '072020';
const apimCdpBondFirstCouponDate = '2019-11-07';
const apimCdpBondMaturityDate = '2024-05-07';

export const mockApimData: IMockApimData = {
  data: {
    fpdata: {
      bankResponseStatuses: [
        {
          sp: 'DBS',
          statusCode: '0000',
        },
      ],
      casa: [
        {
          id: 'aad6abe55589623a41b714866b6ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX002-4-SINGLE-SGD',
          accountType: 'Savings',
          accountRelationship: 'Single',
          productName: 'POSB Passcard Savings (Single - SGD only)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '231434',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '211434',
              ccy: 'SGD',
              date: apimPrevOneMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '331434',
              ccy: 'SGD',
              date: apimPrevTwoMonths,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'aad6abe55589623a41b714866b6ca2f5238f483d',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX002-4-SINGLE-SGD-AGG',
          accountType: 'Savings',
          accountRelationship: 'Single',
          productName: 'POSB Passcard Savings (Single - SGD only - Aggregated)',
          percentageShare: 50,
          aggregateCount: 3,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '231434',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '128392',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '51521',
                  productNumbers: [0, 2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '171434',
              ccy: 'SGD',
              date: apimPrevOneMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '108392',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '31521',
                  productNumbers: [0, 2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '231434',
              ccy: 'SGD',
              date: apimPrevTwoMonths,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '128392',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '51521',
                  productNumbers: [0, 2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'ac9b91c2656acf0016a5c6cbdf0848ce5d8af61f',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXXXX-XX-3-SINGLE-USD',
          accountType: 'Current',
          accountRelationship: 'Single',
          productName: 'Foreign Currency Current Account (Single - USD only)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: 'e46e89d1-3d92-445e-ba8d-7b764c6940c0',
              amountType: 'balance',
              amount: '230010',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'ac9b91c2656acf0016a5c6cbdf0848ce5d8af620',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXXXX-XX-3-SINGLE-USD-AGG',
          accountType: 'Current',
          accountRelationship: 'Single',
          productName: 'Foreign Currency Current Account (Single - USD only - Aggregated)',
          percentageShare: 50,
          aggregateCount: 3,
          amounts: [
            {
              id: 'e46e89d1-3d92-445e-ba8d-7b764c6940c0',
              amountType: 'balance',
              amount: '230010',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '64162',
                  productNumbers: [1, 2],
                },

                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '101686',
                  productNumbers: [0],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '2c2dcb98a7f84629b782abb2398fe1976b40e5d9',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-8-XX3408-SINGLE-MC',
          accountType: 'Savings',
          accountRelationship: 'Single',
          productName: 'DBS Savings Plus (Single - Multi-currency)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab5',
              amountType: 'balance',
              amount: '231432',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab6',
              amountType: 'balance',
              amount: '231432',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab7',
              amountType: 'balance',
              amount: '231432',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '2c2dcb98a7f84629b782abb2398fe1976b40e5e1',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-8-XX3408-SINGLE-MC-AGG',
          accountType: 'Savings',
          accountRelationship: 'Single',
          productName: 'DBS Savings Plus (Single - Multi-currency - Aggregated)',
          percentageShare: 50,
          aggregateCount: 4,
          amounts: [
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbcd',
              amountType: 'balance',
              amount: '283066',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '28123',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afceaa',
                  amount: '87558',
                  productNumbers: [0, 2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afceaa',
                  amount: '79827',
                  productNumbers: [3],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbce',
              amountType: 'balance',
              amount: '231432',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '28322',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebe',
                  amount: '101555',
                  productNumbers: [0, 3],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbcf',
              amountType: 'balance',
              amount: '231432',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '231432',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '06b422799277fef4c40569ad91bb814958eb329d',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXXXX-XX-JOINT-SGD',
          accountType: 'Current',
          accountRelationship: 'Joint',
          productName: 'Foreign Currency Current Account (Joint - SGD only)',
          percentageShare: 72,
          aggregateCount: 1,
          amounts: [
            {
              id: '51b94aaa-8467-4445-b048-72a0b284a72a',
              amountType: 'balance',
              amount: '335111',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '06b422799277fef4c40569ad91bb814958eb329e',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXXXX-XX-JOINT-SGD-AGG',
          accountType: 'Current',
          accountRelationship: 'Joint',
          productName: 'Foreign Currency Current Account (Joint - SGD only - Aggregated)',
          percentageShare: 34,
          aggregateCount: 4,
          amounts: [
            {
              id: '51b94aaa-8467-4445-b048-72a0b284a72b',
              amountType: 'balance',
              amount: '335111',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '70424',
                  productNumbers: [0, 2, 3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '123839',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '22301e4dda4fea5dcbf837ab232c4c081df8fd31',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXXXX-XX-JOINT-USD',
          accountType: 'Current',
          accountRelationship: 'Joint',
          productName: 'Foreign Currency Current Account  (Joint - USD only)',
          percentageShare: 92,
          aggregateCount: 1,
          amounts: [
            {
              id: '22775c11-bea0-4800-9e86-45a5e3e2a94f',
              amountType: 'balance',
              amount: '440212',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '22301e2dea4fea5dcbf837ab232c4c081df8fd31',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXXXX-XX-JOINT-USD-AGG',
          accountType: 'Current',
          accountRelationship: 'Joint',
          productName: 'Foreign Currency Current Account  (Joint - USD only - Aggregated)',
          percentageShare: 51,
          aggregateCount: 3,
          amounts: [
            {
              id: '22775c11-bea0-4800-9e86-45a5e3e2a94f',
              amountType: 'balance',
              amount: '440212',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '238122',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afceab',
                  amount: '101045',
                  productNumbers: [0, 2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '8af1ec6cfb4f9ff87a0ad47592314e121e7a68e0',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-X6404-1',
          accountType: 'Current',
          accountRelationship: 'Joint-OR',
          productName: 'POSB Current Account (Joint - Multicurrency)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: '287e9b61-d625-4f8f-ae17-3903a86c1dfe',
              amountType: 'balance',
              amount: '220924',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '0448b727-2f80-444e-9136-40c6de7864cf',
              amountType: 'balance',
              amount: '218737',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '4813ffd8-bd55-428f-84ea-9d27488febda',
              amountType: 'balance',
              amount: '216571',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '8af1ec6cfb4f9ff87a0ad47595814e121e7a68e0',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-X6404-1-JOINT-MC-JAGG',
          accountType: 'Current',
          accountRelationship: 'Joint-OR',
          productName: 'POSB Current Account (Joint - Multicurrency - Aggregated)',
          percentageShare: 50,
          aggregateCount: 3,
          amounts: [
            {
              id: '287e9b61-d625-4f8f-ae17-3903a86c1dfe',
              amountType: 'balance',
              amount: '220924',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '110462',
                  productNumbers: [1, 2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '0448b727-2f80-444e-9136-40c6de7864cf',
              amountType: 'balance',
              amount: '218737',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '218737',
                  productNumbers: [0],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '4813ffd8-bd55-428f-84ea-9d27488febda',
              amountType: 'balance',
              amount: '216571',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '212212',
                  productNumbers: [0],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '4359',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
      ],
      cpfis: [
        {
          id: '4bd482220088ab0cc9a5ed14dec8ca18a00bad22',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XXXXXX-4-220',
          amounts: [
            {
              id: '13433751-d385-47f7-a77f-afe2b88d8ae1',
              amountType: 'balanceFD',
              amount: '459045',
              ccy: 'USD',
              date: apimCurrMonth,
              description: 'FCFD-DBS (USD)',
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '8b95e2d7-2c78-42a8-8b73-0707f4e29589',
              amountType: 'premiumPaid',
              amount: '153015',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: 'MANULIFE',
              aggregatedAmounts: [],
              surrenderValue: 1234,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '21f42f4d-f741-4a02-a95d-23c93af6ce17',
              amountType: 'balanceFM',
              amount: '306030',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: 'IFAST',
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'e9bcec85-f463-4aaa-9dce-def663b73712',
              amountType: 'balanceCash',
              amount: '228933',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: 'Cash',
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
          assetHoldings: [
            {
              id: '530f9a54-b780-4482-a93d-bd83421dfdee-assetHoldingId',
              holdingType: 'gold',
              holdingCode: 'GW0008',
              holdingCcy: 'SGD',
              holdingCodeType: 'productCode',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: '530f9a54-b780-4482-a93d-bd83421dfdee',
                  balancesQuantity: '1000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
            {
              id: 'ba359e79-b954-4681-aca8-feff8c36e234-assetHoldingId',
              holdingType: 'equity',
              holdingCode: 'SG1L01001701',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: 'ba359e79-b954-4681-aca8-feff8c36e234',
                  balancesQuantity: '300000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
            {
              id: '706d6c21-e56d-4627-966d-d4d98fbe5a76-assetHoldingId',
              holdingType: 'equity',
              holdingCode: 'SG1U25933169',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: '706d6c21-e56d-4627-966d-d4d98fbe5a76',
                  balancesQuantity: '100000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
            {
              id: '9f696763-e507-4f79-b879-2c31e2de535b-assetHoldingId',
              holdingType: 'bond',
              holdingCode: 'SG7Y76964295',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: '9f696763-e507-4f79-b879-2c31e2de535b',
                  balancesQuantity: '200000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
            {
              id: '6b305bdc-67ab-4ebf-ac03-b0b3d265e5b6-assetHoldingId',
              holdingType: 'unitTrust',
              holdingCode: 'SG9999004360',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: '6b305bdc-67ab-4ebf-ac03-b0b3d265e5b6',
                  balancesQuantity: '400000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
          ],
        },
      ],
      creditCard: [
        {
          id: '34a4e91398280643213a4af7b77e81255f8b38b4',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXX-XXXX-8241',
          productName: 'DBS/POSB MASTERCARD PLATINUM',
          amounts: [
            {
              id: '911b5925-4e43-49ca-a0df-9bb60c9b328e',
              amountType: 'balance',
              amount: '-26026',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'd8ea3cd5-f526-40ca-8200-4f9a02c3ee75',
              amountType: 'balance',
              amount: '-26289',
              ccy: 'SGD',
              date: '062020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'f7dd4e96-be1a-4c22-9aea-e83b69acc0be',
              amountType: 'balance',
              amount: '-26555',
              ccy: 'SGD',
              date: '052020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '4911d4d9-63f3-4c34-84cb-3f8fda20fd06',
              amountType: 'balance',
              amount: '-26823',
              ccy: 'SGD',
              date: '042020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '3bddcdfaf88094e2906d65349c7102c68005eb7f',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXX-XXXX-2554',
          productName: 'DBS ALTITUDE VISA SIGNATURE CARD',
          amounts: [
            {
              id: '21ceb8de-a279-45eb-b5ea-a7a1f7867cbf',
              amountType: 'balance',
              amount: '24151',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '24326459-5e91-4cbf-a496-3856ea483ab5',
              amountType: 'balance',
              amount: '24395',
              ccy: 'SGD',
              date: '062020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '60e8dd0c-e829-4916-872f-20cf7b0d28ce',
              amountType: 'balance',
              amount: '24641',
              ccy: 'SGD',
              date: '052020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'bfa679ce-60fd-4c89-bdc1-a9a430e1d8ca',
              amountType: 'balance',
              amount: '24890',
              ccy: 'SGD',
              date: '042020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '0107b5b9b092f7f102063aed896c68b0286ad808',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXX-XXXX-9122',
          productName: 'DBS/POSB MASTERCARD PLATINUM',
          amounts: [
            {
              id: 'e9427bf3-ee5b-430f-85c0-2352ca7fb7ea',
              amountType: 'balance',
              amount: '0',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '1aa2d92c-bbb5-41df-9e73-d0e076c659f3',
              amountType: 'balance',
              amount: '0',
              ccy: 'SGD',
              date: '062020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '04d1f2e6-8fb7-43cc-ad75-f64956b08042',
              amountType: 'balance',
              amount: '0',
              ccy: 'SGD',
              date: '052020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '113de919-897b-4ef2-b021-578c600f0246',
              amountType: 'balance',
              amount: '0',
              ccy: 'SGD',
              date: '042020',
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
      ],
      fixedDeposit: [
        {
          id: 'bbd6abe55589623a41b714866b8ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-SINGLE-SGD',
          accountRelationship: 'Single',
          productName: 'SGD Time Deposit (Single - SGD only)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '473823',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41b714822b6ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-SINGLE-SGD',
          accountRelationship: 'Single',
          productName: 'SGD Time Deposit (Single - SGD only - Aggregated)',
          percentageShare: 50,
          aggregateCount: 3,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b88d5523482e',
              amountType: 'balance',
              amount: '473823',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '273281',
                  productNumbers: [0],
                },
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '100271',
                  productNumbers: [1, 2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41b714866a6ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-SINGLE-USD',
          accountRelationship: 'Single',
          productName: 'SGD Time Deposit (Single - USD only)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-bbb7-b99d5523482e',
              amountType: 'balance',
              amount: '2341234',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41b714866b6ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-SINGLE-USD-AGG',
          accountRelationship: 'Single',
          productName: 'SGD Time Deposit (Single - USD only - Aggregated)',
          percentageShare: 50,
          aggregateCount: 4,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99a5523482e',
              amountType: 'balance',
              amount: '2341234',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '283722',
                  productNumbers: [0],
                },
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '372812',
                  productNumbers: [1],
                },
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '842350',
                  productNumbers: [2, 3],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41b714866b6ca2f5238f3c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-MC',
          accountRelationship: 'Single',
          productName: 'SGD Time Deposit (Single - Multicurrency)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab5',
              amountType: 'balance',
              amount: '231432',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab6',
              amountType: 'balance',
              amount: '231432',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab7',
              amountType: 'balance',
              amount: '231432',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd51be55589623a41b714866b6ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-MC-AGG',
          accountRelationship: 'Single',
          productName: 'SGD Time Deposit (Single - Multicurrency - Aggregated)',
          percentageShare: 50,
          aggregateCount: 4,
          amounts: [
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbcd',
              amountType: 'balance',
              amount: '231432',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '28123',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afceaa',
                  amount: '61741',
                  productNumbers: [0, 2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afceaa',
                  amount: '79827',
                  productNumbers: [3],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbce',
              amountType: 'balance',
              amount: '231432',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '28322',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebe',
                  amount: '101555',
                  productNumbers: [0, 3],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbcf',
              amountType: 'balance',
              amount: '231432',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '231432',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623b21b714866b6ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-JOINT-SGD',
          accountRelationship: 'Joint',
          productName: 'SGD Time Deposit (Joint - SGD only)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '473823',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41a814866b6ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-Joint-SGD',
          accountRelationship: 'Joint',
          productName: 'SGD Time Deposit (Joint - SGD only - Aggregated)',
          percentageShare: 50,
          aggregateCount: 3,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '473823',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '273281',
                  productNumbers: [0],
                },
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '100271',
                  productNumbers: [1, 2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41b714866b6ca2f5238f4823',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-JOINT-USD',
          accountRelationship: 'Joint',
          productName: 'SGD Time Deposit (Joint - USD only)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '2341234',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41b714866b6ca2f5238f4833',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-Joint-USD-AGG',
          accountRelationship: 'Joint',
          productName: 'SGD Time Deposit (Joint - USD only - Aggregated)',
          percentageShare: 50,
          aggregateCount: 4,
          amounts: [
            {
              id: 'cae9b42e-6e4a-4ab1-baa7-b99d5523482e',
              amountType: 'balance',
              amount: '2341234',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '283722',
                  productNumbers: [0],
                },
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '372812',
                  productNumbers: [1],
                },
                {
                  id: '8c8b18db-4765-4f38-9152-b56d8b28e809',
                  amount: '842350',
                  productNumbers: [2, 3],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41c814866b6ca2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-MC',
          accountRelationship: 'Joint',
          productName: 'SGD Time Deposit (Joint - Multicurrency)',
          percentageShare: 50,
          aggregateCount: 1,
          amounts: [
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab5',
              amountType: 'balance',
              amount: '231432',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab6',
              amountType: 'balance',
              amount: '231432',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbab7',
              amountType: 'balance',
              amount: '231432',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'bbd6abe55589623a41b714866b6cd2f5238f483c',
          serviceProvider: 'DBS',
          accountNumber: 'XXX-XX1234-MC-AGG',
          accountRelationship: 'Joint',
          productName: 'SGD Time Deposit (Joint - Multicurrency - Aggregated)',
          percentageShare: 50,
          aggregateCount: 4,
          amounts: [
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbcd',
              amountType: 'balance',
              amount: '231432',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '28123',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afceaa',
                  amount: '61741',
                  productNumbers: [0, 2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afceaa',
                  amount: '79827',
                  productNumbers: [3],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbce',
              amountType: 'balance',
              amount: '231432',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '28322',
                  productNumbers: [1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebe',
                  amount: '101555',
                  productNumbers: [0, 3],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '462fbf27-9f5b-4ff8-bdfd-420dd68fbbcf',
              amountType: 'balance',
              amount: '231432',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '231432',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
      ],
      securedLoan: [
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d333-1',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-SINGLE-SGD',
          accountType: 'HD',
          accountRelationship: 'Single',
          productName: 'MORTGAGE LOAN (Single - SGD only)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '21729128',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d111-2',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-SINGLE-SGD-AGG',
          accountType: 'HD',
          accountRelationship: 'Single',
          productName: 'MORTGAGE LOAN (Single - SGD only - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 4,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '21729128',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2738128',
                  productNumbers: [0],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '8079881',
                  productNumbers: [1, 3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2831238',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d334-3',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-SINGLE-USD',
          accountType: 'HD',
          accountRelationship: 'Single',
          productName: 'MORTGAGE LOAN (Single - USD only)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '2381248',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997e123-4',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-SINGLE-USD-AGG',
          accountType: 'HD',
          accountRelationship: 'Single',
          productName: 'MORTGAGE LOAN (Single - USD only - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 4,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '2381248',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '238234',
                  productNumbers: [3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '929547',
                  productNumbers: [0, 1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '283920',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d335-5',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-SINGLE-MC',
          accountType: 'HD',
          accountRelationship: 'Single',
          productName: 'MORTGAGE LOAN (Single - Multicurrency)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '5819232',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '283929',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '3483292',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d335-6',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-SINGLE-MC-AGG',
          accountType: 'HD',
          accountRelationship: 'Single',
          productName: 'MORTGAGE LOAN (Single - Multicurrency - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 3,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '5819232',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '1723449',
                  productNumbers: [0, 2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2372334',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '283929',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '183821',
                  productNumbers: [2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '100108',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '3483292',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '3483292',
                  productNumbers: [0],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d333-7',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-JOINT-SGD',
          accountType: 'HD',
          accountRelationship: 'Joint-OR',
          productName: 'MORTGAGE LOAN (Joint - SGD only)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '21729128',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d111-8',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-JOINT-SGD-AGG',
          accountType: 'HD',
          accountRelationship: 'Joint-OR',
          productName: 'MORTGAGE LOAN (Joint - SGD only - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 4,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '21729128',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2738128',
                  productNumbers: [0],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '8079881',
                  productNumbers: [1, 3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2831238',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d334-9',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-JOINT-USD',
          accountType: 'HD',
          accountRelationship: 'Joint-OR',
          productName: 'MORTGAGE LOAN (Joint - USD only)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '2381248',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997e123-10',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-JOIUNT-USD-AGG',
          accountType: 'HD',
          accountRelationship: 'Joint-OR',
          productName: 'MORTGAGE LOAN (Joint - USD only - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 4,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '2381248',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '238234',
                  productNumbers: [3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '929547',
                  productNumbers: [0, 1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '283920',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999bf8b6ea64614b250d6997d335-11',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-JOINT-MC',
          accountType: 'HD',
          accountRelationship: 'Joint-OR',
          productName: 'MORTGAGE LOAN (Joint - Multicurrency)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '5819232',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '283929',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '3483292',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'c2cced6eebaa999baab6ea64614b250d6997d335-12',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX118-1-JOINT-MC-AGG',
          accountType: 'HD',
          accountRelationship: 'Joint-OR',
          productName: 'MORTGAGE LOAN (Joint - Multicurrency - Aggregate)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'MORTGAGE', description: 'Mortgage' },
          aggregateCount: 3,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '5819232',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '1723449',
                  productNumbers: [0, 2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2372334',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '283929',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '183821',
                  productNumbers: [2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '100108',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '3483292',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '3483292',
                  productNumbers: [0],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
      ],
      srs: [
        {
          id: 'becc2b013d827942a29e43bafe6c5cc040db1d6d',
          serviceProvider: 'DBS',
          accountNumber: 'XXXX-XXXXX8-4-223',
          amounts: [
            {
              id: '79293eba-07de-47c0-a494-722e4add9b94',
              amountType: 'balanceFD',
              amount: '459045',
              ccy: 'USD',
              date: apimCurrMonth,
              description: 'FCFD-DBS (USD)',
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '5b010ad5-a051-4a72-bba5-93485c1ebfcb',
              amountType: 'premiumPaid',
              amount: '153015',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: 'MANULIFE',
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '0523c996-1c52-4d33-8330-067df4fb5f32',
              amountType: 'balanceFM',
              amount: '306030',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: 'IFAST',
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: '22d13b21-f5a5-404c-9a3d-b8606653ea01',
              amountType: 'balanceCash',
              amount: '218527',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: 'Cash',
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfc67e96-759e-4207-b306-b47e00780624',
              amountType: 'balanceSSB',
              amount: '300000',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: 'SBFEB19 GX19020V',
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
          assetHoldings: [
            {
              id: 'ac7ad0f4-ab01-4ce1-bd2b-ad39cf513b6f-assetHoldingId',
              holdingType: 'equity',
              holdingCode: 'SG1L01001701',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: 'ac7ad0f4-ab01-4ce1-bd2b-ad39cf513b6f',
                  balancesQuantity: '500000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
            {
              id: 'e57d5557-06b7-4517-a364-26d701c7bbb5-assetHoldingId',
              holdingType: 'equity',
              holdingCode: 'SG1U25933169',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: 'e57d5557-06b7-4517-a364-26d701c7bbb5',
                  balancesQuantity: '100000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
            {
              id: '47bfc310-ee2b-4275-9be5-ded1ebc61b0b-assetHoldingId',
              holdingType: 'bond',
              holdingCode: 'SG7Y76964295',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: '47bfc310-ee2b-4275-9be5-ded1ebc61b0b',
                  balancesQuantity: '200000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
            {
              id: 'a0fc5fb1-0088-487a-b410-8f91ad6718c8-assetHoldingId',
              holdingType: 'unitTrust',
              holdingCode: 'SG9999007991',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: 'a0fc5fb1-0088-487a-b410-8f91ad6718c8',
                  balancesQuantity: '400000',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
          ],
        },
      ],
      unitTrust: [
        {
          id: '1db187f1dad7c6505549d6cfcbe26b4612750d03',
          serviceProvider: 'DBS',
          accountNumber: 'XXXXXXX0877',
          accountRelationship: 'Single',
          assetHoldings: [
            {
              id: '02ef6b16-82e7-44fb-8aef-04051b86942c-assetHoldingId',
              holdingType: 'unitTrust',
              holdingCode: 'LU0330918003',
              holdingCcy: 'SGD',
              holdingCodeType: 'isin',
              balanceType: 'Total',
              percentageShare: 0,
              estimatedGrowthRate: 12.34,
              assetHoldingBalances: [
                {
                  id: '02ef6b16-82e7-44fb-8aef-04051b86942c',
                  balancesQuantity: '6000012',
                  balancesDate: apimCurrMonth,
                  unitPrice: 0,
                },
              ],
            },
          ],
        },
      ],
      unsecuredLoan: [
        {
          id: '7ed1663bd7949170dfe0a7a70cee9efe0b7b6de5-13',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-SINGLE-SGD',
          accountType: 'SL',
          accountRelationship: 'Single',
          productName: 'Study Loan (Single - SGD only)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '21729128',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663bd7949170dfe0a7a70cee9efe0b7b6de5-14',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-SINGLE-SGD-AGG',
          accountRelationship: 'Single',
          accountType: 'SL',
          productName: 'Study Loan (Single - SGD only - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 4,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '21729128',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2738128',
                  productNumbers: [0],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '8079881',
                  productNumbers: [1, 3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2831238',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1552bd7949170dfe0a7a70cee9efe0b7b6de5-15',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-SINGLE-USD',
          accountRelationship: 'Single',
          accountType: 'SL',
          productName: 'Study Loan (Single - USD only)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '2381248',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663bd7949170dfe0a7a70cee9efe0b7b6de5-16',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-SINGLE-USD',
          accountRelationship: 'Single',
          accountType: 'SL',
          productName: 'Study Loan (Single - USD only - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 4,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '2381248',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '238234',
                  productNumbers: [3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '929547',
                  productNumbers: [0, 1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '283920',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663bd7444170dfe0a7a70cee9efe0b7b6de5-17',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-SINGLE-MC',
          accountRelationship: 'Single',
          accountType: 'SL',
          productName: 'Study Loan (Single - Multicurrency)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '5819232',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '283929',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '3483292',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663bd7949170eae0a7a70cee9efe0b7b6de5-18',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-SINGLE-MC-AGG',
          accountRelationship: 'Single',
          accountType: 'SL',
          productName: 'Study Loan (Single - Multicurrency - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 3,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '5819232',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '1723449',
                  productNumbers: [0, 2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2372334',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '283929',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '183821',
                  productNumbers: [2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '100108',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '3483292',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '3483292',
                  productNumbers: [0],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663bd7949170dfe1b8a70cee9efe0b7b6de5-19',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-JOINT-SGD',
          accountRelationship: 'Joint-AND',
          accountType: 'SL',
          productName: 'Study Loan (Joint - SGD only)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '21729128',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed2223bd7949170dfe0a7a70cee9efe0b7b6de5-20',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-JOINT-SGD-AGG',
          accountRelationship: 'Joint-AND',
          accountType: 'SL',
          productName: 'Study Loan (Joint - SGD only - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 4,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '21729128',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2738128',
                  productNumbers: [0],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '8079881',
                  productNumbers: [1, 3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2831238',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663ee8849170dfe0a7a70cee9efe0b7b6de5-21',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-JOINT-USD',
          accountRelationship: 'Joint-AND',
          accountType: 'SL',
          productName: 'Study Loan (Joint - USD only)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '2381248',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663bd7123450dfe0a7a70cee9efe0b7b6de5-22',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-JOINT-USD-AGG',
          accountRelationship: 'Joint-AND',
          accountType: 'SL',
          productName: 'Study Loan (Joint - USD only - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 4,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '2381248',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '238234',
                  productNumbers: [3],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '929547',
                  productNumbers: [0, 1],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '283920',
                  productNumbers: [2],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663bd7442170dfe0a7a70cee9efe0b7b6de5-23',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-JOINT-MC',
          accountRelationship: 'Joint-AND',
          accountType: 'SL',
          productName: 'Study Loan (Joint - Multicurrency)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 1,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '5819232',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '283929',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '3483292',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: '7ed1663bd7949170dfe0a9b90cee9efe0b7b6de5-24',
          serviceProvider: 'DBS',
          accountNumber: 'XX-XXX399-5-JOINT-MC-AGG',
          accountRelationship: 'Joint-AND',
          accountType: 'SL',
          productName: 'Study Loan (Joint - Multicurrency - Aggregated)',
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          percentageShare: 50,
          type: { id: 'NON_MORTGAGE', description: 'Non-mortgage' },
          aggregateCount: 3,
          amounts: [
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '5819232',
              ccy: 'SGD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '1723449',
                  productNumbers: [0, 2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '2372334',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '283929',
              ccy: 'USD',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '183821',
                  productNumbers: [2],
                },
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '100108',
                  productNumbers: [1],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
            {
              id: 'cfe1432e-5e9f-42fb-88a2-928653aaa3f2',
              amountType: 'balance',
              amount: '3483292',
              ccy: 'MYR',
              date: apimCurrMonth,
              description: null,
              aggregatedAmounts: [
                {
                  id: 'a121a4c3e03db906f0fd4480b0fe537ba2afcebf',
                  amount: '3483292',
                  productNumbers: [0],
                },
              ],
              surrenderValue: 0,
              estimatedGrowthRate: 12.34,
            },
          ],
        },
      ],
      cdp: [
        {
          id: 'c49ba4f1d9e138ef49b4c91e1b1f1963884d9b058c766b831a585a1687314e3f',
          accountNumber: 'xxxxxxxx3171',
          accountRelationship: 'Single',
          holdings: [
            {
              id: '1b09665967eb54195a54ad3aa3f4aaa7956e3aec',
              securityType: 25,
              percentageShare: 50,
              status: 0,
              isin: {
                id: 'SG1P75919099',
                name: 'TELECHOICE INTL',
                primaryStockCode: 'T41',
              },
              holdingBalances: [
                {
                  id: '7d2d5d8606d06ae8446e1d8c448997f379613e69',
                  date: apimCurrMonth,
                  totalBalance: '600000',
                  freeBalance: null,
                  blockedBalance: null,
                  marketValue: '11820000',
                  marketValueCcy: 'SGD',
                  bondCouponRatePercent: null,
                  bondCouponFrequency: null,
                  bondParValue: null,
                  bondFirstCouponDate: null,
                  bondMaturityDate: null,
                },
                {
                  id: '447435a62b80b17da13c74a2a6bcf6586148d86d',
                  date: apimCurrMonth,
                  totalBalance: '700000',
                  freeBalance: null,
                  blockedBalance: null,
                  marketValue: '12000000',
                  marketValueCcy: 'SGD',
                  bondCouponRatePercent: null,
                  bondCouponFrequency: null,
                  bondParValue: null,
                  bondFirstCouponDate: null,
                  bondMaturityDate: null,
                },
              ],
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'd49ba4f1d9e138ef49b4c91e1b1f1963884d9b058c766b831a585a1687314e3f',
          accountNumber: 'xxxxxxxx3222',
          accountRelationship: 'Single',
          holdings: [
            {
              id: '2b09665967eb54195a54ad3aa3f4aaa7956e3aec',
              securityType: 25,
              percentageShare: 50,
              status: 0,
              isin: {
                id: 'SG1P75919099',
                name: 'SINGTEL INTL',
                primaryStockCode: 'B42',
              },
              holdingBalances: [
                {
                  id: '1d2d5d8606d06ae8446e1d8c448997f379613e69',
                  date: apimCurrMonth,
                  totalBalance: '600000',
                  freeBalance: null,
                  blockedBalance: null,
                  marketValue: null,
                  marketValueCcy: 'SGD',
                  bondCouponRatePercent: null,
                  bondCouponFrequency: null,
                  bondParValue: null,
                  bondFirstCouponDate: null,
                  bondMaturityDate: null,
                },
                {
                  id: '147435a62b80b17da13c74a2a6bcf6586148d86d',
                  date: apimCurrMonth,
                  totalBalance: '700000',
                  freeBalance: null,
                  blockedBalance: null,
                  marketValue: '12000000',
                  marketValueCcy: 'SGD',
                  bondCouponRatePercent: null,
                  bondCouponFrequency: null,
                  bondParValue: null,
                  bondFirstCouponDate: null,
                  bondMaturityDate: null,
                },
              ],
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'f6bedcb11cd0bdacededfa7b436e94f3ad404e251faf8349f96dd667beaa8d7e',
          accountNumber: 'xxxxxxxx3172',
          accountRelationship: 'Joint-OR',
          holdings: [
            {
              id: '114c19748a57cdc71aedbe7e7ba76652701804eb',
              securityType: 0,
              percentageShare: 50,
              status: 0,
              isin: {
                id: 'SGXF73155123',
                name: 'SOME CORP N240507',
                primaryStockCode: null,
              },
              holdingBalances: [
                {
                  id: '78eb1e0296d6d8812f9bda9d57df23a08342a1cd',
                  date: apimCurrMonth,
                  totalBalance: '100000',
                  freeBalance: '100000',
                  blockedBalance: null,
                  marketValue: '10000000',
                  marketValueCcy: 'SGD',
                  bondCouponRatePercent: '3.000000',
                  bondCouponFrequency: '2',
                  bondParValue: '100',
                  bondFirstCouponDate: apimCdpBondFirstCouponDate,
                  bondMaturityDate: apimCdpBondMaturityDate,
                },
                {
                  id: '210cc684776e75c1b8b9d7c4b6e72aa7c9f85206',
                  date: apimPrevOneMonth,
                  totalBalance: '0',
                  freeBalance: '100000',
                  blockedBalance: null,
                  marketValue: '10800000',
                  marketValueCcy: 'SGD',
                  bondCouponRatePercent: '3.000000',
                  bondCouponFrequency: '2',
                  bondParValue: '100',
                  bondFirstCouponDate: apimCdpBondFirstCouponDate,
                  bondMaturityDate: apimCdpBondMaturityDate,
                },
              ],
              estimatedGrowthRate: 12.34,
            },
          ],
        },
        {
          id: 'a6bedcb11cd0bdacededfa7b436e94f3ad404e251faf8349f96dd667beaa8d7e',
          accountNumber: 'xxxxxxxx3111',
          accountRelationship: 'Joint-OR',
          holdings: [
            {
              id: 'a14c19748a57cdc71aedbe7e7ba76652701804eb',
              securityType: 0,
              percentageShare: 50,
              status: 0,
              isin: {
                id: 'SGXF73155974',
                name: 'KEPCORP N240507',
                primaryStockCode: null,
              },
              holdingBalances: [
                {
                  id: 'a8eb1e0296d6d8812f9bda9d57df23a08342a1cd',
                  date: apimCurrMonth,
                  totalBalance: '100000',
                  freeBalance: '100000',
                  blockedBalance: null,
                  marketValue: '10000000',
                  marketValueCcy: 'SGD',
                  bondCouponRatePercent: '3.000000',
                  bondCouponFrequency: '2',
                  bondParValue: '100',
                  bondFirstCouponDate: apimCdpBondFirstCouponDate,
                  bondMaturityDate: apimCdpBondMaturityDate,
                },
                {
                  id: 'a10cc684776e75c1b8b9d7c4b6e72aa7c9f85206',
                  date: apimPrevOneMonth,
                  totalBalance: '0',
                  freeBalance: '100000',
                  blockedBalance: null,
                  marketValue: null,
                  marketValueCcy: 'SGD',
                  bondCouponRatePercent: '3.000000',
                  bondCouponFrequency: '2',
                  bondParValue: '100',
                  bondFirstCouponDate: apimCdpBondFirstCouponDate,
                  bondMaturityDate: apimCdpBondMaturityDate,
                },
              ],
              estimatedGrowthRate: 12.34,
            },
          ],
        },
      ],

      latestConsentAt: '2023-01-03T06:52:25.129Z',
    },
    myinfo: {
      id: '0d1bc59d-ac4c-4940-aef6-16001afef544',
      myinfoResponseStatus: [
        {
          sp: 'MYINFO',
          statusCode: '0000',
        },
      ],
      noa: {
        id: 'b9ab7b2a-2b57-4bb9-a0c2-15eb263a8a1c',
        lastUpdatedAt: '2020-09-09',
        amount: 8293917,
        trade: 239192,
        interest: 492932,
        employment: 3920102,
        rent: 127382,
        yearOfAssessment: '2019',
        taxClearance: false,
        category: 'ORIGINAL',
      },
      cpf: {
        id: '0ee676f9-582d-4bbd-881f-75fc9b91c817',
        lastUpdatedAt: '2020-08-26',
        oa: 4960238,
        ma: 1725300,
        sa: 1293975,
        ra: 3426564,
      },
      hdbOwnership: [
        {
          id: '23dc3745-3ec5-4f67-9a11-900cfd2ee7e7',
          outstandingLoanBalance: 529381828,
          monthlyLoanInstalment: 128492,
          percentageShare: 50,
          monthlyCashLoanInstalment: 50,
          monthlyCPFLoanInstalment: 50,
          lastUpdatedAt: '2020-08-26',
        },
      ],
      latestConsentAt: '2023-01-03T06:52:25.129Z',
    },
  },
};

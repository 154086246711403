import React, { useState } from 'react';
import styled from 'styled-components';

import { colorScale } from 'themes';

import { NUMBERING_CIRCLE_SIZE_PX, GAP_BETWEEN_BUTTON_AND_CONTENT_PX } from './constants';

const LINE_WIDTH_PX = '2px';

const Wrapper = styled.div`
  position: relative;
`;

const Line = styled.div`
  background: ${colorScale('primary', 20)};
  width: ${LINE_WIDTH_PX};
  height: calc(100% - ${NUMBERING_CIRCLE_SIZE_PX} - ${GAP_BETWEEN_BUTTON_AND_CONTENT_PX});

  position: absolute;
  top: calc(${NUMBERING_CIRCLE_SIZE_PX} / 2);
  left: calc(${NUMBERING_CIRCLE_SIZE_PX} / 2 - ${LINE_WIDTH_PX} / 2);
  z-index: 0;
`;

interface IInjectedHousingAccordionSectionProps {
  openedSection: number | null;
  setOpenedSection: (openedSection: number | null) => void;
}

interface IOwnProps {
  children: (
    injectedHousingAccordionSectionProps: IInjectedHousingAccordionSectionProps
  ) => React.ReactNode;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

/**
 * Displays a housing accordion.
 * @param children Function that receives injected props and returns a block of HousingAccordionSection
 */
export const HousingAccordion = ({ children, ...otherProps }: IProps) => {
  const [openedSection, setOpenedSection] = useState<number | null>(null);

  return (
    <Wrapper {...otherProps}>
      <Line />
      {children({ openedSection, setOpenedSection })}
    </Wrapper>
  );
};

import styled from 'styled-components';

export const AddonPanel = styled.div`
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: 100;
  display: grid;
  grid-gap: 16px;
`;

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { IPortfolioDiversificationInputs } from '../interfaces';

export const IV1: IRule<IPortfolioDiversificationInputs> = {
  condition: ({ numInvestmentClasses }: IPortfolioDiversificationInputs) =>
    numInvestmentClasses >= 2,
  result: {
    key: RULE.IV1,
    indicatorStatus: indicatorStatuses.GOOD,
    recommendedActions: [recommendedActions.TRA_PD_A, recommendedActions.TRA_PD_B],
    resources: [
      // Some links
    ],
  },
};

import { sortBy } from 'lodash';

import { IEngineOutputs, RULE } from './interfaces';

const priorityList = [
  RULE.SR3,
  RULE.EF3,
  RULE.TDSR2,
  RULE.SR2,
  RULE.EF2,
  RULE.IV2,
  RULE.IV3A,
  RULE.TDSR1,
  RULE.SR1A,
  RULE.SR1B,
  RULE.SR1C,
  RULE.IV1,
  RULE.EF1A,
  RULE.EF1B,
];

export const getSortedResults = (results: IEngineOutputs[]): IEngineOutputs[] =>
  sortBy(results, ({ key }) => priorityList.indexOf(key));

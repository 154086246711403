import React from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import {
  IButtonRenderParams,
  required,
  ValidationButton,
  ValidationForm,
  ValidationContainer,
} from 'components/Validations';
import { typography } from 'themes';

import { AmountField } from '../AmountField/AmountField';
import { Button } from '../Button/Button';
import { DateField } from '../DateField/DateField';
import { Input } from '../Input/Input';
import { Pill } from '../Pill/Pill';

import { AmountLimitBanner } from './AmountLimitBanner';

const targetNameRecommendations = [
  'General savings',
  'Home downpayment',
  'Renovation',
  'Travel',
  'Wedding',
];

const Header = styled.div`
  ${typography('heading-m')}
  margin-bottom: 28px;
`;

const NameInputWrapper = styled.div`
  margin-bottom: 24px;
  width: 70%;
`;

const PillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

const StyledNameInput = styled(Input)`
  ${typography('body-m')}
  label {
    ${typography('body-s-semibold')}
  }
`;

const AmountInputWrapper = styled.div`
  margin-bottom: 32px;
  width: calc(50% - 8px);
`;

const StyledAmountField = styled(AmountField)`
  ${typography('body-m')}
  label {
    ${typography('body-s-semibold')}
  }
`;

const DateFieldWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledDateField = styled(DateField)`
  ${typography('body-m')}
  label {
    ${typography('body-s-semibold')}
  }
`;

const AddButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`;

interface IProps {
  transitionStatus?: TransitionStatus;
  name: string;
  amount: number | null;
  date: Date;
  addToStore: () => void;
  handlePillClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  updateName: (name: string) => void;
  updateAmount: (amount: number) => void;
  updateDate: (date: Date) => void;
  thresholdAmount: number;
}

export class AddTargetModalContent extends React.Component<IProps> {
  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    this.inputRef = React.createRef();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.transitionStatus === 'entering' && this.props.transitionStatus === 'entered') {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  }

  public render() {
    const {
      name,
      amount,
      date,
      addToStore,
      updateName,
      updateAmount,
      updateDate,
      handlePillClick,
      thresholdAmount,
    } = this.props;

    return (
      <React.Fragment>
        <Header>Add target</Header>
        <ValidationForm onSubmit={addToStore} disableAfterSubmit={true}>
          <NameInputWrapper>
            <ValidationContainer
              value={name}
              validations={[required]}
              render={(validationsParams) => (
                <StyledNameInput
                  inputRef={this.inputRef}
                  label="Name"
                  widthStyle="elastic"
                  minNumChars={15}
                  errorMessage={validationsParams.errorMessage}
                  onBlur={validationsParams.onBlur}
                  onFocus={validationsParams.onFocus}
                  value={name}
                  wrapperRef={validationsParams.placerRef}
                  handleChange={updateName}
                />
              )}
            />
          </NameInputWrapper>
          <PillWrapper>
            {targetNameRecommendations.map((targetName) => (
              <Pill
                key={targetName}
                onClick={handlePillClick}
                value={targetName}
                isSelected={name.toLowerCase() === targetName.toLowerCase()}
              >
                {targetName}
              </Pill>
            ))}
          </PillWrapper>
          <AmountInputWrapper>
            <StyledAmountField
              label="Amount"
              widthStyle="elastic"
              minNumChars={8}
              amount={amount}
              required={true}
              minAmount={0}
              minAmountInclusive={false}
              onInputChange={updateAmount}
              addonBefore={<AmountField.DollarSign />}
            />
          </AmountInputWrapper>
          <DateFieldWrapper>
            <StyledDateField
              label="Target due"
              date={date}
              onInputChange={updateDate}
              menuPlacement="top"
            />
          </DateFieldWrapper>
          {Number(amount) > thresholdAmount && (
            <AmountLimitBanner thresholdAmount={thresholdAmount} />
          )}
          <ValidationButton
            render={(renderParams: IButtonRenderParams) => (
              <AddButton {...renderParams}>Add</AddButton>
            )}
          />
        </ValidationForm>
      </React.Fragment>
    );
  }
}

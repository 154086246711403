import moment from 'moment';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

import { getApimCalendarLatestMonthYear } from 'redux/apim/selectors';
import { getHasToken } from 'redux/dataStorage/selectors';
import {
  getHasCompletedOnboardingProfileQuestions,
  getHasCompletedOnboarding,
  getHasCompletedTargetsSetup,
  getHasConsentedToAppTNCAt,
  getAccountStatus,
  getNeedsNOCIntercept,
  getDeferEmailVerification,
} from 'redux/profile/selectors';
import { getExpiredAndNotPaidOutGoals, isEFExpiredAndNotAchieved } from 'redux/targets/selectors';

import LogoutPage from 'components/App/Authentication/LogoutPage/LogoutPage';
import { ErrorBoundary } from 'components/App/ErrorBoundary';
import { NavigationBar, Footer, LogoBar } from 'components/Common';
import MainLandingPage from 'components/LandingPage/MainLandingPage/MainLandingPage';
import SiteMapPage from 'components/LandingPage/SiteMap/SiteMapPage';
import RetirementResourcesRouter from 'components/Resources/RetirementResources/RetirementResourcesRouter';
import { TargetsLockoutPage } from 'components/Targets/TargetsLockoutPage';
import { ACCOUNT_STATUS } from 'constants/enums';
import {
  ONBOARDING,
  PROFILE,
  RESOURCES,
  SETTINGS,
  TARGETS,
  INTERCEPT,
  LOGIN,
  FINANCIAL_LITERACY_QUIZ,
  SGFINDEX,
} from 'constants/urls';
import { getDynamicFeatureDecisions } from 'featureDecisions';
import { theme } from 'themes';
import { colorScale } from 'themes';
import { getLogoutPageStatusType, LOGOUT_STATUS_TYPES } from 'utilities/sessionUtilities';

import { ProtectedRoute } from './ProtectedRoute';
import {
  defaultRedirection,
  getRedirectRouteFn,
  loggedInRedirection,
  redirectToOnboardingQuestions,
  redirectToTargetsSetupOrOnboardingQuestions,
  updateAvailableFundsRedirection,
  updateBankDataRedirection,
  updateExpiredTargetsRedirection,
  introPrivacyRedirection,
  accountPendingDeletionRedirection,
  dashBoardRedirection,
} from './redirectFunctions';
import { ScrollToTop } from './ScrollToTop';

const ProfileRouter = lazy(() => import('components/Profile/ProfileRouter'));
const SettingsRouter = lazy(() => import('components/Settings/SettingsRouter'));
const SetSavingsTargetsRouter = lazy(() =>
  import('components/SetSavingsTargets/SetSavingsTargetsRouter')
);
const TargetsPageContainer = lazy(() => import('components/Targets/TargetsPage/TargetsPage'));
const DecideHowMuchToSavePage = lazy(() =>
  import('components/Targets/DecideHowMuchToSavePage/DecideHowMuchToSavePage')
);
const OnboardingRouter = lazy(() => import('components/Onboarding/OnboardingRouter'));
const AuthenticatePageWithRouter = lazy(() =>
  import('./Authentication/LoginPage/AuthenticatePage')
);
const MyinfoIntroPage = lazy(() => import('components/Apim-Myinfo/Myinfo/MyinfoIntroPage'));
const MyinfoRouter = lazy(() => import('components/Apim-Myinfo/Myinfo/MyinfoRouter'));
const ApimRouter = lazy(() => import('components/Apim-Myinfo/ApiMarketplace/ApimRouter'));
const ApiMarketplaceTokenPage = lazy(() =>
  import('components/Apim-Myinfo/ApiMarketplace/ApiMarketplaceTokenPage')
);
const SearchResultPageWithRouter = lazy(() => import('./SearchResultPage/SearchResultPage'));

const PrivacyPolicyPage = lazy(() =>
  import('components/LandingPage/PrivacyPolicyPage/PrivacyPolicyPage')
);

const SGFinDexTNCPage = lazy(() => import('components/LandingPage/SGFinDexTNC/SGFinDexTNC'));
const SGFinDexTNCRevisionNotificationPage = lazy(() =>
  import('components/LandingPage/SGFinDexTNC/SGFinDexTNCRevisionNotificationPage')
);
const SGFinDexDataInfoPage = lazy(() =>
  import('components/LandingPage/SGFinDexDataInfoPage/SGFinDexDataListPage')
);
const AboutUsPage = lazy(() => import('components/LandingPage/AboutUsPage/AboutUsPage'));
const TermsOfUsePage = lazy(() => import('components/LandingPage/TermsOfUsePage/TermsOfUsePage'));
const FAQPage = lazy(() => import('components/LandingPage/FAQPage/FAQPage'));
const MaintenanceNoticesPage = lazy(() =>
  import('components/LandingPage/MaintenanceNoticesPage/MaintenanceNoticesPage')
);

const AccountDeactivatedPage = lazy(() =>
  import('components/Profile/DeleteAccountPages/AccountDeactivatedPage')
);
const DeletionCompletionPage = lazy(() =>
  import('components/Profile/DeleteAccountPages/DeleteCompletionPage')
);
const UsefulLinksPage = lazy(() => import('../LandingPage/UsefulLinksPage/UsefulLinksPage'));

const NotificationsPage = lazy(() => import('components/Notifications/NotificationsPage'));
const MyinfoCallbackPage = lazy(() => import('components/Apim-Myinfo/Myinfo/MyinfoCallbackPage'));
const ExpenseManagerRouter = lazy(() => import('components/ExpenseManager/ExpenseManagerRouter'));
const UpdateSavingsPreviewPage = lazy(() =>
  import('components/ReturnFlow/UpdateSavingsPreviewPage')
);
const UpdateTargetProgressPageContainer = lazy(() =>
  import('components/ReturnFlow/UpdateTargetProgressPage')
);
const UpdateFundsPage = lazy(() => import('components/ReturnFlow/UpdateFundsPage/UpdateFundsPage'));
const AllocateFundsPage = lazy(() => import('components/ReturnFlow/AllocateFundsPage'));
const UpdateBankDataPage = lazy(() =>
  import('components/ReturnFlow/UpdateBankDataPage/UpdateBankDataPage')
);
const CashflowRouter = lazy(() => import('components/Cashflow/CashflowRouter'));
const WealthRouter = lazy(() => import('components/Wealth/WealthRouter'));
const DashboardRouter = lazy(() => import('components/Dashboard/DashboardRouter'));
const AllocateSavingsStandalonePage = lazy(() =>
  import('components/Composite/AllocateSavingsPage/AllocateSavingsStandalonePage')
);

const SavingsResourcesRouter = lazy(() =>
  import('components/Resources/SavingsResources/SavingsResourcesRouter')
);
const LoansAndCreditResourcesRouter = lazy(() =>
  import('components/Resources/LoansAndCreditResources/LoansAndCreditResourcesRouter')
);
const InvestmentResourcesRouter = lazy(() =>
  import('components/Resources/InvestmentResources/InvestmentResourcesRouter')
);
const InsuranceResourcesRouter = lazy(() =>
  import('components/Resources/InsuranceResources/InsuranceResourcesRouter')
);

const HousingResourcesRouter = lazy(() => import('components/Housing/HousingResourcesRouter'));

const ApiMarketplacePage = lazy(() =>
  import('components/Apim-Myinfo/ApiMarketplace/ApiMarketplacePage')
);

const EmailVerificationResultPage = lazy(() =>
  import('components/Profile/EmailVerificationResultPage')
);

const ExistingUserEmailPage = lazy(() =>
  import('components/Profile/EmailReturnFlow/ExistingUserEmailPage')
);

const NotificationOfChangesPage = lazy(() =>
  import('components/App/Updates/NotificationOfChangesPage')
);

const SingpassRedirectPage = lazy(() => import('./Authentication/LoginPage/SingpassRedirectPage'));

const QuizRouter = lazy(() => import('components/FinancialLiteracyQuiz/QuizRouter'));

const SupportedBrowsersPage = lazy(() =>
  import('components/LandingPage/SupportedBrowsersPage/SupportedBrowsersPage')
);

const RootWrapper = styled.div<{ layout: layouts }>`
  background: ${colorScale('grey', 10)};
  display: grid;
  min-height: calc(100dvh - ${theme.heights.governmentBanner});

  ${switchProp(
    'layout',
    {
      contentOnly: css`
        grid-template-areas: 'main-content';
      `,
      contentFooterOnly: css`
        grid-template-areas:
          'main-content'
          'footer';
      `,
    },
    css`
      grid-template-rows: min-content auto auto;
      grid-template-areas:
        'nav-bar'
        'main-content'
        'footer';
    `
  )}
`;

const Wrapper = styled.div`
  display: grid;
  grid-area: main-content;
  position: relative;
  grid-template-rows: 100%;
`;

type layouts = 'default' | 'contentOnly' | 'contentFooterOnly';

interface IPage {
  component?: React.ComponentType<any>;
  render?: () => JSX.Element;
  strict?: boolean;
  exact: boolean;
  path: string;
  documentTitle?: string;
  layout?: layouts;
  getRedirectRoute: getRedirectRouteFn;
}

export const pageList: IPage[] = [
  {
    component: NotificationOfChangesPage,
    exact: true,
    path: INTERCEPT.LOGIN.nocInterceptOnLogin,
    documentTitle: 'Notification of Changes',
    getRedirectRoute: () => undefined,
  },
  {
    component: () => <ExistingUserEmailPage origin="onboarding" />,
    exact: true,
    path: INTERCEPT.LOGIN.emailInterceptOnLogin,
    getRedirectRoute: introPrivacyRedirection,
  },
  {
    component: MainLandingPage,
    exact: true,
    path: '/',
    getRedirectRoute: () => undefined,
  },
  {
    component: SingpassRedirectPage,
    exact: true,
    path: LOGIN.singpassRedirect,
    getRedirectRoute: () => undefined,
    layout: 'contentOnly',
  },
  {
    component: MyinfoIntroPage,
    exact: true,
    path: '/intro-privacy',
    getRedirectRoute: loggedInRedirection,
  },
  {
    component: SiteMapPage,
    exact: true,
    path: '/site-map',
    getRedirectRoute: () => undefined,
  },
  {
    component: FAQPage,
    exact: true,
    path: '/faq',
    documentTitle: 'FAQ',
    getRedirectRoute: () => undefined,
  },
  {
    component: TermsOfUsePage,
    exact: true,
    path: '/terms-of-use',
    documentTitle: 'Terms of use',
    getRedirectRoute: () => undefined,
  },
  {
    component: PrivacyPolicyPage,
    exact: true,
    path: '/privacy-policy',
    documentTitle: 'Privacy policy',
    getRedirectRoute: () => undefined,
  },
  {
    component: MaintenanceNoticesPage,
    exact: true,
    path: '/maintenance-notices',
    documentTitle: 'Maintenance notices',
    getRedirectRoute: () => undefined,
  },
  {
    component: SGFinDexTNCPage,
    exact: true,
    path: SGFINDEX.termAndConditions,
    documentTitle: 'SGFinDex terms and conditions',
    getRedirectRoute: () => undefined,
  },
  {
    component: SGFinDexTNCRevisionNotificationPage,
    exact: true,
    path: SGFINDEX.termAndConditionsRevisions,
    documentTitle: 'SGFinDex terms and conditions revisions',
    getRedirectRoute: () => undefined,
  },
  {
    component: SGFinDexDataInfoPage,
    exact: true,
    path: SGFINDEX.dataList,
    documentTitle: 'SGFinDex Data Info',
    getRedirectRoute: () => undefined,
  },
  {
    component: AboutUsPage,
    exact: true,
    path: '/about-us',
    documentTitle: 'About us',
    getRedirectRoute: () => undefined,
  },
  {
    component: AuthenticatePageWithRouter,
    exact: true,
    path: '/sp-callback',
    layout: 'contentOnly',
    getRedirectRoute: () => undefined,
  },
  {
    component: SearchResultPageWithRouter,
    exact: false,
    path: '/search',
    getRedirectRoute: () => undefined,
  },
  {
    component: UsefulLinksPage,
    exact: true,
    path: '/useful-links',
    getRedirectRoute: () => undefined,
  },
  {
    component: DeletionCompletionPage,
    exact: true,
    path: PROFILE.deleteCompleted,
    getRedirectRoute: () => undefined,
  },
  {
    component: EmailVerificationResultPage,
    exact: true,
    path: PROFILE.emailVerification,
    layout: 'contentFooterOnly',
    getRedirectRoute: () => undefined,
  },
  {
    component: QuizRouter,
    exact: false,
    path: FINANCIAL_LITERACY_QUIZ.baseRoute,
    documentTitle: 'Financial Literacy Quiz',
    layout: 'contentOnly',
    getRedirectRoute: () => undefined,
  },
  {
    component: SupportedBrowsersPage,
    exact: true,
    path: '/supported-browsers',
    documentTitle: 'Supported Browsers',
    getRedirectRoute: () => undefined,
  },
  {
    component: ProfileRouter,
    exact: false,
    path: '/profile',
    documentTitle: 'Profile',
    getRedirectRoute: redirectToOnboardingQuestions,
  },
  {
    component: SettingsRouter,
    exact: false,
    path: SETTINGS.baseRoute,
    documentTitle: 'Settings',
    getRedirectRoute: redirectToOnboardingQuestions,
  },
  {
    component: TargetsPageContainer,
    exact: true,
    path: '/targets',
    documentTitle: 'Targets',
    getRedirectRoute: redirectToTargetsSetupOrOnboardingQuestions,
  },
  {
    component: SetSavingsTargetsRouter,
    exact: false,
    path: '/targets/setup',
    documentTitle: 'Set savings targets',
    getRedirectRoute: defaultRedirection,
  },
  {
    component: AllocateSavingsStandalonePage,
    exact: true,
    path: '/allocate-funds-and-allocation',
    documentTitle: 'Targets',
    getRedirectRoute: defaultRedirection,
  },
  {
    component: DecideHowMuchToSavePage,
    exact: true,
    path: '/targets/decide-savings',
    documentTitle: 'Targets',
    getRedirectRoute: defaultRedirection,
  },
  {
    component: TargetsLockoutPage,
    exact: true,
    path: TARGETS.lockoutPage,
    documentTitle: 'Targets Lockout Page',
    getRedirectRoute: redirectToOnboardingQuestions,
  },
  {
    component: OnboardingRouter,
    exact: false,
    path: ONBOARDING.baseRoute,
    documentTitle: 'Onboarding',
    getRedirectRoute: introPrivacyRedirection,
  },
  {
    component: ApimRouter,
    exact: false,
    path: '/data',
    documentTitle: 'SGFinDex',
    getRedirectRoute: redirectToOnboardingQuestions,
  },
  {
    component: ApiMarketplaceTokenPage,
    exact: true,
    path: '/apim-callback',
    documentTitle: 'SGFinDex',
    getRedirectRoute: (options) =>
      options.showSGFinDexVanillaTables ? undefined : redirectToOnboardingQuestions(options),
  },
  {
    component: UpdateSavingsPreviewPage,
    exact: true,
    path: '/update-savings-preview',
    documentTitle: 'Update savings preview',
    getRedirectRoute: defaultRedirection,
  },
  {
    component: UpdateTargetProgressPageContainer,
    exact: true,
    path: '/update-target-progress',
    documentTitle: 'Update target progress',
    getRedirectRoute: updateExpiredTargetsRedirection,
  },
  {
    component: UpdateFundsPage,
    exact: true,
    path: '/update-available-funds',
    documentTitle: 'Update available funds',
    getRedirectRoute: updateAvailableFundsRedirection,
  },
  {
    component: AllocateFundsPage,
    exact: true,
    path: '/allocate-available-funds',
    documentTitle: 'Update available funds',
    getRedirectRoute: updateAvailableFundsRedirection,
  },
  {
    component: UpdateBankDataPage,
    exact: true,
    path: '/update-bank-data',
    documentTitle: 'Update bank data',
    getRedirectRoute: updateBankDataRedirection,
  },
  {
    component: NotificationsPage,
    exact: true,
    path: '/notifications',
    documentTitle: 'Notifications',
    getRedirectRoute: introPrivacyRedirection,
  },
  {
    component: MyinfoRouter,
    exact: false,
    path: '/myinfo',
    documentTitle: 'Myinfo',
    getRedirectRoute: () => undefined,
  },
  {
    component: MyinfoCallbackPage,
    exact: true,
    path: '/my-info-data-callback',
    documentTitle: 'Myinfo demo',
    getRedirectRoute: loggedInRedirection,
  },
  {
    component: ExpenseManagerRouter,
    exact: false,
    path: '/expense-manager',
    documentTitle: 'Expense manager',
    getRedirectRoute: () => undefined,
  },
  {
    component: CashflowRouter,
    exact: false,
    path: ONBOARDING.CASHFLOW.baseRoute,
    documentTitle: 'Cashflow',
    getRedirectRoute: redirectToOnboardingQuestions,
  },
  {
    component: WealthRouter,
    exact: false,
    path: ONBOARDING.WEALTH.baseRoute,
    documentTitle: 'Wealth',
    getRedirectRoute: redirectToOnboardingQuestions,
  },
  {
    component: DashboardRouter,
    exact: false,
    path: '/dashboard',
    documentTitle: 'Dashboard',
    getRedirectRoute: dashBoardRedirection,
  },
  {
    component: SavingsResourcesRouter,
    exact: false,
    path: RESOURCES.SAVINGS.baseRoute,
    documentTitle: 'Savings Resources',
    getRedirectRoute: () => undefined,
  },
  {
    component: LoansAndCreditResourcesRouter,
    exact: false,
    path: RESOURCES.LOANS_AND_CREDIT.baseRoute,
    documentTitle: 'Loans and Credit Resources',
    getRedirectRoute: () => undefined,
  },
  {
    component: InvestmentResourcesRouter,
    exact: false,
    path: RESOURCES.INVESTMENT.baseRoute,
    documentTitle: 'Investment Resources',
    getRedirectRoute: () => undefined,
  },
  {
    component: InsuranceResourcesRouter,
    exact: false,
    path: RESOURCES.INSURANCE.baseRoute,
    documentTitle: 'Insurance Resources',
    getRedirectRoute: () => undefined,
  },
  {
    component: HousingResourcesRouter,
    exact: false,
    path: '/buying-a-house',
    documentTitle: 'Buying a house',
    getRedirectRoute: () => undefined,
  },
  {
    component: RetirementResourcesRouter,
    exact: false,
    path: RESOURCES.RETIREMENT.baseRoute,
    documentTitle: 'Getting started on retirement planning',
    getRedirectRoute: () => undefined,
  },
  {
    component: AccountDeactivatedPage,
    exact: true,
    path: PROFILE.deactivated,
    getRedirectRoute: accountPendingDeletionRedirection,
  },
  {
    component: ApiMarketplacePage,
    exact: true,
    path: '/sgfindex',
    getRedirectRoute: ({ showSGFinDexVanillaTables }) =>
      showSGFinDexVanillaTables ? undefined : '/',
  },
  {
    component: LogoutPage,
    exact: true,
    path: '/logout',
    getRedirectRoute: () => undefined,
  },
];

export const PageRouter = () => {
  const hasToken = useSelector(getHasToken);
  const hasCompletedOnboardingQuestions = useSelector(getHasCompletedOnboardingProfileQuestions);
  const hasCompletedOnboarding = useSelector(getHasCompletedOnboarding);
  const hasCompletedTargetSetup = useSelector(getHasCompletedTargetsSetup);
  const hasConsentedToAppTNC = useSelector(getHasConsentedToAppTNCAt);
  const needsNOCIntercept = useSelector(getNeedsNOCIntercept);
  const deferEmailVerification = useSelector(getDeferEmailVerification);

  const hasExpiredAndNonPaidOutGoals = useSelector(getExpiredAndNotPaidOutGoals).length > 0;
  const hasExpiredAndNonPaidOutTargets =
    useSelector(isEFExpiredAndNotAchieved) || hasExpiredAndNonPaidOutGoals;

  const hasLatestBankData =
    moment().diff(moment(useSelector(getApimCalendarLatestMonthYear), 'MMYYYY'), 'months') === 1;

  const accountStatus = useSelector(getAccountStatus);
  const isAccountPendingDeletion = accountStatus?.status === ACCOUNT_STATUS.PENDING_DELETION;

  const logoutStatus = getLogoutPageStatusType();

  const SkeletonPage = (
    <RootWrapper layout="contentOnly">
      <LogoBar />
    </RootWrapper>
  );

  return hasToken === null ? (
    SkeletonPage
  ) : (
    <Suspense fallback={SkeletonPage}>
      <ErrorBoundary>
        <Switch>
          {pageList.map(
            ({ getRedirectRoute, component, layout = 'default', ...otherProps }: IPage) => {
              const redirectRoute = getRedirectRoute({
                hasValidToken: Boolean(hasToken),
                hasConsentedToAppTNC,
                needsNOCIntercept,
                deferEmailVerification,
                hasCompletedOnboardingQuestions,
                hasCompletedOnboarding,
                hasCompletedTargetSetup,
                hasExpiredAndNonPaidOutTargets,
                hasLatestBankData,
                isAccountPendingDeletion,
                showSGFinDexVanillaTables: getDynamicFeatureDecisions().showSGFinDexVanillaTables,
                isDuplicateSession: logoutStatus === LOGOUT_STATUS_TYPES.DUPLICATE_SESSION,
              });

              return (
                <ProtectedRoute
                  isAllowed={redirectRoute === undefined}
                  redirectTo={redirectRoute}
                  {...otherProps}
                  key="protected-route"
                  render={() => {
                    const title = otherProps.documentTitle;
                    document.title = title ? `MyMoneySense - ${title}` : 'MyMoneySense';
                    return (
                      <RootWrapper layout={layout}>
                        {layout === 'default' && <NavigationBar key="nav-bar" />}
                        <ScrollToTop>
                          <Wrapper id="main-content">
                            <Route component={component} {...otherProps} />
                          </Wrapper>
                        </ScrollToTop>
                        {(layout === 'contentFooterOnly' || layout === 'default') && <Footer />}
                      </RootWrapper>
                    );
                  }}
                />
              );
            }
          )}
          <Redirect to={ONBOARDING.baseRoute} />
        </Switch>
      </ErrorBoundary>
    </Suspense>
  );
};

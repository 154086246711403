import React from 'react';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { typeScale, typeScaleLevel, colorScale, typography } from 'themes';

import { LevelContext } from './SectionHeaderContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Heading extends React.Component<any> {
  public render() {
    return (
      <LevelContext.Consumer>
        {(level) => {
          const HeadingLevel = headingLevels[Math.min(level, 6) - 1];
          return <HeadingLevel {...this.props} />;
        }}
      </LevelContext.Consumer>
    );
  }
}

const headingLevels = Array.from({ length: 6 }, (__, i) => {
  return styled[`h${i + 1}`]`
    font-size: ${typeScale((6 - i) as typeScaleLevel)};
    font-family: ${fromTheme('fonts.mainFontFamily')};
    margin: 0;
    color: ${colorScale('grey', 90)};
  `;
});

export const PageHeading = styled(Heading)`
  ${typography('heading-l')}
`;

export const PageDescription = styled.p`
  ${typography('body-m')}
`;

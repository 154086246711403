import React from 'react';

import { Modal, Button } from 'components/Common';
import { ASSET_TYPE, INVESTMENT_SOURCE } from 'constants/enums';

import {
  ModalWrapper,
  ModalTitle,
  ModalText,
  ModalStyledRadioGroup,
  ModalStyledRadio,
  ModalButtonModalWrapper,
  ModalCancelButton,
} from './sharedStyles';

export const investmentSourceLabels = {
  [INVESTMENT_SOURCE.CASH]: 'Cash',
  [INVESTMENT_SOURCE.CPFIS]: 'CPFIS',
  [INVESTMENT_SOURCE.SRS]: 'SRS',
};

export const structuredProductLabels = {
  [INVESTMENT_SOURCE.CASH]: 'Cash',
  [INVESTMENT_SOURCE.SRS]: 'SRS',
};

export const cashBalanceLabels = {
  [INVESTMENT_SOURCE.CPFIS]: 'CPFIS',
  [INVESTMENT_SOURCE.SRS]: 'SRS',
};

interface IModalProps {
  mounted: boolean;
  closeModal: () => void;
}

export interface IInvestmentSourceModalProps {
  name: string;
  assetType: ASSET_TYPE;
  onChange: (investmentSource: INVESTMENT_SOURCE) => void;
  onSubmit: (investmentSource: INVESTMENT_SOURCE) => void;
  investmentSource: INVESTMENT_SOURCE;
}

type IProps = IModalProps & IInvestmentSourceModalProps;

export const InvestmentSourceModal: React.FC<IProps> = ({
  mounted,
  closeModal,
  name,
  assetType,
  investmentSource,
  onChange,
  onSubmit,
}) => {
  const labels =
    assetType === ASSET_TYPE.STRUCTURED_PRODUCT
      ? structuredProductLabels
      : assetType === ASSET_TYPE.CASH_BALANCE
      ? cashBalanceLabels
      : investmentSourceLabels;

  return (
    <Modal
      titleText="select category"
      mounted={mounted}
      onExit={closeModal}
      showCloseButton={false}
    >
      <ModalWrapper>
        <ModalTitle>Select category</ModalTitle>
        <ModalText>
          {assetType === ASSET_TYPE.CASH_BALANCE ? (
            <>
              <strong>Cash balance</strong> belongs to your:
            </>
          ) : (
            <>
              <strong>{name ? name : 'Asset'}</strong> was bought using:
            </>
          )}
        </ModalText>
        <ModalStyledRadioGroup
          name="investment-source"
          value={investmentSource}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value as INVESTMENT_SOURCE)
          }
          variant="styled"
        >
          {Object.keys(labels).map((key) => (
            <ModalStyledRadio key={key} label={labels[key]} value={key} />
          ))}
        </ModalStyledRadioGroup>
        <ModalButtonModalWrapper>
          <ModalCancelButton variant="tertiary" widthStyle="elastic" onClick={closeModal}>
            Cancel
          </ModalCancelButton>
          <Button
            widthStyle="elastic"
            onClick={() => {
              onSubmit(investmentSource);
              closeModal();
            }}
          >
            Select
          </Button>
        </ModalButtonModalWrapper>
      </ModalWrapper>
    </Modal>
  );
};

import React from 'react';
import styled from 'styled-components';

import { IBaseButtonProps } from './constants';
import { StyledButton } from './sharedStyles';

const BaguetteWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const BaguetteCTAButton = ({
  primaryText,
  primaryOnClick,
  primaryVariant,
  primaryProps,
  ...otherProps
}: IBaseButtonProps) => {
  return (
    <BaguetteWrapper data-testid="baguette" {...otherProps}>
      <StyledButton
        widthStyle="fluid"
        onClick={primaryOnClick}
        variant={primaryVariant}
        data-testid="cta-primary"
        {...primaryProps}
      >
        {primaryText}
      </StyledButton>
    </BaguetteWrapper>
  );
};

export enum MODULES_ACTIONS {
  SET_CURRENT_STEP_INDEX = 'MODULES_ACTIONS/SET_CURRENT_STEP_INDEX',
  SET_LAST_UPDATED_DATE = 'MODULES_ACTIONS/SET_LAST_UPDATED_DATE',
  SET_LAST_COMPLETED_DATE = 'MODULES_ACTIONS/SET_LAST_COMPLETED_DATE',
}

export enum MODULES_LITE_ACTIONS {
  SET_CURRENT_STEP_INDEX = 'MODULES_LITE_ACTIONS/SET_CURRENT_STEP_INDEX',
  SET_MODULE_COMPLETED = 'MODULES_LITE_ACTIONS/SET_MODULE_COMPLETED',
}

export enum FINANCES_LITE_ACTIONS {
  SET_MONTHLY_CASH_IN = 'FINANCES_LITE_ACTIONS/SET_MONTHLY_CASH_IN',
  CREATE_EXPENSE = 'FINANCES_LITE_ACTIONS/CREATE_EXPENSE',
  UPDATE_EXPENSE = 'FINANCES_LITE_ACTIONS/UPDATE_EXPENSE',
  DELETE_EXPENSE = 'FINANCES_LITE_ACTIONS/DELETE_EXPENSE',
  CLEAR_EXPENSES = 'FINANCES_LITE_ACTIONS/CLEAR_EXPENSES',
  SET_EXPENSES = 'FINANCES_LITE_ACTIONS/SET_EXPENSES',
  SET_CURRENT_SAVINGS = 'FINANCES_LITE_ACTIONS/SET_CURRENT_SAVINGS',
}

export enum TARGETS_ACTIONS {
  CREATE_GOAL = 'TARGETS_ACTIONS/CREATE_GOAL',
  UPDATE_GOAL = 'TARGETS_ACTIONS/UPDATE_GOAL',
  DELETE_GOAL = 'TARGETS_ACTIONS/DELETE_GOAL',
  SET_EMERGENCY_FUND = 'TARGETS_ACTIONS/SET_EMERGENCY_FUND',
  UPDATE_PREV_ACCUMULATED_MONTHS = 'TARGETS_ACTIONS/UPDATE_PREV_ACCUMULATED_MONTHS',
}

export enum FINANCES_ACTIONS {
  SET_MONTHLY_CASH_IN = 'FINANCES_ACTIONS/SET_MONTHLY_CASH_IN',
  CREATE_INCOME = 'FINANCES_ACTIONS/CREATE_INCOME',
  SET_INCOME_LIST = 'FINANCES_ACTIONS/SET_INCOME_LIST',
  SET_LIABILITY = 'FINANCES_ACTIONS/SET_LIABILITY',
  DELETE_LIABILITY = 'FINANCES_ACTIONS/DELETE_LIABILITY',
  SET_LIABILITIES = 'FINANCES_ACTIONS/SET_LIABILITIES',
  CREATE_EXPENSE = 'FINANCES_ACTIONS/CREATE_EXPENSE',
  SET_EXPENSES = 'FINANCES_ACTIONS/SET_EXPENSES',
  UPDATE_EXPENSE = 'FINANCES_ACTIONS/UPDATE_EXPENSE',
  DELETE_EXPENSE = 'FINANCES_ACTIONS/DELETE_EXPENSE',
  CLEAR_EXPENSES = 'FINANCES_ACTIONS/CLEAR_EXPENSES',
  SET_ESTIMATED_MONTHLY_EXPENSES = 'FINANCE_ACTIONS/SET_ESTIMATED_MONTHLY_EXPENSES',
  SET_LOAN_REPAYMENTS = 'FINANCES_ACTIONS/SET_LOAN_REPAYMENTS',
  SET_INVESTMENT = 'FINANCES_ACTIONS/SET_INVESTMENT',
  DELETE_INVESTMENT = 'FINANCES_ACTIONS/DELETE_INVESTMENT',
  SET_TOTAL_SAVINGS = 'FINANCES_ACTIONS/SET_TOTAL_SAVINGS',
  SET_TARGET_MONTHLY_SAVINGS = 'FINANCES_ACTIONS/SET_TARGET_MONTHLY_SAVINGS',
  SET_DAY_OF_TRANSFER = 'FINANCES_ACTIONS/SET_DAY_OF_TRANSFER',
  SET_CAPACITY_TO_SAVE = 'FINANCES_ACTIONS/SET_CAPACITY_TO_SAVE',
  SET_HAS_SETUP_AUTOMATIC_TRANSFER = 'FINANCES_ACTIONS/SET_HAS_SETUP_AUTOMATIC_TRANSFER',
  SET_CPF_ASSET = 'SET_CPF_ASSET',
  SET_CASH_BALANCE_ASSET = 'FINANCES_ACTIONS/SET_CASH_BALANCE_ASSET',
  DELETE_CASH_BALANCE_ASSET = 'FINANCES_ACTIONS/DELETE_CASH_BALANCE_ASSET',
  SET_CASH_SAVINGS_ASSET = 'FINANCES_ACTIONS/SET_CASH_SAVINGS_ASSET',
  DELETE_CASH_SAVINGS_ASSET = 'DELETE_CASH_SAVINGS_ASSET',
  SET_BOND_ASSET = 'FINANCES_ACTIONS/SET_BOND_ASSET',
  DELETE_BOND_ASSET = 'FINANCES_ACTIONS/DELETE_BOND_ASSET',
  SET_GOLD_ASSET = 'FINANCES_ACTIONS/SET_GOLD_ASSET',
  DELETE_GOLD_ASSET = 'FINANCES_ACTIONS/DELETE_GOLD_ASSET',
  SET_FIXED_DEPOSIT_ASSET = 'FINANCES_ACTIONS/SET_FIXED_DEPOSIT_ASSET',
  DELETE_FIXED_DEPOSIT_ASSET = 'FINANCES_ACTIONS/DELETE_FIXED_DEPOSIT_ASSET',
  SET_INSURANCE_ASSET = 'FINANCES_ACTIONS/SET_INSURANCE_ASSET',
  DELETE_INSURANCE_ASSET = 'FINANCES_ACTIONS/DELETE_INSURANCE_ASSET',
  SET_STOCK_ASSET = 'FINANCES_ACTIONS/SET_STOCK_ASSET',
  DELETE_STOCK_ASSET = 'FINANCES_ACTIONS/DELETE_STOCK_ASSET',
  SET_STRUCTURED_PRODUCT_ASSET = 'FINANCES_ACTIONS/SET_STRUCTURED_PRODUCT_ASSET',
  DELETE_STRUCTURED_PRODUCT_ASSET = 'FINANCES_ACTIONS/DELETE_STRUCTURED_PRODUCT_ASSET',
  SET_UNIT_TRUST_ASSET = 'FINANCES_ACTIONS/SET_UNIT_TRUST_ASSET',
  DELETE_UNIT_TRUST_ASSET = 'FINANCES_ACTIONS/DELETE_UNIT_TRUST_ASSET',
  SET_OTHER_INVESTMENT_ASSET = 'FINANCES_ACTIONS/SET_OTHER_INVESTMENT_ASSET',
  DELETE_OTHER_INVESTMENT_ASSET = 'FINANCES_ACTIONS/DELETE_OTHER_INVESTMENT_ASSET',
  SET_OTHER_NON_INVESTMENT_ASSET = 'FINANCES_ACTIONS/SET_OTHER_NON_INVESTMENT_ASSET',
  DELETE_OTHER_NON_INVESTMENT_ASSET = 'FINANCES_ACTIONS/DELETE_OTHER_NON_INVESTMENT_ASSET',
  SET_TOTAL_BUDGET = 'FINANCES_ACTIONS/SET_TOTAL_BUDGET',
}

export enum FX_RATES_ACTIONS {
  SET_FX_RATES = 'FX_RATES_ACTIONS/SET_FX_RATES',
}

export enum DATA_STORAGE_ACTIONS {
  SET_HAS_TOKEN = 'DATA_STORAGE_ACTIONS/SET_HAS_TOKEN',
  CLEAR_REDUX_DATA = 'DATA_STORAGE_ACTIONS/CLEAR_REDUX_DATA',
}

export enum MESSAGES_ACTIONS {
  ADD_MESSAGE = 'MESSAGES_ACTIONS/ADD_MESSAGE',
  SET_GLOBAL_MODAL = 'MESSAGES_ACTIONS/SET_GLOBAL_MODAL',
}

export enum NOTIFICATIONS_ACTIONS {
  CREATE_NOTIFICATION = 'NOTIFICATIONS_ACTIONS/CREATE_NOTIFICATION',
  ACKNOWLEDGE_NOTIFICATION = 'NOTIFICATIONS_ACTIONS/ACKNOWLEDGE_NOTIFICATION',
}

export enum MYINFO_ACTIONS {
  SET_ID = 'MYINFO_ACTIONS/SET_ID',
  SET_NAME = 'MYINFO_ACTIONS/SET_NAME',
  SET_SEX = 'MYINFO_ACTIONS/SET_SEX',
  SET_MARITAL_STATUS = 'MYINFO_ACTIONS/SET_MARITAL_STATUS',
  SET_DOB = 'MYINFO_ACTIONS/SET_DOB',
  SET_RESIDENTIAL_STATUS = 'MYINFO_ACTIONS/SET_RESIDENTIAL_STATUS',
  SET_EMAIL = 'MYINFO_ACTIONS/SET_EMAIL',
  SET_NOA = 'MYINFO_ACTIONS/SET_NOA',
  SET_CPF = 'MYINFO_ACTIONS/SET_CPF',
  SET_HDB_OWNERSHIP = 'MYINFO_ACTIONS/SET_HDB_OWNERSHIP',
  UPDATE_HDB_OWNERSHIP = 'MYINFO_ACTIONS/UPDATE_HDB_OWNERSHIP',
  SET_USE_PERSON_FULL_DATA = 'MYINFO_ACTIONS/SET_USE_PERSON_FULL_DATA',
  RESET_MYINFO = 'MYINFO_ACTIONS/RESET_MYINFO',
}

export enum MYINFO_RESET_ACTION_SCOPE {
  FULL = 'FULL',
  BASIC = 'BASIC',
}

export enum FINANCIAL_STATUS_ACTIONS {
  SET_RECOMMENDED_ACTIONS = 'FINANCIAL_STATUS_ACTIONS/SET_RECOMMENDED_ACTIONS',
  CREATE_RECOMMENDED_ACTION = 'FINANCIAL_STATUS_ACTIONS/CREATE_RECOMMENDED_ACTION',
  UPDATE_RECOMMENDED_ACTION = 'FINANCIAL_STATUS_ACTIONS/UPDATE_RECOMMENDED_ACTION',
  DELETE_RECOMMENDED_ACTION = 'FINANCIAL_STATUS_ACTIONS/DELETE_RECOMMENDED_ACTION',
}

export enum APIM_ACTIONS {
  SET_APIM_DATA = 'APIM_ACTIONS/SET_APIM_DATA',
  UPDATE_CASA = 'APIM_ACTIONS/UPDATE_CASA',
  UPDATE_FIXED_DEPOSIT = 'APIM_ACTIONS/UPDATE_FIXED_DEPOSIT',
  UPDATE_SECURED_LOAN = 'APIM_ACTIONS/UPDATE_SECURED_LOAN',
  UPDATE_UNSECURED_LOAN = 'APIM_ACTIONS/UPDATE_UNSECURED_LOAN',
  UPDATE_SRS_AMOUNT = 'APIM_ACTIONS/UPDATE_SRS_AMOUNT',
  UPDATE_SRS_HOLDING = 'APIM_ACTIONS/UPDATE_SRS_HOLDING',
  UPDATE_CPFIS_AMOUNT = 'APIM_ACTIONS/UPDATE_CPFIS_AMOUNT',
  UPDATE_CPFIS_HOLDING = 'APIM_ACTIONS/UPDATE_CPFIS_HOLDING',
  UPDATE_UNIT_TRUST_HOLDING = 'APIM_ACTIONS/UPDATE_UNIT_TRUST_HOLDING',
  UPDATE_CDP_HOLDING = 'APIM_ACTIONS/UPDATE_CDP_HOLDING',
}

export enum USER_HISTORY_ACTIONS {
  SET_HISTORY = 'USER_HISTORY_ACTIONS/SET_HISTORY',
}

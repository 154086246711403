import { Reducer } from 'redux';

import { IDataStorageActions } from 'constants/actionTypes';
import { DATA_STORAGE_ACTIONS } from 'constants/enums';
import { IDataStorageStore } from 'constants/storeTypes';

import { mockInitialStates } from './../mockInitialStates';

export const initialState: IDataStorageStore = {
  hasToken: null,
};

export const dataStorageReducer: Reducer<IDataStorageStore, IDataStorageActions> = (
  state: IDataStorageStore = process.env.REACT_APP_DEPLOYMENT_ENV &&
  ['development', 'staging'].includes(process.env.REACT_APP_DEPLOYMENT_ENV) &&
  mockInitialStates.dataStorage !== null
    ? mockInitialStates.dataStorage
    : initialState,
  action: IDataStorageActions
) => {
  if (DATA_STORAGE_ACTIONS.SET_HAS_TOKEN === action.type) {
    return {
      ...state,
      hasToken: action.hasToken,
    };
  }
  return state;
};

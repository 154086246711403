import React from 'react';
import styled, { css } from 'styled-components';
import { prop, switchProp, ifProp } from 'styled-tools';

import { Time } from 'assets/icons';
import { PaperPlaneImage } from 'assets/Templates';
import { Section, Heading, Button } from 'components/Common';
import { colorScale, typography, minWidth, maxWidth, ITheme } from 'themes';

import { SuperstarTemplateContainer } from '../sharedStyles';

import { ARTICLE_SIDE_SPACING_PX } from './constants';
import { disableMarginCollapsing } from './sharedStyles';

const TemplateWrapper = styled.div`
  background: ${colorScale('grey', 0)};
`;

export const ArticleTemplateContainer = styled(SuperstarTemplateContainer)`
  ${disableMarginCollapsing}

  ${maxWidth(
    'tablet',
    css`
      padding: 0 ${ARTICLE_SIDE_SPACING_PX};
    `
  )}
`;

const StyledSection = styled(Section)`
  padding-bottom: 64px;

  ${minWidth(
    'desktop',
    css`
      padding-bottom: 80px;
    `
  )}
`;

const Banner = styled.div<{ bannerColor: (props: ITheme) => string }>`
  background: ${prop('bannerColor')};
  display: flex;
  justify-content: center;
  height: 220px;
  margin-bottom: 32px;

  ${minWidth(
    'desktop',
    css`
      height: 280px;
    `
  )}
`;

const HeroIllustration = styled.div<{ position: IArticleTemplateHeroIllustrationPosition }>`
  ${switchProp('position', {
    center: css`
      height: 80%;
      align-self: center;
    `,
    bottom: css`
      height: 80%;
      align-self: flex-end;
    `,
    spread: css`
      height: 100%;
    `,
  })}

  > img {
    height: 100%;
  }
`;

const StyledHeading = styled(Heading)`
  ${typography('heading-l')}
  margin-bottom: 16px;
`;

const ReadingTime = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-content: flex-start;
  align-items: center;

  color: ${colorScale('grey', 80)};
  ${typography('body-l')}
  margin-bottom: 32px;
`;

const ClockIcon = styled(Time)`
  color: ${colorScale('grey', 50)};
`;

// https://webdelving.com/blog/styled-components-ampersand/
const Content = styled.div<{ isFlush?: boolean }>`
  ${typography('body-l')}
  ${disableMarginCollapsing}

  ${ifProp(
    'isFlush',
    css`
      &&& {
        margin-bottom: 0;
      }
    `
  )}
`;

const CallToActionSection = styled.div`
  ${typography('body-l')}
  ${disableMarginCollapsing}

  background: ${colorScale('primary', 10)};
  border-radius: 12px;
  padding: 24px;
  margin-top: 32px;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const AdditionalContent = styled.div`
  ${typography('body-l')}
  ${disableMarginCollapsing}
  margin-top: 56px;
`;

const FeedbackPanel = styled(Section)`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  justify-content: center;

  background: ${colorScale('grey', 15)};
  padding: 24px 24px 0;
`;

const StyledPaperPlaneImage = styled(PaperPlaneImage)`
  align-self: flex-end;
`;

const FeedbackCallToAction = styled.div`
  padding-bottom: 24px;
`;

const FeedbackHeading = styled(Heading)`
  ${typography('heading-m')}
  margin-bottom: 16px;
`;

type IArticleTemplateHeroIllustrationPosition = 'center' | 'bottom' | 'spread';

interface IProps {
  bannerColor?: (props: ITheme) => string;
  heroIllustration: React.ReactElement;
  heroIllustrationPosition?: IArticleTemplateHeroIllustrationPosition;
  heading: string;
  readingTimeInMins?: number;
  content: React.ReactChild;
  flushContentToEdges?: boolean;
  callToActionSection?: React.ReactChild;
  additionalContent?: React.ReactChild;
  showFeedbackPanel?: boolean;
}

/**
 * Displays a component that renders a formatted article
 * @param [bannerColor] Optional choice to change the background color of the page banner
 * @param heroIllustration Compulsory illustration to be displayed on the page banner
 * @param [heroIllustrationPosition] Optional choice to define the vertical alignment of the hero illustration on the page banner
 * @param heading Compulsory page heading
 * @param [readingTimeInMins] Optional time taken it takes in minutes to read the article
 * @param content Compulsory article content
 * @param [flushContentToEdges] Determines if content should flush to screen edges. Useful for full width background, requires ArticleTemplateContainer to wrap the content being passed in.
 * @param [callToActionSection] Optional call to action content meant for readers to take action
 * @param [additionalContent] Optional content below the call the action section
 * @param [showFeedbackPanel] Optional flag to show feedback panel. Defaults to true
 */
export const ArticleTemplate = ({
  bannerColor = colorScale('primary', 10),
  heroIllustration,
  heroIllustrationPosition = 'center',
  heading,
  readingTimeInMins,
  content,
  flushContentToEdges = false,
  callToActionSection,
  additionalContent,
  showFeedbackPanel = true,
}: IProps) => {
  const openFeedbackFormInNewTab = () => {
    const newWindow = window.open(
      process.env.REACT_APP_FEEDBACK_FORM_URL,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <TemplateWrapper>
      <Banner bannerColor={bannerColor}>
        <HeroIllustration position={heroIllustrationPosition}>{heroIllustration}</HeroIllustration>
      </Banner>

      <StyledSection>
        <ArticleTemplateContainer>
          <StyledHeading>{heading}</StyledHeading>
          {readingTimeInMins !== undefined && (
            <ReadingTime>
              <ClockIcon />
              {readingTimeInMins} min read
            </ReadingTime>
          )}
        </ArticleTemplateContainer>

        {flushContentToEdges ? (
          <Content isFlush={flushContentToEdges}>{content}</Content>
        ) : (
          <ArticleTemplateContainer>
            <Content>{content}</Content>
          </ArticleTemplateContainer>
        )}

        {callToActionSection && (
          <ArticleTemplateContainer>
            <CallToActionSection>{callToActionSection}</CallToActionSection>
          </ArticleTemplateContainer>
        )}

        {additionalContent && (
          <ArticleTemplateContainer>
            <AdditionalContent>{additionalContent}</AdditionalContent>
          </ArticleTemplateContainer>
        )}
      </StyledSection>

      {showFeedbackPanel && (
        <FeedbackPanel>
          <StyledPaperPlaneImage />
          <FeedbackCallToAction>
            <FeedbackHeading>Found this helpful?</FeedbackHeading>
            <Button variant="secondary" widthStyle="fluid" onClick={openFeedbackFormInNewTab}>
              Leave a feedback
            </Button>
          </FeedbackCallToAction>
        </FeedbackPanel>
      )}
    </TemplateWrapper>
  );
};

import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import { ShallowWaveSvg } from 'assets/Templates';
import { ITheme, colorScale, heights, layers } from 'themes';

import { TEMPLATE_BACKGROUND_ID } from './constants';
import { convertPxStringToNumber } from './helpers';

export const SEA_SURFACE_ID = 'sea-surface-id';
const DEFAULT_SEA_LEVEL = '0px';

const SeaWater = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${layers.templateBackgroundBack};

  height: 100%;
`;

const WaterWave = styled(ShallowWaveSvg)<{ $seaColor: (props: ITheme) => string }>`
  position: relative;
  color: ${prop('$seaColor')};
`;

const WaterBody = styled.span<{
  seaColor: (props: ITheme) => string;
  seaLevel: string;
  additionalSeaLevel: number;
}>`
  background: ${prop('seaColor')};
  height: calc(100% - ${prop('seaLevel')} + ${prop('additionalSeaLevel')}px);
`;

interface IProps {
  seaColor?: (props: ITheme) => string;
  globalWarmingLevel?: number;
}

/**
 * Requires the page to have an element with the id SEA_SURFACE_ID.
 * Renders a wave background from bottom up to the position of where the aforementioned element starts.
 *
 * @param seaColor Determines the color of the whole wave background
 * @param globalWarmingLevel Pixel value to offset and raise the height of the wave from its relative position
 */
export const Sea = ({
  seaColor = colorScale('supporting-blue', 50),
  globalWarmingLevel = 0,
}: IProps) => {
  const [seaLevel, setSeaLevel] = useState(DEFAULT_SEA_LEVEL);

  useLayoutEffect(() => {
    const adjustSeaLevel = () => {
      const seaSurface = document.getElementById(SEA_SURFACE_ID);

      if (seaSurface) {
        const navBarHeight = convertPxStringToNumber(heights.navbar);
        const seaSurfaceContainerPosition = seaSurface.getBoundingClientRect().top;

        const seaHeight = seaSurfaceContainerPosition + window.scrollY - navBarHeight;

        setSeaLevel(`${seaHeight}px`);
      } else {
        // Fallback for pages using templates with no predetermined sea surface
        // and has not specified the sea surface in its content,
        // set sea level to half of its parent container's height
        setSeaLevel('50%');
      }
    };

    if (seaLevel === DEFAULT_SEA_LEVEL) {
      adjustSeaLevel();
    }

    window.addEventListener('resize', adjustSeaLevel);

    return () => {
      window.removeEventListener('resize', adjustSeaLevel);
    };
  }, [seaLevel]);

  return (
    <SeaWater id={TEMPLATE_BACKGROUND_ID}>
      <WaterWave $seaColor={seaColor} />
      <WaterBody seaColor={seaColor} seaLevel={seaLevel} additionalSeaLevel={globalWarmingLevel} />
    </SeaWater>
  );
};

import React from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { IGoalForRender } from 'constants/storeTypes';
import { formatCurrency } from 'utilities/currencyUtilities';
import { formatDate } from 'utilities/dateUtilities';

import { Button } from '../Button/Button';
import { Section } from '../SectionHeading/Section';

import { FieldLabel, FieldWrapper, SecondaryTargetButton, StyledHeading } from './sharedStyles';

const RestoreTargetButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`;

const StyledSecondaryTargetButton = styled(SecondaryTargetButton)`
  margin-top: 8px;
  width: 100%;
`;

interface IProps {
  goal: IGoalForRender;
  transitionStatus?: TransitionStatus;
  handleRestoreAction: () => void;
  handleDeleteAction: () => void;
}

export class CompletedGoalContent extends React.Component<IProps> {
  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.transitionStatus === 'entering' && this.props.transitionStatus === 'entered') {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  }

  public render() {
    const { goal, handleDeleteAction, handleRestoreAction } = this.props;
    const { name, paidOutAmount, paidOutDate } = goal;

    return (
      <Section>
        <StyledHeading>Completed target</StyledHeading>
        <FieldWrapper>
          <FieldLabel>Name</FieldLabel>
          {name}
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>Date completed</FieldLabel>
          {paidOutDate && formatDate(paidOutDate)}
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>Amount spent on target</FieldLabel>
          {paidOutAmount && formatCurrency(paidOutAmount, 0)}
        </FieldWrapper>
        <RestoreTargetButton onClick={handleRestoreAction} widthStyle="fluid">
          Restore target
        </RestoreTargetButton>
        <StyledSecondaryTargetButton
          variant="tertiary"
          onClick={handleDeleteAction}
          widthStyle="fluid"
        >
          Delete target
        </StyledSecondaryTargetButton>
      </Section>
    );
  }
}

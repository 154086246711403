import React, { useCallback, useRef, useEffect, useContext } from 'react';
import uuid from 'uuid/v4';

import { scrollElementIntoView } from 'utilities/animationUtilities';

import { ValidationContext } from '../ValidationContext';
import { IValidationType } from '../ValidationTypes';

export interface IValidatorRenderParams {
  errorMessage?: string;
  placerRef: React.RefObject<HTMLDivElement>;
}

export interface IValidatorProps<T> {
  value: T;
  validations: IValidationType<T>[];
  render: (renderParams: IValidatorRenderParams) => JSX.Element;
}

export const Validator = <T extends any>({ value, validations, render }: IValidatorProps<T>) => {
  const messenger = useContext(ValidationContext);
  const keyname = useRef<string>(uuid()).current;
  const placerRef = useRef<HTMLDivElement>(null);

  const getCurrentY = useCallback(
    () => window.scrollY + (placerRef.current?.getBoundingClientRect().top || 0),
    [placerRef]
  );

  const scrollIntoView = useCallback(
    () => placerRef.current && scrollElementIntoView(placerRef.current),
    [placerRef]
  );

  const errorMessage = validations
    .map((validation) => validation(value))
    .filter((error) => error !== undefined)
    .join(', ');

  useEffect(() => {
    messenger &&
      messenger.registerValidator(keyname, { getCurrentY, scrollIntoView }, errorMessage === '');

    return () => {
      messenger && messenger.unregisterValidator(keyname);
    };
  }, [messenger, keyname, getCurrentY, scrollIntoView, errorMessage]);

  useEffect(() => {
    messenger && messenger.updateValidatorValidity(keyname, errorMessage === '');
  }, [messenger, keyname, errorMessage]);

  const renderParams = { errorMessage, placerRef };

  return render(renderParams);
};

import mixpanel from 'mixpanel-browser';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { USE_ANALYTICS_KEY } from 'components/App/Addons/AnalyticsToggler/constants';
import { IStore } from 'constants/storeTypes';
import { featureDecisions } from 'featureDecisions';

export * from './behaviouralFunctions';
export * from './configFunctions';
export * from './unclassifiedFunctions';
export * from './expenseManagerFunctions';

// https://help.mixpanel.com/hc/en-us/articles/360001337103#collecting-last-touch-utms
function getQueryParam(url: string, param: string) {
  // Expects a raw URL
  // eslint-disable-next-line no-empty-character-class
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  const regexS = `[?&]${param}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (
    results === null ||
    (results && typeof results[1] !== 'string' && (results[1] as any).length)
  ) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
}

function campaignParams() {
  const campaignKeywords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
  const cleanedKeywords = ['UTM Source', 'UTM Medium', 'UTM Campaign', 'UTM Content', 'UTM Term'];
  let kw = '';
  const params = {};
  const firstParams = {};
  let index;
  for (index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(document.URL, campaignKeywords[index]);
    if (kw.length) {
      params[`${cleanedKeywords[index]} [last touch]`] = kw;
    }
  }
  for (index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(document.URL, campaignKeywords[index]);
    if (kw.length) {
      firstParams[`${cleanedKeywords[index]} [first touch]`] = kw;
    }
  }
  mixpanel.people.set(params);
  mixpanel.people.set_once(firstParams);
  mixpanel.register(params);
}

export async function initialiseAnalytics() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY || 'stubMixpanelKey', {
    cross_site_cookie: true,
  });

  const isNetlifyEnv =
    featureDecisions.useAnalyticsToggler && process.env.NODE_ENV === 'production';
  const isDevEnv = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

  if (sessionStorage.getItem(USE_ANALYTICS_KEY) === 'enabled') {
    mixpanel.opt_in_tracking();
  } else if (isNetlifyEnv || isDevEnv) {
    // Opt out by default under these environments to prevent data spamming to mixpanel
    mixpanel.opt_out_tracking();
  }

  campaignParams();
}

export type ThunkResult<R> = ThunkAction<R, IStore, undefined, AnyAction>;

export function resetAnalyticsIdentifier() {
  mixpanel.reset();
}

export const captureLogin = () => {
  mixpanel.track('Logged in');
};

export const captureLogout = () => {
  mixpanel.track('Logged out');
};

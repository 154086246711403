import React from 'react';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { Caution, Tick } from 'assets/icons';
import { IGoalForRender, IEmergencyFundForRender } from 'constants/storeTypes';
import { colorScale, typography } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';

const Box = styled.div`
  display: flex;
  border-radius: ${fromTheme('borders.radiusRound')};
  padding: 4px 12px;
  align-items: center;
  margin: 0 8px 0 0;
  align-self: flex-start;
`;

const StyledCautionIcon = styled(Caution)`
  margin-right: 4px;
  height: 1.2em;
  width: 1.2em;
`;

const StyledTickIcon = styled(Tick)`
  margin-right: 4px;
  height: 1.2em;
  width: 1.2em;
`;

const AchievabilityText = styled.p`
  ${typography('label')};
  margin: 0;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

const AchievableBox = styled(Box)`
  background: ${colorScale('supporting-green', 20)};
  color: ${colorScale('supporting-green', 40)};
`;

const UnachievableBox = styled(Box)`
  background: ${colorScale('supporting-red', 20)};
  color: ${colorScale('supporting-red', 40)};
`;

interface ITargetDetails {
  target: IEmergencyFundForRender | IGoalForRender;
  projectedTarget: IEmergencyFundForRender | IGoalForRender;
}

interface IProps {
  details: ITargetDetails;
}

export class AchievabilityBox extends React.Component<IProps> {
  public render() {
    const { details } = this.props;
    const { target, projectedTarget } = details;

    return target.totalAmount === target.currentAmount ? (
      <AchievableBox>
        <StyledTickIcon />
        <AchievabilityText>Achieved</AchievabilityText>
      </AchievableBox>
    ) : projectedTarget.totalAmount === projectedTarget.currentAmount ? (
      <AchievableBox>
        <AchievabilityText>Achievable</AchievabilityText>
      </AchievableBox>
    ) : (
      <UnachievableBox>
        <StyledCautionIcon />
        <AchievabilityText>{`Short of ${formatCurrency(
          projectedTarget.totalAmount - projectedTarget.currentAmount
        )}`}</AchievabilityText>
      </UnachievableBox>
    );
  }
}

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Open } from 'assets/icons';
import { Section, Heading } from 'components/Common';
import { typeScale, colorScale } from 'themes';

import { StyledHeading, PageWrapper } from '../sharedStyles';

import { siteMapData } from './content';

const SectionWrapper = styled(Section)`
  display: flex;
  flex-direction: column;
`;

const Bar = styled.div`
  height: 8px;
  width: 64px;
  background-color: ${colorScale('primary', 50)};
  margin-bottom: 24px;
`;

const StyledSubHeading = styled(Heading)`
  font-size: ${typeScale(2)};
  margin: 16px 0 0.33em;
`;

const StyledLink = styled.a`
  font-size: ${typeScale(0)};
  width: fit-content;
`;

const StyledOpen = styled(Open)`
  margin-left: 8px;
`;

const StyledListItem = styled.li`
  list-style: disc;
`;

type NestedListItem = {
  text: string;
  url: string;
  isExternal: boolean;
  children?: NestedListItem[];
};

const StyledUl = styled.ul`
  text-align: left;
  padding-inline-start: 32px;
  margin: 0;

  > li {
    padding-left: 8px;
    margin-bottom: 16px;
  }
`;

const renderNestedList = (object: NestedListItem): React.ReactElement => {
  return (
    <StyledUl>
      <StyledListItem>
        {object.isExternal ? (
          <StyledLink href={object.url} target="_blank" rel="noopener noreferrer">
            {object.text}
            <StyledOpen />
          </StyledLink>
        ) : (
          <Link to={object.url}>{object.text}</Link>
        )}
      </StyledListItem>
      {object?.children?.map((child) => renderNestedList(child))}
    </StyledUl>
  );
};

export const SiteMapPage: React.FC = () => {
  return (
    <PageWrapper>
      <StyledHeading>Site Map</StyledHeading>
      <SectionWrapper>
        {siteMapData.map((section) => (
          <React.Fragment key={section.subHeadingTitle}>
            <StyledSubHeading>{section.subHeadingTitle}</StyledSubHeading>
            <Bar />
            {section.links.map((d: NestedListItem) => renderNestedList(d))}
          </React.Fragment>
        ))}
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SiteMapPage;

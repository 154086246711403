import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { recommendedActions } from '../../recommendedActions';
import { IEmergencyFundRatioInputs } from '../interfaces';

export const EF2: IRule<IEmergencyFundRatioInputs> = {
  condition: ({ emergencyFundMonths, isWorking }: IEmergencyFundRatioInputs) =>
    emergencyFundMonths >= 3 && emergencyFundMonths < 6 && isWorking,
  result: {
    key: RULE.EF2,
    indicatorStatus: indicatorStatuses.FAIR,
    recommendedActions: [recommendedActions.TRA_SR_C],
    resources: [
      // Some links
    ],
  },
};

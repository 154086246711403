import React from 'react';
import styled from 'styled-components';

import { Sliders, Undo } from 'assets/icons';
import { PanelList, Button } from 'components/Common';
import { IEmergencyFundForRender, IGoalForRender } from 'constants/storeTypes';

import { AllocateSavingsPanel } from './AllocateSavingsPanel';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: min-content;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-right: 8px;
`;

interface IProps {
  currEFAmount: number;
  currGoalAmounts: { [key: string]: number };
  emergencyFund: IEmergencyFundForRender;
  sortedGoals: IGoalForRender[];
  optimiseUndoState: 'optimise' | 'undo';
  availableFunds: number;
  unallocatedSavings: number;
  handleEFAmountChange: (amount: number, id: string) => void;
  handleGoalAmountChange: (amount: number, goalID: string) => void;
  handleAutoAllocateTargets: () => void;
  handleUndo: () => void;
}

export const AllocationPanel: React.FC<IProps> = ({
  currEFAmount,
  currGoalAmounts,
  emergencyFund,
  sortedGoals,
  optimiseUndoState,
  unallocatedSavings,
  handleEFAmountChange,
  handleGoalAmountChange,
  handleAutoAllocateTargets,
  handleUndo,
}) => (
  <Wrapper>
    <IconButtonWrapper>
      {optimiseUndoState === 'optimise' ? (
        <Button
          data-testid="auto-alloc-btn"
          variant="tertiary"
          size="medium"
          edge="right"
          icon={<Sliders />}
          disabled={unallocatedSavings <= 0}
          onClick={handleAutoAllocateTargets}
        >
          Auto-allocate
        </Button>
      ) : (
        <Button
          data-testid="undo-btn"
          variant="tertiary"
          size="medium"
          edge="right"
          icon={<Undo />}
          onClick={handleUndo}
        >
          Undo
        </Button>
      )}
    </IconButtonWrapper>
    <PanelList>
      {emergencyFund.totalAmount > 0 && (
        <AllocateSavingsPanel
          title="Emergency fund"
          currentAmount={currEFAmount}
          totalAmount={emergencyFund.totalAmount}
          id="EF"
          date={emergencyFund.date}
          onAmountChange={handleEFAmountChange}
        />
      )}
      {sortedGoals.map(({ name, id, totalAmount, date }) => (
        <AllocateSavingsPanel
          title={name}
          currentAmount={currGoalAmounts[id]}
          totalAmount={totalAmount}
          id={id}
          date={date}
          onAmountChange={handleGoalAmountChange}
          key={id}
        />
      ))}
    </PanelList>
  </Wrapper>
);

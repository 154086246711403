import { ISetUserHistory } from 'constants/actionTypes';
import { USER_HISTORY_ACTIONS } from 'constants/enums';
import { IUserDataWithoutHistory } from 'constants/responseTypes';

export const setUserHistory = (history: {
  [dt: string]: IUserDataWithoutHistory;
}): ISetUserHistory => ({
  type: USER_HISTORY_ACTIONS.SET_HISTORY,
  history,
});

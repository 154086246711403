import { css } from 'styled-components';
import { ifProp, theme as fromTheme } from 'styled-tools';

import { colorScale } from 'themes';

export const tertiaryProperties = css`
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${colorScale('primary', 40)};
  border-radius: ${fromTheme('borders.radiusRound')};
  font-weight: 600;

  ${ifProp(
    'disabled',
    css`
      color: ${colorScale('grey', 50)};
    `,
    css`
      &:hover,
      &:focus,
      &:active {
        background: ${colorScale('grey', 10)};
      }
    `
  )}
`;

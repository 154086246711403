import React from 'react';
import styled from 'styled-components';

import { colorScale, typography } from 'themes';

import {
  CIRCLE_BORDER_PX,
  TIMELINE_SUBSECTIONS_GAP_PX,
  TIMELINE_SECTIONS_GAP_PX,
} from './constants';
import { GridWrapper, circleProperties, lineProperties } from './sharedStyles';

const Line = styled.div`
  ${lineProperties}

  height: calc(100% + ${TIMELINE_SECTIONS_GAP_PX});
  top: -${TIMELINE_SECTIONS_GAP_PX};
`;

const Circle = styled.div`
  ${circleProperties}
  background: ${colorScale('primary', 50)};
`;

const Title = styled.p`
  ${typography('heading-s')}
  margin-top: 8px;
`;

const Subtitle = styled.p`
  ${typography('label-bold')}
  color: ${colorScale('grey', 80)};
  margin-top: ${CIRCLE_BORDER_PX};
`;

const TimelineSubsectionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${TIMELINE_SUBSECTIONS_GAP_PX};
`;

interface IProps {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

/**
 * Displays a section in the timeline, completed with a circular indicator
 * at the start of the section.
 * @param title
 * @param subtitle A small subtext that displays above the title
 * @param children A block of TimelineSubsections
 */
export const TimelineSection = ({ title, subtitle, children }: IProps) => {
  return (
    <div className="timeline-section">
      <GridWrapper>
        <Line className="section-line" />
        <Circle />
        <div>
          <Subtitle>{subtitle.toUpperCase()}</Subtitle>
          <Title>{title}</Title>
        </div>
      </GridWrapper>

      <TimelineSubsectionsWrapper>{children}</TimelineSubsectionsWrapper>
    </div>
  );
};

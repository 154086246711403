import React from 'react';
import styled from 'styled-components';

import { ManSittingOnCoinsImage } from 'assets/Retirement';
import { PurpleCtaPanel } from 'components/Composite';
import { TARGETS } from 'constants/urls';

const StyledPurpleCtaPanel = styled(PurpleCtaPanel)`
  margin-top: 32px;
`;

export const RetirementCtaPanel = () => (
  <StyledPurpleCtaPanel
    image={<ManSittingOnCoinsImage />}
    title="Create a retirement target"
    desc="After estimating how much you need for retirement, create a target and work towards it."
    route={TARGETS.baseRoute}
    ctaText="Create a retirement target"
  />
);

import React from 'react';

import { BaguetteCTAButton } from './BaguetteCTAButton';
import { IFullButtonProps } from './constants';
import { DinnerRollCTAButtons } from './DinnerRollCTAButtons';
import { SandwichCTAButtons } from './SandwichCTAButtons';

export const CTAButtons = ({
  primaryText,
  primaryOnClick,
  primaryVariant = 'primary',
  primaryProps,
  secondaryText,
  secondaryOnClick,
  secondaryVariant,
  secondaryProps,
  variant = 'vertical',
  ...otherProps
}: IFullButtonProps) => {
  if (secondaryText && secondaryOnClick) {
    return variant === 'vertical' ? (
      <SandwichCTAButtons
        primaryText={primaryText}
        primaryOnClick={primaryOnClick}
        primaryVariant={primaryVariant}
        primaryProps={primaryProps}
        secondaryText={secondaryText}
        secondaryOnClick={secondaryOnClick}
        secondaryVariant={secondaryVariant}
        secondaryProps={secondaryProps}
        {...otherProps}
      />
    ) : (
      <DinnerRollCTAButtons
        primaryText={primaryText}
        primaryOnClick={primaryOnClick}
        primaryVariant={primaryVariant}
        primaryProps={primaryProps}
        secondaryText={secondaryText}
        secondaryOnClick={secondaryOnClick}
        secondaryVariant={secondaryVariant}
        secondaryProps={secondaryProps}
        {...otherProps}
      />
    );
  }
  return (
    <BaguetteCTAButton
      primaryText={primaryText}
      primaryOnClick={primaryOnClick}
      primaryVariant={primaryVariant}
      primaryProps={primaryProps}
      {...otherProps}
    />
  );
};

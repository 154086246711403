import React from 'react';
import styled from 'styled-components';

import { Bell } from 'assets/icons';
import { FINANCIAL_LITERACY_QUIZ } from 'constants/urls';
import { colorScale, typography } from 'themes';

import { ContentWrapper } from '../sharedStyles';

import { StyledArrowRight, iconStyles } from './sharedStyles';

const Wrapper = styled.div`
  background: ${colorScale('primary', 20)};
  cursor: pointer;
`;

const StyledContentWrapper = styled(ContentWrapper)`
  color: ${colorScale('primary', 50)};
  gap: 16px;

  p {
    margin: 0;
    width: 100%;
    ${typography('body-m')};
  }
`;

const StyledBell = styled(Bell)`
  ${iconStyles}
`;

export const FLQAnnouncementBanner = () => {
  // FLQ cutoff date is 31 Dec 2024 23:59
  const CUTOFF_DATE = new Date('2024-12-31T23:59:59');
  const START_DATE = new Date('2024-10-01T00:00:00');
  const CURRENT_DATE = new Date();

  const handleOnClick = () => {
    window.open(FINANCIAL_LITERACY_QUIZ.baseRoute, '_blank', 'noopener,noreferrer');
  };

  return CURRENT_DATE >= START_DATE && CURRENT_DATE <= CUTOFF_DATE ? (
    <Wrapper onClick={handleOnClick}>
      <StyledContentWrapper>
        <StyledBell />
        <p>
          Click here to try our <b>Financial Literacy Quiz</b> and stand a chance to receive $20
          vouchers.
        </p>
        <StyledArrowRight />
      </StyledContentWrapper>
    </Wrapper>
  ) : (
    <></>
  );
};

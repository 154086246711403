import React from 'react';

import { Form, IFormProps } from 'components/Common/Form/Form';

import { ValidationContext } from '../ValidationContext';
import { ValidationMessenger } from '../ValidationMessenger/ValidationMessenger';

interface IState {
  valid: boolean;
}

export class ValidationForm extends React.Component<IFormProps, IState> {
  private readonly messenger: ValidationMessenger = new ValidationMessenger();

  constructor(props: IFormProps) {
    super(props);

    this.setValid = this.setValid.bind(this);

    const valid = this.messenger.registerForm({ setFormState: this.setValid });
    this.state = { valid };
  }

  public setValid(valid: boolean) {
    this.setState({ valid });
  }

  public componentWillUnmount() {
    this.messenger.unregisterForm();
    this.messenger.reset();
  }

  public render() {
    const onSubmit = this.state.valid ? this.props.onSubmit : () => null;

    return (
      <Form
        {...this.props}
        disableAfterSubmit={this.state.valid && this.props.disableAfterSubmit}
        onSubmit={onSubmit}
      >
        <ValidationContext.Provider value={this.messenger}>
          {this.props.children}
        </ValidationContext.Provider>
      </Form>
    );
  }
}

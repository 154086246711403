import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import { typography, colorScale } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  border-bottom: 1px solid ${colorScale('grey', 20)};
  text-align: left;
  max-width: 100%;
`;

export const Text = styled.p<{ color?: string }>`
  ${typography('body-s')}
  color: ${colorScale('grey', 90)};
  color: ${prop('color')};
  align-self: center;
`;

const BulletTextWrapper = styled.div<{ color: string }>`
  display: flex;
  align-self: center;

  &:before {
    content: '';
    background: ${prop('color')};
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    align-self: center;
    margin-right: 4px;
  }

  > :first-child {
    font-weight: bold;
    width: 100%;
    word-break: break-word;
    hyphens: auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

interface IProps {
  category: string;
  amount: number;
  budget: number;
  mainColor: string;
  textColor: string;
}

export const CategoryBreakdownRow = ({
  category,
  amount,
  budget,
  mainColor,
  textColor,
}: IProps) => {
  return (
    <Wrapper data-testid="category-row">
      <BulletTextWrapper color={mainColor}>
        <Text color={textColor}>{category}</Text>
      </BulletTextWrapper>
      <Text>{formatCurrency(amount)}</Text>
      <Text>{formatCurrency(budget)}</Text>
    </Wrapper>
  );
};

import React from 'react';
import styled, { css } from 'styled-components';

import { Section, Heading, Modal } from 'components/Common';
import { typography, minWidth } from 'themes';

const StyledModal = styled(Modal)`
  text-align: left;
  padding-top: 24px;

  ${minWidth(
    'tablet',
    css`
      max-width: 384px;
    `
  )}
`;

const StyledSection = styled(Section)`
  padding: 0;
`;

const StyledHeading = styled(Heading)`
  ${typography('heading-m')}
  margin: 0 16px 16px 0;
`;

export const InfoModalList = styled.ul`
  padding-inline-start: 24px;
`;

export const InfoModalListItem = styled.li`
  margin-bottom: 8px;
`;

export interface IInfoModalProps {
  /**
   *  titleText defines the name of the modal for users who requires other means of reading such as through a screen reader.
   *  It should be descriptive and addressable; it is not the same thing as title.
   */
  titleText: string;
  title: string;
  mounted: boolean;
  onExit: () => void;
  showCloseButton?: boolean;
  underlayClickExits?: boolean;
  escapeExits?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export const InfoModal: React.FC<IInfoModalProps> = ({
  titleText,
  title,
  mounted,
  onExit,
  children,
  showCloseButton,
  underlayClickExits,
  escapeExits,
  ...otherProps
}) => (
  <StyledModal
    titleText={titleText}
    showCloseButton={showCloseButton}
    underlayClickExits={underlayClickExits}
    escapeExits={escapeExits}
    mounted={mounted}
    onExit={onExit}
    {...otherProps}
  >
    <StyledSection>
      <StyledHeading>{title}</StyledHeading>
      {children}
    </StyledSection>
  </StyledModal>
);

import {
  IApimAssetFormatted,
  IApimCasaFormatted,
  IApimFixedDepositFormatted,
  IApimUnitTrustHoldingFormatted,
  IApimCpfisSrsHoldingFormatted,
  IApimCpfisSrsAmountFormatted,
  IApimCdpHoldingFormatted,
  IGetExcludePercentageShareOption,
} from 'redux/apim/selectors';

import { ENTER_OPTION, ASSET_TYPE, APIM_ASSET_FORMATTED_TYPE } from 'constants/enums';
import { convertToSGD } from 'utilities/currencyUtilities';
import { goldQuantityToOz, getGoldInOz } from 'utilities/goldUtilities';

import {
  IManualAsset,
  ICashBalanceAsset,
  ICashSavingsAsset,
  IInsuranceAsset,
  IBondAsset,
  IStockAsset,
  IUnitTrustAsset,
  IOtherInvestmentAsset,
  IOtherNonInvestmentAsset,
  IStructuredProductAsset,
  IGoldAsset,
  IFixedDepositAsset,
} from './../constants/storeTypes';

export const calcManualAssetsValueInSGD = (manualAssets: IManualAsset[]) =>
  manualAssets.reduce((totalValue, asset) => totalValue + calcManualAssetValueInSGD(asset), 0);

export const calcManualAssetValueInSGD = (asset: IManualAsset) => {
  switch (asset.type) {
    case ASSET_TYPE.CASH_BALANCE: {
      return calcManualCashBalanceAssetValueInSGD(asset as ICashBalanceAsset);
    }
    case ASSET_TYPE.CASH_SAVINGS: {
      return calcManualCashSavingsAssetValueinSGD(asset as ICashSavingsAsset);
    }
    case ASSET_TYPE.OTHER_NON_INVESTMENT: {
      return calcManualOtherNonInvestmentAssetValueInSGD(asset as IOtherNonInvestmentAsset);
    }
    case ASSET_TYPE.BOND: {
      return calcManualBondAssetValueInSGD(asset as IBondAsset);
    }
    case ASSET_TYPE.STOCK: {
      return calcManualStockAssetValueInSGD(asset as IStockAsset);
    }
    case ASSET_TYPE.UNIT_TRUST: {
      return calcManualUnitTrustAssetValueInSGD(asset as IUnitTrustAsset);
    }
    case ASSET_TYPE.OTHER_INVESTMENT: {
      return calcManualOtherInvestmentAssetValueInSGD(asset as IOtherInvestmentAsset);
    }
    case ASSET_TYPE.GOLD: {
      return calcManualGoldAssetValueInSGD(asset as IGoldAsset);
    }
    case ASSET_TYPE.INSURANCE: {
      return calcManualInsuranceAssetValueInSGD(asset as IInsuranceAsset);
    }
    case ASSET_TYPE.STRUCTURED_PRODUCT: {
      return calcManualStructuredProductAssetValueInSGD(asset as IStructuredProductAsset);
    }
    case ASSET_TYPE.FIXED_DEPOSIT: {
      return calcManualFixedDepositAssetValueInSGD(asset as IFixedDepositAsset);
    }
    default:
      return 0;
  }
};

export const calcApimAssetsValueInSGD = (
  apimAssets: IApimAssetFormatted[],
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
) =>
  apimAssets.reduce<number>(
    (totalValue, asset) => totalValue + calcApimAssetValueInSGD(asset, { excludePercentageShare }),
    0
  );

export const calcApimAssetValueInSGD = (
  asset: IApimAssetFormatted,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
) => {
  switch (asset.assetType) {
    case APIM_ASSET_FORMATTED_TYPE.CASA: {
      return calcApimCasaAssetValueInSGD(asset as IApimCasaFormatted, { excludePercentageShare });
    }
    case APIM_ASSET_FORMATTED_TYPE.FD: {
      return calcApimFixedDepositAssetValueInSGD(asset as IApimFixedDepositFormatted, {
        excludePercentageShare,
      });
    }
    case APIM_ASSET_FORMATTED_TYPE.UNIT_TRUST_HOLDING: {
      return calcApimUnitTrustHoldingAssetValue(asset as IApimUnitTrustHoldingFormatted, {
        excludePercentageShare,
      });
    }
    case APIM_ASSET_FORMATTED_TYPE.CPFIS_HOLDING: {
      return calcApimCpfisHoldingAssetValueInSGD(asset as IApimCpfisSrsHoldingFormatted);
    }
    case APIM_ASSET_FORMATTED_TYPE.SRS_HOLDING: {
      return calcApimSrsHoldingAssetValueInSGD(asset as IApimCpfisSrsHoldingFormatted);
    }
    case APIM_ASSET_FORMATTED_TYPE.CPFIS_AMOUNT: {
      return calcApimCpfisAmountAssetValueInSGD(asset as IApimCpfisSrsAmountFormatted);
    }
    case APIM_ASSET_FORMATTED_TYPE.SRS_AMOUNT: {
      return calcApimSrsAmountAssetValueInSGD(asset as IApimCpfisSrsAmountFormatted);
    }
    case APIM_ASSET_FORMATTED_TYPE.CDP: {
      return calcApimCdpAmountAssetValueInSGD(asset as IApimCdpHoldingFormatted, {
        excludePercentageShare,
      });
    }
    default:
      return 0;
  }
};

export const calcManualCashBalanceAssetValueInSGD = ({ totalValueAmount }: ICashBalanceAsset) =>
  totalValueAmount;

export const calcManualCashSavingsAssetValueinSGD = ({
  currency,
  totalValueAmount,
}: ICashSavingsAsset) => convertToSGD(totalValueAmount, currency);

export const calcManualOtherNonInvestmentAssetValueInSGD = ({
  currency,
  totalValueAmount,
}: IOtherNonInvestmentAsset) => convertToSGD(totalValueAmount, currency);

export const calcManualBondAssetValueInSGD = ({
  unitPrice,
  quantity,
  totalValueAmount,
  currency,
  enterOption,
}: IBondAsset) =>
  convertToSGD(
    enterOption === ENTER_OPTION.TOTAL_VALUE ? totalValueAmount : unitPrice * quantity,
    currency
  );

export const calcManualStockAssetValueInSGD = ({
  unitPrice,
  quantity,
  totalValueAmount,
  currency,
  enterOption,
}: IStockAsset) =>
  convertToSGD(
    enterOption === ENTER_OPTION.TOTAL_VALUE ? totalValueAmount : unitPrice * quantity,
    currency
  );

export const calcManualUnitTrustAssetValueInSGD = ({
  unitPrice,
  quantity,
  totalValueAmount,
  currency,
  enterOption,
}: IUnitTrustAsset) =>
  convertToSGD(
    enterOption === ENTER_OPTION.TOTAL_VALUE ? totalValueAmount : unitPrice * quantity,
    currency
  );

export const calcManualOtherInvestmentAssetValueInSGD = ({
  unitPrice,
  quantity,
  totalValueAmount,
  currency,
  enterOption,
}: IOtherInvestmentAsset) =>
  convertToSGD(
    enterOption === ENTER_OPTION.TOTAL_VALUE ? totalValueAmount : unitPrice * quantity,
    currency
  );

export const calcManualGoldAssetValueInSGD = ({
  quantity,
  totalValueAmount,
  currency,
  enterOption,
}: IGoldAsset) =>
  convertToSGD(
    enterOption === ENTER_OPTION.TOTAL_VALUE
      ? totalValueAmount
      : parseFloat(quantity) * convertToSGD(100, 'XAU'),
    currency
  );

export const calcManualInsuranceAssetValueInSGD = ({
  totalValueAmount,
  currency,
}: IInsuranceAsset) => convertToSGD(totalValueAmount, currency);

export const calcManualStructuredProductAssetValueInSGD = ({
  totalValueAmount,
  currency,
}: IStructuredProductAsset) => convertToSGD(totalValueAmount, currency);

export const calcManualFixedDepositAssetValueInSGD = ({
  totalValueAmount,
  currency,
}: IFixedDepositAsset) => convertToSGD(totalValueAmount, currency);

export const calcApimCasaAssetValueInSGD = (
  asset: IApimCasaFormatted,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
) => {
  const { amountByCurrency, percentageShare, isJoint } = asset;
  const percentage = !excludePercentageShare && isJoint ? percentageShare * 0.01 : 1;

  return Math.round(
    Object.entries(amountByCurrency).reduce(
      (a, [ccy, amount]) => a + convertToSGD(amount, ccy) * percentage,
      0
    )
  );
};

export const calcApimFixedDepositAssetValueInSGD = (
  asset: IApimFixedDepositFormatted,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
) => {
  const { amountByCurrency, percentageShare, isJoint } = asset;
  const percentage = !excludePercentageShare && isJoint ? percentageShare * 0.01 : 1;
  return Math.round(
    Object.entries(amountByCurrency).reduce<number>(
      (a, [ccy, amount]) => a + convertToSGD(amount, ccy) * percentage,
      0
    )
  );
};

export const calcApimUnitTrustHoldingAssetValue = (
  { ccy, quantity, unitPrice, percentageShare, isJoint }: IApimUnitTrustHoldingFormatted,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
) =>
  Math.round(
    convertToSGD(unitPrice, ccy) *
      quantity *
      (!excludePercentageShare && isJoint ? percentageShare * 0.01 : 1)
  );

export const calcApimCpfisHoldingAssetValueInSGD = (asset: IApimCpfisSrsHoldingFormatted) => {
  const { holdingCode, unitPrice, quantity } = asset;

  if (holdingCode in goldQuantityToOz) {
    return Math.round(convertToSGD(100, 'XAU') * getGoldInOz(quantity, holdingCode));
  }
  return Math.round(unitPrice * quantity);
};

export const calcApimSrsHoldingAssetValueInSGD = (asset: IApimCpfisSrsHoldingFormatted) => {
  const { holdingCode, unitPrice, quantity } = asset;

  if (holdingCode in goldQuantityToOz) {
    return Math.round(convertToSGD(100, 'XAU') * getGoldInOz(quantity, holdingCode));
  }
  return Math.round(unitPrice * quantity);
};

export const calcApimCpfisAmountAssetValueInSGD = ({
  amount,
  amountType,
  surrenderValue,
}: IApimCpfisSrsAmountFormatted) =>
  amountType.toLowerCase() === 'insurance' ? surrenderValue : amount;

export const calcApimSrsAmountAssetValueInSGD = ({
  amount,
  amountType,
  surrenderValue,
}: IApimCpfisSrsAmountFormatted) =>
  amountType.toLowerCase() === 'insurance' ? surrenderValue : amount;

export const calcApimCdpAmountAssetValueInSGD = (
  { marketValue, marketValueCcy, percentageShare, accountRelationship }: IApimCdpHoldingFormatted,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
) => {
  const isJoint = accountRelationship.toLowerCase().includes('joint');

  const marketValueNum = marketValue ? parseInt(marketValue) : 0;
  const jointValue = marketValueNum * percentageShare * 0.01;

  return convertToSGD(
    !excludePercentageShare && isJoint ? jointValue : marketValueNum,
    marketValueCcy || 'SGD'
  );
};

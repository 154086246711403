import React from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import {
  ValidationForm,
  ValidationContainer,
  required,
  ValidationButton,
  IButtonRenderParams,
} from 'components/Validations';

import { AmountField } from '../AmountField/AmountField';
import { Button } from '../Button/Button';
import { Section } from '../SectionHeading/Section';

import { AmountLimitBanner } from './AmountLimitBanner';
import {
  StyledHeading,
  NameInputWrapper,
  AmountInputWrapper,
  DateFieldWrapper,
  PrimaryButtonGroup,
  BackButton,
  StyledAmountField,
  StyledDateField,
  StyledNameInput,
} from './sharedStyles';

interface IProps {
  name: string;
  amount: number;
  date: Date;
  transitionStatus?: TransitionStatus;
  handleBackAction: () => void;
  updateDate: (date: Date) => void;
  updateName: (name: string) => void;
  updateAmount: (amount: number) => void;
  thresholdAmount: number;
  saveToStore: () => void;
}

export class RestoreGoalContent extends React.Component<IProps> {
  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.transitionStatus === 'entering' && this.props.transitionStatus === 'entered') {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  }

  public render() {
    const {
      name,
      amount,
      date,
      updateDate,
      updateName,
      updateAmount,
      thresholdAmount,
      handleBackAction,
      saveToStore,
    } = this.props;

    return (
      <Section>
        <StyledHeading>Edit target</StyledHeading>
        <ValidationForm onSubmit={saveToStore} disableAfterSubmit={true}>
          <NameInputWrapper>
            <ValidationContainer
              value={name}
              validations={[required]}
              render={(validationsParams) => (
                <StyledNameInput
                  inputRef={this.inputRef}
                  label="Name"
                  widthStyle="fluid"
                  errorMessage={validationsParams.errorMessage}
                  onBlur={validationsParams.onBlur}
                  onFocus={validationsParams.onFocus}
                  value={name}
                  wrapperRef={validationsParams.placerRef}
                  handleChange={updateName}
                />
              )}
            />
          </NameInputWrapper>
          <AmountInputWrapper>
            <StyledAmountField
              label="Amount"
              widthStyle="fluid"
              amount={amount}
              required={true}
              minAmount={1}
              onInputChange={updateAmount}
              addonBefore={<AmountField.DollarSign />}
            />
          </AmountInputWrapper>
          <DateFieldWrapper>
            <StyledDateField
              label="Target due"
              date={date}
              onInputChange={updateDate}
              menuPlacement="top"
            />
          </DateFieldWrapper>
          {amount > thresholdAmount && <AmountLimitBanner thresholdAmount={thresholdAmount} />}
          <PrimaryButtonGroup>
            <BackButton
              variant="tertiary"
              onClick={handleBackAction}
              widthStyle="fluid"
              type="button"
            >
              Back
            </BackButton>
            <ValidationButton
              render={(renderParams: IButtonRenderParams) => (
                <Button widthStyle="fluid" type="submit" {...renderParams}>
                  Save
                </Button>
              )}
            />
          </PrimaryButtonGroup>
        </ValidationForm>
      </Section>
    );
  }
}

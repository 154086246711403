const TOLA_TO_OZ = 1 / 2.666665;
const GRAMS_TO_OZ = 1 / 31.103477;

export const goldQuantityToOz = {
  GC0001: {
    description: 'BCCS COINS 1 OZ',
    conversionMultiplier: 1,
  },
  GC0002: {
    description: 'BCCS COINS 1/2 OZS',
    conversionMultiplier: 0.5,
  },
  GC0003: {
    description: 'BCCS COINS 1/4 OZS',
    conversionMultiplier: 0.25,
  },
  GC0004: {
    description: 'BCCS COINS 1/10 OZS',
    conversionMultiplier: 0.1,
  },
  GC0005: {
    description: 'MAPLE LEAF 1 OZ',
    conversionMultiplier: 1,
  },
  GC0006: {
    description: 'MAPLE LEAF 1/2 OZ',
    conversionMultiplier: 0.5,
  },
  GC0007: {
    description: 'MAPLE LEAF 1/4 OZ',
    conversionMultiplier: 0.25,
  },
  GC0008: {
    description: 'MAPLE LEAF 1/10 OZ',
    conversionMultiplier: 0.1,
  },
  GC0009: {
    description: 'SBC 1 OZ',
    conversionMultiplier: 1,
  },
  GC0010: {
    description: 'SBC 1/2 OZ',
    conversionMultiplier: 0.5,
  },
  GC0011: {
    description: 'SBC 1 KG',
    conversionMultiplier: 1000 * GRAMS_TO_OZ,
  },
  GC0012: {
    description: 'SBC 5 GMS',
    conversionMultiplier: 5 * GRAMS_TO_OZ,
  },
  GC0013: {
    description: 'SBC 10 GMS',
    conversionMultiplier: 10 * GRAMS_TO_OZ,
  },
  GC0014: {
    description: 'SBC 20 GMS',
    conversionMultiplier: 20 * GRAMS_TO_OZ,
  },
  GC0015: {
    description: 'SBC 50 GMS',
    conversionMultiplier: 50 * GRAMS_TO_OZ,
  },
  GC0016: {
    description: 'SBC 100 GMS',
    conversionMultiplier: 100 * GRAMS_TO_OZ,
  },
  GC0017: {
    description: 'SPORE LION GOLD 1 OZ',
    conversionMultiplier: 1,
  },
  GC0018: {
    description: 'SPORE LION GOLD 1/2 OZ',
    conversionMultiplier: 0.5,
  },
  GC0019: {
    description: 'SPORE LION GOLD 1/4 OZ',
    conversionMultiplier: 0.25,
  },
  GC0020: {
    description: 'SPORE LION GOLD 1/10 OZ',
    conversionMultiplier: 0.1,
  },
  GC0021: {
    description: 'SPORE LION GOLD 1/20 OZ',
    conversionMultiplier: 0.05,
  },
  GC0022: {
    description: 'AUSTN NUGGET GOLD 1KG',
    conversionMultiplier: 1000 * GRAMS_TO_OZ,
  },
  GC0023: {
    description: 'AUSTN NUGGET GOLD 10 OZ COIN',
    conversionMultiplier: 10,
  },
  GC0024: {
    description: 'AUSTN NUGGET GOLD 1 OZ COIN',
    conversionMultiplier: 1,
  },
  GC0025: {
    description: 'AUSTN NUGGET GOLD 1/2 OZ COIN',
    conversionMultiplier: 0.5,
  },
  GC0026: {
    description: 'AUSTN NUGGET GOLD 1/4 OZ COIN',
    conversionMultiplier: 0.25,
  },
  GC0027: {
    description: 'AUSTN NUGGET GOLD 1/10 OZ COIN',
    conversionMultiplier: 0.1,
  },
  GC0028: {
    description: 'AUSTN NUGGET GOLD COIN',
    conversionMultiplier: 0.05,
  },
  GS0001: {
    description: 'GOLD SAVINGS ACCOUNT',
    conversionMultiplier: GRAMS_TO_OZ,
  },
  GT0001: {
    description: 'GOLD CERTIFICATES 100G',
    conversionMultiplier: 100 * GRAMS_TO_OZ,
  },
  GT0002: {
    description: 'GOLD CERTIFICATES 500G',
    conversionMultiplier: 500 * GRAMS_TO_OZ,
  },
  GT0003: {
    description: 'GOLD CERTIFICATES 1 KG',
    conversionMultiplier: 1000 * GRAMS_TO_OZ,
  },
  GT0004: {
    description: 'GOLD CERTIFICATES 3 KG',
    conversionMultiplier: 3000 * GRAMS_TO_OZ,
  },
  GT0005: {
    description: 'CERTIFICATES IN OZS (96.44 OZS)',
    conversionMultiplier: 96.44,
  },
  GW0001: {
    description: 'GOLD WAFER 1 GM',
    conversionMultiplier: GRAMS_TO_OZ,
  },
  GW0002: {
    description: 'GOLD WAFER 2.5 GM',
    conversionMultiplier: 2.5 * GRAMS_TO_OZ,
  },
  GW0003: {
    description: 'GOLD WAFER 5 GM',
    conversionMultiplier: 5 * GRAMS_TO_OZ,
  },
  GW0004: {
    description: 'GOLD WAFER 10 GM',
    conversionMultiplier: 10 * GRAMS_TO_OZ,
  },
  GW0005: {
    description: 'GOLD WAFER 20 GM',
    conversionMultiplier: 20 * GRAMS_TO_OZ,
  },
  GW0006: {
    description: 'GOLD WAFER 50 GM',
    conversionMultiplier: 50 * GRAMS_TO_OZ,
  },
  GW0007: {
    description: 'GOLD WAFER 100 GM',
    conversionMultiplier: 100 * GRAMS_TO_OZ,
  },
  GW0008: {
    description: 'GOLD WAFER 500 GM',
    conversionMultiplier: 500 * GRAMS_TO_OZ,
  },
  GW0009: {
    description: 'GOLD WAFER 1 KG',
    conversionMultiplier: 1000 * GRAMS_TO_OZ,
  },
  GW0010: {
    description: 'GOLD WAFER 1 OZ',
    conversionMultiplier: 1,
  },
  GW0011: {
    description: 'GOLD WAFER 1/2 OZ',
    conversionMultiplier: 0.5,
  },
  GW0012: {
    description: 'GOLD WAFER 1/4 OZ',
    conversionMultiplier: 0.25,
  },
  GW0013: {
    description: 'GOLD WAFER 1/10 OZ',
    conversionMultiplier: 0.1,
  },
  GW0014: {
    description: 'GOLD WAFER 10 TOLA',
    conversionMultiplier: 10 * TOLA_TO_OZ,
  },
  GC0029: {
    description: 'AUSTN 2 OZ GOLD COIN',
    conversionMultiplier: 2,
  },
  GC0030: {
    description: 'MAPLE LEAF 1/20 OZ',
    conversionMultiplier: 0.05,
  },
  GW0015: {
    description: 'ARGOR HERAEUS GOLD BAR – 100 GRAMS',
    conversionMultiplier: 100 * GRAMS_TO_OZ,
  },
};

export const getGoldInOz = (quantity: number, investmentCode: string) =>
  quantity * (goldQuantityToOz[investmentCode]?.conversionMultiplier || 0);

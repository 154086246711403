import { RouteComponentProps } from 'react-router';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const easeInOutQuad = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

type scrollDirection = 'x' | 'y';
export const smoothScroll = (
  from: number,
  to: number,
  duration: number,
  target: HTMLElement | null = null,
  direction: scrollDirection = 'y',
  doneCallback: () => void = () => null
) => {
  const change = to - from;
  const startTime = new Date().getTime();
  let animationTimer = -1;

  const scroll = (val: number) => {
    if (target === null) {
      direction === 'y' ? window.scrollTo(0, val) : window.scrollTo(val, 0);
    } else {
      if (direction === 'y') {
        target.scrollTop = val;
      } else {
        target.scrollLeft = val;
      }
    }
  };

  const animateScroll = () => {
    const currentTime = new Date().getTime();
    const t = easeInOutQuad((currentTime - startTime) / duration);
    const isAnimationComplete = currentTime - startTime >= duration;
    const val = isAnimationComplete ? to : t * change + from;

    scroll(val);

    if (isAnimationComplete) {
      window.requestAnimationFrame(doneCallback);
    } else {
      animationTimer = window.requestAnimationFrame(animateScroll);
    }
  };
  animateScroll();

  return {
    cancel: () => {
      window.cancelAnimationFrame(animationTimer);
      doneCallback();
    },
  };
};

/**
This function scrolls the element provided to the top of the 'main-content' div.
This is therefore only useful for elements that are within the main content.
*/
export const scrollElementIntoView = (
  element: HTMLElement,
  TOP_PADDING_PX = 16,
  SCROLL_DURATION_IN_MILLIS = 500
) => {
  const currentScrollY = window.scrollY;

  window.requestAnimationFrame(() => {
    const mainDiv = document.getElementById('main-content');
    if (element && mainDiv) {
      const elementTop = window.scrollY + element.getBoundingClientRect().top;
      const mainDivTop = window.scrollY + mainDiv.getBoundingClientRect().top;

      smoothScroll(
        currentScrollY,
        elementTop - mainDivTop - TOP_PADDING_PX,
        SCROLL_DURATION_IN_MILLIS
      );
    }
  });
};

export const scrollElementIntoViewById = (
  id: string,
  TOP_PADDING_PX = 16,
  SCROLL_DURATION_IN_MILLIS = 500
) => {
  requestAnimationFrame(() => {
    const newPanel = document.getElementById(id);
    newPanel && scrollElementIntoView(newPanel, TOP_PADDING_PX, SCROLL_DURATION_IN_MILLIS);
  });
};

export const scrollElementIntoViewByLocationHash = (location: RouteComponentProps['location']) => {
  const locationHash = location.hash?.slice(1);
  if (locationHash) {
    scrollElementIntoViewById(locationHash);
  }
};

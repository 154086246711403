import { StyleSheet } from '@react-pdf/renderer';

import { colors, fontSizes, margin, padding } from '../styles';

export const BasePageStyles = StyleSheet.create({
  page: {
    display: 'flex',
    fontFamily: 'Libre Franklin',
    fontSize: fontSizes.body,
    color: colors.grey90,
    paddingBottom: padding.xlarge,
  },
  pageContentContainer: {
    paddingLeft: padding.xlarge,
    paddingRight: padding.xlarge,
  },
  header: {
    color: colors.grey90,
    paddingTop: padding.xlarge,
    paddingLeft: padding.xlarge,
    paddingRight: padding.xlarge,
    marginBottom: margin.large,
  },
  sampleContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.primary20,
    color: colors.primary40,
    fontSize: fontSizes.h2,
    fontStyle: 'italic',
    fontWeight: 'bold',
    textAlign: 'right',
    height: 42,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    width: 140,
    height: 20,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    color: colors.grey90,
    backgroundColor: colors.grey10,
    paddingTop: padding.small,
    paddingBottom: padding.medium,
    paddingLeft: padding.xlarge,
    paddingRight: padding.xlarge,
    display: 'flex',
    alignItems: 'flex-end',
  },
});

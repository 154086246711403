import { omit } from 'lodash';
import { Reducer } from 'redux';

import { mockInitialStates } from 'redux/mockInitialStates';

import { IFinancesLiteActions } from 'constants/actionTypes';
import { FINANCES_LITE_ACTIONS } from 'constants/enums';
import { IFinancesLiteStore } from 'constants/storeTypes';

export const initialState: IFinancesLiteStore = {
  monthlyCashIn: null,
  expenses: {},
  currentSavings: {
    CASH_SAVINGS: { id: 'CASH_SAVINGS', name: 'Cash savings', amount: null },
  },
};

export const financesLiteReducer: Reducer<IFinancesLiteStore, IFinancesLiteActions> = (
  state: IFinancesLiteStore = process.env.REACT_APP_DEPLOYMENT_ENV &&
  ['development', 'staging'].includes(process.env.REACT_APP_DEPLOYMENT_ENV) &&
  mockInitialStates.financesLite !== null
    ? mockInitialStates.financesLite
    : initialState,
  action: IFinancesLiteActions
) => {
  switch (action.type) {
    case FINANCES_LITE_ACTIONS.SET_MONTHLY_CASH_IN:
      return { ...state, monthlyCashIn: action.monthlyCashIn };

    case FINANCES_LITE_ACTIONS.CREATE_EXPENSE:
      return { ...state, expenses: { ...state.expenses, [action.expense.id]: action.expense } };

    case FINANCES_LITE_ACTIONS.UPDATE_EXPENSE:
      return {
        ...state,
        expenses: {
          ...state.expenses,
          [action.id]: { ...state.expenses[action.id], ...action.update },
        },
      };

    case FINANCES_LITE_ACTIONS.DELETE_EXPENSE:
      return {
        ...state,
        expenses: omit(state.expenses, action.id),
      };

    case FINANCES_LITE_ACTIONS.CLEAR_EXPENSES:
      return { ...state, expenses: {} };

    case FINANCES_LITE_ACTIONS.SET_EXPENSES:
      return { ...state, expenses: action.expenses };

    case FINANCES_LITE_ACTIONS.SET_CURRENT_SAVINGS:
      return { ...state, currentSavings: action.currentSavings };

    default:
      return state;
  }
};

import React from 'react';
import stickybits, { StickyBits } from 'stickybits';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme as fromTheme } from 'styled-tools';

interface IStickyProps {
  stickyOptions?: StickyBits.Options;
  minWidthForSticky?: number;
  children: React.ReactNode;
  disabled?: boolean;
}

interface IStickyState {
  width: number;
  height: number;
}

const StickyWrapper = styled.div<{ bottomOffset?: string }>`
  z-index: ${fromTheme('layers.sticky')};
  ${ifProp(
    'bottomOffset',
    css`
      bottom: ${prop('bottomOffset')};
    `
  )}
`;

export class Sticky extends React.Component<IStickyProps, IStickyState> {
  private stickyInstance?: StickyBits;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly ref: React.RefObject<any>;

  constructor(props: IStickyProps) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      width: 0,
      height: 0,
    };
    this.onResize = this.onResize.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    if (this.shouldSticky()) {
      this.addSticky();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    if (this.stickyInstance) {
      this.stickyInstance.cleanup();
    }
  }

  public componentDidUpdate() {
    if (this.shouldSticky()) {
      if (!this.stickyInstance) {
        this.addSticky();
      } else {
        this.stickyInstance.update();
      }
    } else if (this.stickyInstance) {
      this.stickyInstance.cleanup();
      this.stickyInstance = undefined;
    }
  }

  public render() {
    const { stickyOptions, minWidthForSticky, children, ...otherProps } = this.props;
    let bottomOffset;

    if (stickyOptions) {
      const { stickyBitStickyOffset = 0, verticalPosition } = stickyOptions;
      if (verticalPosition === 'bottom') {
        bottomOffset = `${stickyBitStickyOffset}px`;
      }
    }

    return (
      <StickyWrapper ref={this.ref} bottomOffset={bottomOffset} {...otherProps}>
        {children}
      </StickyWrapper>
    );
  }

  private onResize() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  private shouldSticky() {
    return !this.props.disabled && this.state.width >= (this.props.minWidthForSticky || 0);
  }

  private addSticky() {
    this.stickyInstance = stickybits(this.ref.current, {
      ...this.props.stickyOptions,
      useStickyClasses: true, // This is required for verticalPosition: bottom to work. https://github.com/dollarshaveclub/stickybits/pull/121
    });
  }
}

import React from 'react';
import styled from 'styled-components';

import { colorScale, typography } from 'themes';

interface IProps {
  image: React.ReactElement;
  text: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  text-align: center;
  color: ${colorScale('grey', 80)};
  ${typography('body-l')}
  margin-bottom: 0;
`;

export const NoDataImage = ({
  image,
  text,
  ...otherProps
}: IProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Wrapper {...otherProps}>
      {image}
      <Text>{text}</Text>
    </Wrapper>
  );
};

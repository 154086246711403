import React from 'react';
import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';

import { minWidth, typography, colorScale } from 'themes';

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  row-gap: 8px;
`;

const Row = styled.div<{ columnGap: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${prop('columnGap')};

  ${minWidth(
    'tablet',
    css`
      grid-template-columns: 2fr 3fr;
    `
  )}
`;

const Nutrient = styled.p`
  ${typography('body-m-bold')};
  color: ${colorScale('grey', 80)};
  margin: 0;
`;

export const NutritionalValueText = styled.p`
  ${typography('body-l')};
  color: ${colorScale('grey', 90)};
  margin: 0;
`;

const NutritionalValue = styled.div``;

export interface INutritionalBreakdown {
  nutrient: React.ReactChild;
  nutritionalValue: React.ReactChild;
}

interface IProps {
  content: INutritionalBreakdown[];
  columnGap?: string;
}

/**
 * Represents a two column layout, similar to a nutritional facts table.
 * Comprises of headers with colons and their respective descriptions.
 * @param content Array of headers (nutrient) and their respective descriptions (nutritionalValue)
 * @param columnGap Gap between nutrient and nutritional value
 */
export const NutritionalBreakdown = ({ content, columnGap = '8px' }: IProps) => (
  <Wrapper>
    {content.map(({ nutrient, nutritionalValue }, idx) => (
      <Row key={idx} columnGap={columnGap}>
        <Nutrient>{nutrient}</Nutrient>
        <NutritionalValue>{nutritionalValue}</NutritionalValue>
      </Row>
    ))}
  </Wrapper>
);

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { IEmergencyFundRatioInputs } from '../interfaces';

export const EF5: IRule<IEmergencyFundRatioInputs> = {
  condition: ({ emergencyFundMonths, isWorking }: IEmergencyFundRatioInputs) =>
    emergencyFundMonths >= 3 && emergencyFundMonths < 6 && !isWorking,
  result: {
    key: RULE.EF5,
    indicatorStatus: indicatorStatuses.FAIR,
    recommendedActions: [],
    resources: [
      // Some links
    ],
  },
};

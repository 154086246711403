import React from 'react';
import styled from 'styled-components';

import { colorScale, typeScale } from 'themes';

const StyledText = styled.span`
  margin: 8px 0;
  color: ${colorScale('grey', 80)};
  font-size: ${typeScale(-2)};
`;

export const Subtext: React.FC = ({ children }) => <StyledText>{children}</StyledText>;

import { Reducer } from 'redux';

import { IFxRatesActions } from 'constants/actionTypes';
import { FX_RATES_ACTIONS } from 'constants/enums';
import { IFxRatesStore } from 'constants/storeTypes';

export const initialState: IFxRatesStore = {
  fxRates: {},
};

export const fxRatesReducer: Reducer<IFxRatesStore, IFxRatesActions> = (
  state: IFxRatesStore = initialState,
  action: IFxRatesActions
) => {
  switch (action.type) {
    case FX_RATES_ACTIONS.SET_FX_RATES:
      return { ...state, fxRates: action.fxRates };

    default:
      return state;
  }
};

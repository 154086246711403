import React from 'react';
import styled from 'styled-components';

import { captureCalendarReminderSet } from 'analytics';
import { AppleIcon, GoogleIcon, ICSIcon } from 'assets/ReminderModal';
import { Heading, Section, Button } from 'components/Common';
import { colorScale, typeScale } from 'themes';
import {
  ICalendarEventData,
  generateGoogleLink,
  generateIcsLink,
  generateAndDownloadIcsOnIE,
} from 'utilities/calendarEventUtilities';

const StyledHeading = styled(Heading)`
  color: ${colorScale('grey', 90)};
  font-size: ${typeScale(3)};
  text-align: left;
  margin: 0;
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const LinkWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px 0;
  text-decoration: none;
  cursor: pointer;
`;

const CircleWrapper = styled.div`
  width: 100%;
  max-width: 72px;
`;

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50% 0;
  height: 0;
  border-radius: 50%;
  background: ${colorScale('grey', 15)};
`;

const LinkImage = styled.div`
  width: 50%;
  max-height: 36px;

  > img {
    width: 100%;
    max-height: 36px;
  }
`;

const LinkText = styled.p`
  color: ${colorScale('grey', 90)};
  font-size: ${typeScale(-1)};
  margin-bottom: 0;
`;

interface IProps {
  onClickReminderCTA: () => void;
  eventData: ICalendarEventData;
  headingText: string;
  onUnmount?: () => void;
  onIconClick?: () => void;
}

interface IState {
  hasClicked: boolean;
}

const CALENDAR_FILE_NAME = 'MyMoneySense Reminder.ics';

export class ReminderChoicesContent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasClicked: false,
    };

    this.handleIconClick = this.handleIconClick.bind(this);
    this.handleDownloadIcsClick = this.handleDownloadIcsClick.bind(this);
    this.handleAppleClick = this.handleAppleClick.bind(this);
    this.handleGoogleClick = this.handleGoogleClick.bind(this);
    this.handleIcsClick = this.handleIcsClick.bind(this);
  }

  handleIconClick() {
    const { onIconClick } = this.props;
    this.setState({ hasClicked: true });
    if (onIconClick) {
      onIconClick();
    }
  }

  handleDownloadIcsClick() {
    this.handleIconClick();
    generateAndDownloadIcsOnIE(this.props.eventData, CALENDAR_FILE_NAME);
  }

  handleAppleClick() {
    this.handleDownloadIcsClick();
    captureCalendarReminderSet('apple');
  }

  handleGoogleClick() {
    this.handleIconClick();
    captureCalendarReminderSet('google');
  }

  handleIcsClick() {
    this.handleDownloadIcsClick();
    captureCalendarReminderSet('ics');
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    if (onUnmount) {
      onUnmount();
    }
  }

  render() {
    const { onClickReminderCTA, eventData, headingText } = this.props;

    return (
      <Section>
        <StyledHeading>{headingText}</StyledHeading>
        <Wrapper>
          <LinkWrapper
            download={CALENDAR_FILE_NAME}
            href={generateIcsLink(eventData)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.handleAppleClick}
          >
            <CircleWrapper>
              <IconCircle>
                <LinkImage>{<AppleIcon />}</LinkImage>
              </IconCircle>
            </CircleWrapper>
            <LinkText>Apple</LinkText>
          </LinkWrapper>
          <LinkWrapper
            href={generateGoogleLink(eventData)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.handleGoogleClick}
          >
            <CircleWrapper>
              <IconCircle>
                <LinkImage>{<GoogleIcon />}</LinkImage>
              </IconCircle>
            </CircleWrapper>
            <LinkText>Google</LinkText>
          </LinkWrapper>
          <LinkWrapper
            download={CALENDAR_FILE_NAME}
            href={generateIcsLink(eventData)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.handleIcsClick}
          >
            <CircleWrapper>
              <IconCircle>
                <LinkImage>{<ICSIcon />}</LinkImage>
              </IconCircle>
            </CircleWrapper>
            <LinkText>Others</LinkText>
          </LinkWrapper>
        </Wrapper>
        <Button
          onClick={onClickReminderCTA}
          variant={this.state.hasClicked ? 'primary' : 'secondary'}
          widthStyle="fluid"
        >
          {this.state.hasClicked ? 'Done' : 'Skip'}
        </Button>
      </Section>
    );
  }
}

import { groupBy, isEmpty, pick, uniq } from 'lodash';

import { initialState } from 'redux/finances/reducer';

import { ASSET_TYPE, LIABILITY_TYPE, ENTER_OPTION, INVESTMENT_SOURCE } from 'constants/enums';
import {
  IStore,
  IExpense,
  ILoanRepayment,
  IIncome,
  ICpfAsset,
  ICashSavingsAsset,
  IBondAsset,
  IGoldAsset,
  IFixedDepositAsset,
  IInsuranceAsset,
  IStockAsset,
  IStructuredProductAsset,
  IUnitTrustAsset,
  IOtherInvestmentAsset,
  IOtherNonInvestmentAsset,
  IManualAsset,
  IInvestmentAssetWithUnitPrice,
  IInvestmentAssetWithTotalValue,
  IAssetBreakdown,
  IAssetBreakdownBySource,
  IInvestmentBreakdown,
  ICashBalanceAsset,
  ILiabilityBreakdown,
  IInvestmentPortfolio,
} from 'constants/storeTypes';
import { convertToSGD } from 'utilities/currencyUtilities';
import { getDateDiffInMonths, getMaxDate } from 'utilities/dateUtilities';
import { descending } from 'utilities/sortingUtilities';

import {
  getApimInvestmentsBreakdownInSGD,
  getApimLoansAsLoanRepayments,
  getTotalApimAssetsValueInSGD,
  getTotalApimCasasInSGD,
  getApimCasaBreakdownInSGD,
  getTotalApimCashFixedDepositInSGD,
  getTotalApimLiabilitiesInSGD,
  getApimLiabilitiesBreakdownInSGD,
  getApimCpfisAccountNumber,
  getApimSrsAccountNumber,
  IGetExcludePercentageShareOption,
} from '../apim/selectors';
import {
  getMyinfoCpfAsset,
  getMyinfoCPFTotalValueInSGD,
  getMyinfoHDBOwnershipAsLiabilities,
  getMyinfoHDBOwnershipAsLoanRepayment,
  getTotalMyinfoHDBOwnershipOutstandingLoan,
  getMyinfoLiabilitiesBreakdown,
} from '../myinfo/selectors';
import {
  totalAmountSpentForTargets,
  getSortedGoals,
  getOngoingGoals,
  getEmergencyFund,
} from '../targets/selectors';

export const getNetCashflow = (store: IStore) =>
  getTotalNetIncomeInSGD(store) - getTotalCashOutflow(store);

export const getNetWorth = (store: IStore) =>
  getTotalAssetsValueInSGD(store) - getTotalLiabilities(store);

export const getTotalLiabilities = (store: IStore) =>
  getTotalManualLiabilitiesInSGD(store) +
  getTotalMyinfoHDBOwnershipOutstandingLoan(store) +
  getTotalApimLiabilitiesInSGD(store);

// no conversion and keeps the *Income in the value in respective currency
export const getIncomeList = (store: IStore) => store.finances.incomeList;

export const getIncomeListArray = (store: IStore) => Object.values(getIncomeList(store));

export const getIncomeListInSGD = (store: IStore): { [id: string]: IIncome } =>
  Object.entries(getIncomeList(store)).reduce((acc, [id, income]) => {
    const { grossIncome, netIncome, incomeCurrency, ...others } = income;
    return {
      ...acc,
      [id]: {
        ...others,
        grossIncome: convertToSGD(grossIncome || 0, incomeCurrency),
        netIncome: convertToSGD(netIncome || 0, incomeCurrency),
        incomeCurrency,
      },
    };
  }, {});

export const getIncomeListInSGDArray = (store: IStore) => Object.values(getIncomeListInSGD(store));

export const getIncomeListInSGDByType = (store: IStore) =>
  groupBy(getIncomeListInSGD(store), (income) => income.type.id);

export const getTotalGrossIncomeInSGD = (store: IStore) =>
  getIncomeListInSGDArray(store).reduce<number>((a, c) => a + (c?.grossIncome || 0), 0);

export const getTotalNetIncomeInSGD = (store: IStore) =>
  getIncomeListInSGDArray(store).reduce<number>((a, c) => a + (c?.netIncome || 0), 0);

export const getTotalLoanRepaymentsInSGD = (store: IStore) =>
  getTotalLoanRepaymentsCashComponentInSGD(store) + getTotalLoanRepaymentsCpfComponentInSGD(store);

export const getTotalLoanRepaymentsCashComponentInSGD = (store: IStore) =>
  getLoanRepaymentsArray(store).reduce<number>((a, c) => a + convertToSGD(c?.cash || 0, c.ccy), 0);

export const getTotalLoanRepaymentsCpfComponentInSGD = (store: IStore) =>
  getLoanRepaymentsArray(store).reduce<number>((a, c) => a + (c?.cpf || 0), 0);

export const getTotalInvestmentsInSGD = (store: IStore) =>
  getInvestmentsArray(store).reduce<number>((a, c) => a + convertToSGD(c?.amount || 0, c.ccy), 0);

export const getTotalOutflow = (store: IStore) =>
  getTotalInvestmentsInSGD(store) +
  getTotalLoanRepaymentsInSGD(store) +
  getTotalMonthlyExpenses(store);

interface IGetTotalCashOutflowOptions {
  excludeInvestments?: boolean;
  excludeLoanRepayments?: boolean;
}

export const getTotalCashOutflow = (
  store: IStore,
  { excludeInvestments = false, excludeLoanRepayments = false }: IGetTotalCashOutflowOptions = {}
) => {
  let sum = getTotalMonthlyExpenses(store);
  if (!excludeInvestments) {
    sum += getTotalInvestmentsInSGD(store);
  }
  if (!excludeLoanRepayments) {
    sum += getTotalLoanRepaymentsCashComponentInSGD(store);
  }
  return sum;
};

export const getTotalManualLiabilitiesInSGD = (store: IStore) =>
  getManualLiabilitiesArray(store).reduce<number>(
    (a, c) => a + convertToSGD(c?.amount || 0, c.ccy),
    0
  );

export const getLiabilitiesByType = (store: IStore) =>
  groupBy(getLiabilitiesBreakdownInSGD(store), (liabilities) => liabilities.type);

export const getMonthlyExpenses = (store: IStore) => store.finances.expenses;

export const getMonthlyExpensesArray = (store: IStore) => Object.values(getMonthlyExpenses(store));

export const getManualLiabilities = (store: IStore) => store.finances.liabilities;

export const getManualLiabilitiesArray = (store: IStore) =>
  Object.values(getManualLiabilities(store));

export const getManualLiabilitiesBreakdownInSGD = (store: IStore) => {
  const liabilities = Object.values(getManualLiabilities(store));

  return liabilities.map(({ name, amount, ccy, category }) => ({
    name,
    amount: Number(convertToSGD(amount, ccy).toFixed(2)),
    type: category,
  }));
};

export const getLiabilitiesBreakdownInSGD = (store: IStore): ILiabilityBreakdown[] => {
  let manualLiabilities = getManualLiabilitiesBreakdownInSGD(store);
  const apimLiabilities = getApimLiabilitiesBreakdownInSGD(store);
  const myinfoLiabilities = getMyinfoLiabilitiesBreakdown(store);
  const hasMyinfoLiabilities = Boolean(
    Object.values(getMyinfoHDBOwnershipAsLiabilities(store)).length
  );

  if (hasMyinfoLiabilities) {
    manualLiabilities = manualLiabilities.filter(
      ({ type }) => type === LIABILITY_TYPE.NON_MORTGAGE
    );
  }

  return [...manualLiabilities, ...apimLiabilities, ...myinfoLiabilities];
};

// This function returns a null if the category passed in does not exist
export const getTotalMonthlyExpensesOfCategory = (store: IStore, category: string) => {
  const expenses = getMonthlyExpensesArray(store);

  const expensesOfCategory = expenses.filter((expense) => expense.category === category);

  if (expensesOfCategory.length === 0) {
    return null;
  }

  return expensesOfCategory.reduce(
    (subTotal, expense) => subTotal + expense.amount / expense.timeInterval,
    0
  );
};

export const getTotalDetailedMonthlyExpenses = (store: IStore) =>
  Math.round(
    getMonthlyExpensesArray(store).reduce(
      (subTotal, expense) => subTotal + expense.amount / expense.timeInterval,
      0
    )
  );

export const getTotalEstimatedMonthlyExpenses = (store: IStore) =>
  store.finances.estimatedMonthlyExpenses;

export const getTotalMonthlyExpenses = (store: IStore) =>
  getTotalEstimatedMonthlyExpenses(store) === 0
    ? getTotalDetailedMonthlyExpenses(store)
    : getTotalEstimatedMonthlyExpenses(store);

/**
 * Returns an array of expenses sorted in descending monthly amount for the particular expense type
 * @param store
 */
export const getSortedMonthlyExpenses = (store: IStore): IExpense[] =>
  getMonthlyExpensesArray(store).sort((a, b) =>
    descending(a.amount / a.timeInterval, b.amount / b.timeInterval)
  );

export const getDuplicatedExpenses = (store: IStore) => {
  //naive implementation first, write the tests, then see if can optimize
  const arrayOfExpenseCategories = getMonthlyExpensesArray(store).map(
    (expense) => expense.category
  );
  const categoriesCount = {};
  arrayOfExpenseCategories.forEach((category) => {
    categoriesCount[category] = (categoriesCount[category] || 0) + 1;
  });

  return Object.keys(categoriesCount)
    .filter((category) => categoriesCount[category] > 1)
    .sort();
};

export const getManualLoanRepayments = (store: IStore) => store.finances.loanRepayments;

export const getLoanRepayments = (store: IStore): { [id: string]: ILoanRepayment } => ({
  ...getApimLoansAsLoanRepayments(store),
  ...getMyinfoHDBOwnershipAsLoanRepayment(store),
  ...getManualLoanRepayments(store),
});

export const getLoanRepaymentsArray = (store: IStore) => Object.values(getLoanRepayments(store));

export const getInvestments = (store: IStore) => store.finances.investments;
export const getInvestmentsArray = (store: IStore) => Object.values(getInvestments(store));

export const getTargetMonthlySavings = (store: IStore) => store.finances.targetMonthlySavings;

// TODO: [FP-1074] Fix the computation once historical data has been implemented
export const getTargetDeltaSavings = (store: IStore) => {
  const numMonths = 1;
  return numMonths * getTargetMonthlySavings(store);
};

export const getMonthlySavings = (store: IStore) =>
  getTotalNetIncomeInSGD(store) - getTotalCashOutflow(store);

export const getProjectedAvailableFunds = (
  store: IStore,
  date: Date,
  monthlySavings: number,
  initialDate: Date = new Date()
) => {
  const goals = getSortedGoals(store).filter(
    (goal) =>
      getDateDiffInMonths(date, goal.date) > 0 && getDateDiffInMonths(goal.date, initialDate) > 0
  );
  const availableFunds = getAvailableFunds(store);
  const amountGainedAtDate = getDateDiffInMonths(date, initialDate) * monthlySavings;
  const amountSpentForTargets =
    totalAmountSpentForTargets(goals, monthlySavings, initialDate) +
    goals.reduce((sum, goal) => sum + goal.currentAmount, 0);

  return availableFunds + amountGainedAtDate - amountSpentForTargets;
};

export const getDayOfTransfer = (store: IStore) => store.finances.dayOfTransfer;

export const getCurrentMonthlySavings = (store: IStore): number =>
  getTargetMonthlySavings(store) || getMonthlySavings(store);

/**
 * For users whose take home income is less than $2,000
 * - Recommend them to save 1% of their income
 * For users whose take home income is more than or equals to $2,000
 * - If they are currently saving 0% or less of their income, to nudge them to save at least 5% of their income.
 * - If they are currently saving more than 0% of their income, to nudge them to save 5% more (capped at 20%)
 */

export const getRecommendedMonthlySavings = (
  store: IStore,
  selectedMonthlySavings = 0
): { recommendedMonthlySavings: number; amountToNudgeTo: number } => {
  const currentMonthlySavings = getCurrentMonthlySavings(store);
  const totalNetIncomeInSGD = getTotalNetIncomeInSGD(store);
  let recommendedMonthlySavings;
  let amountToNudgeTo;

  if (totalNetIncomeInSGD < 200000) {
    recommendedMonthlySavings = 0.01 * totalNetIncomeInSGD;
    amountToNudgeTo = recommendedMonthlySavings;
  } else {
    if (currentMonthlySavings <= 0) {
      recommendedMonthlySavings = 0.05 * totalNetIncomeInSGD;
      amountToNudgeTo = recommendedMonthlySavings;
    } else {
      recommendedMonthlySavings = Math.min(
        0.05 * totalNetIncomeInSGD + currentMonthlySavings,
        0.2 * totalNetIncomeInSGD
      );
      amountToNudgeTo = Math.min(
        0.05 * totalNetIncomeInSGD + selectedMonthlySavings,
        0.2 * totalNetIncomeInSGD
      );
    }
  }
  return { recommendedMonthlySavings, amountToNudgeTo };
};

export const getAutomaticTransferStatus = (store: IStore) => store.finances.automaticTransferStatus;

export const getTargetAmountLimit = (store: IStore) =>
  (70 - 29) * 12 * Math.max(50000, getTotalNetIncomeInSGD(store));

export const getAllocatedSavings = (store: IStore): number => {
  const nonExpiredGoals = getOngoingGoals(store);
  const nonExpiredNonPaidOutGoals = nonExpiredGoals.filter((goal) => !goal.paidOut);

  const emergencyFund = getEmergencyFund(store);

  return (
    emergencyFund.currentAmount +
    nonExpiredNonPaidOutGoals.reduce((acc, target) => acc + target.currentAmount, 0)
  );
};

export const getUnallocatedSavings = (store: IStore): number => {
  const availableFunds = getAvailableFunds(store);
  const allocatedSavings = getAllocatedSavings(store);
  return availableFunds - allocatedSavings;
};

export const getPaidOutAmounts = (store: IStore): number => {
  const sortedGoals = getSortedGoals(store);
  const paidOutGoals = sortedGoals.filter((goal) => goal.paidOut);
  return paidOutGoals.reduce(
    (acc, cur) => acc + (cur.paidOutAmount as number), //won't be null if paidOut = true
    0
  );
};

export const getAvailableFundsAndPaidOutAmounts = (store: IStore): number => {
  const availableFunds = getAvailableFunds(store);
  const paidOutAmounts = getPaidOutAmounts(store);

  return availableFunds + paidOutAmounts;
};

export const getAvailableFunds = (store: IStore): number => {
  const totalManualCASAandFD =
    getTotalManualCashSavingsInSGD(store) +
    getTotalManualFixedDepositsInSGD(store, { excludeSrs: true, excludeCpfis: true });
  const totalApimCASAandFD =
    getTotalApimCasasInSGD(store) + getTotalApimCashFixedDepositInSGD(store);
  return totalManualCASAandFD + totalApimCASAandFD;
};

export const getManualFundsLastUpdatedAt = (store: IStore): Date | null => {
  const cashSavings = getCashSavingsAssetsAsArray(store);
  const fixedDeposits = getFixedDepositAssetsAsArray(store);
  const cashFixedDeposits = fixedDeposits.filter(
    (fd) => fd.investmentSource === INVESTMENT_SOURCE.CASH
  );
  if (isEmpty(cashSavings) && isEmpty(cashFixedDeposits)) {
    return null;
  }

  const cashSavingsLastUpdatedAt = cashSavings.reduce(
    (acc: Date[], { lastUpdatedAt }) => [...acc, new Date(lastUpdatedAt)],
    []
  );
  const cashFixedDepositsLastUpdatedAt = cashFixedDeposits.reduce(
    (acc: Date[], { lastUpdatedAt }) => [...acc, new Date(lastUpdatedAt)],
    []
  );
  const lastUpdatedAtDates = cashSavingsLastUpdatedAt.concat(cashFixedDepositsLastUpdatedAt);
  const latestDate = getMaxDate(lastUpdatedAtDates);

  return latestDate;
};

export const getNumInvestmentClasses = (store: IStore): number =>
  uniq(
    getInvestmentsBreakdownInSGD(store, { excludeCashBalances: true, excludeZeroAmount: true }).map(
      ({ type }) => type
    )
  ).length;

export const getSavingsRatio = (store: IStore): number | null => {
  const netIncome = getTotalNetIncomeInSGD(store);
  return netIncome === 0
    ? null
    : (netIncome - getTotalCashOutflow(store, { excludeInvestments: true })) / netIncome;
};

export const getTotalDebtServicingRatio = (store: IStore): number | null => {
  const grossIncome = getTotalGrossIncomeInSGD(store);
  return grossIncome === 0 ? null : getTotalLoanRepaymentsInSGD(store) / grossIncome;
};

export const getCpfAsset = (store: IStore): ICpfAsset => store.finances.cpfAsset;

export const getMedisave = (store: IStore): number =>
  getCPFBreakdown(store).find(({ key }) => key === 'ma')?.amount || 0;

export const getCPFBreakdown = (store: IStore): (IAssetBreakdown & { key: string })[] => {
  const nameMap = {
    oa: 'Ordinary account',
    ma: 'MediSave account',
    sa: 'Special account',
    ra: 'Retirement account',
  };
  const myinfoCpfAsset = getMyinfoCpfAsset(store);
  const manualCpfAsset = getCpfAsset(store);
  const cpfAsset = myinfoCpfAsset || manualCpfAsset;

  return Object.keys(pick(cpfAsset, ['oa', 'sa', 'ra', 'ma'])).map((key) => ({
    key,
    name: nameMap[key],
    amount: cpfAsset[key],
  }));
};

export const getCashBalanceAssets = (store: IStore): { [id: string]: ICashBalanceAsset } =>
  store.finances.cashBalanceAssets;

export const getCashBalanceAssetsAsArray = (store: IStore): ICashBalanceAsset[] =>
  Object.values(getCashBalanceAssets(store));

export const getCashSavingsAssets = (store: IStore): { [id: string]: ICashSavingsAsset } =>
  store.finances.cashSavingsAssets;

export const getCashSavingsAssetsAsArray = (store: IStore): ICashSavingsAsset[] =>
  Object.values(getCashSavingsAssets(store));

export const getManualCashSavingsBreakdownInSGD = (store: IStore): IAssetBreakdown[] =>
  Object.entries(getCashSavingsAssets(store)).map(([_, { name, currency, totalValueAmount }]) => ({
    name,
    amount: convertToSGD(totalValueAmount, currency),
  }));

export const getCashSavingsBreakdownInSGD = (store: IStore): IAssetBreakdown[] => {
  return getManualCashSavingsBreakdownInSGD(store).concat(getApimCasaBreakdownInSGD(store));
};

export const getTotalManualCashSavingsInSGD = (store: IStore): number =>
  Object.values(getCashSavingsAssets(store)).reduce<number>(
    (acc, { totalValueAmount, currency }) => acc + convertToSGD(totalValueAmount, currency),
    0
  );

export const getBondAssets = (store: IStore): { [id: string]: IBondAsset } =>
  store.finances.bondAssets;

export const getBondAssetsAsArray = (store: IStore): IBondAsset[] =>
  Object.values(store.finances.bondAssets);

export const getGoldAssets = (store: IStore): { [id: string]: IGoldAsset } =>
  store.finances.goldAssets;

export const getGoldAssetsAsArray = (store: IStore): IGoldAsset[] =>
  Object.values(store.finances.goldAssets);

export const getFixedDepositAssets = (store: IStore): { [id: string]: IFixedDepositAsset } =>
  store.finances.fixedDepositAssets;

export const getFixedDepositAssetsAsArray = (store: IStore): IFixedDepositAsset[] =>
  Object.values(getFixedDepositAssets(store));

interface IGetTotalManualOrApimFixedDepositsInSGDOptions {
  excludeCash?: boolean;
  excludeCpfis?: boolean;
  excludeSrs?: boolean;
}

export const getTotalManualFixedDepositsInSGD = (
  store: IStore,
  {
    excludeCash = false,
    excludeCpfis = false,
    excludeSrs = false,
  }: IGetTotalManualOrApimFixedDepositsInSGDOptions = {}
): number =>
  Object.values(getFixedDepositAssets(store)).reduce<number>(
    (acc, { totalValueAmount, currency, investmentSource }) => {
      if (
        (excludeCash && investmentSource === INVESTMENT_SOURCE.CASH) ||
        (excludeCpfis && investmentSource === INVESTMENT_SOURCE.CPFIS) ||
        (excludeSrs && investmentSource === INVESTMENT_SOURCE.SRS)
      ) {
        return acc;
      }
      return acc + convertToSGD(totalValueAmount, currency);
    },
    0
  );

export const getInsuranceAssets = (store: IStore): { [id: string]: IInsuranceAsset } =>
  store.finances.insuranceAssets;

export const getInsuranceAssetsAsArray = (store: IStore): IInsuranceAsset[] =>
  Object.values(getInsuranceAssets(store));

export const getStockAssets = (store: IStore): { [id: string]: IStockAsset } =>
  store.finances.stockAssets;

export const getStockAssetsAsArray = (store: IStore): IStockAsset[] =>
  Object.values(getStockAssets(store));

export const getStructuredProductAssets = (
  store: IStore
): { [id: string]: IStructuredProductAsset } => store.finances.structuredProductAssets;

export const getStructuredProductAssetsAsArray = (store: IStore): IStructuredProductAsset[] =>
  Object.values(getStructuredProductAssets(store));

export const getUnitTrustAssets = (store: IStore): { [id: string]: IUnitTrustAsset } =>
  store.finances.unitTrustAssets;

export const getUnitTrustAssetsAsArray = (store: IStore): IUnitTrustAsset[] =>
  Object.values(getUnitTrustAssets(store));

export const getOtherInvestmentAssets = (store: IStore): { [id: string]: IOtherInvestmentAsset } =>
  store.finances.otherInvestmentAssets;

export const getOtherInvestmentAssetsAsArray = (store: IStore): IOtherInvestmentAsset[] =>
  Object.values(getOtherInvestmentAssets(store));

export interface IGetInvestmentsBreakdownInSGDOptions {
  excludeCashBalances?: boolean;
  excludeZeroAmount?: boolean;
}

export const getInvestmentsBreakdownInSGD = (
  store: IStore,
  {
    excludeCashBalances = false,
    excludeZeroAmount = false,
    excludePercentageShare = false,
  }: IGetInvestmentsBreakdownInSGDOptions & IGetExcludePercentageShareOption = {}
) => {
  const manualInvestments = getManualInvestmentsBreakdownInSGD(store, { excludeCashBalances });
  const apimInvestments = getApimInvestmentsBreakdownInSGD(store, {
    excludeCashBalances,
    excludePercentageShare,
  });

  const nonOthersInvestment = [...manualInvestments, ...apimInvestments].filter(
    (v) => v.type !== ASSET_TYPE.OTHER_INVESTMENT
  );
  const otherInvestment = [...manualInvestments, ...apimInvestments].filter(
    (v) => v.type === ASSET_TYPE.OTHER_INVESTMENT
  );

  const aggregatedInvestments = [...nonOthersInvestment, ...otherInvestment].sort(
    (a, b) => b.amount - a.amount
  );

  return excludeZeroAmount
    ? aggregatedInvestments.filter((investment) => investment.amount)
    : aggregatedInvestments;
};

export const getManualInvestmentsBreakdownInSGD = (
  store: IStore,
  { excludeCashBalances = false }: IGetInvestmentsBreakdownInSGDOptions = {}
): IInvestmentBreakdown[] => {
  const cashBalances = getCashBalanceAssetsAsArray(store);
  const cashBalanceAssetsBreakdown = cashBalances.map(
    ({ id, name, totalValueAmount, investmentSource }) => ({
      id,
      name,
      amount: totalValueAmount,
      investmentSource,
      type: ASSET_TYPE.CASH_BALANCE,
      estimatedGrowthRate: 0,
    })
  );

  const bonds = getBondAssetsAsArray(store);
  const stocks = getStockAssetsAsArray(store);
  const uts = getUnitTrustAssetsAsArray(store);
  const others = getOtherInvestmentAssetsAsArray(store);
  const unitPriceAssetsBreakdown = ([
    ...bonds,
    ...stocks,
    ...uts,
    ...others,
  ] as IInvestmentAssetWithUnitPrice[]).map(
    ({
      id,
      name,
      unitPrice,
      quantity,
      totalValueAmount,
      currency,
      enterOption,
      investmentSource,
      type,
      estimatedGrowthRate,
    }) => ({
      id,
      name,
      amount:
        enterOption === ENTER_OPTION.TOTAL_VALUE
          ? convertToSGD(totalValueAmount, currency)
          : convertToSGD(unitPrice * quantity, currency),
      investmentSource,
      type,
      estimatedGrowthRate,
    })
  );

  const insurances = getInsuranceAssetsAsArray(store);
  const structuredProds = getStructuredProductAssetsAsArray(store);
  const fds = getFixedDepositAssetsAsArray(store);
  const totalValAssetsBreakdown = ([
    ...insurances,
    ...structuredProds,
    ...fds,
  ] as IInvestmentAssetWithTotalValue[]).map(
    ({ id, name, totalValueAmount, currency, investmentSource, type, estimatedGrowthRate }) => ({
      id,
      name,
      amount: convertToSGD(totalValueAmount, currency),
      investmentSource,
      type,
      estimatedGrowthRate,
    })
  );

  const golds = getGoldAssetsAsArray(store);
  const goldsBreakdown = golds.map(
    ({
      id,
      name,
      quantity,
      totalValueAmount,
      currency,
      enterOption,
      investmentSource,
      type,
      estimatedGrowthRate,
    }) => ({
      id,
      name,
      amount:
        enterOption === ENTER_OPTION.TOTAL_VALUE
          ? convertToSGD(totalValueAmount, currency)
          : convertToSGD(parseFloat(quantity) * convertToSGD(100, 'XAU'), currency),
      investmentSource,
      type,
      estimatedGrowthRate,
    })
  );

  return [
    ...(excludeCashBalances ? [] : cashBalanceAssetsBreakdown),
    ...unitPriceAssetsBreakdown,
    ...totalValAssetsBreakdown,
    ...goldsBreakdown,
  ];
};

export const getInvestmentsBreakdownBySourceInSGD = (store: IStore): IAssetBreakdownBySource[] => {
  const getSum = (assets: IInvestmentBreakdown[]) => {
    return assets ? assets.reduce((acc, curr) => acc + curr.amount, 0) : 0;
  };

  const manualInvestments = getManualInvestmentsBreakdownInSGD(store);
  const apimInvestments = getApimInvestmentsBreakdownInSGD(store);
  const combinedInvestments = groupBy(
    manualInvestments.concat(apimInvestments),
    'investmentSource'
  );

  return [
    {
      name: 'Cash investments',
      investmentSource: INVESTMENT_SOURCE.CASH,
      amount: getSum(combinedInvestments[INVESTMENT_SOURCE.CASH]),
    },
    {
      name: INVESTMENT_SOURCE.CPFIS,
      investmentSource: INVESTMENT_SOURCE.CPFIS,
      amount: getSum(combinedInvestments[INVESTMENT_SOURCE.CPFIS]),
      accountNumber: getApimCpfisAccountNumber(store),
    },
    {
      name: INVESTMENT_SOURCE.SRS,
      investmentSource: INVESTMENT_SOURCE.SRS,
      amount: getSum(combinedInvestments[INVESTMENT_SOURCE.SRS]),
      accountNumber: getApimSrsAccountNumber(store),
    },
  ].filter(({ amount }) => amount > 0);
};

export const getOtherNonInvestmentAssets = (
  store: IStore
): { [id: string]: IOtherNonInvestmentAsset } => store.finances.otherNonInvestmentAssets;

export const getOtherNonInvestmentAssetsAsArray = (store: IStore): IOtherNonInvestmentAsset[] =>
  Object.values(getOtherNonInvestmentAssets(store));

export const getOtherNonInvestmentAssetsBreakdownInSGD = (store: IStore): IAssetBreakdown[] =>
  getOtherNonInvestmentAssetsAsArray(store).map(({ name, totalValueAmount, currency }) => ({
    name,
    amount: convertToSGD(totalValueAmount, currency),
  }));

export const getManualAssets = (store: IStore): IManualAsset[] => {
  const manualAssets: IManualAsset[] = [
    ...getCashBalanceAssetsAsArray(store),
    ...getCashSavingsAssetsAsArray(store),
    ...getBondAssetsAsArray(store),
    ...getGoldAssetsAsArray(store),
    ...getFixedDepositAssetsAsArray(store),
    ...getInsuranceAssetsAsArray(store),
    ...getStockAssetsAsArray(store),
    ...getStructuredProductAssetsAsArray(store),
    ...getUnitTrustAssetsAsArray(store),
    ...getOtherInvestmentAssetsAsArray(store),
    ...getOtherNonInvestmentAssetsAsArray(store),
  ];

  if (!store.myinfo.cpf) {
    manualAssets.push(getCpfAsset(store));
  } else {
    manualAssets.push(initialState.cpfAsset);
  }

  return manualAssets;
};

export const getTotalManualAssetsValueInSGD = (store: IStore) =>
  getManualAssets(store).reduce((acc, curr) => {
    switch (curr.type) {
      case ASSET_TYPE.CASH_BALANCE: {
        const { totalValueAmount } = curr as ICashBalanceAsset;
        return acc + totalValueAmount;
      }
      case ASSET_TYPE.CASH_SAVINGS:
      case ASSET_TYPE.OTHER_NON_INVESTMENT: {
        const { currency, totalValueAmount } = curr as ICashSavingsAsset | IOtherNonInvestmentAsset;
        return acc + convertToSGD(totalValueAmount, currency);
      }
      case ASSET_TYPE.BOND:
      case ASSET_TYPE.STOCK:
      case ASSET_TYPE.UNIT_TRUST:
      case ASSET_TYPE.OTHER_INVESTMENT: {
        const {
          unitPrice,
          quantity,
          totalValueAmount,
          currency,
          enterOption,
        } = curr as IInvestmentAssetWithUnitPrice;
        if (enterOption === ENTER_OPTION.TOTAL_VALUE) {
          return acc + convertToSGD(totalValueAmount, currency);
        } else {
          return acc + convertToSGD(unitPrice * quantity, currency);
        }
      }
      case ASSET_TYPE.GOLD: {
        const { quantity, totalValueAmount, currency, enterOption } = curr as IGoldAsset;
        if (enterOption === ENTER_OPTION.TOTAL_VALUE) {
          return acc + convertToSGD(totalValueAmount, currency);
        } else {
          return acc + convertToSGD(parseFloat(quantity) * convertToSGD(100, 'XAU'), currency);
        }
      }
      case ASSET_TYPE.INSURANCE:
      case ASSET_TYPE.STRUCTURED_PRODUCT:
      case ASSET_TYPE.FIXED_DEPOSIT: {
        const { totalValueAmount, currency } = curr as IFixedDepositAsset;
        return acc + convertToSGD(totalValueAmount, currency);
      }
      case ASSET_TYPE.CPF: {
        const { ma, oa, ra, sa } = curr as ICpfAsset;
        return acc + ma + oa + ra + sa;
      }
      default:
        return acc;
    }
  }, 0);

export const getTotalAssetsValueInSGD = (store: IStore) =>
  getTotalManualAssetsValueInSGD(store) +
  getTotalApimAssetsValueInSGD(store) +
  getMyinfoCPFTotalValueInSGD(store);

export const getTotalBudget = (store: IStore) => store.finances.totalBudget;

export const getInvestmentPortfolio = (
  store: IStore,
  portfolioSource: INVESTMENT_SOURCE
): IInvestmentPortfolio => {
  const investments = getInvestmentsBreakdownInSGD(store, { excludeCashBalances: true });
  const investmentsInPortfolio = groupBy(investments, 'investmentSource')[portfolioSource] || [];

  let totalAmount = 0;
  let totalEstimatedReturns = 0;

  investmentsInPortfolio.forEach(({ amount, estimatedGrowthRate }) => {
    totalAmount += amount;
    totalEstimatedReturns += amount * (estimatedGrowthRate || 0) * 0.01;
  });

  return {
    totalAmount: Math.round(totalAmount),
    totalEstimatedReturns: Math.round(totalEstimatedReturns),
    averageEstimatedReturnsRate: totalAmount
      ? Number(((totalEstimatedReturns / totalAmount) * 100).toFixed(2))
      : 0,
    investments: investmentsInPortfolio,
  };
};

/**
 * Extracts the number from a provided string value in pixel.
 * @param valueInPxString String in the format of 'xpx' e.g. 24px
 * @returns Number x
 */
export const convertPxStringToNumber = (valueInPxString: string) => {
  if (!/\d+(px)/.test(valueInPxString)) {
    throw new Error("Provided argument not in a valid format of 'xpx'");
  }
  return Number(valueInPxString.replace('px', ''));
};

import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

import { minWidth, colorScale } from 'themes';

interface IBarBaseProps {
  type: 'main' | 'sub' | 'empty';
}

export const BarBase = styled.div<IBarBaseProps>`
  border-radius: 24px;
  ${switchProp('type', {
    main: css`
      background: linear-gradient(#53c5fa, #826fff);
      color: ${colorScale('primary', 50)};
    `,
    sub: css`
      background: linear-gradient(#d34bb2, #ea9442);
    `,
    empty: css`
      background: ${colorScale('grey', 15)};
      border: 1px dashed ${colorScale('grey', 30)};
    `,
  })}
`;

interface IBarProps extends IBarBaseProps {
  // As of now it's not possible to separate out the widths, because we'll either end up passing the css prop or passing in an extended StyledBar
  widths: [number, number];
  heightPercentage: number;
}

export const Bar = styled(BarBase)<IBarProps>`
  ${(props) => css`
    width: ${props.widths[0]}px;
    height: max(${props.heightPercentage}%, ${props.widths[0]}px);
    ${minWidth(
      'desktop',
      css`
        width: ${props.widths[1]}px;
        height: max(${props.heightPercentage}%, ${props.widths[1]}px);
      `
    )}
  `}
`;

export const Dot = styled(BarBase)`
  width: 10px;
  height: 10px;
`;

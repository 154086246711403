import React from 'react';
import styled, { css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { maxWidth, minWidth } from 'themes';

import { ProgressBar, IProgressBarProps } from '..';
import { Container } from '../Container/Container';

import { Title, ProgressBarWrapper } from './sharedStyles';

const Wrapper = styled(Container)`
  grid-area: module-header;
  z-index: ${fromTheme('layers.moduleHeader')};

  ${maxWidth(
    'tablet',
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 32px 0;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      display: grid;
      grid-template-columns: minmax(0, 1fr) 2fr;
      grid-template-areas: '. .' '. main';
      grid-column-gap: 16px;
      justify-items: flex-start;
      align-items: center;
    `
  )}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${maxWidth(
    'tablet',
    css`
      align-items: center;
      margin-top: 24px;
    `
  )}

  ${minWidth(
    'desktop',
    css`
      grid-area: main;
      margin: 64px 0 32px 0;
    `
  )}
`;

interface IProps {
  /** Title text */
  title: string;

  /** Progress bar values */
  progressValues?: IProgressBarProps;

  className?: string;
  breadcrumbs: React.ReactNode;
}

export const ModuleHeaderWithBreadcrumbs = ({
  title,
  progressValues,
  className,
  breadcrumbs,
}: IProps) => {
  return (
    <Wrapper className={className}>
      {breadcrumbs}
      <InnerWrapper>
        <Title>{title}</Title>
        {progressValues && (
          <ProgressBarWrapper>
            <ProgressBar current={progressValues.current} total={progressValues.total} />
          </ProgressBarWrapper>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

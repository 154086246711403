import React from 'react';
import styled from 'styled-components';

import { WomanHoldingUmbrellaInRainImage } from 'assets/ProtectionCard';
import { IGoalForRender, IEmergencyFundForRender } from 'constants/storeTypes';
import { typography } from 'themes';

import { AchievabilityBox } from './AchievabilityBox';
import { EditButton } from './EditButton';
import { ProgressBarSection } from './ProgressBarSection';
import { TargetAmount } from './TargetAmount';

interface IProps {
  editCallback: () => void;
  target: IGoalForRender | IEmergencyFundForRender;
  projectedTarget?: IGoalForRender | IEmergencyFundForRender;
  showProgress?: boolean;
}

const AchievabilityWrapper = styled.div`
  display: grid;
  grid-template-columns: fit-content(100%) max-content;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const TargetWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TargetName = styled.p`
  ${typography('body-l-bold')}
  margin: 0;
`;

const TargetNameRow = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  height: 36px;
  width: 84px;
  min-width: 84px;
  margin: 0 -24px 0 0;
`;

const StyledEmergencyFundImage = styled(WomanHoldingUmbrellaInRainImage)`
  opacity: 0.8;
  width: 100%;
`;

export const TargetContent: React.FC<IProps> = ({
  editCallback,
  projectedTarget,
  showProgress,
  target,
}) => {
  const isEmergencyFund = !target.hasOwnProperty('id');
  const targetName = isEmergencyFund ? 'Emergency fund' : (target as IGoalForRender).name;

  return (
    <React.Fragment>
      {projectedTarget ? (
        <React.Fragment>
          <AchievabilityWrapper>
            <AchievabilityBox
              data-testid="achievability-box"
              details={{
                target,
                projectedTarget,
              }}
            />
            <EditButton onClick={editCallback} />
          </AchievabilityWrapper>
          <TargetNameRow>
            <TargetWrapper>
              <TargetName>{targetName}</TargetName>
              <TargetAmount amount={showProgress ? target.currentAmount : target.totalAmount} />
            </TargetWrapper>
            {isEmergencyFund && (
              <ImageWrapper>
                <StyledEmergencyFundImage />
              </ImageWrapper>
            )}
          </TargetNameRow>
        </React.Fragment>
      ) : (
        <TargetWrapper>
          <TargetNameRow>
            <TargetName>{targetName}</TargetName>
            <EditButton onClick={editCallback} />
          </TargetNameRow>
          <TargetNameRow>
            <TargetAmount amount={showProgress ? target.currentAmount : target.totalAmount} />
            {isEmergencyFund && (
              <ImageWrapper id="tc-girl-bg">
                <StyledEmergencyFundImage />
              </ImageWrapper>
            )}
          </TargetNameRow>
        </TargetWrapper>
      )}
      {showProgress && <ProgressBarSection target={target} />}
    </React.Fragment>
  );
};

import * as React from 'react';
import styled from 'styled-components';

import { Delete } from 'assets/icons';

import { IconButton } from '../IconButton/IconButton';

import { Panel } from './Panel';

const StyledPanel = styled(Panel)`
  position: relative;
  padding: 20px 16px 40px;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

interface IProps {
  onDelete: () => void;
  children: React.ReactNode;
}

export const PanelWithDeleteIcon: React.FC<IProps & React.HTMLAttributes<HTMLDivElement>> = ({
  onDelete,
  children,
  ...otherProps
}) => {
  return (
    <StyledPanel {...otherProps}>
      <StyledIconButton
        data-testid="panel-delete"
        ariaLabel="Delete button"
        variant="grey"
        onClick={onDelete}
      >
        <Delete />
      </StyledIconButton>
      {children}
    </StyledPanel>
  );
};

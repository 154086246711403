import { DASHBOARD, HOUSING, MORE, PROFILE, RESOURCES, TARGETS, SETTINGS } from 'constants/urls';

import { NAVIGATION_TYPE } from './enums';

export const navigationDetails = {
  [NAVIGATION_TYPE.DASHBOARD]: {
    navbarTitle: 'Dashboard',
    title: 'Review your finances',
    urlPath: DASHBOARD.baseRoute,
  },
  [NAVIGATION_TYPE.PROFILE]: {
    title: 'Profile',
    urlPath: PROFILE.baseRoute,
  },
  [NAVIGATION_TYPE.TARGETS]: {
    navbarTitle: 'Targets',
    title: 'Create your financial targets',
    urlPath: TARGETS.baseRoute,
  },
  [NAVIGATION_TYPE.SAVINGS]: {
    title: 'Savings',
    urlPath: RESOURCES.SAVINGS.baseRoute,
  },
  [NAVIGATION_TYPE.LOANS_AND_CREDIT]: {
    title: 'Loans and credit',
    urlPath: RESOURCES.LOANS_AND_CREDIT.baseRoute,
  },
  [NAVIGATION_TYPE.INVESTMENT]: {
    title: 'Investments',
    urlPath: RESOURCES.INVESTMENT.baseRoute,
  },
  [NAVIGATION_TYPE.INSURANCE]: {
    title: 'Insurance',
    urlPath: RESOURCES.INSURANCE.baseRoute,
  },
  [NAVIGATION_TYPE.HOUSING]: {
    title: 'Buying a house',
    urlPath: HOUSING.housingResources,
  },
  [NAVIGATION_TYPE.RETIREMENT]: {
    title: 'Retirement planning',
    urlPath: RESOURCES.RETIREMENT.baseRoute,
  },
  [NAVIGATION_TYPE.SETTINGS]: {
    title: 'Settings',
    urlPath: SETTINGS.baseRoute,
  },
  [NAVIGATION_TYPE.ABOUT_US]: {
    title: 'About us',
    urlPath: MORE.aboutUs,
  },
  [NAVIGATION_TYPE.CONTACT_US]: {
    title: 'Contact us',
    urlPath: process.env.REACT_APP_CONTACT_US_FORM_URL,
  },
};

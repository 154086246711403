import React from 'react';

import { Info } from 'assets/icons';
import { IconButton, IIconButtonProps } from 'components/Common';

interface IOwnProps {
  onClick: () => void;
}

type IProps = IOwnProps & Omit<IIconButtonProps, 'children' | 'ariaLabel'>;

export const InfoButton = (props: IProps) => {
  return (
    <IconButton data-testid="info-btn" ariaLabel="Info button" {...props}>
      <Info />
    </IconButton>
  );
};

import React from 'react';
import styled from 'styled-components';

const StyledUl = styled.ul`
  text-align: left;
  padding-inline-start: 32px;
  margin: 0;

  > li {
    padding-left: 8px;
  }
`;

export interface IUnorderedListProps {
  children: React.ReactChild | React.ReactChild[];
}

export const UnorderedList = ({
  children,
  ...otherProps
}: IUnorderedListProps & Omit<React.HTMLAttributes<HTMLUListElement>, 'children'>) => (
  <StyledUl {...otherProps}>
    {Array.isArray(children) ? (
      children.map((item: React.ReactChild, index: number) => <li key={index}>{item}</li>)
    ) : (
      <li>{children}</li>
    )}
  </StyledUl>
);

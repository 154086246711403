import { omit } from 'lodash';
import { Reducer } from 'redux';

import { IFinancialStatusActions } from 'constants/actionTypes';
import { FINANCIAL_STATUS_ACTIONS } from 'constants/enums';
import { IFinancialStatusStore } from 'constants/storeTypes';

export const initialState: IFinancialStatusStore = {
  recommendedActions: {},
};

export const financialStatusReducer: Reducer<IFinancialStatusStore, IFinancialStatusActions> = (
  state: IFinancialStatusStore = initialState,
  action: IFinancialStatusActions
) => {
  switch (action.type) {
    case FINANCIAL_STATUS_ACTIONS.SET_RECOMMENDED_ACTIONS:
      return {
        ...state,
        recommendedActions: action.recommendedActions,
      };

    case FINANCIAL_STATUS_ACTIONS.CREATE_RECOMMENDED_ACTION:
      return {
        ...state,
        recommendedActions: {
          ...state.recommendedActions,
          [action.id]: action.recommendedAction,
        },
      };

    case FINANCIAL_STATUS_ACTIONS.UPDATE_RECOMMENDED_ACTION:
      return {
        ...state,
        recommendedActions: {
          ...state.recommendedActions,
          [action.id]: { ...state.recommendedActions[action.id], ...action.update },
        },
      };

    case FINANCIAL_STATUS_ACTIONS.DELETE_RECOMMENDED_ACTION:
      return {
        ...state,
        recommendedActions: omit(state.recommendedActions, action.id),
      };

    default:
      return state;
  }
};

import React from 'react';
import styled, { css } from 'styled-components';

import { Caution, Tick } from 'assets/icons';
import { ComparisonBarSection } from 'components/Composite';
import { theme, colorScale, typography } from 'themes';

import { getBreakdownPercentage } from '../ExpenseBudgetPage/getBreakdownPercentage';

interface IProps {
  label: string;
  errorLabel: string;
  leftValue: number;
  rightValue: number;
  showErrorWhen: boolean;
}

const iconStyles = css`
  width: 20px;
  margin-right: 8px;
`;

const StyledTick = styled(Tick)`
  ${iconStyles}
  color: ${colorScale('supporting-green', 40)};
`;

const StyledCaution = styled(Caution)`
  ${iconStyles}
  color: ${colorScale('supporting-red', 40)};
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  ${typography('body-m-bold')}
`;

export const ComparisonBarWithValidation = ({
  label,
  errorLabel,
  leftValue,
  rightValue,
  showErrorWhen: isInvalid,
}: IProps) => {
  const currentBgColor = isInvalid
    ? colorScale('supporting-red', 30)({ theme })
    : colorScale('primary', 30)({ theme });

  const totalBgColor = colorScale('grey', 20)({ theme });

  return (
    <>
      {isInvalid ? (
        <TextWrapper>
          <StyledCaution data-testid="caution-icon" />
          {errorLabel}
        </TextWrapper>
      ) : (
        <TextWrapper>
          <StyledTick data-testid="tick-icon" />
          {label}
        </TextWrapper>
      )}
      <ComparisonBarSection
        current={{
          amount: leftValue,
          bgColor: currentBgColor,
        }}
        total={{
          amount: rightValue,
          bgColor: totalBgColor,
        }}
        percentage={getBreakdownPercentage(leftValue, rightValue)}
      />
    </>
  );
};

import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp, theme as fromTheme } from 'styled-tools';

import { Lock, Tick } from 'assets/icons';
import { colorScale, typography } from 'themes';

import { RoutedButton } from '../Button/RoutedButton';

interface IStyledButton {
  shouldHighlight: boolean;
  disabled: boolean;
}

interface IConnector {
  noOfSteps: number;
  stepNumber: number;
  stepsGap: string;
}

const StyledButton = styled(RoutedButton)<IStyledButton>`
  background: white;
  ${typography('body-l')}
  padding: 16px;

  ${ifProp(
    'disabled',
    css`
      border-style: dashed;
      border-color: ${colorScale('grey', 30)};
      color: ${colorScale('grey', 90)};
    `
  )}

  ${ifProp(
    'shouldHighlight',
    css`
      border-color: ${colorScale('grey', 10)};

      &:hover,
      &:focus {
        border-color: ${colorScale('grey', 20)};
      }
    `
  )}
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconCircle = styled.div<{ locked: boolean; completed: boolean }>`
  background: ${ifProp(
    'locked',
    colorScale('grey', 15),
    ifProp('completed', colorScale('secondary', 20), colorScale('primary', 30))
  )};
  color: white;
  ${typography('heading-s')}
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.25rem;
  border-radius: 50%;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const StyledLock = styled(Lock)`
  color: ${colorScale('grey', 50)};
`;

const StyledTick = styled(Tick)`
  color: ${colorScale('secondary', 30)};
`;

const StepName = styled.div<{ isUpcoming?: boolean }>`
  text-align: left;
  flex-grow: 1;
  ${typography('body-l')}
  ${ifProp(
    'isUpcoming',
    css`
      color: ${colorScale('grey', 80)};
    `
  )};
`;

const Connector = styled.div<{ noOfSteps: number; stepNumber: number; stepsGap: string }>`
  background: ${colorScale('grey', 50)};
  width: 1.5px;
  height: calc(100% + ${prop('stepsGap')} * 2);
  position: absolute;
  left: 36px;

  ${ifProp(
    { stepNumber: 1 },
    css`
      top: 50%;
      height: calc(50% + ${prop('stepsGap')});
    `
  )};

  ${ifProp(
    (props: IConnector) => props.stepNumber === props.noOfSteps,
    css`
      bottom: 50%;
      height: calc(50% + ${prop('stepsGap')});
    `
  )};

  ${ifProp(
    (props: IConnector) => props.noOfSteps === 1,
    css`
      display: none;
    `
  )};
`;

const ComingSoonBanner = styled.div`
  background: ${colorScale('grey', 15)};
  color: ${colorScale('grey', 80)};
  border-radius: ${fromTheme('borders.radiusLarge')};
  padding: 8px;
  margin-right: 16px;
  ${typography('label')}
`;

interface IProps {
  stepName: string;
  stepNumber: number;
  stepsGap: string;
  totalSteps: number;
  route: string;
  completed: boolean;
  disabled: boolean;
  isUpcoming?: boolean;
  onClick?: () => void;
}

export class ModuleStepCard extends React.Component<IProps> {
  public render() {
    const {
      stepName,
      stepNumber,
      stepsGap,
      totalSteps,
      route,
      disabled,
      completed,
      isUpcoming,
      onClick,
    } = this.props;

    return (
      <StyledButton
        to={route}
        shouldHighlight={!disabled && !completed}
        disabled={disabled}
        variant="panel"
        widthStyle="fluid"
        data-testid={`step-${stepNumber}-button`}
        onClick={onClick}
      >
        <ButtonContent>
          {isUpcoming ? (
            <ComingSoonBanner>COMING SOON</ComingSoonBanner>
          ) : (
            <IconCircle locked={disabled} completed={completed}>
              {disabled ? <StyledLock /> : completed ? <StyledTick /> : stepNumber}
            </IconCircle>
          )}
          <StepName isUpcoming={isUpcoming}>{stepName}</StepName>
          {!isUpcoming && (
            <Connector noOfSteps={totalSteps} stepNumber={stepNumber} stepsGap={stepsGap} />
          )}
        </ButtonContent>
      </StyledButton>
    );
  }
}

import React from 'react';

import { ConfusedDudeImage } from 'assets/Retirement';
import { TallManFatBoyTemplate, BannerHeader, HalfCurtain } from 'components/Templates';
import { colorScale } from 'themes';

import { DESCRIPTION, RetirementResourcesContent } from './RetirementResourcesContent';

export const RetirementResourcesPage = () => {
  return (
    <>
      <HalfCurtain curtainColor={colorScale('primary', 10)} />
      <TallManFatBoyTemplate
        header={
          <BannerHeader
            title="Getting started on retirement planning"
            description={DESCRIPTION}
            image={{ mobile: <ConfusedDudeImage />, desktop: <ConfusedDudeImage /> }}
          />
        }
        content={<RetirementResourcesContent />}
      />
    </>
  );
};

import { omit } from 'lodash';
import { Reducer } from 'redux';

import { IFinancesActions } from 'constants/actionTypes';
import { FINANCES_ACTIONS, LIABILITY_TYPE, ASSET_TYPE } from 'constants/enums';
import { IFinancesStore } from 'constants/storeTypes';

import { mockInitialStates } from './../mockInitialStates';

export const initialState: IFinancesStore = {
  incomeList: {},
  liabilities: {},
  expenses: {},
  estimatedMonthlyExpenses: 0,
  loanRepayments: {},
  investments: {},
  targetMonthlySavings: 0,
  dayOfTransfer: 0,
  automaticTransferStatus: null,
  assets: {},
  cpfAsset: {
    id: '0',
    oa: 0,
    sa: 0,
    ma: 0,
    ra: 0,
    type: ASSET_TYPE.CPF,
  },
  cashBalanceAssets: {},
  cashSavingsAssets: {},
  bondAssets: {},
  goldAssets: {},
  fixedDepositAssets: {},
  insuranceAssets: {},
  stockAssets: {},
  structuredProductAssets: {},
  unitTrustAssets: {},
  otherInvestmentAssets: {},
  otherNonInvestmentAssets: {},
  totalBudget: 0,
};

export const financesReducer: Reducer<IFinancesStore, IFinancesActions> = (
  state: IFinancesStore = process.env.REACT_APP_DEPLOYMENT_ENV &&
  ['development', 'staging'].includes(process.env.REACT_APP_DEPLOYMENT_ENV) &&
  mockInitialStates.finances !== null
    ? mockInitialStates.finances
    : initialState,
  action: IFinancesActions
) => {
  switch (action.type) {
    case FINANCES_ACTIONS.CREATE_INCOME:
      return {
        ...state,
        incomeList: {
          ...state.incomeList,
          [action.income.id]: action.income,
        },
      };

    case FINANCES_ACTIONS.SET_INCOME_LIST:
      return {
        ...state,
        incomeList: action.incomeList.reduce((obj, curr) => ({ ...obj, [curr.id]: curr }), {}),
      };

    case FINANCES_ACTIONS.SET_LIABILITIES:
      return {
        ...state,
        liabilities: action.liabilities,
      };

    case FINANCES_ACTIONS.SET_LIABILITY:
      return {
        ...state,
        liabilities: {
          ...state.liabilities,
          [action.liability.id]: action.liability,
        },
      };

    case FINANCES_ACTIONS.DELETE_LIABILITY:
      return {
        ...state,
        liabilities: omit(state.liabilities, action.id),
      };

    case FINANCES_ACTIONS.CREATE_EXPENSE: {
      const { id } = action.expense;
      return {
        ...state,
        expenses: { ...state.expenses, [id]: action.expense },
      };
    }

    case FINANCES_ACTIONS.SET_EXPENSES: {
      return {
        ...state,
        expenses: action.expenses,
      };
    }

    case FINANCES_ACTIONS.UPDATE_EXPENSE:
      return {
        ...state,
        expenses: {
          ...state.expenses,
          [action.id]: { ...state.expenses[action.id], ...action.update },
        },
      };

    case FINANCES_ACTIONS.DELETE_EXPENSE:
      return {
        ...state,
        expenses: omit(state.expenses, action.id),
      };

    case FINANCES_ACTIONS.CLEAR_EXPENSES:
      return {
        ...state,
        expenses: {},
      };

    case FINANCES_ACTIONS.SET_ESTIMATED_MONTHLY_EXPENSES:
      return {
        ...state,
        estimatedMonthlyExpenses: action.estimatedMonthlyExpenses,
      };

    case FINANCES_ACTIONS.SET_LOAN_REPAYMENTS:
      return {
        ...state,
        loanRepayments: action.loanRepaymentList.reduce(
          (obj, curr) => ({
            ...obj,
            [curr.id]: { ...curr, type: LIABILITY_TYPE[curr.type.id] },
          }),
          {}
        ),
      };

    case FINANCES_ACTIONS.SET_INVESTMENT:
      return {
        ...state,
        investments: {
          ...state.investments,
          [action.investment.id]: action.investment,
        },
      };

    case FINANCES_ACTIONS.DELETE_INVESTMENT:
      return {
        ...state,
        investments: omit(state.investments, action.id),
      };

    case FINANCES_ACTIONS.SET_TARGET_MONTHLY_SAVINGS:
      return { ...state, targetMonthlySavings: action.targetMonthlySavings };

    case FINANCES_ACTIONS.SET_DAY_OF_TRANSFER:
      return { ...state, dayOfTransfer: action.dayOfTransfer };

    case FINANCES_ACTIONS.SET_HAS_SETUP_AUTOMATIC_TRANSFER:
      return { ...state, automaticTransferStatus: action.automaticTransferStatus };

    case FINANCES_ACTIONS.SET_CPF_ASSET:
      return {
        ...state,
        cpfAsset: action.cpfAsset,
      };

    case FINANCES_ACTIONS.SET_CASH_BALANCE_ASSET:
      return {
        ...state,
        cashBalanceAssets: {
          ...state.cashBalanceAssets,
          [action.cashBalanceAsset.id]: action.cashBalanceAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_CASH_BALANCE_ASSET:
      return {
        ...state,
        cashBalanceAssets: omit(state.cashBalanceAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_CASH_SAVINGS_ASSET:
      return {
        ...state,
        cashSavingsAssets: {
          ...state.cashSavingsAssets,
          [action.cashSavingsAsset.id]: action.cashSavingsAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_CASH_SAVINGS_ASSET:
      return {
        ...state,
        cashSavingsAssets: omit(state.cashSavingsAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_BOND_ASSET:
      return {
        ...state,
        bondAssets: {
          ...state.bondAssets,
          [action.bondAsset.id]: action.bondAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_BOND_ASSET:
      return {
        ...state,
        bondAssets: omit(state.bondAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_GOLD_ASSET:
      return {
        ...state,
        goldAssets: {
          ...state.goldAssets,
          [action.goldAsset.id]: action.goldAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_GOLD_ASSET:
      return {
        ...state,
        goldAssets: omit(state.goldAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_FIXED_DEPOSIT_ASSET:
      return {
        ...state,
        fixedDepositAssets: {
          ...state.fixedDepositAssets,
          [action.fixedDepositAsset.id]: action.fixedDepositAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_FIXED_DEPOSIT_ASSET:
      return {
        ...state,
        fixedDepositAssets: omit(state.fixedDepositAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_INSURANCE_ASSET:
      return {
        ...state,
        insuranceAssets: {
          ...state.insuranceAssets,
          [action.insuranceAsset.id]: action.insuranceAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_INSURANCE_ASSET:
      return {
        ...state,
        insuranceAssets: omit(state.insuranceAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_STOCK_ASSET:
      return {
        ...state,
        stockAssets: {
          ...state.stockAssets,
          [action.stockAsset.id]: action.stockAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_STOCK_ASSET:
      return {
        ...state,
        stockAssets: omit(state.stockAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_STRUCTURED_PRODUCT_ASSET:
      return {
        ...state,
        structuredProductAssets: {
          ...state.structuredProductAssets,
          [action.structuredProductAsset.id]: action.structuredProductAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_STRUCTURED_PRODUCT_ASSET:
      return {
        ...state,
        structuredProductAssets: omit(state.structuredProductAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_UNIT_TRUST_ASSET:
      return {
        ...state,
        unitTrustAssets: {
          ...state.unitTrustAssets,
          [action.unitTrustAsset.id]: action.unitTrustAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_UNIT_TRUST_ASSET:
      return {
        ...state,
        unitTrustAssets: omit(state.unitTrustAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_OTHER_INVESTMENT_ASSET:
      return {
        ...state,
        otherInvestmentAssets: {
          ...state.otherInvestmentAssets,
          [action.otherInvestmentAsset.id]: action.otherInvestmentAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_OTHER_INVESTMENT_ASSET:
      return {
        ...state,
        otherInvestmentAssets: omit(state.otherInvestmentAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_OTHER_NON_INVESTMENT_ASSET:
      return {
        ...state,
        otherNonInvestmentAssets: {
          ...state.otherNonInvestmentAssets,
          [action.otherNonInvestmentAsset.id]: action.otherNonInvestmentAsset,
        },
      };

    case FINANCES_ACTIONS.DELETE_OTHER_NON_INVESTMENT_ASSET:
      return {
        ...state,
        otherNonInvestmentAssets: omit(state.otherNonInvestmentAssets, action.id),
      };

    case FINANCES_ACTIONS.SET_TOTAL_BUDGET:
      return {
        ...state,
        totalBudget: action.totalBudget,
      };

    default:
      return state;
  }
};

import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { InfoButton } from 'components/Common';

import {
  ApimMarketPriceInfoModal,
  IApimMarketPriceInfoModalProps,
} from './ApimMarketPriceInfoModal';

export const LabelInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export type IApimMarketPriceInputLabelProps = Pick<IApimMarketPriceInfoModalProps, 'assetType'>;

export const ApimMarketPriceInputLabel = ({ assetType }: IApimMarketPriceInputLabelProps) => {
  const [modalOpened, setModalOpened] = useState(false);

  const openModal = useCallback(() => setModalOpened(true), [setModalOpened]);
  const onExit = useCallback(() => setModalOpened(false), [setModalOpened]);

  return (
    <>
      <ApimMarketPriceInfoModal assetType={assetType} mounted={modalOpened} onExit={onExit} />

      <LabelInfoWrapper>
        Market price per unit
        <InfoButton onClick={openModal} />
      </LabelInfoWrapper>
    </>
  );
};

import axios from 'axios';

import { getFxRates } from 'redux/fxRates/selectors';

import { store } from 'store';

export const numberWithSeparator = (
  amount: number,
  decimalPlaces = 0,
  shouldSuffix = false,
  minAmountToSuffix = 999999
) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    notation: shouldSuffix && Math.abs(amount) > minAmountToSuffix ? 'compact' : 'standard',
  }).format(amount);

export const formatCurrency = (
  amountInCents: number,
  fractionalDigits = 2,
  currency = 'SGD',
  style: 'decimal' | 'currency' = 'currency'
) =>
  new Intl.NumberFormat('en-US', {
    style,
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits,
  }).format(amountInCents / 100);

export const formatStringCurrency = (
  amountInDollars: string | undefined,
  fractionalDigits = 2,
  currency = 'SGD',
  style: 'decimal' | 'currency' = 'currency'
) => {
  if (amountInDollars === undefined) return amountInDollars;
  if (typeof amountInDollars !== 'string' || !/^[-+]?\d+(.\d+)?$/.test(String(amountInDollars))) {
    // eslint-disable-next-line no-console
    console.warn(`amountInDollars (${amountInDollars}) is not in the correct format`);
    return amountInDollars;
  }

  const fractionalSplit = amountInDollars.includes('.')
    ? amountInDollars.split('.')
    : [amountInDollars, '00'];

  const wholeNumber = new Intl.NumberFormat('en-US', {
    style,
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits,
  }).format(BigInt(fractionalSplit[0]));

  // we can cast Number for fractional part as it'll never be a BigNumber
  const fractionalNumber = Number(`.${fractionalSplit[1]}`).toFixed(fractionalDigits);

  return `${wholeNumber.slice(0, -fractionalDigits)}${fractionalNumber.slice(2)}`;
};

export const currencyAbbreviationFormatter = (amountInCents: number) => {
  const isNegative = amountInCents < 0;
  const amountInDollars = Math.abs(amountInCents) / 100;
  if (amountInDollars >= 1e9) {
    return `${isNegative ? '-' : ''}${(amountInDollars / 1e9).toFixed(1).replace(/\.0$/, '')}B`;
  }
  if (amountInDollars >= 1e6) {
    return `${isNegative ? '-' : ''}${(amountInDollars / 1e6).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (amountInDollars >= 1e3) {
    return `${isNegative ? '-' : ''}${(amountInDollars / 1e3).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return isNegative ? -amountInDollars : amountInDollars;
};

export const getLatestFxRatesRequest = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/v1/fx-rates/latest`);

const memoizedConvertToSGD = () => {
  const cache: { [k: string]: number } = {};
  return (amount: number, ccy: string) => {
    if (cache[ccy]) {
      return Math.round(cache[ccy] * amount);
    } else {
      const fxRates = getFxRates(store.getState());
      const { exchangeRate } = (fxRates[ccy] || [{ exchangeRate: 0 }])[0];
      cache[ccy] = exchangeRate;
      return Math.round(amount * exchangeRate);
    }
  };
};

export const convertToSGD = memoizedConvertToSGD();

const memoizedExchangeConversion = () => {
  const cache: {
    [k: string]: {
      [k: string]: number;
    };
  } = {};
  return (from: string, to: string, amount?: number, getRates = false) => {
    if (cache[from] && cache[from][to]) {
      const rate = cache[from][to];
      return getRates ? rate : Math.round(rate * (amount || 0));
    } else {
      const fxRates = getFxRates(store.getState());
      const fromRates = (fxRates[from] || [{ exchangeRate: 0 }])[0];
      const toSGDRates = (fxRates[to] || [{ exchangeRate: 0 }])[0];
      const rate = parseFloat((fromRates.exchangeRate / toSGDRates.exchangeRate).toFixed(6)) || 0;

      cache[from] = {
        [to]: rate,
      };
      return getRates ? rate : Math.round(rate * (amount || 0));
    }
  };
};
export const convertCurrency = memoizedExchangeConversion();

import { ASSET_TYPE, INVESTMENT_SOURCE, CDP_SECURITY_TYPE_CODE } from 'constants/enums';

export const investmentTypeLabels = {
  [ASSET_TYPE.CASH_BALANCE]: 'Cash balances',
  [ASSET_TYPE.BOND]: 'Bonds',
  [ASSET_TYPE.FIXED_DEPOSIT]: 'Fixed deposits',
  [ASSET_TYPE.GOLD]: 'Gold',
  [ASSET_TYPE.INSURANCE]: 'Insurance',
  [ASSET_TYPE.STOCK]: 'Stocks',
  [ASSET_TYPE.STRUCTURED_PRODUCT]: 'Structured products',
  [ASSET_TYPE.UNIT_TRUST]: 'Unit trusts',
  [ASSET_TYPE.OTHER_INVESTMENT]: 'Others',
};

export const investmentSourceLabels = {
  [INVESTMENT_SOURCE.CASH]: 'Cash',
  [INVESTMENT_SOURCE.CPFIS]: 'CPFIS',
  [INVESTMENT_SOURCE.SRS]: 'SRS',
};

export const cdpSecurityTypeLabels = {
  [CDP_SECURITY_TYPE_CODE.CORPORATE_DEBT]: 'Corporate Debt',
  [CDP_SECURITY_TYPE_CODE.TBILL]: 'Tbill',
  [CDP_SECURITY_TYPE_CODE.TBOND]: 'Tbond',
  [CDP_SECURITY_TYPE_CODE.STRUCTURED_WARRANTS]: 'Structured Warrants',
  [CDP_SECURITY_TYPE_CODE.ABC_SHARES]: 'A/B/C Shares',
  [CDP_SECURITY_TYPE_CODE.DEPOSITORY_RECEIPTS]: 'Depository Receipts',
  [CDP_SECURITY_TYPE_CODE.LOCAL_EXCHANGE_TRADED_FUNDS]: 'Local Exchange Traded Funds',
  [CDP_SECURITY_TYPE_CODE.FOREIGN_OWNED]: 'Foreign Owned',
  [CDP_SECURITY_TYPE_CODE.LOAN_STOCKS]: 'Loan Stocks',
  [CDP_SECURITY_TYPE_CODE.ORDINARY_SHARES]: 'Ordinary Shares',
  [CDP_SECURITY_TYPE_CODE.RIGHTS]: 'Rights',
  [CDP_SECURITY_TYPE_CODE.STAPLED_SECURITY]: 'Stapled Security',
  [CDP_SECURITY_TYPE_CODE.EXTENDED_SETTLEMENT]: 'Extended Settlement',
  [CDP_SECURITY_TYPE_CODE.UNIT_TRUST]: 'Unit Trusts',
  [CDP_SECURITY_TYPE_CODE.PREFERENCE_SHARE]: 'Preference Share',
  [CDP_SECURITY_TYPE_CODE.FUND]: 'Funds',
  [CDP_SECURITY_TYPE_CODE.PLAIN_WARRANTS]: 'Plain Warrants',
  [CDP_SECURITY_TYPE_CODE.CALL_OPTION]: 'Call Option',
  [CDP_SECURITY_TYPE_CODE.PUT_OPTION]: 'Put Option',
};

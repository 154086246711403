import axios, { AxiosResponse } from 'axios';
import { Omit, difference, differenceWith, isEqual, intersection, pick, groupBy } from 'lodash';
import _ from 'lodash';
import qs from 'qs';
import { AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import uuidv4 from 'uuid/v4';

import {
  getInvestmentsArray,
  getManualLiabilities,
  getCashBalanceAssets,
  getBondAssets,
  getGoldAssets,
  getFixedDepositAssets,
  getInsuranceAssets,
  getStockAssets,
  getStructuredProductAssets,
  getUnitTrustAssets,
  getCashSavingsAssets,
  getOtherInvestmentAssets,
  getOtherNonInvestmentAssets,
} from 'redux/finances/selectors';

import { captureEmergencyFundAchievability } from 'analytics';
import {
  RequestAction,
  IClearExpenses,
  ICreateExpense,
  ISetExpenses,
  ICreateIncome,
  ISetIncomeList,
  IDeleteExpense,
  ISetDayOfTransfer,
  ISetTargetMonthlySavings,
  IUpdateExpense,
  ISetEstimatedMonthlyExpenses,
  ISetAutomaticTransferStatus,
  ISetLoanRepayments,
  ISetInvestment,
  IDeleteInvestment,
  ISetLiabilities,
  ISetLiability,
  IDeleteLiability,
  ISetCpfAsset,
  ISetCashBalanceAsset,
  IDeleteCashBalanceAsset,
  ISetCashSavingsAsset,
  IDeleteCashSavingsAsset,
  ISetBondAsset,
  IDeleteBondAsset,
  ISetGoldAsset,
  IDeleteGoldAsset,
  ISetFixedDepositAsset,
  IDeleteFixedDepositAsset,
  ISetInsuranceAsset,
  IDeleteInsuranceAsset,
  ISetStockAsset,
  IDeleteStockAsset,
  ISetStructuredProductAsset,
  IDeleteStructuredProductAsset,
  ISetUnitTrustAsset,
  IDeleteUnitTrustAsset,
  ISetOtherInvestmentAsset,
  IDeleteOtherInvestmentAsset,
  ISetOtherNonInvestmentAsset,
  IDeleteOtherNonInvestmentAsset,
  ISetTotalBudget,
} from 'constants/actionTypes';
import {
  FINANCES_ACTIONS,
  AUTOMATIC_TRANSFER_STATUS,
  TIME_INTERVAL,
  ASSET_TYPE,
  LIABILITY_TYPE,
} from 'constants/enums';
import { IExpenseResponse } from 'constants/responseTypes';
import {
  IStore,
  IExpense,
  IIncome,
  ILoanRepayment,
  IInvestment,
  ILiability,
  ICpfAsset,
  ICashBalanceAsset,
  ICashSavingsAsset,
  IBondAsset,
  IGoldAsset,
  IFixedDepositAsset,
  IInsuranceAsset,
  IStockAsset,
  IStructuredProductAsset,
  IUnitTrustAsset,
  IManualAsset,
  IOtherInvestmentAsset,
  IOtherNonInvestmentAsset,
} from 'constants/storeTypes';
import { featureDecisions } from 'featureDecisions';

export const updateFinances = (updateValues: { targetMonthlySavings: number }) => async (
  dispatch: ThunkDispatch<IStore, undefined, AnyAction>
) => {
  const { targetMonthlySavings } = updateValues;

  return Promise.all([dispatch(setTargetMonthlySavingsRequest(targetMonthlySavings))]);
};

export const createIncome = (income: IIncome): ICreateIncome => ({
  type: FINANCES_ACTIONS.CREATE_INCOME,
  income,
});

export const setIncomeList = (incomeList: IIncome[]): ISetIncomeList => ({
  type: FINANCES_ACTIONS.SET_INCOME_LIST,
  incomeList,
});

export const updateIncomeListRequest = (incomeList: IIncome[]): RequestAction => async (
  dispatch
) => {
  if (featureDecisions.mockServerResponses) {
    dispatch(setIncomeList(incomeList));
    return Promise.resolve();
  }

  const promise = axios.post(`${process.env.REACT_APP_API_URL}/v1/income`, {
    incomeList: incomeList.map((income) => ({
      id: income.id.includes('local-') ? undefined : income.id,
      name: income.name,
      grossIncome: income.grossIncome,
      netIncome: income.netIncome === null ? null : Math.round(income.netIncome), // Can sometimes be calculated to have floating point values
      incomeCurrency: income.incomeCurrency,
      type: income.type.id,
    })),
  });

  promise
    .then(({ data: { incomeList } }) => {
      dispatch(setIncomeList(incomeList));
    })
    .catch(() => {});

  return promise;
};

export const setLiability = (liability: ILiability): ISetLiability => ({
  type: FINANCES_ACTIONS.SET_LIABILITY,
  liability,
});

export const deleteLiability = (id: string): IDeleteLiability => ({
  type: FINANCES_ACTIONS.DELETE_LIABILITY,
  id,
});

export const setLiabilities = (liabilities: { [id: string]: ILiability }): ISetLiabilities => ({
  type: FINANCES_ACTIONS.SET_LIABILITIES,
  liabilities,
});

export const setLiabilitiesRequest = (
  liabilities: ILiability[]
): ThunkAction<Promise<void | AxiosResponse<any>[]>, IStore, undefined, AnyAction> => async (
  dispatch,
  getState
) => {
  // get raw liabilites so the myinfo filtered ones will be deleted
  const currentLiabilities = Object.values(getManualLiabilities(getState()));
  const newLiabilities = differenceWith(liabilities, currentLiabilities, isEqual);

  const allLiabilityIds = liabilities.map(({ id }) => id);
  const currentLiabilityIds = currentLiabilities.map(({ id }) => id);
  const liabilitiesToDelete = difference(currentLiabilityIds, allLiabilityIds);
  const liabilitiesToCreate = difference(allLiabilityIds, currentLiabilityIds);

  if (featureDecisions.mockServerResponses) {
    dispatch(
      setLiabilities(
        liabilities.reduce((obj, liability) => ({ ...obj, [liability.id]: liability }), {})
      )
    );
    return Promise.resolve();
  }

  const setPromises = newLiabilities.map(async ({ id, name, amount, ccy, category }) => {
    if (liabilitiesToCreate.includes(id)) {
      return axios.post(`${process.env.REACT_APP_API_URL}/v1/liability`, {
        name,
        amount,
        ccy,
        category,
      });
    }

    return axios.patch(`${process.env.REACT_APP_API_URL}/v1/liability/${id}`, {
      name,
      amount,
      ccy,
      category,
    });
  });

  const deletePromises = liabilitiesToDelete.map((id) =>
    axios.delete(`${process.env.REACT_APP_API_URL}/v1/liability/${id}`)
  );

  setPromises.forEach((promise) =>
    promise
      .then(({ data: { liability } }) => {
        const { id, name, amount, ccy, category } = liability;

        dispatch(
          setLiability({
            id,
            name,
            amount,
            ccy,
            category: LIABILITY_TYPE[category] || LIABILITY_TYPE[category.id], // POST and PATCh return this field differently
          })
        );
      })
      .catch(() => {})
  );

  deletePromises.forEach((promise) =>
    promise
      .then(({ data: { id } }) => {
        dispatch(deleteLiability(id));
      })
      .catch(() => {})
  );

  return Promise.all([...setPromises, ...deletePromises]);
};

export const createExpense = (expense: IExpense): ICreateExpense => ({
  type: FINANCES_ACTIONS.CREATE_EXPENSE,
  expense,
});

export const setExpenses = (expenses: IExpense[]): ISetExpenses => ({
  type: FINANCES_ACTIONS.SET_EXPENSES,
  expenses: expenses.reduce(
    (res, curr) => ({
      ...res,
      [curr.id]: curr,
    }),
    {}
  ),
});

export const createExpenseRequest = (expense: Omit<IExpense, 'id'>): RequestAction => {
  const { category, amount, timeInterval, budgetAmount, budgetTimeInterval } = expense;
  return createExpensesRequest([
    {
      category,
      amount,
      timeInterval,
      budgetAmount,
      budgetTimeInterval,
    },
  ]);
};

export const createExpensesRequest = (newExpenses: Omit<IExpense, 'id'>[]): RequestAction => async (
  dispatch
) => {
  const promise = axios.post(`${process.env.REACT_APP_API_URL}/v1/expense`, {
    expenses: newExpenses.map(
      ({ category, amount, timeInterval, budgetAmount, budgetTimeInterval }) => ({
        category,
        amount,
        timeInterval: TIME_INTERVAL[timeInterval],
        budgetAmount,
        budgetTimeInterval: TIME_INTERVAL[budgetTimeInterval],
      })
    ),
  });

  if (featureDecisions.mockServerResponses) {
    newExpenses.forEach((ne) => dispatch(createExpense({ ...ne, id: uuidv4() })));
  } else {
    promise
      .then(({ data: { expenses } }) => {
        dispatch(
          setExpenses(
            expenses.map((e: IExpenseResponse) => ({
              ...e,
              id: e.id,
              timeInterval: Number(TIME_INTERVAL[e.timeInterval.id]),
              budgetTimeInterval: Number(TIME_INTERVAL[e.budgetTimeInterval.id]),
            }))
          )
        );
      })
      .catch(() => {});
  }

  return promise;
};

export const updateExpense = (id: string, update: Partial<IExpense>): IUpdateExpense => ({
  type: FINANCES_ACTIONS.UPDATE_EXPENSE,
  id,
  update,
});

export const updateExpensesRequest = (updateExpenses: IExpense[]): RequestAction => async (
  dispatch
) => {
  const delta = updateExpenses.map((e) => ({
    ...e,
    timeInterval: e.timeInterval && TIME_INTERVAL[e.timeInterval],
    budgetTimeInterval: e.budgetTimeInterval && TIME_INTERVAL[e.budgetTimeInterval],
  }));

  const promise = axios.patch(`${process.env.REACT_APP_API_URL}/v1/expense`, {
    expenses: delta,
  });

  promise
    .then(() =>
      delta.forEach((e) =>
        dispatch(
          updateExpense(e.id, {
            ...e,
            timeInterval: TIME_INTERVAL[e.timeInterval],
            budgetTimeInterval: TIME_INTERVAL[e.budgetTimeInterval],
          })
        )
      )
    )
    .catch(() => {});

  return promise;
};

export const deleteExpense = (id: string): IDeleteExpense => ({
  type: FINANCES_ACTIONS.DELETE_EXPENSE,
  id,
});

export const deleteExpensesRequest = (ids: string[]): RequestAction => async (dispatch) => {
  const promise = axios.delete(
    `${process.env.REACT_APP_API_URL}/v1/expense?${qs.stringify({ ids })}`
  );
  promise.then(() => ids.forEach((id) => dispatch(deleteExpense(id)))).catch(() => {});
  return promise;
};

export const clearExpenses = (): IClearExpenses => ({
  type: FINANCES_ACTIONS.CLEAR_EXPENSES,
});

export const clearExpensesRequest = (): RequestAction => async (dispatch) => {
  const promise = axios.delete(`${process.env.REACT_APP_API_URL}/v1/expense`);

  promise
    .then(() => {
      dispatch(clearExpenses());
    })
    .catch(() => {});

  return promise;
};

export const setEstimatedMonthlyExpenses = (
  estimatedMonthlyExpenses: number
): ISetEstimatedMonthlyExpenses => ({
  type: FINANCES_ACTIONS.SET_ESTIMATED_MONTHLY_EXPENSES,
  estimatedMonthlyExpenses,
});

export const setEstimatedMonthlyExpensesRequest = (
  estimatedMonthlyExpenses: number,
  isDerived: boolean
): RequestAction => async (dispatch) => {
  const promise = axios.post(`${process.env.REACT_APP_API_URL}/v1/estimated-monthly-expenses`, {
    amount: estimatedMonthlyExpenses,
    isDerived,
    enteredDate: new Date().toISOString(),
  });

  promise
    .then(() => {
      if (isDerived) {
        dispatch(setEstimatedMonthlyExpenses(0));
      } else {
        dispatch(setEstimatedMonthlyExpenses(estimatedMonthlyExpenses));
      }
    })
    .catch(() => {});

  return promise;
};

export const setLoanRepayments = (
  loanRepaymentList: {
    id: string;
    name: string;
    amount: number;
    ccy: string;
    cash: number;
    cpf: number;
    type: {
      id: LIABILITY_TYPE;
      description: string;
    };
  }[]
): ISetLoanRepayments => ({
  type: FINANCES_ACTIONS.SET_LOAN_REPAYMENTS,
  loanRepaymentList,
});

export const setLoanRepaymentsRequest = (
  loanRepayments: ILoanRepayment[]
): ThunkAction<Promise<void | AxiosResponse<any>>, IStore, undefined, AnyAction> => async (
  dispatch
) => {
  if (featureDecisions.mockServerResponses) {
    dispatch(
      setLoanRepayments(
        loanRepayments.map((loanRepayment) => ({
          id: loanRepayment.id,
          name: loanRepayment.name,
          amount: loanRepayment.amount,
          ccy: loanRepayment.ccy,
          cash: loanRepayment.cash,
          cpf: loanRepayment.cpf,
          type: { id: loanRepayment.type, description: loanRepayment.type },
        }))
      )
    );

    return Promise.resolve();
  }
  const promise = axios.post(`${process.env.REACT_APP_API_URL}/v1/loan-repayment`, {
    loanRepayments: loanRepayments.map((loanRepayment) => ({
      id: loanRepayment.id.includes('local-') ? undefined : loanRepayment.id,
      name: loanRepayment.name,
      amount: loanRepayment.amount,
      ccy: loanRepayment.ccy,
      cash: loanRepayment.cash,
      cpf: loanRepayment.cpf,
      type: loanRepayment.type,
    })),
  });

  promise.then(({ data: { loanRepaymentList } }) => {
    dispatch(setLoanRepayments(loanRepaymentList));
  });

  return promise;
};

export const setInvestment = (investment: IInvestment): ISetInvestment => ({
  type: FINANCES_ACTIONS.SET_INVESTMENT,
  investment,
});

export const deleteInvestment = (id: string): IDeleteInvestment => ({
  type: FINANCES_ACTIONS.DELETE_INVESTMENT,
  id,
});

export const setInvestmentsRequest = (
  investments: IInvestment[]
): ThunkAction<void, IStore, undefined, AnyAction> => async (dispatch, getState) => {
  const currentInvestments = getInvestmentsArray(getState());
  const newInvestments = differenceWith(investments, currentInvestments, isEqual);

  const allInvestmentsIds = investments.map(({ id }) => id);
  const currentInvestmentsIds = currentInvestments.map(({ id }) => id);
  const investmentsToDelete = difference(currentInvestmentsIds, allInvestmentsIds);
  const investmentsToCreate = difference(allInvestmentsIds, currentInvestmentsIds);

  if (featureDecisions.mockServerResponses) {
    investments.forEach((investment) => dispatch(setInvestment(investment)));
    investmentsToDelete.forEach((id) => dispatch(deleteInvestment(id)));
    return Promise.resolve();
  }

  const setPromises = newInvestments.map(async ({ id, name, amount, ccy, assetType }) => {
    if (investmentsToCreate.includes(id)) {
      return axios.post(`${process.env.REACT_APP_API_URL}/v1/investment`, {
        name,
        amount,
        ccy,
        assetType,
      });
    }

    return axios.patch(`${process.env.REACT_APP_API_URL}/v1/investment/${id}`, {
      name,
      amount,
      ccy,
      assetType,
    });
  });

  const setResults = await Promise.all(setPromises);
  setResults.forEach(({ data: { data } }) => {
    const { id, name, amount, ccy, assetType } = data;
    dispatch(
      setInvestment({
        id,
        name,
        amount,
        ccy,
        assetType: ASSET_TYPE[assetType],
      })
    );
  });

  investmentsToDelete.map(async (id) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/v1/investment/${id}`).then(() => {
      dispatch(deleteInvestment(id));
    });
  });
};

export const setTargetMonthlySavings = (
  targetMonthlySavings: number
): ISetTargetMonthlySavings => ({
  type: FINANCES_ACTIONS.SET_TARGET_MONTHLY_SAVINGS,
  targetMonthlySavings,
});

export const setTargetMonthlySavingsRequest = (
  targetMonthlySavings: number
): RequestAction => async (dispatch) => {
  const promise = axios.post(`${process.env.REACT_APP_API_URL}/v1/target-monthly-savings`, {
    targetMonthlySavings,
    enteredDate: new Date().toISOString(),
  });

  promise
    .then(() => {
      dispatch(setTargetMonthlySavings(targetMonthlySavings));
      dispatch(captureEmergencyFundAchievability());
    })
    .catch(() => {});

  return promise;
};

export const setDayOfTransfer = (dayOfTransfer: number): ISetDayOfTransfer => ({
  type: FINANCES_ACTIONS.SET_DAY_OF_TRANSFER,
  dayOfTransfer,
});

export const setAutomaticTransferStatus = (
  automaticTransferStatus: AUTOMATIC_TRANSFER_STATUS
): ISetAutomaticTransferStatus => ({
  type: FINANCES_ACTIONS.SET_HAS_SETUP_AUTOMATIC_TRANSFER,
  automaticTransferStatus,
});

export const setCpfAsset = (cpfAsset: ICpfAsset): ISetCpfAsset => {
  return {
    type: FINANCES_ACTIONS.SET_CPF_ASSET,
    cpfAsset,
  };
};

export const setCpfAssetRequest = (cpfAsset: ICpfAsset): RequestAction => async (dispatch) => {
  if (featureDecisions.mockServerResponses) {
    dispatch(setCpfAsset({ ...cpfAsset }));
  }

  const promise = axios.patch(`${process.env.REACT_APP_API_URL}/v1/asset/cpf`, {
    ...pick(cpfAsset, ['oa', 'sa', 'ma', 'ra']),
  });

  promise
    .then(({ data: { cpfAsset } }) => {
      dispatch(setCpfAsset({ ...cpfAsset, type: ASSET_TYPE.CPF }));
    })
    .catch(() => {});

  return promise;
};

export const setCashBalanceAsset = (cashBalanceAsset: ICashBalanceAsset): ISetCashBalanceAsset => ({
  type: FINANCES_ACTIONS.SET_CASH_BALANCE_ASSET,
  cashBalanceAsset,
});

export const deleteCashBalanceAsset = (id: string): IDeleteCashBalanceAsset => {
  return {
    type: FINANCES_ACTIONS.DELETE_CASH_BALANCE_ASSET,
    id,
  };
};

export const setCashBalanceAssetsRequest = setInvestmentAssetsRequestFactory<
  ICashBalanceAsset,
  ISetCashBalanceAsset,
  IDeleteCashBalanceAsset
>(
  getCashBalanceAssets,
  setCashBalanceAsset,
  deleteCashBalanceAsset,
  'cash-balance',
  'cashBalanceAsset',
  ASSET_TYPE.CASH_BALANCE
);

export const setCashSavingsAsset = (cashSavingsAsset: ICashSavingsAsset): ISetCashSavingsAsset => ({
  type: FINANCES_ACTIONS.SET_CASH_SAVINGS_ASSET,
  cashSavingsAsset,
});

export const deleteCashSavingsAsset = (id: string): IDeleteCashSavingsAsset => {
  return {
    type: FINANCES_ACTIONS.DELETE_CASH_SAVINGS_ASSET,
    id,
  };
};

export const setCashSavingsAssetsRequest = setFundsRequestFactory<
  ICashSavingsAsset,
  ISetCashSavingsAsset,
  IDeleteCashSavingsAsset
>(
  getCashSavingsAssets,
  setCashSavingsAsset,
  deleteCashSavingsAsset,
  'cash-savings',
  'cashSavingsAsset',
  ASSET_TYPE.CASH_SAVINGS
);

export const setBondAsset = (bondAsset: IBondAsset): ISetBondAsset => ({
  type: FINANCES_ACTIONS.SET_BOND_ASSET,
  bondAsset,
});

export const deleteBondAsset = (id: string): IDeleteBondAsset => ({
  type: FINANCES_ACTIONS.DELETE_BOND_ASSET,
  id,
});

export const setBondAssetsRequest = setInvestmentAssetsRequestFactory<
  IBondAsset,
  ISetBondAsset,
  IDeleteBondAsset
>(getBondAssets, setBondAsset, deleteBondAsset, 'bond', 'bondAsset', ASSET_TYPE.BOND);

export const setGoldAsset = (goldAsset: IGoldAsset): ISetGoldAsset => ({
  type: FINANCES_ACTIONS.SET_GOLD_ASSET,
  goldAsset,
});

export const deleteGoldAsset = (id: string): IDeleteGoldAsset => ({
  type: FINANCES_ACTIONS.DELETE_GOLD_ASSET,
  id,
});

export const setGoldAssetsRequest = setInvestmentAssetsRequestFactory<
  IGoldAsset,
  ISetGoldAsset,
  IDeleteGoldAsset
>(getGoldAssets, setGoldAsset, deleteGoldAsset, 'gold', 'goldAsset', ASSET_TYPE.GOLD);

export const setFixedDepositAsset = (
  fixedDepositAsset: IFixedDepositAsset
): ISetFixedDepositAsset => ({
  type: FINANCES_ACTIONS.SET_FIXED_DEPOSIT_ASSET,
  fixedDepositAsset,
});

export const deleteFixedDepositAsset = (id: string): IDeleteFixedDepositAsset => ({
  type: FINANCES_ACTIONS.DELETE_FIXED_DEPOSIT_ASSET,
  id,
});

export const setFixedDepositAssetsRequest = setFundsRequestFactory<
  IFixedDepositAsset,
  ISetFixedDepositAsset,
  IDeleteFixedDepositAsset
>(
  getFixedDepositAssets,
  setFixedDepositAsset,
  deleteFixedDepositAsset,
  'fixed-deposit',
  'fixedDepositAsset',
  ASSET_TYPE.FIXED_DEPOSIT
);

export const setInsuranceAsset = (insuranceAsset: IInsuranceAsset): ISetInsuranceAsset => ({
  type: FINANCES_ACTIONS.SET_INSURANCE_ASSET,
  insuranceAsset,
});

export const deleteInsuranceAsset = (id: string): IDeleteInsuranceAsset => ({
  type: FINANCES_ACTIONS.DELETE_INSURANCE_ASSET,
  id,
});

export const setInsuranceAssetsRequest = setInvestmentAssetsRequestFactory<
  IInsuranceAsset,
  ISetInsuranceAsset,
  IDeleteInsuranceAsset
>(
  getInsuranceAssets,
  setInsuranceAsset,
  deleteInsuranceAsset,
  'insurance',
  'insuranceAsset',
  ASSET_TYPE.INSURANCE
);

export const setStockAsset = (stockAsset: IStockAsset): ISetStockAsset => ({
  type: FINANCES_ACTIONS.SET_STOCK_ASSET,
  stockAsset,
});

export const deleteStockAsset = (id: string): IDeleteStockAsset => ({
  type: FINANCES_ACTIONS.DELETE_STOCK_ASSET,
  id,
});

export const setStockAssetsRequest = setInvestmentAssetsRequestFactory<
  IStockAsset,
  ISetStockAsset,
  IDeleteStockAsset
>(getStockAssets, setStockAsset, deleteStockAsset, 'stock', 'stockAsset', ASSET_TYPE.STOCK);

export const setStructuredProductAsset = (
  structuredProductAsset: IStructuredProductAsset
): ISetStructuredProductAsset => ({
  type: FINANCES_ACTIONS.SET_STRUCTURED_PRODUCT_ASSET,
  structuredProductAsset,
});

export const deleteStructuredProductAsset = (id: string): IDeleteStructuredProductAsset => ({
  type: FINANCES_ACTIONS.DELETE_STRUCTURED_PRODUCT_ASSET,
  id,
});

export const setStructuredProductAssetsRequest = setInvestmentAssetsRequestFactory<
  IStructuredProductAsset,
  ISetStructuredProductAsset,
  IDeleteStructuredProductAsset
>(
  getStructuredProductAssets,
  setStructuredProductAsset,
  deleteStructuredProductAsset,
  'structured-product',
  'structuredProductAsset',
  ASSET_TYPE.STRUCTURED_PRODUCT
);

export const setUnitTrustAsset = (unitTrustAsset: IUnitTrustAsset): ISetUnitTrustAsset => ({
  type: FINANCES_ACTIONS.SET_UNIT_TRUST_ASSET,
  unitTrustAsset,
});

export const deleteUnitTrustAsset = (id: string): IDeleteUnitTrustAsset => ({
  type: FINANCES_ACTIONS.DELETE_UNIT_TRUST_ASSET,
  id,
});

export const setUnitTrustAssetsRequest = setInvestmentAssetsRequestFactory<
  IUnitTrustAsset,
  ISetUnitTrustAsset,
  IDeleteUnitTrustAsset
>(
  getUnitTrustAssets,
  setUnitTrustAsset,
  deleteUnitTrustAsset,
  'unit-trust',
  'unitTrustAsset',
  ASSET_TYPE.UNIT_TRUST
);

export const setOtherInvestmentAsset = (
  otherInvestmentAsset: IOtherInvestmentAsset
): ISetOtherInvestmentAsset => ({
  type: FINANCES_ACTIONS.SET_OTHER_INVESTMENT_ASSET,
  otherInvestmentAsset,
});

export const deleteOtherInvestmentAsset = (id: string): IDeleteOtherInvestmentAsset => ({
  type: FINANCES_ACTIONS.DELETE_OTHER_INVESTMENT_ASSET,
  id,
});

export const setOtherInvestmentAssetsRequest = setInvestmentAssetsRequestFactory<
  IOtherInvestmentAsset,
  ISetOtherInvestmentAsset,
  IDeleteOtherInvestmentAsset
>(
  getOtherInvestmentAssets,
  setOtherInvestmentAsset,
  deleteOtherInvestmentAsset,
  'other-investment',
  'otherInvestmentAsset',
  ASSET_TYPE.OTHER_INVESTMENT
);

export const setOtherNonInvestmentAsset = (
  otherNonInvestmentAsset: IOtherNonInvestmentAsset
): ISetOtherNonInvestmentAsset => ({
  type: FINANCES_ACTIONS.SET_OTHER_NON_INVESTMENT_ASSET,
  otherNonInvestmentAsset,
});

export const deleteOtherNonInvestmentAsset = (id: string): IDeleteOtherNonInvestmentAsset => ({
  type: FINANCES_ACTIONS.DELETE_OTHER_NON_INVESTMENT_ASSET,
  id,
});

export const setOtherNonInvestmentAssetsRequest = setInvestmentAssetsRequestFactory<
  IOtherNonInvestmentAsset,
  ISetOtherNonInvestmentAsset,
  IDeleteOtherNonInvestmentAsset
>(
  getOtherNonInvestmentAssets,
  setOtherNonInvestmentAsset,
  deleteOtherNonInvestmentAsset,
  'other-non-investment',
  'otherNonInvestmentAsset',
  ASSET_TYPE.OTHER_NON_INVESTMENT
);

export const setManualAssetsRequest = (manualAssets: IManualAsset[]): RequestAction => async (
  dispatch
) => {
  function arrayReducer(arr: IManualAsset[]): { [id: string]: IManualAsset } {
    return arr.reduce((obj, asset) => {
      obj[asset.id] = asset;
      return obj;
    }, {});
  }

  const assetsByType = groupBy(manualAssets, (asset) => asset.type);
  const promises = [
    dispatch(
      setCashBalanceAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.CASH_BALANCE] || []) as {
          [id: string]: ICashBalanceAsset;
        }
      )
    ),
    dispatch(
      setCashSavingsAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.CASH_SAVINGS] || []) as {
          [id: string]: ICashSavingsAsset;
        }
      )
    ),
    dispatch(
      setBondAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.BOND] || []) as { [id: string]: IBondAsset }
      )
    ),
    dispatch(
      setGoldAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.GOLD] || []) as { [id: string]: IGoldAsset }
      )
    ),
    dispatch(
      setFixedDepositAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.FIXED_DEPOSIT] || []) as {
          [id: string]: IFixedDepositAsset;
        }
      )
    ),
    dispatch(
      setInsuranceAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.INSURANCE] || []) as { [id: string]: IInsuranceAsset }
      )
    ),
    dispatch(
      setStockAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.STOCK] || []) as { [id: string]: IStockAsset }
      )
    ),
    dispatch(
      setStructuredProductAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.STRUCTURED_PRODUCT] || []) as {
          [id: string]: IStructuredProductAsset;
        }
      )
    ),
    dispatch(
      setUnitTrustAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.UNIT_TRUST] || []) as { [id: string]: IUnitTrustAsset }
      )
    ),
    dispatch(
      setOtherInvestmentAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.OTHER_INVESTMENT] || []) as {
          [id: string]: IOtherInvestmentAsset;
        }
      )
    ),
    dispatch(
      setOtherNonInvestmentAssetsRequest(
        arrayReducer(assetsByType[ASSET_TYPE.OTHER_NON_INVESTMENT] || []) as {
          [id: string]: IOtherNonInvestmentAsset;
        }
      )
    ),
  ];

  const cpfAsset = assetsByType[ASSET_TYPE.CPF];
  if (cpfAsset) {
    promises.push(dispatch(setCpfAssetRequest(cpfAsset[0] as ICpfAsset)));
  }

  return Promise.all(promises);
};

type IAsset =
  | ICashBalanceAsset
  | ICashSavingsAsset
  | IBondAsset
  | IGoldAsset
  | IFixedDepositAsset
  | IInsuranceAsset
  | IStockAsset
  | IStructuredProductAsset
  | IUnitTrustAsset
  | IOtherInvestmentAsset
  | IOtherNonInvestmentAsset;

type ISetAsset =
  | ISetCashBalanceAsset
  | ISetCashSavingsAsset
  | ISetBondAsset
  | ISetGoldAsset
  | ISetFixedDepositAsset
  | ISetInsuranceAsset
  | ISetStockAsset
  | ISetStructuredProductAsset
  | ISetUnitTrustAsset
  | ISetOtherInvestmentAsset
  | ISetOtherNonInvestmentAsset;

type IDeleteAsset =
  | IDeleteCashBalanceAsset
  | IDeleteCashSavingsAsset
  | IDeleteBondAsset
  | IDeleteGoldAsset
  | IDeleteFixedDepositAsset
  | IDeleteInsuranceAsset
  | IDeleteStockAsset
  | IDeleteStructuredProductAsset
  | IDeleteUnitTrustAsset
  | IDeleteOtherInvestmentAsset
  | IDeleteOtherNonInvestmentAsset;

export function setInvestmentAssetsRequestFactory<
  T extends IAsset,
  U extends ISetAsset,
  V extends IDeleteAsset
>(
  getInvestmentAssets: (
    store: IStore
  ) => {
    [id: string]: T;
  },
  setInvestmentAsset: (investmentAsset: T) => U,
  deleteInvestmentAsset: (id: string) => V,
  endpoint: string,
  investmentAssetKeyName: string,
  assetType: ASSET_TYPE
) {
  return function (investmentAssets: { [id: string]: T }): RequestAction {
    return async (dispatch, getState) => {
      const initialInvestmentAssets = getInvestmentAssets(getState());

      const createPromises = createInvestmentAssetsRequest(
        initialInvestmentAssets,
        investmentAssets,
        setInvestmentAsset,
        endpoint,
        investmentAssetKeyName,
        assetType,
        dispatch
      );
      const updatePromises = updateInvestmentAssetsRequest(
        initialInvestmentAssets,
        investmentAssets,
        setInvestmentAsset,
        endpoint,
        investmentAssetKeyName,
        assetType,
        dispatch
      );
      const deletePromises = deleteInvestmentAssetsRequest(
        initialInvestmentAssets,
        investmentAssets,
        deleteInvestmentAsset,
        endpoint,
        dispatch
      );

      if (featureDecisions.mockServerResponses) {
        return;
      }

      return Promise.all([...createPromises, ...updatePromises, ...deletePromises]);
    };
  };
}

export function createInvestmentAssetsRequest<T extends IAsset, U extends ISetAsset>(
  initialInvestmentAssets: {
    [id: string]: T;
  },
  investmentAssets: { [id: string]: T },
  setInvestmentAsset: (investmentAsset: T) => U,
  endpoint: string,
  investmentAssetKeyName: string,
  assetType: ASSET_TYPE,
  dispatch: ThunkDispatch<IStore, undefined, AnyAction>
) {
  const assetIDsToCreate = difference(
    Object.keys(investmentAssets),
    Object.keys(initialInvestmentAssets)
  );

  const assetsToCreate = pick(investmentAssets, assetIDsToCreate);

  if (featureDecisions.mockServerResponses) {
    Object.values(investmentAssets).forEach((asset) => dispatch(setInvestmentAsset(asset)));
    return [];
  }

  const createPromises = Object.values(assetsToCreate).map((ele) => {
    const promise = axios.post(
      `${process.env.REACT_APP_API_URL}/v1/asset/${endpoint}`,
      _.omit(ele, 'id', 'type')
    );
    promise
      .then(({ data }) => {
        const investmentAsset = { ...data[investmentAssetKeyName], type: assetType };
        const cleanedInvestmentAsset: T = pick<T, keyof T>(
          investmentAsset,
          Object.keys(ele) as Array<keyof T>
        );
        dispatch(setInvestmentAsset(cleanedInvestmentAsset));
      })
      .catch(() => {});
    return promise;
  });

  return createPromises;
}

export function updateInvestmentAssetsRequest<T extends IAsset, U extends ISetAsset>(
  initialInvestmentAssets: {
    [id: string]: T;
  },
  investmentAssets: { [id: string]: T },
  setInvestmentAsset: (investmentAsset: T) => U,
  endpoint: string,
  investmentAssetKeyName: string,
  assetType: ASSET_TYPE,
  dispatch: ThunkDispatch<IStore, undefined, AnyAction>
) {
  const assetIDsIntersection = intersection(
    Object.keys(initialInvestmentAssets),
    Object.keys(investmentAssets)
  );

  const intersectingAssetsNew = Object.values(pick(investmentAssets, assetIDsIntersection));
  const intersectingAssetsOld = Object.values(pick(initialInvestmentAssets, assetIDsIntersection));

  const assetsToUpdate = differenceWith(intersectingAssetsNew, intersectingAssetsOld, isEqual).map(
    (eleNew) => {
      const eleOld = initialInvestmentAssets[eleNew.id];
      return Object.entries(eleNew).reduce(
        (a, [k, v]) => {
          if (v !== eleOld[k]) {
            a[k] = v;
          }
          return a;
        },
        {
          id: eleNew.id,
        }
      );
    }
  );

  if (featureDecisions.mockServerResponses) {
    Object.values(investmentAssets).forEach((asset) => dispatch(setInvestmentAsset(asset)));
    return [];
  }

  const updatePromises = Object.values(assetsToUpdate).map((ele) => {
    const promise = axios.patch(
      `${process.env.REACT_APP_API_URL}/v1/asset/${endpoint}/${ele.id}`,
      _.omit(ele, 'id', 'type')
    );
    promise
      .then(({ data }) => {
        const investmentAsset = data[investmentAssetKeyName];
        const cleanedInvestmentAsset: T = { ...investmentAsset, type: assetType };
        dispatch(setInvestmentAsset(cleanedInvestmentAsset));
      })
      .catch(() => {});
    return promise;
  });

  return updatePromises;
}

export function deleteInvestmentAssetsRequest<T extends IAsset, V extends IDeleteAsset>(
  initialInvestmentAssets: {
    [id: string]: T;
  },
  investmentAssets: { [id: string]: T },
  deleteInvestmentAsset: (id: string) => V,
  endpoint: string,
  dispatch: ThunkDispatch<IStore, undefined, AnyAction>
) {
  const assetIDsToDelete = difference(
    Object.keys(initialInvestmentAssets),
    Object.keys(investmentAssets)
  );

  if (featureDecisions.mockServerResponses) {
    assetIDsToDelete.map((id) => dispatch(deleteInvestmentAsset(id)));
    return [];
  }

  const deletePromises = assetIDsToDelete.map((ele) => {
    const promise = axios.delete(`${process.env.REACT_APP_API_URL}/v1/asset/${endpoint}/${ele}`);
    promise
      .then(({ data: { id } }) => {
        dispatch(deleteInvestmentAsset(id));
      })
      .catch(() => {});
    return promise;
  });

  return deletePromises;
}

export function setFundsRequestFactory<
  T extends IAsset,
  U extends ISetAsset,
  V extends IDeleteAsset
>(
  getInvestmentAssets: (
    store: IStore
  ) => {
    [id: string]: T;
  },
  setInvestmentAsset: (investmentAsset: T) => U,
  deleteInvestmentAsset: (id: string) => V,
  endpoint: string,
  investmentAssetKeyName: string,
  assetType: ASSET_TYPE
) {
  return function (investmentAssets: { [id: string]: T }): RequestAction {
    return async (dispatch, getState) => {
      const initialInvestmentAssets = getInvestmentAssets(getState());

      const createPromises = createFundsRequest(
        initialInvestmentAssets,
        investmentAssets,
        setInvestmentAsset,
        endpoint,
        investmentAssetKeyName,
        assetType,
        dispatch
      );
      const updatePromises = updateFundsRequest(
        initialInvestmentAssets,
        investmentAssets,
        setInvestmentAsset,
        endpoint,
        investmentAssetKeyName,
        assetType,
        dispatch
      );
      const deletePromises = deleteInvestmentAssetsRequest(
        initialInvestmentAssets,
        investmentAssets,
        deleteInvestmentAsset,
        endpoint,
        dispatch
      );

      if (featureDecisions.mockServerResponses) {
        return;
      }

      return Promise.all([...createPromises, ...updatePromises, ...deletePromises]);
    };
  };
}

export function createFundsRequest<T extends IAsset, U extends ISetAsset>(
  initialInvestmentAssets: {
    [id: string]: T;
  },
  investmentAssets: { [id: string]: T },
  setInvestmentAsset: (investmentAsset: T) => U,
  endpoint: string,
  investmentAssetKeyName: string,
  assetType: ASSET_TYPE,
  dispatch: ThunkDispatch<IStore, undefined, AnyAction>
) {
  const assetIDsToCreate = difference(
    Object.keys(investmentAssets),
    Object.keys(initialInvestmentAssets)
  );

  const assetsToCreate = pick(investmentAssets, assetIDsToCreate);

  if (featureDecisions.mockServerResponses) {
    Object.values(investmentAssets).forEach((asset) => dispatch(setInvestmentAsset(asset)));
    return [];
  }

  const createPromises = Object.values(assetsToCreate).map((ele) => {
    const promise = axios.post(
      `${process.env.REACT_APP_API_URL}/v1/asset/${endpoint}`,
      _.omit(ele, 'id', 'type', 'lastUpdatedAt')
    );
    promise
      .then(({ data }) => {
        const investmentAsset = data[investmentAssetKeyName];
        const investmentAssetToCreate = {
          ...investmentAsset,
          type: assetType,
        };
        const cleanedInvestmentAsset: T = pick<T, keyof T>(
          investmentAssetToCreate,
          Object.keys(ele) as Array<keyof T>
        );
        dispatch(setInvestmentAsset(cleanedInvestmentAsset));
      })
      .catch(() => {});
    return promise;
  });

  return createPromises;
}

export function updateFundsRequest<T extends IAsset, U extends ISetAsset>(
  initialInvestmentAssets: {
    [id: string]: T;
  },
  investmentAssets: { [id: string]: T },
  setInvestmentAsset: (investmentAsset: T) => U,
  endpoint: string,
  investmentAssetKeyName: string,
  assetType: ASSET_TYPE,
  dispatch: ThunkDispatch<IStore, undefined, AnyAction>
) {
  const assetIDsIntersection = intersection(
    Object.keys(initialInvestmentAssets),
    Object.keys(investmentAssets)
  );

  const intersectingAssetsNew = Object.values(pick(investmentAssets, assetIDsIntersection));
  const intersectingAssetsOld = Object.values(pick(initialInvestmentAssets, assetIDsIntersection));

  const assetsToUpdate = differenceWith(intersectingAssetsNew, intersectingAssetsOld, isEqual).map(
    (eleNew) => {
      const eleOld = initialInvestmentAssets[eleNew.id];
      return Object.entries(eleNew).reduce(
        (a, [k, v]) => {
          if (v !== eleOld[k]) {
            a[k] = v;
          }
          return a;
        },
        {
          id: eleNew.id,
        }
      );
    }
  );

  if (featureDecisions.mockServerResponses) {
    Object.values(investmentAssets).forEach((asset) => dispatch(setInvestmentAsset(asset)));
    return [];
  }

  const updatePromises = Object.values(assetsToUpdate).map((ele) => {
    const promise = axios.patch(
      `${process.env.REACT_APP_API_URL}/v1/asset/${endpoint}/${ele.id}`,
      _.omit(ele, 'id', 'type', 'lastUpdatedAt')
    );
    promise
      .then(({ data }) => {
        const investmentAsset = data[investmentAssetKeyName];
        const cleanedInvestmentAsset: T = {
          ...investmentAsset,
          type: assetType,
        };
        dispatch(setInvestmentAsset(cleanedInvestmentAsset));
      })
      .catch(() => {});
    return promise;
  });

  return updatePromises;
}

export const setTotalBudget = (totalBudget: number): ISetTotalBudget => ({
  type: FINANCES_ACTIONS.SET_TOTAL_BUDGET,
  totalBudget,
});

export const upsertTotalBudgetRequest = (totalBudget: number): RequestAction => async (
  dispatch
) => {
  const promise = axios.put(`${process.env.REACT_APP_API_URL}/v1/total-budget`, {
    amount: totalBudget,
  });

  promise
    .then(() => {
      dispatch(setTotalBudget(totalBudget));
    })
    .catch(() => {});

  return promise;
};

import React, { useEffect, useContext } from 'react';
import { isBrowser } from 'react-device-detect';
import styled from 'styled-components';

import { CurrencyInputField, Input, NumberField, AmountField } from 'components/Common';
import { INVESTMENT_SOURCE } from 'constants/enums';
import { formatCurrency, convertToSGD } from 'utilities/currencyUtilities';

import { InvestmentSourceContext } from './Wrapper';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
`;

export interface IUnitPriceSectionLabelProps {
  totalQuantityFieldLabel: string;
  unitPriceLabel: React.ReactElement;
  unitOfMeasurement: string;
  allowedDecimalPlaces?: number;
}

export interface IUnitPriceSectionProps {
  totalQuantity: number | null;
  handleTotalQuantityChange: (value: number) => void;
  unitPrice: number | null;
  unitPriceCurrency: string;
  handleUnitPriceChange: (amount: number) => void;
  handleUnitPriceCurrencyChange: (currency: string) => void;
  autoFocus?: boolean;
}

type IProps = IUnitPriceSectionLabelProps & IUnitPriceSectionProps;

export const UnitPriceSection: React.FC<IProps> = ({
  totalQuantityFieldLabel,
  totalQuantity,
  handleTotalQuantityChange,
  unitPriceLabel,
  unitOfMeasurement,
  unitPrice,
  unitPriceCurrency,
  handleUnitPriceChange,
  handleUnitPriceCurrencyChange,
  allowedDecimalPlaces = 0,
  autoFocus,
}) => {
  const investmentSource = useContext(InvestmentSourceContext);

  useEffect(() => {
    if (investmentSource !== INVESTMENT_SOURCE.CASH && unitPriceCurrency !== 'SGD') {
      handleUnitPriceCurrencyChange('SGD');
    }
  }, [handleUnitPriceCurrencyChange, investmentSource, unitPriceCurrency]);

  const totalValue = Number(unitPrice) * Number(totalQuantity);
  const totalValueText = formatCurrency(totalValue, 2, unitPriceCurrency);
  const conversionText =
    unitPriceCurrency === 'SGD'
      ? ''
      : `${formatCurrency(convertToSGD(totalValue, unitPriceCurrency))}`;

  const isEmptyTotalQty = Number(totalQuantity) === 0;
  const isEmptyUnitPrice = Number(unitPrice) === 0;

  return (
    <Wrapper>
      <NumberField
        label={totalQuantityFieldLabel}
        widthStyle="elastic"
        minNumChars={8}
        allowedDecimalPlaces={allowedDecimalPlaces}
        addonAfter={<span>{unitOfMeasurement}</span>}
        numberValue={totalQuantity}
        type="numeric"
        onInputChange={handleTotalQuantityChange}
        collapseLabel={false}
        autoFocus={isBrowser && isEmptyTotalQty && isEmptyUnitPrice}
        maxAllowableAmount={99999999}
      />
      {investmentSource === INVESTMENT_SOURCE.CASH ? (
        <CurrencyInputField
          amount={unitPrice}
          selectedCurrency={unitPriceCurrency}
          onInputChange={handleUnitPriceChange}
          onChangeCurrency={handleUnitPriceCurrencyChange}
          label={unitPriceLabel}
          minNumChars={8}
          widthStyle="elastic"
          maxAllowableAmount={999999999}
          autoFocus={isBrowser && autoFocus && !isEmptyTotalQty}
        />
      ) : (
        <AmountField
          label={unitPriceLabel}
          amount={unitPrice}
          onInputChange={handleUnitPriceChange}
          minNumChars={8}
          widthStyle="elastic"
          addonBefore={<AmountField.DollarSign />}
          maxAllowableAmount={999999999}
          autoFocus={isBrowser && autoFocus && !isEmptyTotalQty}
        />
      )}
      <Input label="Total value" value={totalValueText} readOnly={true} subtext={conversionText} />
    </Wrapper>
  );
};

import { ascending } from 'utilities/sortingUtilities';

// This function returns the median of a list of numbers but in the case of an even number of items
// it returns the lower of the 2 middle numbers instead of averaging them
export function getLowerMedian(numbers: number[]) {
  const length = numbers.length;
  if (length === 0) {
    return 0;
  }
  numbers.sort((a, b) => ascending(a, b));
  if (length % 2 === 0) {
    return numbers[length / 2 - 1];
  } else {
    return numbers[Math.floor(length / 2)];
  }
}

export function positiveModulo(value: number, modulo: number) {
  return ((value % modulo) + Math.abs(modulo)) % modulo;
}

export function orderOfMagnitude(n: number) {
  return Math.floor(Math.log(n) / Math.LN10);
}

export function generateSecureRandomString(length: number) {
  const CHAR_SET = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
  const getCharFromSet = (n: number) => {
    if (length === 0) {
      return;
    }

    if (n < CHAR_SET.length) {
      result += CHAR_SET[n];
      length--;
    }
  };

  let result = '';

  while (length > 0) {
    const bytes = new Uint8Array(16);
    const random = window.crypto.getRandomValues(bytes);

    random.forEach(getCharFromSet);
  }

  return result;
}

export function* getHashValueGenerator(seed: string): Generator<number> {
  let hash = 5381;
  let index = 0;
  while (true) {
    hash = ((hash << 5) - hash + seed.charCodeAt(index)) | 0;
    yield hash;

    index = (index + 1) % seed.length;
  }
}

export function* getSeededRandomOrderGenerator<T>(seed: string): Generator<(items: T[]) => T[]> {
  const hashValueGenerator = getHashValueGenerator(seed);

  Array.from(seed).forEach(() => hashValueGenerator.next());

  while (true) {
    yield (items: T[]) => {
      let itemsCopy = [...items];

      return Array(itemsCopy.length)
        .fill(null)
        .map(() => {
          const hashValue = hashValueGenerator.next().value;
          const index = Math.abs(hashValue) % itemsCopy.length;
          const item = itemsCopy[index];
          itemsCopy = [...itemsCopy.slice(0, index), ...itemsCopy.slice(index + 1)];
          return item;
        });
    };
  }
}

import { omit, isEmpty, capitalize } from 'lodash';

import { IGetExcludePercentageShareOption } from 'redux/apim/selectors';
import { getProfileAge } from 'redux/profile/selectors';

import { LIABILITY_TYPE, RESIDENTIAL_STATUS } from 'constants/enums';
import {
  IStore,
  IMyinfoLiability,
  ILoanRepayment,
  MyinfoCpfAsset,
  ILiabilityBreakdown,
} from 'constants/storeTypes';

export const getHasMyinfoBasic = (store: IStore) =>
  store.myinfo.id !== null &&
  [
    getMyinfoName,
    getMyinfoSex,
    getMyinfoDob,
    getMyinfoMaritalStatus,
    getMyinfoResidentialStatus,
    getMyinfoEmail,
  ].some((getter) => !isEmpty(getter(store)));

export const getMyinfoName = (store: IStore) => store.myinfo.name?.name;

export const getMyinfoSex = (store: IStore) => store.myinfo.sex;

export const getMyinfoSexDesc = (store: IStore) => {
  const sex = getMyinfoSex(store);
  return sex?.desc ? capitalize(sex.desc) : undefined;
};

export const getMyinfoDob = (store: IStore) =>
  store.myinfo?.dob?.dob ? new Date(store.myinfo.dob.dob) : undefined;

export const getMyinfoMaritalStatus = (store: IStore) => store.myinfo.maritalStatus;

export const getMyinfoMaritalStatusDesc = (store: IStore) => {
  const maritalStatus = getMyinfoMaritalStatus(store);
  return maritalStatus?.desc ? capitalize(maritalStatus.desc) : undefined;
};

export const getMyinfoResidentialStatus = (store: IStore) => store.myinfo.residentialStatus;

export const getMyinfoEmail = (store: IStore) => store.myinfo.email?.email;

export const getMyinfoResidentialStatusDesc = (store: IStore) => {
  const residentialStatus = getMyinfoResidentialStatus(store);
  if (residentialStatus?.desc) {
    const descLower = residentialStatus.desc.toLowerCase();
    if (/(singapore|singaporean|citizen)/i.test(descLower)) {
      return RESIDENTIAL_STATUS.SC;
    } else if (/(pr|permanent resident)/i.test(descLower)) {
      return RESIDENTIAL_STATUS.PR;
    }
    return RESIDENTIAL_STATUS.FIN;
  }
  return undefined;
};

export const getMyinfoNoa = (store: IStore) => store.myinfo.noa;

export const getMyinfoNoaTotalValueInSGD = (store: IStore) => {
  const { noa } = store.myinfo;
  return noa?.amount || 0;
};

export const getMyinfoCpfAsset = (store: IStore): MyinfoCpfAsset | undefined => {
  const { cpf } = store.myinfo;
  const age = getProfileAge(store);

  if (cpf && age < 55) {
    return omit(cpf, 'ra');
  }

  return cpf;
};

export const getMyinfoCPFTotalValueInSGD = (store: IStore) => {
  const cpf = getMyinfoCpfAsset(store);
  return cpf ? Math.round(cpf.oa + cpf.ma + cpf.sa + (cpf?.ra || 0)) : 0;
};

export const getMyinfoHDBOwnership = (store: IStore) => store.myinfo.hdbOwnership || [];

export const getMyinfoHDBOwnershipAsLiabilities = (
  store: IStore
): { [id: string]: IMyinfoLiability } => {
  if (store.myinfo?.hdbOwnership) {
    // transform myinfo.hdbOwnership into liabilities format
    const { hdbOwnership } = store.myinfo;
    return hdbOwnership.reduce((acc, curr) => {
      const { id, percentageShare, outstandingLoanBalance, lastUpdatedAt } = curr;
      acc[id] = {
        id,
        name: 'HDB Mortgage',
        amount: outstandingLoanBalance,
        ccy: 'SGD',
        category: LIABILITY_TYPE.MORTGAGE,
        percentageShare,
        lastUpdatedAt,
      };
      return acc;
    }, {});
  }
  return {};
};

export const getMyinfoHDBOwnershipAsLoanRepayment = (
  store: IStore
): { [id: string]: ILoanRepayment } => {
  if (store.myinfo?.hdbOwnership) {
    const { hdbOwnership } = store.myinfo;
    // transform myinfo.hdbOwnership into loan repayment format
    return hdbOwnership.reduce((acc, curr) => {
      const {
        id,
        monthlyLoanInstalment,
        monthlyCashLoanInstalment,
        monthlyCPFLoanInstalment,
        lastUpdatedAt,
      } = curr;
      acc[id] = {
        id,
        name: 'HDB Mortgage instalment',
        amount: monthlyLoanInstalment,
        cash: monthlyCashLoanInstalment,
        cpf: monthlyCPFLoanInstalment,
        ccy: 'SGD',
        type: LIABILITY_TYPE.MORTGAGE,
        lastUpdatedAt,
      };
      return acc;
    }, {});
  }
  return {};
};

export const getTotalMyinfoHDBOwnershipOutstandingLoan = (
  store: IStore,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
): number => {
  const hdbOwnership = getMyinfoHDBOwnership(store);
  return Math.round(
    hdbOwnership.reduce(
      (acc, { outstandingLoanBalance, percentageShare }) =>
        acc +
        (excludePercentageShare
          ? outstandingLoanBalance
          : outstandingLoanBalance * percentageShare * 0.01),
      0
    )
  );
};

export const getMyinfoLiabilitiesBreakdown = (
  store: IStore,
  { excludePercentageShare = false }: IGetExcludePercentageShareOption = {}
): ILiabilityBreakdown[] => {
  const hdbOwnership = getMyinfoHDBOwnership(store);
  return hdbOwnership.map(({ outstandingLoanBalance, percentageShare }) => ({
    name: 'HDB Mortgage',
    amount: Math.round(
      excludePercentageShare
        ? outstandingLoanBalance
        : outstandingLoanBalance * percentageShare * 0.01
    ),
    type: LIABILITY_TYPE.MORTGAGE,
  }));
};

export const getMyinfoUsePersonFullData = (store: IStore): boolean =>
  store.myinfo.usePersonFullData;

import React from 'react';
import styled from 'styled-components';

import { LightBulb, ArrowRight } from 'assets/icons';
import { Button, Section, Heading, Panel } from 'components/Common';
import { colorScale, typography } from 'themes';

const ICON_SIZE_PX = '24px';

const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${colorScale('primary', 20)};
  background: ${colorScale('primary', 10)};
  color: ${colorScale('primary', 50)};
  padding: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Content = styled(Section)`
  margin: 0 0 0 16px;
  display: flex;
  flex-direction: column;
  ${typography('body-m')};
  width: calc(100% - ${ICON_SIZE_PX});
`;

const StyledIcon = styled.div`
  > :first-child {
    width: ${ICON_SIZE_PX};
    height: auto;
  }
`;

const Title = styled(Heading)`
  ${typography('body-l-bold')};
  color: ${colorScale('primary', 50)};
  margin: 0;
`;

const CTAButton = styled(Button)`
  align-self: flex-end;
`;

const openLinkInNewTab = (path: string) => {
  const newWindow = window.open(path, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

type IProps = {
  title: string;
  content: React.ReactElement;
  icon?: React.ReactElement;
} & (
  | {
      ctaText?: never;
      ctaPath?: never;
    }
  | {
      ctaText?: string;
      ctaPath: string;
    }
);

export const TipsCard = ({
  title,
  content,
  icon = <LightBulb />,
  ctaText = 'Read full article',
  ctaPath,
  ...otherProps
}: IProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <StyledPanel {...otherProps}>
      <ContentWrapper>
        <StyledIcon>{icon}</StyledIcon>
        <Content>
          <Title>{title}</Title>
          <div>{content}</div>
        </Content>
      </ContentWrapper>
      {ctaPath && (
        <CTAButton
          variant="tertiary"
          size="small"
          icon={<ArrowRight />}
          iconPosition="end"
          onClick={() => openLinkInNewTab(ctaPath)}
          edge="right"
          //To prevent the implicit type of submit when used in articles so user wont be routed to the next page unintentionally
          type="button"
        >
          <b>{ctaText}</b>
        </CTAButton>
      )}
    </StyledPanel>
  );
};

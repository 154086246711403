import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp } from 'styled-tools';

import { colorScale, typography } from 'themes';

const Wrapper = styled.div<{ variant: IVariant; columnsNo: number; layout: ILayout }>`
  display: flex;
  ${switchProp('layout', {
    'left-align': css`
      justify-content: flex-start;
    `,
    evenly: css`
      justify-content: space-evenly;
    `,
    'right-align': css`
      justify-content: flex-end;
    `,
  })}

  ${switchProp('variant', {
    scrollable: css`
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x proximity;
      display: flex;
      column-gap: 32px;
    `,
    'non-scrollable': css`
      display: grid;
      grid-template-columns: repeat(${prop('columnsNo')}, minmax(0, 1fr));
      grid-gap: 16px;
    `,
  })}
`;

const TabWrapper = styled.div<{ variant: IVariant }>`
  ${switchProp('variant', {
    scrollable: css`
      display: inline-block;
      scroll-snap-align: center;
      :first-child {
        padding-left: 0px;
      }

      span > div {
        padding: 8px 0px;
      }
    `,
    'non-scrollable': css`
      display: flex;
      flex-direction: column;
    `,
  })}

  position: relative;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 0;

  &:hover,
  &:focus,
  &:active {
    color: ${colorScale('primary', 50)};
  }
`;

const Tab = styled.span<{ selected: boolean }>`
  margin: 0 auto;
  text-align: center;
  padding: 8px 0;
  ${typography('body-l')};

  ${ifProp(
    'selected',
    css`
      color: ${colorScale('primary', 50)};
      ${typography('body-l-bold')};

      ::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        height: 4px;
        width: 100%;
        background: ${colorScale('primary', 30)};
      }
    `
  )};
`;

type IVariant = 'scrollable' | 'non-scrollable';
type ILayout = 'left-align' | 'evenly' | 'right-align';
export interface ITabHeaderGroup {
  variant: IVariant;
  selectedIndex: number;
  setSelectedIndex: (selectedIndex: number) => void;
  tabs: React.ReactNode[];
  layout?: ILayout;
}

const inHorizontalViewport = (el: Element) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const horizontalScroll = (
  leftElement: Element | null | undefined,
  rightElement: Element | null | undefined,
  selectedTabRef: React.RefObject<HTMLDivElement>,
  containerRef: React.RefObject<HTMLDivElement>
) => {
  const selectedTabRect = selectedTabRef.current?.getBoundingClientRect();
  const container = containerRef.current;

  if (selectedTabRect && container) {
    switch (true) {
      case !leftElement:
        container.scrollTo({ left: 0, behavior: 'smooth' });
        break;
      case !rightElement:
        container.scrollTo({
          left: window.innerWidth || document.documentElement.clientWidth,
          behavior: 'smooth',
        });
        break;
      case leftElement && !inHorizontalViewport(leftElement):
        container.scrollBy({ left: -selectedTabRect.width, behavior: 'smooth' });
        break;
      case rightElement && !inHorizontalViewport(rightElement):
        container.scrollBy({ left: selectedTabRect.width, behavior: 'smooth' });
        break;
    }
  }
};

export const TabHeaderGroup = ({
  variant,
  selectedIndex,
  setSelectedIndex,
  tabs,
  layout = 'left-align',
  ...otherProps
}: ITabHeaderGroup) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedTabRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (variant === 'scrollable') {
      const leftElement = containerRef.current?.children.item(selectedIndex - 1);
      const rightElement = containerRef.current?.children.item(selectedIndex + 1);
      horizontalScroll(leftElement, rightElement, selectedTabRef, containerRef);
    }
  }, [selectedIndex, variant]);

  return (
    <Wrapper
      variant={variant}
      columnsNo={tabs.length}
      layout={layout}
      {...{ ref: containerRef }}
      {...otherProps}
    >
      {tabs.map((tab, i) => (
        <TabWrapper
          key={i}
          onClick={() => setSelectedIndex(i)}
          variant={variant}
          {...(i === selectedIndex ? { ref: selectedTabRef } : {})}
        >
          <Tab selected={i === selectedIndex}>{tab}</Tab>
        </TabWrapper>
      ))}
    </Wrapper>
  );
};

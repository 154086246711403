import React from 'react';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

import { Section, Heading } from 'components/Common';
import { typography, minWidth } from 'themes';

import { DEFAULT_BETWEEN_COMPONENT_SPACING_PX } from './constants';

const Wrapper = styled(Section)<{ orientation?: IOrientation }>`
  display: grid;
  margin: ${DEFAULT_BETWEEN_COMPONENT_SPACING_PX} 0;

  ${switchProp('orientation', {
    vertical: css`
      grid-row-gap: 12px;
    `,
    horizontal: css`
      align-items: flex-start;
      grid-column-gap: 24px;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    `,
  })}
`;

const Image = styled.div<{ orientation?: IOrientation }>`
  > img {
    width: 100%;
    height: 100%;
  }

  width: 64px;
  height: 64px;

  ${switchProp('orientation', {
    vertical: css`
      ${minWidth(
        'desktop',
        css`
          width: 90px;
          height: 90px;
        `
      )}
    `,
  })}
`;

const Title = styled(Heading)`
  ${typography('heading-s')}
  margin-top: 0;
`;

// https://webdelving.com/blog/styled-components-ampersand/
const Description = styled.div`
  && > * {
    margin: 8px 0;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

type IOrientation = 'vertical' | 'horizontal';

interface IProps {
  orientation?: IOrientation;
  image: React.ReactElement;
  heading?: string;
  subheading?: React.ReactElement;
  description: React.ReactChild;
}

export const ArticleImageWithText = ({
  orientation = 'horizontal',
  image,
  heading,
  subheading,
  description,
  ...otherProps
}: IProps) => {
  return (
    <Wrapper orientation={orientation} {...otherProps}>
      <Image>{image}</Image>
      <div>
        <Title>{heading}</Title>
        {subheading}
        <Description>{description}</Description>
      </div>
    </Wrapper>
  );
};

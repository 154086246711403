import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp } from 'styled-tools';

import { Caution, Cross, Tick } from 'assets/icons';
import { colorScale, typeScale, maxWidth, minWidth, shadowScale } from 'themes';

import { IconButton } from '../IconButton/IconButton';
import { Panel } from '../Panel/Panel';

const supportingGreenColor = 'supporting-green';
const supportingRedColor = 'supporting-red';

const translateToOrigin = 'translate3d(0, 0, 0)';
const transitionTimingCubic = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
const transitionTimingCubic2 = 'cubic-bezier(0.55, 0.085, 0.68, 0.53)';

const CloseButton = styled(IconButton)`
  margin-left: 20px;
  align-self: inherit;
  color: inherit;
`;

const Progress = styled.div<{ isComplete: boolean }>`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 4px;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: ${ifProp('isComplete', 'inherit', 0)};
  transition-timing-function: linear;
  transition-property: width;
`;

const defaultProperties = css`
  background-color: ${colorScale('secondary', 10)};
  border-color: ${colorScale('secondary', 30)};
  color: ${colorScale('secondary', 30)};

  ${CloseButton} {
    &:hover,
    &:focus {
      color: ${colorScale('secondary', 40)};
      background: ${colorScale('secondary', 10)};
    }
  }

  ${Progress} {
    background-color: ${colorScale('secondary', 30)};
  }
`;

const successProperties = css`
  background-color: ${colorScale(supportingGreenColor, 10)};
  border-color: ${colorScale(supportingGreenColor, 30)};
  color: ${colorScale(supportingGreenColor, 40)};

  ${CloseButton} {
    &:hover,
    &:focus {
      color: ${colorScale(supportingGreenColor, 50)};
      background: ${colorScale(supportingGreenColor, 20)};
    }
  }

  ${Progress} {
    background-color: ${colorScale(supportingGreenColor, 30)};
  }
`;

const errorProperties = css`
  background-color: ${colorScale(supportingRedColor, 20)};
  border-color: ${colorScale(supportingRedColor, 30)};
  color: ${colorScale(supportingRedColor, 40)};

  ${CloseButton} {
    &:hover,
    &:focus {
      color: ${colorScale(supportingRedColor, 50)};
      background: ${colorScale(supportingRedColor, 20)};
    }
  }

  ${Progress} {
    background-color: ${colorScale(supportingRedColor, 30)};
  }
`;

type IToastWrapperVariant = 'default' | 'success' | 'error';
interface IToastWrapper {
  transitionState: TransitionState;
  variant: IToastWrapperVariant;
}
const ToastWrapper = styled(Panel)<IToastWrapper>`
  margin: 8px 16px 8px;
  position: relative;
  font-size: ${typeScale(-1)};
  padding: 16px;
  display: flex;
  align-items: center;
  transition-property: transform, opacity;
  box-shadow: ${shadowScale(1)};

  ${maxWidth(
    'mobile',
    css`
      & + & {
        margin-bottom: 0;
      }

      ${switchProp('transitionState', {
        entering: {
          transform: translateToOrigin,
          transitionTimingFunction: transitionTimingCubic,
          opacity: 1,
        },
        entered: {
          transform: translateToOrigin,
          transitionTimingFunction: transitionTimingCubic,
          opacity: 1,
        },
        exiting: {
          transform: 'translate3d(0, -75%, 0)',
          transitionTimingFunction: transitionTimingCubic2,
          opacity: 0,
        },
        exited: {
          transform: 'translate3d(0, -75%, 0)',
          transitionTimingFunction: transitionTimingCubic2,
          opacity: 0,
        },
      })}
    `
  )}

${minWidth(
  'tablet',
  css`
    & + & {
      margin-top: 0;
    }

    ${switchProp('transitionState', {
      entering: {
        transform: translateToOrigin,
        transitionTimingFunction: transitionTimingCubic,
      },
      entered: {
        transform: translateToOrigin,
        transitionTimingFunction: transitionTimingCubic,
      },
      exiting: {
        transform: 'translate3d(120%, 0, 0)',
        transitionTimingFunction: transitionTimingCubic2,
      },
      exited: {
        transform: 'translate3d(120%, 0, 0)',
        transitionTimingFunction: transitionTimingCubic2,
      },
    })}
  `
)}

  ${switchProp('variant', {
    default: defaultProperties,
    success: successProperties,
    error: errorProperties,
  })}
`;

const StyledTick = styled(Tick)`
  margin-right: 16px;
`;

const StyledCaution = styled(Caution)`
  margin-right: 16px;
  font-size: ${typeScale(5)};
`;

const Content = styled.span`
  flex-grow: 1;
`;

type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited';
type Callback = (id: string) => void;

export interface IAddToastOptions {
  /**
   * Whether the toast is dismissed automatically after a timeout.
   * When false, always shows the close button regardless.
   * Overrides what was defined in ToastProvider.
   */
  autoDismiss?: boolean;

  /**
   * Whether the toast pauses when hovered.
   * When false, always shows the progress bar regardless.
   * Overrides what was defined in ToastProvider.
   */
  pauseOnHover?: boolean;

  /**
   * Timeout value for automatic dismissal of the toast.
   * Overrides what was defined in ToastProvider.
   */
  autoDismissTimeout?: number;

  /**
   * Sets the duration of the transition in and out of the page
   * Overrides what was defined in ToastProvider.
   */
  transitionDuration?: number;

  /**
   * Decides whether or not to show the progress bar for the toast
   * Overrides what was defined in ToastProvider.
   */
  showProgressBar?: boolean;

  /**
   * Decides whether or not to show the close button for the toast
   * Overrides what was defined in ToastProvider.
   */
  showCloseButton?: boolean;

  /**
   * Determines the style of the toast and what icon to show
   * Overrides what was defined in ToastProvider.
   */
  variant?: 'default' | 'success' | 'error';
}

export interface IToastRenderProps {
  add: (content: React.ReactNode, options?: IAddToastOptions) => Callback;
  remove: (id: string) => Callback;
  toasts: (IAddToastOptions & { content: React.ReactNode; id: string })[];
}

export interface IToastHOCProps {
  toastManager: IToastRenderProps;
}

/**
 * `Toast`s are given props from their associated `ToastController`s. Since `Toast`s are not
 * used directly, these props cannot be changed directly. The props that can be changed by
 * using the `add` API are defined in `IAddToastOptions`.
 */
interface IToastProps {
  autoDismiss: boolean;
  autoDismissTimeout: number;
  pauseOnHover: boolean;
  transitionDuration: number;
  children: React.ReactNode;
  isRunning: boolean;
  onDismiss: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  transitionState: TransitionState;
  showProgressBar: boolean;
  showCloseButton: boolean;
  variant: 'default' | 'success' | 'error';
}

interface IState {
  /** Used to keep track of when the countdown to dismissal previously resumed */
  currentTime: number;

  /** Used to find out when props can be changed so that transitions will appear */
  isReadyToAnimate: boolean;

  /** Time left until the toast is dismissed */
  remainingTime: number;
}

export class Toast extends React.Component<IToastProps, IState> {
  constructor(props: IToastProps) {
    super(props);
    this.state = {
      currentTime: new Date().getTime(),
      isReadyToAnimate: false,
      remainingTime: props.autoDismissTimeout,
    };
  }

  public componentDidMount() {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.setState({ isReadyToAnimate: true });
      });
    });
  }

  public componentDidUpdate(prevProps: IToastProps, prevState: IState) {
    if (this.props.isRunning !== prevProps.isRunning) {
      const currentTime = new Date().getTime();
      if (this.props.isRunning) {
        this.setState({
          currentTime,
        });
      } else {
        this.setState({
          currentTime,
          remainingTime: prevState.remainingTime - (currentTime - prevState.currentTime),
        });
      }
    }
  }

  public render() {
    const {
      autoDismiss,
      autoDismissTimeout,
      children,
      pauseOnHover,
      isRunning,
      showProgressBar,
      onDismiss,
      onMouseEnter,
      onMouseLeave,
      transitionDuration,
      transitionState,
      showCloseButton,
      variant,
    } = this.props;

    const { isReadyToAnimate: readyToAnimate, remainingTime } = this.state;

    let width = 0;
    if (readyToAnimate) {
      if (isRunning) {
        width = 100;
      } else {
        width = ((autoDismissTimeout - remainingTime) / autoDismissTimeout) * 100;
      }
    }

    return (
      <ToastWrapper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        transitionState={readyToAnimate ? transitionState : 'exited'}
        style={{
          transitionDuration: `${transitionDuration}ms`,
        }}
        variant={variant}
      >
        {variant === 'success' && <StyledTick />}
        {variant === 'error' && <StyledCaution />}
        <Content role="alert">{children}</Content>
        {(!autoDismiss || showCloseButton) && (
          <CloseButton size="small" ariaLabel="Close button" onClick={onDismiss}>
            <Cross />
          </CloseButton>
        )}
        {(pauseOnHover || showProgressBar) && (
          <Progress
            isComplete={transitionState === 'exiting'}
            style={{
              width: `${width}%`,
              transitionDuration: `${remainingTime}ms`,
            }}
          />
        )}
      </ToastWrapper>
    );
  }
}

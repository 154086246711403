// TODO: [FP-0000] FISP's value should be either cleaned up or matched to INSURERS enum
// TODO: [FP-0000] to be moved to a proper place to map fisp code to insurer name
export enum FISP_CODE {
  'AIA' = 'I566C',
  'SINGLIFE' = 'I518C',
  'HSBC LIFE' = 'I544C',
  'GE' = 'I516L',
  'MANULIFE' = 'I522L',
  'INCOME' = 'I808C',
  'PRUDENTIAL' = 'I527L',
}

export enum PRODUCT_CLASS {
  PARTICIPATING = 'Participating',
  NON_PARTICIPATING = 'Non-participating',
  INVESTMENT_LINKED_POLICY = 'Investment Linked Policy',
  UNIVERSAL_LIFE = 'Universal Life',
}

// DashboardProtectionInsurancePolicies content is dependent enum order. Do not change this order
export enum PRODUCT_TYPE {
  TERM = 'Term',
  WHOLE_LIFE = 'Whole Life',
  ENDOWMENT = 'Endowment',
  ANNUITY = 'Annuity',
  MEDICAL = 'Medical',
  LONG_TERM_CARE = 'Long Term Care',
  CRITICAL_ILLNESS = 'Critical Illness',
  DISABILITY = 'Disability',
  PERSONAL_ACCIDENT = 'Personal Accident',
  OTHERS = 'Others',
}

export enum PREMIUM_MODE_FREQUENCY {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  SEMI_ANNUALLY = 'Semi-annually',
  ANNUALLY = 'Annually',
  SINGLE_PREMIUM = 'Single Premium',
  OTHERS = 'Others',
}

export enum INSURANCE_PLAN_TYPE {
  BASIC = 'Basic',
  RIDER = 'Rider',
}

export enum INSURANCE_PAYMENT_METHOD {
  AUTOPAY_GIRO_LOWERCASE = 'Autopay (Giro)',
  AUTOPAY_GIRO_UPPERCASE = 'Autopay (GIRO)',
  CREDIT_CARD = 'Credit Card',
  OTHER_METHOD = 'Other Method',
  CPF_ORDINARY_ACCOUNT = 'CPF Ordinary Account',
  CPF_SPECIAL_ACCOUNT = 'CPF Special Account',
  CPF_MEDISAVE = 'CPF MediSave',
  SRS = 'SRS',
  CASH = 'Cash',
}

export enum PAYOUT_FREQUENCY {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  SEMI_ANNUALLY = 'Semi-annually',
  ANNUALLY = 'Annually',
  SINGLE_PAYMENT = 'Single Payment',
  OTHERS = 'Others',
}

export enum INSURANCE_MAPPING {
  LIFE = 'Life',
  ACCIDENT_AND_HEALTH = 'Accident & Health',
  OTHERS = 'Others',
}

export enum INSURANCE_AGGREGATED_SUM_INSURED {
  DEATH = 'Death',
  ACCIDENTAL_DEATH = 'Accidental death',
  TOTAL_PERMANENT_DISABILITY = 'Total permanent disability',
  CRITICAL_ILLNESS = 'Critical illness',
}

import React from 'react';

import LogoWhite from './logo-white.svg';
import Logo from './logo.svg';
import MedicalShield from './medical-shield.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const LogoWhiteImage = (props: ImageProps) => (
  <img {...props} src={LogoWhite} alt="MyMoneySense" />
);

export const LogoImage = (props: ImageProps) => <img {...props} src={Logo} alt="MyMoneySense" />;

export const MedicalShieldImage = (props: ImageProps) => (
  <img
    {...props}
    src={MedicalShield}
    alt="Blue medical case, and a shield with a cross on the bottom right"
  />
);

import { clamp } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { ArrowSwitch } from 'assets/icons';
import { Input, DateField, AmountField } from 'components/Common';
import { ValidationContainer, required } from 'components/Validations';
import { colorScale, typeScale, typography } from 'themes';
import { formatCurrency } from 'utilities/currencyUtilities';

const MIN_ALLOWABLE_NUMBER = 1;
const MAX_ALLOWABLE_NUMBER = 999;

interface ILabel {
  inputFocused: string | null;
  isErrored: boolean;
}

const StyledNameInput = styled(Input)`
  ${typography('body-m')}
  label {
    ${typography('body-s-semibold')}
    color: ${colorScale('grey', 80)};
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 24px;
`;

const LabelText = styled.label`
  ${typography('body-s-semibold')}
  color: ${colorScale('grey', 80)};
`;

const LabelSubText = styled.p`
  ${typography('body-s')}
  color: ${colorScale('grey', 80)};
  margin: 0 0 18px 0;
`;

const LabelSuggestionSubText = styled.p`
  ${typography('body-s')}
  color: ${colorScale('grey', 80)};
  margin: 4px 0 0 0;
`;

const InputFieldLabel = styled(LabelText)`
  margin: 8px 0;
  &:active,
  &:hover,
  &:focus {
    color: ${colorScale('primary', 50)};
  }
`;

const AmountFieldWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'amount-label . num-months-label'
    'amount-input arrow-icon num-months-input';
  grid-template-columns: 1fr 48px 1fr;
  align-items: start;
  margin-bottom: 32px;
`;

const NumMonthsInputWrapper = styled.div`
  display: flex;
  grid-area: num-months-input;
  align-self: start;
`;

const NumMonthsInput = styled(Input)`
  ${typography('body-l')}
  margin: 0 4px;

  & input {
    text-align: center;
  }
`;

const NumMonthsLabel = styled(InputFieldLabel)<ILabel>`
  grid-area: num-months-label;
  color: ${colorScale('grey', 80)};
  font-weight: normal;

  ${ifProp(
    'isErrored',
    css`
      color: ${colorScale('supporting-red', 50)};
    `
  )};

  ${ifProp(
    { inputFocused: 'num-months-input' },
    css`
      color: ${colorScale('primary', 50)};
      font-weight: 600;
    `
  )};
`;

const EmergencyFundAmountField = styled(AmountField)`
  grid-area: amount-input;
  align-self: start;
  ${typography('body-m')}
`;

const EmergencyFundAmountLabel = styled(InputFieldLabel)<ILabel>`
  grid-area: amount-label;
  color: ${colorScale('grey', 80)};
  font-weight: normal;

  ${ifProp(
    'isErrored',
    css`
      color: ${colorScale('supporting-red', 50)};
    `
  )};

  ${ifProp(
    { inputFocused: 'amount-input' },
    css`
      color: ${colorScale('primary', 50)};
      font-weight: 600;
    `
  )};
`;

const StyledArrowSwitch = styled(ArrowSwitch)`
  grid-area: arrow-icon;
  color: ${colorScale('grey', 50)};
  font-size: ${typeScale(3)};
  justify-self: center;
  margin: 8px 0;
`;

const StyledDateField = styled(DateField)`
  ${typography('body-m')}
  label {
    ${typography('body-s-semibold')}
  }
`;

export interface IProps {
  totalMonthlyCashOutflow: number;
  amount: number;
  numMonths: number;
  date: Date;
  updateAmount: (amount: number) => void;
  updateNumMonths: (value: string) => void;
  updateDate: (date: Date) => void;
  recommendedEmergencyFundMonths: number;
}

interface IState {
  inputFocused: 'num-months-input' | 'amount-input' | null;
}

export class SetEmergencyFundInputFields extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      inputFocused: null,
    };

    this.filterNumMonthsInput = this.filterNumMonthsInput.bind(this);
    this.decrementNumMonths = this.decrementNumMonths.bind(this);
    this.incrementNumMonths = this.incrementNumMonths.bind(this);
    this.handleAmountInputFocused = this.handleAmountInputFocused.bind(this);
    this.handleNumMonthsInputFocused = this.handleNumMonthsInputFocused.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  filterNumMonthsInput(value: string) {
    if (this.props.numMonths === 0) {
      value = value.replace(/[< ]/g, '');
      value = value.replace('1', '');
    }

    if (this.props.numMonths > MAX_ALLOWABLE_NUMBER) {
      value = value.replace(/[> ]/g, '');
    }

    const newValue = this.clampNumber(Number(value));

    return isNaN(newValue) ? null : `${newValue}`;
  }

  clampNumber(value: number) {
    return clamp(Math.floor(value), MIN_ALLOWABLE_NUMBER, MAX_ALLOWABLE_NUMBER + 1);
  }

  decrementNumMonths() {
    const { numMonths, updateNumMonths } = this.props;
    const newValue = this.clampNumber(numMonths - 1);
    updateNumMonths(`${newValue}`);
  }

  incrementNumMonths() {
    const { numMonths, updateNumMonths } = this.props;
    const newValue = this.clampNumber(numMonths + 1);
    updateNumMonths(`${newValue}`);
  }

  handleAmountInputFocused() {
    this.setState({ inputFocused: 'amount-input' });
  }

  handleNumMonthsInputFocused() {
    this.setState({ inputFocused: 'num-months-input' });
  }

  handleBlur() {
    this.setState({ inputFocused: null });
  }

  public render() {
    const {
      amount,
      numMonths,
      date,
      updateAmount,
      updateNumMonths,
      updateDate,
      totalMonthlyCashOutflow,
      recommendedEmergencyFundMonths,
    } = this.props;
    const { inputFocused } = this.state;
    const recommendedAmount = totalMonthlyCashOutflow * recommendedEmergencyFundMonths;
    const numMonthsToShow = `${
      numMonths === 0
        ? '< 1'
        : numMonths > MAX_ALLOWABLE_NUMBER
        ? `> ${MAX_ALLOWABLE_NUMBER}`
        : numMonths
    }`;

    return (
      <React.Fragment>
        <InputWrapper>
          <StyledNameInput
            label="Name"
            widthStyle="elastic"
            minNumChars={15}
            readOnly={true}
            value="Emergency fund"
          />
        </InputWrapper>
        <InputWrapper>
          <AmountFieldWrapper>
            <EmergencyFundAmountField
              data-testid="amount-field"
              overrideId="amount-input"
              label="Amount"
              hideLabel={true}
              widthStyle="fluid"
              amount={amount}
              required={true}
              minAmount={100}
              minAmountInclusive={false}
              onInputChange={updateAmount}
              onFocus={this.handleAmountInputFocused}
              onBlur={this.handleBlur}
              addonBefore={<AmountField.DollarSign />}
              renderBefore={({ errorMessage }) => (
                <EmergencyFundAmountLabel
                  inputFocused={inputFocused}
                  isErrored={errorMessage !== undefined}
                  htmlFor="amount-input"
                >
                  Actual amount
                </EmergencyFundAmountLabel>
              )}
            />
            <StyledArrowSwitch />
            <ValidationContainer
              value={numMonthsToShow}
              validations={[required]}
              render={(validationsParams) => {
                return (
                  <React.Fragment>
                    <NumMonthsLabel
                      inputFocused={inputFocused}
                      isErrored={validationsParams.errorMessage !== undefined}
                      htmlFor="num-months-input"
                    >
                      Months of expenses and loan repayments
                    </NumMonthsLabel>
                    <NumMonthsInputWrapper
                      onFocus={this.handleNumMonthsInputFocused}
                      onBlur={this.handleBlur}
                    >
                      <Input.MinusButton onClick={this.decrementNumMonths} />
                      <NumMonthsInput
                        data-testid="num-months-input-field"
                        overrideId="num-months-input"
                        label="Number of months"
                        hideLabel={true}
                        widthStyle="fluid"
                        errorMessage={validationsParams.errorMessage}
                        value={numMonthsToShow}
                        type="tel"
                        autoComplete="off"
                        handleChange={updateNumMonths}
                        inputFilter={this.filterNumMonthsInput}
                      />
                      <Input.PlusButton onClick={this.incrementNumMonths} />
                    </NumMonthsInputWrapper>
                  </React.Fragment>
                );
              }}
            />
          </AmountFieldWrapper>
          <LabelSubText>
            Based on your monthly expenses of {formatCurrency(totalMonthlyCashOutflow)}, we
            recommend you to save at least&nbsp;
            <strong>
              {formatCurrency(recommendedAmount)} ({recommendedEmergencyFundMonths} months’
              expenses)
            </strong>
            .
          </LabelSubText>
        </InputWrapper>
        <InputWrapper>
          <LabelText>Due date</LabelText>
          <StyledDateField
            label="Target due"
            hideLabel={true}
            date={date}
            onInputChange={updateDate}
            menuPlacement="top"
          />
          <LabelSuggestionSubText>
            Aim to achieve this as soon as possible for earlier peace of mind.
          </LabelSuggestionSubText>
        </InputWrapper>
      </React.Fragment>
    );
  }
}

import preval from 'preval.macro';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link, NavLink } from 'react-router-dom';
import styled, { withTheme, css } from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { getHasToken } from 'redux/dataStorage/selectors';
import { updateProfileRequest } from 'redux/profile/slice';

import { Open } from 'assets/icons';
import { Logo, Container, ConditionalWrapper } from 'components/Common';
import { ONBOARDING } from 'constants/urls';
import { ITheme, minWidth, maxWidth, colorScale, typeScale } from 'themes';

const FooterWrapper = styled.div`
  background: ${colorScale('grey', 90)};
  grid-area: footer;
  position: relative;
  z-index: ${fromTheme('layers.footer')};
`;

const StyledLogo = styled(Link)`
  text-decoration: none;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`;

const TopContent = styled(Container)`
  align-items: flex-start;

  > * {
    margin-bottom: 16px;
  }

  > *:first-child {
    margin: 40px 0 32px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const BottomContent = styled(Container)`
  /* max-width: 1024px; */
  align-items: flex-start;

  > * {
    margin-bottom: 16px;
  }

  ${minWidth(
    'desktop',
    css`
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      > * {
        margin: 0 64px 24px 0;
      }
      > *:last-child {
        margin-right: 0;
      }
    `
  )}
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 32px 0;
  ${minWidth(
    'desktop',
    css`
      padding: 32px 0 32px 0;
    `
  )}
`;

const CopyrightContent = styled(Container)`
  align-items: flex-start;
`;

const CopyrightText = styled.p`
  font-size: ${typeScale(-2)};
  color: ${colorScale('grey', 50)};
  margin: 0;
  align-self: flex-start;

  ${minWidth(
    'desktop',
    css`
      align-self: flex-end;
    `
  )}
`;

const StyledNavLink = styled(NavLink)`
  color: ${colorScale('grey', 50)};
  font-size: ${typeScale(-1)};
  text-decoration: none;

  &:focus,
  &:hover {
    color: white;
  }
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: ${typeScale(-1)};
  color: ${colorScale('grey', 50)};

  &:hover,
  &:focus {
    color: white;
  }
`;

const StyledOpen = styled(Open)`
  margin-left: 8px;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: space-between;

  > * {
    margin-left: 64px;
  }
`;

const Divider = styled(Container)`
  margin: 0 auto;
  border-top: 1px solid ${colorScale('grey', 50)};

  ${maxWidth(
    'tablet',
    css`
      width: calc(100% - 48px);
    `
  )}
`;

type IProps = ITheme;

const LAST_UPDATED_DATE = preval`
  const moment = require('moment');
  module.exports = moment().utcOffset(8).format('DD MMM YYYY');
`;

const CURRENT_YEAR = preval`
  const moment = require('moment');
  module.exports = moment().year();
`;

export const FooterComponent = (props: IProps) => {
  const { theme, ...otherProps } = props;
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getHasToken);

  return (
    <FooterWrapper {...otherProps}>
      <TopSection>
        <TopContent>
          <StyledLogo to={ONBOARDING.baseRoute}>
            <Logo variant="white" />
          </StyledLogo>
          <StyledNavLink exact={true} to="/about-us">
            About us
          </StyledNavLink>
          <StyledNavLink exact={true} to="/useful-links">
            Useful links
          </StyledNavLink>
          <MediaQuery minWidth={theme.breakpoints.desktopMin}>
            {(matches) => (
              <ConditionalWrapper
                condition={matches}
                wrapper={(children) => <StyledWrapper>{children}</StyledWrapper>}
              >
                <React.Fragment>
                  <StyledLink
                    href={process.env.REACT_APP_CONTACT_US_FORM_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact us <StyledOpen />
                  </StyledLink>
                  <div
                    onClick={() => {
                      if (isLoggedIn) {
                        dispatch(updateProfileRequest({ hasCompletedFeedbackForm: true }));
                      }
                    }}
                  >
                    <StyledLink
                      href={process.env.REACT_APP_FEEDBACK_FORM_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Give feedback
                      <StyledOpen />
                    </StyledLink>
                  </div>

                  <StyledNavLink exact={true} to="/faq">
                    FAQ
                  </StyledNavLink>
                  <StyledNavLink exact={true} to="/maintenance-notices">
                    Maintenance notices
                  </StyledNavLink>
                </React.Fragment>
              </ConditionalWrapper>
            )}
          </MediaQuery>
        </TopContent>
      </TopSection>
      <Divider />
      <BottomSection>
        <BottomContent>
          <StyledNavLink exact={true} to="/site-map">
            Site Map
          </StyledNavLink>
          <StyledLink
            href="https://www.tech.gov.sg/report_vulnerability"
            target="_blank"
            rel="noopener noreferrer"
          >
            Report vulnerability
            <StyledOpen />
          </StyledLink>
          <StyledNavLink exact={true} to="/terms-of-use">
            Terms of Use
          </StyledNavLink>
          <StyledNavLink exact={true} to="/privacy-policy">
            Privacy Policy
          </StyledNavLink>
          <StyledNavLink exact={true} to="/SGFinDex-Terms-and-Conditions">
            SGFinDex Terms and Conditions
          </StyledNavLink>
          <StyledNavLink exact={true} to="/supported-browsers">
            Supported Browsers
          </StyledNavLink>
        </BottomContent>
        <CopyrightContent>
          <MediaQuery maxWidth={theme.breakpoints.tabletMax}>
            <CopyrightText>© {CURRENT_YEAR} Government of Singapore</CopyrightText>
            <CopyrightText>Last updated {LAST_UPDATED_DATE}</CopyrightText>
          </MediaQuery>
          <MediaQuery minWidth={theme.breakpoints.desktopMin}>
            <CopyrightText>© {CURRENT_YEAR} Government of Singapore</CopyrightText>
            <CopyrightText>Last updated {LAST_UPDATED_DATE}</CopyrightText>
          </MediaQuery>
        </CopyrightContent>
      </BottomSection>
    </FooterWrapper>
  );
};

export const Footer = withTheme(FooterComponent);

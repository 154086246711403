import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { IPortfolioDiversificationInputs } from '../interfaces';

export const IV3B: IRule<IPortfolioDiversificationInputs> = {
  condition: ({
    emergencyFundMonths,
    savingsRatio,
    isWorking,
    numInvestmentClasses,
  }: IPortfolioDiversificationInputs) =>
    numInvestmentClasses === 0 &&
    !(savingsRatio !== null && savingsRatio >= 0.2 && emergencyFundMonths >= 6 && isWorking),
  result: {
    key: RULE.IV3B,
    indicatorStatus: indicatorStatuses.NEEDS_ATTENTION,
    recommendedActions: [],
    resources: [
      // Some links
    ],
  },
};

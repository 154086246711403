import { UAT_USAGE_OPTIONS } from 'constants/enums';
import { UAT_USAGE_KEY } from 'constants/uatUsageConstants';

export const featureDecisions = {
  mockServerResponses: (process.env.REACT_APP_MOCK_SERVER_RESPONSES || '').toLowerCase() === 'true',
  skipMyinfo: (process.env.REACT_APP_SKIP_MYINFO || '').toLowerCase() === 'true',
  skipApim: (process.env.REACT_APP_SKIP_APIM || '').toLowerCase() === 'true',
  disableBlocker: (process.env.REACT_APP_DISABLE_BLOCKER || '').toLowerCase() === 'true',
  useAnalyticsToggler: (process.env.REACT_APP_USE_ANALYTICS_TOGGLER || '').toLowerCase() === 'true',
  isSentryEnabled: (process.env.REACT_APP_ENABLE_SENTRY_ENV || '').toLowerCase() === 'true',
};

export const getDynamicFeatureDecisions = () => ({
  showSGFinDexVanillaTables:
    (process.env.REACT_APP_APIM_SHOW_VANILLA_TABLE || '').toLowerCase() === 'true' ||
    (process.env.REACT_APP_DEPLOYMENT_ENV !== 'production' &&
      localStorage.getItem(UAT_USAGE_KEY) === UAT_USAGE_OPTIONS.SGFINDEX_VANILLA_TABLES) ||
    false,
});

import React, { useRef, useState, useEffect, useContext } from 'react';
import uuid from 'uuid/v4';

import { ValidationContext } from '../ValidationContext';

export interface IButtonRenderParams {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface IOwnProps {
  render: (renderParams: IButtonRenderParams) => JSX.Element;
}

export type IValidationButtonProps = IOwnProps & React.HTMLAttributes<HTMLButtonElement>;

export const ValidationButton: React.FC<IValidationButtonProps> = ({ render }) => {
  const messenger = useContext(ValidationContext);
  const keyname = useRef<string>(uuid()).current;
  const [valid, setValid] = useState(false);

  useEffect(() => {
    messenger && messenger.registerButton(keyname, { setValid });

    return () => {
      messenger && messenger.unregisterButton(keyname);
    };
    // eslint-disable-next-line
  }, []);

  const renderParams = valid
    ? {}
    : {
        onClick: messenger && messenger.displayAllErrors,
      };

  return render(renderParams);
};

import axios from 'axios';
import { ThunkAction } from 'redux-thunk';

import {
  RequestAction,
  ISetCurrentStepIndex,
  ISetLastCompletedDate,
  IMainModulesActions,
  ISetLastUpdatedDate,
} from 'constants/actionTypes';
import { MAIN_MODULE, MODULES_ACTIONS } from 'constants/enums';
import { IStore } from 'constants/storeTypes';

export const setCurrentStepIndex = (
  module: MAIN_MODULE,
  currentStepIndex: number | null
): ISetCurrentStepIndex => ({
  type: MODULES_ACTIONS.SET_CURRENT_STEP_INDEX,
  module,
  currentStepIndex,
});

export const setLastUpdatedDate = (
  module: MAIN_MODULE,
  lastUpdatedDate: Date
): ISetLastUpdatedDate => ({
  type: MODULES_ACTIONS.SET_LAST_UPDATED_DATE,
  module,
  lastUpdatedDate: lastUpdatedDate.toISOString(),
});

type ThunkResult<R> = ThunkAction<R, IStore, undefined, IMainModulesActions>;

export const updateCurrentStepIndex = (
  module: MAIN_MODULE,
  currentStepIndex: number | null,
  date: Date
): ThunkResult<void> => (dispatch) => {
  dispatch(setCurrentStepIndex(module, currentStepIndex));
  dispatch(setLastUpdatedDate(module, date));
};

export const upsertCurrentStepIndexRequest = (
  module: MAIN_MODULE,
  currentStepIndex: number | null
): RequestAction => async (dispatch) => {
  const now = new Date();

  const promise = axios.put(`${process.env.REACT_APP_API_URL}/v1/module-status`, {
    module,
    currentStep: currentStepIndex,
    lastUpdated: now.toISOString(),
  });

  promise
    .then(() => {
      dispatch(updateCurrentStepIndex(module, currentStepIndex, now));
    })
    .catch(() => {});

  return promise;
};

export const setLastCompletedDate = (
  module: MAIN_MODULE,
  lastCompletedDate: Date
): ISetLastCompletedDate => ({
  type: MODULES_ACTIONS.SET_LAST_COMPLETED_DATE,
  module,
  lastCompletedDate: lastCompletedDate.toISOString(),
});

export const markModuleAsCompleted = (module: MAIN_MODULE, date: Date): ThunkResult<void> => {
  return (dispatch) => {
    // The last completed date needs to be set first because if the current step is set to null
    // without the module being set to completed then the module router will route the user out of the page
    dispatch(setLastCompletedDate(module, date));
    dispatch(setLastUpdatedDate(module, date));
    dispatch(setCurrentStepIndex(module, null));
  };
};

export const markModuleAsCompletedRequest = (module: MAIN_MODULE): RequestAction => async (
  dispatch
) => {
  const now = new Date();

  const promise = axios
    .put(`${process.env.REACT_APP_API_URL}/v1/module-status`, {
      module,
      currentStep: null,
      lastUpdated: now.toISOString(),
      lastCompleted: now.toISOString(),
    })
    .then(() => {
      dispatch(markModuleAsCompleted(module, now));
    });

  promise.catch(() => {});

  return promise;
};

import { indicatorStatuses } from '../../indicatorStatuses';
import { RULE, IRule } from '../../interfaces';
import { ISavingsRatioInputs } from '../interfaces';

export const SR0: IRule<ISavingsRatioInputs> = {
  condition: ({ savingsRatio }: ISavingsRatioInputs) => savingsRatio === null,
  result: {
    key: RULE.SR0,
    indicatorStatus: indicatorStatuses.UNAVAILABLE,
    recommendedActions: [],
    resources: [],
  },
};

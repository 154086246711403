import React from 'react';

import { INVESTMENT_SOURCE, ASSET_TYPE } from 'constants/enums';
import { IBondAsset } from 'constants/storeTypes';

import { InvestmentWithUnitPriceInputPanel } from '../InvestmentWithUnitPriceInputPanel/InvestmentWithUnitPriceInputPanel';

interface IOwnProps {
  asset: IBondAsset;
  updateManualInvestmentAsset: (id: string, update: Partial<IBondAsset>) => void;
  deleteManualInvestmentAsset: (id: string) => void;
  onClickInvestmentSource: (
    id: string,
    name: string,
    investmentSource: INVESTMENT_SOURCE,
    assetType: ASSET_TYPE
  ) => void;
  autoFocusOnStart?: boolean;
}

type IProps = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

export const BondInputPanel: React.FC<IProps> = ({
  asset,
  updateManualInvestmentAsset,
  deleteManualInvestmentAsset,
  onClickInvestmentSource,
  autoFocusOnStart,
}) => {
  const {
    id,
    name,
    investmentSource,
    totalValueAmount,
    quantity,
    unitPrice,
    currency,
    enterOption,
  } = asset;
  return (
    <InvestmentWithUnitPriceInputPanel
      investmentType={ASSET_TYPE.BOND}
      id={id}
      onDelete={() => deleteManualInvestmentAsset(id)}
      name={name}
      handleNameChange={(name) => updateManualInvestmentAsset(id, { name })}
      investmentSource={investmentSource}
      totalValue={totalValueAmount}
      handleTotalValueChange={(totalValueAmount) =>
        updateManualInvestmentAsset(id, { totalValueAmount })
      }
      onClickInvestmentSource={() =>
        onClickInvestmentSource(id, name, investmentSource, ASSET_TYPE.BOND)
      }
      totalQuantity={quantity}
      handleTotalQuantityChange={(quantity) => updateManualInvestmentAsset(id, { quantity })}
      unitPrice={unitPrice}
      handleUnitPriceChange={(unitPrice) => updateManualInvestmentAsset(id, { unitPrice })}
      totalValueCurrency={currency}
      unitPriceCurrency={currency}
      handleTotalValueCurrencyChange={(currency) => updateManualInvestmentAsset(id, { currency })}
      handleUnitPriceCurrencyChange={(currency) => updateManualInvestmentAsset(id, { currency })}
      enterOption={enterOption}
      handleEnterOptionChange={(enterOption) => updateManualInvestmentAsset(id, { enterOption })}
      autoFocusOnStart={autoFocusOnStart}
    />
  );
};

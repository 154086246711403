import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css, StyledComponent } from 'styled-components';

import { ArrowLeft } from 'assets/icons';
import { Container, Button } from 'components/Common';
import { MAIN_MODULE } from 'constants/enums';
import { IModuleDetails } from 'constants/storeTypes';
import { minWidth, PAGE_SIDE_SPACING, typography } from 'themes';

import { BlockedBanner } from './BlockedBanner';
import { ModuleSteps } from './ModuleSteps';

const StyledContainer = styled(Container)`
  display: grid;

  ${minWidth(
    'desktop',
    css`
      grid-template-columns: minmax(0, 7fr) minmax(0, 9fr);
      width: 100%;
    `
  )}
`;

const Wrapper = styled.div`
  background: linear-gradient(85.86deg, #6361d1 0%, #8861d1 100%);
  min-height: 350px;
  padding: 0 24px 40px 24px;
  margin: 0 -${PAGE_SIDE_SPACING.DEFAULT_PX};

  ${minWidth(
    'desktop',
    css`
      margin: 0;
      padding-left: calc((100vw - 768px) / 2);
    `
  )};

  ${minWidth(
    'largeScreen',
    css`
      padding-left: calc((100vw - 1024px) / 2);
    `
  )};
`;

const ButtonWrapper = styled.div`
  padding: 24px 0 8px 0;
  display: flex;
  align-items: center;
`;

const StyledSpan = styled.span`
  margin-left: 12px;
  ${typography('body-m-bold')}
`;

const StyledButton = styled(Button)`
  background: none;
  padding: 0;

  &:hover {
    box-shadow: none;
  }
`;

const TextWrapper = styled.div`
  color: white;
  max-width: 500px;

  ${minWidth(
    'desktop',
    css`
      max-width: 240px;
    `
  )};
`;

const TitleText = styled.p`
  margin: 0;
  ${typography('heading-l')}
`;

const TitleSubtext = styled.p`
  margin: 8px 0 16px 0;
  text-transform: uppercase;
  ${typography('label')}
`;

const Description = styled.p`
  margin: 0;
  ${typography('body-m')}
  white-space: pre-line;
`;

const SubTitle = styled.p`
  margin: 24px 0 8px 0;
  ${typography('heading-s')}
`;

const StepsHeading = styled.p`
  margin: 0;
  ${typography('heading-s')}
`;

const StepsWrapper = styled.div`
  margin: 0 -${PAGE_SIDE_SPACING.DEFAULT_PX};
  padding: 16px 24px 64px 24px;

  ${minWidth(
    'desktop',
    css`
      margin: 0;
      padding-top: 80px;
      padding-left: 120px;
      padding-right: calc((100vw - 768px) / 2);
    `
  )};

  ${minWidth(
    'largeScreen',
    css`
      padding-right: calc((100vw - 1024px) / 2);
    `
  )};
`;

interface IProps {
  moduleDetails: IModuleDetails;
  SidePanelContent: React.ComponentType<any>;
  currentStepIndex: number | null;
  wasModuleCompleted: boolean;
  homeRoute: string;
  homeRouteButtonText?: string;
  blockingModules?: MAIN_MODULE[];
}

interface IComponents {
  TextWrapper: StyledComponent<any, any>;
  TitleText: StyledComponent<any, any>;
  TitleSubtext: StyledComponent<any, any>;
  SubTitle: StyledComponent<any, any>;
  Description: StyledComponent<any, any>;
}

export const ModulePreviewPage: React.FC<IProps> & IComponents = ({
  moduleDetails,
  currentStepIndex,
  wasModuleCompleted,
  SidePanelContent,
  homeRoute,
  homeRouteButtonText = 'Home',
  blockingModules,
}) => {
  const history = useHistory();

  return (
    <StyledContainer>
      <Wrapper>
        <ButtonWrapper>
          <StyledButton onClick={() => history.push(homeRoute)}>
            <ArrowLeft />
            <StyledSpan>{homeRouteButtonText}</StyledSpan>
          </StyledButton>
        </ButtonWrapper>
        <SidePanelContent />
      </Wrapper>
      <StepsWrapper>
        {blockingModules && blockingModules.length !== 0 && (
          <BlockedBanner blockingModules={blockingModules} />
        )}
        <StepsHeading>Steps</StepsHeading>
        <ModuleSteps
          chosenModule={moduleDetails}
          wasModuleCompleted={wasModuleCompleted}
          currentStepIndex={currentStepIndex}
        />
      </StepsWrapper>
    </StyledContainer>
  );
};

ModulePreviewPage.TextWrapper = TextWrapper;
ModulePreviewPage.TitleText = TitleText;
ModulePreviewPage.TitleSubtext = TitleSubtext;
ModulePreviewPage.SubTitle = SubTitle;
ModulePreviewPage.Description = Description;

import React from 'react';

import { ENTER_OPTION } from 'constants/enums';

import { QuantityValueRadioGroup, QuantityValueRadio } from '../sharedStyles';

import {
  TotalValueSection,
  ITotalValueSectionProps,
  ITotalValueSectionLabelProps,
} from './TotalValueSection';
import {
  UnitPriceSection,
  IUnitPriceSectionProps,
  IUnitPriceSectionLabelProps,
} from './UnitPriceSection';

export interface IQuantityValueSectionProps {
  enterOption: ENTER_OPTION;
  handleEnterOptionChange: (enterOption: ENTER_OPTION) => void;
}

type IProps = IUnitPriceSectionLabelProps &
  IUnitPriceSectionProps &
  ITotalValueSectionLabelProps &
  ITotalValueSectionProps &
  IQuantityValueSectionProps;

export const QuantityValueSection: React.FC<IProps> = ({
  enterOption,
  handleEnterOptionChange,
  totalValueFieldLabel,
  totalValue,
  totalValueCurrency,
  handleTotalValueChange,
  handleTotalValueCurrencyChange,
  totalQuantityFieldLabel,
  unitPriceLabel,
  unitOfMeasurement,
  totalQuantity,
  handleTotalQuantityChange,
  unitPrice,
  unitPriceCurrency,
  handleUnitPriceChange,
  handleUnitPriceCurrencyChange,
  allowedDecimalPlaces,
  autoFocus,
}) => (
  <>
    <QuantityValueRadioGroup
      name="enter-options"
      value={enterOption}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleEnterOptionChange(e.target.value as ENTER_OPTION)
      }
      variant="styled"
    >
      <QuantityValueRadio label="Enter total value" value={ENTER_OPTION.TOTAL_VALUE} />
      <QuantityValueRadio label="Enter quantity and market price" value={ENTER_OPTION.UNIT_PRICE} />
    </QuantityValueRadioGroup>
    {enterOption === ENTER_OPTION.TOTAL_VALUE && (
      <TotalValueSection
        totalValueFieldLabel={totalValueFieldLabel}
        totalValue={totalValue}
        totalValueCurrency={totalValueCurrency}
        handleTotalValueChange={handleTotalValueChange}
        handleTotalValueCurrencyChange={handleTotalValueCurrencyChange}
        autoFocus={autoFocus}
      />
    )}
    {enterOption === ENTER_OPTION.UNIT_PRICE && (
      <UnitPriceSection
        totalQuantityFieldLabel={totalQuantityFieldLabel}
        totalQuantity={totalQuantity}
        handleTotalQuantityChange={handleTotalQuantityChange}
        unitPriceLabel={unitPriceLabel}
        unitOfMeasurement={unitOfMeasurement}
        unitPrice={unitPrice}
        unitPriceCurrency={unitPriceCurrency}
        handleUnitPriceChange={handleUnitPriceChange}
        handleUnitPriceCurrencyChange={handleUnitPriceCurrencyChange}
        allowedDecimalPlaces={allowedDecimalPlaces}
        autoFocus={autoFocus}
      />
    )}
  </>
);

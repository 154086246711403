import React from 'react';
import styled from 'styled-components';

import { INVESTMENT_SOURCE } from 'constants/enums';

export const WrapperComponent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
`;

interface IProps {
  investmentSource: INVESTMENT_SOURCE;
}

export const InvestmentSourceContext = React.createContext(INVESTMENT_SOURCE.CASH);

export const Wrapper: React.FC<IProps> = ({ investmentSource, children }) => {
  return (
    <InvestmentSourceContext.Provider value={investmentSource}>
      <WrapperComponent>{children}</WrapperComponent>
    </InvestmentSourceContext.Provider>
  );
};

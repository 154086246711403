import styled from 'styled-components';

import { Add } from 'assets/icons';
import { colorScale, typeScale } from 'themes';

export const PillAdd = styled(Add)`
  color: ${colorScale('primary', 50)};
  font-size: ${typeScale(-3)};
  margin-right: 8px;
`;

import { IMyinfoResponse } from 'constants/responseTypes';

interface IMockMyinfoData {
  data: IMyinfoResponse;
}

export const mockMyinfoData: IMockMyinfoData = {
  data: {
    id: 'b0502c6a-fa84-4bb8-b575-57f290e0812f',
    noa: {
      id: '624ce6a5-0ce1-46e1-abf4-74a1ab7d8cb3',
      lastUpdatedAt: '2020-09-09',
      amount: 4779608,
      trade: 239192,
      interest: 492932,
      employment: 3920102,
      rent: 127382,
      yearOfAssessment: '2019',
      taxClearance: false,
      category: 'ORIGINAL',
    },
    cpf: {
      id: 'cd12582a-46bd-4385-87bd-8b7b948b2e5d',
      lastUpdatedAt: '2020-08-26',
      oa: 4960238,
      ma: 1725300,
      sa: 1293975,
      ra: 3426564,
    },
    hdbOwnership: [
      {
        id: 'eae7934d-f763-48a8-8ff6-d8d574c18393',
        outstandingLoanBalance: 529381828,
        monthlyLoanInstalment: 128492,
        lastUpdatedAt: '2020-08-26',
        percentageShare: 50,
        monthlyCashLoanInstalment: 50,
        monthlyCPFLoanInstalment: 50,
      },
    ],
    name: {
      id: '4619e8c0-25d1-42df-a1ce-d12e90ad79d6',
      lastUpdatedAt: '2020-11-10',
      name: 'TANGAMALA DO GAVINDARAYA',
    },
    dob: {
      id: '7b2b1e95-0a1a-43b4-9be6-450c025ee064',
      lastUpdatedAt: '2020-11-10',
      dob: '1960-04-10',
    },
    sex: {
      id: '662ca166-3eb1-4e5a-9a72-b9fca8dcfc31',
      lastUpdatedAt: '2020-11-10',
      desc: 'FEMALE',
      code: 'F',
    },
    maritalStatus: {
      id: 'ab60a433-deb6-4820-889e-a113af2c1740',
      lastUpdatedAt: '2020-11-10',
      desc: 'WIDOWED',
      code: '3',
    },
    residentialStatus: {
      id: 'f75c62ec-c2a3-4923-80fa-ced8a51fb46d',
      lastUpdatedAt: '2020-11-10',
      desc: 'Citizen',
      code: 'C',
    },
    email: {
      id: 'ba449b45-591e-46b7-87df-bfb09e25d1da',
      lastUpdatedAt: '2020-11-10',
      email: 'tangamala.do.gavindaraya@gmail.com',
    },
    latestConsentAt: '2020-11-03T06:52:25.129Z',
  },
};

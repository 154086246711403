import React from 'react';

import Bullseye from './bullseye-icon.svg';
import Calendar from './calendar-icon.svg';
import ListWithATick from './list-with-a-tick-icon.svg';
import Medal from './medal-icon.svg';
import UpwardGrowthBarGraph from './upward-growth-bar-graph-icon.svg';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const MedalIcon = (props: ImageProps) => <img {...props} src={Medal} alt="Medal" />;

export const UpwardGrowthBarGraphIcon = (props: ImageProps) => (
  <img {...props} src={UpwardGrowthBarGraph} alt="Bar graph showing upward trend" />
);

export const BullseyeIcon = (props: ImageProps) => (
  <img {...props} src={Bullseye} alt="Arrow hitting the bullseye" />
);

export const ListWithATickIcon = (props: ImageProps) => (
  <img {...props} src={ListWithATick} alt="List with a tick" />
);

export const CalendarIcon = (props: ImageProps) => <img {...props} src={Calendar} alt="Calendar" />;

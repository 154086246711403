import axios from 'axios';
import { differenceWith, isEqual, omit } from 'lodash';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { updateProfile } from 'redux/profile/slice';

import {
  RequestAction,
  ISetMyinfoName,
  ISetMyinfoSex,
  ISetMyinfoDob,
  ISetMyinfoMaritalStatus,
  ISetMyinfoResidentialStatus,
  ISetMyinfoEmail,
  ISetMyinfoNoa,
  ISetMyinfoCPF,
  ISetMyinfoHDBOwnership,
  ISetMyinfoUsePersonFullData,
  IUpdateMyinfoHDBOwnership,
  ISetMyinfoId,
  IResetMyinfo,
} from 'constants/actionTypes';
import { MYINFO_ACTIONS, MYINFO_RESET_ACTION_SCOPE } from 'constants/enums';
import { IMyinfoResponse, IUserDataMyinfo } from 'constants/responseTypes';
import {
  IStore,
  IMyinfoNameStore,
  IMyinfoSexStore,
  IMyinfoDobStore,
  IMyinfoMaritalStatusStore,
  IMyinfoResidentialStatusStore,
  IMyinfoEmailStore,
  IMyinfoNoaStore,
  IMyinfoCPFStore,
  IMyinfoHDBOwnershipStore,
} from 'constants/storeTypes';
import { featureDecisions } from 'featureDecisions';

import { getHasMyinfoBasic, getMyinfoHDBOwnership } from './selectors';

const apiUrl = process.env.REACT_APP_API_URL as string;

export const getMyinfoTokenRequest = (code: string, state: string) =>
  axios.post(`${apiUrl}/v1/myinfo/token`, { code, state });

export const getMyinfoPersonBasicRequest = (): RequestAction => async (dispatch) => {
  const promise = axios.get(`${process.env.REACT_APP_API_URL}/v1/myinfo/person-basic`);
  promise
    .then(({ data }) => {
      // only dispatch myinfo data if no errors present
      if (!data.data.code) {
        dispatch(resetMyinfo(MYINFO_RESET_ACTION_SCOPE.BASIC));
        dispatch(setMyinfo(data.data));
      }
    })
    .catch(() => {});

  return promise;
};

export const getMyinfoPersonFullRequest = (): RequestAction => (dispatch) => {
  const promise = axios.get(`${apiUrl}/v1/myinfo/person`);
  promise
    .then(({ data }) => {
      dispatch(resetMyinfo(MYINFO_RESET_ACTION_SCOPE.FULL));

      dispatch(setMyinfoUsePersonFullData(true));
      dispatch(setMyinfo(data.data));

      if (data.data.latestConsentAt) {
        dispatch(updateProfile({ myinfoLatestPullConsentAt: new Date(data.data.latestConsentAt) }));
      }
    })
    .catch(() => {});
  return promise;
};

export const logMyinfoRejection = (error: string, description: string) =>
  axios.post(`${apiUrl}/v1/myinfo/user-data-rejection`, { error, description });

export const updateMyinfoHDBOwnershipRequest = (
  hdbOwnerships: Partial<IMyinfoHDBOwnershipStore>[]
): RequestAction => async (dispatch, getState) => {
  const currHDBOs = getMyinfoHDBOwnership(getState());
  const hdboWithChanges = differenceWith(
    hdbOwnerships,
    currHDBOs as IMyinfoHDBOwnershipStore[],
    (a, b) => isEqual(a, b)
  );

  if (featureDecisions.mockServerResponses) {
    hdboWithChanges.forEach(
      ({ id, percentageShare, monthlyCashLoanInstalment, monthlyCPFLoanInstalment }) => {
        if (id !== undefined) {
          dispatch(
            updateMyinfoHDBOwnership(id, {
              percentageShare,
              monthlyCashLoanInstalment,
              monthlyCPFLoanInstalment,
            })
          );
        }
      }
    );
    return Promise.resolve();
  }

  const promises = hdboWithChanges.map(
    ({ id, percentageShare, monthlyCashLoanInstalment, monthlyCPFLoanInstalment }) => {
      return axios.patch(`${apiUrl}/v1/myinfo/hdb/ownership/${id}`, {
        percentageShare,
        monthlyCashLoanInstalment,
        monthlyCPFLoanInstalment,
      });
    }
  );

  promises.forEach((promise) =>
    promise
      .then(({ data: { hdbOwnership } }) =>
        dispatch(updateMyinfoHDBOwnership(hdbOwnership.id, hdbOwnership))
      )
      .catch(() => {})
  );

  return Promise.all(promises);
};

const loadIdFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.id && dispatch(setMyinfoId(myinfo.id));

const loadNameFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.name && dispatch(setMyinfoName(myinfo.name));

const loadSexFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.sex && dispatch(setMyinfoSex(myinfo.sex));

const loadMaritalStatusFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.maritalStatus && dispatch(setMyinfoMaritalStatus(myinfo.maritalStatus));

const loadDobFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.dob && dispatch(setMyinfoDob(myinfo.dob));

const loadResidentialStatusFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.residentialStatus && dispatch(setMyinfoResidentialStatus(myinfo.residentialStatus));

const loadEmailFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.email && dispatch(setMyinfoEmail(myinfo.email));

const loadNoaFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.noa &&
  dispatch(
    setMyinfoNoa({
      ...myinfo.noa,
      amount: Number(myinfo.noa.amount),
      employment: Number(myinfo.noa.employment),
      trade: Number(myinfo.noa.trade),
      interest: Number(myinfo.noa.interest),
      rent: Number(myinfo.noa.rent),
    })
  );

const loadCPFFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) => {
  myinfo?.cpf &&
    dispatch(
      setMyinfoCPF({
        ...myinfo.cpf,
        oa: Number(myinfo.cpf.oa),
        sa: Number(myinfo.cpf.sa),
        ma: Number(myinfo.cpf.ma),
        ra: Number(myinfo.cpf.ra),
      })
    );
};

type Unpacked<T> = T extends (infer U)[] ? U : T;
type IMyinfoResponseHDBOwnership = Unpacked<NonNullable<IMyinfoResponse['hdbOwnership']>>;
type IUserDataMyinfoHDBOwnership = Unpacked<
  NonNullable<NonNullable<IUserDataMyinfo>['hdbOwnership']>
>;

const loadHDBOwnershipFromMyinfoData = (myinfo: ISetMyinfoInput, dispatch: Dispatch) =>
  myinfo?.hdbOwnership &&
  dispatch(
    setMyinfoHDBOwnership(
      (myinfo.hdbOwnership as Array<IMyinfoResponseHDBOwnership | IUserDataMyinfoHDBOwnership>)
        .filter((hdbo) => hdbo.outstandingLoanBalance > 0)
        .map((hdbo) => ({
          ...hdbo,
          outstandingLoanBalance: Number(hdbo.outstandingLoanBalance),
          monthlyLoanInstalment: Number(hdbo.monthlyLoanInstalment),
          monthlyCashLoanInstalment: hdbo.monthlyCashLoanInstalment
            ? Number(hdbo.monthlyCashLoanInstalment)
            : 0,
          monthlyCPFLoanInstalment: hdbo.monthlyCPFLoanInstalment
            ? Number(hdbo.monthlyCPFLoanInstalment)
            : 0,
          percentageShare: hdbo.percentageShare,
        }))
    )
  );

type ISetMyinfoInput = IMyinfoResponse | IUserDataMyinfo;
export const setMyinfo: ActionCreator<ThunkAction<void, IStore, undefined, AnyAction>> = (
  myinfo: ISetMyinfoInput
) => (dispatch) => {
  [
    loadNameFromMyinfoData,
    loadSexFromMyinfoData,
    loadMaritalStatusFromMyinfoData,
    loadDobFromMyinfoData,
    loadResidentialStatusFromMyinfoData,
    loadEmailFromMyinfoData,
    loadNoaFromMyinfoData,
    loadCPFFromMyinfoData,
    loadHDBOwnershipFromMyinfoData,
    loadIdFromMyinfoData,
  ].forEach((loadFunction: (myinfo: ISetMyinfoInput, dispatch: Dispatch) => void) =>
    loadFunction(myinfo, dispatch)
  );
};

export const deleteMyinfoBasicRequest = (): RequestAction => async (dispatch, getState) => {
  if (featureDecisions.mockServerResponses) {
    const myinfoStore = getState().myinfo;
    const hasMyinfoFull = myinfoStore.noa || myinfoStore.hdbOwnership || myinfoStore.cpf;

    dispatch(resetMyinfo(MYINFO_RESET_ACTION_SCOPE.BASIC));
    if (hasMyinfoFull) {
      dispatch(
        setMyinfo(
          omit(myinfoStore, ['name', 'sex', 'dob', 'maritalStatus', 'residentialStatus', 'email'])
        )
      );
    }
    return Promise.resolve();
  }

  const promise = axios.delete(`${apiUrl}/v1/myinfo/person-basic`);
  promise
    .then(({ data }) => {
      dispatch(resetMyinfo(MYINFO_RESET_ACTION_SCOPE.BASIC));

      if (data !== 'OK') {
        dispatch(setMyinfo(data.data));
      }
    })
    .catch(() => {});
  return promise;
};

export const deleteMyinfoRequest = (): RequestAction => async (dispatch, getState) => {
  if (featureDecisions.mockServerResponses) {
    const store = getState();
    const hasMyinfoBasic = getHasMyinfoBasic(store);

    dispatch(resetMyinfo(MYINFO_RESET_ACTION_SCOPE.FULL));
    if (hasMyinfoBasic) {
      dispatch(setMyinfo(omit(store.myinfo, ['cpf', 'noa', 'hdbOwnership'])));
    }
    return Promise.resolve();
  }

  const promise = axios.delete(`${apiUrl}/v1/myinfo/person`);

  promise
    .then(({ data }) => {
      dispatch(resetMyinfo(MYINFO_RESET_ACTION_SCOPE.FULL));

      if (data !== 'OK') {
        dispatch(setMyinfo(data.data));
      }
    })
    .catch(() => {});
  return promise;
};

export const resetMyinfo = (scope?: MYINFO_RESET_ACTION_SCOPE): IResetMyinfo => ({
  type: MYINFO_ACTIONS.RESET_MYINFO,
  scope,
});

export const setMyinfoId = (id: string): ISetMyinfoId => ({
  type: MYINFO_ACTIONS.SET_ID,
  id,
});

export const setMyinfoName = (name: IMyinfoNameStore): ISetMyinfoName => ({
  type: MYINFO_ACTIONS.SET_NAME,
  name,
});

export const setMyinfoSex = (sex: IMyinfoSexStore): ISetMyinfoSex => ({
  type: MYINFO_ACTIONS.SET_SEX,
  sex,
});

export const setMyinfoMaritalStatus = (
  maritalStatus: IMyinfoMaritalStatusStore
): ISetMyinfoMaritalStatus => ({
  type: MYINFO_ACTIONS.SET_MARITAL_STATUS,
  maritalStatus,
});

export const setMyinfoDob = (dob: IMyinfoDobStore): ISetMyinfoDob => ({
  type: MYINFO_ACTIONS.SET_DOB,
  dob,
});

export const setMyinfoResidentialStatus = (
  residentialStatus: IMyinfoResidentialStatusStore
): ISetMyinfoResidentialStatus => ({
  type: MYINFO_ACTIONS.SET_RESIDENTIAL_STATUS,
  residentialStatus,
});

export const setMyinfoEmail = (email: IMyinfoEmailStore): ISetMyinfoEmail => ({
  type: MYINFO_ACTIONS.SET_EMAIL,
  email,
});

export const setMyinfoNoa = (noa: IMyinfoNoaStore): ISetMyinfoNoa => ({
  type: MYINFO_ACTIONS.SET_NOA,
  noa,
});

export const setMyinfoCPF = (cpf: IMyinfoCPFStore): ISetMyinfoCPF => ({
  type: MYINFO_ACTIONS.SET_CPF,
  cpf,
});

export const setMyinfoHDBOwnership = (
  hdbOwnership: IMyinfoHDBOwnershipStore[]
): ISetMyinfoHDBOwnership => ({
  type: MYINFO_ACTIONS.SET_HDB_OWNERSHIP,
  hdbOwnership,
});

export const setMyinfoUsePersonFullData = (
  usePersonFullData: boolean
): ISetMyinfoUsePersonFullData => ({
  type: MYINFO_ACTIONS.SET_USE_PERSON_FULL_DATA,
  usePersonFullData,
});

export const updateMyinfoHDBOwnership = (
  id: string,
  update: Partial<IMyinfoHDBOwnershipStore>
): IUpdateMyinfoHDBOwnership => ({
  type: MYINFO_ACTIONS.UPDATE_HDB_OWNERSHIP,
  id,
  update,
});

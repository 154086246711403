import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { HdbFlatsCircleImage } from 'assets/Housing';
import { GeneralInsuranceImage, LifeInsuranceImage, MedicalInsuranceImage } from 'assets/Insurance';
import {
  BriefCaseWithMedicalShieldImage,
  DebtFreeShopImage,
  EarlyHousingLoanPaymentMobileImage,
  EarlyHousingLoanPaymentTabletDesktopImage,
  MoneyBagsImage,
} from 'assets/Retirement';
import {
  ArticleHeading,
  ArticleImageWithText,
  ArticleParagraph,
  ArticleHyperlink,
  renderResponsiveGraphImages,
} from 'components/Templates';
import { HOUSING, TARGETS } from 'constants/urls';
import { breakpoints } from 'themes';

const StyledArticleImageWithText = styled(ArticleImageWithText)`
  > div > div {
    margin: 8px 0;
  }
`;

export const FinancialGoals = () => {
  const isMobileWidth = useMediaQuery({ query: `(max-width: ${breakpoints.mobileMax})` });
  return (
    <>
      <ArticleHeading>
        In short, here are some financial health goals to aim for, as you save for retirement:
      </ArticleHeading>

      <ArticleImageWithText
        orientation={isMobileWidth ? 'vertical' : 'horizontal'}
        image={<MoneyBagsImage />}
        heading="1. Have 3 to 6 months of emergency funds"
        description={
          <ArticleParagraph>
            Having an emergency fund reduces the impact to your current lifestyle when something
            unexpected happens e.g. loss of job or personal accident. If you have not set aside a
            sum for emergencies,{' '}
            <NavLink to={TARGETS.baseRoute} target="_blank" rel="noopener noreferrer">
              set a target
            </NavLink>{' '}
            and start working towards it.
          </ArticleParagraph>
        }
      />

      <ArticleImageWithText
        orientation={isMobileWidth ? 'vertical' : 'horizontal'}
        image={<DebtFreeShopImage />}
        heading="2. Have no outstanding loans and liabilities"
        description={
          <ArticleParagraph>
            Being debt free allows you to free up your money for other goals and retire with a peace
            of mind. Work towards paying off all your loans. For a start, focus on paying off the
            loan with the highest interest rate as early as possible and work your way down to
            decrease your interest payments.
          </ArticleParagraph>
        }
      />

      <ArticleImageWithText
        orientation={isMobileWidth ? 'vertical' : 'horizontal'}
        image={<HdbFlatsCircleImage />}
        heading="3. Make prudent housing decisions "
        description={
          <>
            <ArticleParagraph>
              If you plan to buy a home,{' '}
              <NavLink to={HOUSING.ARTICLES.myFirstHouse} target="_blank" rel="noopener noreferrer">
                choose one you can afford
              </NavLink>{' '}
              while taking care to set aside adequate savings for your future retirement needs.
            </ArticleParagraph>
            <ArticleParagraph>
              If you decide to take a housing loan, conduct ample research on the housing loan
              packages available in the market to choose one that best suits your financial needs.
              Be mindful that interest rates may change over time and the instalment payable per
              month may increase if the interest rate increases.
            </ArticleParagraph>
            <ArticleParagraph>
              If you wish to tap on your CPF Ordinary Account (OA) savings for your housing loan
              repayment, consider the changes in the CPF contribution rates and the applicable
              limits for{' '}
              <ArticleHyperlink url="https://www.cpf.gov.sg/member/tools-and-services/calculators/cpf-housing-usage">
                CPF usage
              </ArticleHyperlink>{' '}
              as you age, as this will affect the available CPF OA savings you may use to keep up
              with the monthly mortgage instalments. You will need to pay the monthly mortgage
              instalments in cash when the allowed CPF amount that could be used is reached.
            </ArticleParagraph>

            {renderResponsiveGraphImages(
              <EarlyHousingLoanPaymentMobileImage />,
              <EarlyHousingLoanPaymentTabletDesktopImage />,
              <EarlyHousingLoanPaymentTabletDesktopImage />
            )}

            <ArticleParagraph>
              Consider paying off your housing loan as early as possible, in view that you will pay
              more interest with a longer loan repayment period. For HDB housing loans, there are no
              early repayment penalties if you redeem or make partial capital repayments to reduce
              the total interest payable. However, making early repayments for a bank loan may incur
              charges, so be sure to check if there are any penalties and the terms and conditions
              from the financers first.
            </ArticleParagraph>
          </>
        }
      />

      <ArticleImageWithText
        orientation={isMobileWidth ? 'vertical' : 'horizontal'}
        image={<BriefCaseWithMedicalShieldImage />}
        heading="4. Have adequate insurance coverage to protect you and your family from financial loss should something happen"
        subheading={
          <ArticleParagraph>
            <b>The main types of insurance are:</b>
          </ArticleParagraph>
        }
        description={
          <>
            <StyledArticleImageWithText
              image={<MedicalInsuranceImage />}
              heading="Medical insurance"
              description={
                <ArticleParagraph>
                  <b>
                    Insurance which reimburses medical costs incurred because of an accident or
                    illness.
                  </b>{' '}
                  In terms of coverage, consider the ward class (e.g. Class A or B1), type of
                  hospital (e.g. private or restructured) and the amount of protection you need.
                </ArticleParagraph>
              }
            />

            <StyledArticleImageWithText
              image={<LifeInsuranceImage />}
              heading="Life insurance"
              description={
                <ArticleParagraph>
                  <b>
                    Insurance which pays out a sum in the event of Total Permanent Disability (TPD),
                    or death.
                  </b>{' '}
                  One rule of thumb for reference is to aim for{' '}
                  <ArticleHyperlink url="https://www.lia.org.sg/tools-and-resources/faq/mortality-protection/">
                    a life coverage of 9 to 10 times your annual income
                  </ArticleHyperlink>
                  .
                </ArticleParagraph>
              }
            />

            <StyledArticleImageWithText
              image={<GeneralInsuranceImage />}
              heading="General insurance"
              description={
                <ArticleParagraph>
                  <b>Insurance such as fire and home insurance to safeguard your home.</b> If you
                  are using your CPF savings to pay the monthly mortgage instalments for your HDB
                  flat, the{' '}
                  <ArticleHyperlink url="https://www.cpf.gov.sg/member/home-ownership/protecting-against-losing-your-home">
                    Home Protection Scheme
                  </ArticleHyperlink>{' '}
                  is a mortgage-reducing insurance that protects CPF members and their families
                  against losing their home, in the event of death, terminal illness or TPD before
                  the housing loan is paid up.
                </ArticleParagraph>
              }
            />
          </>
        }
      />
    </>
  );
};

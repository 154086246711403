import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { pickBy } from 'lodash';
import moment from 'moment';

import {
  SEX,
  MARITAL_STATUS,
  RESIDENTIAL_STATUS,
  APIM_MYINFO_DATA_SELECTION_STATE,
  AGE_RANGE,
  AUTOMATIC_TRANSFER_STATUS,
  EMPLOYMENT_STATUS_RANGE,
  WORK_YEARS_RANGE,
  EMAIL_VERIFICATION_STATUS,
} from 'constants/enums';
import { IProfileStore, IUpdateProfilePayloadAction } from 'constants/storeTypes';

export const initialState: IProfileStore = {
  userId: '',
  name: '',
  dob: null,
  sex: null,
  maritalStatus: null,
  residentialStatus: null,
  email: '',
  deferEmailVerification: false,
  latestConsentedToAppNOCAt: null,
  emailVerificationStatus: null,
  emailNotificationsStatus: null,
  hasCompletedFeedbackForm: false,
  myinfoLatestPullConsentAt: null,
  apimLatestPullConsentAt: null,
  apimMyinfoDataSelectionState: null,
  lastLogin: null,
  accountStatus: null,
  isReturnUser: false,
};

const name = 'profile';
const profileSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateProfile: (state, { payload }: PayloadAction<Partial<IUpdateProfilePayloadAction>>) => {
      const profileData = prepareUpdateProfileData(payload);
      // https://redux-toolkit.js.org/usage/immer-reducers#mutating-and-returning-state
      Object.assign(state, { ...state, ...profileData });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setProfileApimMyinfoDataSelectionStateRequest.fulfilled, (state, { payload }) => {
        state.apimMyinfoDataSelectionState = payload;
      })
      .addCase(setProfileToReactivatedRequest.fulfilled, (state, { payload }) => {
        state.accountStatus = payload;
      })
      .addCase(setEmailVerificationRequest.fulfilled, (state, _) => {
        state.emailVerificationStatus = EMAIL_VERIFICATION_STATUS.VERIFIED;
      })
      .addCase(setProfileEmailNotificationsRequest.fulfilled, (state, { payload }) => {
        state.emailNotificationsStatus = payload;
      });
  },
});

interface IUpdateProfileRequest {
  name: string;
  dob: Date;
  sex: SEX;
  maritalStatus: MARITAL_STATUS;
  residentialStatus: RESIDENTIAL_STATUS;
  email: string;
  emailNotification: boolean;
  deferEmailVerification: boolean;
  age: number;
  ageRange: AGE_RANGE;
  workYearsRange: WORK_YEARS_RANGE;
  employmentStatusRange: EMPLOYMENT_STATUS_RANGE;
  hasStartedPlanning: boolean;
  automaticTransferStatus: AUTOMATIC_TRANSFER_STATUS;
  dayOfTransfer: number;
  hasCompletedFeedbackForm: boolean;
  consentedToAppTNCAt: Date;
  latestConsentedToAppNOCAt: Date;
  apimMyinfoDataSelectionState: APIM_MYINFO_DATA_SELECTION_STATE;
}

// Updates Backend AND redux store when the former succeeds
export const updateProfileRequest = createAsyncThunk<void, Partial<IUpdateProfileRequest>>(
  `${name}/updateProfileRequest`,
  async (userData, { dispatch }) => {
    const sanitisedUserData = sanitiseDobAndEmail(userData);
    const preparedUserData = preparePayloadForAPIRequest(sanitisedUserData);

    await axios.patch(`${process.env.REACT_APP_API_URL}/v1/user`, preparedUserData);

    dispatch(updateProfile(sanitisedUserData));
  }
);

export const setProfileApimMyinfoDataSelectionStateRequest = createAsyncThunk<
  APIM_MYINFO_DATA_SELECTION_STATE,
  APIM_MYINFO_DATA_SELECTION_STATE
>(`${name}/setProfileApimMyinfoDataSelectionStateRequest`, async (apimMyinfoDataSelectionState) => {
  if (
    apimMyinfoDataSelectionState === APIM_MYINFO_DATA_SELECTION_STATE.MANAGE_SGFINDEX_CONNECTION
  ) {
    apimMyinfoDataSelectionState = APIM_MYINFO_DATA_SELECTION_STATE.APIM_AND_MYINFO;
  }
  await axios.patch(`${process.env.REACT_APP_API_URL}/v1/user`, { apimMyinfoDataSelectionState });
  return apimMyinfoDataSelectionState;
});

export const setProfileToReactivatedRequest = createAsyncThunk(
  `${name}/setProfileToReactivatedRequest`,
  async () => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/v1/user/reactivate-account`);
    return null;
  }
);

export const setEmailVerificationRequest = createAsyncThunk(
  `${name}/setEmailVerificationRequest`,
  async (verificationCode: string) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/email/verify-email`, {
      verificationCode,
    });
  }
);

export const setProfileEmailNotificationsRequest = createAsyncThunk(
  `${name}/profileEmailNotificationsRequest`,
  async (notification: boolean) => {
    await axios.put(`${process.env.REACT_APP_API_URL}/v1/email-notifications`, {
      newsletter: notification,
    });
    return { newsletter: notification };
  }
);

// Non-async-thunks
export const setProfileToPendingDeletionRequest = async () => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1/user/deactivate-account`);
  return data;
};

export const generateEmailVerificationCode = async () => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/v1/email/generate-verification-code`
  );
  return data;
};

export const regenerateEmailVerificationCode = async (verificationCode: string) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/v1/email/regenerate-verification-code`,
    {
      verificationCode,
    }
  );
  return data;
};

// prepares user data for updating state
const prepareUpdateProfileData = (payload: Partial<IUpdateProfilePayloadAction>) => {
  const dob = payload.dob && moment(payload.dob).utc().startOf('day').toISOString();
  const apimLatestPullConsentAt =
    payload.apimLatestPullConsentAt && payload.apimLatestPullConsentAt.toISOString();
  const myinfoLatestPullConsentAt =
    payload.myinfoLatestPullConsentAt && payload.myinfoLatestPullConsentAt.toISOString();
  const consentedToAppTNCAt =
    payload.consentedToAppTNCAt && payload.consentedToAppTNCAt.toISOString();
  const latestConsentedToAppNOCAt =
    payload.latestConsentedToAppNOCAt && payload.latestConsentedToAppNOCAt.toISOString();
  const email = payload.email && payload.email.trim();

  const values = {
    ...payload,
    email,
    dob,
    apimLatestPullConsentAt,
    myinfoLatestPullConsentAt,
    consentedToAppTNCAt,
    latestConsentedToAppNOCAt,
  };
  return pickBy(values, (value) => value !== undefined);
};

// prepares user data to be sent to backend and passed to updateProfile reducer
const preparePayloadForAPIRequest = (payload: Partial<IUpdateProfileRequest>) => {
  const sex = payload.sex && Object.keys(SEX).find((key) => SEX[key] === payload.sex);
  const maritalStatus =
    payload.maritalStatus &&
    Object.keys(MARITAL_STATUS).find((key) => MARITAL_STATUS[key] === payload.maritalStatus);
  const residentialStatus =
    payload.residentialStatus &&
    Object.keys(RESIDENTIAL_STATUS).find(
      (key) => RESIDENTIAL_STATUS[key] === payload.residentialStatus
    );

  const updatedValues = {
    ...payload,
    sex,
    maritalStatus,
    residentialStatus,
  };
  return pickBy(updatedValues, (value) => value !== undefined);
};

const sanitiseDobAndEmail = (payload: Partial<IUpdateProfileRequest>) => {
  const dob = payload.dob && moment(payload.dob).utc().startOf('day');
  const email = payload.email && payload.email.trim();

  const updatedValues = {
    ...payload,
    dob,
    email,
  };
  return pickBy(updatedValues, (value) => value !== undefined);
};

export const profileReducer = profileSlice.reducer;
export const { updateProfile } = profileSlice.actions;

import React from 'react';

import { TableHeader, TableRow, Table, CurrencyPrefix } from 'components/Common';

import { StyledTableData, StyledCurrencyPrefix } from './sharedStyles';

export const AnnualExpensesAtSixtyFiveTable = () => (
  <Table>
    <thead>
      <TableRow>
        <TableHeader colSpan={1}>
          <span>Annual expenses at age 65</span>
        </TableHeader>
        <TableHeader colSpan={1}>
          <StyledCurrencyPrefix>21,600</StyledCurrencyPrefix>
        </TableHeader>
      </TableRow>
    </thead>
    <tbody>
      <TableRow>
        <StyledTableData colSpan={1}>
          <b>Total retirement income needed (for 19 years)</b>
        </StyledTableData>
        <StyledTableData colSpan={2}>
          <CurrencyPrefix>462,100</CurrencyPrefix>
        </StyledTableData>
      </TableRow>
    </tbody>
  </Table>
);

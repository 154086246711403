import React from 'react';
import { useHistory } from 'react-router';

import { ManWithTargetsAsDestinationsImage } from 'assets/SavingsResources';
import {
  MedalIcon,
  UpwardGrowthBarGraphIcon,
  BullseyeIcon,
  ListWithATickIcon,
  CalendarIcon,
} from 'assets/SmartTargetsPage';
import { ArticleTemplate, ArticleParagraph, ArticleImageWithText } from 'components/Templates';

import { CTAButtons } from '../CTAButtons/CTAButtons';

const SMART_TARGETS = [
  {
    title: 'Specific',
    desc:
      'Details matter. Write down what you want to achieve and how you are going to achieve it.',
    icon: <ListWithATickIcon />,
  },
  {
    title: 'Measurable',
    desc:
      'You’ll need to be able to measure your progress so that you can catch up if you are falling behind.',
    icon: <UpwardGrowthBarGraphIcon />,
  },
  {
    title: 'Attainable',
    desc:
      'With the time and resources you have, your target should be within reach, even if it requires some discipline.',
    icon: <MedalIcon />,
  },
  {
    title: 'Relevant',
    desc:
      'Your target needs to be important to you, and it should also align with the goals you are pursuing.',
    icon: <BullseyeIcon />,
  },
  {
    title: 'Time-bound',
    desc: 'Set a deadline to achieve your target by.',
    icon: <CalendarIcon />,
  },
];

interface IProps {
  ctaText?: string;
  backRoute: string;
}

export const SmartTargetsContent = ({ ctaText = 'Back', backRoute }: IProps) => {
  const history = useHistory();

  return (
    <ArticleTemplate
      heroIllustration={<ManWithTargetsAsDestinationsImage />}
      heroIllustrationPosition="bottom"
      readingTimeInMins={2}
      heading="Setting SMART targets"
      content={
        <>
          <ArticleParagraph>
            SMART targets help you focus your efforts and increase the chances of achieving your
            targets.
          </ArticleParagraph>

          {SMART_TARGETS.map(({ title, desc, icon }, idx) => (
            <ArticleImageWithText key={idx} heading={title} description={desc} image={icon} />
          ))}
        </>
      }
      additionalContent={
        <CTAButtons primaryText={ctaText} primaryOnClick={() => history.push(backRoute)} />
      }
    />
  );
};

import React from 'react';
import styled, { css } from 'styled-components';

import { AliciaPersonaImage, BettyPersonaImage } from 'assets/Retirement/CloseSavingsGapArticle';
import { TableHeader, TableRow } from 'components/Common';
import { CurrencyPrefix } from 'components/Common';
import { minWidth, typography } from 'themes';

import { StyledTable, StyledTableData } from './sharedStyles';

const HeaderWithImage = styled.div`
  text-align: center;

  > :first-child {
    height: 64px;
    ${minWidth(
      'desktop',
      css`
        height: 90px;
      `
    )}
  }
`;

const HeaderText = styled.p`
  margin: 0;
  ${typography('body-m-bold')}
`;

const BodyText = styled.p`
  ${typography('body-m')}
`;

export const PersonaComparisonTable = () => (
  <StyledTable>
    <thead>
      <TableRow>
        <TableHeader colSpan={2}>
          Alicia and Betty both started working at 23 years old and plan to retire at 60 years old.
        </TableHeader>
      </TableRow>
    </thead>

    <tbody>
      <TableRow>
        <StyledTableData>
          <HeaderWithImage>
            <AliciaPersonaImage />

            <HeaderText>Alicia</HeaderText>
          </HeaderWithImage>

          <BodyText>
            Alicia has a good understanding of compound interest and immediately starts investing{' '}
            <CurrencyPrefix>500</CurrencyPrefix> every month in a financial instrument that gives
            about 4% per annum.
          </BodyText>
        </StyledTableData>

        <StyledTableData>
          <HeaderWithImage>
            <BettyPersonaImage />

            <HeaderText>Betty</HeaderText>
          </HeaderWithImage>

          <BodyText>
            On the other hand, Jane only realised the importance of compound interest at 35 years
            old and started investing <CurrencyPrefix>800</CurrencyPrefix> every month in the same
            financial instrument.
          </BodyText>
        </StyledTableData>
      </TableRow>
    </tbody>
  </StyledTable>
);

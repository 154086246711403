import React from 'react';
import styled from 'styled-components';
import { prop, theme as fromTheme } from 'styled-tools';

import { colorScale, typography } from 'themes';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: row;
  background: ${colorScale('grey', 10)};
  border: 1px solid ${colorScale('grey', 30)};
  border-radius: ${fromTheme('borders.radiusLarge')};
  padding: 16px;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LabelText = styled.span<{ mainColor: string; textColor: string }>`
  ${typography('body-s-bold')};
  color: ${prop('textColor')};
  > ::first-letter {
    text-transform: uppercase;
  }

  &::before {
    color: ${prop('mainColor')};
    content: '●';
    margin-right: 8px;
  }
`;

const PercentageText = styled.span<{ color: string }>`
  color: ${prop('color')};
  ${typography('body-l-bold')};
`;

interface IProps {
  data: {
    label: string;
    amount: number;
    color: { main: string; text: string };
  }[];
}

export const Legend: React.FC<IProps> = ({ data, ...otherProps }) => {
  const total = data.reduce((a, c) => a + c.amount, 0);
  return (
    <Wrapper {...otherProps}>
      {data.map(({ label, amount, color }, i) => (
        <RowWrapper key={i}>
          <LabelText mainColor={color.main} textColor={color.text}>
            {label}
          </LabelText>
          <PercentageText color={color.text}>
            {total ? (Math.round((amount / total) * 1000) / 10).toFixed(1) : 0}%
          </PercentageText>
        </RowWrapper>
      ))}
    </Wrapper>
  );
};

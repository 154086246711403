import React from 'react';

import { ManWithTargetsAsDestinationsLongRoadImage } from 'assets/SavingsResources';
import { ToolLockoutPage } from 'components/Composite';
import { DASHBOARD } from 'constants/urls';
import { APIM_MYINFO_RETRIEVAL_ENTRY_PAGE } from 'utilities/sessionUtilities';

export const TargetsLockoutPage = () => (
  <ToolLockoutPage
    lockoutToolText="You can manage your targets by using SGFinDex to retrieve your data from financial institutions securely."
    image={<ManWithTargetsAsDestinationsLongRoadImage />}
    backCtaText="Return to dashboard"
    backCtaRoute={DASHBOARD.baseRoute}
    dataSelectionPageEntryPoint={APIM_MYINFO_RETRIEVAL_ENTRY_PAGE.TARGETS_LOCKOUT_PAGE}
  />
);

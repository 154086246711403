import { IRadarChartProps } from './RadarChart';

const minGraphSpacePercentage = 0;
export const maxGraphSpacePercentage = 30;

export const calculatePolygonVertices = ({
  numOfSides,
  radiusLevels,
}: {
  numOfSides: number;
  radiusLevels: number[];
}) => {
  // radius is half of the given space, which is 50%
  const radius = 50;
  const vertices = [];
  const angle = (2 * Math.PI) / numOfSides;

  for (let i = 0; i < numOfSides; i++) {
    const rotated = angle * i - Math.PI;
    const x = radius - radiusLevels[i] * Math.sin(rotated);
    const y = radius + radiusLevels[i] * Math.cos(rotated);
    vertices.push({ x, y });
  }

  return vertices;
};

export const mapChartLevel = ({ data, level }: Omit<IRadarChartProps, 'displayOrder'>) => {
  const graphStep =
    Math.floor(((maxGraphSpacePercentage - minGraphSpacePercentage) / level) * 10000) / 10000;

  const graphLevels: number[] = [];
  for (let i = minGraphSpacePercentage; i <= maxGraphSpacePercentage; i = i + graphStep) {
    graphLevels.push(i);
  }

  return {
    graphLevels,
    dataLevels: data.map(({ value }) => Math.min(value * graphStep, maxGraphSpacePercentage)),
  };
};

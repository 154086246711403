export enum RECOMMENDED_ACTION {
  TRA_SR_A = 'TRA_SR_A',
  TRA_SR_B = 'TRA_SR_B',
  TRA_SR_C = 'TRA_SR_C',
  TRA_SR_D = 'TRA_SR_D',
  TRA_SR_E = 'TRA_SR_E',
  TRA_TDSR_A = 'TRA_TDSR_A',
  TRA_TDSR_B = 'TRA_TDSR_B',
  TRA_TDSR_C = 'TRA_TDSR_C',
  TRA_PD_A = 'TRA_PD_A',
  TRA_PD_B = 'TRA_PD_B',
  TRA_PD_C = 'TRA_PD_C',
  TRA_PD_D = 'TRA_PD_D',
  TRA_PD_E = 'TRA_PD_E',
  TRA_EF_A = 'TRA_EF_A',
}

export enum RECOMMENDED_ACTION_COMPLETER {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum RECOMMENDED_ACTION_LABEL {
  SAVINGS = 'SAVINGS',
  LOANS_AND_CREDIT = 'LOANS AND CREDIT',
  INVESTMENTS = 'INVESTMENTS',
}

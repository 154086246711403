import * as Sentry from '@sentry/browser';

import { applicationFlags } from 'applicationFlags';
import { featureDecisions } from 'featureDecisions';

export const captureSentryException = (err: any) => {
  if (getSentryAuthorized()) Sentry.captureException(err);
};

export const getSentryAuthorized = () => {
  return !applicationFlags.isProduction && featureDecisions.isSentryEnabled;
};

import { clamp } from 'lodash';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { prop, theme as fromTheme } from 'styled-tools';

import { colorScale, shadowScale } from 'themes';

const borderRadiusRound = fromTheme('borders.radiusRound');

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const sliderKnobStyles = css`
  appearance: none;
  width: 0.7rem;
  height: calc(${prop('height')} + 0.7rem);
  background: ${colorScale('primary', 40)};
  cursor: pointer;
  border-radius: 0.2rem;
  border-width: 0;
`;

const StyledSlider = styled.input<{ height: string }>`
  appearance: none;
  width: 100%;
  height: ${prop('height')};
  background: ${colorScale('grey', 20)};
  border-radius: ${borderRadiusRound};
  margin: 0;
  cursor: pointer;
  outline: none;

  &::-webkit-slider-thumb {
    ${sliderKnobStyles};
  }

  &::-moz-range-thumb {
    ${sliderKnobStyles};
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:hover,
  &:focus,
  &:active {
    &::-webkit-slider-thumb {
      box-shadow: ${shadowScale(2)};
    }

    &::-moz-range-thumb {
      box-shadow: ${shadowScale(2)};
    }
  }
`;

const Trail = styled.div<{ height: string }>`
  pointer-events: none;
  position: absolute;
  height: ${prop('height')};
  background: ${colorScale('primary', 30)};
  border-radius: ${borderRadiusRound} 0 0 ${borderRadiusRound};
`;

interface IOwnProps {
  hasTrail?: boolean;
  min: number;
  max: number;
  step?: number;
  value: number;
  height?: string;
}

enum SCROLL_DIRECTION {
  X = 'X',
  Y = 'Y',
}

export type ISliderProps = IOwnProps & React.HTMLAttributes<HTMLInputElement>;

export const Slider: React.FC<ISliderProps> = ({
  hasTrail = true,
  min: targetMin,
  max,
  step: targetStep = 1,
  value: targetValue,
  height = '1.5rem',
  onChange,
  ...otherProps
}) => {
  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
    },
    [onChange]
  );
  const [initialTouch, setInitialTouch] = useState({ x: 0, y: 0 });
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<SCROLL_DIRECTION | null>(null);

  const sliderRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const handleResize = useCallback(() => {
    if (sliderRef.current) {
      const { left, width } = sliderRef.current.getBoundingClientRect();
      setLeft(left);
      setWidth(width);
    }
  }, [setLeft, setWidth]);

  const calculateScrollDirection = useCallback(
    (e: TouchEvent) => {
      const touchX = e.touches[0]?.clientX || 0;
      const touchY = e.touches[0]?.clientY || 0;

      return Math.abs(touchY - initialTouch.y) > Math.abs(touchX - initialTouch.x)
        ? SCROLL_DIRECTION.Y
        : SCROLL_DIRECTION.X;
    },
    [initialTouch]
  );

  const shiftSlider = useCallback(
    (touchX: number) => {
      const min = Math.min(targetMin, max - 1);
      const step = Math.max(1, targetStep);
      const percentage = (touchX - left) / width;
      const value = clamp(Math.round((percentage * (max - min) + min) / step) * step, min, max);

      onChangeCallback && onChangeCallback({ target: { value } } as any);
    },
    [onChangeCallback, left, max, targetMin, targetStep, width]
  );

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      const touchX = e.touches[0]?.clientX || 0;
      const touchY = e.touches[0]?.clientY || 0;

      setInitialTouch({
        x: touchX,
        y: touchY,
      });
    },
    [setInitialTouch]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (scrollDirection === null) {
        setScrollDirection(calculateScrollDirection(e));
      }

      if (scrollDirection === SCROLL_DIRECTION.Y) {
        return;
      }

      if (scrollDirection === SCROLL_DIRECTION.X) {
        e.cancelable && e.preventDefault();
        shiftSlider(e.touches[0]?.clientX || 0);
      }
    },
    [scrollDirection, setScrollDirection, calculateScrollDirection, shiftSlider]
  );

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      if (scrollDirection === null) {
        shiftSlider(e.changedTouches[0]?.clientX || 0);
      } else {
        setScrollDirection(null);
      }
    },
    [scrollDirection, shiftSlider]
  );

  useEffect(() => {
    const sliderRefCurrent = sliderRef.current;

    if (sliderRefCurrent) {
      sliderRefCurrent.addEventListener('touchstart', handleTouchStart);
      sliderRefCurrent.addEventListener('touchmove', handleTouchMove);
      sliderRefCurrent.addEventListener('touchend', handleTouchEnd);
      window.addEventListener('resize', handleResize);

      handleResize();
    }

    return () => {
      if (sliderRefCurrent) {
        sliderRefCurrent.removeEventListener('touchstart', handleTouchStart);
        sliderRefCurrent.removeEventListener('touchmove', handleTouchMove);
        sliderRefCurrent.removeEventListener('touchend', handleTouchEnd);
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [sliderRef, handleTouchStart, handleTouchMove, handleTouchEnd, handleResize]);

  const min = Math.min(targetMin, max - 1);
  const value = clamp(targetValue, min, max);
  const step = Math.max(1, targetStep);
  const pseudoMax = Math.ceil(max / step) * step;
  const trailValue = (Math.round((value - min) / step) * step) / (pseudoMax - min);

  return (
    <Wrapper>
      <StyledSlider
        type="range"
        min={min}
        max={pseudoMax}
        step={step}
        value={value}
        height={height}
        ref={sliderRef}
        onChange={onChangeCallback}
        {...otherProps}
      />
      {hasTrail && (
        <Trail height={height} style={{ width: `calc(${trailValue} * (100% - 0.7rem))` }} />
      )}
    </Wrapper>
  );
};

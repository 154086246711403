import React from 'react';
import styled from 'styled-components';
import { theme as fromTheme } from 'styled-tools';

import { Open } from 'assets/icons';
import { colorScale } from 'themes';

export const Link = styled.a`
  &[href] {
    color: #0000ee;
    transition: ${fromTheme('transitions.duration')};

    &:visited {
      color: #551a8b;
    }

    &:visited svg {
      fill: #551a8b;
    }

    &:focus {
      outline: none;
      padding: 0.2em 0.4em;
      margin-left: -0.4em;
      margin-top: -0.2em;
      background: ${colorScale('primary', 20)};
    }
  }
`;

interface IExternalLinkProps {
  url: string;
  children: string;
}

const StyledLink = styled(Link)`
  > svg {
    vertical-align: middle;
    margin-left: 4px;
  }
`;

export const ExternalLink = ({ url, children }: IExternalLinkProps) => (
  <StyledLink href={url} target="_blank" rel="noopener noreferrer">
    {children}
    <Open />
  </StyledLink>
);

import axios from 'axios';
import { useState, useEffect } from 'react';

import { getDateDiffInDays } from 'utilities/dateUtilities';

export interface IMaintenanceSchedule {
  agencies: string[];
  from: Date;
  to: Date;
  showOnLandingPageBanner: boolean;
  landingPageBannerMessage: string;
  maintenanceNoticePageRemarks: string;
}

export interface IMaintenanceScheduleStore {
  maintenanceSchedules: IMaintenanceSchedule[];
  lastUpdated: Date | null;
}

export interface IMaintenanceSchedulesResponse {
  agencies: string[];
  from: string;
  to: string;
  showOnLandingPageBanner: boolean;
  landingPageBannerMessage: string;
  maintenanceNoticePageRemarks: string;
}

export type IMaintenanceSchedulesResult = IMaintenanceScheduleStore & {
  hasPullError: boolean;
  getUnexpiredMaintenanceSchedules: () => IMaintenanceSchedule[];
  getMaintenanceSchedulesToShowOnBanner: () => IMaintenanceSchedule[];
};

const maintenanceSchedulesCache: IMaintenanceScheduleStore = {
  maintenanceSchedules: [],
  lastUpdated: null,
};

export const resetMaintenanceSchedulesCache = () => {
  maintenanceSchedulesCache.maintenanceSchedules = [];
  maintenanceSchedulesCache.lastUpdated = null;
};

export const useMaintenanceSchedules = (): IMaintenanceSchedulesResult => {
  const [maintenanceSchedules, setMaintenanceSchedules] = useState<IMaintenanceSchedule[]>([]);
  const [lastUpdated, setMaintenanceSchedulesLastUpdated] = useState<Date | null>(null);
  const [hasPullError, setHasPullError] = useState(false);

  const hasNotPulledBefore =
    maintenanceSchedulesCache.maintenanceSchedules.length === 0 &&
    maintenanceSchedulesCache.lastUpdated === null;

  useEffect(() => {
    if (hasNotPulledBefore) {
      const promise = axios.get(`${process.env.REACT_APP_API_URL}/v1/maintenance-schedule`);

      promise
        .then(({ data }) => {
          if (data && data.data) {
            const { maintenanceSchedules, lastUpdated } = data.data;

            const maintenanceSchedulesToUpdate = maintenanceSchedules.map(
              (ms: IMaintenanceSchedulesResponse) => ({
                ...ms,
                from: new Date(ms.from),
                to: new Date(ms.to),
              })
            );
            const lastUpdatedToUpdate = new Date(lastUpdated);

            setMaintenanceSchedules(maintenanceSchedulesToUpdate);
            maintenanceSchedulesCache.maintenanceSchedules = maintenanceSchedulesToUpdate;
            setMaintenanceSchedulesLastUpdated(lastUpdatedToUpdate);
            maintenanceSchedulesCache.lastUpdated = lastUpdatedToUpdate;
            setHasPullError(false);
          }
        })
        .catch(() => {
          setHasPullError(true);
        });
    } else {
      setMaintenanceSchedules(maintenanceSchedulesCache.maintenanceSchedules);
      setMaintenanceSchedulesLastUpdated(maintenanceSchedulesCache.lastUpdated);
    }
  }, [hasNotPulledBefore]);

  const getUnexpiredMaintenanceSchedules = (): IMaintenanceSchedule[] => {
    const dateNow = new Date();
    return maintenanceSchedules.filter((ms) => dateNow.getTime() - ms.to.getTime() <= 0);
  };

  const getMaintenanceSchedulesToShowOnBanner = (): IMaintenanceSchedule[] => {
    const dateNow = new Date();
    return maintenanceSchedules
      .filter((ms) => {
        const diffInDaysStartVsNow = getDateDiffInDays(dateNow, ms.from);
        const hasMaintenanceScheduleEnded = dateNow.getTime() - ms.to.getTime() > 0;
        return (
          ms.showOnLandingPageBanner && !hasMaintenanceScheduleEnded && diffInDaysStartVsNow >= -3
        );
      })
      .sort((ms) => ms.from.getTime());
  };

  return {
    maintenanceSchedules,
    lastUpdated,
    hasPullError,
    getUnexpiredMaintenanceSchedules,
    getMaintenanceSchedulesToShowOnBanner,
  };
};

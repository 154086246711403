const onboardingPrefix = '/onboarding';
const cashflowPrefix = '/cashflow';
const wealthPrefix = '/wealth';
const protectionPrefix = '/protection';
export const ONBOARDING = {
  baseRoute: onboardingPrefix,
  expenseEstimation: `${onboardingPrefix}/expense-estimation`,
  incomeEstimation: `${onboardingPrefix}/income-estimation`,
  PROFILE: {
    baseRoute: `${onboardingPrefix}/info`,
    profileSetup: `${onboardingPrefix}/info/step-1`,
    emailConfirmation: `${onboardingPrefix}/info/step-2`,
    employmentStatus: `${onboardingPrefix}/info/step-3`,
  },
  CASHFLOW: {
    baseRoute: cashflowPrefix,
    setup: `${cashflowPrefix}/setup`,
    inflow: `${cashflowPrefix}/inflow`,
    outflowExpenses: `${cashflowPrefix}/outflow-expense`,
    outflowLoans: `${cashflowPrefix}/outflow-loans`,
    outflowInvestments: `${cashflowPrefix}/outflow-investments`,
    completed: `${cashflowPrefix}/completed`,
  },
  WEALTH: {
    baseRoute: wealthPrefix,
    setup: `${wealthPrefix}/setup`,
    assets: `${wealthPrefix}/assets`,
    liabilities: `${wealthPrefix}/liabilities`,
    completed: `${wealthPrefix}/completed`,
  },
  completed: `${onboardingPrefix}/completed`,
  incomplete: `${onboardingPrefix}/incomplete`,
};

const dashboardPrefix = '/dashboard';
const insurancePoliciesPrefix = '/policies';
export const DASHBOARD = {
  baseRoute: dashboardPrefix,
  CASHFLOW: {
    baseRoute: `${dashboardPrefix}${cashflowPrefix}`,
    editIncome: `${dashboardPrefix}${cashflowPrefix}/edit-income`,
    editMonthlyInvestments: `${dashboardPrefix}${cashflowPrefix}/edit-monthly-investments`,
    editExpenses: `${dashboardPrefix}${cashflowPrefix}/edit-expenses`,
    editLoans: `${dashboardPrefix}${cashflowPrefix}/edit-loans`,
    loanRepayment: `${dashboardPrefix}${cashflowPrefix}/loan-repayment`,
    savingsRatio: `${dashboardPrefix}${cashflowPrefix}/savings-ratio`,
    totalDebtServicingRatio: `${dashboardPrefix}${cashflowPrefix}/total-debt-servicing-ratio`,
    monthlyInvestments: `${dashboardPrefix}${cashflowPrefix}/monthly-investments`,
  },
  WEALTH: {
    baseRoute: `${dashboardPrefix}${wealthPrefix}`,
    editAssets: `${dashboardPrefix}${wealthPrefix}/edit-assets`,
    editLiabilities: `${dashboardPrefix}${wealthPrefix}/edit-liabilities`,
    investments: `${dashboardPrefix}${wealthPrefix}/investments`,
    cashInvestments: `${dashboardPrefix}${wealthPrefix}/investments/cash-investments`,
    cpfisInvestments: `${dashboardPrefix}${wealthPrefix}/investments/cpfis-investments`,
    srsInvestments: `${dashboardPrefix}${wealthPrefix}/investments/srs-investments`,
    portfolioDiversification: `${dashboardPrefix}${wealthPrefix}/portfolio-diversification`,
  },
  PROTECTION: {
    baseRoute: `${dashboardPrefix}${protectionPrefix}`,
    editEmergencyFund: `${dashboardPrefix}${protectionPrefix}/edit-emergency-fund`,
    editAssets: `${dashboardPrefix}${protectionPrefix}/edit-assets`,
    emergencyFund: `${dashboardPrefix}${protectionPrefix}/emergency-fund`,
    emergencyFundRatio: `${dashboardPrefix}${protectionPrefix}/emergency-fund-ratio`,
    allocateFunds: `${dashboardPrefix}${protectionPrefix}/allocate-funds-and-allocation`,
    insurancePolicies: `${dashboardPrefix}${protectionPrefix}${insurancePoliciesPrefix}`,
    insurancePlans: `${dashboardPrefix}${protectionPrefix}${insurancePoliciesPrefix}/plan/:planId`,
  },
};

const targetsPrefix = '/targets';
export const TARGETS = {
  baseRoute: targetsPrefix,
  SETUP: {
    step1: `${targetsPrefix}/setup/step-1`,
    step2: `${targetsPrefix}/setup/step-2`,
    step3: `${targetsPrefix}/setup/step-3`,
    smartTargets: `${targetsPrefix}/setup/smart-targets`,
    completed: `${targetsPrefix}/setup/completed`,
  },
  lockoutPage: `${targetsPrefix}/lockout-page`,
};

const housingResources = '/buying-a-house';
const financingPlan = '/buying-a-house/financing-plan';
const purchaseGuide = '/buying-a-house/purchase-guide';

export const HOUSING = {
  housingResources,
  financingPlan,
  lockoutPage: '/buying-a-house/lockout-page',
  ARTICLES: {
    baseRoute: purchaseGuide,
    cashOrCPF: `${purchaseGuide}/cash-or-CPF`,
    hdbOrBankLoan: `${purchaseGuide}/hdb-or-bank-loan`,
    leaveMoneyInCPF: `${purchaseGuide}/leave-money-in-CPF`,
    loanAmountAndPeriod: `${purchaseGuide}/loan-amount-and-period`,
    myFirstHouse: `${purchaseGuide}/my-first-house`,
    newOrResaleFlat: `${purchaseGuide}/new-or-resale-flat`,
    otherThingsToPayFor: `${purchaseGuide}/other-things-to-pay-for`,
  },
  CUSTOMPLAN: {
    step1: `${financingPlan}/custom/step-1`,
    step2: `${financingPlan}/custom/step-2`,
    step3: `${financingPlan}/custom/step-3`,
    step4: `${financingPlan}/custom/step-4`,
    step5: `${financingPlan}/custom/step-5`,
    planSummary: `${financingPlan}/custom/summary`,
  },
  RECOMMENDEDPLAN: {
    step1: `${financingPlan}/recommended/step-1`,
    planSummary: `${financingPlan}/recommended/summary`,
    assumptions: `${financingPlan}/recommended/assumptions`,
  },
};

const savingsResourcesPrefix = '/savings-resources';
const loansAndCreditResourcesPrefix = '/loans-and-credit-resources';
const investmentResourcesPrefix = '/investment-resources';
const insuranceResourcesPrefix = '/insurance-resources';
const retirementResourcesPrefix = '/retirement-resources';
export const RESOURCES = {
  SAVINGS: {
    baseRoute: savingsResourcesPrefix,
    saveBeforeYouSpend: `${savingsResourcesPrefix}/save-before-you-spend`,
    setSmartTargets: `${savingsResourcesPrefix}/setting-smart-targets`,
    budgetCalcLockoutPage: `${savingsResourcesPrefix}/budget-calc-lockout-page`,
  },
  LOANS_AND_CREDIT: {
    baseRoute: loansAndCreditResourcesPrefix,
    repayYourLoansEarlyPage: `${loansAndCreditResourcesPrefix}/repay-your-loans-early`,
  },
  INVESTMENT: {
    baseRoute: investmentResourcesPrefix,
    antiScamArticle: `${investmentResourcesPrefix}/beware-of-investment-scams`,
    ESTIMATEDGROWTHCAL: {
      baseRoute: `${investmentResourcesPrefix}/estimated-growth-rate`,
      lockoutPage: `${investmentResourcesPrefix}/estimated-growth-rate/lockout-page`,
    },
    setReminders: `${investmentResourcesPrefix}/set-reminders`,
  },
  INSURANCE: {
    baseRoute: insuranceResourcesPrefix,
    understandingTheTypesOfInsurance: `${insuranceResourcesPrefix}/understanding-the-types-of-insurance`,
    ARTICLE: {
      dpsArticle: `${insuranceResourcesPrefix}/what-is-dependants-protection-scheme`,
      hpsArticle: `${insuranceResourcesPrefix}/what-is-home-protection-scheme`,
      mshlArticle: `${insuranceResourcesPrefix}/what-is-medishield-life`,
      ispArticle: `${insuranceResourcesPrefix}/what-is-integrated-shield-plan`,
      careShieldArticle: `${insuranceResourcesPrefix}/what-is-careshield`,
      elderShieldArticle: `${insuranceResourcesPrefix}/what-is-eldershield`,
      supplementArticle: `${insuranceResourcesPrefix}/what-are-supplements`,
    },
  },
  RETIREMENT: {
    baseRoute: retirementResourcesPrefix,
    defineRetirementGoals: `${retirementResourcesPrefix}/define-retirement-goals`,
    assessCurrentSituation: `${retirementResourcesPrefix}/assess-current-situation`,
    closeSavingsGap: `${retirementResourcesPrefix}/close-savings-gap`,
  },
  housingResources,
};

const investingForBeginnersPrefix = `${investmentResourcesPrefix}/investing-for-beginners`;
export const INVESTING_FOR_BEGINNERS = {
  baseRoute: investingForBeginnersPrefix,
  lockoutPage: `${investingForBeginnersPrefix}/lockout-page`,
  ARTICLES: {
    setUpCdpBrokerageAccount: `${investingForBeginnersPrefix}/set-up-cdp-and-or-brokerage-account`,
    getFinancialAdvice: `${investingForBeginnersPrefix}/get-financial-advice`,
  },
  READINESS_ASSESSMENT: {
    baseRoute: `${investingForBeginnersPrefix}/readiness-assessment`,
    results: `${investingForBeginnersPrefix}/readiness-assessment-results`,
    resultsEditEmergencyFund: `${investingForBeginnersPrefix}/readiness-assessment-results/edit-emergency-fund`,
    editIncome: `${investingForBeginnersPrefix}/edit-income`,
    editExpenses: `${investingForBeginnersPrefix}/edit-expenses`,
    editLoans: `${investingForBeginnersPrefix}/edit-loans`,
    editMonthlyInvestments: `${investingForBeginnersPrefix}/edit-monthly-investments`,
    editEmergencyFund: `${investingForBeginnersPrefix}/edit-emergency-fund`,
  },
};

export const MORE = {
  aboutUs: '/about-us',
};

const profilePrefix = '/profile';
export const PROFILE = {
  baseRoute: profilePrefix,
  addEmail: `${profilePrefix}/add-email`,
  editGeneralInfo: `${profilePrefix}/edit-general-information`,
  editEmploymentStatus: `${profilePrefix}/edit-employment-status`,
  editEmailPreferences: `${profilePrefix}/edit-email-preferences`,
  deleteConsequences: `${profilePrefix}/delete-consequences`,
  deleteConfirmation: `${profilePrefix}/delete-confirmation`,
  deleteCompleted: `/delete-completed`,
  deactivated: `/deactivated`,
  emailVerification: `/email-verification/:verificationCode`,
};

export const INTERCEPT = {
  LOGIN: {
    nocInterceptOnLogin: '/notification-of-changes-intercept-login',
    emailInterceptOnLogin: `/email-intercept-login`,
  },
  emailInterceptNotification: `${profilePrefix}/email-intercept-notification`,
};

export const NOTIFICATIONS = {
  notifications: '/notifications',
};

export const ANNOUNCEMENTS = {};

export const LOGIN = {
  singpassRedirect: '/singpass-redirect',
};

const settingsPrefix = '/settings';
export const SETTINGS = {
  baseRoute: settingsPrefix,
  DATASELECTION: {
    baseRoute: `${settingsPrefix}/data-selection`,
    main: `${settingsPrefix}/data-selection/main`,
  },
};

const apimPrefix = '/data';
export const APIM = {
  bankSummary: `${apimPrefix}/summary/banks`,
  govSummary: `${apimPrefix}/summary/gov`,
  error: `${apimPrefix}/error`,
  errorFromSp: `${apimPrefix}/error/sp`,
};

export const EXTERNAL_URL = {
  cpfTopUpSavings:
    'https://www.cpf.gov.sg/member/growing-your-savings/saving-more-with-cpf/top-up-to-enjoy-higher-retirement-payouts',
  cpfContribution:
    'https://www.cpf.gov.sg/member/growing-your-savings/cpf-contributions/saving-as-an-employee',
  medianHouseHoldIncome2020: 'https://tablebuilder.singstat.gov.sg/table/CT/17870',
  voluntaryHousingRefund:
    'https://www.cpf.gov.sg/member/growing-your-savings/saving-more-with-cpf/make-a-voluntary-housing-refund',
  institueForFinancialLiteracy: 'https://ifl.org.sg/modules/',
  institueForFinancialLiteracySignUpForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSeIzVg-Dfx8ezSdXwM5wUCsFjy8qPmfrq9jak8Mulz54AUFGA/viewform',
  facebook: 'https://www.facebook.com/MoneySense/',
  instagram: 'https://www.instagram.com/moneysense_sg/',
  basicFinancialPlanningGuide: 'https://www.moneysense.gov.sg/planning-your-finances-well',
  cpfBeReady: 'https://www.cpf.gov.sg/member/infohub/be-ready',
};

const budgetCaculatorPrefix = `${RESOURCES.SAVINGS.baseRoute}/budget-calculator`;
export const BUDGET_CALCULATOR = {
  baseRoute: budgetCaculatorPrefix,
  categoriseExpenses: `${budgetCaculatorPrefix}/categorise-expenses`,
  setMonthlyBudget: `${budgetCaculatorPrefix}/set-monthly-budget`,
  summary: `${budgetCaculatorPrefix}/summary`,
};

const financialLiteracyQuizPrefix = '/fin-lit-quiz';
export const FINANCIAL_LITERACY_QUIZ = {
  baseRoute: financialLiteracyQuizPrefix,
  preface: `${financialLiteracyQuizPrefix}/preface`,
  questions: `${financialLiteracyQuizPrefix}/questions`,
  results: `${financialLiteracyQuizPrefix}/results`,
  termsAndConditions: `${financialLiteracyQuizPrefix}/terms-and-conditions`,
  demographics: `${financialLiteracyQuizPrefix}/demographics`,
  error: `${financialLiteracyQuizPrefix}/error`,
};

export const SGFINDEX = {
  termAndConditions: '/SGFinDex-Terms-and-Conditions',
  termAndConditionsRevisions: '/SGFinDex-Terms-and-Conditions-revisions',
  dataList: '/SGFinDex-Data-List',
};

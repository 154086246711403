import Tippy, { TippyProps } from '@tippyjs/react';
import React from 'react';
import styled from 'styled-components';

import { theme, shadowScale } from 'themes';

const StyledTippy = styled(Tippy)`
  background: white;
  padding: 0;
  border-radius: 12px;
  box-shadow: ${shadowScale(3)};
  outline: none;

  .tippy-tooltip {
    background: white;
  }

  .tippy-backdrop {
    background: white;
  }
`;

export const Popover = ({ placement = 'right-start', ...otherProps }: TippyProps) => (
  <StyledTippy
    duration={[500, 250]}
    zIndex={theme.layers.tooltip} // Import directly since this part of the theme will not change
    interactive={true}
    placement={placement}
    {...otherProps}
  />
);

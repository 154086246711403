import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Heading, Section } from 'components/Common';
import { CTAButtons } from 'components/Composite';
import { TOKEN_LIFETIME_IN_MIN } from 'components/Sessions/SessionManager';
import { LOGIN } from 'constants/urls';
import { typeScale } from 'themes';
import { getLogoutPageStatusType, LOGOUT_STATUS_TYPES } from 'utilities/sessionUtilities';

import { PageWithBackground } from '../PageWithBackground';

const openFeedbackFormInNewTab = () => {
  const newWindow = window.open(
    process.env.REACT_APP_FEEDBACK_FORM_URL,
    '_blank',
    'noopener,noreferrer'
  );
  if (newWindow) newWindow.opener = null;
};

export const Title = styled(Heading)`
  font-size: ${typeScale(4)};
  text-align: center;
  margin-top: 56px;
  margin-bottom: 48px;
  max-width: 480px;
`;

export const SubTitle = styled.p`
  font-size: ${typeScale(2)};
  text-align: center;
  margin-bottom: 48px;
  max-width: 480px;
`;

export const StyledSection = styled(Section)`
  position: relative;
  z-index: 1;
  margin-bottom: 160px;
  padding: 16px;
`;

export const LogoutPage = () => {
  const history = useHistory();
  const logoutStatusType = getLogoutPageStatusType();
  const isDuplicateSession = logoutStatusType === LOGOUT_STATUS_TYPES.DUPLICATE_SESSION;

  const redirectToSingpassPage = () => {
    history.push(LOGIN.singpassRedirect);
  };

  const redirectToLandingPage = () => {
    history.push('/');
  };

  useEffect(() => {
    window.sessionStorage.clear();

    if (!logoutStatusType) {
      redirectToLandingPage();
    }
  });

  const inactivityDescription = `To protect your data, we have logged you out after ${TOKEN_LIFETIME_IN_MIN} minutes of inactivity. Please log in again.`;
  const accessExpiryDescription = 'Your login has expired. Please log in again.';
  const involuntaryLogout =
    logoutStatusType === LOGOUT_STATUS_TYPES.INACTIVITY_EXPIRED ||
    logoutStatusType === LOGOUT_STATUS_TYPES.ACCESS_TOKEN_EXPIRED;

  return (
    <PageWithBackground>
      <StyledSection>
        {!involuntaryLogout && !isDuplicateSession && (
          <>
            <Title>You have logged out successfully</Title>
            <SubTitle> Give us your feedback and help us improve the website.</SubTitle>
            <CTAButtons primaryText="Leave a feedback" primaryOnClick={openFeedbackFormInNewTab} />
          </>
        )}

        {isDuplicateSession && (
          <>
            <Title>Multiple sessions detected</Title>
            <SubTitle>
              For security reasons, we have logged you out. Kindly log in once more to resume your
              session.
            </SubTitle>
            <CTAButtons
              primaryText="Login again with Singpass"
              primaryOnClick={redirectToSingpassPage}
            />
          </>
        )}

        {involuntaryLogout && (
          <>
            <Title>We have logged you out</Title>
            <SubTitle>
              {logoutStatusType === LOGOUT_STATUS_TYPES.INACTIVITY_EXPIRED && inactivityDescription}
              {logoutStatusType === LOGOUT_STATUS_TYPES.ACCESS_TOKEN_EXPIRED &&
                accessExpiryDescription}
            </SubTitle>
            <CTAButtons
              primaryOnClick={redirectToSingpassPage}
              primaryText="Login"
              secondaryOnClick={redirectToLandingPage}
              secondaryText="Return to MyMoneySense"
            />
          </>
        )}
      </StyledSection>
    </PageWithBackground>
  );
};

export default LogoutPage;
